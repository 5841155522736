import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TagSidebar, { TagOverflowMenu } from './TagSidebar';
import PeopleSidebar from './PeopleSidebar';
import ThreeDSidebar from './ThreeDSidebar';
import LessonSidebar from './LessonSidebar';
import DataSidebar from './DataSidebar';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { fade, useMediaQuery, useTheme, Typography, Divider, Card, CardContent } from '@material-ui/core';
import TurnedInRoundedIcon from '@material-ui/icons/TurnedInRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import LayersRoundedIcon from '@material-ui/icons/LayersRounded';
import FunctionsIcon from '@material-ui/icons/Functions';
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import DownloadingIcon from '@mui/icons-material/Downloading';
// import { ThreeDIcon } from '@crema/core/Icons/CustomIcons';
import { makeStyles, createStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import { Lesson, SIDEBAR_INDEX, SpaceRole, TagGroup } from 'types/models/home/HomeApp';
import Variables from './Variables/Variables';
import { canEditSpace } from '@crema/utility/Utils';
import { SET_CURRENT_SIDEBAR_INDEX } from 'types/actions/Home.action';
import clsx from 'clsx';
import { UseCase } from 'shared/constants/AppConst';
import TaskSidebar from './TaskSidebar';
import { useHistory } from 'react-router-dom';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import { onStopLesson } from 'redux/actions/Layer';
import { store } from 'App';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';
import { setCurrentSidebar } from 'redux/actions/Home';
import { Scrollbar } from '@crema';
import PropPanelWrapper from '../ShowcaseOverlay/3DTools/PropPanelWrapper';
import PoseList from './PoseList';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import IconHover from '@crema/core/AppAnimation/ItemHover/IconHover';
import * as Icons from '@mui/icons-material';
import { isUserAdmin } from '../../utils';
import { RichHelpBadge } from '@crema/core/HelpBage';
import ConfirmationDialog from '@crema/core/ConfirmationDialog';
import { setSDKMode } from 'redux/actions/ThreeD';
import { SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import { ExternalSdkMode } from 'mp/core/craEngine/SubSystems/core/Simulation';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { Alert } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  matches: boolean;
}

const useStyles = makeStyles((theme: CremaTheme) =>
  createStyles({
    tabRoot: {
      backgroundColor: '#a9a2a533',
      '& .MuiTab-root': {
        // '#1890ff',

        '& .MuiButtonBase-root': {
        },
        width: '0.7em',
        fontSize: '0.85em',
        textTransform: 'none',
        color: 'black',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400
      },

      '& .MuiTab-labelIcon': {
        color: 'black'
      },

      '& .MuiTab-textColorInherit': {
        textColor: 'black'
      }
      ,

      '& .MuiTab-textColorPrimary': {
        textColor: 'black'
      }
      ,

      '& .MuiTab-textColorSecondary': {
        textColor: 'black'
      },
      '& svg': {
        color: theme.palette.secondary.main
      },
      '& .MuiTabs-indicator': {
        display: 'none'
      }
    },
    minimizedContainer: {
      // display: 'flex',
      // flexDirection: 'row',
      minWidth: '0px !important',
      maxWidth: '0px !important',
    },
    // regularContainer: {
    //   display: 'flex', flexDirection: 'row',
    // }
    tabMenuItem: {
      height: 0,
      borderRadius: '8px !important',
      '&.Mui-selected': {
        color: 'black !important',
        backgroundColor: fade(theme.palette.primary.main, 0.2),
      },
      // '&.Mui-selected svg': {
      //   color: theme.palette.secondary.main
      // },
    }
  }));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, matches, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={matches ? value == 2 ? { maxHeight: "500px" } : { maxHeight: "250px" } : {}}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: "100%" }}>
          {/* // <Box sx={{ p: 3 }}> */}
          {/* <Typography> */}
          {children}
          {/* </Typography> */}
          {/* // </Box> */}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface Props {
  setMinimized: (isMinimized: boolean) => void;
  isMinimized: boolean;
}

export default function SidebarTabs({ setMinimized,
  isMinimized,
}: Props) {
  const history = useHistory();
  const [tagsSubTab, setTagsSubTab] = React.useState('Tags');
  const [sdkModeDialogOpen, setSdkModeDialogOpen] = React.useState(false);

  const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  const primaryUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.primaryUseCase);
  const currentSidebarIndex = useSelector<AppState, SIDEBAR_INDEX>(({ home }) => home.currentSidebarIndex);
  // const showPropertiesPanel = useSelector<AppState, boolean>(({ threeD }) => threeD.showPropertiesPanel);
  const currentSpaceDid = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.did || ''
  );
  const projectDetail = useSelector<AppState, ProjectObj | null>(({ projects }) => projects.projectDetail);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();

  const handleSidebarTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // setSelectedTabIndex(newValue);
    // dispatch({ type: 'SET_CURRENT_SIDEBAR_INDEX', payload: newValue });

    if (newValue == SIDEBAR_INDEX.SDK_MODE) {
      setSdkModeDialogOpen(true);
    } else {
      dispatch(setCurrentSidebar(newValue));
      if (newValue == SIDEBAR_INDEX.TASKS) {
        history.push(`/home/space/${currentSpaceDid}/project/${projectDetail?.did}/tasks`);
      } else {
        history.push(`/home/space/${currentSpaceDid}/project/${projectDetail?.did}`);
      }
    }
  };

  const presentationMode = useSelector<AppState, boolean>(({ layer }) => layer.presentationMode);
  if (currentSidebarIndex !== SIDEBAR_INDEX.LAYERS) {
    !!store.getState().layer.currentLesson && dispatch(onStopLesson());
  }

  const classes = useStyles();

  return (console.log(`%c[st] rendering SidebarTabs`, 'color: orange;'),
    <Box
      id="app-sidebar"
      flexDirection={{ xs: 'column', sm: 'row' }}
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        //   height: 224
      }}
    >

      <SDKSelector sdkModeDialogOpen={sdkModeDialogOpen} setSdkModeDialogOpen={setSdkModeDialogOpen}
        onSelect={(mode) => {
          // dispatch(setSDKMode(mode))

          if (!store.getState().layer.currentLesson) {
            alert('Please open an experience first!');
          } else {
            store.getState().home.currentSpace && projectDetail?.did && store.getState().layer.currentLesson?.did && history.push({
              pathname:
                `/home/space/${store.getState().home.currentSpace?.did}/project/${projectDetail?.did}/layers/${store.getState().layer.currentLesson?.did}`, search: '?mode=present&viewIn=' + mode
            });
            window.location.reload();
          }
        }

        } />

      {React.useMemo(() => <PropPanelWrapper />, [])}
      {React.useMemo(() => <PoseList />, [])}
      {/* </TabPanel> */}
      {spaceRole && canEditSpace(spaceRole) && currentSidebarIndex.valueOf() == SIDEBAR_INDEX.VARIABLES
        && <Variables
          isVariablesOpen={currentSidebarIndex == SIDEBAR_INDEX.VARIABLES}
          onCloseVariables={() => dispatch({ type: SET_CURRENT_SIDEBAR_INDEX, payload: SIDEBAR_INDEX.NONE })}
        />}

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.TAGS} matches={matches}>
        <div style={{ backgroundColor: theme.palette.background.default, height: "100%", overflow: 'hidden' }}>
          <TabHeader title='Tags' componentsBefore={[<TagOverflowMenu />]} />
          <TagSidebar />
        </div>
      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.THREED} matches={matches}>
        <Scrollbar>

          <div style={{ backgroundColor: theme.palette.background.default, maxHeight: "100%", height: '100%' }}>
            <TabHeader title='3D' />

            {React.useMemo(() =>
              <ThreeDSidebar />
              , [
                // showPropertiesPanel
              ]
            )}
          </div>
        </Scrollbar>
      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.LAYERS} matches={matches}>
        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>

          {<TabHeader title={store.getState().home.currentSpace?.primaryUseCase?.layerTermPlural || ''} />}
          {/* {showPropertiesPanel && <PropPanel />} */}
          {<LessonSidebar presentationMode={presentationMode} setMinimized={setMinimized} isMinimized={isMinimized} />}

        </div>
      </TabPanel>
      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.PEOPLE} matches={matches}>

        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>
          <TabHeader title='People' />
          <PeopleSidebar />
        </div>

      </TabPanel>
      {/* <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.CATALOG} matches={matches}>
        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>
          <TabHeader title='Catalog' />
          {React.useMemo(() =>
          // React.lazy
          // (<CatalogSidebar />),
          (<div style={{
            minWidth: '320px',
            maxWidth: '320px',
            maxHeight: '100%',
            overflowY: 'scroll',
            // overflowX: 'hidden',
            textAlign: 'center'
          }}>
            <Spacer y={4} />
            <Typography variant='h6'> Search Sketchfab</Typography>

            <ThirdPartyImporter /></div>),
            [])}
        </div>
      </TabPanel> */}

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.TASKS} matches={matches}>
        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>

          <TabHeader title='Tasks' helpBadge={<RichHelpBadge title="Manage Tasks for the selected project in this space"
            content={[{
              text: `Create tags with type 'Task' and manage status, deadlines and assignees and keep all your communication in one place with comments!`
            },
            { text: `When you are in the Task Sidebar, the space only shows Task tags` },
            {
              text: "To view all your Task lists, scroll to the right in the sidebar",
            }
            ]}
          >
          </RichHelpBadge>}
          />


          <TaskSidebar />
        </div>

      </TabPanel>

      <TabPanel value={currentSidebarIndex.valueOf()} index={SIDEBAR_INDEX.DATA} matches={matches}>
        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>
          <TabHeader title='Data' />
          <DataSidebar />
        </div>

      </TabPanel>

      {/* <TabPanel
      value={tagsViewModeSecondary}
      index={SIDEBAR_INDEX.DATA} matches={matches}>

        <div style={{ backgroundColor: theme.palette.background.default, height: "100%" }}>
          <DataSidebar />
        </div>

      </TabPanel> */}


      <Tabs
        orientation={matches ? "horizontal" : "vertical"}
        variant={matches ? "scrollable" : "standard"}
        value={(currentSidebarIndex == SIDEBAR_INDEX.NONE) ? false : currentSidebarIndex.valueOf()}
        centered={true}
        onChange={handleSidebarTabChange}
        aria-label="Sidebar tabs"
        className={clsx(classes.tabRoot, presentationMode ? classes.minimizedContainer : '')}
        style={{ position: 'relative', right: '0px' }}

      // sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab value={SIDEBAR_INDEX.TAGS} className={clsx('item-hover', classes.tabMenuItem)} icon={<TurnedInRoundedIcon />} label="Tags" {...a11yProps(SIDEBAR_INDEX.TAGS)} />
        <Tab value={SIDEBAR_INDEX.THREED} className={clsx('item-hover', classes.tabMenuItem)} icon={<ThreeDIcon2Svg style={{ fill: theme.palette.secondary.main, height: 23 }} />} label="3D" {...a11yProps(SIDEBAR_INDEX.THREED)} />
        <Tab value={SIDEBAR_INDEX.LAYERS} className={clsx('item-hover', classes.tabMenuItem)} icon={<LayersRoundedIcon />} label={primaryUseCase?.layerTermPlural || 'Experiences'} {...a11yProps(SIDEBAR_INDEX.LAYERS)} />
        <Tab value={SIDEBAR_INDEX.VARIABLES} disabled={!canEditSpace()} className={clsx('item-hover', classes.tabMenuItem)} icon={<FunctionsIcon />} label="Variables" {...a11yProps(SIDEBAR_INDEX.VARIABLES)} />

        <Divider />
        <Tab value={SIDEBAR_INDEX.TASKS} disabled={!canEditSpace()} className={clsx('item-hover', classes.tabMenuItem)} icon={<PlaylistAddCheckRoundedIcon />} label="Tasks" {...a11yProps(SIDEBAR_INDEX.TASKS)} />
        {/* <Tab value={SIDEBAR_INDEX.CATALOG} className={clsx('item-hover', classes.tabMenuItem)} icon={<WeekendRoundedIcon />} label="Catalog" {...a11yProps(SIDEBAR_INDEX.CATALOG)} /> */}

        <Tab value={SIDEBAR_INDEX.PEOPLE} disabled={!canEditSpace()} className={clsx('item-hover', classes.tabMenuItem)} icon={<PeopleAltRoundedIcon />} label="People" {...a11yProps(SIDEBAR_INDEX.PEOPLE)} />

        {isUserAdmin() && <Tab value={SIDEBAR_INDEX.DATA} className={clsx('item-hover', classes.tabMenuItem)} icon={<DownloadingIcon />} label="Data" {...a11yProps(SIDEBAR_INDEX.DATA)} />}
        {<Tab value={SIDEBAR_INDEX.SDK_MODE} className={clsx('item-hover', classes.tabMenuItem)} icon={<ViewInArIcon />} label="View in" {...a11yProps(SIDEBAR_INDEX.SDK_MODE)} />}

      </Tabs>

    </Box >
  );
}

export function TabHeader({ title, componentsBefore, helpBadge }: { title: string, componentsBefore?: JSX.Element[], helpBadge?: JSX.Element }) {
  const dispatch = useDispatch();
  const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
  let styleProps = { position: 'relative', right: 0 } as any;
  currentLesson && (styleProps['left'] = '90%');

  return currentLesson ? null : <div
    style={{
      display: 'flex', flexDirection: 'row',
      // justifyContent: 'flex-start',
      alignItems: 'center'
    }}
  >
    {/* <Spacer y={4} /> */}

    <Box display='inline-flex' style={{ alignItems: 'center', width: '100%', justifyContent: 'center' }}>
      <Box
        style={{ width: '100%', textAlign: 'center', }}
      ><Typography
        variant='h6'
      >
          {title}
        </Typography>
      </Box>
      {helpBadge}
    </Box>
    {componentsBefore}
    <Box style={{ float: 'right' }}>
      <IconHover tooltip="Close" iconProps={{ color: '#6f6f6f' }} styleProps={styleProps}>
        <Icons.CloseRounded onClick={() => dispatch(setCurrentSidebar(SIDEBAR_INDEX.NONE))} />
      </IconHover>
    </Box>
  </div>
}

export function SDKSelector({ sdkModeDialogOpen, setSdkModeDialogOpen, onSelect }: {
  sdkModeDialogOpen: boolean,
  setSdkModeDialogOpen: (open: boolean) => void,
  onSelect(mode: SpatialThinkSDKMode | ExternalSdkMode): void
}) {

  // const [showError, setShowError] = React.useState(false);

  const dispatch = useDispatch();
  return (<ConfirmationDialog
    open={sdkModeDialogOpen}
    onDeny={() => { setSdkModeDialogOpen(false) }}
    onConfirm={() => { }}
    // onDeny={() => alert("oclod")}
    // onConfirm={onClose}
    title={""}
    dialogTitle={`View in`}
    yesLabel={"Confirm"}
    noLabel={"Cancel"}
    dark={true}
    hideYesButton={true}
  // bg={{ darkTranslucentBg: true }}
  >
    {/* {showError && <Alert severity="error">Please set a Default Viewpoint for this space to view it in AR</Alert>} */}
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      {[{ key: 'AR', value: SpatialThinkSDKMode.AR },
      { key: 'VR', value: SpatialThinkSDKMode.VR },
      { key: 'Plain Scene', value: SpatialThinkSDKMode.Plain },
      { key: 'Matterport', value: ExternalSdkMode.MP }].map((mode: any, i: number) =>
        <Box key={i} style={{ cursor: 'pointer' }} onClick={() => {
          // dispatch(setSDKMode(mode.value));
          // let dp = store.getState().home.currentSpace?.poses?.find(p => !!p.isDefault);
          // if (!dp && mode.value == SpatialThinkSDKMode.AR) {
          //   setShowError(true);
          // } else {
            // setShowError(false);
            onSelect(mode.value)
            setSdkModeDialogOpen(false);
          // }
        }}>
          <>

            <ItemHover>
              <Card key={i}>
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    {mode.key}
                  </Typography>
                </CardContent>
              </Card>
            </ItemHover>
          </>
        </Box>
      )
      }
    </Box>
  </ConfirmationDialog>)

}