import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  onDeleteSelectedSection,
  onEditProjectSection,
} from '../../../../../redux/actions';
import {useDispatch} from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationDialog from '../../../../../@crema/core/ConfirmationDialog';
import CheckIcon from '@material-ui/icons/Check';
import IntlMessages from '../../../../../@crema/utility/IntlMessages';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import {Fonts} from '../../../../../shared/constants/AppEnums';
import {TaskSectionObj} from '../../../../../types/models/apps/ProjectBoard';
import { Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 8,
    minHeight: (props: {isEditListName: boolean}) =>
      props.isEditListName ? '5.5rem' : '3.5rem',
  },
  pointer: {
    cursor: 'pointer',
  },
  marginLeft12: {
    marginLeft: 12,
  },
  textFieldRoot: {
    marginTop: 0,
  },
}));

interface ListHeaderProps {
  list: TaskSectionObj;
  projectId: string;
}

const ListHeader: React.FC<ListHeaderProps> = ({list, projectId}) => {
  const dispatch = useDispatch();

  const [isEditListName, setEditListName] = useState<boolean>(false);

  const [editedListName, setEditedListName] = useState<string>('');

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const onDeleteProjectList = () => {
    dispatch(onDeleteSelectedSection(projectId, list.id));
    setDeleteDialogOpen(false);
  };

  const onEditButtonClick = () => {
    setEditedListName(list.name);
    setEditListName(!isEditListName);
  };

  const onEditListName = () => {
    if (editedListName !== '') {
      const editedList = {...list, name: editedListName};
      dispatch(onEditProjectSection(projectId, editedList));
      setEditListName(false);
    }
  };

  const keyPress=(e:any)=>{
    if(e.keyCode == Keys.RETURN){
      onEditListName();
    }
  }

  const classes = useStyles({isEditListName});

  return (
    <Card className={classes.root}>
      <Box display='flex' alignItems='center'>
        {!isEditListName ? (
          <>
            <Box component='h5' fontSize={15} fontWeight={Fonts.MEDIUM}>
              {list.name}
            </Box>
            <Box ml='auto' display='flex' alignItems='center'>
              <EditIcon
                className={clsx(classes.marginLeft12, classes.pointer)}
                onClick={onEditButtonClick}
              />
              <DeleteIcon
                className={clsx(classes.marginLeft12, classes.pointer)}
                onClick={() => setDeleteDialogOpen(true)}
              />
            </Box>
          </>
        ) : (
          <>
            <Box mr={3}>
              <TextField
                fullWidth
                className={classes.textFieldRoot}
                margin='normal'
                label={<IntlMessages id='projectboard.listTitle' />}
                value={editedListName}
                onChange={event => setEditedListName(event.target.value)}
                onKeyDown={keyPress}
              />
            </Box>
            <Box ml='auto' display='flex' alignItems='center'>
              <CheckIcon className={classes.pointer} onClick={onEditListName} />
              <CloseIcon
                className={clsx(classes.marginLeft12, classes.pointer)}
                onClick={() => setEditListName(false)}
              />
            </Box>
          </>
        )}
      </Box>

      {isDeleteDialogOpen ? (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          onDeny={setDeleteDialogOpen}
          onConfirm={onDeleteProjectList}
          title={<IntlMessages id='projectboard.deleteMessage' />}
          dialogTitle={<IntlMessages id='common.deleteItem' />}
        />
      ) : null}
    </Card>
  );
};

export default ListHeader;
