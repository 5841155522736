import { store } from "App";
import { GizmoTools } from "modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { goToPose } from "modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils";
import { fetchError, hideAlert, showAlert, showMessage } from "redux/actions";
import { SIDEBAR_INDEX } from "types/models/home/HomeApp";
import { showGizmo } from "redux/actions/ThreeD";
import { SET_SELECTED_NODE_ID, SHOW_PROP_PANEL } from "types/actions/ThreeD.actions";
import { TemporalOutlineElement } from "../../components/PostProcess/OutlineComponent";
import QueueScheduler from "../../Tools/QueueScheduler";
import Utils from "../../Tools/Utils";
import PlaceObjectBehaviors from "../core/PlaceObjectBehaviors";
import { SimulationMode } from "../core/RenderingAndPlaceObjectStateSystem";
import Simulation from "../core/Simulation";
import InputSubSystem from "../input/InputSubSystem";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import NodeStorage from "../storageAndSerialization/NodeStorage";
import PropertiesPanel, { PropertiesPanelMode, UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";
import { areNodesSpatialThinkSDK, getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { onUpdateTagGroup } from "redux/actions/Step";
import { ArrowFlowMarker } from "modules/home/models";
import { setSpaceModelsList } from "redux/actions/Home";
import { makeFireParticleSystem } from "../../components/ParticleSystem/FireParticleSystemComponent";
import ExpandedModel from "modules/home/SpaceDetail/SpaceView/Sidebar/ThreeDSidebar/ExpandedModel";

export default class PropertiesPanelWithSimulationFunctionality extends PlaceObjectBehaviors {
    propertiesPanel: PropertiesPanel;

    private _propertyUpdateQueueScheduler: QueueScheduler<ISceneNode>;

    constructor() {
        super();
        this.propertiesPanel = new PropertiesPanel();
        this._propertyUpdateQueueScheduler = new QueueScheduler<ISceneNode>(this.selectNodeWithPropertiesUpdate.bind(this), 10);
    }

    // public focusOnLastNodeAdded(){
    //     let lastNode = this.sceneLoader.getLastNodeAdded();
    //     lastNode && lastNode.userData && this.handleModelClick(lastNode.userData.id);
    // }

    public async handleModelClick(nodeId: string): Promise<boolean> {
        if (!store.getState().layer.presentationMode
            // && (store.getState().home.currentSidebarIndex !== SIDEBAR_INDEX.DATA)
        ) {
            store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
            this.propertiesPanel.hidePropertiesPanel();
            if (!this.spaceModels() || this.spaceModels().size == 0 || !this.spaceModels().has(nodeId)) {
                return false;
            }
            let node = this.spaceModels().get(nodeId).nodeRef;
            let meshes = Utils.FindAllMeshesAndLineSegments(node);
            //this.camera.LookAtNode(node, () => this.PropertyUpdateQueueScheduler.addQueueElement(node, true));

            //TODO save pose with node - problematic bc update node happens too often - need to check
            // if (node.userData.pose) {
            //     goToPose(node.userData.pose);
            //     console.log(`[st] [model] went to pose`)
            //     this.PropertyUpdateQueueScheduler.addQueueElement(node, true);
            //     this.outlineComponent.temporalOutlines.set(nodeId, new TemporalOutlineElement(1750, meshes!));
            //     store.dispatch({ type: SHOW_PROP_PANEL, payload: true });
            // }
            //else {
            this.camera?.NavigateToNode(node, () => {
                this.PropertyUpdateQueueScheduler.addQueueElement(node, true);
                this.outlineComponent.temporalOutlines.set(nodeId, new TemporalOutlineElement(1750, meshes!));
                store.dispatch({ type: SHOW_PROP_PANEL, payload: true });
            });

            // }
            Utils.EnableCollidersOnNode(node);

            Utils.SetVisibility(true, node, meshes);

            console.log(`Model Clicked `, node.userData);

            if (!store.getState().layer.currentLesson?.id && !node.userData?.noClickVarChange) {
                // if (areNodesSpatialThinkSDK()) {
                //     Simulation.instance.processNodeClickOutsideLessonST(node);
                // } else {
                Simulation.instance.processNodeClickOutsideLesson(node);
                // }
            }

            this.cancelPlaceMode();
            this.cancelAddObject();
        }


        return true;
        //this.propertiesPanel.hidePropertiesPanel();
        //this.resetSimulationMode();
        //this.propertyUpdateQueueScheduler.addQueueElement(node, true);
    }

    resizeModel(node: ISceneNode) {


        // // if (node.userData.nameToShow == 'Foxy-plush model 2') {


        //     // let n: ISceneNode;
        //     let c = node.components.find((c: any) => c.instance.componentType == 'mp.gltfLoader')
        //     // Yep. This code takes a node and finds its size and centerpoint, then rescales it so the max extent is 1 and its centered at 0,0,0, and above the ground on the y axis.

        //     var mroot: THREE.Scene = //Simulation.instance.scene.getScene();
        //         // EulerIntegrator.instance?.scene;
        //         c.instance.context.scene;
        //     if (!!mroot) {
        //         var bbox = new THREE.Box3().setFromObject(mroot);
        //         var cent = bbox.getCenter(new THREE.Vector3());
        //         var size = bbox.getSize(new THREE.Vector3());


        //     //     //Rescale the object to normalized space
        //         var maxAxis = Math.max(size.x, size.y, size.z);
        //         node.scale.x = 5000/maxAxis;
        //         node.scale.y = 5000/maxAxis;
        //         node.scale.z = 5000/maxAxis;
        //     //     let meshes = Utils.FindAllMeshesAndLineSegments(node);
        //     //     if(!!meshes){
        //     //         meshes.forEach((mesh: THREE.Object3D )=> {
        //     //         mesh.scale.multiplyScalar(1.0 / (maxAxis/ 5000));
        //     //         // bbox.setFromObject(mroot);
        //     //         // bbox.getCenter(cent);
        //     //         // bbox.getSize(size);
        //     //         // //Reposition to 0,halfY,0
        //     //         // mroot.position.copy(cent).multiplyScalar(-1);
        //     //         // mroot.position.y -= (size.y * 0.5);
        //     //     })}

        //     }
    }

    async addObject(objectOrObjectName: string, mode: SimulationMode) {

        if (this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Adding ||
            this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Editing) {
            console.log("TODO: Warning you can't add anything while in event add mode for the properties panel");
            store.dispatch("You can't add anything while in behavior add mode!");

        } else {

            if (await NodeStorage.loadNode(objectOrObjectName)) { //save node that was just added to the scene to the database
                let node = Simulation.instance.sceneLoader.getLastNodeAdded();
                NodeStorage.storeNode(node).then(() => {

                    // If we were in a a lesson-> Step, add this Node to the step automatically
                    node = Simulation.instance.sceneLoader.getLastNodeAdded();
                    if (store.getState().layer.currentLesson && store.getState().layer.currentTagGroupId
                        && node.userData.type !== UserDataTypes.internalObject && node.userData.type !== UserDataTypes.arrowFlowMarker) {
                        let tg = getCurrentTagGroup();

                        if (node.userData?.id && tg) {
                            tg.tagIds = (tg.tagIds || []).concat([node.userData?.id])
                            store.dispatch(onUpdateTagGroup(tg));
                        }
                    }
                })

                // console.log("[Simulation] AddObject: " + objectName);
                store.dispatch(showMessage("Move cursor into the Space area to add!"));
                this.simulationMode = mode;

                this.alreadyLocked = this.lockViewState[0];

                // InputSubSystem.input.setMatterPortCameraMovement(false);
                this.lockViewState[1](true);
            } else {
                console.error("[Simulation] Failed to execute AddObject: " + objectOrObjectName);
                store.dispatch(fetchError("Failed to add object!"));
                //dispatch(showMessage("Failed to add object!"))
            }
        }
    }

    public async selectNodeWithPropertiesUpdate(node: ISceneNode): Promise<boolean> {

        let tempOldLastSelectedNode = this.lastSelectedNode;

        //this.root.lastSelectedNode = this.node;
        await this.selectNode(node);
        //this.root.lastSelectedNode.position
        /*
        var meshes = Utils.FindAllMeshes(this.node);
        if(meshes) {
            Simulation.instance.outlineComponent.SelectedObjects = meshes;
        }*/

        if (this.propertiesPanel.checkIfNodeHasProperties(node)) {

            let shouldShowPropertiesPanel = this.propertiesPanel.showPropertiesPanel();
            if (shouldShowPropertiesPanel) {
            } else {
                this.propertiesPanel.updateClickEventAction(node);
                this.selectNode(tempOldLastSelectedNode!);
            }
        } else {
            if (this.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.CanAdd) {
            } else {
                this.selectNode(tempOldLastSelectedNode!);
            }
        }

        return true;
    }

    resetSimulationMode(): void {

        if (this.getSimulationMode() == SimulationMode.ADD_OBJECT || this.getSimulationMode() == SimulationMode.ADD_INTERNAL_OBJECT) {
            setTimeout(this.resumeMatterPortCameraMovement.bind(this), 1000);
            this.propertiesPanel.saveLastNode(true, this.sceneLoader.getLastNodeAdded(), true);
            //this.propertiesPanel.refreshReactStateUI();
        } else if (this.getSimulationMode() == SimulationMode.PLACE_MODE) {
            this.cancelPlaceMode();
            if (this.lastSelectedNode) {
                this.propertiesPanel.saveLastNode(true, this.lastSelectedNode, true);
            }
        } else if (this.getSimulationMode() == SimulationMode.GRAB_MODE) {
            setTimeout(this.resumeMatterPortCameraMovement.bind(this), 1000);
        }
        this.simulationMode = SimulationMode.NONE;
    }

    get PropertyUpdateQueueScheduler(): QueueScheduler<ISceneNode> {
        return this._propertyUpdateQueueScheduler;
    }


}
