export const arraySchema = {
    "type": "object",
    "properties": {
        "comments": {
            "type": "array",
            "items": {
                "type": "object",
                "properties": {
                    "date": {
                        "type": "string",
                        "format": "date"
                    },
                    "message": {
                        "type": "string",
                        "maxLength": 5
                    },
                    "enum": {
                        "type": "string",
                        "enum": [
                            "foo",
                            "bar"
                        ]
                    }
                }
            }
        }
    }
}

export const arrayUiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "Control",
            "scope": "#/properties/comments"
        }
    ]
}

export const arrayData = {
    "comments": [
      {
        "date": "2001-09-11",
        "message": "This is an example message"
      },
      {
        "date": "2022-08-23",
        "message": "Get ready for booohay"
      }
    ]
  }
export const basicSchema = {
    "type": "object",
    "properties": {
        "name": {
            "type": "string",
            "minLength": 3,
            "description": "Please enter your name"
        },
        "vegetarian": {
            "type": "boolean"
        },
        "birthDate": {
            "type": "string",
            "format": "date"
        },
        "nationality": {
            "type": "string",
            "enum": [
                "DE",
                "IT",
                "JP",
                "US",
                "RU",
                "Other"
            ]
        },
        "personalData": {
            "type": "object",
            "properties": {
                "age": {
                    "type": "integer",
                    "description": "Please enter your age."
                },
                "height": {
                    "type": "number"
                },
                "drivingSkill": {
                    "type": "number",
                    "maximum": 10,
                    "minimum": 1,
                    "default": 7
                }
            },
            "required": [
                "age",
                "height"
            ]
        },
        "occupation": {
            "type": "string"
        },
        "postalCode": {
            "type": "string",
            "maxLength": 5
        }
    },
    "required": [
        "occupation",
        "nationality"
    ]
}
export const basicUiSchema = {
    "type": "VerticalLayout",
    "elements": [
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/name"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/personalData/properties/age"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/birthDate"
                }
            ]
        },
        {
            "type": "Label",
            "text": "Additional Information"
        },
        {
            "type": "HorizontalLayout",
            "elements": [
                {
                    "type": "Control",
                    "scope": "#/properties/personalData/properties/height"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/nationality"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/occupation",
                    "suggestion": [
                        "Accountant",
                        "Engineer",
                        "Freelancer",
                        "Journalism",
                        "Physician",
                        "Student",
                        "Teacher",
                        "Other"
                    ]
                }
            ]
        }
    ]
}
export const basicData = {
    "name": "John Doe",
    "vegetarian": false,
    "birthDate": "1985-06-02",
    "personalData": {
      "age": 34
    },
    "postalCode": "12345"
  }

export const dataGridSchema = {

    "type": "array",
    "items": {
        "$ref": "#/definitions/Welcome4Element"
    },
    "definitions": {
        "Welcome4Element": {
            "type": "object",
            "additionalProperties": false,
            "properties": {
                "Commodity": {
                    "type": "string"
                },
                "Quantity": {
                    "type": "string"
                },
                "Filled Quantity": {
                    "$ref": "#/definitions/FeeRate"
                },
                "Is Filled": {
                    "$ref": "#/definitions/IsFilled"
                },
                "Status": {
                    "$ref": "#/definitions/Status"
                },
                "Unit Price": {
                    "$ref": "#/definitions/FeeRate"
                },
                "Unit Price Currency": {
                    "type": "string"
                },
                "Sub Total": {
                    "type": "string"
                },
                "Fee Rate": {
                    "$ref": "#/definitions/FeeRate"
                },
                "Fee Amount": {
                    "type": "string"
                },
                "incoTerm": {
                    "$ref": "#/definitions/IncoTerm"
                }
            },
            "required": [
                "Commodity",
                "Fee Amount",
                "Fee Rate",
                "Filled Quantity",
                "Is Filled",
                "Quantity",
                "Status",
                "Sub Total",
                "Unit Price",
                "Unit Price Currency",
                "incoTerm"
            ],
            "title": "Welcome4Element"
        },
        "FeeRate": {
            "anyOf": [
                {
                    "type": "number"
                },
                {
                    "type": "string"
                }
            ],
            "title": "FeeRate"
        },
        "IsFilled": {
            "type": "string",
            "enum": [
                "no"
            ],
            "title": "IsFilled"
        },
        "Status": {
            "type": "string",
            "enum": [
                "",
                "Rejected",
                "Open",
                "Filled",
                "PartiallyFilled"
            ],
            "title": "Status"
        },
        "IncoTerm": {
            "type": "string",
            "enum": [
                "",
                "DAP (Delivered at Place)",
                "FCA (Free Carrier)",
                "EXW (Ex Works)",
                "DPU (Delivered at Place Unloaded)",
                "DDP (Delivered Duty Paid)",
                "FAS (Free Alongside Ship)",
                "CPT (Carriage Paid To)"
            ],
            "title": "IncoTerm"
        }
    }
}

export const emptySchema = {

}

export const emptyUiSchema = {

}

export const dataForEmptySchema = [
    {
      "Commodity": "Adzuki bean",
      "Quantity": "",
      "Filled Quantity": "",
      "Is Filled": "no",
      "Status": "",
      "Unit Price": "",
      "Unit Price Currency": "",
      "Sub Total": "",
      "Fee Rate": "",
      "Fee Amount": "",
      "incoTerm": ""
    },
    {
      "Commodity": "",
      "Quantity": "85,032",
      "Filled Quantity": 0.894,
      "Is Filled": "no",
      "Status": "Rejected",
      "Unit Price": 87.61,
      "Unit Price Currency": "MXN",
      "Sub Total": "7,449,653.52",
      "Fee Rate": 0.322,
      "Fee Amount": "2,398,788.433",
      "incoTerm": "DAP (Delivered at Place)"
    },
    {
      "Commodity": "",
      "Quantity": "52,995",
      "Filled Quantity": 0.575,
      "Is Filled": "no",
      "Status": "Open",
      "Unit Price": 66.99,
      "Unit Price Currency": "BRL",
      "Sub Total": "3,550,135.05",
      "Fee Rate": 0.364,
      "Fee Amount": "1,292,249.158",
      "incoTerm": "FCA (Free Carrier)"
    },
    {
      "Commodity": "",
      "Quantity": "87,576",
      "Filled Quantity": 0.026,
      "Is Filled": "no",
      "Status": "Filled",
      "Unit Price": 67.65,
      "Unit Price Currency": "GBP",
      "Sub Total": "5,924,516.4",
      "Fee Rate": 0.266,
      "Fee Amount": "1,575,921.362",
      "incoTerm": "EXW (Ex Works)"
    },
    {
      "Commodity": "",
      "Quantity": "36,590",
      "Filled Quantity": 0.679,
      "Is Filled": "no",
      "Status": "Open",
      "Unit Price": 92.89,
      "Unit Price Currency": "CHF",
      "Sub Total": "3,398,845.1",
      "Fee Rate": 0.101,
      "Fee Amount": "343,283.355",
      "incoTerm": "DPU (Delivered at Place Unloaded)"
    },
    {
      "Commodity": "",
      "Quantity": "55,133",
      "Filled Quantity": 0.421,
      "Is Filled": "no",
      "Status": "PartiallyFilled",
      "Unit Price": 81.55,
      "Unit Price Currency": "AUD",
      "Sub Total": "4,496,096.15",
      "Fee Rate": 0.355,
      "Fee Amount": "1,596,114.133",
      "incoTerm": "DDP (Delivered Duty Paid)"
    },
    {
      "Commodity": "",
      "Quantity": "39,956",
      "Filled Quantity": 0.511,
      "Is Filled": "no",
      "Status": "Open",
      "Unit Price": 92.57,
      "Unit Price Currency": "THB",
      "Sub Total": "3,698,726.92",
      "Fee Rate": 0.252,
      "Fee Amount": "932,079.184",
      "incoTerm": "DPU (Delivered at Place Unloaded)"
    },
    {
      "Commodity": "",
      "Quantity": "35,769",
      "Filled Quantity": 0.821,
      "Is Filled": "no",
      "Status": "PartiallyFilled",
      "Unit Price": 85.74,
      "Unit Price Currency": "THB",
      "Sub Total": "3,066,834.06",
      "Fee Rate": 0.111,
      "Fee Amount": "340,418.581",
      "incoTerm": "EXW (Ex Works)"
    },
    {
      "Commodity": "Cocoa",
      "Quantity": "",
      "Filled Quantity": "",
      "Is Filled": "no",
      "Status": "",
      "Unit Price": "",
      "Unit Price Currency": "",
      "Sub Total": "",
      "Fee Rate": "",
      "Fee Amount": "",
      "incoTerm": ""
    },
  ]