import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { CremaTheme } from '../../../types/AppContextPropsType';
import { Button } from '@material-ui/core';

type Variant = 'error' | 'info' | 'success' | 'warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};
const useStyles1 = makeStyles((theme: CremaTheme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface AppSnackbarProps {
  className?: string;
  message: string;
  variant: Variant;
  verticalPosition?: 'bottom' | 'top';
  horizontalPosition?: "left" | "right" | "center";

  [x: string]: any;
}

const AppSnackbar: React.FC<AppSnackbarProps> = ({
  className,
  message,
  variant,
  verticalPosition = 'bottom',
  horizontalPosition = 'center',
  ...other
}) => {
  const classes = useStyles1();

  const [open, setOpen] = React.useState(true);
  const Icon = variantIcon[variant];

  const onClose = () => {
    setOpen(false);
  };

  return (console.log(`%c[st] rendering MessageView - ${message}`, 'color: orange;'),
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: horizontalPosition,
      }}

      open={open}
      autoHideDuration={6000}
      onClose={onClose}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
          // <Button
          //   aria-label='dontshowagain'
          //   color='secondary'
          //   onClick={() => {
          //     //savemessage in Users.
          //     onClose();
          //   }}>
          //   Don't Show Again
          // </Button>,
        ]}
        {...other}
      />
    </Snackbar>
  );
};
export default AppSnackbar;
