import ManagedObject from './ManagedObject';
import DoublyLinkedList from './DoublyLinkedList';
import INodeRefHolder from './INodeRefHolder';

export default class ListNode<T> extends ManagedObject {
    public next:ListNode<T>|null = null;
    public prev:ListNode<T>|null = null;
    //public listRef:DoublyLinkedList<Y>|null = null;
    
    constructor(public data: T) {
        super();
    }
}