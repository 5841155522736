import { BaseComponent } from './BaseComponent';
import { RenderingSystem } from '../Systems/RenderingSystem';
import TextureManager from '../../mp/core/craEngine/SubSystems/TextureManager';
import { store } from '../../App';
import Emitter, { EmitterType } from '../../mp/core/craEngine/SubSystems/ParticleEngine/Emitter';
import ShaderStringCache from '../../mp/core/craEngine/SubSystems/ParticleEngine/Shaders/ShaderStringCache';
import CircleEmitterDescriptor
    from '../../mp/core/craEngine/SubSystems/ParticleEngine/EmitterDescriptor/CircleEmitterDescriptor';
import GradientInterpolator from '../../mp/core/craEngine/SubSystems/ParticleEngine/Interpolators/GradientInterpolator';
import VectorGradientInterpolator
    from '../../mp/core/craEngine/SubSystems/ParticleEngine/Interpolators/VectorGradientInterpolator';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import * as THREE from 'three';
import { IParticleSystem } from '../../mp/core/craEngine/components/ParticleSystem/BaseParticleSystem';
import { Material } from 'three';
import {RaycastSystem} from '../Systems/RaycastSystem';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

interface Inputs {
    particleCount: number;
    velocityMin: { x: number; y: number; z: number },
    velocityMax: { x: number; y: number; z: number },
    gravity: { x: number; y: number; z: number },
    chaosMax: { x: number; y: number; z: number },
    chaos: boolean;
    particleSystemLife: number;
    boxVisible: boolean;
}

export class FireParticleSystemComponent extends BaseComponent implements IParticleSystem {
    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
        // throw new Error('Method not implemented.');
    }
    setNextInputValue(): void {
        // throw new Error('Method not implemented.');
    }
    inputs: Inputs = {
        particleCount: 5000,
        velocityMin: { x: 0, y: 0, z: 0 },
        velocityMax: { x: 0, y: 0, z: 0 },
        gravity: { x: 0, y: 0, z: 0 },
        chaosMax: { x: 0, y: 0, z: 0 },
        chaos: false,
        particleSystemLife: 1.25,
        boxVisible: true
    };
    public ignoreInMeshFindPopulation: boolean = true;
    private moved: boolean = false;
    private emitter: Emitter;
    private boxMesh: THREE.Mesh;

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "FireParticleSystemComponent";
    }

    onInit(userdata: { [p: string]: any }) {
        super.onInit(userdata);

        this.inputs.particleCount = userdata.particleCount;

        this.buildParticleSystem();
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onTick.bind(this));
        // this.node && this.setLoaded(true);
    }

    onTick(tickDelta: number) {
        let tickInSeconds = tickDelta;
        this.emitter.simulate(tickInSeconds);
    }

    buildParticleSystem(): void {
        //BoxGeometry (makes a geometry)
        var geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1);
        //Material to apply to the cube (green)

        let opacity = !store.getState().layer.presentationMode ? 0.5 : 0.01; //check the latest value every time
        // let opacity = this.inputs.boxVisible ? 0.5 : 0.01;
        var material = new THREE.MeshBasicMaterial({ color: 0xf35c14, transparent: true, opacity: opacity });
        //Applies material to BoxGeometry
        this.boxMesh = new THREE.Mesh(geometry, material);
        this.root.add(this.boxMesh);

        this.outputs.collider = this.boxMesh;
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.boxMesh);

        this.emitter = new Emitter(this, {
            emitterType: EmitterType.GlowyAdditive,
            particleTexture: '/assets/images/fireParticleTexture.png',
            vertexShader: ShaderStringCache.fireVert,
            fragmentShader: ShaderStringCache.fireFrag,
            particleCount: 5000,
            alphaTest: 0.5,
            damping: 0.997,
            spawnInterval: 0.1,
            emissionRate: 100,
            life: this.inputs.particleSystemLife,
            randomLife: 0,
            timeIntervalScale: 1,
            brownianMotion: false,
            brownianVector: new THREE.Vector3(10, 10, 10),
            emitterDescriptor: new CircleEmitterDescriptor(0.5, 1, new THREE.Vector3(0, 0.5, 0), new THREE.Vector3(1, 2.5, 1), new THREE.Vector3(-1, 0, -1),
                0, 20, -20),
        });

        //this.emitter.universalForces.push(new THREE.Vector3(0, -1, 0));
        // this.root.position.set(0, 0, 2);
        // this.root.rotation.setFromVector3(new THREE.Vector3(0, 0, 45));
        this.emitter.addUniversalForce(new THREE.Vector3(0, -1, 0));
        this.emitter.addAttractor({ position: new THREE.Vector3(0, 1, 0), mass: 5, radius: 5 });
        // this.emitter.addAttractor({ position: new THREE.Vector3(0, 3.75, 4), mass: 40, radius:3 });


        this.emitter.alphaGradientInterpolator = new GradientInterpolator([
            { gradient: this.inputs.particleSystemLife, interpolationMapping: 0 },
            { gradient: this.inputs.particleSystemLife * 0.5, interpolationMapping: 0.75 },
            { gradient: this.inputs.particleSystemLife * 0.25, interpolationMapping: 1 },
            { gradient: 0, interpolationMapping: 0 }]);

        this.emitter.sizeGradientInterpolator = new GradientInterpolator([
            { gradient: this.inputs.particleSystemLife, interpolationMapping: 0.2 },
            { gradient: this.inputs.particleSystemLife * 0.5, interpolationMapping: 0.01 },
            { gradient: 0, interpolationMapping: 0.0 }]);

        this.emitter.colorGradientInterpolator = new VectorGradientInterpolator([
            {
                gradient: this.inputs.particleSystemLife,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ffcd00')),
            },
            {
                gradient: this.inputs.particleSystemLife * 0.95,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ff7300')),
            },
            {
                gradient: this.inputs.particleSystemLife * 0.55,
                interpolationMapping: Utils.getColorAsVector(new THREE.Color('#ff0000')),
            },
            { gradient: 0, interpolationMapping: Utils.getColorAsVector(new THREE.Color('#000000')) }]);
        //window.addEventListener('pointerdown', this.onPointerDown);
    }

    public getEmitters(): Emitter[] {
        return [this.emitter];
    }

    onDestroy(): void {
        this.boxMesh.geometry.dispose();
        (this.boxMesh.material as Material).dispose();
        this.emitter.dispose();

        console.error('onDestroy not implemented');

    }
}