import React, { useEffect } from 'react';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgTransparent: {
    backgroundColor: 'transparent',
    '& .MuiToggleButton-root.Mui-selected':{
      backgroundColor: "#315f72",
      color: 'white'
    },
    '& .MuiToggleButton-root':{
      color: "#000000",
    },
    marginBottom: '10px',
  },
  btn:{
    '& .MuiToggleButton-root':{
      color: "#000000"
    }
  },
  gridContainer:{
    '& .MuiGrid-container':{
      flexWrap: 'nowrap'
    },
    '& .MuiGrid-root':{
      flexWrap: 'nowrap'
    }
  },
  gridContainerItem:{
    '& .MuiGrid-container':{
      flexWrap: 'nowrap'
    },
    '& .MuiGrid-root':{
      flexWrap: 'nowrap'
    }
  }
}));
export default function ToggleButtons(props) {
  const [value, setValue] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleChange(newValue);
  };
  const classes = useStyles(props);

  useEffect(() => {setValue(props.initialValue)}, [props])
  return (
    // <Grid container spacing={2} direction='column' alignItems='center' alignContent='flex-start' className={classes.gridContainer}>
      // <Grid item className={classes.gridContainerItem}>
        <ToggleButtonGroup
          className={classes.bgTransparent}
          size={props.size}
          value={value}
          exclusive
          onChange={handleChange}>
          {props.children.map((item, index) => <ToggleButton key={index} value={item} className={classes.btn}>
            {item}
          </ToggleButton>)}
        </ToggleButtonGroup>
    //   </Grid>

    // </Grid>
  );
}