import { BaseComponent } from './BaseComponent';
import { RenderingSystem } from '../Systems/RenderingSystem';
import * as THREE from 'three';
import { Texture, Vector2 } from 'three';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import { CanvasRenderer } from './CanvasTools/CanvasRenderer';
import QueueScheduler from '../../mp/core/craEngine/Tools/QueueScheduler';
import {RaycastSystem} from '../Systems/RaycastSystem';
import {UserDataProperties} from '../../mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

type Inputs = {
    fontSize: number;
    visible: boolean;
    opacity: number;
    color: number;
    text: string;
    borderColor: number;
    invertScale: boolean;
    //planeSize: { w:number, y:number };
    // noColliders: boolean;
}

export class TextBoxComponent extends BaseComponent {

    inputs: Inputs = {
        fontSize: 80,
        visible: true,
        opacity: 1,
        color: 0xffffff,
        text: "Hello World",
        borderColor: 0xffff00,
        invertScale: false,
        // noColliders: false
    };

    private canvasRendererBorder: CanvasRenderer;
    private canvasRendererBG: CanvasRenderer;
    private canvasTextRenderer: CanvasRenderer;

    private oldRootScale: THREE.Vector2;

    private rebuildMeshQueue: QueueScheduler<any>;
    // private _interactable: Interactable;

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "TextBoxComponent";

        this.oldRootScale = new THREE.Vector2(1, 1);
        // this.createMesh(new THREE.Vector2(1, 1), true);
        // this._interactable = new Interactable();
    }

    onInit(userdata: { [p: string]: any }) {
        super.onInit(userdata);
        this.buildTextures();
        // this.buildAllMeshes();
        this.inputs.text = userdata[UserDataProperties.textProperty];
        this.inputs.fontSize = userdata[UserDataProperties.fontSize];
        this.rebuildMeshQueue = new QueueScheduler<any>(this.buildAllMeshes.bind(this), 0.05);
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onTick.bind(this));
        // this._interactable.addAction(this.onClick.bind(this));

        // let grabbable = new Grabbable();
        // grabbable.addObject([this.root]);
        // this.node && this.setLoaded(true);

        this.onInputsUpdated(this.inputs, true);
    }


    onTick(deltaInSeconds: number) {
        // console.log(`rrr root scale: ${this.root.scale.x} ${this.root.scale.y}`);

        var rootScale = new THREE.Vector2(this.root.scale.x, this.root.scale.y);
        if (Math.abs(rootScale.x - this.oldRootScale.x) > 0.1 ||
            Math.abs(rootScale.y - this.oldRootScale.y) > 0.1) {
            this.oldRootScale.set(rootScale.x, rootScale.y);
            this.rebuildMeshQueue.addQueueElement({}, true);
            // this.buildAllMeshes();
        }
    }

    private buildAllMeshes(any: any | null = null): boolean {
        //First remove all meshes while disposing their resources
        this.clearMeshes();

        this.createMesh2(true, this.canvasRendererBorder.Texture, 0xffffff, 1, 10);
        let clickableMesh = this.createMesh2(true, this.canvasRendererBG.Texture, 0xffffff, 0.2, 10);
        this.createMesh2(true, this.canvasTextRenderer.Texture, 0xffffff, 1, 10, true, -100, 0, true);

        // clickableMesh.userData[Interactable.type] = this._interactable;
        this.outputs.collider = this.meshes[0];
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.meshes[0]);
        // this.node && this.setLoaded(true);
        return true;
    }

    private buildTextures() {
        this.canvasRendererBorder = new CanvasRenderer(1024, 1024);
        this.canvasRendererBorder.drawBorder(new Vector2(0, 0), new Vector2(1024, 1024), 5, 20, Utils.SimpleColorHexToString(this.inputs.borderColor));

        this.canvasRendererBG = new CanvasRenderer(256, 256);
        this.canvasRendererBG.fillColor(new Vector2(0, 0), new Vector2(256, 256), '#000000');

        this.canvasTextRenderer = new CanvasRenderer(1024, 1024);
        this.canvasTextRenderer.drawText(this.inputs.text, Utils.SimpleColorHexToString(this.inputs.color), 'normal bold', this.inputs.fontSize, 'sans-serif', new THREE.Vector2(1, 1));
    }

    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
        // throw new Error('Method not implemented.');

        if (this.inputs.fontSize != oldInputs.fontSize || force) {
            this.canvasTextRenderer?.drawText(this.inputs.text, Utils.SimpleColorHexToString(this.inputs.color), "normal bold", this.inputs.fontSize, "sans-serif", new THREE.Vector2(1, 1));
        }

        if (this.inputs.text != oldInputs.text || force) {
            this.canvasTextRenderer?.drawText(this.inputs.text, Utils.SimpleColorHexToString(this.inputs.color), "normal bold", this.inputs.fontSize, "sans-serif", new THREE.Vector2(1, 1));
        }
    }

    private createMesh2(transparent: boolean, texture: Texture | null, color: number, opacity: number, borderRadius: number,
        polygonOffset: boolean = false, polygonOffsetFactor: number = 0, polygonOffsetUnits: number = 0, updateUV: boolean = false): THREE.Mesh {
        //ThreeJS code to draw a plane from scratch
        let geometry = new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, borderRadius))
        let tempMesh = new THREE.Mesh(
            //new THREE.PlaneBufferGeometry(2, 1),
            geometry.
                translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            new THREE.MeshBasicMaterial({
                transparent: transparent,
                map: texture,
                opacity: opacity,
                color: new THREE.Color(color),
                polygonOffset: polygonOffset,
                polygonOffsetFactor: polygonOffsetFactor,
                polygonOffsetUnits: polygonOffsetUnits,
                side: THREE.DoubleSide,
            })
            // new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        );

        this.meshes.push(tempMesh);

        if (updateUV) {

            // var geometry = (tempMesh.geometry as ShapeBufferGeometry);
            geometry.computeBoundingBox();

            ////This gets # of vertices
            const vertexCount = geometry.getAttribute('position').count;

            //with attribute
            const uv = geometry.getAttribute('uv');
            //
            for (let i = 0; i < vertexCount; i++) {
                let xx = uv.getX(i);
                let yy = uv.getY(i);
                // uv.setXY(i, xx * this.oldRootScale.x, yy * this.oldRootScale.y);
                uv.setXY(i, xx, yy);
                // uv.setXY(i, xx/ this.oldRootScale.x, yy / this.oldRootScale.y);

            }

            uv.needsUpdate = true;

            console.log(`root scale: ${this.root.scale.x} ${this.root.scale.y}`);
            // this.mesh!.scale.set(1 / oldRootScale.x, 1 / oldRootScale.y, 1);
            tempMesh.scale.set(1 / this.oldRootScale.x, 1 / this.oldRootScale.y, 1);
            tempMesh.updateMatrixWorld();
        }
        this.root.add(tempMesh);
        return tempMesh;
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}
