import { Plane, Quaternion, Vector2, Vector3 } from "three";

export default class CardinalAxesAndPlanes {
    private static _instance:CardinalAxesAndPlanes;
    
    public static get instance():CardinalAxesAndPlanes {
        if(!this._instance) {
            this._instance = new CardinalAxesAndPlanes();
        }

        return this._instance;
    }

    constructor() {
        this.unitVector = new Vector3(1, 1, 1);
        this.unitVector2 = new Vector2(1, 1);
        Object.freeze(this.unitVector);
        Object.freeze(this.unitVector2);

        this.xAxis = new Vector3(1, 0, 0);
        this.yAxis = new Vector3(0, 1, 0);
        this.zAxis = new Vector3(0, 0, 1);  
        
        Object.freeze(this.xAxis);
        Object.freeze(this.yAxis);
        Object.freeze(this.zAxis);

        this.XY = new Plane(new Vector3().crossVectors(this.xAxis, this.yAxis), 0);
        this.ZX = new Plane(new Vector3().crossVectors(this.zAxis, this.xAxis), 0);
        this.YZ = new Plane(new Vector3().crossVectors(this.yAxis, this.zAxis), 0);

        Object.freeze(this.XY);
        Object.freeze(this.ZX);
        Object.freeze(this.YZ);

        this.quaternionIdentity = new Quaternion().identity();
        Object.freeze(this.quaternionIdentity);
    }

    public YZ:Plane;
    public ZX:Plane;
    public XY:Plane;

    public xAxis:Vector3;
    public yAxis:Vector3;
    public zAxis:Vector3;

    public quaternionIdentity:Quaternion;

    public unitVector:Vector3;
    public unitVector2:Vector2;
    
}
