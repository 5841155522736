import {ISceneNode, SceneComponent} from '../sceneManagement/SceneComponent';
import Utils from '../../Tools/Utils';
import * as THREE from 'three';
import {Object3D} from 'three';
import {UserDataProperties} from '../ui-interop/PropertiesPanel';

export default class PoseResetOnInit {
    private _attempts:number = 30;
    private _done:boolean;
    
    private meshes:Object3D[];
    private _aSkinnedMesh:THREE.SkinnedMesh;
    
    constructor(private node: ISceneNode) {
        this._done = false;
        for (let i = 0; i < this._attempts; i++) {
            setTimeout(()=> {this.resetNodePose()}, (1000 + Math.round(Math.random() * 500)) * (i + 1));
        }
    }
    
    private resetNodePose() {
        if (this._done) {
            return;
        }
        
        let rootOfNode = Utils.Find3DRootOfNode(this.node);
        let isSkinned:boolean = false;
        if (rootOfNode) {
            this.meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(rootOfNode);
            for (let i = 0; i < this.meshes.length; i++) {
                let tempMesh = this.meshes[i];
        
                if (tempMesh.type == 'SkinnedMesh') {
                    this._aSkinnedMesh = tempMesh as THREE.SkinnedMesh;
                    // this._aSkinnedMesh.pose();
                    isSkinned = true;
                    // sk.rotateX(45 * Math.PI / 180);
                    // sk.skeleton.dispose();
                }
            }
        }
        
        if (isSkinned) {
            var newSkinnedGroup = new THREE.Group();
            var allRootChildren:Object3D[] = [];
            while (rootOfNode?.children?.length! > 0) {
                allRootChildren.push(rootOfNode!.children[0]);
                rootOfNode!.remove(rootOfNode!.children[0]);
            }

            for (let i = 0; i < allRootChildren.length; i++) {
                newSkinnedGroup.add(allRootChildren[i]);
            }
            rootOfNode?.add(newSkinnedGroup);
            this.node.userData[UserDataProperties.skinnedNode] = newSkinnedGroup;

            this._aSkinnedMesh.pose();
    
            this._done = true;
            
            // var child = rootOfNode?.children.pop();
            //
            // let meshes = Utils.GetAllMeshesAndLineSegmentsInObject3D(rootOfNode!);
            // for (let i = 0; i < meshes.length; i++) {
            //     let tempMesh = meshes[i];
            //
            //     if (tempMesh.type == 'SkinnedMesh') {
            //         let sk = tempMesh as THREE.SkinnedMesh;
            //         sk.skeleton.dispose();
            //     }
            // }
            // rootOfNode?.children.forEach((child) => {
            //     if (child instanceof THREE.SkinnedMesh) {
            //         child.skeleton.dispose();
            //     }
            // });
        }
        
    }
}
