import { GenericInput, genericInputType } from "../../components/systemComponents/GenericInput";
import { GenericInputDragBeginSpy } from "./spies/GenericInputDragBeginSpy";
import { GenericInputDragEndSpy } from "./spies/GenericInputDragEndSpy";
import { GenericInputKeySpy } from "./spies/GenericInputKeySpy";
// import {GenericInputDragSpy} from './spies/GenericInputDragSpy';
// import {GenericInputPointerMoveSpy} from './spies/PointerMoveSpy';

export default class InputSubSystem {
    //private members
    private static _instance:InputSubSystem | null = null;
    private genericInput: GenericInput;

    //public members
    public static get instance():InputSubSystem {
        if (!InputSubSystem._instance) {
            InputSubSystem._instance = new InputSubSystem();
        }

        return InputSubSystem._instance;
    }

    public static get input():GenericInput {
        return InputSubSystem.instance.genericInput;
    }

    private constructor() {

    }

    public async Initialize(sdk:any):Promise<void> {
        console.log("[InputSubSystem] Performing initialization for Simulation...");
        const inputNode = await sdk.Scene.createNode();
        this.genericInput = inputNode.addComponent(genericInputType);

        this.genericInput.setupInputNode(inputNode);

        const genericInputDragBeginSpy = new GenericInputDragBeginSpy();
        this.genericInput.spyOnEvent(genericInputDragBeginSpy);
        
        // const genericInputDragSpy = new GenericInputDragSpy();
        // this.genericInput.spyOnEvent(genericInputDragSpy);
        
        //PointerMoveSpy
        // const genericInputPointerMoveSpy = new GenericInputPointerMoveSpy();
        // this.genericInput.spyOnEvent(genericInputPointerMoveSpy);

        const genericInputDragEndSpy = new GenericInputDragEndSpy();
        this.genericInput.spyOnEvent(genericInputDragEndSpy);

        const genericInputKeySpy = new GenericInputKeySpy();
        this.genericInput.spyOnEvent(genericInputKeySpy);
    }
}