import ListNode from './ListNode';
import INodeRefHolder from './INodeRefHolder';
import ManagedObject from './ManagedObject';

export default class DoublyLinkedList<T extends ManagedObject> extends ManagedObject {
    protected head:ListNode<T>|null = null;
    protected tail:ListNode<T>|null = null;
    
    private size: number;
    
    constructor()
    {
        super();
        this.head = null;
        this.tail = null;
        this.size = 0;
    }
    
    public dispose() {
        this.head = null;
        this.tail = null;
        this.size = 0;
    }
    
    public get first(): ListNode<T> | null
    {
        if(this.head != null)
        {
            return this.head;
        }
        return null;
    }
    
    public shift():T|null
    {
        if(this.isEmpty())
        {
            return null;
        }
    
        let data = this.head?.data;
        
        if(this.size === 1)
        {
            this.head = null;
            this.tail = null;
        }
        else
        {
            this.head = this.head!.next;
            this.head!.prev = null;
            
        }
        
        this.size--;
        return data!;
    }
    
    public removeNode(x:ListNode<T>):T|null {
        
        if(this.size === 1) {
            if(x === this.head) {
                this.head = null;
                this.tail = null;
            } else {
                return null;
            }
        } else {
            if(x.prev === null) {
                this.head = x.next;
                this.head!.prev = null;
            } else if(x.next === null) {
                this.tail = x.prev;
                this.tail!.next = null;
            } else {
                x.prev!.next = x.next;
                x.next!.prev = x.prev;
            }
        }
        
        this.size--;
        
        x.disconnectFromList();
        x.next = x.prev = null;
        
        return x.data;
    }
    
    public push(value: T):ListNode<T>
    {
        let tmp = value.nodeRef as ListNode<T>|null;
        
        if(tmp == null) {
            tmp = new ListNode<T>(value);
            
        }
    
        value.changeNodeRef(tmp as ListNode<T>);
        tmp.connectToList(this);
        
        tmp.next = tmp.prev = null;
        
        if(this.isEmpty())
        {
            this.head = tmp;
            this.tail = tmp;
            this.size++;
        }
        else
        {
            tmp.next = null;
            tmp.prev = this.tail;
            tmp.data = value;
            this.tail!.next = tmp;
            
            this.tail = tmp;
            this.size++;
        }
    
        return tmp;
    }
    
    public get length(): number
    {
        return this.size;
    }
    
    public isEmpty(): boolean
    {
        return this.size <= 0;
    }
    
}
