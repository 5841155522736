import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch } from 'react-redux';
import { useAuthUser } from '@crema/utility/AppHooks';
import { Box, Button, makeStyles, MenuItem, Popover, Typography, useTheme } from '@material-ui/core';
// import HelpSectionItem from './HelpSectionItem';
import { CremaTheme } from '../../../types/AppContextPropsType';
// import { onGetHelpSectionList, onMarkReadAllHelpSection } from 'redux/actions';
import * as Icons from '@mui/icons-material/';
// import { GET_NOTIFICATIONS } from 'types/actions/Notification.actions';
import _ from 'lodash';
import { HelpBeacon } from 'shared/constants/AppConst';
import IntlMessages from '@crema/utility/IntlMessages';
import { Link, useHistory } from 'react-router-dom';
import { Shortcuts3D } from 'modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/ShortCutPanel';
import { tryCatch } from '@crema/utility/Utils';

const useStyles = makeStyles((theme: CremaTheme) => ({
    crPopover: {
        '& .MuiPopover-paper': {
            width: 260,
            [theme.breakpoints.up('sm')]: {
                width: 300,
            },
            [theme.breakpoints.up('xl')]: {
                width: 380,
            },
        },
        '& .scroll-submenu': {
            maxHeight: 500, //TODO this should be 100%
            [theme.breakpoints.up('xl')]: {
                maxHeight: 380,
            },
        },
    },
    btnPopover: {
        borderRadius: 0,
        width: '100%',
        textTransform: 'capitalize',
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

interface HelpSectionProps { }

const HelpSection: React.FC<HelpSectionProps> = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const authUser = useAuthUser();
    const theme = useTheme();
    const history = useHistory();

    const [openHelp, setOpenHelp] = React.useState<HTMLButtonElement | null>(null);
    const [openShortcuts, setOpenShortcuts] = React.useState(false);
    // React.useEffect(() => {

    //     // openHelp && (window as any).Beacon('toggle');
    //     openHelp && HelpBeacon && console.log('Opening helpbeacon');
    //     try {
    //         openHelp && HelpBeacon && HelpBeacon.Beacon('toggle');
    //     } catch (e) {
    //         console.error("Couldn't open the HelpScout Beacon " + e);
    //         openHelp && (window as any).Beacon('toggle');
    //     }
    // }, [openHelp]);

    // React.useEffect(() => {

    //     // openHelp && (window as any).Beacon('toggle');
    //     // openHelp && HelpBeacon && console.log('Opening helpbeacon');
    //     try {
    //         // (window as any).Beacon('prefill', {
    //         //     name: (authUser?.user.firstName || '') + (authUser?.user.lastName || ''),
    //         //     email: authUser?.user.email || '',
    //             // fields: [
    //             //     {
    //             //       id: 5678, // dropdown field ID
    //             //       value: 1234, // option ID
    //             //     },
    //         // });

    //         openHelp && HelpBeacon && HelpBeacon.Beacon('toggle');
    //     } catch (e) {
    //         console.error("Couldn't fill HelpScout Beacon form " + e);
    //         // openHelp && (window as any).Beacon('toggle');
    //     }
    // }, [authUser?.uid]);

    return (<>

        <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box style={{ position: 'absolute', right: 90, top: 70 }}
            >{openShortcuts && <Shortcuts3D open={openShortcuts} setOpen={setOpenShortcuts} />}
            </Box>
            <IconButton
                // className={clsx(classes.releaseBtn, 'releaseBtn')}
                size='medium'

                aria-label='help'
                // style={{ backgroundColor: (openHelp ? theme.palette.primary.main : '') }}
                // color='inherit'
                onClick={(event) => {

                    setOpenHelp(event.currentTarget);
                    // (window as any).Beacon('open');
                }}>
                {/* {openHelp ? <Icons.Close htmlColor='white' /> :  */}
                <Icons.HelpRounded color="success" />
            </IconButton>

            <Popover
                anchorEl={openHelp}
                id='language-switcher'
                className={classes.crPopover}
                keepMounted
                open={Boolean(openHelp)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setOpenHelp(null)}>
                <Box>
                    <MenuItem
                        id='get-support-button'
                        divider
                        onClick={() => {
                            tryCatch(() => (window as any).Beacon('open'), 'beacon open');
                            tryCatch(() => (window as any).Beacon('navigate', '/ask/'), 'beacon navigate to ask')
                            // tryCatch(() => (window as any).Beacon('open'))
                            setOpenHelp(null);
                        }}
                    >
                        Ask Support
                    </MenuItem>
                    <MenuItem
                        id='knowledgebase-button'
                        onClick={() => {
                            tryCatch(() => (window as any).Beacon('open'), 'beacon open')
                            tryCatch(() => (window as any).Beacon('navigate', '/answers/'), 'beacon navigate to answers')
                            // window.open('https://spatialthink.notion.site/Product-How-tos-7935c036c0054e80ad86ce9a7cf53fdf', '_blank');
                            setOpenHelp(null);
                        }}
                    >
                        Documentation
                    </MenuItem>
                    <MenuItem
                        id='keyboard-shortcuts-button'
                        onClick={() => {
                            setOpenShortcuts(true);
                            setOpenHelp(null);
                        }}
                    >
                        Keyboard Shortcuts
                    </MenuItem>
                    <MenuItem
                    id='followalong-guides'
                        onClick={() => {
                            window.open('https://spatialthink.notion.site/Product-How-tos-7935c036c0054e80ad86ce9a7cf53fdf', '_blank');
                            setOpenHelp(null);
                        }}
                    >
                        Follow-along Guides
                    </MenuItem>

                    {/* <Box px={5} py={3} className={classes.header}>
                    <Box component='h5' fontFamily='Poppins' fontSize={16}>
                        <IntlMessages id='common.updates' />
                    </Box>
                    <Typography variant='caption'>v</Typography>
                </Box> */}
                    {/* <Scrollbar className='scroll-submenu'>
                    <List dense
                        className={classes.list}
                        onClick={() => {
                            // setAnchorReleaseNotes(null);
                        }}>

                        {releaseNotes.map((release: Release, index) => {

                            let hasUserSeenRelease = !!seenUserReleases.find(ur => ur.id === release.id)?.seen;
                            return <Tooltip key={index} title={release.id}><ListItem divider alignItems="flex-start" className={'item-hover'} onClick={() => {
                                if (!hasUserSeenRelease) {
                                    markReleaseAsSeen(release.id);
                                }
                            }}
                            >
                                <ListItemAvatar>
                                    <Icons.FiberManualRecordRounded style={{ color: hasUserSeenRelease ? 'grey' : '#f5831b' }} fontSize='small' />
                                </ListItemAvatar>
                                <ReleaseNotesItem key={release.id} release={release} />

                            </ListItem>
                            </Tooltip>
                        }
                        )}
                    </List>
                </Scrollbar> */}

                </Box>
            </Popover>
        </div >

    </>);
};

export default HelpSection;
