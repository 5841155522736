import React, { useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Spacer from '@crema/core/Spacer';
import ThreeDAssets from './ThreeDAssets';
import Layout from '../layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardRoot: {
      backgroundColor: theme.palette.background.default,
    },
  }),
);

interface Props {
}

export default function ThreeDSidebar({
}: Props) {

  return (console.log(`%c[st] rendering ThreeDSidebar `,'color: orange;'),

    <Layout>
      <Spacer y={4} />

      <div style={{
        maxHeight: '100%', height: "100%",
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-start',
        marginRight: '8px'
      }}>

        {useMemo(() => <ThreeDAssets />, [])}
      </div>
    </Layout>
  );
}
