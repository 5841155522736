// import THREE, { FontLoader } from "three";
import * as THREE from 'three';
import { ARButton } from './jsm/webxr/ARButton.js';
import { TagsARInteraction } from './TagsARInteraction';
import { MimickScene } from './Mimick/MimickScene';
import { TagSystem } from './Systems/TagSystem';
import { RenderingSystem } from './Systems/RenderingSystem';
import { WindowingSystem } from './Systems/WindowingSystem';
import { CameraSystem } from './Systems/CameraSystem';
import { InputSystem } from './Systems/InputSystem';
import { RaycastSystem } from './Systems/RaycastSystem';
import Simulation from '../mp/core/craEngine/SubSystems/core/Simulation';
import { MimickPointer } from './Mimick/MimickPointer';
import { MimickCamera } from './Mimick/MimickCamera';
import { LightSystem } from './Systems/LightSystem';
import { VRButton } from './jsm/webxr/VRButton.js';
import { Font } from 'three/examples/jsm/loaders/FontLoader.js';
import { GrabbableSystem } from './Systems/GrabbableSystem';
import { TagsVRInteraction } from './TagsVRInteraction';
import { store } from 'App.js';

export enum SpatialThinkSDKMode {
    Plain = 'Plain',
    AR = 'AR',
    VR = 'VR'
}

export interface SpatialSDKType {
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
    effectComposer: null;
    camera: THREE.Camera;
    Scene: MimickScene;
    Pointer: MimickPointer;
    Camera: MimickCamera;
    customSDK: boolean;
}

export class SpatialThinkSDK {
    public renderingSystem: RenderingSystem;
    protected windowingSystem: WindowingSystem;
    public cameraSystem: CameraSystem;
    protected raycastSystem: RaycastSystem;
    protected lightingSystem: LightSystem;

    protected mode: SpatialThinkSDKMode;

    //Misc
    protected font: Font | null = null;
    protected boxObject: THREE.Object3D | null = null;
    protected arInteraction: TagsARInteraction | null = null;
    protected vrInteraction: TagsVRInteraction | null = null;

    protected mimickScene: MimickScene;
    protected mimickPointer: MimickPointer;
    protected mimickCamera: MimickCamera;

    public tagSystem: TagSystem;
    // protected grabbableSystem:GrabbableSystem;

    public constructor() {
        this.windowingSystem = new WindowingSystem();
        this.cameraSystem = new CameraSystem(this.windowingSystem);
        this.renderingSystem = new RenderingSystem(this.cameraSystem);
        this.raycastSystem = new RaycastSystem(this.renderingSystem);
        // this.grabbableSystem = new GrabbableSystem(this.renderingSystem, this.raycastSystem, );
        this.lightingSystem = new LightSystem(this.renderingSystem);
        //document.body.appendChild(this.container);

        const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 1);
        light.position.set(0.5, 1, 0.25);
        this.renderingSystem.scene.add(light);

        const geometry = new THREE.BoxGeometry(0.2, 0.2, 0.2);
        this.boxObject = new THREE.Mesh(geometry, new THREE.MeshLambertMaterial({ color: Math.random() * 0xffffff }));
        this.boxObject.position.set(0, 0, -0.5);
        // this.renderingSystem.scene.add(this.boxObject);

        this.tagSystem = new TagSystem(this.renderingSystem);

        this.renderingSystem.startRenderer();
        this.renderingSystem.registerRenderCallback(this.render.bind(this))

        this.mimickScene = new MimickScene(this.renderingSystem);
        this.mimickPointer = new MimickPointer();
        this.mimickCamera = new MimickCamera();
    }

    public SetMode(mode: SpatialThinkSDKMode): void {
        this.mode = mode;

        this.arInteraction && this.arInteraction.dispose();
        this.vrInteraction && this.vrInteraction.dispose();

        switch (mode) {
            case SpatialThinkSDKMode.AR:
                this.arInteraction = new TagsARInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);

                // document.getElementById('app-sidebar')?.remove();
                document.getElementById('shortcut-panel')?.remove();
                // document.getElementById('quick-access-toolbar')?.remove();
                document.body.appendChild(ARButton.createButton(this.renderingSystem.renderer, { requiredFeatures: ['hit-test'] }));

                break;
            case SpatialThinkSDKMode.Plain:

                break;

            case SpatialThinkSDKMode.VR:
                this.renderingSystem.enableXR();
                document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));
                this.vrInteraction = new TagsVRInteraction(this.renderingSystem.base, this.renderingSystem, this.raycastSystem);
            //     this.renderingSystem.enableVR();
            //     document.body.appendChild(VRButton.createButton(this.renderingSystem.renderer));

        }
    }

    protected render(timestamp: any, frame: any) {
        switch (this.mode) {
            case SpatialThinkSDKMode.Plain:
                break;
            case SpatialThinkSDKMode.VR:
                break;
            case SpatialThinkSDKMode.AR:

                this.arInteraction!.xrRender(frame);
                break;
        }
        // console.log(timestamp);
        // console.log(frame);
        // if (this.boxObject) {
        //     this.boxObject?.rotateY(0.01);
        // }
        // this.boxObject?.rotateY(0.01);

    }

    public getSDK(): SpatialSDKType {
        return {
            scene: this.renderingSystem.scene,
            renderer: this.renderingSystem.renderer,
            effectComposer: null,
            camera: this.cameraSystem.camera,
            Scene: this.mimickScene,
            Pointer: this.mimickPointer,
            Camera: this.mimickCamera,
            customSDK: true
        };
    }

    //Getter for raycast system
    public get RaycastSystem(): RaycastSystem {
        return this.raycastSystem;
    }

    //get grabbable system
    // public get GrabbableSystem(): GrabbableSystem {
    //     return this.grabbableSystem;
    // }

    // private static _instance: SpatialThinkSDK | null = null;
    // public static get instance(): SpatialThinkSDK {
    //     if (!SpatialThinkSDK._instance) {
    //         SpatialThinkSDK._instance = new SpatialThinkSDK();
    //     }

    //     return SpatialThinkSDK._instance;
    // }

    //Getter for mode
    public get Mode(): SpatialThinkSDKMode {
        return this.mode;
    }

    public destroy(){
        this.renderingSystem.renderer.dispose();
        this.renderingSystem.labelRenderer?.dispose();
    }



}
