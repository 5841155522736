import { SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import { ExternalSdkMode } from 'mp/core/craEngine/SubSystems/core/Simulation';
import { ISceneNode } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import * as ThreeDActions from 'types/actions/ThreeD.actions';
import { NodeProperties } from 'types/models/home/HomeApp';
import { MeModelList, MeModelResponse } from 'types/models/home/ThirdParty/Sketchfab';
import { AppActions } from '../../types';

const INIT_STATE: {
  objectPlacedId: string;
  showPropertiesPanel: boolean;
  showPoses: boolean;
  selectedNodeId: string;
  showGizmo: boolean;
  mySketchfabModels: MeModelResponse;
  selectedNode?: NodeProperties | undefined;
  sdkMode: SpatialThinkSDKMode | ExternalSdkMode | undefined;
  highlightedObject?: THREE.Object3D
} = {
  objectPlacedId: '',
  showPropertiesPanel: false,
  showPoses: false,
  selectedNodeId: '',
  showGizmo: false,
  mySketchfabModels: { results: [] },
  selectedNode: undefined,
  sdkMode: undefined,
  highlightedObject: undefined
};

const ThreeD = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case ThreeDActions.OBJECT_PLACED: {
      return {
        ...state,
        objectPlacedId: action.payload,
      };
    }

    case ThreeDActions.SHOW_PROP_PANEL:
      return {
        ...state,
        showPropertiesPanel: action.payload
      }

    case ThreeDActions.SHOW_POSES:
      return {
        ...state,
        showPoses: action.payload
      }

    case ThreeDActions.SHOW_GIZMO:
      return {
        ...state,
        showGizmo: action.payload
      }

    case ThreeDActions.SET_MY_SKETCHFAB_MODELS:
      return {
        ...state,
        mySketchfabModels: action.payload
      }
    case ThreeDActions.SELECT_NODE:
      return {
        ...state,
        selectedNode: action.payload
      }
    case ThreeDActions.SET_SDK:
      let sm = state.mySketchfabModels;
      return {
        ...INIT_STATE,
        mySketchfabModels: sm,
        sdkMode: action.payload
      }

    case ThreeDActions.SET_HIGHLIGHTED_OBJECT:
      return {
        ...state,
        highlightedObject: action.payload
      }
    case ThreeDActions.SET_SELECTED_NODE_ID:
      return {
        ...state,
        selectedNodeId: action.payload
      }
    default:
      return state;
  }
};
export default ThreeD;