import React, { useMemo } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import ThreeDAnnotations from './ThreeDAnnotations';
import CatalogSidebar from '../CatalogSidebar';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { UseCase } from 'shared/constants/AppConst';
import { ThirdPartyName } from 'types/models/dataAccess/User';
import ThirdPartyImporter from '../ThirdPartyImporter';
import InsertedObjects from './InsertedObjects';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardRoot: {
            backgroundColor: theme.palette.background.default,
        },
        tabContext: {
            '& .MuiTab-fullWidth': {
                flexBasis: 'unset',
            }
        },
        tabs: {
            width: '100%',
            '& .MuiTab-fullWidth': {
                flexBasis: 'unset',
            }
        },
        root: {
            width: '100%',
            height: '50%',
        },
        tabPanel: {
            // backgroundColor: 'green',
            // width: '100%',
            padding: '8px 0px'

        },
        appBarRoot: {
            // elevation4: {
            boxShadow: 'unset',
            // backgroundColor: 'red',
            width: "100%"
            // }
        },
        tabButton: {
            minWidth: '30px',
            textTransform: 'none',
            width: 'fit-content'
        }
    }),
);

interface Props {

}

export default function ThreeDAssets({

}: Props) {
    const classes = useStyles();

    const [value, setValue] = React.useState('1');
    const theme = useTheme();
    // const currentSpaceUseCase = useSelector<AppState, UseCase | null>(({ home }) => home.currentSpace?.primaryUseCase || null);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
    };

    return (console.log(`%c[st] rendering ThreeDAssets`, 'color: orange;'),

        // <Layout>
        <div style={{
            width: "100%",
            paddingBottom: '24px'
        }}>

            <TabContext value={value}
            >
                <TabList onChange={handleChange} aria-label="3D tabs"
                    value={value}
                    // onChange={handleChange}
                    style={{ minHeight: '48px', width: "100%" }}
                    indicatorColor='secondary'
                    textColor='primary'
                    variant='fullWidth'
                    // aria-label='full width tabs'
                    className={classes.tabs}>
                    {<Tab classes={{ root: classes.tabButton }} label={'Inserted'} value={'0'} />}
                    {<Tab classes={{ root: classes.tabButton }} label={'Annotations'} value={'1'} />}
                    {<Tab classes={{ root: classes.tabButton }} label={'Uploads'} value={'2'} />}
                    {<Tab classes={{ root: classes.tabButton }} label={'Sketchfab'} value={'3'} />}

                </TabList>

                <TabPanel value={'0'}
                    // index={0}
                    style={{}}
                    className={classes.tabPanel}
                >
                    <div style={{
                        padding: '8px',
                        width: "100%",
                        // maxHeight: 'calc(100vh - 400px)',
                    }}>
                        {useMemo(() => <InsertedObjects />, [])}
                    </div>

                    {/* {React.useMemo(() => <CatalogSidebar />, [])} */}

                </TabPanel>
                <TabPanel value={'1'}
                    className={classes.tabPanel}>
                    <div>
                        {useMemo(() => <ThreeDAnnotations />, [])}
                    </div>

                </TabPanel>
                <TabPanel
                    value={'2'}
                    className={classes.tabPanel}>
                    <div style={{ width: "100%" }}>
                        {React.useMemo(() => <CatalogSidebar myUploadsOnly={true} title='My Uploads' />, [])}
                    </div>

                </TabPanel>
                <TabPanel
                    value={'3'}
                    className={classes.tabPanel}>
                    <div style={{
                        width: "100%",
                    }}>
                        {React.useMemo(() => <ThirdPartyImporter importer={ThirdPartyName.SKETCHFAB} />, [])}
                    </div>
                </TabPanel>
            </TabContext>

        </div>
    );
}
