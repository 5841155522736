import Api from '../../@crema/services/ApiConfig';
import { appIntl, BasicProfiler } from '../../@crema/utility/Utils';
import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import * as Common from './Common';
import {
  ADD_PROJECT_SECTION,
  ADD_SECTION_TASK,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  DELETE_SECTION,
  DELETE_LIST_TASK,
  EDIT_PROJECT_DETAIL,
  EDIT_SECTION_TASK,
  GET_PROJECT_DETAIL,
  GET_PROJECTS,
  GET_MEMBER_LIST,
  GET_PROJECT_LABEL_LIST,
  EDIT_PROJECT_LABEL,
  DELETE_PROJECT_LABEL,
  ADD_PROJECT_LABEL,
  ADD_MEMBER_LIST_INVITES,
  UPDATE_TASK,
} from '../../types/actions/Projectboard.actions';
import {
  ProjectObj,
  TaskSectionObj,
  TaskObj,
  LabelObj,
  CollaboratorInvite,
  CollaboratorObj,
  CommentObj,
} from '../../types/models/apps/ProjectBoard';
import { GET_SPACE_PROJECTS, SET_SPACE_DATA } from 'types/actions/Home.action';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { store } from 'App';
import { DocumentSnapshot, FieldValue, QuerySnapshot } from '@firebase/firestore-types';
import _ from 'lodash';
import { refreshTagIcons, updateTag } from './Home';
// import admin from "firebase-admin";


export const onGetProjectList = () => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.get('/api/projects/list')
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_PROJECTS, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onGetSpaceProjectList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());

    Api.get(`/api/spaces/${did}/spaceProjectList`)
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_SPACE_PROJECTS, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onGetProjectLabelList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.get(`/api/projects/${did}/label/list`)
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_PROJECT_LABEL_LIST, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onEditProjectLabel = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.put('/api/projects/edit/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: EDIT_PROJECT_LABEL, payload: data.data });
          // refreshTagIcons(); //TODO make sure tag icons are refreshed
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onEditProjectLabelFromSpace = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { currentSpace } = getState()?.home || {};
    dispatch(Common.fetchStart());
    Api.put('/api/projects/edit/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: SET_SPACE_DATA, payload: { ...currentSpace, currentSpaceProject: data.data } });
          // dispatch({type: EDIT_PROJECT_LABEL, payload: data.data});
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onAddProjectLabel = (projectId: string, label: LabelObj, onError?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/add/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: ADD_PROJECT_LABEL, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        if (onError) onError();
        dispatch(Common.fetchError(error.response ? error.response.statusText : error.message));
      });
  };
};

export const onAddProjectLabelFromSpace = (projectId: string, label: LabelObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { currentSpace } = getState()?.home || {};
    dispatch(Common.fetchStart());
    Api.post('/api/projects/add/label', { projectId, label })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: SET_SPACE_DATA, payload: { ...currentSpace, currentSpaceProject: data.data } });
          // dispatch({type: ADD_PROJECT_LABEL, payload: data.data});
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onAddProjectCollaborator = (projectId: string, collaborator: CollaboratorObj, onError?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStartNonBlocking(messages['common.saving']));
    Api.post('/api/projects/add/collaborator', { projectId, collaborator })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: ADD_MEMBER_LIST_INVITES, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        if (onError) onError();
        dispatch(Common.fetchError(error.response ? error.response.statusText : error.message));
      });
  };
};

export const onDeleteProjectLabel = (projectId: string, taskId: string, labelId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/delete/label', { projectId, taskId, labelId })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: DELETE_PROJECT_LABEL, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.listEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onGetMemberList = (did: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.get(`/api/projects/${did}/member/list`)
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_MEMBER_LIST, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onEditProjectDetail = (project: ProjectObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.put('/api/projects/edit/project', { project })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: EDIT_PROJECT_DETAIL, payload: data.data });
          dispatch(Common.showMessage(messages['projectBoard.projectEdited'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onGetProjectDetail = (did: string): any => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.get(`/api/projects/${did}/projectDetail`)
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_PROJECT_DETAIL, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onGetSpaceProjectDetail = (sid: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.get(`/api/projects/${sid}/spaceProjectDetail`)
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: GET_PROJECT_DETAIL, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onAddNewProject = (name: string, spaceId: string, onSuccess?: (project: ProjectObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/create', { name, spaceId })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: ADD_NEW_PROJECT, payload: data.data });
          dispatch(Common.showMessage(messages['projectBoard.projectAdded'] as string));
          if (onSuccess) onSuccess(data.data);
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onAddNewSection = (projectId: string, section: TaskSectionObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/add/section', { projectId, section: section })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: ADD_PROJECT_SECTION, payload: data.data });
          dispatch(Common.showMessage(messages['projectBoard.listAdded'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onEditProjectSection = (projectId: string, section: TaskSectionObj) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.put('/api/projects/edit/section', { projectId, section })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: EDIT_SECTION_TASK, payload: data.data });
          console.log(`[st] [tasks] task section updated`);
          dispatch(Common.showMessage(messages['common.saved'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onAddNewTask = (task: TaskObj, onSuccess: (task: TaskObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/add/task', { task })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: ADD_SECTION_TASK, payload: data.data.project });
          // dispatch(showMessage(messages['projectBoard.cardAdded'] as string));
          // let index=data.data.sectionList.findIndex((i:TaskSectionObj)=>(i.id==task.sectionId));
          // let selectedTask=data.data.sectionList[index].taskList[0];
          onSuccess(data.data.task);
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onEditTaskDetails = (task: TaskObj, onSuccess: (task: TaskObj) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStartNonBlocking(messages['common.saving']));
    Api.put('/api/projects/edit/task', { task })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: EDIT_SECTION_TASK, payload: data.data });
          console.log(`[st] [tasks] task edited`);
          // dispatch(showMessage(messages['projectBoard.cardEdited'] as string));
          onSuccess(task);
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onDeleteSelectedTask = (
  projectId: string,
  sectionId: number,
  taskId: string,
) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/delete/task', { projectId, sectionId, taskId })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: DELETE_LIST_TASK, payload: data.data });
          // dispatch(showMessage(messages['projectBoard.cardDeleted'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onDeleteSelectedProject = (projectId: string) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/delete', { projectId })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: DELETE_PROJECT, payload: data.data });
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onDeleteSelectedSection = (projectId: string, sectionId: number) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(Common.fetchStart());
    Api.post('/api/projects/delete/section', { projectId, sectionId })
      .then(data => {
        if (data.status === 200) {
          dispatch(Common.fetchSuccess());
          dispatch({ type: DELETE_SECTION, payload: data.data });
          dispatch(Common.showMessage(messages['projectBoard.listDeleted'] as string));
        } else {
          dispatch(
            Common.fetchError(messages['message.somethingWentWrong'] as string),
          );
        }
      })
      .catch(error => {
        dispatch(Common.fetchError(error.message));
      });
  };
};

export const onNullifyProjectDetail = () => {
  return {
    type: GET_PROJECT_DETAIL,
    payload: null,
  };
};

export function getTaskInProject(id: string): TaskObj | null {
  let p = store.getState().projects?.projectDetail;

  for (let i = 0; i < (p?.sectionList?.length || 0); i++) {
    let s = p?.sectionList[i];
    let taskObj = s?.taskList.find(t => t.id == id);
    if (!!taskObj) {
      return taskObj;
    }
  }
  return null;
}

export const onAddNewComment = (projectId: string, tagId: string, comment: CommentObj) => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {

    projectId = projectId || store.getState().projects?.projectDetail?.id || '';
    tagId = tagId || store.getState().home?.editShowcaseTagId;

    if (!projectId || !tagId) {
      dispatch(
        Common.fetchError('Something went wrong getting the project and/or task. Please refresh the page and try again'),
      );
    }
    dispatch(Common.fetchStart());

    let taskId = store.getState().home.spaceTags[tagId]?.taskTag?.id || '';

    if (!!taskId) {
      firestore.collection(`Projects`).doc(projectId).collection(`Tasks`).doc(taskId).get()
        // .update('comments', FieldValue.arrayUnion(comment) as any)
        .then(async (ds: DocumentSnapshot) => {

          let updatedComments = ((await ds.data())?.comments as any[] || []).concat([comment]);
          ds.ref.update('comments', updatedComments).then(() => {
            dispatch(Common.fetchSuccess());
            let bp = new BasicProfiler();
            let t = _.cloneDeep(getTaskInProject(taskId));
            bp.lap('[clone] task in addComment');

            !!t && (t.comments = updatedComments) && dispatch({ type: UPDATE_TASK, payload: t }); //FIXME

            if (!!t && t.tagId) {
              bp = new BasicProfiler();
              let tag = _.cloneDeep(store.getState().home.spaceTags[t.tagId]);
              bp.lap('[clone] spacetag in addComment')
              if (!!tag) {
                tag.taskTag = t;
                dispatch(updateTag(tag));
                console.log(`[st] [tags] comment added`, tag);
                dispatch(Common.showMessage("Comment posted!"));
              }
            }
            // dispatch(showMessage(messages['projectBoard.listAdded'] as string));

            // dispatch(
            //   fetchError(messages['message.somethingWentWrong'] as string),
            // );
          })
        })
        .catch(error => {
          console.error(error.message);
          dispatch(Common.fetchError(error.message));
        });
    }
  };
};
