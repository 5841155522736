import { Model, Result } from './Model';

import emailjs from 'emailjs-com';
import moment from 'moment';
import { firestore } from '../../@crema/services/auth/firebase/firebase';
import { channels } from 'shared/constants/AppConst';
import { AuthUser } from './AuthUser';

export interface NotificationModel {
  id: string;
  createdOn: Date;
  createdBy?: string;
  lastUpdatedOn?: Date;
  lastUpdatedBy?: string;
  color?: string;
  read: boolean;
  text: string;
  link?: string;
  title?: string;
  data?: any;
  templateId?: string;
}

export type MemberObjNotif = {
  name: string;
  id: string;
  email: string;
};

export class Notification implements NotificationModel {
  id: string;
  createdOn: Date;
  createdBy: string;
  lastUpdatedOn?: Date;
  lastUpdatedBy?: string;
  color?: string;
  read: boolean;
  text: string;
  link?: string;
  templateId?: string;

  async create(
    recipientId: string,
    notificationText: string,
    appLink: string,
    creatorId: string
  ) {

    let notificationObj: NotificationModel = {
      text: notificationText,
      createdOn: new Date(),
      id: Math.ceil(Date.now() + Math.random()).toString(),
      read: false,
      createdBy: creatorId || '',
      link: appLink,
    };

    return new Promise(async (resolve, reject) => {
      console.log('NotificationClass', recipientId, notificationObj);

      // if (channelList?.includes(channels.notification)) {
      const userNotificationDoc = firestore
        .collection('notifications')
        .doc(recipientId);

      userNotificationDoc
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log('Document data:', doc.data());
            const notifObj = {
              id: recipientId,
              notifications: [
                ...doc.data()?.notifications,
                notificationObj,
              ],
            };
            userNotificationDoc
              .update({ notifications: notifObj.notifications })
              .then(() => {
                console.log('user notification added!');
                if (recipientId === notificationObj.createdBy)
                  resolve(notificationObj);
                else resolve({});
              })
              .catch((error) => {
                console.error('Error writing notification: ', error);
                reject(error);
              });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            const notifObj = {
              id: recipientId,
              notifications: [notificationObj],
            };
            userNotificationDoc
              .set(notifObj)
              .then(() => {
                console.log('Document successfully written!');
                if (recipientId === notificationObj.createdBy)
                  resolve(notificationObj);
                else resolve({});
              })
              .catch((error) => {
                console.error('Error writing document: ', error);
                reject(error);
              });
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
      // }
      // if (channelList?.includes(channels.email)) {
      // //   try {
      //     const to_user= await firestore.collection('Users').doc(member).get();
      //     const from_user= await firestore.collection('Users').doc(notificationObj.createdBy).get();
      //     // console.log(channelList,member,to_user.data(),from_user.data(),"Notification_Create");
      //     const to_email=to_user.data()?.email;
      //     const to_name=to_user.data()?.firstName?`${to_user.data()?.firstName} ${to_user.data()?.lastName}`:to_email;
      //     const from_name='Spatial Think';
      //     const created_by=from_user.data()?.firstName?`${from_user.data()?.firstName} ${from_user.data()?.lastName}`:from_user.data()?.email;
      //     const message=`${notificationObj.text}\n Created By: ${created_by}`;

      //   const templateParams={
      //     from_name:from_name,
      //     to_name:to_name,
      //     message:message,
      //     to_email:to_email,
      //     // reply_to:'',
      //   }
      //   emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, templateParams, EMAILJS_USER_ID)
      //   .then((response) => {
      //       console.log('SUCCESS!', response.status, response.text);
      //   }, (error) => {
      //       console.log('FAILED...', error,error.text);
      //       throw new Error(error);
      //   });
      //   } catch (error) {
      //     console.log(error,"error")
      //   }
    }
      //END//
      // }
    );
  }

  get(authUser: AuthUser | null) {
    return new Promise((resolve, reject) => {
      const notificationRef = firestore
        .collection('notifications')
        .doc(authUser?.uid);

      notificationRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log('Document data:', doc.data());
            let notificationLength = 0;
            let notifications: NotificationModel[] = [];

            (doc.data()?.notifications || []).forEach((notif: any) => {
              notifications = [...notifications, { ...notif, createdOn: new Date(notif.createdOn?.seconds * 1000) }]
              if (!notif.read) notificationLength++;
            });

            // console.log(notifications, "getnotifications")
            const sorted_notif = notifications
              .sort((a: NotificationModel, b: NotificationModel) => (
                new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime())
              ).reverse();

            resolve({
              notifications: sorted_notif,
              notifCount: notificationLength,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log('No such document!');
            resolve({ notifications: [], notifCount: 0 });
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
          reject(error);
        });
    });
  }

  markReadAll(authUser: AuthUser | null, notifications: NotificationModel[]) {
    return new Promise((resolve, reject) => {
      const notificationRef = firestore
        .collection('notifications')
        .doc(authUser?.uid);

      notificationRef
        .update({ notifications })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  }

  markRead(user: AuthUser | null, notification: NotificationModel) {
    return new Promise((resolve, reject) => {
      const notificationRef = firestore
        .collection('notifications')
        .doc(user?.uid);

      notificationRef.get().then(doc => {
        let notifications: NotificationModel[] = (doc.data()?.notifications || []);
        let objIndex = notifications.findIndex(((obj: NotificationModel) => obj.id == notification.id));
        notifications[objIndex].read = true;

        notificationRef
          .update({ notifications })
          .then(() => {
            console.log('Document successfully updated!');
            resolve('Document successfully updated!');
          })
          .catch((error) => {
            console.error('Error updating document: ', error);
            reject(error);
          });
      }).catch(err => {
        console.error('Error updating document: ', err);
        reject(err);
      })
    });
  }

  delete(idOrObject: string | Model): Model {
    throw new Error('Method not implemented.');
  }

  update(model: NotificationModel) {
    throw new Error('Method not implemented.');
  }
}
