import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';
import {Size} from '../../mp/core/craEngine/components/DecoupledComponents/DecoupledComponent';
import {Mesh, Vector2} from 'three';
import * as THREE from 'three';
import {CanvasRenderer} from './CanvasTools/CanvasRenderer';
import {BasicCallback, Interactable, UserDataCallback} from './Others/Interactable';
import {Dict} from '../../mp/core/types';
import {RaycastSystem} from '../Systems/RaycastSystem';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

type Inputs = {
    transparent: boolean;
    visible: boolean;
    opacity: number;
    color: string;
    hoverColor: string;
    clickColor: string;
    bgColor: string;
    bgHoverColor: string;
    bgClickColor: string;
    fontSize: number;
    textLabel: string;
    borderRadius: number;
    borderShadowColor: string;
    borderStyle: string;
    borderLineWidth: number;
    polygonOffset: boolean;
    polygonOffsetFactor: number;
    polygonOffsetUnits: number;
    size: Size;
    //planeSize: { w:number, y:number };
    localScale: {x: number; y: number; z: number;};
    localPosition: {x: number; y: number; z: number;};
    localRotation: {x: number; y: number; z: number;};
}

export class ButtonComponent extends BaseComponent {
    inputs: Inputs = {
        transparent: true,
        visible: true,
        opacity: 1,
        color: '#d70d0d',
        clickColor: '#ff0000',
        hoverColor: '#ffff00',
        bgColor: 'rgba(203,80,26,0.55)',
        bgHoverColor: "rgba(180,23,232,0.55)",
        bgClickColor: "#00ff00",
        polygonOffset: false,
        borderRadius: 10,
        borderShadowColor: '#000000',
        borderStyle: '#ff0000',
        borderLineWidth: 10,
        fontSize: 80,
        textLabel: 'Menu Item 1',
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
        size: {w: 1, h: 1},
        localScale: {x: 1, y: 1, z: 1},
        localPosition: {x: 0, y: 0, z: 0},
        localRotation: {x: 0, y: 0, z: 0},
    };

    private canvasRendererBG: CanvasRenderer;
    private canvasRendererText: CanvasRenderer;

    // private _interactable: Interactable;



    onInit(userdata: {[p: string]: any}) {
        super.onInit(userdata);
        this.buildTextures();
        this.buildMeshes();
        this.root.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
        // this._interactable = new Interactable();
        // this._interactable.addObject([this.meshes[0]]);
        // this._interactable.addAction(this.onClick.bind(this));
        // this.node && this.setLoaded(true);
    }

    private buildTextures() {
        var targetResolution = 1024
        var resolutionWidth = this.inputs.size.w * targetResolution;
        var resolutionHeight = this.inputs.size.h * targetResolution;

        this.canvasRendererBG = new CanvasRenderer(resolutionWidth, resolutionHeight);
        // this.canvasRendererBG.FillColor(new Vector2(0, 0), new Vector2(1024, 1024), this.bgColor);
        this.canvasRendererBG.clear(this.inputs.bgColor);
        this.canvasRendererBG.drawBorder(new Vector2(0, 0), new Vector2(resolutionWidth, resolutionHeight), this.inputs.borderRadius, this.inputs.borderLineWidth, this.inputs.borderStyle);
        // this.canvasRendererBG.drawText(this.inputs.textLabel, this.inputs.color, 'normal bold', this.inputs.fontSize, 'sans-serif', new Vector2(1, 1));

        this.canvasRendererText = new CanvasRenderer(resolutionWidth, resolutionHeight);
        this.canvasRendererText.drawText(this.inputs.textLabel, this.inputs.color, 'normal bold', this.inputs.fontSize * 2, 'sans-serif', new Vector2(1, 1));

    }

    private repaintTextures() {
        var targetResolution = 1024
        var resolutionWidth = this.inputs.size.w * targetResolution;
        var resolutionHeight = this.inputs.size.h * targetResolution;

        if(this.canvasRendererBG) {
            this.canvasRendererBG.clear(this.inputs.bgColor);
            this.canvasRendererBG.drawBorder(new Vector2(0, 0), new Vector2(resolutionWidth, resolutionHeight), this.inputs.borderRadius, this.inputs.borderLineWidth, this.inputs.borderStyle);
        }
    }

    private buildMeshes() {
        this.clearMeshes();

        this.createMesh(new Vector2(this.inputs.size.w, this.inputs.size.h), true, this.canvasRendererBG.Texture, 1, this.inputs.borderRadius,
            true, -4, 0, new THREE.Color(1, 1, 1));

        this.createMesh(new Vector2(this.inputs.size.w, this.inputs.size.h), true, this.canvasRendererText.Texture, 1, this.inputs.borderRadius,
            true, -8, 0, new THREE.Color(1, 1, 1));

        this.outputs.collider = this.meshes[0];
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.meshes[0]);
        this.meshes[0].userData.componentTarget = this.uuid;

        this.initUserDataWithParentComponent();
        //create sphere mesh with threeJS
        // let sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32);
        // let sphereMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00});
        // let sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
        // sphereMesh.position.set(0, 0, 0);
        // // sphereMesh.scale.set(0.1, 0.1, 0.1);
        // this.root.add(sphereMesh);

    }

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "ButtonComponent";
    }

    onInputsUpdated(oldInputs: this["inputs"], force:boolean): void {
        if(oldInputs?.bgColor !== this.inputs?.bgColor || force) {
            this.repaintTextures();
        }
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}