// import "./styles.css";
// import { useReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from "react-video-recorder";
import { store } from 'App';
import { firestore, storage } from '@crema/services/auth/firebase/firebase';
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { onUpdateTagGroup } from 'redux/actions/Step';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Switch, FormControlLabel, Button, Tooltip, IconButton } from '@material-ui/core/';
import React, { useEffect, useState } from 'react';
import { fetchStart, fetchSuccess, fetchError } from "redux/actions/Common";
import { appIntl } from "@crema/utility/Utils";
import Spacer from '@crema/core/Spacer';
import Utils from "mp/core/craEngine/Tools/Utils";

export default function VideoRecorder3({ onClose }) {
    const dispatch = useDispatch();
    const [showSave, setShowSave] = React.useState(false);
    const [showRecorder, setShowRecorder] = React.useState(true);
    const [videoBlob, setVideoBlob] = React.useState(false);
    // let tgOrg = getCurrentTagGroup();
    // let tg = JSON.parse(JSON.stringify(tgOrg));
    let tg = getCurrentTagGroup();
    const { messages } = appIntl();

    React.useEffect(() => tg?.video && setShowRecorder(false), [tg?.video]);

    return (console.log(`%c[st] rendering TG - ${tg.bannerText?.substring(0, Math.min(10, tg.bannerText.length))}  -- audio: ${tg.audioOnly}`,'color: orange;'),
        <div style={{ width: '100%' }}>
            {<Box style={{
                padding: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'
                // width: '100%'
            }}>
                {showSave && tg && <Button variant={'outlined'} size='small' color="secondary"
                    onClick={() => {

                        dispatch(fetchStart());
                        let lid = store.getState().layer.currentLesson?.id;
                        let tgid = store.getState().layer.currentTagGroupId;
                        let sid = store.getState().home.currentSpace?.id;

                        storage.ref().child(`Spaces/${sid}/lessons/${lid}/tagGroups/${tgid}/video`).put(videoBlob).then(async snapshot => {
                            let downloadURL = await snapshot.ref.getDownloadURL();
                            console.log(`[st] ${downloadURL} -- ${snapshot.ref.fullPath} `);
                            firestore.doc(`Spaces/${sid}/lessons/${lid}/tagGroups/${tgid}`)
                                .update({ video: downloadURL })
                                .then(() => {
                                    console.log(`[st] saved successfully`);
                                    tg.video = downloadURL;
                                    dispatch(fetchSuccess());
                                    tg && dispatch(onUpdateTagGroup(tg));
                                    onClose();
                                })
                                .catch((e) => {
                                    console.error(e);
                                    dispatch(fetchError(messages['message.somethingWentWrong']));
                                });
                            // Could not find a declaration file for module 'react-video-recorder'. '/Users/arpita/projects/oldCraVtc/node_modules/react-video-recorder/lib/index.js' implicitly has an 'any' type.
                            // Try `npm i --save-dev @types/react-video-recorder` if it exists or add a new declaration (.d.ts) file containing `declare module 'react-video-recorder';`
                        })

                    }}>
                    Save
                </Button >}
                {tg?.video && !showRecorder &&
                    <Button variant={'outlined'} size='small' color="primary"
                    // style={{padding}}
                        onClick={() => setShowRecorder(true)}>
                        Re-take
                    </Button>}
                    {<Spacer x={4} />}
                {tg?.video &&
                    <Button variant={'outlined'} size='small' color="primary"
                        onClick={() => {
                            tg.video = '';
                            tg.audioOnly = false;
                            tg && dispatch(onUpdateTagGroup(tg));
                        }}>
                        Delete
                    </Button>}
                {<Spacer x={4} />}
                {tg?.video && !showRecorder &&
                    <FormControlLabel
                        label='Audio Only'
                        control={<Switch
                            checked={tg?.audioOnly}
                            size="small"
                            onChange={(e) => {
                                // console.log(`[banner] audioOnly is ${tg.audioOnly}`)
                                tg.audioOnly = !tg.audioOnly;
                                tg && dispatch(onUpdateTagGroup(tg));
                                // spaceModels.size > 0 &&
                                // InputSubSystem.input.setMatterPortCameraMovement(lockView);
                                // setLockView(!lockView);

                            }}
                            name="lockViewSwitch"
                            color="primary"
                        />}
                    >
                    </FormControlLabel>}
            </Box>}

            {tg?.video && !showRecorder &&
                <video
                    //  className={classes.video}
                    width='100%'
                    // height='400px'
                    controls src={tg.video} autoPlay />}
            {showRecorder && <div style={{ width: '500px' }}><VideoRecorder
                //  style={{ minWidth: '100%' }}
                isOnInitially
                showReplayControls
                // useVideoInput
                onRecordingComplete={(videoBlob) => {
                    // Do something with the video...
                    console.log("videoBlob", videoBlob);
                    setShowSave(true);
                    setVideoBlob(videoBlob);
                    //   downloadURL
                }}
            /></div>}

        </div>
    );
}
