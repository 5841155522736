import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';

export class FlowMarkerComponent extends BaseComponent {
    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
        // throw new Error('Method not implemented.');
    }
    setNextInputValue(): void {
        // throw new Error('Method not implemented.');
    }
    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "FlowMarkerComponent";
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}