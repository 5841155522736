import { canEditSpace } from "@crema/utility/Utils";

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  disabled?: boolean;
  tooltip?: string;
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'spaces',
    title: 'Spaces',
    messageId: 'sidebar.spaces',
    type: 'item',
    icon: 'dashboard',
    url: '/home/spaces',
  },
  // {
  //   id: 'sharedSpaces',
  //   title: 'Shared Spaces',
  //   messageId: 'sidebar.sharedSpaces',
  //   type: 'item',
  //   icon: 'share',
  //   url: '/home/shared-spaces',
  // },
  // {
  //   id: 'reports',
  //   title: 'Reports',
  //   messageId: 'sidebar.reports',
  //   type: 'item',
  //   icon: 'widgets',
  //   url: '/reports',
  // },
  // {
  //   id: 'projects',
  //   title: 'Project Status',
  //   messageId: 'sidebar.apps.projectboard',
  //   type: 'item',
  //   icon: 'taskAlt',
  //   url: '/apps/projects',
  //   disabled: true,
  //   tooltip: 'This product is not enabled with your current plan' //TODO: fix this
  // },
];
routesConfig.push({
  id: 'admin',
  title: 'Admin',
  messageId: 'sidebar.admin',
  type: 'item',
  icon: 'widgets',
  url: '/admin/reports',
});
routesConfig.push({
  id: 'projects',
  title: 'Project Status',
  messageId: 'sidebar.apps.projectboard',
  type: 'item',
  icon: 'taskAlt',
  url: '/apps/projects',
  disabled: true,
  tooltip: 'This feature is not enabled with your current plan' //TODO: fix this
});

export default routesConfig;
