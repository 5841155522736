import {NotificationModel} from '../models/NotificationModel';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const READ_ALL_NOTIFICATION = 'READ_ALL_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export interface AddNotificationActions {
  type: typeof ADD_NOTIFICATION;
  payload: NotificationModel;
}

export interface ReadAllNotificationActions {
  type: typeof READ_ALL_NOTIFICATION;
  payload: string;
}

export interface ReadNotificationActions {
  type: typeof READ_NOTIFICATION;
  payload: string;
}

export interface GetNotificationsActions {
  type: typeof GET_NOTIFICATIONS;
  payload: {notifications: NotificationModel[]; notifCount: number};
}

export type NotificationActions =
  | AddNotificationActions
  | ReadAllNotificationActions
  | ReadNotificationActions
  | GetNotificationsActions;
