import { Tooltip, Box, makeStyles, useTheme, Typography, CardMedia, IconButton } from '@material-ui/core';
import React, { ReactNode } from 'react';
import IconHover from './AppAnimation/ItemHover/IconHover';
import * as Icons from '@mui/icons-material';
import { CremaTheme } from 'types/AppContextPropsType';
import { Card, Popover, Popper } from '@mui/material/';
import CloseIcon from '@material-ui/icons/Close';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

const useStyles = makeStyles((theme: CremaTheme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& .MuiSvgIcon-root': {
            margin: '4px',
            // fontSize: 30,
            cursor: 'pointer',
            color: theme.palette.grey[700],
            height: "19px",
            // width: "15px",
            '&:hover, &:focus': {
                color: '#FF3158',
            },
        },
        // transition: 'all .1s',
    },
    popover: {
        '& .MuiPopover-root': {
            width: '600px',
            // width: '600px !important',
            // maxWidth: '600px !important'

        }
    }
}));



export const HelpBadge = ({ tooltip }: { tooltip: string }) => {
    const classes = useStyles();
    return <Tooltip title={tooltip}
    >
        <Box className={classes.root} >
            <Icons.HelpOutlineRounded />
        </Box>
    </Tooltip>;
}

export const RichHelpBadge = ({ title, content, children, type = 'popover' }: {
    title: string,
    content?: { text: string, imgSrc?: string }[],
    children?: ReactNode,
    type?: 'popper' | 'popover'
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    function getComponent(type: string) {
        switch (type) {

            case 'popover': //closes on blur
                // console.log('getting popover')
                return <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onMouseLeave={() => setAnchorEl(null)}
                    style={{
                        // pointerEvents: 'none',
                        // maxHeight: '600px',
                        // overflow: 'scroll',
                        // maxWidth: '400px'
                    }}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}

                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Card style={{
                        maxWidth: '400px',
                        backgroundColor: theme.palette.background.paper,
                        padding: '16px'
                    }}>
                        <Box style={ {display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'} } >
                            {<Typography variant='body1' gutterBottom style={{ fontWeight: 'bold' }}>
                                {title || '-'}
                            </Typography>}
                            <IconButton
                                style={{ pointerEvents: 'all' }}
                                aria-label='close'
                                color='inherit'
                                size='small'
                                onClick={() => {
                                    setAnchorEl(null);
                                }}>
                                <CloseIcon fontSize='inherit' />
                            </IconButton>

                        </Box>
                        <ul>
                            {content && content.length > 0 && content.map((c, i) => <li key={i}>
                                <Typography variant='body2'>
                                    {c.text}
                                </Typography>

                                {c.imgSrc && <CardMedia
                                    style={{ maxWidth: '300px' }}
                                    component="img"
                                    alt={c.text}
                                    // height="140"
                                    image={c.imgSrc}
                                />
                                }
                                {/* {c.imgSrc && <Image src={c.imgSrc} alt={c.text}/>} */}
                            </li>)}
                        </ul>
                        {children}
                    </Card>
                </Popover>

            case "popper": //closes on blur
            default:
                return <Popper
                    open={open}
                    anchorEl={anchorEl}
                    // onClose={() => setAnchorEl(null)}
                    // onMouseLeave={() => setAnchorEl(null)}
                    style={{
                        // pointerEvents: 'none',
                    }}
                    className={classes.popover}
                    // anchorOrigin={{
                    //     vertical: 'bottom',
                    //     horizontal: 'left',
                    // }}
                    placement="bottom-end"
                // popperOptions={{
                //     vertical: 'top',
                //     horizontal: 'left',
                // }}
                >
                    <Box style={{
                        width: '600px',
                        backgroundColor: theme.palette.background.paper,
                        padding: '16px'
                    }}>
                        {title && <Typography variant='body2' gutterBottom style={{ fontWeight: 'bold' }}>
                            {title}
                        </Typography>}
                        {content && content.length > 0 && content.map(c => <>
                            <Typography variant='caption'>
                                {c.text}
                            </Typography>
                            {c.imgSrc && <img src={c.imgSrc} alt={c.text} />}
                        </>)

                        }

                        {children}
                    </Box>
                </Popper>
        }

    }
    useBottomScrollListener(() => {}, 200);
    return <Box>

        {getComponent(type)}
        <Box className={classes.root}
            // onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            onClick={(e) => setAnchorEl(e.currentTarget)}
        // onMouseLeave={() =>
        //     setAnchorEl(null)
        // }
        >
            <Icons.HelpOutlineRounded />
        </Box>
    </Box>
        ;
}

export const UnderlineHelpBadge = ({ text, children, type = 'popover' }: {
    text: string,
    children: ReactNode,
    type?: 'popper' | 'popover'
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    return <Box>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            onMouseLeave={() => setAnchorEl(null)}
            style={{
                pointerEvents: 'none',
            }}
            className={classes.popover}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box style={{
                width: '600px',
                backgroundColor: theme.palette.background.paper,
                padding: '16px'
            }}>
                {children}
            </Box>
        </Popover>
        <Box
            style={{ borderBottom: '1.5px dashed darkgrey' }}
            // className={classes.root}
            // onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            onMouseLeave={() =>
                setAnchorEl(null)
            }
        >
            {text}

        </Box>
    </Box>
        ;
}

// export function MouseOverPopover() {
//     const classes = useStyles();
//     const theme = useTheme();

//     const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

//     const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handlePopoverClose = () => {
//         setAnchorEl(null);
//     };

//     const open = Boolean(anchorEl);

//     return (
//         <div>
//             <Box className={classes.root}
//                 onMouseEnter={(e) => {
//                     console.log(`[popper] entering Icon box`)
//                     setAnchorEl(e.currentTarget)
//                 }}
//                 onMouseLeave={() => setAnchorEl(null)}
//             >
//                 <Icons.HelpOutlineRounded />
//             </Box>
//             <Popover
//                 id="mouse-over-popover"
//                 style={{
//                     pointerEvents: 'none',
//                 }}
//                 open={open}
//                 anchorEl={anchorEl}
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                     vertical: 'top',
//                     horizontal: 'left',
//                 }}
//                 onClose={handlePopoverClose}
//                 disableRestoreFocus
//             >
//                 <Typography style={{ padding: 1 }}>I use Popover.</Typography>
//             </Popover>
//         </div>
//     );
// }