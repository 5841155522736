import { useDispatch, useSelector } from 'react-redux';
import { createRef, useEffect, useState } from 'react';
import { auth as firebaseAuth, userRepo } from '../services/auth/firebase/firebase';
import {
  fetchError,
  fetchSuccess,
  getUserObject,
} from '../../redux/actions';
import { AppState } from '../../redux/store';
import { UPDATE_AUTH_USER } from '../../types/actions/Auth.actions';
import { AuthUser } from '../../types/models/AuthUser';
import { useHistory } from 'react-router-dom';
import { tryCatch } from './Utils';

export const useAuthToken = (): [boolean, AuthUser | null] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
  const history = useHistory();

  useEffect(() => {
    // console.log(`[st] env is: ${window.location.hostname} . ${Object.entries(window.location)}`)
    const firebaseCheck = () =>
      new Promise<void>(resolve => {
        firebaseAuth.onAuthStateChanged(async authUser => {

          if (authUser && authUser.emailVerified) {

            try {
              // dispatch(setUserOnSignIn(authUser));
              // setLoading(false);

              // doing this instead of calling dispatch(setUserOnSignIn(authUser)) prevents the routes from jumping to /signin and back
              let user = await userRepo.get(authUser.uid)
              let authUserObj = getUserObject(authUser, user);

              dispatch(fetchSuccess());
              dispatch({
                type: UPDATE_AUTH_USER,
                payload: authUserObj,
              });

            } catch (err) {
              console.error(`[st] error in app hooks`)

              dispatch(fetchError("A user could not be found with these credentials. Please try signing up. If an account was previously associated with this login, those accounts will be linked."));
              history.push('/signin');
            }

          } else {
            tryCatch(() => (window as any).Beacon('logout'), 'BeaconLogout');
          }
          resolve();
        });
        return Promise.resolve();
      });

    const checkAuth = () => {
      Promise.all([firebaseCheck(),
      ]).then(() => {
        setLoading(false);
      });

    };
    checkAuth();
  }, [dispatch]);


  return [loading, authUser];
};

export const useAuthUser = (): AuthUser | null => {
  // const history = useHistory();
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
  //TODO what is email is not verified?
  if (authUser) {
    return authUser;
  }
  return null;
};

// export const useUserAdmin = () => {
//   const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
//   return authUser?.user?.permissions?.includes('ADMIN');
// }

// export const useUserInExperiment = (experimentName: string) => {
//   const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);
//   return authUser?.user?.permissions?.includes(experimentName);
// }

// export const useHistoryPush = (route: string) => {
//   const history = useHistory();
//   // history.push(route);
//   return null;
// };


export const useHistoryPush = (): AuthUser | null => {
  const history = useHistory();
  const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser)
  //TODO what is email is not verified?
  if (authUser) {
    return authUser;
  }
  return null;
};


export const useOnClickOutside = (ref: any, handler: any) => {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}
