import { store } from "App";
import { SceneNode } from "CustomSdk/Mimick/SceneNode";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { Behaviors } from "mp/core/craEngine/SubSystems/core/Behaviors";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import { ActionType, TriggerActionOutcome } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions";
import { onSetVariableValue, showMessage } from "redux/actions";
import { handleGoToTagGroupByIndex } from "redux/actions/Step";
import { TagGroup } from "../home/HomeApp";
import { CompInfo } from "modules/home/SpaceDetail/Interactions/LogicTrees";
import { NodeCollider, NodeCompCollider, NodeSubObject } from "mp/core/craEngine/spies/DragSpies";
import { WireTerminal } from "mp/core/craEngine/components/Wire/WireTerminal";
import { SceneNodeBase } from "mp/core/craEngine/components/SceneNodeBase/SceneNodeBase";

export interface Logic {
    conditions: ConditionTree[];
}
export interface ConditionTree {
    id?: string, // Identify Condition Tree
    conditionRoot: NestedCondition;
    actions: Action[];
}
export enum CONDITION_TYPE {
    VARIABLE_CONDITION = "Variable Condition",
    OBJECT_CONDITION = "Object Condition",
    NESTED_TREE = "Nested Tree"
}

export interface ReadableCondition {

    // id: string;
    type: CONDITION_TYPE;
    objectEventType?: OBJECT_EVENT_TYPES;
    // objectId?: string;
    // objectId2?: string;
    // subObject1Name?: string;
    // subObject2Name?: string;
    // compInfo?: CompInfo;
    varName?: string;
    varValue?: string;
    // valueOptions: Array<any>;
    // nestedCondition?: NestedCondition;
    parameters?: string; // could be x,y,z or key,value string

    // public getFromICondition() {
    //     return
    // }
}
export abstract class Condition {
    type: CONDITION_TYPE;
    toString() {
        return JSON.stringify(this)
        // return `Action: ${this.actionType} for $ {this.objectId} ${this.iotVariableName ? (', iot var ' + this.iotVariableName) : ''} `
    }
    public static serializeConditionTree(condition: Condition): string {
        return JSON.stringify(Condition.serialize(condition));
    }

    private static serialize(condition: Condition): ReadableCondition {

        let c: any = {};

        if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
            c = {
                // conditionList.push({
                // id: 't' + tIndex + condition.varName + condition.varValue + index,
                varName: (condition as VarCondition).varName, //should exist
                varValue: (condition as VarCondition).varValue, //should exist
                type: (condition as VarCondition).type, //should exist, check
                // valueOptions: getValueOptions(condition.varName),
                // });
            } as ReadableCondition;
        } else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {

            let objects = NodeCollider.getFromCollidesCondition(condition as ObjectCondition);
            c = {
                // id: 't' + tIndex + condition.objectEventType + index,
                objectEventType: (condition as ObjectCondition).objectEventType,
                type: CONDITION_TYPE.OBJECT_CONDITION,
                objectId: objects[0]?.getReadableObjectId(),
                objectId2: objects[1]?.getReadableObjectId(),
                parameters: (condition as ObjectCondition).parameters,
                // compInfo: (condition as ObjectCondition).compInfo,
                // valueOptions: [],
            } as ReadableCondition;
        } else if (condition.type == CONDITION_TYPE.NESTED_TREE) {

            let nestedTreeCondition = (condition as TreeCondition);
            let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;

            c = {
                // id: 't' + tIndex + '-nested-tree' + index,
                nestedCondition: { conditionJoiner: nestedCondition.conditionJoiner, conditions: nestedCondition.conditions.map(c => Condition.serialize(c)) },
                type: CONDITION_TYPE.NESTED_TREE,
                // valueOptions: [],
            } as ReadableCondition;
        }
        return c;
    }
    // public static deserializeNestedCondition(tIndex: string, conditionString: string): Condition | undefined {

    // }
    public static deserialize(conditionString: string): Condition | undefined {
        let condition = JSON.parse(conditionString) as Condition;
        //     let tRootLogic = conditionsTree.joiner;
        //     // let root = {
        //     // 	'joiner': joiner, 'conditions': []
        //     // }

        let conditionList: Condition[] = [];
        // conditionsTree.conditions.map((condition: any, index: number) => {
        let c: any = {};
        // conditions.forEach((condition: any, index) => {
        // if (condition.type == CONDITION_TYPE.VARIABLE_CONDITION) {
        //     c = {
        //         id: 't' + tIndex + condition.varName + condition.varValue + index,
        //         varName: condition.variableName, //should exist
        //         varValue: condition.varValue, //should exist
        //         type: condition.conditionType, //should exist, check
        //         valueOptions: getValueOptions(condition.varName),
        //     };
        // } else if (condition.type == CONDITION_TYPE.OBJECT_CONDITION) {
        //     c = {
        //         id: 't' + tIndex + condition.objectEventType + index,
        //         objectEventType: condition.objectEventType,
        //         type: CONDITION_TYPE.OBJECT_CONDITION,
        //         objectId: condition.object ==> id,
        //         objectId2: condition.object2 ==> id,
        //         parameters: condition.parameters,
        //         compInfo: condition.compInfo,
        //         valueOptions: [],
        //     };
        // } else if (condition.type == CONDITION_TYPE.NESTED_TREE) {
        //     let nestedTreeCondition = (condition as TreeCondition);
        //     let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;

        //     c = {
        //         id: 't' + tIndex + '-nested-tree' + index,
        //         nestedCondition: { conditionJoiner: nestedCondition.conditionJoiner, conditions: nestedCondition.conditions.map(c => Condition.serialize(c)) },
        //         type: CONDITION_TYPE.NESTED_TREE,
        //         valueOptions: [],
        //     };
        // }

        return condition;
    }
}
export interface VarCondition extends Condition {
    varName: string;
    varValue: string;
}
export interface ObjectCondition extends Condition {
    objectId?: string;
    objectId2?: string;
    objectEventType: OBJECT_EVENT_TYPES;
    parameters?: string; // could be x,y,z or key,value string
    compInfo?: CompInfo;
    subObject1Name?: string;
    subObject2Name?: string;
    localCollidingPoint1?: { x: number, y: number, z: number };
    localCollidingPoint2?: { x: number, y: number, z: number };

    // objects?: NodeCollider[];
    // object2: {id: string, componentIndex: number, subObjectName: string}
}
// export interface ObjectComponentCondition extends Condition {
//     objectId?: string;
//     objectId2?: string;
//     objectEventType: OBJECT_EVENT_TYPES;
//     parameters?: string; // could be x,y,z or key,value string
//     compInfo?: CompInfo;
//     subObject1Name?: string;
//     subObject2Name?: string;
// }
// export class ObjectConditionClass extends Condition implements ObjectCondition {

//     constructor(
//         public type: CONDITION_TYPE,
//         public objectEventType: OBJECT_EVENT_TYPES,
//         public objectId?: string | undefined,
//         public objectId2?: string | undefined,
//         public parameters?: string | undefined,
//         public compInfo?: CompInfo | undefined,
//     ) {
//         super();
//     }
//     serialize(): void {

//     }

// }
export interface TreeCondition extends Condition {
    nestedCondition: NestedCondition
}

export enum OBJECT_EVENT_TYPES {
    CLICK = "Click",
    COLLIDES = "Collides",
    OBJECT_PROPERTY = "Object Property is"
    // HOVER = "Hover - coming soon"
}
export interface NestedCondition {
    conditionJoiner: CONDITION_JOINER;
    conditions: Condition[];
}

export abstract class Action {
    type: ACTION_TYPE;
    toString() {
        return JSON.stringify(this)
        // return `Action: ${this.actionType} for ${this.objectId} ${this.iotVariableName ? (', iot var ' + this.iotVariableName) : ''} `
    }
}

export enum ACTION_TYPE {
    VARIABLE_ACTION = "Variable action",
    OBJECT_ACTION = "Object action",
    NEXTSTEP_ACTION = "NextStep action",
    WAIT_ACTION = "Wait Action",
    // PLAY_AUDIO = "Play Audio",
    // STOP_AUDIO = "Stop Audio",
}
export class ObjectAction extends Action {
    actionType: OBJECT_ACTIONS;
    objectId: string;
    iotVariableName?: string;
    parameters?: string;
}

export class VarAction extends Action {
    varName: string;
    varValue: string;
}
export class NextStepAction extends Action {
    currentTagGroupId: string;
    waitInSeconds: number;
}
export enum CONDITION_JOINER_LEGACY { ALL = "All" }
export enum CONDITION_JOINER { ALL = "And", ANY = "Or", NONE = "None" }
export enum OBJECT_ACTIONS {
    HIGHLIGHT = "Highlight", GROW_SHRINK = "Animate", SHOW = "Show", HIDE = "Hide", MOVE = "Move", ROTATE = "Rotate", SCALE = "Scale",
    SET_CUSTOM_PROP = "Set Property",
    // CALL_ACTIVATE = "Show values on Digital Display"
}

export const conditionsTest = async () => {

    let c1: VarCondition = { varName: "Material", varValue: "Copper", type: CONDITION_TYPE.VARIABLE_CONDITION };
    let c2: VarCondition = { varName: "Diameter", varValue: "1/2 inch", type: CONDITION_TYPE.VARIABLE_CONDITION };

    let orCondition: NestedCondition = { conditions: [c1, c2], conditionJoiner: CONDITION_JOINER.ALL }

    let a1: ObjectAction = { actionType: OBJECT_ACTIONS.SHOW, objectId: "someId", type: ACTION_TYPE.OBJECT_ACTION };
    let a2: VarAction = { varName: "System Pressure", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION };
    let a3: VarAction = { varName: "Lever State", varValue: "ON", type: ACTION_TYPE.VARIABLE_ACTION };

    let mainCondition1Nested: ConditionTree = { conditionRoot: orCondition, actions: [a1, a2] };

    let logic: Logic = {
        conditions: [
            mainCondition1Nested
        ]
    }
    console.log(`%c[st] logic`, logic);
}

export class LogicEngine {
    public static async onVariablesStateChange(tg: TagGroup,
        /*allModels: Map<string, any> = Simulation.instance.spaceModels(),*/
    ): Promise<void> {

        // if(currentSpaceId) {
        //     if(currentLessonId && currentTagGroupId) {
        //         let docRef = await firestore.doc(`Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroupId}`);
        //         let doc = await docRef.get();
        //         let data = await doc.data();

        //         if(data ) {


        if (tg.logic) {
            LogicEngine.processStepLogicConditions(
                { tgId: tg.id, stepLogic: tg.logic as Logic }
            );
        }
        //         }
        //     }
        // }
        /*
        allModels.forEach((model: any) => {
            let targetNode: ISceneNode =  model.nodeRef;//
            if(targetNode){
                //LogicEngine.processSceneNodeLogicConditions(targetNode, false, false, Simulation.instance.spaceModels(), variableValues);
            }
        });*/
    }

    public static evaluateNestedCondition({
        nestedCondition,
        targetNode,
        // clickEvent: boolean = false, hoverEvent: boolean = false,
        eventType = null,
        allModels = Simulation.instance.spaceModels(),
        variableValues = store.getState().layer.variableValues,
        targetNodeList = [],
        subObjectName = ''
    }: {
        nestedCondition: NestedCondition,
        targetNode: ISceneNode | SceneNode | null,
        // clickEvent: boolean = false, hoverEvent: boolean = false,
        eventType: OBJECT_EVENT_TYPES | null,
        allModels: Map<string, any>,
        variableValues: any[] | undefined,
        targetNodeList: string[],
        subObjectName: string
    },
    ): boolean {
        let conditionMet: boolean = false;

        if (!nestedCondition.conditions || nestedCondition.conditions.length == 0) {
            return true;
        }
        if (nestedCondition.conditionJoiner === CONDITION_JOINER.ALL || (nestedCondition.conditionJoiner as string) === 'All') {
            nestedCondition.conditionJoiner = CONDITION_JOINER.ALL;

            if (nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (!LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList,
                            subObjectName
                        }
                    )) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.ANY) {
            if (nestedCondition.conditions) {
                conditionMet = false;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList,
                            subObjectName
                        }
                        // clickEvent, hoverEvent
                    )) {
                        conditionMet = true;
                        break;
                    }
                }
            }
        } else if (nestedCondition.conditionJoiner === CONDITION_JOINER.NONE) {
            if (nestedCondition.conditions) {
                conditionMet = true;

                for (const subConditionElement of nestedCondition.conditions) {
                    if (LogicEngine.checkCondition(
                        {
                            targetNode: targetNode, allModels: allModels, variableValues: variableValues,
                            condition: subConditionElement, eventType: eventType, targetNodeList: targetNodeList,
                            subObjectName
                        }
                        // clickEvent, hoverEvent
                    )) {
                        conditionMet = false;
                        break;
                    }
                }
            }
        }

        return conditionMet;
    }

    public static processStepLogicConditions({
        tgId,
        stepLogic,
        targetNode = null,
        targetNodeList = [],
        eventType = null,
        subObjectName = '',
        allModels = Simulation.instance.spaceModels(),
        variableValues = store.getState().layer.variableValues,
    }: {
        tgId: string,
        stepLogic: Logic,
        targetNode?: ISceneNode | SceneNode | null,
        targetNodeList?: string[],
        eventType?: OBJECT_EVENT_TYPES | null,
        subObjectName?: string,
        // clickEvent: boolean = false, hoverEvent: boolean = false, collideEvent = false,
        allModels?: Map<string, any>,
        variableValues?: any[] | undefined,
    }
    ): void {

        //  if (getCurrentTagGroup()?.id !== tgId) { //TODO commenting this out -> might not be right
        //      return;
        //  }

        if (stepLogic?.conditions) {
            // console.trace();
            let conditionTree = stepLogic.conditions as ConditionTree[];
            conditionTree.forEach((condition: ConditionTree) => {

                //performActions(condition.actions);
                let nestedCondition = condition.conditionRoot as NestedCondition;
                console.log(`%c[vars] Checking condition:`, 'color: grey;', nestedCondition);
                if (LogicEngine.evaluateNestedCondition({
                    nestedCondition: nestedCondition,
                    targetNode: targetNode,
                    eventType: eventType,
                    allModels: allModels,
                    variableValues: variableValues,
                    targetNodeList: targetNodeList,
                    subObjectName: subObjectName
                })) {
                    //Perform actions
                    console.log(`%c[vars] Performing actions`, 'color: grey;', condition.actions);
                    LogicEngine.performActions(tgId, condition.actions, variableValues, allModels);
                } else {
                    // LogicEngine.performUnActions(tgId, condition.actions, variableValues, allModels);
                }
            });
        }
    }

    public static checkCondition({ targetNode, allModels, variableValues, condition, eventType = null,
        targetNodeList, subObjectName }:
        {
            targetNode: ISceneNode | SceneNode | null,
            allModels: Map<string, any>,
            variableValues: any[] | undefined,
            condition: Condition,
            eventType: OBJECT_EVENT_TYPES | null,
            targetNodeList: string[],
            subObjectName: string
        },
    ): boolean {
        if (condition.type === CONDITION_TYPE.OBJECT_CONDITION) {
            let objectCondition = (condition as ObjectCondition);

            if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.CLICK) {
                if (targetNode) {
                    if (eventType == OBJECT_EVENT_TYPES.CLICK) {

                        if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                            if (!!objectCondition.subObject1Name) {
                                return objectCondition.subObject1Name === subObjectName;
                            } else {
                                return true;
                            }
                        }
                    }
                }
                // } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.HOVER) {
                //     if (targetNode) {
                //         if (eventType == OBJECT_EVENT_TYPES.HOVER) {
                //             if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
                //                 return true;
                //             }
                //         }
                //     }
            } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.COLLIDES) {
                let result = false;
                if (targetNodeList.length > 0) {
                    if (eventType == OBJECT_EVENT_TYPES.COLLIDES) {

                        if (objectCondition.objectId && objectCondition.objectId2) {

                            let pair = NodeCollider.getFromCollidesCondition(objectCondition);

                            if (pair[0] && pair[1]) {
                                let conditionObjectsPair = pair as NodeCollider[];

                                if (conditionObjectsPair[0]?.getCompositeObjectId() && targetNodeList.includes(conditionObjectsPair[0].getCompositeObjectId())
                                    && conditionObjectsPair[1]?.getCompositeObjectId() && targetNodeList.includes(conditionObjectsPair[1].getCompositeObjectId())
                                ) {
                                    // if (conditionObjectsPair[1].node?.name !== 'gltf') {
                                    let collidedWith: NodeCollider | NodeCompCollider | NodeSubObject | undefined = undefined;
                                    if (Simulation.instance.draggedObject?.getCompositeObjectId() === conditionObjectsPair[0].getCompositeObjectId()) {
                                        collidedWith = conditionObjectsPair[1];

                                    } else if (Simulation.instance.draggedObject?.getCompositeObjectId() === conditionObjectsPair[1].getCompositeObjectId()) {
                                        collidedWith = conditionObjectsPair[0];
                                    }
                                    if (Simulation.instance.draggedObject?.node?.name === 'gltf' &&
                                        collidedWith?.node?.name === 'Box Highlight') {// is box and battery and box isnt occupied
                                        // see if anythign is occupying collidedWITH

                                        let isFree = true;
                                        [...store.getState().home.spaceModels.values()].forEach((model) => {
                                            if (collidedWith?.getReadableObjectId() === SceneNodeBase.getObjectPropValue(model.nodeRef?.userData?.id || '', 'Occupying')) {
                                                isFree = false;
                                            }
                                        });
                                        if (isFree) {
                                            Simulation.instance.draggedObject?.setPositionFrom(conditionObjectsPair, objectCondition);
                                            Simulation.instance.draggedObject = null;
                                            return true;
                                        }
                                    } else {

                                        Simulation.instance.draggedObject?.setPositionFrom(conditionObjectsPair, objectCondition);
                                        Simulation.instance.draggedObject = null;
                                    }

                                    // if(){ dragged object is part of miultimeter

                                    // }
                                    //if some var says both connected, then show random value on multimeter with node.component[9].inputs.text


                                    return true;
                                }
                            }
                        }
                        return false;
                    }
                }


            } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.OBJECT_PROPERTY) {

                if (objectCondition.objectId) {

                    let objectPropValue = SceneNodeBase.getObjectPropValue(objectCondition.objectId, SceneNodeBase.getPropKeyFromParams(objectCondition.parameters || ''));// store.getState().home.spaceModels.get(objectCondition.objectId)?.userData[getPropKeyFromParams(objectCondition.parameters || '')];
                    if (objectPropValue === SceneNodeBase.getPropValueFromParams(objectCondition.parameters || '')) {
                        return true;
                    }
                }

            }
        } else if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
            // console.log(`%cchecking var condition `, 'color: grey;');
            let variableCondition = (condition as VarCondition);
            if (variableCondition.varName) {
                let relevantSystemVariable = variableValues?.find(vv => vv.name.toLowerCase() === variableCondition.varName.toLowerCase());

                console.log(`%c[vars] for var condition`, 'color: grey;', variableCondition)
                if (relevantSystemVariable) {
                    if (relevantSystemVariable.hasOwnProperty("value")) {
                        if ((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
                            if (relevantSystemVariable.value === variableCondition.varValue) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
            let nestedTreeCondition = (condition as TreeCondition);

            let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;
            return LogicEngine.evaluateNestedCondition({
                nestedCondition: nestedCondition, targetNode: targetNode,
                eventType: eventType, allModels: allModels, variableValues: variableValues, targetNodeList: targetNodeList,
                subObjectName
            });
            // clickEvent, hoverEvent,

        }

        return false;
    }

    public static performUnActions(tgId: string, actions: Action[], variableValues: any[] | undefined, allModels: Map<string, any>): void {
        // if (getCurrentTagGroup()?.id !== tgId) {
        //     return;
        // }
        // console.log(`%c[vars] performing actions ${targetNode.userData?.nameToShow} -- ${JSON.stringify(actionOutcome)}`)
        actions.forEach(action => {
            if (action.type === ACTION_TYPE.OBJECT_ACTION) {
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };

                switch (objectAction.actionType) {
                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    // case OBJECT_ACTIONS.CALL_ACTIVATE:
                    //     triggerActionOutcome.actionType = ActionType.CallActivate;
                    //     break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if (model) {
                    let localNodeForAction = model.nodeRef as ISceneNode;
                    // if (objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                    //     localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    // }
                    Behaviors.runUnActionsOnNode(localNodeForAction, triggerActionOutcome);
                }
            }
        });
    }

    public static performActions(tgId: string, actions: Action[], variableValues: any[] | undefined, allModels: Map<string, any>): void {
        // if (getCurrentTagGroup()?.id !== tgId) {
        //     return;
        // }

        // console.log('%c[vars] performing actions', 'color: grey;');
        actions.forEach(action => {
            if (action.type === ACTION_TYPE.OBJECT_ACTION) {
                console.log(`%c[vars] logic obj action -- `, action);
                let objectAction = action as ObjectAction;

                let triggerActionOutcome: TriggerActionOutcome = {
                    actionType: ActionType.Show,
                    parameter: ""
                };

                switch (objectAction.actionType) {
                    case OBJECT_ACTIONS.SHOW:
                        triggerActionOutcome.actionType = ActionType.Show;
                        break;
                    case OBJECT_ACTIONS.HIDE:
                        triggerActionOutcome.actionType = ActionType.Hide;
                        break;
                    case OBJECT_ACTIONS.HIGHLIGHT:
                        triggerActionOutcome.actionType = ActionType.Highlight;
                        break;
                    case OBJECT_ACTIONS.GROW_SHRINK:
                        triggerActionOutcome.actionType = ActionType.GrowShrinkAnimate;
                        break;
                    // case OBJECT_ACTIONS.CALL_ACTIVATE:
                    //     triggerActionOutcome.actionType = ActionType.CallActivate;
                    //     break;
                    case OBJECT_ACTIONS.MOVE:
                        triggerActionOutcome.actionType = ActionType.Move_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.ROTATE:
                        triggerActionOutcome.actionType = ActionType.Rotate_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.SCALE:
                        triggerActionOutcome.actionType = ActionType.Scale_Parameterized;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                    case OBJECT_ACTIONS.SET_CUSTOM_PROP:
                        triggerActionOutcome.actionType = ActionType.Set_Custom_Prop;
                        triggerActionOutcome.parameter = objectAction.parameters!;
                        break;
                }

                //objectAction.objectId
                let model = allModels?.get(objectAction.objectId);

                if (model) {
                    let localNodeForAction = model.nodeRef;// as ISceneNode;

                    // if (objectAction.actionType == OBJECT_ACTIONS.CALL_ACTIVATE) {
                    //     localNodeForAction.userData[UserDataProperties.inputSource1] = objectAction.iotVariableName;
                    // }
                    Behaviors.runActionsOnNode(localNodeForAction, triggerActionOutcome);
                }
            } else if (action.type === ACTION_TYPE.VARIABLE_ACTION) {
                //This throws Error: Reducers may not dispatch actions.", don't know why, but setTimeout fixes it
                //store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: (action as any).varName, value: (action as any).varValue} });
                console.log(`%c[vars] Var Action found in perfActions:`, 'color: grey;', action);
                let variableAction = action as VarAction;

                //This is needed to check if the variable is actually unchanged
                if (variableAction.varName) {
                    let localSystemVariable = variableValues!.find(element => (element.name as string).toLowerCase() === (variableAction.varName as string).toLowerCase());


                    if (localSystemVariable) {
                        console.log(`%cCHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        // setTimeout(() =>
                        store.dispatch(onSetVariableValue(variableAction.varName, variableAction.varValue))
                        // 100);
                        /*
                        if(localSystemVariable.value === variableAction.varValue) {
                            console.log(`%cNO CHANGE: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        } else {
                            setTimeout(()=> store.dispatch({ type: SET_VARIABLE_VALUE, payload: { name: variableAction.varName, value: variableAction.varValue } }),
                            100);
                            console.log(`%cCHANGED: set var ${variableAction.varName} to ${variableAction.varValue}`, 'color: grey;');
                        }*/
                    }
                } else {
                    console.log(`%c[st] A variable action was not saved properly`)
                }

            } else if (action.type === ACTION_TYPE.NEXTSTEP_ACTION) {
                let tg = getCurrentTagGroup();

                let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;


                let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`%c[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000, 'color: grey;');

                setTimeout(() => {
                    if (store.getState().layer.presentationMode) {
                        store.dispatch(handleGoToTagGroupByIndex(nextTagGroupIndex));
                    } else {
                        store.dispatch(showMessage("Auto-moving to next step suppresed since you are in Studio mode. Press Next to continue"));
                    }

                }, (nextStepAction.waitInSeconds || 0) * 1000);

            } else if (action.type === ACTION_TYPE.WAIT_ACTION) {
                // console.log(`%c[st] logic sleeping `, 'color: grey;');

                // let nextStepAction = action as NextStepAction;
                // setTimeout(()=> {console.log(`[st] logic waking `)}, (nextStepAction.waitInSeconds || 0) * 1000, 'color: grey;');
                // sleep(3000);
                // let tg = getCurrentTagGroup();

                // let nextTagGroupIndex = tg ? tg.sortIndex + 1 : 0;
                // store.dispatch(handleGoToTagGroup(nextTagGroupIndex));
            }

        });
    }
}


export function getAllConditions(stepLogic: Logic) {
    let conds: Condition[] = [];
    if (stepLogic?.conditions) {
        let conditionTree = stepLogic.conditions as ConditionTree[];

        conditionTree.forEach((condition: ConditionTree) => {

            let nestedCondition = condition.conditionRoot as NestedCondition;
            conds.push(...getNestedCondition(nestedCondition));
        });
    }
    return conds;
}

function getNestedCondition(nestedCondition: NestedCondition): Condition[] {

    let conds: Condition[] = [];
    if (!nestedCondition.conditions || nestedCondition.conditions.length == 0) {
        return conds;
    }

    for (const subConditionElement of nestedCondition.conditions) {
        if (subConditionElement.type === CONDITION_TYPE.NESTED_TREE) {
            let nestedTreeCondition = (subConditionElement as TreeCondition);
            let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;
            conds.push(...getNestedCondition(nestedCondition));

        } else {
            conds.push(subConditionElement);
        }
    }

    return conds;
}


// function getCondition({ condition, }:
//     {
//         condition: Condition,
//     },
// ): boolean {
//     if (condition.type === CONDITION_TYPE.OBJECT_CONDITION) {
//         let objectCondition = (condition as ObjectCondition);

//         if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.CLICK) {
//             if (targetNode) {
//                 if (eventType == OBJECT_EVENT_TYPES.CLICK) {
//                     if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
//                         return true;
//                     }
//                 }
//             }
//             // } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.HOVER) {
//             //     if (targetNode) {
//             //         if (eventType == OBJECT_EVENT_TYPES.HOVER) {
//             //             if (objectCondition.objectId === targetNode.userData[UserDataProperties.id]) {
//             //                 return true;
//             //             }
//             //         }
//             //     }
//         } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.COLLIDES) {
//             if (targetNodeList.length > 0) {
//                 if (eventType == OBJECT_EVENT_TYPES.COLLIDES) {
//                     if (objectCondition.objectId && objectCondition.objectId2 &&
//                         targetNodeList.includes(objectCondition.objectId)
//                         && targetNodeList.includes(objectCondition.objectId2)) {
//                         return true;
//                     }
//                 }

//             }
//         } else if (objectCondition.objectEventType === OBJECT_EVENT_TYPES.OBJECT_PROPERTY) {

//             if (objectCondition.objectId) {

//                 let objectPropValue = getObjectPropValue(objectCondition.objectId, getPropKeyFromParams(objectCondition.parameters || ''));// store.getState().home.spaceModels.get(objectCondition.objectId)?.userData[getPropKeyFromParams(objectCondition.parameters || '')];
//                 if (objectPropValue === getPropValueFromParams(objectCondition.parameters || '')) {
//                     return true;
//                 }
//             }

//         }
//     } else if (condition.type === CONDITION_TYPE.VARIABLE_CONDITION) {
//         // console.log(`%cchecking var condition `, 'color: grey;');
//         let variableCondition = (condition as VarCondition);
//         if (variableCondition.varName) {
//             let relevantSystemVariable = variableValues?.find(vv => vv.name.toLowerCase() === variableCondition.varName.toLowerCase());

//             console.log(`%c[vars] for var condition`, 'color: grey;', variableCondition)
//             if (relevantSystemVariable) {
//                 if (relevantSystemVariable.hasOwnProperty("value")) {
//                     if ((relevantSystemVariable.value as string != null) && (relevantSystemVariable.value as string).length > 0) {
//                         if (relevantSystemVariable.value === variableCondition.varValue) {
//                             return true;
//                         }
//                     }
//                 }
//             }
//         }
//     } else if (condition.type === CONDITION_TYPE.NESTED_TREE) {
//         let nestedTreeCondition = (condition as TreeCondition);

//         let nestedCondition = nestedTreeCondition.nestedCondition as NestedCondition;
//         return LogicEngine.evaluateNestedCondition({
//             nestedCondition: nestedCondition, targetNode: targetNode,
//             eventType: eventType, allModels: allModels, variableValues: variableValues, targetNodeList: targetNodeList
//         });
//         // clickEvent, hoverEvent,

//     }

//     return false;
// }