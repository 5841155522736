export default class QueueScheduler<Type> {

    public processPending(dt:number):void {
        if(this.queueLength <= 0) {
            this.internalDelayCounter = 0;
            return;
        } else {
            this.internalDelayCounter += dt;

            if(this.internalDelayCounter >= this.processDelay) {
                while(this.queueLength-- > 0) {
                    let queueOutElement = this.queue.pop();
                    if(queueOutElement) {
                        if(!this.callback(queueOutElement)) {
                            this.queueLength = this.queue.unshift(queueOutElement);
                            break;
                        }
                        break;
                    }
                }

                this.internalDelayCounter -= this.processDelay;
            }
        }
    }

    public addQueueElement(queueInElement:Type, checkRepeating:boolean):void {
        if(checkRepeating) {
            if(this.queueLength < 1) {
                this.queueLength = this.queue.push(queueInElement);
            } else {
                if(this.queue[this.queueLength - 1] == queueInElement) {
                    this.internalDelayCounter = 0;
                } else {
                    this.queueLength = this.queue.push(queueInElement);
                }
            }
        } else {
            this.queueLength = this.queue.push(queueInElement);
        }
    }

    private queue:Type[];
    private queueLength:number;
    //private keyMapList: { [key: string]: Type | null };

    private internalDelayCounter:number;
    constructor(private callback: (queueOutElement:Type) => boolean | Promise<boolean>, public processDelay:number = 1000) {
        this.internalDelayCounter = 0;
        this.queue = [];
        this.queueLength = 0;
        QueueScheduler.queueSchedulers.push(this);
    }

    public static ProcessAllQueueSchedulers(dt:number):void {
        for(let q of QueueScheduler.queueSchedulers) {
            q.processPending(dt);
        }
    }

    public static queueSchedulers:QueueScheduler<any>[] = [];
}