interface IntersectionSubscription {
    (object: string,  position: THREE.Vector3, normal: THREE.Vector3):void;
}

export class MimickIntersection {
    constructor() {
        console.log("intersection");
    }
    
    public subscribe(callback:IntersectionSubscription) {
    
    }
}
