import React from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Box,
  Fab,
  Tooltip,
} from '@material-ui/core';
import SidebarTabPanel from './TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEditSpace } from '@crema/utility/Utils';
import { ANNOTATION_TYPE, SpaceRole } from 'types/models/home/HomeApp';
import Layout from '../layout';
import { importMatterportTags } from 'modules/home/SpaceDetail/utils';
import { addTag } from 'redux/actions/Tags';
import AddIcon from '@material-ui/icons/Add';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import Spacer from '@crema/core/Spacer';
import { SHOW_POSES } from 'types/actions/ThreeD.actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
      textAlign: 'center',
      marginTop: '10px',
      width: '100%',
      paddingLeft: '26px'
    },
    tagsOr3dTab: {
      margin: '10px 5px',
      padding: '5px 2px',
      width: '50%',
      textAlign: 'center'

    },
    tagsOr3dTabActive: {
      color: 'black',
      borderBottom: '3px solid #315F72',
      borderRadius: '2px',

    },
    tagsOr3dTabInActive: {
      // color: 'black',

      cursor: 'pointer',
      '&:hover': {
        borderBottom: '3px solid #315F7250',
        borderRadius: '2px'
      }
    }
  }),
);
interface Props {
  // importMatterportTags(): void;
  // tagsAlreadyImported: boolean;
  // addObject(objectName: string): void;
}

export default function TagSidebar({
}: Props) {
  const classes = useStyles();

  const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  const dispatch = useDispatch();
  const theme = useTheme();

  return (console.log(`%c[st] rendering TagSidebar`, 'color: orange;'),
    <>
    <Layout>
      <Spacer y={2} />

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 10,
        }}>

        {/* {canEditSpace(currentSpace.spaceMembership.role) && (
            <Button
              variant='outlined'
              color='secondary'
              size='small'
              // style={{width: "80%"}}
              // type='button'
              onClick={() => handleAddTag()}>
              Delete all tags
            </Button>
          )} */}
      </Box>

      {/* <Box marginTop="15px" marginBottom="15px">
        <AvatarGrid isSizeSmall={true} dataList={["Info", "Image", "Video", "Audio", "Files"].map(x =>
          { return {imageName: x, imageSrc: `/assets/images/sidebarIcons/${x}.png`} }
        )
        } />
      </Box> */}

      {/* <Divider orientation='horizontal' variant='middle' /> */}

      {/* {
        (console.log(`[st] tagsImported ${tagsImported} `), !tagsImported &&
          isUserAdmin && spaceRole && canEditSpace(spaceRole) &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}><Link
            type='underlineAlways'
            //  underline='always'
            style={{ margin: 10, textAlign: 'center', cursor: 'pointer' }}
            onClick={() => importMatterportTags()}>
            Import Matterport Tags
          </Link></div>)
      } */}

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {spaceRole && canEditSpace(spaceRole) &&
            <Tooltip title='Add a tag' >
              <Fab size="small" color="secondary" onClick={(e) => {
                e.stopPropagation();
                dispatch(addTag(ANNOTATION_TYPE.INFO));
              }}>
                <AddIcon />
              </Fab>
            </Tooltip>
          }
          <SidebarTabPanel
          />
        </div>
        <Spacer y={2} />


    </Layout >
    </>
  );
}

export function TagOverflowMenu() {
  const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  const tagsImported = useSelector<AppState, boolean>(({ home }) => !!home?.currentSpace?.tagsImported);
  const theme = useTheme();

  const dispatch = useDispatch();

  return <OverflowMenu buttonStyle={{ color: theme.palette.primary.main }} menuItems={[
    {
      title: tagsImported ? "Tags already imported" : "Import Tags from Matterport",
      isDisabled: tagsImported,
      // tooltip: 'Tags already imported',
      onClick: () => {
        !tagsImported &&
          spaceRole && canEditSpace(spaceRole) && importMatterportTags()
      }
    },
    {
      title: "Viewpoints",
      isDisabled: false,
      // tooltip: 'Tags already imported',
      onClick: () => {
        dispatch({ type: SHOW_POSES, payload: true });
      }
    }
  ]} />
}