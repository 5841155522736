import { AuthUser } from '../../types/models/AuthUser';
import { AuthType } from './AppEnums';
import * as THREE from 'three';
import { getRandomArrayElement } from '@crema/utility/Utils';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const initialUrl = '/home/spaces'; // this url will open after login
// export const initialUrl = '/ecommerce/products'; // this url will open after login

export const channels = {
  email: 'email',
  notification: 'notification',
};

export const templates = {
  task: 'task',
  shareSpace: 'shareSpace',
  signup: 'signup',
};

export interface UseCase {
  id: number;
  name: string;
  layerTermSingular: string;
  layerTermPlural: string;
}

export const defaultLayerNameSingular = "Experience";
export const defaultLayerNamePlural = "Experiences"

export const UseCases: UseCase[] = [
  { id: 0, name: "Training", layerTermSingular: "Lesson", layerTermPlural: "Lessons" },
  { id: 1, name: "Hotel/ Airbnb", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 2, name: "Home Owner/ Home Manual", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 3, name: "Layout Planning", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 4, name: "Interior Design", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 5, name: "Retail", layerTermSingular: "Tour", layerTermPlural: "Tours" },
  { id: 6, name: "Facilities Management", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 7, name: "Construction", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 8, name: "Office Manual", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  // { id: 9, name: "Monitoring & Remote Control", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
  { id: 100, name: "Other", layerTermSingular: defaultLayerNameSingular, layerTermPlural: defaultLayerNamePlural },
];


// export const TagColorToMatterportColorObj = (tagColorObj: any): { r: number, g: number, b: number } => {
//   let c = tagColorObj;

//   let r = (c.r * 256).toFixed(6);
//   let g = (c.g * 256).toFixed(6);
//   let b = (c.b * 256).toFixed(6);

//   return rgbToMatterportColorObj(r, g, b);

// }


// export function RGBToHex(r: any, g: any, b: any) {
//   r = r.toString(16);
//   g = g.toString(16);
//   b = b.toString(16);

//   if (r.length == 1)
//     r = "0" + r;
//   if (g.length == 1)
//     g = "0" + g;
//   if (b.length == 1)
//     b = "0" + b;

//   return "#" + r + g + b;
// }

// export function hexToRGB(hex: string): number[] | undefined {
//   if(hex.startsWith('#')){
//     hex = hex.substring(1)
//   }
//   if (!hex || hex.length != 6) {
//     console.error("Only six-digit hex colors are allowed.");
//     return undefined;
//   }

//   var aRgbHex = hex.match(/.{1,2}/g);

//   if (aRgbHex) {
//     var aRgb = [
//       parseInt(aRgbHex[0], 16),
//       parseInt(aRgbHex[1], 16),
//       parseInt(aRgbHex[2], 16)
//     ];
//     return aRgb;
//   }
//   return undefined;
// }

export function hexToMatterportColorObj(hex: string) {

  if (!hex) {
    return getDefaultMatterportColorObj();
  }

  let c = MpColors.find(c => [c.hex, c.hexTHREE].includes(hex));

  if (!c) {
    return getDefaultMatterportColorObj();
  } else {
    return { r: c.r, g: c.g, b: c.b };
  }
}

export const MpColors = [
  {
    num: 1,
    color: "Red",
    label: "1 red",
    rgb: "0.8313725490196079,0.26666666666666666,0.2549019607843137",
    r: 0.8313725490196079,
    g: 0.26666666666666666,
    b: 0.2549019607843137,
    r256: "213",
    g256: "68",
    b256: "65",
    hex: "#d54441",
    hexTHREE: "#d44441",
  },
  {
    num: 2,
    color: "Orange",
    label: "2 orange",
    rgb: "0.9568627450980393,0.2627450980392157,0.21176470588235294",
    r: 0.9568627450980393,
    g: 0.2627450980392157,
    b: 0.21176470588235294,
    r256: "245",
    g256: "67",
    b256: "54",
    hex: "#f54336",
    hexTHREE: "#f44336",
  },
  {
    num: 3,
    color: "Pink",
    label: "3 pink",
    rgb: "0.9137254901960784,0.11764705882352941,0.38823529411764707",
    r: 0.9137254901960784,
    g: 0.11764705882352941,
    b: 0.38823529411764707,
    r256: "234",
    g256: "30",
    b256: "99",
    hex: "#ea1e63",
    hexTHREE: "#e91e63",
  },
  {
    num: 4,
    color: "PinkWarm",
    label: "4 pinkwarm",
    rgb: "0.9686274509803922,0.5529411764705883,0.6549019607843137",
    r: 0.9686274509803922,
    g: 0.5529411764705883,
    b: 0.6549019607843137,
    r256: "248",
    g256: "142",
    b256: "168",
    hex: "#f88ea8",
    hexTHREE: "#f78da7",
  },
  {
    num: 5,
    color: "Lavender",
    label: "5 lavender",
    rgb: "0.611764705882353,0.29411764705882354,0.5725490196078431",
    r: 0.611764705882353,
    g: 0.29411764705882354,
    b: 0.5725490196078431,
    r256: "157",
    g256: "75",
    b256: "147",
    hex: "#9d4b93",
    hexTHREE: "#9c4b92",
  },
  {
    num: 6,
    color: "Purple",
    label: "6 purple",
    rgb: "0.403921568627451,0.22745098039215686,0.7176470588235294",
    r: 0.403921568627451,
    g: 0.22745098039215686,
    b: 0.7176470588235294,
    r256: "103",
    g256: "58",
    b256: "184",
    hex: "#673ab8",
    hexTHREE: "#673ab7",
  },
  {
    num: 7,
    color: "Teal",
    label: "7 teal",
    rgb: "0.011764705882352941,0.40784313725490196,0.49019607843137253",
    r: 0.011764705882352941,
    g: 0.40784313725490196,
    b: 0.49019607843137253,
    r256: "3",
    g256: "104",
    b256: "125",
    hex: "#03687d",
    hexTHREE: "#03687d",
  },
  {
    num: 8,
    color: "Blue",
    label: "8 blue",
    rgb: "0.011764705882352941,0.6627450980392157,0.9568627450980393",
    r: 0.011764705882352941,
    g: 0.6627450980392157,
    b: 0.9568627450980393,
    r256: "3",
    g256: "170",
    b256: "245",
    hex: "#03aaf5",
    hexTHREE: "#03a9f4",
  },
  {
    num: 9,
    color: "SkyBlue",
    label: "9 skyblue",
    rgb: "0,0.7372549019607844,0.8313725490196079",
    r: 0,
    g: 0.7372549019607844,
    b: 0.8313725490196079,
    r256: "0",
    g256: "189",
    b256: "213",
    hex: "#00bdd5",
    hexTHREE: "#00bcd4",
  },
  {
    num: 10,
    color: "Olive",
    label: "10 olive",
    rgb: "0.2549019607843137,0.4588235294117647,0.0196078431372549",
    r: 0.2549019607843137,
    g: 0.4588235294117647,
    b: 0.0196078431372549,
    r256: "65",
    g256: "117",
    b256: "5",
    hex: "#417505",
    hexTHREE: "#417505",
  },
  {
    num: 11,
    color: "Green",
    label: "11 Green",
    rgb: "0.3176470588235294,0.6588235294117647,0.40784313725490196",
    r: 0.3176470588235294,
    g: 0.6588235294117647,
    b: 0.40784313725490196,
    r256: "81",
    g256: "169",
    b256: "104",
    hex: "#51a968",
    hexTHREE: "#51a868",
  },
  {
    num: 12,
    color: "Mint",
    label: "12 mint",
    rgb: "0.21568627450980393,0.8392156862745098,0.47843137254901963",
    r: 0.21568627450980393,
    g: 0.8392156862745098,
    b: 0.47843137254901963,
    r256: "55",
    g256: "215",
    b256: "122",
    hex: "#37d77a",
    hexTHREE: "#37d67a",
  },
  {
    num: 13,
    color: "Lime",
    label: "13 lime",
    rgb: "0.803921568627451,0.8627450980392157,0.2235294117647059",
    r: 0.803921568627451,
    g: 0.8627450980392157,
    b: 0.2235294117647059,
    r256: "206",
    g256: "221",
    b256: "57",
    hex: "#cedd39",
    hexTHREE: "#cddc39",
  },
  {
    num: 14,
    color: "Yellow",
    label: "14 yellow",
    rgb: "0.984313725490196,0.803921568627451,0",
    r: 0.984313725490196,
    g: 0.803921568627451,
    b: 0,
    r256: "252",
    g256: "206",
    b256: "0",
    hex: "#fcce00",
    hexTHREE: "#fbcd00",
  },
  {
    num: 15,
    color: "Mustard",
    label: "15 mustard",
    rgb: "1,0.6745098039215687,0.09019607843137255",
    r: 1,
    g: 0.6745098039215687,
    b: 0.09019607843137255,
    r256: "255",
    g256: "173",
    b256: "23",
    hex: "#ffad17",
    hexTHREE: "#ffac17",
  },
  {
    num: 16,
    color: "Brown",
    label: "16 brown",
    rgb: "1,0.4117647058823529,0",
    r: 1,
    g: 0.4117647058823529,
    b: 0,
    r256: "255",
    g256: "105",
    b256: "0",
    hex: "#ff6900",
    hexTHREE: "#ff6900",
  },
  {
    num: 17,
    color: "Grey",
    label: "17 grey",
    rgb: "0.6705882352941176,0.7215686274509804,0.7647058823529411",
    r: 0.6705882352941176,
    g: 0.7215686274509804,
    b: 0.7647058823529411,
    r256: "172",
    g256: "185",
    b256: "196",
    hex: "#acb9c4",
    hexTHREE: "#abb8c3",
  },
  {
    num: 18,
    color: "Black",
    label: "18 black",
    rgb: "0.3764705882352941,0.49019607843137253,0.5450980392156862",
    r: 0.3764705882352941,
    g: 0.49019607843137253,
    b: 0.5450980392156862,
    r256: "96",
    g256: "125",
    b256: "140",
    hex: "#607d8c",
    hexTHREE: "#607d8b",
  },
]

export function getDefaultMatterportColorObj() {
  // return { r: MpColors[0].r, g: MpColors[0].g, b: MpColors[0].b };
  return { r: 1, g: 0, b: 0 };
}

export const labelColors = MpColors.map(c => c.hexTHREE);
export const taskStatusColors = MpColors.map(c => c.hexTHREE);

// ['#F87E7D', '#FFCD7A', '#FEFF00', '#7ED085', '#5FB3F9', '#D190DA', '#D0AF8F', '#BDBDBD', '#FFCCD2', '#FFE1AF', '#FEFF85', '#C6E7C8', '#B9DDFC', '#EBC7EF', '#EEDCCA', '#E5E5E5', '#FFEBEE', '#FFF3DF', '#FFFEE6', '#E7F5E9', '#E2F2FE', '#F4E4F5', '#F2E8DE', '#FFFFFF'];
// export const labelList = [
//   {id: '0', name: 'Scheduled'}, {id: 1, name: 'In Progress'}, {id: 2, name: 'Complete'}, {id: 3, name: 'Needs Attention'}];

export const getUniqueColor = (colorsToExclude: string[]) => taskStatusColors.find(c => !colorsToExclude.includes(c)) || getRandomArrayElement(taskStatusColors);
export const defaultTaskLabelColor = getRandomArrayElement(labelColors);
export const defaultTaskCollaboratorColor = "#e8e5e4";

export let HelpBeacon: any = undefined;
export function initHelpBeacon(b: any) {
  !HelpBeacon && (HelpBeacon = (window as any).Beacon);
}