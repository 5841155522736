import RigidBody from './RigidBody';
import * as THREE from 'three';
import DoublyLinkedList from '../ds/DoublyLinkedList';
import ListNode from '../ds/ListNode';

export default class Particle extends RigidBody {
    get life(): number {
        return this._life;
    }
    private _life:number;
    
    public color:THREE.Color;
    
    /*
    public constructor(__pos:THREE.Vector3, __mass:number) {
        super();
        
        this.position.set(__pos.x, __pos.y, __pos.z);
        this.mass = __mass;
        this.life = 1;
    }*/
    
    public constructor(_linkedListRef:DoublyLinkedList<Particle>|null = null) {
        super(_linkedListRef);
        this.kill();
        this.color = new THREE.Color(1, 1, 1);
        //this.linkedListRef.push(this);
    }
    
    public kill():void {
        this._life = 0;
        this.position.set(999999, 999999, 999999);
    }
    
    public spawn(_life:number):void {
        this._life = _life;
    }
    
    simulate(dt: number) {
        if(this.alive) {
            this._life -= dt;
            super.simulate(dt);
            
            if(this.dead) {
                this.kill();
            }
        } else {
        
        }
    }
    
    public get alive():boolean {
        return this._life > 0;
    }
    
    public get dead():boolean {
        return this._life <= 0;
    }
    
    /*
    public reset() {
        this.position =
    }*/
}
