import {store} from "App";
import { TaskObj, ProjectObj, TaskSectionObj } from "types/models/apps/ProjectBoard";

export function getTaskSection(task: TaskObj, project?: ProjectObj): TaskSectionObj | null{
    return (project || getProjectForTask(task))?.sectionList.find(s=>(s.id === task.sectionId)) || null;
}

export function getProjectName(task: TaskObj): string {
    return getProjectForTask(task)?.name || '';
}


export function getProjectForTask(task: TaskObj): ProjectObj | undefined{

    let currentProject = store.getState().projects?.projectDetail;
    let p = (currentProject?.id == task.projectId) ? currentProject :
    store.getState().projects?.projectList?.find(p => p.id === task.projectId);
    return p;
}