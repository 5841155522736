import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SpatialThinkSDK } from '../SpatialThinkSDK';
import { BaseComponent } from './BaseComponent';
import { RenderingSystem } from '../Systems/RenderingSystem';
import * as THREE from 'three';
import {RaycastSystem} from '../Systems/RaycastSystem';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import {AnimationActionLoopStyles} from 'three';
import {LoopOnce, LoopRepeat} from 'three/src/constants';

export class GLTFComponent extends BaseComponent {
    public static loader: GLTFLoader | undefined = undefined;
    protected gltf: GLTF;
    protected url: string;
    //mixer
    protected mixer: THREE.AnimationMixer;
    protected currentActionIndex = 0;
    protected animationActions: THREE.AnimationAction[] = [];

    private _animationActionsMap: {[key: string]: THREE.AnimationAction} = {};
    
    public get animationActionsMap(): {[p: string]: THREE.AnimationAction} {
        return this._animationActionsMap;
    }
    
    constructor(renderingSystem: RenderingSystem, protected urlString: string) {
        super(renderingSystem);
        this.type = "GLTFComponent";

        this.url = urlString;
    }

    public static getLoader() {
        if (!GLTFComponent.loader) {
            GLTFComponent.loader = new GLTFLoader();
        }
        return GLTFComponent.loader;
    }

    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
        // throw new Error('Method not implemented.');
    }
    
    setNextInputValue(): void {
        // throw new Error('Method not implemented.');
    }

    onInit(userdata: { [p: string]: any }) {
        super.onInit(userdata);
        GLTFComponent.getLoader().load(this.url, this.onModelLoad.bind(this));

        this.outputs.collider = this.root;
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.root);
        
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onTick.bind(this));
    }
    
    onTick(delta: number) {
        this.mixer?.update(delta);
        
        if(this.animationActions.length > 1 && this.animationActions[this.currentActionIndex].isRunning() === false) {
            this.animationActions[this.currentActionIndex].stop();
            this.currentActionIndex = (this.currentActionIndex + 1) % this.animationActions.length;
            this.animationActions[this.currentActionIndex].play();
        }
    }

    onModelLoad(gltf: GLTF) {
        this.gltf = gltf;
        this.root.add(this.gltf.scene);
        // this.node && this.setLoaded(true);
        // console.log(`%c[st] [debug] gltf loading`,'color: red;');
        this.mixer = new THREE.AnimationMixer( gltf.scene );

        // if(gltf.animations && gltf.animations.length == 1) {
        //     // this.mixer.clipAction( animation ).play();
        //     const action = this.mixer.clipAction(gltf.animations[0]);
        //     action.setLoop(THREE.LoopRepeat, 1);
        //     this.animationActions.push(action);
        //     action.play();
        // }
        
        // gltf.animations.forEach( ( animation ) => {
        //     // this.mixer.clipAction( animation ).play();
        //     const action = this.mixer.clipAction(animation);
        //     action.setLoop(THREE.LoopOnce, 1);
        //     this.animationActions.push(action);
        //     action.play();
        // } );
        
        //loop through all the animations and play them for loop, without foreach
        for (let i = 0; i < gltf.animations.length; i++) {
            const action = this.mixer.clipAction(gltf.animations[i]);
            // console.log(gltf.animations[i].name);
            
            if(gltf.animations.length == 1) {
                // action.setLoop(THREE.LoopRepeat, 1);
            } else {
                 action.setLoop(THREE.LoopOnce, 1);
            }
            this.animationActions.push(action);
            this._animationActionsMap[gltf.animations[i].name] = action;
        }
        this.currentActionIndex = 0;
        
        if (this.animationActions.length > 0) {
            this.animationActions[this.currentActionIndex].play();
        }
        
        
        
        
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}
