import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
// import AppSearchBar from "@crema/core/AppSearchBar";
// import SelectModelsDropdown from "./SelectModelsDropdown";
// import SelectedIdsActions from "./SelectedIdsActions";
// import ViewSelectButtons from "./ViewSelectButtons";
import { AppState } from "redux/store";
// import { TodoObj } from "../../../../../types/models/apps/Todo";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import NodeStorage from 'mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import * as Icons from '@mui/icons-material/';

import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import { SORTBY } from "../InsertedObjects";
import { canEditSpace } from '@crema/utility/Utils';
import { copyModelsToAnotherSpace } from "redux/actions/Space";
import ConfirmationDialog from "@crema/core/ConfirmationDialog";
import { loadAndWatchSpaces } from "redux/actions";
import { Link, List, ListItem, ListItemText } from "@material-ui/core";
import { ListItemButton, Pagination } from "@mui/material";
import { baseUrl } from "Config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appsPagination: {
      overflow: "hidden",
      paddingRight: 2,
      paddingLeft: 2,
      "& .MuiToolbar-root": {
        paddingLeft: 0,
      },
      "& .MuiTablePagination-actions": {
        marginLeft: 0,
        "& .MuiButtonBase-root": {
          padding: 2,
        },
      },

    },
  }));

interface ModelContentHeaderProps {
  selectedIds: string[];
  setSelectedIds: (selectedIds: string[]) => void;
  onSortItemClickHandler: (sortBy: SORTBY) => void;
  sortBy: SORTBY;
  // filterText: string;
  // onSetFilterText: (text: string) => void;
  // onPageChange: (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   page: number
  // ) => void;
  // onViewModeSelect: (mode: string) => void;
  // viewMode: string;
  // page: number;
}

const ModelContentHeader: React.FC<ModelContentHeaderProps> = ({
  selectedIds,
  setSelectedIds,
  onSortItemClickHandler,
  sortBy
}) => {
  const spaceModels = useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
  const page = 0;
  const classes = useStyles();
  const [isCopyModelsDialogOpen, setCopyModelsDialogOpen] = useState(false);

  const onHandleMasterCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const ids = Array.from(spaceModels.keys());
      setSelectedIds(ids);
    } else {
      setSelectedIds([]);
    }
  };

  const onDeleteAllItems = async () => {
    const con = window.confirm(`Are you sure you want to delete all ${selectedIds.length} items?`);
    if (con) {
      const ids = Array.from(selectedIds);
      ids.forEach(async (id) => {
        await NodeStorage.deleteNodeById(id);
      });
    }
  }

  const { messages } = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
        }}
      >
        {canEditSpace() && <Checkbox
          style={{
            color: "text.disabled",
            position: "relative",
            left: "-12px",
          }}
          indeterminate={
            selectedIds.length > 0 && selectedIds.length < Array.from(spaceModels.keys()).length
          }
          checked={
            Array.from(spaceModels.keys()).length > 0 && selectedIds.length === Array.from(spaceModels.keys()).length
          }
          onChange={onHandleMasterCheckbox}
        />}
        {canEditSpace() && Array.from(spaceModels.keys()).length > 0 && selectedIds.length > 0 &&
          <DeleteOutlinedIcon
            style={{
              position: "relative",
              left: "-8px",
              cursor: 'pointer'
            }}
            onClick={(e) => {
              onDeleteAllItems();
              e.stopPropagation();
            }}
          />}

        {isCopyModelsDialogOpen && <CopyModelsDialog isCopyModelsDialogOpen={isCopyModelsDialogOpen} setCopyModelsDialogOpen={setCopyModelsDialogOpen}
          selectedIds={selectedIds} />}

        {canEditSpace() && Array.from(spaceModels.keys()).length > 0 && selectedIds.length > 0 &&
          <Icons.CopyAllOutlined
            onClick={(e) => {
              e.stopPropagation();
              setCopyModelsDialogOpen(true);
            }}
          />}

        {Array.from(spaceModels.keys()).length > 0 && (<OverflowMenu menuItems={[
          { title: "Alphabetical", onClick: () => { onSortItemClickHandler(SORTBY.ALPHABETICAL) }, isSelected: sortBy === SORTBY.ALPHABETICAL },
          { title: "Last Updated On", onClick: () => { onSortItemClickHandler(SORTBY.LAST_UPDATED_ON)}, isSelected: sortBy === SORTBY.LAST_UPDATED_ON  },
          { title: "Created On", onClick: () => { onSortItemClickHandler(SORTBY.CREATED_ON) }, isSelected: sortBy === SORTBY.CREATED_ON},
        ]}
          propIcon={<Icons.Sort />} />)}
        <Box style={{ marginRight: 3 }}>
          {/* <AppSearchBar
            iconPosition="right"
            overlap={false}
            value={filterText}
            onChange={(event: any) => onSetFilterText(event.target.value)}
            placeholder={messages["common.searchHere"] as string}
          /> */}
        </Box>
        {/* <SelectModelsDropdown
          onSelectModels={onSelectModels}
          selectedIds={selectedIds}
        /> */}
        {selectedIds.length > 0 ? (
          <Box
            component="span"
            style={{
              [theme.breakpoints.down('sm')]: {
                marginRight: 4
              },
              display: "flex",
            }}
          >
            {/* <SelectedIdsActions
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              page={page}
            /> */}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ModelContentHeader;

const CopyModelsDialog = ({ isCopyModelsDialogOpen, setCopyModelsDialogOpen, selectedIds,
  // spacesList
}: {
  isCopyModelsDialogOpen: boolean,
  setCopyModelsDialogOpen: (set: boolean) => void,
  selectedIds: string[],
  // spacesList: { name: string, id: string, did: string }[],
}) => {
  const spacesList = useSelector<AppState, { name: string, id: string, did: string }[]>(({ auth }) => auth.spacesList.map(s => ({
    name: s.name, id: s.id, did: s.did
  })));
  const ITEMS_PER_PAGE = 10;

  const dispatch = useDispatch();
  React.useEffect(() => {
    spacesList.length == 0 && dispatch(loadAndWatchSpaces());
  }, []);
  const [page, setPage] = React.useState(1);
  const [selected, setSelected] = React.useState<{ name: string, id: string, did: string } | undefined>(undefined);
  const [copyComplete, setCopyComplete] = React.useState(false);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const paginatedItems = React.useMemo(() => {
    const offset = (page - 1) * ITEMS_PER_PAGE;
    return spacesList.slice(offset, offset + ITEMS_PER_PAGE);
  }, [page, spacesList]);

  return (<ConfirmationDialog
    open={isCopyModelsDialogOpen}
    onDeny={() => setCopyModelsDialogOpen(false)}
    onConfirm={() => {
      if (selected) {
        dispatch(copyModelsToAnotherSpace(selectedIds, selected.did));
        setCopyComplete(true);
      }
    }}
    title={""}
    dialogTitle={"Copy 3D Objects to Another Space "}
    hideYesButton={copyComplete}
    yesLabel={"Copy " + selectedIds.length + " objects"}
    noLabel={copyComplete ? "Close" : "Cancel"}
  >

    {!copyComplete ? <><List >
      {paginatedItems.map((space) => (
        <ListItem dense disableGutters style={{
          padding: '2px', cursor: 'hand',
          border: selected?.id == space.id ? '2px solid green' : '',
          borderRadius: '16px'
        }}>
          <ListItemButton dense onClick={() => setSelected(space)}>
            <ListItemText primary={space.name} key={space.did}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
      <Pagination
        count={Math.ceil(spacesList.length / ITEMS_PER_PAGE)}
        shape="rounded" page={page}
        onChange={handlePageChange} />
        </>
      : <Box>
        {selectedIds.length} objects successfully copied to {selected?.name}.
        {/* <Link
              underline="always"
              type='underlineAlways'
              style={{ color:'blue', margin: 10, textAlign: 'center', cursor: 'pointer', fontWeight: 500 }}
              // onClick={() => { }}
              href={baseUrl + '/home/space/' + selected?.did}

        > View it now</Link> */}
      </Box>
    }

  </ConfirmationDialog>)
}