import { store } from "App";

export class SceneNodeBase {

    public static getPropKeyFromParams(actionOrConditionParams: string) {
        let a = actionOrConditionParams?.split(',') || [];
        return a.length >= 1 ? a[0] : '';
    }

    public static getPropValueFromParams(actionOrConditionParams: string) {
        let a = actionOrConditionParams?.split(',') || [];
        console.log('value is', a.length, a[1], a.length >= 2 ? a[1] : '')
        return a.length >= 2 ? a[1] : '';
    }

    public static getObjectPropKeys(actionOrConditionObjectId: string) {
        return Object.keys(SceneNodeBase.getCustomProps(store.getState().home.spaceModels.get(actionOrConditionObjectId || '')?.nodeRef.userData.customProps || ''));
    }

    public static getObjectPropValue(objectId: string, propName: string) {
        return SceneNodeBase.getCustomProps(store.getState().home.spaceModels.get(objectId || '')?.nodeRef?.userData.customProps)[propName];
    }

    public static getObjectPropValues(actionOrConditionParams: string, actionOrConditionObjectId: string) {
        let values = [(SceneNodeBase.getCustomProps(store.getState().home.spaceModels.get(actionOrConditionObjectId || '')?.nodeRef?.userData.customProps || ''))[SceneNodeBase.getPropKeyFromParams(actionOrConditionParams) || ''] || ''];
        let cv = SceneNodeBase.getPropValueFromParams(actionOrConditionParams);
        if (!values.includes(cv)) {
            values.push(cv);
        }
        return values;
    }
    public static getCustomProps(nodeUserDataCustomProps: string) {
        if (!nodeUserDataCustomProps) {
            return {};
        }

        let regex = /\s*([^=,\n]+)\s*=\s*([^=,\n]+)[,\n]?\s*/g;
        let matches = (nodeUserDataCustomProps).match(regex) || [];
        let kvPairs: { [key: string]: string } = {};

        matches.forEach((match) => {
            let parts = match.split('=');
            let key = parts[0].trim();
            let value = parts[1].trim();
            kvPairs[key] = value;
        });
        return kvPairs;
    }

    public static updateCustomProps(nodeUserDataCustomProps: any, customPropName: string, customPropValue: any) {
        let kvPairs = SceneNodeBase.getCustomProps(nodeUserDataCustomProps);
        kvPairs[customPropName] = customPropValue;

        // Convert the object back into a string
        let customPropsString = '';
        for (let k in kvPairs) {
            customPropsString += `${k} = ${kvPairs[k]}\n`;
        }
        return customPropsString;
    }
}