import { store } from "App";
import _ from "lodash";
import { EulerIntegrator } from "mp/core/craEngine/components/systemComponents/EulerIntegrator";
import PropertiesPanel from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import Utils from "mp/core/craEngine/Tools/Utils";
import MpSdk from "mp/MpSdk";
import { ANNOTATION_TYPE, ShowcaseTag, SweepData, SweepTransition } from "types/models/home/HomeApp";

let _currentTagsIdToSidMap: Map<string, string>;
let _currentTagSidToIdMap: Map<string, string>;

export function resetSpaceMeta() {
  setCurrentTagsSidMapGlobal(new Map());
  setAddAnnotationType(ANNOTATION_TYPE.INFO);
  setExternalTags([]);
  externalTagsWritten = false;
  setTagSidBeingAdded('');
  setCurrentZoom(null);
  setIsPlacingTag(false);
  setCurrentTagsSidMapGlobal(new Map());
}

export const addToCurrentTagsSidMapGlobal = (showcaseTagId: string, tagSid: string) => {
  (_currentTagsIdToSidMap || new Map()).set(showcaseTagId, tagSid);
  (_currentTagSidToIdMap || new Map()).set(tagSid, showcaseTagId);
}
export const setCurrentTagsSidMapGlobal = (tags: Map<string, string>) => {
  _currentTagsIdToSidMap = tags;
  setTagSidToIdMap();
}

export const getTagSidForTagId = (tagId: string) => {
  return _currentTagsIdToSidMap && _currentTagsIdToSidMap.get(tagId)
}

// export const tagsAlreadyImported = () => {
//     if (Array.from(store.getState().home.spaceTags.values()).find((x) => x.data?.source == 'MP')) {
//         return true;
//     }
//     return false;
// }


export let isRepositioning: boolean;
export const setIsRepositioning = (value: boolean) => {
  isRepositioning = value;
}


export let isPlacingTag: boolean;
export const setIsPlacingTag = (value: boolean) => {
  isPlacingTag = value;
}

// export let currentSweep: any;
// export let currentRotation: any;
// export const setCurrentSweep = (pose: any) => {
//     currentSweep = pose.sweep;
//     currentRotation = pose.rotation;
// }


export const getCurrentSweep = async (): Promise<SweepData> => {

  try {
    let pose = await MpSdk.Instance.mps.Camera.getPose();
    let s: SweepData = { sid: pose.sweep, rotation: pose.rotation, pose: pose };
    currentZoom && (s.zoom = currentZoom);
    return JSON.parse(JSON.stringify(s));
    // .catch(console.error);
  } catch (e) {
    console.error(e);
    return { sid: '' };
  }
}

export let currentZoom: any;
export const setCurrentZoom = (zoom: any) => {
  currentZoom = zoom;
}

const setTagSidToIdMap = () => {

  _currentTagSidToIdMap = Array.from(_currentTagsIdToSidMap.entries()).reduce(function (map, obj) {
    map.set(obj[1], obj[0]);
    return map;
  }, new Map<string, string>());

}

export const getTagIdForTagSid = (tagSid: string) => {
  let tagId = _currentTagSidToIdMap.get(tagSid)
  return tagId;
}

export let tagSidBeingAdded: any = '';
export const setTagSidBeingAdded = (value: any) => {
  tagSidBeingAdded = value
};

export let externalTags: any[] = [];
export const setExternalTags = (tags: any[]) => {
  if (!externalTagsWritten) {
    externalTags = tags;
    externalTagsWritten = true;
  }
};
export let externalTagsWritten = false;

export let spaceViewDispatchHook: any = null;
export const setSpaceViewDispatchHook = (dispatch: any) => {
  spaceViewDispatchHook = dispatch;
  PropertiesPanel.getDispatch = dispatch;
}

// export let rawRichText: string = '';
// export const setRawRichText = (text: string) => rawRichText = text;

export let addAnnotationType: ANNOTATION_TYPE = ANNOTATION_TYPE.INFO;
export const setAddAnnotationType = (annoType: ANNOTATION_TYPE) => addAnnotationType = annoType;

export async function goToPoseById(poseId: string): Promise<void> {
  let sweepAndPose = (store.getState().home.currentSpace?.poses || []).find(p => p.id === poseId)?.data;
  return sweepAndPose && await goToPose(sweepAndPose);
}

export async function goToPose(sweepData: SweepData): Promise<void> {

  if (store.getState().home.currentSpace?.settings?.useFreeCamera) {
    EulerIntegrator.instance?.cameraSystem?.goToPoseData(sweepData);

    // TODO raj
    // try {
    //   await goToZoom(sweepData);
    // }
    // catch (e: any) {
    //   console.error(`Error FREECAMERA step/cant-move-to-rotation: ${e}`)
    // };
  } else {

    let mpSdk = MpSdk.Instance.mps;

    if (mpSdk && sweepData) {

      // console.log(`[st] tg:  ${JSON.stringify(sweepData.sid)} --- ${JSON.stringify(sweepData.pose.rotation)} ----- `);
      let currentSweep = await getCurrentSweep();
      console.log(`[st] [vp] currentSweep: `, currentSweep?.sid, currentSweep?.rotation);
      if (currentSweep?.sid !== sweepData.sid) { // different sweeps, move, rotate and zoom

        try {
          console.log(`[st] [vp] moving to sweep:`, sweepData?.sid);
          await mpSdk.Sweep.moveTo(sweepData.sid, { rotation: sweepData?.pose?.rotation, transition: SweepTransition.FLY })
          // .then(() => {
          await goToZoom(sweepData);
          // })
        } catch (e: any) { console.error(`[st] [vp] Error step/cant-move-to-pose: ${e}`) };

      } else if (currentSweep?.rotation !== sweepData.rotation) { //same sweep, check rotation
        console.log(`[st] [vp] setting rotation from`, currentSweep?.rotation, sweepData?.rotation)

        try {
          await mpSdk.Camera.setRotation(sweepData.rotation);
        } catch (e: any) {
          console.error('[st] [mpsdk] couldnt move camera', sweepData.rotation);
        }
        // .then(
        try {
          await goToZoom(sweepData);
          // ).
        }
        catch (e: any) {
          console.error(`Error step/cant-move-to-rotation: ${e}`)
        };

      } else { //same sweep, same rotation, check zoom

        await goToZoom(sweepData);
      }
    }
  }
}



export async function goToZoom(sweepData: SweepData): Promise<void> {
  let mpSdk = MpSdk.Instance.mps;
  if (mpSdk && sweepData && sweepData.zoom &&
    (sweepData.zoom?.level !== currentZoom?.level)) {
    console.log(`[st] [vp] changing zoom to:  ${sweepData?.zoom?.level} from ${currentZoom?.level}`);
    try {
      await mpSdk.Camera.zoomTo(sweepData.zoom?.level)
      // .catch((e: any) => console.error(`Error step/cant-move-to-zoom: ${e}`))
    }
    catch (e: any) { console.error(`[st] [vp] Error step/cant-move-to-zoom: ${e}`) };
  }
}

export let tagHTMLMap: any = {};
export const addToTagHTMLMap = (tagId: string, htmlString: string) => tagHTMLMap[tagId] = htmlString;

export function logAllMpTags() {
  MpSdk.Instance.mps.Mattertag.getData().then((allTags: any[]) => {
    console.log(`[st] [tags] all tags: ${allTags.map(
      (x: any) => `${x.sid} -- ${x.label} -- ${JSON.stringify(x.color)}`,
    )}`)
  })

}

export function getLabelIdsForTag(showcaseTag: ShowcaseTag): any[] {
  let labelIds: any[] = [];
  if (showcaseTag.annotationType != ANNOTATION_TYPE.TASK) {
    labelIds = showcaseTag.labelIds ? showcaseTag.labelIds : [];
  } else {
    labelIds = showcaseTag.taskTag && showcaseTag.taskTag.label ? showcaseTag.taskTag.label.map((l) => (l.id)) as Array<number> : [];
  }
  return labelIds;
}