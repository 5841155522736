import React, { ErrorInfo } from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { reloadWithoutCache } from '@crema/utility/Utils';
import NotificationService from '@crema/services/NotificationService';
import { store } from 'App';

class AppErrorBoundary extends React.Component<{ location: any }, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log('errorInfo: ', errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false })
    }
  }
  render() {
    if (this.state.hasError) {
      try {
        store.dispatch(NotificationService.errorEmailsToSTAdmins());
      } catch (e) {
        console.error('Couldn\'t send email', e);
      }
      return (console.error(`[st] [error] BREAKING APP ERROR`),
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          justifyContent='center'
          flex={1}>
          {/* <img src={'/assets/images/error.png'} alt='Error Info' /> */}
          <Box component='h2' fontSize={30} mt={4}>
            Oops! We spruced up the app while you weren't looking.
          </Box>
          <Box fontSize={18} textAlign='center'>
            Give it a quick refresh, or. click the Refresh button below and you'll be on your way. Don't worry, none of your data is lost!
          </Box>
          <Box fontSize={18} textAlign='center'>
            Still stuck? Please contact our Support team (Green ? Button on the top right)
          </Box>
          <Box component='div' mt={4}>
            <Button
              variant='contained'
              color='secondary'
              size='small'
              onClick={() => {
                this.setState({ hasError: false });
                reloadWithoutCache();
              }}>
              Refresh
            </Button>
          </Box>
        </Box>
      );
    } else {
      return this.props.children;
    }
  }
}

export default AppErrorBoundary;
