export const generateUUID = () => {
  let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16)
  let result = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-')
  return result
}

export const makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i ++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}
