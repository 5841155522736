import Particle from '../Particle';
import * as THREE from 'three';
import RegularEmitterDescriptor from './RegularEmitterDescriptor';

export default class CircleEmitterDescriptor extends RegularEmitterDescriptor {
    constructor(public radius = 0.5,
                private mass: number,
                private velocityMinScale: THREE.Vector3,
                private velocityMaxScalePositive: THREE.Vector3,
                private velocityMaxScaleNegative: THREE.Vector3,
                private angularVelocityMin: number,  private angularVelocityMaxPositive:number, private angularVelocityMaxNegative:number) {
        super();
    }
    
    public DescribeParticle(particle: Particle, position: THREE.Vector3, life: number) {
        //plane = new THREE.Plane(0,)
        //plane.projectOnPlane()
        
        let r = this.radius * Math.sqrt(Math.random());
        let theta = Math.random() * 2 * Math.PI;
        
        position.x += Math.cos(theta) * r;
        position.y += 0;//Math.sin(this.startAngle) * this.radius;
        position.z += Math.sin(theta) * r;//Math.sin(this.startAngle) * this.radius;
        
        
        //this.plane.projectPoint(position, particle.position);
        
        particle.position.set(position.x, position.y, position.z);
        particle.mass = this.mass;
        particle.color.setRGB(1, 1, 1);
        particle.velocity.set((Math.random() * this.velocityMaxScalePositive.x + this.velocityMinScale.x + Math.random() * this.velocityMaxScaleNegative.x ),
            (Math.random() * this.velocityMaxScalePositive.y + this.velocityMinScale.y + Math.random() * this.velocityMaxScaleNegative.y),
            (Math.random() * this.velocityMaxScalePositive.z + this.velocityMinScale.z + Math.random() * this.velocityMaxScaleNegative.z));
        particle.angularVelocity = this.angularVelocityMin + Math.random() * this.angularVelocityMaxPositive + Math.random() * this.angularVelocityMaxNegative;
        particle.spawn(life);
        
    }
}
