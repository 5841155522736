import { store } from "App";
import { showGizmo } from "redux/actions/ThreeD";
import { SHOW_PROP_PANEL } from "types/actions/ThreeD.actions";
import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import Simulation from "../../core/Simulation";
import { IComponentEventSpy } from "../../sceneManagement/SceneComponent";
import { PropertiesPanelMode } from "../../ui-interop/PropertiesPanel";
import * as THREE from 'three';
import { EulerIntegrator } from "mp/core/craEngine/components/systemComponents/EulerIntegrator";

export class GenericInputDragBeginSpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.DragBegin;
    constructor() {
    }
    onEvent(payload: any) {



        //Mouse was clicked in scene
        //console.log('drag object GenericInputDragBeginSpy', payload, payload.node.root.id, payload.node.root.name, payload.node.root.obj3D);
        if (payload.event.buttons == 1) {

            // if (store.getState().home.currentSpace?.settings?.useFreeCamera) {
            //     if (EulerIntegrator.instance?.cameraSystem) {
            //         EulerIntegrator.instance.cameraSystem.matterportDragging = true;
            //     }
            // }


            Simulation.instance.resetSimulationMode();

            if (Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Adding ||
                Simulation.instance.propertiesPanel.getPropertiesPanelMode() === PropertiesPanelMode.Editing) {

            } else {


                // if (!Simulation.instance.isObjectDragging) {
                //     Simulation.instance.draggedObject = null;
                // };
                // Simulation.instance.sceneLoader.hideTransformGizmo();
                // if (GizmoTools?.instance) {
                store.getState().threeD.showGizmo && store.dispatch(showGizmo(false));
                //     Simulation.instance.propertiesPanel.hidePropertiesPanel();
                !!store.getState().threeD.showPropertiesPanel && store.dispatch({ type: SHOW_PROP_PANEL, payload: false });

                //     // (store.getState().home?.tagsViewModeSecondary == ViewModeSecondary.LAYERS_PROP && store.dispatch(setTagsViewModeSecondary('')));
                // }
                // }



            }
        }
    }

}

// export class MPRaycaster {

//     protected raycaster: THREE.Raycaster;
//     protected _offset: THREE.Vector3;
//     protected _worldPosition: THREE.Vector3;
//     protected _inverseMatrix: THREE.Matrix4;
//     // protected _selected: THREE.Object3D | null;
//     protected _plane: THREE.Plane;
//     // private dragged = false;
//     protected tempMatrix = new THREE.Matrix4();
//     protected _intersection: THREE.Vector3;
//     protected showcase: HTMLDivElement;
//     protected pointer: {
//         x: number;
//         y: number;
//     };
//     protected planeNormal: THREE.Vector3;

//     constructor() {
//         this._offset = new THREE.Vector3();
//         this._intersection = new THREE.Vector3();
//         this._worldPosition = new THREE.Vector3();
//         this._inverseMatrix = new THREE.Matrix4();
//         this._plane = new THREE.Plane();
//         this.planeNormal = new THREE.Vector3();
//         this.raycaster = new THREE.Raycaster();

//         this.pointer ={x: 0, y: 0};

//         let showcase = document.getElementById('sdk-iframe');
//         if (showcase) {
//             this.showcase = showcase as HTMLDivElement;
//             this.showcase.addEventListener('mousemove', this.onMouseMove.bind(this));
//         }
//     }

//     public onMouseMove(e: MouseEvent): void {

//         const rect = this.showcase?.getBoundingClientRect();
//         this.pointer.x = ((e.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
//         this.pointer.y = - ((e.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

//         this.raycaster.setFromCamera(this.pointer, Simulation.instance.camera.Camera);

//         if (Simulation.instance.draggedObject ) {
//             // Simulation.instance.dragged = true;
//             if (this.raycaster.ray.intersectPlane(this._plane, this._intersection)) {
//                 Simulation.instance.draggedObject?.obj3D.position.copy(this._intersection.sub(this._offset).applyMatrix4(this._inverseMatrix));
//             }
//         }
//     }

//     public onMouseDown(
//         //     point: {
//         //     x: number;
//         //     y: number;
//         // }
//     ) {
//         this.raycaster.setFromCamera(this.pointer, Simulation.instance.camera.Camera);

//         // this._selected = (clickedNode as SceneNode).customComponents[0]?.root;

//         this._plane.setFromNormalAndCoplanarPoint(Simulation.instance.camera.Camera.getWorldDirection(this._plane.normal),
//             this._worldPosition.setFromMatrixPosition(Simulation.instance.draggedObject?.obj3D.matrixWorld));

//         if (this.raycaster.ray.intersectPlane(this._plane, this._intersection)) {

//             this._inverseMatrix.copy(Simulation.instance.draggedObject?.obj3D?.parent!?.matrixWorld).invert();
//             this._offset.copy(this._intersection).sub(this._worldPosition.setFromMatrixPosition(Simulation.instance.draggedObject?.obj3D.matrixWorld));
//         }
//     }

//     public onDrag() {
//         this.raycaster.setFromCamera(this.pointer, Simulation.instance.camera.Camera);

//         if (Simulation.instance.draggedObject) {
//             // Simulation.instance.dragged = true;
//             if (this.raycaster.ray.intersectPlane(this._plane, this._intersection)) {
//                 Simulation.instance.draggedObject?.obj3D.position.copy(this._intersection.sub(this._offset).applyMatrix4(this._inverseMatrix));
//             }
//         }
//     }

// }
