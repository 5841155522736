import * as THREE from 'three';
import {Scene} from '../../../../public/bundle/sdk';
import {WebGLRenderTarget} from 'three';
import {RepeatWrapping} from 'three/src/constants';

export class CanvasRenderer {

    private canvas: HTMLCanvasElement;
    private renderContext2D: CanvasRenderingContext2D | null;
    //private renderTarget: WebGLRenderTarget;
    private renderTexture : THREE.CanvasTexture;

    public get Texture():THREE.Texture {
        return this.renderTexture;
    }

    public constructor(protected width: number, protected height: number) {
        // set up canvas 2d context
        this.canvas = document.createElement('canvas');
        this.renderContext2D = this.canvas.getContext('2d')!;


        this.canvas.width = width;
        this.canvas.height = height;

        this.renderTexture = new THREE.CanvasTexture(this.renderContext2D.canvas, THREE.UVMapping, THREE.RepeatWrapping, THREE.RepeatWrapping, THREE.LinearFilter, THREE.LinearFilter, THREE.RGBAFormat, THREE.UnsignedByteType, 1);
    }

    public drawBorder(position:THREE.Vector2, size:THREE.Vector2, radius:number, lineWidth:number, color:string ):void {
        const x = position.x;
        const y = position.y;
        var r = x + size.x;
        var b = y + size.y;

        const context2d = this.renderContext2D!;

        // context2d.shadowBlur = 100;
        context2d.shadowColor = color;
        // context2d.shadowOffsetX = -100;
        // context2d.shadowOffsetY = -100;
        context2d.beginPath();
        context2d.strokeStyle = color;
        context2d.lineWidth = lineWidth;
        context2d.moveTo(x + radius, y);
        context2d.lineTo(r - radius, y);
        context2d.quadraticCurveTo(r, y, r, y + radius);
        context2d.lineTo(r, y + size.y - radius);
        context2d.quadraticCurveTo(r, b, r - radius, b);
        context2d.lineTo(x + radius, b);
        context2d.quadraticCurveTo(x, b, x, b - radius);
        context2d.lineTo(x, y + radius);
        context2d.quadraticCurveTo(x, y, x + radius, y);

        context2d.stroke();
        this.renderTexture.needsUpdate = true;
    }

    public clear(fillStyle: string | null): void {
        if (fillStyle) {
            this.renderContext2D!.fillStyle = fillStyle!;
            this.renderContext2D?.fillRect(0, 0, this.canvas.width, this.canvas.height);
        } else {
            this.renderContext2D?.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    }

    public fillColor(position:THREE.Vector2, size:THREE.Vector2, color:string ):void {
        const x = position.x;
        const y = position.y;
        var r = size.x;
        var b = size.y;

        const context2d = this.renderContext2D!;

        // context2d.shadowBlur = 100;
        // context2d.shadowOffsetX = -100;
        // context2d.shadowOffsetY = -100;
        context2d.beginPath();
        context2d.rect(x, y, size.x, size.y);
        context2d.fillStyle = color;
        context2d.fill();
        // context2d.stroke();
        this.renderTexture.needsUpdate = true;
    }

    public drawText(text:string, fontColor:string, fontStyle:string, fontSize:number, fontName: string, sizeScale:THREE.Vector2):void {
        const context2d = this.renderContext2D!;

        context2d.strokeStyle = fontColor;
        context2d.fillStyle =   fontColor;
        context2d.font = fontStyle + " " + fontSize + "px " + fontName;
        context2d.textAlign = 'left';
        context2d.textBaseline = 'top';
        context2d.clearRect(0, 0, this.width * sizeScale.x, this.height * sizeScale.y);

        this.wrapText(context2d, text, this.width * 0.5 * sizeScale.x,
            this.height * 0.5 * sizeScale.y, this.width * sizeScale.x, fontSize * 0.9);

        this.renderTexture.needsUpdate = true;

    }

    protected wrapText(context: CanvasRenderingContext2D, text: string, x: number, y: number,
             maxWidth: number, lineHeight: number) {
        var startY:number = y;
        var startX:number = x;

        text = text.replaceAll('\r', '');

        var words = text.split(' ');
        var line = '';
        var lines:string[] = [];
        var linesToRender:string[] = [];

        //while
        //let glyphWidth = context.measureText("X");

        context.textAlign = 'center';
        context.textBaseline = 'middle';
        var wordCount = words.length;
        for(var n = 0; n < wordCount; n++) {
            var testLine = line + words[n]+ ((n < (wordCount - 1)) ? ' ' : '');
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                lines.push( line );
                line = words[n] + ' ';
            }
            else {
                line = testLine;
            }
        }

        lines.push( line );
        for(var n = 0; n < lines.length; n++) {
            var currentLine = lines[n];
            let lineBreaksLines = currentLine.split('\n');
            for(var i = 0; i < lineBreaksLines.length; i++) {
                var newLine = lineBreaksLines[i];
                var brokenLines = this.breakBigLines(context, newLine, maxWidth)

                for(var j = 0; j < brokenLines.length; j++) {
                    linesToRender.push(brokenLines[j]);
                }

            }
        }
        var totalLines = linesToRender.length;
        var halfTextHeight = lineHeight * totalLines * 0.5;
        for(var n = 0; n < totalLines; n++) {
            context.fillText(linesToRender[n], x, y + n * lineHeight - halfTextHeight + lineHeight * 0.5 );
        }
    }

    protected breakBigLines(context: CanvasRenderingContext2D, text: string,  maxWidth: number):string[] {
        let line = ''
        let lines:string[] = []
        let lastIndex = 0;
        for(var n = 0; n < text.length; n++) {
            var glyph = text[n];
            line += glyph;
            var metrics = context.measureText(line);
            var testWidth = metrics.width;

            if(testWidth < maxWidth) {

            } else {
                lines.push(line);
                line = '';
                lastIndex = n + 1;
            }
        }

        lines.push(text.substr(lastIndex, text.length));

        return lines;
    }
}