import {GenericEventCallback, InputSystem} from './InputSystem';
import Simulation from '../../mp/core/craEngine/SubSystems/core/Simulation';
import QueueScheduler from '../../mp/core/craEngine/Tools/QueueScheduler';
import * as TWEEN from '@tweenjs/tween.js';
import {RenderingSystem} from './RenderingSystem';
import {SpatialThinkSDK, SpatialThinkSDKMode} from '../SpatialThinkSDK';
import { store } from 'App';

export interface UpdateCallback {
    (deltaTime:number): void;
}

export interface FrameUpdateCallback {
    (): void;
}

export class WindowingSystem {
    // protected iFrameDocument: Document | null = null;

    protected _inputSystem:InputSystem;

    protected _updateCallbacks: UpdateCallback[];
    protected _frameUpdateCallbacks: FrameUpdateCallback[];

    protected _domElement: HTMLElement;

    public ar:boolean;

    constructor(protected showcase: HTMLDivElement | Document | null = null) {
        // this.ar = false;
        if (this.showcase == null) {
            this.showcase = document.getElementById('sdk-iframe') as HTMLDivElement;
            // this.iFrameDocument = this.showcase!? .contentDocument || this.showcase!?.contentWindow!?.document;
        } else {
            if(this.isDocument(this.showcase)) {
                this._domElement = this.showcase.documentElement;
            }
        }

        this._inputSystem = new InputSystem(this);
        this._updateCallbacks = [];
        this._frameUpdateCallbacks = [];
        
        if(this.isDocument(this.showcase)) {
        
        } else {
            this.registerUpdateCallback((delta: number) => {
                TWEEN.update();
                if (Simulation) {
                    QueueScheduler.ProcessAllQueueSchedulers(delta);
                }
            });
        }
    }
    
    private isDocument(obj: any): obj is Document {
        return typeof obj === 'object' &&
            'createElement' in obj &&
            'getElementById' in obj &&
            'querySelector' in obj;
    }

    public initContainer(container:HTMLElement, domElement:HTMLElement) {
        // this.iFrameDocument!.body.innerHTML = '';
        // this.iFrameDocument!.body.appendChild(container);//this.renderer.domElement;
        this.showcase!.appendChild(container);//this.renderer.domElement;
        this._domElement = domElement;
    }

    public getShowcaseDocument(): HTMLElement | Document {
        return this.showcase!;
    }
    
    public getWindow():Window | Document {
        if(this.isDocument(this.showcase)) {
            return this.showcase
        }
        return window;
    }

    public get aspectRatio():number {
        return this.width / this.height;
    }

    public get width():number {
        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.AR){
            let xrOverlay = document.getElementById('xrOverlay');
            return xrOverlay?.clientWidth || window.innerWidth || 430;
        }
        
        if (this.showcase! instanceof Document) {
            return this.showcase!.documentElement.scrollWidth;
        } else {
            return this.showcase!.clientWidth;
        }
    }

    public get height():number {
        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.AR){
            let xrOverlay = document.getElementById('xrOverlay');
            return xrOverlay?.clientHeight || window.innerHeight || 926;
        }
        
        if (this.showcase! instanceof Document) {
            return this.showcase!.documentElement.scrollHeight;
        } else {
            return this.showcase!.clientHeight;
        }
    }

    public get innerWidth():number {
        return window.innerWidth;
    }

    public get innerHeight():number {
        return window.innerHeight;
    }

    get inputSystem(): InputSystem {
        return this._inputSystem;
    }

    public registerUpdateCallback(callback:UpdateCallback):number {
        return this._updateCallbacks.push(callback) - 1;
    }

    public processAllUpdateCallbacks(deltaTime:number) {
        for (const updateCallback of this._updateCallbacks) {
            updateCallback(deltaTime);
        }
    }

    public registerFrameUpdateCallback(callback:FrameUpdateCallback):number {
        return this._frameUpdateCallbacks.push(callback) - 1;
    }

    public processAllFrameUpdateCallbacks() {
        for (const callback of this._frameUpdateCallbacks) {
            callback();
        }
    }

    public getBoundingClientRect(): DOMRect {
        return this._domElement.getBoundingClientRect();
    }
}
