import { store } from "App";
import { SpatialThinkSDK, SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import { TagSystem } from 'CustomSdk/Systems/TagSystem';
import { reloadWithoutCache } from '@crema/utility/Utils';
import { addXRControls } from "./XRControls";
import Simulation from '../../../mp/core/craEngine/SubSystems/core/Simulation';

class ARButton {
    static createButton(renderer, sessionInit = {}) {

        const button = document.createElement('button');
        var rootDiv = document.createElement('div');
        rootDiv.id = 'xrOverlay';

        function showStartAR( /*device*/) {
            let currentSession = null;
            let _func = null;

            if (sessionInit.domOverlay === undefined) {

                let controlsDiv = addXRControls(currentSession);
                if (sessionInit.optionalFeatures === undefined) {
                    sessionInit.optionalFeatures = [];
                }
                rootDiv.appendChild(controlsDiv);

                sessionInit.optionalFeatures.push('dom-overlay');
                sessionInit.domOverlay = { root: rootDiv };
            }

            async function onSessionStarted(session) {

                session.addEventListener('end', onSessionEnded);

                renderer.xr.setReferenceSpaceType('local');
                await renderer.context.makeXRCompatible();
                // await renderer.xr.gl.makeXRCompatible(renderer.xr.setSession);
                await renderer.xr.setSession(session);

                // Simulation.instance.sdk && (Simulation.instance.sdk.tagSystem =
                //     new TagSystem(Simulation.instance.sdk.renderingSystem));

                // button.textContent = 'STOP AR';
                button.style.display = 'none';
                sessionInit.domOverlay.root.style.display = '';

                currentSession = session;


                document.getElementById('xrOverlay')?.appendChild((Simulation.instance.sdk)?.renderingSystem.labelRenderer.domElement);

            }

            function onSessionEnded( /*event*/) {

                currentSession.removeEventListener('end', onSessionEnded);

                button.textContent = 'START AR';
                sessionInit.domOverlay.root.style.display = 'none';

                currentSession = null;

            }

            //

            button.style.display = '';

            button.style.cursor = 'pointer';
            button.style.left = 'calc(50% - 50px)';
            button.style.width = '100px';

            button.textContent = 'START AR';

            button.onmouseenter = function () {

                button.style.opacity = '1.0';

            };

            button.onmouseleave = function () {

                button.style.opacity = '0.5';

            };

            button.onclick = function () {

                if (currentSession === null) {
                    navigator.xr.requestSession('immersive-ar', sessionInit).then(onSessionStarted);

                    // setTimeout(() => {
                    //     alert("Point at the AR code to the right and tap once")
                    // }, 1000)
                } else {
                    currentSession.end();
                    reloadWithoutCache();
                }
            };
        }

        function disableButton() {

            button.style.display = '';

            button.style.cursor = 'auto';
            button.style.left = 'calc(50% - 75px)';
            button.style.width = '150px';

            button.onmouseenter = null;
            button.onmouseleave = null;

            button.onclick = null;
        }

        function showARNotSupported() {
            disableButton();
            button.textContent = 'AR NOT SUPPORTED';
        }

        function stylizeElement(element) {

            element.style.position = 'absolute';
            element.style.bottom = 'calc(50%)';
            element.style.padding = '12px 6px';
            element.style.border = '1px solid #fff';
            element.style.borderRadius = '4px';
            element.style.background = 'black';
            element.style.color = '#fff';
            element.style.font = 'normal 13px sans-serif';
            element.style.textAlign = 'center';
            // element.style.opacity = '0.5';
            element.style.outline = 'none';
            element.style.zIndex = '999';

        }

        if ('xr' in navigator) {

            button.id = 'ARButton';
            button.style.display = 'none';

            stylizeElement(button);

            navigator.xr.isSessionSupported('immersive-ar').then(function (supported) {

                supported ? showStartAR() : showARNotSupported();

            }).catch(showARNotSupported);

            rootDiv.appendChild(button);
            return rootDiv;

        } else {

            const message = document.createElement('a');

            if (window.isSecureContext === false) {

                message.href = document.location.href.replace(/^http:/, 'https:');
                message.innerHTML = 'WEBXR NEEDS HTTPS'; // TODO Improve message

            } else {

                message.href = 'https://immersiveweb.dev/';
                message.innerHTML = 'WEBXR NOT AVAILABLE';
            }

            message.style.left = 'calc(50% - 90px)';
            message.style.width = '180px';
            message.style.textDecoration = 'none';

            stylizeElement(message);

            return message;

        }

    }

}

export { ARButton };
