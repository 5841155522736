import * as THREE from "three";
import Simulation from './Simulation';
import CardinalAxesAndPlanes from '../../Tools/CardinalAxesAndPlanes';
import {MathUtils} from 'three';

export default class ApplyRotation {
    public rotation:THREE.Vector3;
    public constructor() {
        this.rotation = new THREE.Vector3(0, 0, 0);
    }
    
    public PrintRotationValues():void {
        console.log("ApplyRot: {" + this.rotation.x + "," +  + this.rotation.y + ", " +  this.rotation.z + "}");
    }
    
    public ApplyToLastSceneNode():void {
        let finalRot = new THREE.Quaternion();
        finalRot.identity();
        let rotX =  new THREE.Quaternion();
        rotX.setFromAxisAngle(CardinalAxesAndPlanes.instance.xAxis, MathUtils.degToRad(this.rotation.x));
        finalRot = finalRot.multiply(rotX);
        
        var newYAxis = CardinalAxesAndPlanes.instance.yAxis.clone();
        newYAxis.applyQuaternion(finalRot.invert());
    
        let rotY =  new THREE.Quaternion();
        rotY.setFromAxisAngle(newYAxis, MathUtils.degToRad(this.rotation.y));
        finalRot = finalRot.multiply(rotY);
    
        var newZAxis = CardinalAxesAndPlanes.instance.zAxis.clone();
        newZAxis.applyQuaternion(finalRot.invert());
    
        let rotZ =  new THREE.Quaternion();
        rotZ.setFromAxisAngle(newZAxis, MathUtils.degToRad(this.rotation.z));
        finalRot = finalRot.multiply(rotZ);

        //
        // finalRot = finalRot.multiply(rotZ);
        // finalRot = finalRot.multiply(rotY);
        // finalRot = finalRot.multiply(rotX);
        
        Simulation.instance.lastSelectedNode?.quaternion.set(finalRot.x, finalRot.y, finalRot.z, finalRot.w);
    }
}
