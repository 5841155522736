export default class ShaderStringCache {
	public static fireFrag:string = "//#ifdef GL_FRAGMENT_PRECISION_HIGH" + 
					"//precision highp float;\n" + 
					"//#else\n" + 
					"//precision mediump float;\n" + 
					"//#endif\n" + 
					"precision mediump float;\n" + 
					"\n" + 
					"uniform float alphaTest;\n" + 
					"uniform sampler2D diffuseTexture;\n" + 
					"varying vec4 vColor;\n" + 
					"varying vec2 vAngle;\n" + 
					"void main() {\n" + 
					"    vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;\n" + 
					"    vec4 textureSample = texture2D(diffuseTexture, coords);\n" + 
					"    if ( textureSample.a <= alphaTest ) discard;\n" + 
					"    gl_FragColor = textureSample  * vColor;\n" + 
					"}\n";			

	public static fireVert:string = "uniform float pointMultiplier;" + 
					"attribute float size;\n" + 
					"attribute float angle;\n" + 
					"attribute vec4 color;\n" + 
					"varying vec4 vColor;\n" + 
					"varying vec2 vAngle;\n" + 
					"\n" + 
					"void main() {\n" + 
					"    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);\n" + 
					"    gl_Position = projectionMatrix * mvPosition;\n" + 
					"    gl_PointSize = size * pointMultiplier / gl_Position.w;\n" + 
					"    vAngle = vec2(cos(angle), sin(angle));\n" + 
					"    vColor = color;\n" + 
					"}\n";			

}