import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { SpatialThinkSDK } from '../SpatialThinkSDK';
import { BaseComponent } from './BaseComponent';
import { RenderingSystem } from '../Systems/RenderingSystem';
import * as THREE from 'three';
import { Collada, ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { SceneNode } from 'CustomSdk/Mimick/SceneNode';
import { useCallback } from 'react';
import {RaycastSystem} from '../Systems/RaycastSystem';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

export class DAEComponent extends BaseComponent {

    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
        // throw new Error('Method not implemented.');
    }
    setNextInputValue(): void {
        // throw new Error('Method not implemented.');
    }

    hide(): void { //TODO-ST do we need this?
        // this.mesh && (this.mesh.visible = false);
    }

    public static loader: ColladaLoader | undefined = undefined;
    protected collada: Collada;
    protected _hasloaded: boolean = false;

    public static getLoader() {
        if (!DAEComponent.loader) {
            DAEComponent.loader = new ColladaLoader();
        }
        return DAEComponent.loader;
    }
    constructor(renderingSystem: RenderingSystem, protected url: string, public onLoadedCallback?: (e: DAEComponent) => void) {
        super(renderingSystem);
        this.type = "DAEComponent";
        this._hasloaded = false;
    }

    onInit(userData : { [p: string]: any }): void {
        super.onInit(userData);
        DAEComponent.getLoader().load(this.url, this.onModelLoad.bind(this));

        this.outputs.collider = this.root;
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.root);
    }

    public get mesh(): THREE.Mesh | null {
        return this.collada?.scene?.children[0] as THREE.Mesh;
    }

    public rebase(newBase: THREE.Object3D) {
        this.renderingSystem.base.remove(this.root);
        newBase.add(this.root);
    }

    //get hasloaded
    public get hasloaded(): boolean {
        return this._hasloaded;
    }

    onModelLoad(collada: Collada) {
        this.collada = collada;
        this.root.add(this.collada.scene);
        this._hasloaded = true;
        this.onLoadedCallback && this.onLoadedCallback(this);
        // this.node && this.setLoaded(true);
        // console.log(`%c[st] [debug] gltf loading`,'color: red;');
        // let mixer = new THREE.AnimationMixer( gltf.scene );
        // console.log(`%c[st] [debug] gltf loading ${JSON.stringify(mixer)}`,'color: red;') ;
        // gltf.animations.forEach( ( clip ) => {
        //     console.log(`%c[st] [debug] gltf loading ${clip}`,'color: red;');
        //     mixer.clipAction( clip ).play();
        //
        // } );
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}
