import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells,
} from '@jsonforms/material-renderers';
import React from 'react';
import { arrayData, arraySchema, arrayUiSchema, basicData, basicSchema, basicUiSchema, dataForEmptySchema, dataGridSchema, emptySchema, emptyUiSchema } from './JSONSchemas';
import { Box, MenuItem, Select } from '@material-ui/core';

export function JSONForms() {
    const datasets = [
        // { name: "empty", schema: dataGridSchema, uiSchema: emptyUiSchema, data: dataForEmptySchema },
        { name: "basic", schema: basicSchema, uiSchema: basicUiSchema, data: basicData },
        { name: "array", schema: arraySchema, uiSchema: arrayUiSchema, data: arrayData},

    ];

    const [datasetIndex, setDatasetIndex] = React.useState(0);
    const [data, setData] = React.useState(
        basicData

    );

    return <Box>
        <Select value={datasetIndex} onChange={(e: any) => setDatasetIndex(parseInt(e.target.value))}>

            {datasets.map((x, i) => <MenuItem key={i} value={i}>{x.name}</MenuItem>)}

        </Select>
        <Box style={{ backgroundColor: 'white', width: '100%' }}>
            <JsonForms
                schema={datasets[datasetIndex].schema}
                // uischema={datasets[datasetIndex].uiSchema}
                data={datasets[datasetIndex].data}
                renderers={materialRenderers}
                cells={materialCells}
                onChange={({ errors, data }) => setData(data)}
            />
        </Box>
    </Box>
}