import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';
import {CanvasRenderer} from './CanvasTools/CanvasRenderer';
import {Vector2} from 'three';
import {Size} from '../../mp/core/craEngine/components/DecoupledComponents/DecoupledComponent';
import * as THREE from 'three';
import {RaycastSystem} from '../Systems/RaycastSystem';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';

type Inputs = {
    size: Size;
    fontSize: number;
    textLabel: string;
    localPosition: {x: number; y: number; z: number;};
    color: string;
}

export class BasicTextComponent extends BaseComponent {
    onDestroy(): void {
        console.error('onDestroy not implemented');
    }
    inputs: Inputs = {
        size: {w: 1, h: 1},
        color: '#ffffff',
        fontSize: 80,
        textLabel: 'Menu Item 1',
        localPosition: {x: 0, y: 0, z: 0},
    }
    private canvasRendererText: CanvasRenderer;
    private canvasRendererBG: CanvasRenderer;
    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = 'BasicTextComponent';
    }

    protected buildTextures() {
        let textureResolution = 1024;
        let textureWidth = this.inputs.size.w * textureResolution;
        let textureHeight = this.inputs.size.h * textureResolution;

        this.canvasRendererBG = new CanvasRenderer(textureWidth, textureHeight);
        // this.canvasRendererBG.FillColor(new Vector2(0, 0), new Vector2(1024, 1024), this.bgColor);
        this.canvasRendererBG.clear("#222222");
        this.canvasRendererBG.drawBorder(new Vector2(0, 0), new Vector2(textureWidth, textureHeight), 0, 0, "#ffffff");
        //

        this.canvasRendererText = new CanvasRenderer(textureWidth, textureHeight);
        this.canvasRendererText.drawText(this.inputs.textLabel, this.inputs.color, 'normal bold', this.inputs.fontSize * 2, 'sans-serif', new Vector2(1, 1));
    }

    protected buildMeshes() {
        this.clearMeshes();

        this.createMesh(new Vector2(this.inputs.size.w, this.inputs.size.h), true, this.canvasRendererBG.Texture, 1, 1,
            true, -4, 0, new THREE.Color(1, 1, 1));

        this.createMesh(new Vector2(this.inputs.size.w, this.inputs.size.h), true, this.canvasRendererText.Texture, 1, 1,
            true, -8, 0, new THREE.Color(1, 1, 1));

        this.outputs.collider = this.meshes[0];
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.meshes[0]);
    }

    onInit(userdata: {[p: string]: any}): void {
        super.onInit(userdata);
        this.buildTextures();
        this.buildMeshes();
        this.root.position.set(this.inputs.localPosition.x, this.inputs.localPosition.y, this.inputs.localPosition.z);
    }

    onInputsUpdated(oldInputs: this["inputs"], force:boolean): void {
    }
}