import {SceneComponent} from '../../SubSystems/sceneManagement/SceneComponent';
import {EulerIntegrator} from './EulerIntegrator';
import * as THREE from 'three';

export class ThreeContext extends SceneComponent  {
    private static _i:ThreeContext | null = null;

    private threeContext:typeof THREE;

    public get t():typeof THREE {
        return this.threeContext;
    }

    onInit() {
        this.threeContext = this.context.three;

        ThreeContext._i = this;
    }

    public static get i():ThreeContext | null {
        return ThreeContext._i;
    }
}


export const rootThreeContextType = 'st.rootThreeJSContext';
export const makeRootThreeContext = function () {
    return new ThreeContext();
};