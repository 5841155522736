import { ConfirmationDialogProps } from "@crema/core/ConfirmationDialog";

// action strings
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ROUTE_CHANGE = '@@router/LOCATION_CHANGE';
export const SHOW_DICTAPHONE = 'SHOW_DICTAPHONE';
export const SHOW_STATUS = 'SHOW_STATUS';
export const FETCH_START_NON_BLOCKING = 'FETCH_START_NON_BLOCKING';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const SET_HAS_NEW_VERSION = 'SET_HAS_NEW_VERSION';
export interface FetchStartAction {
  type: typeof FETCH_START;
  payload?: string;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  error: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
}

export interface ToggleDrawerAction {
  type: typeof TOGGLE_APP_DRAWER;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}
export interface RouteChangeAction {
  type: typeof ROUTE_CHANGE;
}

export interface ShowDictaphoneAction {
  payload: boolean;
  type: typeof SHOW_DICTAPHONE;
}

export interface ShowStatusAction {
  type: typeof SHOW_STATUS;
  payload: string;
}


export interface FetchStartNonBlockingAction {
  type: typeof FETCH_START_NON_BLOCKING;
  payload: string;
}

export interface ShowAlertAction {
  type: typeof SHOW_ALERT;
  payload: ConfirmationDialogProps;
}
export interface HideAlertAction {
  type: typeof HIDE_ALERT;
}

export interface SetHasNewVersionAction {
  type: typeof SET_HAS_NEW_VERSION;
  payload: boolean;
}

export type CommonActionTypes =
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | ShowMessageAction
  | ToggleDrawerAction
  | HideMessageAction
  | RouteChangeAction
  | ShowDictaphoneAction
  | ShowStatusAction
  | FetchStartNonBlockingAction
  | ShowAlertAction
  | HideAlertAction
  | SetHasNewVersionAction
  ;
