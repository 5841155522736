import { store } from 'App';
import * as THREE from 'three';
import { WindowingSystem } from './WindowingSystem';
import { SpacePose, SweepData } from 'types/models/home/HomeApp';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

class InternalPosition {
    constructor(public left: number, public top: number) {
    }
}


export class CameraSystem {
    protected _camera: THREE.PerspectiveCamera;
    protected mouseDown: boolean;

    public aimSensitivity: number;
    public cameraSpeed: number;
    public rotationSpeed: number;
    public crouchHeight: number;
    public fixedYplane: boolean = false;

    public isCrouching: boolean = false;

    protected direction: THREE.Vector3;

    private mouseMouseSuspended = false;


    constructor(protected _windowingSystem: WindowingSystem | null) {
        // this._camera = new THREE.PerspectiveCamera(75, this._windowingSystem.aspectRatio, 0.1, 50);
        if (this._windowingSystem == null) {
            this._camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1500);
        } else {
            this._camera = new THREE.PerspectiveCamera(75, this._windowingSystem.aspectRatio, 0.1, 1500);
        }

        this.camera.rotation.order = 'YXZ';

        this.goToDefaultPose();

        // let cameraPosition = new THREE.Vector3(0, 0, 0);
        // let cameraRotation = new THREE.Vector2(0, 0);
        // let poses = store.getState().home.currentSpace?.poses;

        // if (poses && poses.length > 0) {
        //     let defaultPose = poses.find(pose => !!pose.isDefault);
        //     defaultPose = (defaultPose || poses[0]);

        //     cameraPosition.x = defaultPose.data.pose?.position.x || 0;
        //     cameraPosition.y = defaultPose.data.pose?.position.y || 0;
        //     cameraPosition.z = defaultPose.data.pose?.position.z || 0;

        //     cameraRotation.x = defaultPose.data.pose?.rotation.x || 0;
        //     cameraRotation.y = defaultPose.data.pose?.rotation.y || 0;
        // }

        // console.log(`[st] [vp] [custom] setting camera position to ${cameraPosition} and rotation to ${cameraRotation}`);
        // this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        // // let newRot = new THREE.Quaternion().setFromEuler(new THREE.Euler(
        // //     120.34569532688162 * Math.PI / 180,
        // //     -3.2167042253612945 * Math.PI / 180,
        // //     (null || 0) * Math.PI / 180,
        // //     'YXZ'));

        // this.camera.rotation.set(cameraRotation.x * Math.PI / 180, cameraRotation.y * Math.PI / 180, (null || 0) * Math.PI / 180, 'YXZ');

        this.mouseDown = false;
        if (this._windowingSystem != null) {
            this._windowingSystem.inputSystem.registerMouseDownCallback(this.onMouseDown.bind(this));
            this._windowingSystem.inputSystem.registerMouseUpCallback(this.onMouseUp.bind(this));
            this._windowingSystem.inputSystem.registerMouseMoveCallback(this.onMouseMove.bind(this));

            this._windowingSystem.registerUpdateCallback(this.onUpdate.bind(this));
        }

        this.aimSensitivity = 2.5;
        this.rotationSpeed = 1;
        this.cameraSpeed = 1;
        this.crouchHeight = 0.5;
        this.direction = new THREE.Vector3();
    }

    protected onUpdate(deltaTime: number): void {
        //console.log(deltaTime);
        if (this._windowingSystem == null) {
            return;
        }

        if (this._windowingSystem.inputSystem.keyStates['KeyW']) {
            this.MoveForward(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['KeyS']) {
            this.MoveBackward(deltaTime);
        }

        if (this._windowingSystem.inputSystem.keyStates['KeyA']) {
            this.StrafeLeft(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['KeyD']) {
            this.StrafeRight(deltaTime);
        }

        // Add a crouch toggle check when the 'C' key is pressed:
        if (this._windowingSystem?.inputSystem.keyStates['KeyZ'] && !this.isCrouching) {
            this.crouch();
        } else if (!this._windowingSystem?.inputSystem.keyStates['KeyZ'] && this.isCrouching) {
            this.standUp();
        }
        
        //Do rotate left/right/up/down with arrow keys
        if (this._windowingSystem.inputSystem.keyStates['ArrowLeft']) {
            this.RotateLeft(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['ArrowRight']) {
            this.RotateRight(deltaTime);
        }

        if (this._windowingSystem.inputSystem.keyStates['ArrowUp']) {
            // this.RotateUp(deltaTime);
            this.MoveForward(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['ArrowDown']) {
            // this.RotateDown(deltaTime);
            this.MoveBackward(deltaTime);
        }
        //
        
        //Do rotate left/right/up/down with IJKL keys
        if (this._windowingSystem.inputSystem.keyStates['KeyJ']) {
            this.RotateLeft(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['KeyL']) {
            this.RotateRight(deltaTime);
        }

        if (this._windowingSystem.inputSystem.keyStates['KeyI']) {
            this.RotateUp(deltaTime);
        } else if (this._windowingSystem.inputSystem.keyStates['KeyK']) {
            this.RotateDown(deltaTime);
        }
    }

    protected crouch(): void {
        // Reduces the camera's Y position by the crouchHeight.
        this.camera.position.y -= this.crouchHeight;
        this.isCrouching = true;
    }

    protected standUp(): void {
        // Increases the camera's Y position by the crouchHeight.
        this.camera.position.y += this.crouchHeight;
        this.isCrouching = false;
    }

    public StrafeRight(deltaTime: number) {
        this.camera.getWorldDirection(this.direction);
        let strafeVector = this.camera.up.clone().cross(this.direction);

        if (this.fixedYplane)
            strafeVector.y = 0;

        this.camera.position.sub(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
    }

    public StrafeLeft(deltaTime: number) {
        this.camera.getWorldDirection(this.direction);
        let strafeVector = this.camera.up.clone().cross(this.direction);

        if (this.fixedYplane)
            strafeVector.y = 0;

        this.camera.position.add(strafeVector.clone().multiplyScalar(this.cameraSpeed * deltaTime));
    }

    public MoveBackward(deltaTime: number) {
        this.camera.getWorldDirection(this.direction);
        let cloneDirection = this.direction.clone();

        if (this.fixedYplane)
            cloneDirection.y = 0;

        this.camera.position.sub(cloneDirection.multiplyScalar(this.cameraSpeed * deltaTime));
    }

    public MoveForward(deltaTime: number) {
        this.camera.getWorldDirection(this.direction);
        let cloneDirection = this.direction.clone();

        if (this.fixedYplane)
            cloneDirection.y = 0;

        this.camera.position.add(cloneDirection.multiplyScalar(this.cameraSpeed * deltaTime));
    }

    protected onMouseDown(e: MouseEvent): void {
        // console.log("mouse down " + e);
        // console.log(e);

        if (Simulation.instance.draggedObject || Simulation.instance.sceneLoader.GizmoVisible) {
            this.mouseMouseSuspended = true;
        } else {
            this.mouseMouseSuspended = false;
            this.mouseDown = true;
        }
    }

    protected onMouseUp(e: MouseEvent): void {
        this.mouseDown = false;
        this.mouseMouseSuspended = false;
    }

    protected onMouseMove(e: MouseEvent): void {
        // if (this.mouseDown && !Simulation.instance.draggedObject) {
        if (Simulation.instance.draggedObject || Simulation.instance.sceneLoader.GizmoVisible) {
            this.mouseMouseSuspended = true;
        }
        if (this.mouseDown && !this.mouseMouseSuspended) {
            this.moveCameraWithMouse(e.movementX * 0.001, e.movementY * 0.001);
        }
    }

    public moveCameraWithMouse(x: number, y: number): void {

        this.camera.rotation.y -= x * this.aimSensitivity;
        this.camera.rotation.x -= y * this.aimSensitivity;

    }

    //Rotate the camera left
    public RotateLeft(deltaTime: number) {
        this.camera.rotation.y += this.rotationSpeed * deltaTime;
    }

    //Rotate the camera right
    public RotateRight(deltaTime: number) {
        this.camera.rotation.y -= this.rotationSpeed * deltaTime;
    }

    //Rotate the camera up
    public RotateUp(deltaTime: number) {
        this.camera.rotation.x += this.rotationSpeed * deltaTime;
    }

    //Rotate the camera down
    public RotateDown(deltaTime: number) {
        this.camera.rotation.x -= this.rotationSpeed * deltaTime;
    }


    get camera(): THREE.PerspectiveCamera {
        return this._camera;
    }

    get windowingSystem(): WindowingSystem {
        return this._windowingSystem!;
    }

    public goToDefaultPose() {
        let poses = store.getState().home.currentSpace?.poses;

        if (poses && poses.length > 0) {
            let defaultPose = poses.find(pose => !!pose.isDefault);
            defaultPose = (defaultPose || poses[0]);

            if (defaultPose) {
                this.goToPose(defaultPose.id);
            }
        }

    }

    public goToPoseData(poseData: SweepData | undefined) {
        if (!poseData) {
            return;
        }
        let cameraPosition = new THREE.Vector3(0, 0, 0);
        let cameraRotation = new THREE.Vector2(0, 0);

        if (poseData) {

            cameraPosition.x = poseData.pose?.position.x || 0;
            cameraPosition.y = poseData.pose?.position.y || 0;
            cameraPosition.z = poseData.pose?.position.z || 0;

            cameraRotation.x = poseData.pose?.rotation.x || 0;
            cameraRotation.y = poseData.pose?.rotation.y || 0;
        }

        // console.log(`[st] [vp] [custom] setting camera position and rotation to`, poseId, cameraPosition, cameraRotation);
        this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
        this.camera.rotation.set(cameraRotation.x * Math.PI / 180, cameraRotation.y * Math.PI / 180, (null || 0) * Math.PI / 180, 'YXZ');
    }

    public goToPose(poseId: string) {
        if (!poseId) {
            return;
        }
        let poses = store.getState().home.currentSpace?.poses;
        let pose = poses?.find(pose => pose.id == poseId);

        this.goToPoseData(pose?.data);

    }

    public worldToScreenPoint(position: THREE.Vector3, div: HTMLElement) {
        var pos = position.clone();
        let projScreenMat = new THREE.Matrix4();
        let VPMatrix = this.camera.projectionMatrix.clone().multiply(this.camera.matrixWorldInverse);
        projScreenMat.multiply(VPMatrix);
        // projScreenMat.multiplyVector3(pos);

        pos = pos.applyMatrix4(projScreenMat);

        var offset = this.findOffset(div);

        return {
            x: (pos.x + 1) * div.clientWidth / 2 + offset.left,
            y: (-pos.y + 1) * div.clientHeight / 2 + offset.top,
        };

    }

    private findOffset(element: HTMLElement): InternalPosition {
        var pos = new InternalPosition(0, 0);
        if (element.offsetParent) {
            do {
                pos.left += element.offsetLeft;
                pos.top += element.offsetTop;
            } while (element = element.offsetParent as HTMLElement)
        }
        return pos;
    }
}
