import * as HomeApp from 'types/models/home/HomeApp';
import * as LayerAction from 'types/actions/Layer.action';
import { AppActions } from 'types';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import _ from 'lodash';
import { tryCatch } from '@crema/utility/Utils';

const initialState: {
    currentLesson: HomeApp.Lesson | null;
    isWorkflowsSidebarOpen: boolean;
    tagGroupsList: HomeApp.TagGroup[];
    currentTagGroupId: string | undefined;
    bannerText: string;
    variableValues: any[];
    presentationMode: boolean;
    stepStatus: string;
} = {
    currentLesson: null,
    isWorkflowsSidebarOpen: false,
    tagGroupsList: [],
    currentTagGroupId: '',
    bannerText: '',
    variableValues: [],
    presentationMode: false,
    stepStatus: ''
};

const Layer = (state = initialState, action: AppActions) => {
    switch (action.type) {

        case LayerAction.SET_LESSON_DATA:
            // if (!action.payload){
            //   return {
            //     ...state,
            //     variableValues: initialState.variableValues,
            //     currentTagGroupId: initialState.currentTagGroupId,
            //     currentLesson: initialState.currentLesson,
            //   };
            // } else {

            // let varValues = state.variableValues.map(v => (v.value = '', v));
            tryCatch(console.log(`default lesson vars: `, action.payload?.defaultVars?.map(v => v.name + ": " + v.value)), 'default vars');
            return {
                ...state,
                currentLesson: action.payload,
                variableValues: state.currentTagGroupId ? state.variableValues : (action.payload?.defaultVars || []),
            };

        case LayerAction.SET_BANNER_TEXT:
            return {
                ...state,
                bannerText: action.payload,
            };
        case LayerAction.SET_BROAD_SIDEBAR_OPEN:
            return {
                ...state,
                isWorkflowsSidebarOpen: action.payload,
            };
        case LayerAction.SET_TAG_GROUP_LIST:
            return {
                ...state,
                tagGroupsList: action.payload,
            };

        case LayerAction.SET_TAG_GROUP_ID:
            return {
                ...state,
                currentTagGroupId: action.payload,
            };

        case LayerAction.SET_LESSON_VARIABLES: {
            // let varValues = _.cloneDeep(state.variableValues);
            // let v = varValues.find(v => v.name.toLowerCase() === action.payload.name.toLowerCase());
            // if (v) {
            //     v.value = action.payload.value;
            // } else {
            //     varValues.push(action.payload);
            // }
            // Simulation.instance.updateVariablesState(varValues, state.currentLesson?.id, state.currentTagGroupId); //TODO commented out - must have side effects
            return {
                ...state,
                variableValues: action.payload,
            };
        }
        case LayerAction.SET_PRESENTATION_MODE:
            Simulation.instance.updateFireWaterObjects(!action.payload);
            return {
                ...state,
                presentationMode: action.payload
            }

        case LayerAction.STOP_LESSON:
            return {
                ...initialState
            }

        case LayerAction.SET_STEP_STATUS:
            return {
                ...state,
                stepStatus: action.payload
            }

        default:
            return state;
    }
};
export default Layer;
