import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import Home from './Home';
import Projects from './Projects';
import ToDoApp from './ToDoApp';
import ContactApp from './ContactApp';
import Ecommerce from './Ecommerce';
import ThreeD from './ThreeD';
import Layer from './Layer';
import Reports from './Reports';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  home: Home,
  projects: Projects,
  todoApp: ToDoApp,
  contactApp: ContactApp,
  ecommerce: Ecommerce,
  threeD: ThreeD,
  layer: Layer,
  reports: Reports
};

export default reducers;
