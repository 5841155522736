import { firestore } from '../../@crema/services/auth/firebase/firebase';
import { AppActions } from '../../types';
import { Dispatch } from 'redux';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  ADD_SPACES_HIDE_MODAL,
  GET_LESSONS_LIST,
  SET_LESSON_DATA,
  SET_PRESENTATION_MODE,
  SET_TAG_GROUP_LIST,
} from '../../types/actions/Home.action';
import { Lesson, SpaceData } from '../../types/models/home/HomeApp';
import { AuthUser } from 'types/models/AuthUser';
import { makeid } from 'shared/helpers/helper';
// import {setCurrentLesson, setPresentationMode, setTagsViewModeSecondary} from './Home';
import { store } from 'App';
import { appIntl, BasicProfiler, canEditSpace } from '@crema/utility/Utils';
import { history } from 'redux/store';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { defaultLayerNameSingular } from 'shared/constants/AppConst';
import { setCurrentTagGroupId } from './Step';
import { setTagsViewModeSecondary } from './Home';
import { SET_LESSON_VARIABLES, STOP_LESSON } from 'types/actions/Layer.action';
import { addTagsToEmptyShowcase, getCurrentTagGroup } from 'modules/home/SpaceDetail/utils';
import _ from 'lodash';
import Utils from 'mp/core/craEngine/Tools/Utils';
import { Behaviors } from 'mp/core/craEngine/SubSystems/core/Behaviors';

export const onGetLessonsList = (spaceId: string): any => {
  // if (user == undefined || user.uid == undefined) return [];

  return (dispatch: Dispatch<AppActions>) => {
    let authUser = store.getState().auth.authUser;
    if (authUser != null) {
      dispatch(fetchStart());
      firestore
        .doc(`Spaces/${spaceId}`)
        .collection('lessons')
        .withConverter(Lesson.lessonConverter)
        .get()
        .then((querySnapshot) => {

          let newLessonList: Lesson[] = querySnapshot.docs.map((doc) => doc.data());

          let sorted_lessons = newLessonList.sort(
            (a: Lesson, b: Lesson) => b?.createdOn?.valueOf() - a?.createdOn?.valueOf(),
          );
          // console.log(newLessonList, sorted_lessons, "newLessonList");
          dispatch(fetchSuccess());
          // console.log(`[lesson] dispatching lessons ${JSON.stringify(sorted_lessons)}`)
          dispatch({ type: GET_LESSONS_LIST, payload: sorted_lessons });
          // dispatch({ type: SET_TAG_GROUP_ID, payload: '' });
        })
        .catch((error) => {
          console.error('Error getting lessons: ', error);
        });
    } else {
      dispatch(fetchError('User is not logged in! Please try again'));
    }
  };
};

export const onGetLayer = (spaceId: string, did: string): any => {
  return (dispatch: Dispatch<AppActions>, getState: any) => {
    const { primaryUseCase }: SpaceData = getState()?.home || {};
    dispatch(fetchStart());

    firestore
      .doc(`Spaces/${spaceId}`)
      .collection('lessons')
      .where('did', '==', did)
      .withConverter(Lesson.lessonConverter)
      .get()
      .then((querySnapshot) => {
        const res: Lesson = querySnapshot.docs.map((doc) => doc.data())[0];
        console.log(`[lesson] got lesson ${res.createdOn} -- ${res.createdOn?.constructor.name}`)

        dispatch(fetchSuccess());
        if (res) {
          dispatch(setCurrentLesson(res));
          // dispatch(setTagsViewMode(ViewMode.LAYERS));
        } else {
          dispatch(fetchError(`${primaryUseCase?.layerTermSingular || defaultLayerNameSingular} not found`));
        }

      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };
};

export const updateCurrentLesson = (lesson: Lesson): any => {
  const { messages } = appIntl();

  return (dispatch: Dispatch<AppActions>) => {
    // console.log(JSON.stringify(lesson.defaultVars));
    lesson.lastUpdatedOn = new Date();
    lesson.lastUpdatedBy = store.getState().auth.authUser?.uid || '';
    firestore.doc(`Spaces/${store.getState().home.currentSpace?.id}/lessons/${lesson.id}`)
      .set(lesson).then(() => {
        // firestore.doc(`Spaces/${store.getState().home.currentSpace?.id}/lessons/${lesson.id}`)
        // .withConverter
        // .get().then()
        // console.log(JSON.stringify(lesson.defaultVars));
        dispatch(setCurrentLesson(lesson));
      }).catch((e) => {
        console.error(e);
        dispatch(fetchError(messages['message.somethingWentWrong']));
      })

  };
};

export const setCurrentLesson = (lesson: Lesson | null): any => {

  let dvs: any[] = [];
  // let mustHaveVars = new Set();

  // Array.from(store.getState().home.spaceModels.values()).map(m => {
  //   m.userData?.inputSource1 && mustHaveVars.add(m.userData?.inputSource1);
  // });
  // //TODO all models -> vars found in logic should also have a default value

  // store.getState().home.spaceVariables.map(sv => {
  //   let dv = lesson?.defaultVars?.find(dv => sv.name == dv.name);
  //   let range = sv.values?.split(',');

  //   if (dv) { // to invalidate default var value if sv range=> value changed
  //     dvs.push({ ...sv, value: (range.find((vv: any) => vv == dv.value) || ''), allowControl: dv.allowControl })

  //   } else if (mustHaveVars.has(sv.name)) {
  //     dvs.push({ ...sv, value: range[0] || '' })

    // } else {
    //   dvs.push({ ...sv, value: '', allowControl: false })
    // }
  // });
  // lesson && (lesson.defaultVars = dvs);

  // if (lesson && (!lesson.defaultVars || lesson.defaultVars.length == 0)) {
  //   lesson.defaultVars = store.getState().home.spaceVariables.map(v => ({ ...v, value: v.values?.split(',')[0] || '' }))
  // }
  return (dispatch: Dispatch<AppActions>) => {
    // console.log(`[st] [lesson] dispatching lesson`, lesson, store.getState());
    [...store.getState().home.spaceModels.values()].map(n => Behaviors.saveCustomPropsBeforeLessonStarts(n.nodeRef))
    dispatch({ type: SET_LESSON_DATA, payload: lesson });
  };
};


export function goToLesson(spaceDid: string, projectDid: string, lessonDid: string, openInPresentationMode: boolean, navigateToFunction: (path: string) => void) {
  navigateToFunction(`/home/space/${spaceDid}/project/${projectDid}/layers/${lessonDid}`);
  if (openInPresentationMode) {
    store.dispatch(setPresentationMode(true));
  }
}

export function onExitPresentationMode() {

  if (canEditSpace()) {
    history.push({ pathname: history.location.pathname, search: "?mode=studio" })
  } else {
    // store.dispatch(onStopLesson());
    goToLessonsList();
  }
  store.dispatch(setPresentationMode(false));
}

export function goToLessonsList() {
  // store.dispatch(setCurrentLesson(null));
  store.dispatch(onStopLesson());
  try {
    history.push(`/home/space/${store.getState().home?.currentSpace?.did}/project/${store.getState().home?.currentSpace?.currentSpaceProject?.did}/layers`);
  }
  catch (e) {
    console.error(e);
  }
}

export const onStopLesson = (): any => {

  return (dispatch: Dispatch<AppActions>) => {
    if (store.getState().layer.currentLesson) {
      dispatch({ type: STOP_LESSON });
      Simulation.instance.resetAllNodesPositions();
      addTagsToEmptyShowcase({ tagIds: Object.keys(store.getState().home.spaceTags) });
      Simulation.instance.filterVisibleNodes(Array.from(store.getState().home.spaceModels.keys()));
      // Simulation.instance.updateVariablesState(vars, null, null);
    }
  }
}

export const setPresentationMode = (value: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_PRESENTATION_MODE, payload: value });
  };
};

// export const onSetVariable = (variable: any): any => {
//   return (dispatch: Dispatch<AppActions>) => {
//     console.trace();
//     // let varValues = _.cloneDeep(state.variableValues);
//     // let v = store.getState().layer.variableValues.find(v => v.id === variable.id);

//     // if (v) {
//     //   v.value = action.payload.value;
//     // } else {
//     //   varValues.push(action.payload);
//     // }

//     // let vars = store.getState().layer.variableValues.find(v => v.name !== variable.name);

//     dispatch({ type: SET_LESSON_VARIABLES, payload: vars });
//     Simulation.instance.updateVariablesState(vars, getCurrentTagGroup() || null);
//   };
// };

export const onSetVariableValue = (name: string, value: any) => {
  return (dispatch: Dispatch<AppActions>) => {
    // let v = getVariableByName(name);
    let bp = new BasicProfiler();
    let vars = _.cloneDeep(store.getState().layer.variableValues);
    bp.lap('[clone] vars in onSetVariableValue');

    let v = vars.find((v: any) => v.name === name);
    // console.log(`[vars] ${name} - ${value} --- ${JSON.stringify(v)}`);
    if (!v) {
      v = store.getState().home.spaceVariables.find(v => v.name === name);
      vars = vars.concat(v);
    }
    if (v && v.value !== value) {
      console.log(`[vars] setting var value`);
      v.value = value;
      dispatch({ type: SET_LESSON_VARIABLES, payload: vars });
    }

    // if(name === 'Battery 1 Positive' && value === "Connected"){//HARDCODED //FIXME
    //   if(store.getState().layer.variableValues.find(vv => vv.name === 'Battery 1 Negative' && vv.value === 'Connected')){
    //     let voltage = (Math.random() * 10);
    //     let voltageString = voltage.toFixed(1).toString();

    //     Array.from(store.getState().home.spaceModels.values()).forEach( m => {
    //       if (m.nodeRef.name === 'Multimeter'){
    //         let mn = m.nodeRef;
    //         mn && ((mn.components[9].inputs as any).text = voltageString);
    //       }
    //     })

    //   }
    // }
    // if(name === 'Battery 1 Negative' && value === "Connected"){//HARDCODED //FIXEME
    //   if(store.getState().layer.variableValues.find(vv => vv.name === 'Battery 1 Positive' && vv.value === 'Connected')){
    //     let voltage = (Math.random() * 10);
    //     let voltageString = voltage.toFixed(1).toString();

    //     Array.from(store.getState().home.spaceModels.values()).forEach( m => {
    //       if (m.nodeRef.name === 'Multimeter'){
    //         let mn = m.nodeRef;
    //         mn && ((mn.components[9].inputs as any).text = voltageString);
    //       }
    //     })

    //   }
    // }
  }



}
