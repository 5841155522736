import Particle from '../Particle';
import * as THREE from 'three';

export default class RegularEmitterDescriptor {
    constructor() {
    }
    
    public DescribeParticle(particle:Particle, position:THREE.Vector3, life:number) {
        // position.x += (5 * Math.random() - 1) * 0.1;
        // position.y += (10 * Math.random() - 1) * 0.01;
        // position.z += (5 * Math.random() - 1) * 0.1;
        particle.position.set(position.x, position.y, position.z);
        particle.mass = 1;
        particle.color.r = 1;
        particle.color.g = 1;
        particle.color.b = 1;
        particle.velocity.set((Math.random() * 2 - 1) * 0.1, 1.5, (Math.random() * 2 - 1) * 0.1);
        particle.angularVelocity = (Math.random() * 2 - 1) * 50;
        particle.spawn(life);
    }
}
