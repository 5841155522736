import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';
import TextureManager, {TextureWithLoadState} from '../../mp/core/craEngine/SubSystems/TextureManager';
import * as THREE from 'three';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import { Material } from 'three';
import {RaycastSystem} from '../Systems/RaycastSystem';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { SpatialThinkSDK } from 'CustomSdk/SpatialThinkSDK';

type Inputs = {
    visible: boolean;
    opacity: number;
    // borderOpacity: number;
    textureSource: string
    color: number;
    // borderColor:number;
    // borderRadius:number;
    // borderSize:number;
    localScale: { x: number; y: number; z: number; };
    localPosition: { x: number; y: number; z: number; };
    localRotation: { x: number; y: number; z: number; };
}

export class ImageRendererComponent extends BaseComponent {
    setNextInputValue(): void {
        // throw new Error('Method not implemented.');
    }


    inputs: Inputs = {
        visible: true,
        opacity: 1,
        //   borderOpacity: 1,
        //   borderSize: 1,
        textureSource: "",
        color: 0xffffffff,
        //   borderColor: 0xffffff00,
        //   borderRadius: 10,
        localScale: { x: 1, y: 1, z: 1 },
        localPosition: { x: 0, y: 0, z: 0 },
        localRotation: { x: 0, y: 0, z: 0 },
    }
    private mesh: THREE.Mesh | null = null;
    private texture: TextureWithLoadState | undefined;

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "ImageRendererComponent";
    }

    // public assignUserData(data: {[p: string]: any}):void {
    //     super.assignUserData(data);
    //     this.inputs.textureSource = data.textureSource;
    // }

    onInit(userdata: { [p: string]: any }) {
        super.onInit(userdata);
        this.inputs.textureSource = userdata.textureSource;
        this.texture = TextureManager.instance.LoadTexture(this.inputs.textureSource, this.buildMesh.bind(this), this.fallbackToDefaultTexture.bind(this))
        // this.node && this.setLoaded(true);
        // this.buildMesh(this.texture?.texture);

        this.outputs.collider = this.root;
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.root);
    }

    onInputsUpdated(oldInputs: this['inputs'], force:boolean) {
        if (oldInputs.textureSource !== this.inputs.textureSource || force) {
            console.log("IMAGE  " + this.inputs.textureSource);
            this.texture = TextureManager.instance.LoadTexture(this.inputs.textureSource, this.buildMesh.bind(this), this.fallbackToDefaultTexture.bind(this))
        }
    }

    private fallbackToDefaultTexture(e: any): void {
        TextureManager.instance.LoadTexture('/assets/images/imageComponent/noPicture.png', this.buildMesh.bind(this), null)
    }
    buildMesh(_texture: THREE.Texture | null = null) {
        this.freeAllMeshResources();

        let finalColorTransparent: boolean = false;
        // let finalBorderColorTransparent:boolean = false;

        if (this.inputs.opacity < 0.99) {
            finalColorTransparent = true;
        }

        this.mesh = new THREE.Mesh(
            //new THREE.PlaneGeometry(2, 2, 1, 1),
            new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, 0)).translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            new THREE.MeshBasicMaterial({
                transparent: true,
                map: _texture,
                opacity: this.inputs.opacity,
                color: this.inputs.color,
                side: THREE.DoubleSide
            }));

        this.root.add(this.mesh!);
        // this.node && this.setLoaded(true);
    }

    freeAllMeshResources(): void {
        this.mesh?.remove(this.mesh);
    }

    onDestroy() {
        this.texture?.Destroy();
        this.mesh?.geometry.dispose();
        (this.mesh?.material as Material)?.dispose();

        this.root.remove(this.mesh!);

        console.error('onDestroy not implemented');
    }
}