import { store } from "App";
import { IInteractionHoverEvent, IInteractionDragBeginEvent, Behaviors } from "../SubSystems/core/Behaviors";
import Simulation from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "../SubSystems/ui-interop/PropertiesPanel";
import Utils from "../Tools/Utils";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { LogicEngine, Logic, OBJECT_EVENT_TYPES } from "types/models/dataAccess/Logic";

export class HoverSpy implements IComponentEventSpy<IInteractionHoverEvent> {
    public eventType = ComponentInteractionType.HOVER;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }
    onEvent(payload: IInteractionHoverEvent) {
        // if (Simulation.instance.dragEndedOn) {
        //     console.log(`[st] hovering`, payload, Simulation.instance.dragEndedOn);

        //     let currentLessonId = store.getState().layer.currentLesson?.id;
        //     let currentSpaceId = store.getState().home.currentSpace?.id;
        //     let tg = getCurrentTagGroup();

        //     if (currentSpaceId && currentLessonId && tg?.logic) {

        //         Simulation.instance.dragEndedOn.position.copy(this.node.position);

        //         LogicEngine.processStepLogicConditions({
        //             tgId: tg.id, stepLogic: tg.logic as Logic,
        //             targetNode: this.node,
        //             targetNodeList: [this.node.userData?.id || '', Simulation.instance.grabbedObject?.userData?.id || ''],
        //             eventType: OBJECT_EVENT_TYPES.COLLIDES
        //         }

        //         );
        //     }
        //     Simulation.instance.dragEndedOn = null;
        //     Simulation.instance.dragEndedOnComponent = null;
        // }
        //Behaviors.instance.processAllNodeDragBeginEvents(this.node, payload);
        // console.log("hover");
        //let meshes = Utils.FindAllMeshesAndLineSegments(this.node);
        //Simulation.instance.highlightModel(this.node.userData[UserDataProperties.id], meshes);
        //console.log("highlighting")
        //let meshes = Utils.FindAllMeshesAndLineSegments(targetNode);
    }
}
