import BaseAnimation from "./BaseAnimation";
import * as THREE from "three"
import * as TWEEN from '@tweenjs/tween.js'
import { ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "../SubSystems/ui-interop/PropertiesPanel";

export default class GrowShrinkAnimation extends BaseAnimation {
    private static _instance: GrowShrinkAnimation;

    public static get instance():GrowShrinkAnimation {
        if(!this._instance) {
            this._instance = new GrowShrinkAnimation();
        }

        return this._instance;
    }


    protected _animation(node:ISceneNode, mesh:THREE.Object3D, extraParams: { [key: string]: any } | null):void {
        if(!mesh) {
            return;
        }
        let oldScale = mesh.scale.clone();
        let newScale = mesh.scale.clone();
        newScale.multiplyScalar(1.25);
        new TWEEN.Tween(mesh.scale)
        .to(
            {
                x: newScale.x,
                y: newScale.y,
                z: newScale.z
            },
            1000
        )
        .easing(TWEEN.Easing.Elastic.Out)
        .start()
        .onComplete(() =>
        {
            new TWEEN.Tween(mesh.scale)
            .to(
                {
                    x: oldScale.x,
                    y: oldScale.y,
                    z: oldScale.z
                },
                1000
            )
            .easing(TWEEN.Easing.Elastic.Out)
            .start();
        })
    }

}