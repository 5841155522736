import BaseAnimation from './BaseAnimation';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import {ISceneNode} from '../SubSystems/sceneManagement/SceneComponent';
import {UserDataProperties} from '../SubSystems/ui-interop/PropertiesPanel';
import {EulerIntegrator} from '../components/systemComponents/EulerIntegrator';

export default class MoveAnimation extends BaseAnimation {
    private static _instance: MoveAnimation;
    
    public static get instance(): MoveAnimation {
        if (!this._instance) {
            this._instance = new MoveAnimation();
        }
        
        return this._instance;
    }
    
    protected _rootAnimation(node: ISceneNode, extraParams: {[key: string]: any} | null): void {
        let newPosition = node.animatedPosition.clone();
        if ((extraParams as any).deltaPosition) {
            newPosition.add((extraParams as any).deltaPosition);
        }
        
        new TWEEN.Tween(node.position)
            .to(
                {
                    x: newPosition.x,
                    y: newPosition.y,
                    z: newPosition.z,
                },
                (extraParams as any).duration ? (extraParams as any).duration : 1000,
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start()
            .onComplete(() => {
                //super._animation(node.userData[UserDataProperties.id], null, null);
                //this.animationList[node.userData[UserDataProperties.id]] = null;
                super._animationComplete(node);
                node.animatedPosition.set(node.position.x, node.position.y, node.position.z);
            })
            .onUpdate((object: THREE.Vector3, elapsed: number) => {
                //console.log(object)
            });
    }
    
    protected _animation(node: ISceneNode, mesh: THREE.Object3D, extraParams: {[key: string]: any} | null): void {
        if (!mesh) {
            return;
        }
    
        // let oldParent = mesh.parent;
        // EulerIntegrator.instance?.scene.updateMatrixWorld();
        // EulerIntegrator.instance?.scene.add(mesh);
        let newPosition = mesh.position.clone();
        // newPosition.setFromMatrixPosition(mesh.matrixWorld);
        
        
        if ((extraParams as any).deltaPosition) {
            let deltaPosition = (extraParams as any).deltaPosition.clone();
            
            // let xDir = new THREE.Vector3();
            // let yDir = new THREE.Vector3();
            // let zDir = new THREE.Vector3();
            //
            // //mesh.parent!.matrix.invert().extractBasis(xDir, yDir, zDir);
            // let localMatrix = mesh.parent!.matrix.clone();
            //
            //
            //
            //  //deltaPosition = mesh.parent!.worldToLocal(deltaPosition);
            //
            // xDir.multiplyScalar(deltaPosition.x);
            // yDir.multiplyScalar(deltaPosition.y);
            // zDir.multiplyScalar(deltaPosition.z);
            // newPosition.add(xDir);
            // newPosition.add(yDir);
            // newPosition.add(zDir);
            // EulerIntegrator.instance?.scene.updateMatrixWorld();
            // deltaPosition = mesh.parent!.parent!.worldToLocal(deltaPosition);
    
            newPosition = mesh.parent!.localToWorld(newPosition);
            // deltaPosition.applyMatrix4(EulerIntegrator.instance?.scene.matrixWorld!);
            newPosition.add(deltaPosition);
    
            newPosition = mesh.parent!.worldToLocal(newPosition);
        }
        
        new TWEEN.Tween(mesh.position)
            .to(
                {
                    x: newPosition.x,
                    y: newPosition.y,
                    z: newPosition.z,
                },
                (extraParams as any).duration ? (extraParams as any).duration : 1000,
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start().onComplete(() => {
            EulerIntegrator.instance?.scene.updateMatrixWorld();
            // oldParent!.add(mesh);
            super._animationComplete(node);
            
        })
        
        //     .onUpdate(() => {
        //     //console.log('hi');
        // });
    }
    
}
