import React, { useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Checkbox,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEditSpace } from '@crema/utility/Utils';
import { fetchError } from 'redux/actions';
import TitlebarGridList from '@crema/core/TitlebarGridList';
import { SpaceRole } from 'types/models/home/HomeApp';
import { AuthUser } from 'types/models/AuthUser';
import { Disable } from 'react-disable';
import { SKETCHFAB_CLIENT_ID } from 'Config';
import { useHistory } from 'react-router-dom';
import { SketchfabToken, ThirdPartyName } from 'types/models/dataAccess/User';
import { executeSketchfabQuery, getMyModels, onDownloadModel, onSearchSketchfab } from 'redux/actions/ThirdParty';
import { Keys } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { MeModelResponse, ModelSearchResponse } from 'types/models/home/ThirdParty/Sketchfab';
import Spacer from '@crema/core/Spacer';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { TooltipIconButton } from '@crema/core/TooltipButton';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { store } from 'App';
import { SET_THIRD_PARTY_TOKENS, UPDATE_AUTH_USER } from 'types/actions/Auth.actions';
import { NoToneMapping } from 'three';
import clsx from 'clsx';
import { BoxBufferGeometry } from 'three';
import { Fonts } from 'shared/constants/AppEnums';

const useStyles = makeStyles((theme) =>
    createStyles({
        tabContainer: {
            // backgroundColor: '#e6d7d7',
            padding: '6px 16px',
            marginLeft: '4px',
            marginRight: '4px',
            borderRadius: '16px',
            border: '0.5px solid ' + theme.palette.secondary.main,
            // borderTopRadius: '8px',
            // borderStartEndRadius: '16px',
            // borderStartStartRadius: '16px',
            cursor: 'pointer',
            // margin: 'auto',
        },
        activeTabContainer: {
            // backgroundColor: theme.palette.background.default,
            backgroundColor: theme.palette.secondary.main,
        },
        tabButton: {
            textTransform: 'none',
            // borderStartEndRadius: '16px',
            // borderStartStartRadius: '16px',
        },
        activeTabButton: {
            color: 'white',
            // backgroundColor: theme.palette.background.default,
            fontWeight: Fonts.BOLD,
            // borderTopRadius: '8px',
            backgroundColor: theme.palette.secondary.main,
        }
    }));

interface Props {
    importer?: ThirdPartyName;
}

enum TabValues { MY_MODELS, SEARCH_SKETCHFAB, BLEH };
export default function ThirdPartyImporter({ importer = ThirdPartyName.SKETCHFAB }: Props) {

    const theme = useTheme();
    const sketchfabSearchResponse = useSelector<AppState, ModelSearchResponse>(({ home }) => home.sketchfabSearchResponse);
    const mySketchfabModelsResponse = useSelector<AppState, MeModelResponse>(({ threeD }) => threeD.mySketchfabModels);
    const [tab, setTab] = React.useState(TabValues.SEARCH_SKETCHFAB);

    const token = useSelector<AppState, string | undefined>(({ auth }) =>
        auth.authUser?.user?.thirdPartyTokens?.find(t => t.type == ThirdPartyName.SKETCHFAB)?.data.access_token);

    const spaceMembershipRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home.currentSpace?.spaceMembership?.role);


    // // const [openAuthWindow, setOpenAuthWindow] = React.useState(false);
    // const [searchText, setSearchText] = React.useState('');
    // const [noSizeLimit, setNoSizeLimit] = React.useState(false);
    // const [hoveringOnSearch, setHoveringOnSearch] = React.useState(false);
    const classes = useStyles();
    // const [token, setToken] = React.useState('');
    // const [searchResponse, setSearchResponse]

    // const classes = useStyles();
    const dispatch = useDispatch();
    // const history = useHistory();
    // let token = getSketchfabToken();

    const onGetThirdPartyToken = () => {
        let uid = store.getState().auth.authUser?.uid;

        return firestore
            .collection('Users')
            .doc(uid)
            .onSnapshot((documentSnapshot) => {
                console.log(`tpt update in onGetThirdPartyToken`);

                (documentSnapshot.data()?.thirdPartyTokens || []).forEach((tpt: any) => {
                    if (tpt.type == ThirdPartyName.SKETCHFAB) {
                        console.log(`tpt update in onGetThirdPartyToken: setting ${tpt.data?.access_token || ''}`);

                        // setToken(tpt.data?.access_token || '');
                        // {...authUser, user: {...authUser.user,
                        //     thirdPartyTokens: [...authUser.user.thirdPartyTokens, t]}};
                    }
                });
                dispatch({ type: SET_THIRD_PARTY_TOKENS, payload: documentSnapshot.data()?.thirdPartyTokens || [] })
                // dispatch({ type: GET_NOTIFICATIONS, payload: data });
            });
    }

    useEffect(() => {
        const subscriber = onGetThirdPartyToken();
        // Stop listening for updates when no longer required
        return () => subscriber();
    }, []);

    const objectPlacedId = useSelector<AppState, string>(({ threeD }) => threeD.objectPlacedId);

    // function toggleSearchHover() {
    //     setHoveringOnSearch(!hoveringOnSearch);
    // }

    useEffect(() => {
        Simulation.instance.handleModelClick(objectPlacedId);
    }, [objectPlacedId]);

    return (console.log(`%c[st] rendering ThirdPartyImporter`, 'color: orange;'),
        <Disable disabled={!canEditSpace()}>

            <Box display='flex' justifyContent={'center'}
                style={{ width: '100%',
                // backgroundColor: '#e6d7d7',
                paddingTop: '4px', marginBottom: '16px' }}>
                <Box onClick={() => {
                    setTab(TabValues.MY_MODELS)
                }}
                    className={clsx(classes.tabContainer, (tab === TabValues.MY_MODELS ? classes.activeTabContainer : ''))}>
                    <Box className={clsx(classes.tabButton, (tab === TabValues.MY_MODELS ? classes.activeTabButton : ''))}>
                        My Models
                    </Box>
                </Box>

                <Box onClick={() => {
                    setTab(TabValues.SEARCH_SKETCHFAB)
                }}
                    className={clsx(classes.tabContainer, (tab === TabValues.SEARCH_SKETCHFAB ? classes.activeTabContainer : ''))}>
                    <Box className={clsx(classes.tabButton, (tab === TabValues.SEARCH_SKETCHFAB ? classes.activeTabButton : ''))}
                    >
                        Search
                    </Box>
                </Box>
            </Box>
            {/* <Box style={{
                padding: '16px',
                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
            }}>
                <Typography style={{
                    fontSize: '14px',
                    fontWeight: 600,
                }} variant='body1'>
                    Search Sketchfab
                </Typography>
            </Box> */}
            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                {!token && <Button variant="outlined" onClick={async () => {
                    // setOpenAuthWindow(!openAuthWindow);
                    try {
                        console.log(`[st] preSketchfabUrl ${window.location.pathname} -- ${window.location.hostname}`);
                        localStorage.setItem('preSketchfabUrl', window.location.pathname);
                        window.open(`https://sketchfab.com/oauth2/authorize/?state=${store.getState().auth.authUser?.email}&response_type=token&client_id=${SKETCHFAB_CLIENT_ID}&approval_prompt=auto`);
                    } catch (error) {
                        console.log(error)
                        dispatch(fetchError("Error connecting to sketchfab"));
                    };
                }}
                >Link Sketchfab
                </Button>}

            </Box>

            {tab == TabValues.MY_MODELS && <MyModels token={token} mySketchfabModelsResponse={mySketchfabModelsResponse} />}
            {tab == TabValues.SEARCH_SKETCHFAB && <SearchSketchfab token={token} sketchfabSearchResponse={sketchfabSearchResponse} />}
            {/* </Layout> */}
            {/* {openAuthWindow && (console.log(`[st] preSketchfabUrl ${window.location.pathname}`), localStorage.setItem('preSketchfabUrl', window.location.pathname),<iframe
            sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
            src={`https://sketchfab.com/oauth2/authorize/?state=${authUser?.email}&response_type=token&client_id=${SKETCHFAB_CLIENT_ID}&output=embed`}>
title='Sketchfab Login'
            </iframe>)} */}
        </Disable >
    );

}

function MyModels({ token, mySketchfabModelsResponse }: { token: string | undefined, mySketchfabModelsResponse: MeModelResponse }) {

    const dispatch = useDispatch();
    React.useEffect(() => {
        token && dispatch(getMyModels(token))
    }, []);

    return (<>
        {(mySketchfabModelsResponse?.results?.length || 0) > 0 &&
            <Box style={{
                display: 'flex', flexDirection: 'row',
                justifyContent: 'center', alignItems: 'center',
                // backgroundColor: 'lightgrey'
            }}>

                <TooltipIconButton title="Previous page"
                    onClick={() => { !!mySketchfabModelsResponse.previous && token && dispatch(getMyModels(token, mySketchfabModelsResponse.previous)) }}
                >
                    <ArrowBackIosNewRoundedIcon />
                </TooltipIconButton>

                <TooltipIconButton title="Next page"
                    onClick={() => { !!mySketchfabModelsResponse.next && token && dispatch(getMyModels(token, mySketchfabModelsResponse.next)) }}
                >
                    <ArrowForwardIosRoundedIcon />
                </TooltipIconButton>
            </Box>}


        <div
        // style={{ backgroundColor: 'lightgrey' }}
        >
            <TitlebarGridList tileData={
                mySketchfabModelsResponse.results.filter(item => !!item.uid && !!item.name).map((model) => ({
                    id: model.uid || '',
                    img: (model?.thumbnails?.images && model?.thumbnails?.images.length > 0) ? model?.thumbnails?.images[0]?.url : '',
                    title: model.name || '',
                    onClick: () => model.uid && model.name && token && dispatch(onDownloadModel(model.uid, model.name, token)),
                    onDelete: () => console.log(`[st] `),
                    hoverInfo: `${((model?.archives?.gltf?.size || 0) / 1000000).toFixed(1)} MB`
                    // ((model?.archives?.gltf?.size || 0) / 1000000 > 7) ?
                    //     'Large model - may take up to 20 seconds to load' : ''
                    ,
                }))
            }
            />
        </div>
    </>)
}



function SearchSketchfab({ token, sketchfabSearchResponse }: {
    token: string | undefined,
    sketchfabSearchResponse: ModelSearchResponse
}) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const [searchText, setSearchText] = React.useState('');
    const [noSizeLimit, setNoSizeLimit] = React.useState(false);
    const [hoveringOnSearch, setHoveringOnSearch] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        performSearch()
    }, [noSizeLimit]);

    const performSearch = () => {
        console.log(`[st] searching: noSizeLimit: ${noSizeLimit}`)
        if (!!searchText?.trim()) {
            dispatch(onSearchSketchfab({ searchText: searchText?.trim(), noSizeLimit: noSizeLimit }));
        }
    }

    return (<>{canEditSpace() && token && <Box style={{
        width: "100%",
        // marginTop: '8px'
    }}>

        <TextField
            fullWidth
            size='small'
            style={{
                backgroundColor: theme.palette.background.paper,
                width: "100%",
            }}
            onMouseEnter={() => setHoveringOnSearch(true)}
            onMouseLeave={() => setHoveringOnSearch(false)}

            placeholder={'Search for 3D models'}
            variant="outlined"
            InputLabelProps={{ style: { fontSize: '13px' } }}
            InputProps={{
                startAdornment: <InputAdornment position="start"><SearchRoundedIcon /></InputAdornment>,
                endAdornment: hoveringOnSearch ? <>
                    <Tooltip title='Check if current search results are too few. Performance may be impacted for models > 15 MB'>
                        <Checkbox
                            checked={noSizeLimit}
                            onChange={() => {
                                setNoSizeLimit(!noSizeLimit);
                            }}
                        />
                    </Tooltip>
                    <div style={{ fontSize: '12px' }}>Large models</div>
                </> : <></>
            }}

            onChange={event => {
                setSearchText(event.target.value);
            }}
            onKeyDown={(e) => {
                if (e.keyCode == Keys.RETURN && !!searchText?.trim()) {
                    performSearch();
                }
            }}
        />
        <Spacer y={2} />

        {(sketchfabSearchResponse?.results?.length || 0) > 0 &&
            <Box style={{
                display: 'flex', flexDirection: 'row',
                justifyContent: 'center', alignItems: 'center'
            }}>

                <TooltipIconButton title="Previous page"
                    onClick={() => { !!sketchfabSearchResponse.previous && dispatch(executeSketchfabQuery(sketchfabSearchResponse.previous)) }}
                >
                    <ArrowBackIosNewRoundedIcon />
                </TooltipIconButton>

                <TooltipIconButton title="Next page"
                    onClick={() => { !!sketchfabSearchResponse.next && dispatch(executeSketchfabQuery(sketchfabSearchResponse.next)) }}
                >
                    <ArrowForwardIosRoundedIcon />
                </TooltipIconButton>
            </Box>}

        {/* <Tooltip title='Check if current search results are too few'>
            <>
                Large models
                <Checkbox
                    checked={noSizeLimit}
                    onChange={() => {
                        setNoSizeLimit(!noSizeLimit);
                        performSearch();
                    }}
                />
            </>
        </Tooltip> */}

        <TitlebarGridList tileData={
            sketchfabSearchResponse.results.filter(item => item.isDownloadable).map((model) => ({
                id: model.uid,
                img: (model?.thumbnails?.images && model?.thumbnails?.images.length > 0) ? model?.thumbnails?.images[0]?.url : '',
                title: model.name,
                onClick: () => dispatch(onDownloadModel(model.uid, model.name, token)),
                onDelete: () => console.log(`[st] `),
                hoverInfo: `${((model?.archives?.gltf?.size || 0) / 1000000).toFixed(1)} MB`
                // ((model?.archives?.gltf?.size || 0) / 1000000 > 7) ?
                //     'Large model - may take up to 20 seconds to load' : ''
                ,
            }))
        }
        />

    </Box>}
    </>)

}