import { CollaboratorObj, LabelObj, ProjectObj, TaskObj, TaskSectionObj } from 'types/models/apps/ProjectBoard';

export const ADD_PROJECT_SECTION = 'ADD_PROJECT_SECTION';
export const ADD_SECTION_TASK = 'ADD_SECTION_TASK';
export const ADD_NEW_PROJECT = 'ADD_NEW_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_LIST_TASK = 'DELETE_LIST_TASK';
export const EDIT_PROJECT_DETAIL = 'EDIT_PROJECT_DETAIL';
export const EDIT_PROJECT_LIST = 'EDIT_PROJECT_LIST';
export const EDIT_SECTION_TASK = 'EDIT_SECTION_TASK';
export const GET_PROJECT_DETAIL = 'GET_PROJECT_DETAIL';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_PROJECT_LABEL_LIST = 'GET_PROJECT_LABEL_LIST';
export const EDIT_PROJECT_LABEL = 'EDIT_PROJECT_LABEL';
export const DELETE_PROJECT_LABEL = 'DELETE_PROJECT_LABEL';
export const ADD_PROJECT_LABEL = 'ADD_PROJECT_LABEL';
export const ADD_MEMBER_LIST_INVITES = 'ADD_MEMBER_LIST_INVITES';
export const UPDATE_TASK = 'UPDATE_TASK';

export interface AddProjectListActions {
  type: typeof ADD_PROJECT_SECTION;
  payload: ProjectObj;
}

export interface AddListTaskActions {
  type: typeof ADD_SECTION_TASK;
  payload: ProjectObj;
}

export interface AddNewProjectActions {
  type: typeof ADD_NEW_PROJECT;
  payload: ProjectObj;
}

export interface DeleteProjectActions {
  type: typeof DELETE_PROJECT;
  payload: string;
}

export interface DeleteListActions {
  type: typeof DELETE_SECTION;
  payload: ProjectObj;
}

export interface DeleteListTaskActions {
  type: typeof DELETE_LIST_TASK;
  payload: ProjectObj;
}

export interface EditProjectDetailActions {
  type: typeof EDIT_PROJECT_DETAIL;
  payload: ProjectObj;
}

export interface EditProjectListActions {
  type: typeof EDIT_PROJECT_LIST;
  payload: ProjectObj;
}
export interface EditListTaskActions {
  type: typeof EDIT_SECTION_TASK;
  payload: ProjectObj;
}
export interface GetProjectDetailActions {
  type: typeof GET_PROJECT_DETAIL;
  payload: ProjectObj | null;
}
export interface GetProjectListActions {
  type: typeof GET_PROJECTS;
  payload: ProjectObj[];
}
export interface GetMemberListActions {
  type: typeof GET_MEMBER_LIST;
  payload: CollaboratorObj[];
}
export interface GetProjectLabelListActions {
  type: typeof GET_PROJECT_LABEL_LIST;
  payload: LabelObj[];
}

export interface EditProjectLabelActions {
  type: typeof EDIT_PROJECT_LABEL;
  payload: ProjectObj;
}
export interface DeleteProjectLabelActions {
  type: typeof DELETE_PROJECT_LABEL;
  payload: ProjectObj;
}

export interface AddProjectLabelActions {
  type: typeof ADD_PROJECT_LABEL;
  payload: ProjectObj;
}

export interface onAddProjectLabel {
  type: typeof ADD_MEMBER_LIST_INVITES;
  payload: ProjectObj;
}

export interface onUpdateTaskAction {
  type: typeof UPDATE_TASK;
  payload: TaskObj;
}

export type ProjectboardActions =
  | AddProjectListActions
  | AddListTaskActions
  | AddNewProjectActions
  | DeleteProjectActions
  | DeleteListActions
  | DeleteListTaskActions
  | EditProjectDetailActions
  | EditProjectListActions
  | EditListTaskActions
  | GetProjectDetailActions
  | GetProjectListActions
  | GetMemberListActions
  | GetProjectLabelListActions
  | EditProjectLabelActions
  | DeleteProjectLabelActions
  | AddProjectLabelActions
  | onAddProjectLabel
  | onUpdateTaskAction;
