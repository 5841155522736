import { Button, IconButton, Tooltip } from "@material-ui/core"
import React, { ReactNode } from "react"

interface Props {
    title: string;
    children: ReactNode;
    [x: string]: any;
}

export function TooltipButton({ title, children, ...props }: Props) {

    return <Tooltip title={title}>
        <Button
            {...props}
        >{children}
        </Button>
    </Tooltip>
}

export function TooltipIconButton({ title, children, ...props }: Props) {

    return <Tooltip title={title}>
        <IconButton
            {...props}
        >{children}
        </IconButton>
    </Tooltip>
}