import { ShowcaseTagMap } from 'redux/reducers/Home';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import {
  SpaceData,
  FilterDataSpaces,
  Lesson,
  IScene,
  ISceneData,
  ShowcaseTag,
  TagGroup,
  PeopleObj,
  SIDEBAR_INDEX,
} from 'types/models/home/HomeApp';
import { ModelSearchList, ModelSearchResponse } from 'types/models/home/ThirdParty/Sketchfab';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//SPACE
export const SET_MP_SDK = 'SET_MP_SDK';
export const SET_SPACE_DATA = 'SET_SPACE_DATA';
export const GET_SPACE_PROJECTS = 'GET_SPACE_PROJECTS';
export const ADD_SPACES_HIDE_MODAL = 'ADD_SPACES_HIDE_MODAL';
export const SET_SHARED_SPACE_DATA = 'SET_SHARED_SPACE_DATA';
export const SET_FILTER_DATA_SHARED_SPACES = 'SET_FILTER_DATA_SHARED_SPACES';
export const SET_SPACE_MODELS_LIST = 'SET_SPACE_MODELS_LIST';
export const SET_SPACE_TAGS_LIST = 'SET_SPACE_TAGS_LIST';
export const SET_CURRENT_SHOWCASE_TAGS = 'SET_CURRENT_SHOWCASE_TAGS';
export const GET_LESSONS_LIST = 'GET_LESSONS_LIST';
export const GET_SCENES_LIST = 'GET_SCENES_LIST';
export const SET_LESSON_DATA = 'SET_LESSON_DATA';
export const SET_SCENE_DATA = 'SET_SCENE_DATA';
export const SET_TAG_GROUP_LIST = 'SET_TAG_GROUP_LIST';
export const SET_TAG_GROUP_ID = 'SET_TAG_GROUP_ID';
export const SET_TAGS_VIEW_MODE = 'SET_TAGS_VIEW_MODE';
export const SET_TAGS_VIEW_MODE_SECONDARY = 'SET_TAGS_VIEW_MODE_SECONDARY';
export const SET_BROAD_SIDEBAR_OPEN = 'SET_BROAD_SIDEBAR_OPEN';
export const SET_SPACE_CONTROLS = 'SET_SPACE_CONTROLS';
export const SET_BANNER_TEXT = 'SET_BANNER_TEXT';
export const GET_PEOPLE_LIST_DATA = 'GET_PEOPLE_LIST_DATA';
export const SET_EDIT_SHOWCASE_TAG_ID = 'SET_EDIT_SHOWCASE_TAG_ID';
export const SET_OPEN_TAG_FORM = 'SET_OPEN_TAG_FORM';
export const SET_SELECTED_TAG = 'SET_SELECTED_TAG';
export const OPEN_RADIAL_MENU = 'OPEN_RADIAL_MENU';
export const SET_CURRENT_SIDEBAR_INDEX = 'SET_CURRENT_SIDEBAR_INDEX';
export const SET_IS_ADDING_TAG = 'SET_IS_ADDING_TAG';
export const SET_TAG_SID_BEING_ADDED = 'SET_TAG_SID_BEING_ADDED';
export const SET_CURRENT_CUSTOM_DATA = 'SET_CURRENT_CUSTOM_DATA';
export const SET_PRESENTATION_MODE = 'SET_PRESENTATION_MODE';
export const SET_SKETCHFAB_SEARCH_RESPONSE = 'SET_SKETCHFAB_SEARCH_RESPONSE';
export const SET_SPACE_VARIABLES = 'SET_SPACE_VARIABLES';
export const SET_SPACE_METADATA = 'SET_SPACE_METADATA';
export const ADD_NEW_TAG = 'ADD_NEW_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const CLEAR_SPACE = 'CLEAR_SPACE';
export const LOCK_VIEW = 'LOCK_VIEW';
export const EDIT_TAG_IN_XR = 'EDIT_TAG_IN_XR';
// export interface SetMpSdkAction {
//   type: typeof SET_MP_SDK;
//   payload: any;
// }

export interface SetSpaceDetailAction {
  type: typeof SET_SPACE_DATA;
  payload: SpaceData;
}
export interface GetSpaceProjectListActions {
  type: typeof GET_SPACE_PROJECTS;
  payload: ProjectObj[];
}
export interface AddSpaceHideModal {
  type: typeof ADD_SPACES_HIDE_MODAL;
  payload: false;
}

export interface SetSharedSpaceDetailAction {
  type: typeof SET_SHARED_SPACE_DATA;
  payload: SpaceData;
}
export interface SetFilterDataSharedSpacesAction {
  type: typeof SET_FILTER_DATA_SHARED_SPACES;
  payload: FilterDataSpaces;
}

export interface SetSpaceTagsAction {
  type: typeof SET_SPACE_TAGS_LIST;
  payload: ShowcaseTagMap;
}

export interface SetCurrentSpaceTagsAction {
  type: typeof SET_CURRENT_SHOWCASE_TAGS;
  payload: ShowcaseTag[];
}

export interface SetSpaceModelsAction {
  type: typeof SET_SPACE_MODELS_LIST;
  payload: Map<string, any>;
}
export interface GetLessonsAction {
  type: typeof GET_LESSONS_LIST;
  payload: Lesson[];
}
export interface GetScenesAction {
  type: typeof GET_SCENES_LIST;
  payload: ISceneData[];
}
export interface SetLessonDetailAction {
  type: typeof SET_LESSON_DATA;
  payload: Lesson | null;
}
export interface SetSceneDetailAction {
  type: typeof SET_SCENE_DATA;
  payload: ISceneData;
}
export interface SetTagsViewModeAction {
  type: typeof SET_TAGS_VIEW_MODE;
  payload: string;
}
export interface SetTagsViewModeSecondaryAction {
  type: typeof SET_TAGS_VIEW_MODE_SECONDARY;
  payload: string;
}
export interface SetTagGroupListAction {
  type: typeof SET_TAG_GROUP_LIST;
  payload: TagGroup[];
}
export interface SetTagGroupAction {
  type: typeof SET_TAG_GROUP_ID;
  payload: any;
}
export interface SetBannerTextAction {
  type: typeof SET_BANNER_TEXT;
  payload: any;
}

export interface SetPeopleListAction {
  type: typeof GET_PEOPLE_LIST_DATA;
  payload: PeopleObj[];
}
export interface SetSpaceVariablesAction {
  type: typeof SET_SPACE_VARIABLES;
  payload: any[];
}

// export interface SetClosePopUpAction {
//   type: typeof SET_CLOSE_ALL_POPUPS;
//   payload: any;
// }

export interface SetEditShowcaseTagIdAction {
  type: typeof SET_EDIT_SHOWCASE_TAG_ID;
  payload: string;
}

export interface SetOpenTagFormAction {
  type: typeof SET_OPEN_TAG_FORM;
  payload: boolean;
}

export interface SetWorkflowsSidebarOpenAction {
  type: typeof SET_BROAD_SIDEBAR_OPEN;
  payload: boolean;
}


// export interface SetProductViewTypeAction {
//   type: typeof SET_PRODUCT_VIEW_TYPE;
//   payload: VIEW_TYPE;
// }

export interface SetSelectedTagAction {
  type: typeof SET_SELECTED_TAG;
  payload: string;
}

export interface OpenRadialMenuAction {
  type: typeof OPEN_RADIAL_MENU;
  payload: boolean;
}

export interface SetCurrentSidebarIndexAction {
  type: typeof SET_CURRENT_SIDEBAR_INDEX;
  payload: SIDEBAR_INDEX;
}

export interface SetIsAddingTagAction {
  type: typeof SET_IS_ADDING_TAG;
  payload: boolean;
}
export interface SetTagSidBeingAdded {
  type: typeof SET_TAG_SID_BEING_ADDED;
  payload: any;
}

export interface SetCurrentCustomData {
  type: typeof SET_CURRENT_CUSTOM_DATA;
  payload: any;
}

export interface SetPresentationModeAction {
  type: typeof SET_PRESENTATION_MODE;
  payload: boolean;
}

export interface SetSketchfabSearchResponseAction {
  type: typeof SET_SKETCHFAB_SEARCH_RESPONSE;
  payload: ModelSearchResponse;
}
export interface SetSpaceMetadataAction {
  type: typeof SET_SPACE_METADATA;
  payload: any;
}

export interface AddNewTagAction {
  type: typeof ADD_NEW_TAG;
  payload: ShowcaseTag;
}

export interface UpdateTagAction {
  type: typeof UPDATE_TAG;
  payload: ShowcaseTag;
}


export interface ClearSpaceAction {
  type: typeof CLEAR_SPACE;
}


export interface LockViewAction {
  type: typeof LOCK_VIEW;
  payload: boolean;
}


export interface EditTagInXRAction {
  type: typeof EDIT_TAG_IN_XR;
  payload: boolean;
}

export type HomeActionTypes =
  // | SetMpSdkAction
  | SetSpaceDetailAction
  | AddSpaceHideModal
  | SetSpaceTagsAction
  | SetCurrentSpaceTagsAction
  | SetSpaceModelsAction
  | GetLessonsAction
  | GetScenesAction
  | SetLessonDetailAction
  | SetSceneDetailAction
  | SetSharedSpaceDetailAction
  | SetFilterDataSharedSpacesAction
  | SetTagsViewModeAction
  | SetTagsViewModeSecondaryAction
  | SetTagGroupListAction
  | SetTagGroupAction
  | SetBannerTextAction
  | SetPeopleListAction
  | SetSelectedTagAction
  // | SetVariablesListAction
	| SetEditShowcaseTagIdAction
  | SetOpenTagFormAction
  | GetSpaceProjectListActions
	// | SetTaskForEditAction
  | SetWorkflowsSidebarOpenAction
	// | SetClosePopUpAction;
  | OpenRadialMenuAction
  | SetCurrentSidebarIndexAction
  | SetIsAddingTagAction
  | SetTagSidBeingAdded
  | SetCurrentCustomData
  | SetPresentationModeAction
  | SetSketchfabSearchResponseAction
  | SetSpaceMetadataAction
  | AddNewTagAction
  | UpdateTagAction
  | SetSpaceVariablesAction
  | ClearSpaceAction
  | LockViewAction
  | EditTagInXRAction;
  ;
