import BaseAnimation from "./BaseAnimation";
import * as THREE from "three"
import * as TWEEN from '@tweenjs/tween.js'
import {MathUtils} from 'three';
import { ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "../SubSystems/ui-interop/PropertiesPanel";

export default class ScaleAnimation extends BaseAnimation {
    private static _instance: ScaleAnimation;
    
    public static get instance():ScaleAnimation {
        if(!this._instance) {
            this._instance = new ScaleAnimation();
        }
        
        return this._instance;
    }
    
    
    protected _animation(node:ISceneNode, mesh:THREE.Object3D, extraParams: { [key: string]: any } | null):void {
        if(!mesh) {
            return;
        }
        
        let newScale = mesh.scale.clone();
    
        if((extraParams as any).deltaScale) {
            let deltaScale = (extraParams as any).deltaScale;
            newScale.x = deltaScale.x;
            newScale.y = deltaScale.y;
            newScale.z = deltaScale.z;
        }
        
        new TWEEN.Tween(mesh.scale)
            .to(
                {
                    x: newScale.x,
                    y: newScale.y,
                    z: newScale.z
                },
                (extraParams as any).duration ? (extraParams as any).duration : 1000
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start().onComplete(() =>
            {
                super._animationComplete(node);
            });
    }
    
}
