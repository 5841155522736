import {ComponentInitFunction} from './MimickScene';

export interface PoseInterface {
    (pose:any):void
}

export class MimickPose {
    readonly poseCallbackList: PoseInterface[];
    
    constructor() {
        this.poseCallbackList = [];
    }
    
    public subscribe(poseCallback:PoseInterface) {
        this.poseCallbackList.push(poseCallback);
    }
}
