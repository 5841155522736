import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { OverflowMenu } from '../../../../../components/OverflowMenu';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  useTheme
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { appIntl, canEditSpace } from '@crema/utility/Utils';
import { ShowcaseTag, TagGroup, ANNOTATION_TYPE, Lesson, SpaceData } from 'types/models/home/HomeApp';
import { addTagsToEmptyShowcase, handleDeleteAllTags, handleTagClick } from '../../../../utils';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { setTagGroupsList } from 'redux/actions/Step';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { ViewMode } from '../..';
import { LabelObj, ProjectObj } from 'types/models/apps/ProjectBoard';
import { showMessage } from 'redux/actions';
import { Scrollbar } from '@crema';
import SearchBar from '@crema/core/SearchBar';
import { setIsRepositioning, getTagSidForTagId, setTagSidBeingAdded, getLabelIdsForTag } from './ShowcaseUtils';
import { addTag, updateTagLabels } from 'redux/actions/Tags';
import { store } from 'App';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Spacer from '@crema/core/Spacer';
import { updateTag } from 'redux/actions/Home';
import SortIcon from '@mui/icons-material/Sort';
import { Collapse, Divider, Grid, IconButton, Stack } from '@mui/material';
import { ModelInfoWrapper, ActionWrapper, StyledListItem } from '../../ThreeDSidebar/ModelListItem/index.style';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete/Autocomplete';
import IntlMessages from '@crema/utility/IntlMessages';
import { defaultTaskLabelColor } from 'shared/constants/AppConst';
import { MultipleOverflowMenu } from 'modules/home/components/MultipleOverflowMenu';
import * as Icons from '@mui/icons-material';

interface RefObject<T> {
  // immutable
  readonly current: T | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagsListRoot: {
      // overflow: 'scroll',
      // height: '800px',
      // paddingLeft: '10px',
      // paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingRight: '4px',
      paddingLeft: '4px',
      '& .MuiInputLabel-root': {
        fontSize: '14px'
      }
    },
    // searchBar: {
    //   color: 'blue'
    // },
    // heading: {
    //   fontWeight: 500,
    //   color: theme.palette.text.primary,
    //   lineHeight: '48px',
    // },
    root: {
      width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },
    // nested: {
    //   paddingLeft: theme.spacing(2),
    // },
    listItem: {
      backgroundColor: theme.palette.background.paper,
      paddingLeft: '12px',
      paddingRight: '2px',
      fontSize: '12px',
      // paddingTop: '2px',
      // paddingBottom: '2px',
      // background:"#f9f8f8",
      // borderRadius: "16px",
      // marginBottom: "7px",
      // '&:hover': {
      //   border: "1px solid grey",
      // },
      '& .MuiListItemIcon-root': {
        maxWidth: '32px'

      }
    },
    listItemTitle: {
      paddingLeft: '2px',
      paddingRight: '2px',
      fontSize: '13px',
      paddingTop: '2px',
      paddingBottom: '2px',
      width: '100%',
      wordWrap: 'break-word',
    },
    scrollLabels: {
      display: 'flex',
      width: '200px',
      height: '22px',
    },
    container: {

    },
    labelDropdown: {
      width: "100%",
      paddingTop: "8px"
    }
  }),
);

interface TagslistProps {
  onClickTagsPopup: (option: string, tagId: string) => void;
  currentShowcaseTags: ShowcaseTag[],
  // tabValue: number
}

interface newLabelObj {
  name: string;
  id: number;
  color: string;
  inputValue?: string;
}

enum SORTBY {
  CREATED_ON = 'Created On',
  LAST_UPDATED_ON = 'Last Updated On',
  ALPHABETICAL = 'Alphabetical'
}

const TagsList: React.FC<TagslistProps> = ({
  onClickTagsPopup,
  currentShowcaseTags,
  // tabValue
}) => {
  const classes = useStyles();
  const [sortBy, setSortBy] = React.useState(SORTBY.LAST_UPDATED_ON);
  const [hideTags, setHideTags] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Array<any>>([]);
  const currentSpaceId = useSelector<AppState, string>(({ home }) => home.currentSpace?.id || '');
  const currentLessonId = useSelector<AppState, string>(({ layer }) => layer?.currentLesson?.id || '');
  const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId || '');
  const tagGroupsList = useSelector<AppState, TagGroup[]>(({ layer }) => layer.tagGroupsList);
  const [selectedLabels, setSelectedLabels] = useState<LabelObj[]>([]);
  const projectDetail = useSelector<AppState, ProjectObj | null>(
    ({ projects }) => projects.projectDetail
  );
  const [filterText, setFilterText] = useState('');
  const { messages } = appIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  let visibleSpaceTags: ShowcaseTag[] = [];

  const tagOverflowMenuItems = (showcaseTag: ShowcaseTag) => [
    { title: 'Edit', onClick: () => onClickTagsPopup('Edit', showcaseTag.id) },
    {
      title: 'Reposition', onClick: () => {
        if (showcaseTag.id) {

          dispatch(showMessage("Move cursor in the Space area to reposition!"));
          // showcaseContext.handleAddTag({isRepositioning: true, tagId: showcaseTag.id})}},

          setIsRepositioning(true);
          setTagSidBeingAdded([getTagSidForTagId(showcaseTag?.id)]);

          dispatch(addTag(ANNOTATION_TYPE.INFO));
          // setTagSidBeingRepositioned(getTagSidForTagId(showcaseTag?.id || '') || ''); //FIXME what if it's undefined
        }
      }
    }
  ];

  //visibleSpaceTags = getFilteredTags({tagsViewMode: 'projectStatus'}, currentShowcaseTags);
  visibleSpaceTags = Array.from(currentShowcaseTags).filter(showcaseTag => {
    if (!filterText) return true;
    let searchableText = showcaseTag?.annotationType === 'quiz'
      ? showcaseTag?.quizDetails?.question
      : showcaseTag?.annotationType === ANNOTATION_TYPE.TASK
        ? showcaseTag?.taskTag?.title
        : showcaseTag.data?.label + showcaseTag.data?.description;
    return searchableText.toUpperCase().includes(filterText.toUpperCase());
  }).filter(filterLabelIds(selectedLabels)).sort(getSorter(sortBy));
  addTagsToEmptyShowcase({ tagIds: [], tagObjects: visibleSpaceTags });
  // useEffect(() => {
  //   // alert("edited")
  // 	visibleSpaceTags.find((el)=> {
  // 		if(editShowcaseTagId === el.id){
  // 			onClickTagsPopup('Edit', el.id)
  // 			return true
  // 		}
  // 	})
  // }, [editShowcaseTagId]);

  // useEffect(() => {
  // let refs = tagRefs;
  const tagRefs: Map<string, React.RefObject<HTMLInputElement>> = new Map();

  const Field = ({ children, id }: { children: any, id: string }) => {
    const fieldRef = React.useRef<HTMLInputElement>(null);
    tagRefs.set(id, fieldRef);
    return (
      <div
        className="field"
        ref={fieldRef}>
        {children}
      </div>
    );
  };

  const handleClick = (tagId: string) => { //TODO: commenting out because scrolling into view doesn't work reliably e.g. after you go to a different tab

    // let c = tagRefs.get(tagId)?.current;

    // if (tagId && !!c) {

    //   c.scrollIntoView({ behavior: "smooth" });

    //   // this is needed if we will need to highlight selected tag
    //   c.style.border = '1px solid green';
    //   // c.style.background = '#000';
    //     // for (var prop in tagRefs) {
    //     //   if (prop !== tagId) {
    //     //     tagRefs[prop].current!.style.background = '#fff';
    //     //     console.log('obj.' + prop + ' = ' + tagRefs[prop]);
    //     //   }
    //     // }
    // }
  };

  // React.useEffect(() => {
  //   if (editShowcaseTagId) {
  //     handleClick(editShowcaseTagId);
  //   }
  // }, [editShowcaseTagId]);


  const onChangeCheckBoxHandler = (event: any, id: any) => {
    event.stopPropagation();
    var selected: any = [...selectedIds];
    if (event.target.checked) {
      selected.push(id);
    } else {
      selected = selected.filter((s: any) => s != id);
    }
    setSelectedIds(selected);
  }

  const onChangeAllCheckBoxHandler = (event: any) => {
    var selected: any = [];
    if (event.target.checked) {
      selected = visibleSpaceTags.map((showcaseTag: ShowcaseTag) => showcaseTag.id);
    } else {
      selected = [];
    }
    setSelectedIds(selected);
  }

  const onSortItemClickHandler = (sortBy: SORTBY) => {
    setSortBy(sortBy);
  }

  function filterLabelIds(selectedLabels: Array<any>): (showcaseTag: ShowcaseTag, index: number) => any {
    const lengthOfSelectedLabels = selectedLabels.length;
    const selectedLabelIds = selectedLabels.map((label) => label.id);
    if (lengthOfSelectedLabels == 0) {
      return (_, __) => true;
    } else {
      return (showcaseTag, _) => {
        var labelIds: Array<any> = [];
        labelIds = getLabelIdsForTag(showcaseTag);

        return selectedLabelIds.every((val) => labelIds.includes(val));
      };
    }
  }

  const applyLabelToSelectedTags = (labelId: any, name: string) => {
    var tempVisibleSpaceTags: ShowcaseTag[] = [...selectedIds];
    console.log(`[st] applying label to ${selectedIds.length} tags`);

    tempVisibleSpaceTags.forEach((selectedId: any, i: number) => {
      const visibleSpaceTag = visibleSpaceTags.find((spaceTag) => spaceTag.id == selectedId);
      if (visibleSpaceTag) {
        if (!Array.isArray(visibleSpaceTag.labelIds)) {
          visibleSpaceTag.labelIds = [];
        }
        const isIndexAvailable = visibleSpaceTag.labelIds.findIndex((lab_id) => lab_id == labelId);
        if (isIndexAvailable == -1) {
          visibleSpaceTag.labelIds.push(labelId);
          updateTagLabels(visibleSpaceTag, visibleSpaceTag.labelIds);
          dispatch(updateTag(visibleSpaceTag));
          console.log(`[st] ${i + 1} applied`);
        }
      }
    });
    dispatch(showMessage(`Label ${name} applied to ${selectedIds.length} tag(s)`));
  }

  const removeLabelToSelectedTags = (labelId: any, name: string) => {
    var tempVisibleSpaceTags: ShowcaseTag[] = [...selectedIds];
    tempVisibleSpaceTags.forEach((selectedId: any) => {
      const visibleSpaceTag = visibleSpaceTags.find((spaceTag) => spaceTag.id == selectedId);
      if (visibleSpaceTag && Array.isArray(visibleSpaceTag.labelIds)) {
        const isIndexAvailable = visibleSpaceTag.labelIds.findIndex((lab_id) => lab_id == labelId);
        if (isIndexAvailable != -1) {
          visibleSpaceTag.labelIds = visibleSpaceTag.labelIds.filter((_, ind: number) => ind != isIndexAvailable);
          updateTagLabels(visibleSpaceTag, visibleSpaceTag.labelIds);
          dispatch(updateTag(visibleSpaceTag));
        }
      }
    });
    dispatch(showMessage(`Label ${name} removed from ${selectedIds.length} tag(s)`));
  }

  function getSorter(sortBy: SORTBY): (a: any, b: any) => number {
    switch (sortBy) {
      case SORTBY.ALPHABETICAL:
        return (a: any, b: any) => a.data.label.localeCompare(b.data.label);

      case SORTBY.CREATED_ON:
        return (a: any, b: any) => {
          if (!a.createdOn || !b.createdOn) {
            return a.data.label.localeCompare(b.data.label);
          }
          return b.createdOn.valueOf() - a.createdOn.valueOf();
        }

      case SORTBY.LAST_UPDATED_ON:
      default:
        return (a: any, b: any) => {
          if (!a.lastUpdatedOn || !b.lastUpdatedOn) {
            return a.data.label.localeCompare(b.data.label);
          }
          return b.lastUpdatedOn.valueOf() - a.lastUpdatedOn.valueOf()
        };
    }
  }

  return (console.log(`%c[st] rendering TagsList for tab ${currentShowcaseTags?.length}`, 'color: orange;'),

    <div className={classes.tagsListRoot}>
      {(<>
        <Spacer y={2} />
        {/* <Collapse> */}

        {/* </Collapse> */}
        <List
          // component='nav'
          aria-labelledby='nested-list-subheader'
          className={classes.root}>
          <ListSubheader style={{
            backgroundColor: theme.palette.background.paper,
            marginBottom: '4px'
          }}>

            <TagsListHeader
              visibleSpaceTags={visibleSpaceTags}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              onChangeAllCheckBoxHandler={onChangeAllCheckBoxHandler}
              onSortItemClickHandler={onSortItemClickHandler}
              projectDetail={projectDetail}
              applyLabelToSelectedTags={applyLabelToSelectedTags}
              removeLabelToSelectedTags={removeLabelToSelectedTags}
              filterText={filterText} setFilterText={setFilterText}
              selectedLabels={selectedLabels}
              setSelectedLabels={setSelectedLabels}
              currentTagGroupId={currentTagGroupId} />

          </ListSubheader>
          {visibleSpaceTags && visibleSpaceTags.map((showcaseTag: ShowcaseTag) => {
            let tagLabelIds = showcaseTag?.annotationType === ANNOTATION_TYPE.TASK ? showcaseTag.taskTag?.label.map(l => (l?.id)) : showcaseTag.labelIds;
            let tagLabelList: (LabelObj | undefined)[] | null = tagLabelIds?.map(tagId => (projectDetail?.labelList.find(tag => (tag.id === tagId)))) || null;
            return (<div key={`main-${showcaseTag.id}`}>
              <StyledListItem>
                <ModelInfoWrapper key={showcaseTag.id} className={`${classes.listItem} hover-to-show-link`}
                  // onMouseOver={() => set}
                  style={{
                    width: '100%'
                  }}
                  onClick={() => {
                    handleTagClick(showcaseTag.id);
                  }}

                >

                  {canEditSpace() && !currentTagGroupId && <Box className={`hover-to-show-link`}>
                    <span onClick={(event) => event.stopPropagation()} className={selectedIds.findIndex((val) => val == showcaseTag.id) == -1 ? "hover-to-show" : ""}>
                      <Checkbox
                        style={{
                          position: 'relative',
                          left: '10x',
                        }}
                        edge='start'
                        onChange={(event) => onChangeCheckBoxHandler(event, showcaseTag.id)}
                        checked={selectedIds.findIndex((val) => val == showcaseTag.id) != -1} />
                    </span>
                  </Box>}
                  {/* {Object.keys(currentScene || {}).length !== 0 && ( */}
                  {currentTagGroupId && (
                    <Checkbox
                      edge='start'
                      icon={<RadioButtonUncheckedRoundedIcon fontSize="medium" />}
                      checkedIcon={<CheckCircleRoundedIcon fontSize="medium" />}
                      checked={
                        currentTagGroupId &&
                          tagGroupsList &&
                          tagGroupsList.length > 0 &&
                          (tagGroupsList
                            .filter(
                              (tagGroup: TagGroup) =>
                                tagGroup.id === currentTagGroupId,
                            )[0]
                            ?.tagIds || []).includes(showcaseTag.id)
                          ? true
                          : false
                        // currentScene.tags
                        //   ?.map((o: any) => o?.data?.label)
                        //   .flat()
                        //   .indexOf(showcaseTag.data?.label) !== -1
                      }
                      tabIndex={-1}
                      onChange={async () => {
                        let currentTagGroup = tagGroupsList.filter(
                          (tagGroup: TagGroup) =>
                            tagGroup.id === currentTagGroupId,
                        )[0];
                        let tgs: TagGroup[] = [];
                        if (currentTagGroup.tagIds.includes(showcaseTag.id)) {
                          //uncheck the tag i.e. remove from tag group
                          // tagGroupsList =
                          // // currentTagGroup.tagIds = currentTagGroup.tagIds.filter((tagId: string) => tagId !== showcaseTag.id);
                          // tagGroupsList.map(((tagGroup: TagGroup) => tagGroup.id == currentTagGroup.id && tagGroup.tagIds.splice(
                          //   tagGroup.tagIds.indexOf(showcaseTag.id)
                          // )))

                          tagGroupsList.forEach((tagGroup) => {
                            if (tagGroup.id == currentTagGroupId) {
                              tagGroup.tagIds = (tagGroup.tagIds || []).filter(
                                (tid) => tid != showcaseTag.id,
                              );
                              currentTagGroup = tagGroup;
                            }
                            tgs.push(tagGroup);
                          });
                        } else {
                          //check the tag i.e. remove from tag group
                          tagGroupsList.forEach((tagGroup) => {
                            if (tagGroup.id == currentTagGroupId) {
                              tagGroup.tagIds.push(showcaseTag.id);
                              currentTagGroup = tagGroup;
                            }
                            tgs.push(tagGroup);
                          });
                        }
                        dispatch(setTagGroupsList(tgs));

                        await firestore
                          .doc(
                            `Spaces/${currentSpaceId}/lessons/${currentLessonId}/tagGroups/${currentTagGroup.id}`,
                          )
                          .set(currentTagGroup);

                        // tagGroupDocs.docs.map(tagGroupDoc => tgs.push({ id: tagGroupDoc.id, name: tagGroupDoc.data().name,
                        //   tagIds: tagGroupDoc.data().tagIds }));

                        // if (
                        //   currentScene.tags
                        //     ?.map((o: any) => o?.data?.label)
                        //     .flat()
                        //     .indexOf(showcaseTag.data?.label) !== -1
                        // ) {
                        //   // let [filterMatterTag] = sceneCollapsableOpen.tags.filter((item: any) => (item.label === tagTableData.data?.label));
                        //   submitSelectTagsForScene(showcaseTag, false);
                        // } else {
                        //   submitSelectTagsForScene(showcaseTag, true);
                        // }
                      }}
                      disableRipple
                      inputProps={{ 'aria-labelledby': showcaseTag.data?.label }}
                    />
                  )}

                  <Box
                    // flex={1}
                    width={'100%'}>

                    {/* <ListItemIcon style={{ minWidth: '40px' }}>
                      <VisibilityIcon />
                    </ListItemIcon> */}
                    {/*
                    <ListItemIcon
                    // style={{ minWidth: '40px' }}
                    >
                      {showcaseTag?.annotationType === 'quiz' ? (
                        <Icons.HelpOutlined
                        // style={{ color: '#F57E7E' }}
                        />
                      ) : (showcaseTag?.annotationType === ANNOTATION_TYPE.TASK ? (
                        <Icons.AssignmentTurnedIn
                        // style={{ color: '#F57E7E' }}
                        />) : (
                        <>
                          {(showcaseTag.data.media?.type.includes('mattertag.media.video')) && <Tooltip title='Has video'><Icons.Videocam /></Tooltip>}
                          {(showcaseTag.data.media?.type.includes('mattertag.media.photo')) && <Tooltip title='Has a picture'><Icons.InsertPhoto /></Tooltip>}
                          {(showcaseTag.data.media?.type.includes('mattertag.media.file')) && <Tooltip title='Has a file'><Icons.AttachFile /></Tooltip>}
                          {(showcaseTag.data.media?.type.includes('mattertag.media.rich')) && <Tooltip title='Has a link or other media'><Icons.InsertLink /></Tooltip>}

                        </>
                        // case 'mattertag.media.file':
                        //   return AttachFileIcon;
                        // case 'mattertag.media.rich':
                        //   return InsertLinkIcon;
                        // case 'mattertag.media.none':
                        //   default:
                        //   return InfoOutlinedIcon;

                        // TagIcons(showcaseTag.data.media?.type || '')
                        // <Icons.InfoOutlined
                        // style={{ color: '#F57E7E' }}
                        // />
                      ))}
                    </ListItemIcon> */}

                    <ListItemText
                      id={showcaseTag.id}
                      disableTypography={true}
                      primary={
                        showcaseTag?.annotationType === 'quiz'
                          ? showcaseTag?.quizDetails?.question
                          : showcaseTag?.annotationType === ANNOTATION_TYPE.TASK
                            ? showcaseTag?.taskTag?.title
                            : showcaseTag.data?.label
                      }
                      className={classes.listItemTitle}
                      style={{
                        cursor: 'pointer',
                        // fontSize: '12px',
                      }}

                    />

                    <Box>
                      {tagLabelList && tagLabelList.length > 0 && <Scrollbar className={classes.scrollLabels}>
                        {tagLabelList.map(label => (
                          <Tooltip arrow title={label?.name || ''} placement='top' key={label?.id}>
                            <Box
                              height={{ xs: 6, sm: 8 }}
                              width={20}
                              mx={1}
                              minWidth={20}
                              borderRadius={20}
                              key={label?.id}
                              bgcolor={label?.color}
                            />
                          </Tooltip>
                        ))}
                      </Scrollbar>}
                    </Box>
                    {/* )} */}
                  </Box>
                  {canEditSpace() && <ActionWrapper>
                    <Box>
                      <span className="labelAction" style={{ display: 'inline-block', position: 'relative', top: '7px' }}>
                        <DeleteOutlinedIcon
                          onClick={(e) => {
                            onClickTagsPopup('Delete', showcaseTag.id)
                          }}
                        />
                      </span>
                      <span style={{ display: 'inline-block' }}>
                        {canEditSpace() && (
                          <span style={{ marginLeft: 'auto' }} onClick={(event) => event.stopPropagation()}>
                            <OverflowMenu menuItems={tagOverflowMenuItems(showcaseTag)} />
                          </span>
                        )}
                      </span>
                    </Box>
                  </ActionWrapper>}
                </ModelInfoWrapper>
              </StyledListItem>
            </div>);
          })}
        </List>
      </>
      )}
    </div>
  );
};

export default TagsList;

function TagsListHeader({
  visibleSpaceTags,
  selectedIds,
  setSelectedIds,
  onChangeAllCheckBoxHandler,
  onSortItemClickHandler,
  projectDetail,
  applyLabelToSelectedTags,
  removeLabelToSelectedTags,
  filterText,
  setFilterText,
  // renderLabelDropdown,
  selectedLabels,
  setSelectedLabels,
  currentTagGroupId }: {
    visibleSpaceTags: ShowcaseTag[],
    selectedIds: string[],
    setSelectedIds: (tags: ShowcaseTag[]) => void,
    onChangeAllCheckBoxHandler: (e: any) => void,
    onSortItemClickHandler: (sortBy: SORTBY) => void,
    projectDetail: ProjectObj | null,
    applyLabelToSelectedTags: (id: number, name: string) => void,
    removeLabelToSelectedTags: (id: number, name: string) => void,
    filterText: string,
    setFilterText: (text: string) => void,
    // renderLabelDropdown: () => void,
    selectedLabels: LabelObj[],
    setSelectedLabels: (labels: LabelObj[]) => void,
    currentTagGroupId: string
  }) {

  const [shouldShowLabelFilter, setShouldShowLabelFilter] = React.useState(false);
  // const [expandedSearchBar, setExpandedSearchBar] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const { messages } = appIntl();
  const renderLabelDropdown = () => (
    <Autocomplete
      multiple
      className={classes.labelDropdown}
      id='tags-outlined'
      options={projectDetail?.labelList || []}
      selectOnFocus
      disableClearable
      handleHomeEndKeys
      value={selectedLabels}
      openOnFocus
      // getOptionSelected={}
      getOptionLabel={(option: newLabelObj) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue?.trim();
        }
        return option.name
      }}
      onChange={(event, value: newLabelObj[]) => {
        setSelectedLabels(value);
      }}
      renderOption={(option) => (!option.inputValue ? <Chip label={option.name} style={{ background: option.color }} /> : <Box>{option.inputValue}</Box>)}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          label={<IntlMessages id='common.label' />}
          fullWidth
          style={{
            fontSize: '12px'
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        <div style={{ position: 'relative', zIndex: 10 }} >
          {value.map((option, index) => (
            <Chip
              label={option.name}
              style={{ background: option.color, }}
              {...getTagProps({ index })}
              onClick={() => {

              }}
              onDelete={() => {
                setSelectedLabels(selectedLabels.filter((lab) => lab.id != option.id));
              }}
            />
          ))}
        </div>
      }

      filterOptions={(options, params) => {
        const filterHigh = createFilterOptions<newLabelObj>();
        const toFilter = filterHigh(options, params);
        // if (params.inputValue !== '') {
        //   let newLabel: newLabelObj = {
        //     inputValue: `Create new label "${params.inputValue}"`,
        //     name: `${params.inputValue}`,
        //     id: (projectDetail?.labelList || []).length,
        //     color: defaultTaskLabelColor,
        //   }
        //   toFilter.push(newLabel);
        // }
        return toFilter;
      }}
    />
  )
  return (console.log(`%c[st] rendering TagsListHeader`, 'color: orange;'), <>
    <Stack direction="row"
      // justifyContent="space-between"
      style={{
        backgroundColor: theme.palette.background.paper,
        borderBottom: '0.5px solid grey',
        // marginBottom: '8px'
      }}
    >
      {canEditSpace() && !currentTagGroupId && (visibleSpaceTags.length > 0) && (<Tooltip title={messages['menus.selectAll']}>
        <Checkbox
          style={{
            position: 'relative',
            left: '5px',
          }}
          edge='start'
          onChange={(event) => onChangeAllCheckBoxHandler(event)}
          checked={selectedIds.length == visibleSpaceTags.length} />

      </Tooltip>)}

      {selectedIds.length > 0 && (<div style={{ display: 'inline-block' }}>
        {projectDetail && projectDetail.labelList && visibleSpaceTags.length > 0 && (<MultipleOverflowMenu
          buttonStyle={{ fontSize: '10px' }}
          menuItems={projectDetail.labelList.map((option, index) => {
            return {
              title: <Chip
                label={option.name}
                style={{ background: option.color, }}
                key={`chip-project-details-${index}`}
              />,
              subItems: [
                { title: `Apply label '${option.name}' to ${selectedIds.length} tag(s)`, onClick: () => { applyLabelToSelectedTags(option.id, option.name) } },
                { title: `Remove label '${option.name}' from ${selectedIds.length} tag(s)`, onClick: () => { removeLabelToSelectedTags(option.id, option.name) } },
              ],
              onClick: () => { console.log('') }
            }
          })}
          propIcon={<SortIcon />} />
        )}
      </div>)}
      {selectedIds.length > 0 && (<Tooltip title={messages['menus.delete']}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (handleDeleteAllTags(selectedIds)) {
              setSelectedIds([]);
            }
          }}
          aria-haspopup='true'
          disableFocusRipple={true}>
          <DeleteOutlinedIcon />
        </IconButton>
      </Tooltip>
      )}
      {selectedIds.length > 0 && <Divider orientation="vertical" variant="middle" flexItem />}

      <span style={{
        display: 'inline-block',
        // marginTop: '8px'
      }}>
        {visibleSpaceTags.length > 0 && (<Tooltip title={messages['menus.sort']}><OverflowMenu styleProps={{ dense: false }} menuItems={[
          { title: "Alphabetical", onClick: () => { onSortItemClickHandler(SORTBY.ALPHABETICAL) } },
          { title: "Created On", onClick: () => { onSortItemClickHandler(SORTBY.CREATED_ON) } },
          { title: "Updated On", onClick: () => { onSortItemClickHandler(SORTBY.LAST_UPDATED_ON) } },
        ]}
          propIcon={<SortIcon />} /></Tooltip>)}
      </span>

      <Tooltip title={messages['menus.filter']}>
        <IconButton
          color={((selectedLabels.length > 0) ? 'info' : undefined)}
          onClick={(e) => {
            e.stopPropagation();
            setShouldShowLabelFilter(!shouldShowLabelFilter);
          }}
          aria-haspopup='true'
          disableFocusRipple={true}>
          <Icons.FilterAlt />
        </IconButton>
      </Tooltip>

      {/* <Collapse orientation="horizontal" in={expandedSearchBar} collapsedSize={40}> */}

      {/* {!expandedSearchBar && <Icons.Search />} */}
      {<SearchBar
        containerStyle={{
          // width: '100%',
          display: 'block',
          marginTop: '4px',
        }}
        iconStyle={{ color: (!!filterText ? theme.palette.info.main : undefined) }}
        overlap={false}
        iconPosition={'right'}
        borderLight
        // onlyIcon={!expandedSearchBar}
        value={filterText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFilterText(event.target.value)
        }
        // onMouseEnter={() => setExpandedSearchBar(true)}
        // onMouseLeave={() => setExpandedSearchBar(false)}

        placeholder={"Search"}
      />}

      {/* </Collapse> */}
    </Stack>
    {
      shouldShowLabelFilter &&
      <Box width={"90%"} style={{ display: 'flex' }}>
        {renderLabelDropdown()}
      </Box>
    }
  </>)
}