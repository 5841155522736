import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import PanelInputField, { PanelInputLabel } from './PanelInputField';
import { IoTSpecialProperties, NodeDataProperties, PropertiesPanelDropDownType, PropertiesPanelMode, PropertiesPanelUIData, UserDataGizmoMinorMods, UserDataProperties, UserDataTypes } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import { ChangeBooleanPropertyPassThrough, ChangeGenericActionOutcomeTextPassThrough, ChangeNodeColorPassThrough, ChangeTextPanelTextPassThrough, ChangeTextPassThrough, ChangeTextPassWithParamThrough } from 'mp/core/craEngine/Tools/InteropTypes/InteropClasses';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import CancelIcon from '@material-ui/icons/Cancel';
import { VariableTypeAllowedValues } from '../../Sidebar/Variables/VariableTypes';
import { EventActionOutcome, VariableValueTriggerPair, VariableLogicType, VariableValueActionPair, TriggerActionOutcome, ActionType } from 'mp/core/craEngine/SubSystems/ui-interop/PropertiesPanelBehaviorActions';
import { useAuthUser } from '@crema/utility/AppHooks';
import { store } from 'App';
import { fetchError } from 'redux/actions';
import { tryCatch } from '@crema/utility/Utils';
import { ISceneNode } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { NodeProperties } from 'types/models/home/HomeApp';
import { Quaternion, Euler, MathUtils } from 'three';
import { ISceneNodeExtensions } from 'mp/core/craEngine/extensions/ISceneNodeExtensions';
import { HelpBadge, RichHelpBadge } from '@crema/core/HelpBage';
import BadgeBeta from '@crema/core/BadgeBeta';
import Utils, { getEulerAnglesFromQuaternion } from '../../../../../../mp/core/craEngine/Tools/Utils';
import { ArrowFlowMarker } from 'modules/home/models';
import { SimulationMode } from 'mp/core/craEngine/SubSystems/core/RenderingAndPlaceObjectStateSystem';
import { isUserInExperiment } from 'modules/home/SpaceDetail/utils';
import ItemHover from '@crema/core/AppAnimation/ItemHover';
import { Container, Draggable } from 'react-smooth-dnd';
import NodeStorage from 'mp/core/craEngine/SubSystems/storageAndSerialization/NodeStorage';
import ExpandedModel from '../../Sidebar/ThreeDSidebar/ExpandedModel';
import { TreeView, TreeItem } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as THREE from 'three';
import ThreeD from 'redux/reducers/ThreeD';
import { SET_HIGHLIGHTED_OBJECT } from 'types/actions/ThreeD.actions';
import { catalogMap } from 'mp/catalog/catalog';

const useStyles = makeStyles((theme) => ({

    propPanelBox: {
        // backgroundColor: theme.background.default
        backgroundColor: theme.palette.background.paper,
        margin: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '16px',
        padding: '8px'
    },
    panelSubTitle: {
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10
    },
    behaviorSubTitle: {
        fontWeight: 500,
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10,
        fontSize: "13px",
        color: "black"
    },
    behaviorPrompt: {
        fontWeight: 500,
        paddingTop: "10px",
        paddingLeft: 8,
        marginBottom: 10,
        fontSize: "13px",
        color: "#1a9e1c"
    }
}));

interface Props {
    // propertiesPanelUIData: PropertiesPanelUIData
    /*
    setPropertiesPanelVisible(x:boolean):void;
    propertiesPanelVisible:boolean*/
}

const PropertiesPanel: React.FC<Props> = ({
    // propertiesPanelUIData
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // const variableValues = store.getState().layer.variableValues; // useSelector<AppState, any[]>(({ home }) => home.spaceVariables || []);
    const spaceModels = store.getState().home.spaceModels; //useSelector<AppState, Map<string, any>>(({ home }) => home.spaceModels);
    const spacePrimaryUseCase = store.getState().home.currentSpace?.primaryUseCase; //useSelector<AppState, UseCase | null>(({ home }) => home.currentSpace?.primaryUseCase || null);
    const spaceVariables = useSelector<AppState, any[]>(({ home }) => home.spaceVariables || []);

    const [propertiesPanelUIData, setPropertiesPanelUIData] = React.useState<PropertiesPanelUIData>(new PropertiesPanelUIData());
    Simulation.instance.propertiesPanel.setPropertiesPanelUIData = setPropertiesPanelUIData;

    // const [varTriggerList, setVarTriggerList] = useState<VarTriggerConfig[]>(nodeUserData[UserDataProperties.varTriggers] || []);
    const colorReference = React.useRef(null);
    const borderColorReference = React.useRef(null);
    // const [accordionExpanded, setAccordionExpanded] = React.useState(true);

    // setSpaceViewDispatchHook(dispatch);

    let propertiesPanelMode = PropertiesPanelMode.None;

    //currentNode = spaceModels.get(propertiesPanelUIData.currentNodeID);
    propertiesPanelMode = propertiesPanelUIData.propertiesPanelMode;

    let nodeUserData = propertiesPanelUIData.userData; //!currentNode ? {} : currentNode[UserDataProperties.userData];

    let varTriggerList: VariableValueTriggerPair[] = nodeUserData[UserDataProperties.varTriggers] || [];
    let varActionList: VariableValueTriggerPair[] = nodeUserData[UserDataProperties.varActions] || []; //uses same type as trigger, not a mistake

    const onColorChanged = (colorHex: string, alpha: number) => {
        Simulation.instance.propertiesPanel.changeColorOfNode(new ChangeNodeColorPassThrough(colorHex, alpha, false));
    }

    const onBorderColorChanged = (colorHex: string, alpha: number) => {
        Simulation.instance.propertiesPanel.changeColorOfNode(new ChangeNodeColorPassThrough(colorHex, alpha, true));
    }

    const onTextLabelChanged = (param: UserDataProperties, param2: string = "", passNode: ISceneNode | null = null) => async (e: any) => {
        //console.log(e.target._valueTracker.getValue(1));
        switch (param) {
            case UserDataProperties.textProperty:
                Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPanelTextPassThrough(e, null, null));
                //nodeUserData[UserDataProperties.nameToShow] = e;
                break;
            case UserDataProperties.fontSize:
                Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPanelTextPassThrough(null, e, null));
                break;
            case UserDataProperties.wireWidth:
                Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPanelTextPassThrough(null, null, e));
                break;
            case UserDataProperties.nameToShow:
                if (!e) {
                    store.dispatch(fetchError("Name can't be empty!"));
                } else {
                    Simulation.instance.propertiesPanel.changeNameOfNode(new ChangeTextPassThrough(e));
                }
                break;
            case UserDataProperties.newModelURL:
                Simulation.instance.propertiesPanel.changeModelURLOfNode(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.localPosition:
                Simulation.instance.propertiesPanel.changeLocalPositionOfNodeWithParam(new ChangeTextPassWithParamThrough(e, param2));
                break;
            case UserDataProperties.rotationAxis:
                Simulation.instance.propertiesPanel.changeRotationAxisOfNodeWithParam(new ChangeTextPassWithParamThrough(e, param2));
                break;
            case UserDataProperties.rotationRange:
                Simulation.instance.propertiesPanel.changeRotationRangesOfNodeWithParam(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.rotationSpeed:
                Simulation.instance.propertiesPanel.changeRotateInputField({ fieldName: param, passNode: null, value: e, updateUI: true });
                break;
            case UserDataProperties.borderRadius:
                Simulation.instance.propertiesPanel.changeBorderRadius(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.borderSize:
                Simulation.instance.propertiesPanel.changeBorderSize(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.flowRadius:
                Simulation.instance.propertiesPanel.changeFlowRadius(new ChangeTextPassThrough(e, passNode));
                break;
            case UserDataProperties.textureSource:
                Simulation.instance.propertiesPanel.changeImageTextureSource(new ChangeTextPassThrough(e));
                break;
            case UserDataProperties.hideDigitalReading:
                Simulation.instance.propertiesPanel.changeAnalogGaugeInputFields({ fieldName: param, passNode: null, value: !!e, updateUI: true });
                break;
            case UserDataProperties.minReading:
            case UserDataProperties.maxReading:
            case UserDataProperties.rotationDelta:
            case UserDataProperties.initialReadingOffset:
                Simulation.instance.propertiesPanel.changeAnalogGaugeInputFields({ fieldName: param, passNode: null, value: parseFloat(e), updateUI: true });
                break;
            case UserDataProperties.customProps:
                Simulation.instance.propertiesPanel.changeCustomProps({ fieldName: param, value: e, updateUI: true });
                break;
        }

        Simulation.instance.propertiesPanel.saveLastNode(true);
    }

    const onTextLabelChangedEventAction = (param: EventActionOutcome, index: number) => async (e: any) => {
        Simulation.instance.propertiesPanel.changeParameterOfEventAction(new ChangeGenericActionOutcomeTextPassThrough(e, index));
        Simulation.instance.propertiesPanel.saveLastNode(true);
    }

    const onTextLabelChangedTriggerAction = (param: TriggerActionOutcome, index: number) => async (e: any) => {
        Simulation.instance.propertiesPanel.changeParameterOfTriggerAction(new ChangeGenericActionOutcomeTextPassThrough(e, index));
        Simulation.instance.propertiesPanel.saveLastNode(true);
    }

    const onTextLabelNodeDataChanged = (name: NodeDataProperties) => async (e: any) => {
        //console.log(e.target._valueTracker.getValue());
        let validChange: boolean = false;
        switch (name) {
            case NodeDataProperties.scale:
                let newValue = e;

                if (!isNaN(newValue) && !isNaN(parseFloat(newValue))) {
                    if (propertiesPanelUIData.nodePointer) {
                        console.log(propertiesPanelUIData.nodePointer);
                        Simulation.instance.propertiesPanel.setUniformScaleOnNode(propertiesPanelUIData.nodePointer, newValue);
                    }

                    validChange = true;
                }
                //Simulation.instance.propertiesPanel.changeTextOfNode(new ChangeTextPassThrough(e.target._valueTracker.getValue(), null));
                break;
        }
        if (validChange) {
            Simulation.instance.propertiesPanel.saveLastNode(true);
        }
    }

    const handleDeleteClick = async (
        typeOfDropDown: PropertiesPanelDropDownType,
        event: React.MouseEvent,
        index: number
    ) => {
        Simulation.instance.propertiesPanel.deleteItem(typeOfDropDown, index);
    };

    const handleDropDownChange = async (
        typeOfDropDown: PropertiesPanelDropDownType,
        event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
        index: number,
        paramIndex: number = 1
    ) => {
        switch (typeOfDropDown) {
            case PropertiesPanelDropDownType.ActionTypeDropDown:
                if (Simulation) {
                    Simulation.instance.propertiesPanel.dropDownChangeEvents<ActionType>(typeOfDropDown, event.target.value as ActionType, index);
                }
                break;
            case PropertiesPanelDropDownType.TriggerActionTypeDropDown:
                if (Simulation) {
                    Simulation.instance.propertiesPanel.dropDownChangeEvents<ActionType>(typeOfDropDown, event.target.value as ActionType, index);
                }
                break;
            case PropertiesPanelDropDownType.SystemVariablesTriggerDropDown:
                if (Simulation) {
                    if (paramIndex == 1) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: event.target.value, value: "", logic: VariableLogicType.blank } as VariableValueTriggerPair, index);
                    } else if (paramIndex == 2) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: "", value: event.target.value, logic: VariableLogicType.blank } as VariableValueTriggerPair, index);
                    } else if (paramIndex == 3) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueTriggerPair>(typeOfDropDown, { name: "", value: "", logic: event.target.value as VariableLogicType } as VariableValueTriggerPair, index);
                    }

                }
                break;
            case PropertiesPanelDropDownType.SystemVariablesActionDropDown:
                if (Simulation) {
                    if (paramIndex == 1) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueActionPair>(typeOfDropDown, { name: event.target.value, value: "" } as VariableValueActionPair, index);
                    } else if (paramIndex == 2) {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<VariableValueActionPair>(typeOfDropDown, { name: "", value: event.target.value } as VariableValueActionPair, index);
                    }

                }
                break;
            case PropertiesPanelDropDownType.InputSource1_DropDown:
                if (Simulation) {
                    //console.log(event.target);
                    if (!!event.target) { //TODO //FIXME TERRIBLE HACK please fix

                        Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(typeOfDropDown, event.target.value as string, 0);
                    } else {
                        Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(typeOfDropDown, event as string, 0);
                    }

                }
                break;
        }

    };

    const onCheckboxPropertiesChange = (name: UserDataProperties) => async (e: any) => {
        /*
        switch (name) {
            case UserDataProperties.hasBorderProperty:
                nodeUserData[UserDataProperties.hasBorderProperty] = !nodeUserData[UserDataProperties.hasBorderProperty];
                await Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(new ChangeBooleanPropertyPassThrough(nodeUserData[UserDataProperties.hasBorderProperty], UserDataProperties.hasBorderProperty));
                break;
        }*/

        nodeUserData[name] = !nodeUserData[name];
        if (Simulation.instance.propertiesPanel.setBooleanPropertyOfNode(
            new ChangeBooleanPropertyPassThrough(nodeUserData[name], name)
        )) {
            Simulation.instance.propertiesPanel.saveLastNode(true);
        }
    }

    const getModelConfigFields = () => {

        return (<Grid container alignItems="center">
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Pivot" }} />
            </Grid>
            <Grid item xs={7}>
                <Box display='flex'>
                    <PanelInputField props={{
                        label: "Pivot X",
                        type: "textInput",
                        inputType: 'number',
                        text: nodeUserData[UserDataProperties.localPosition].x,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'x')
                    }} />

                    <PanelInputField props={{
                        label: "Pivot Y",
                        type: "textInput",
                        inputType: 'number',
                        text: nodeUserData[UserDataProperties.localPosition].y,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'y')
                    }} />

                    <PanelInputField props={{
                        label: "Pivot Z",
                        type: "textInput",
                        inputType: 'number',
                        text: nodeUserData[UserDataProperties.localPosition].z,
                        onChange: onTextLabelChanged(UserDataProperties.localPosition, 'z')
                    }} />
                </Box>
            </Grid>

            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Axis" }} />
            </Grid>
            <Grid item xs={7}>
                <Box display='flex'>
                    <PanelInputField props={{
                        label: "Axis X", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].x,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'x')
                    }} />
                    <PanelInputField props={{
                        label: "Axis Y", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].y,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'y')
                    }} />
                    <PanelInputField props={{
                        label: "Axis Z", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationAxis].z,
                        onChange: onTextLabelChanged(UserDataProperties.rotationAxis, 'z')
                    }} />
                </Box>
            </Grid>
        </Grid>

        )
    }


    let showColorProperty = (UserDataProperties.hasColorProperty in nodeUserData && nodeUserData[UserDataProperties.hasColorProperty]);
    let showBorderColorProperty = (UserDataProperties.hasBorderProperty in nodeUserData && nodeUserData[UserDataProperties.hasBorderProperty]);
    let borderColorLabelText = "";
    switch (nodeUserData[UserDataProperties.type]) {
        case UserDataTypes.thermostatNest:
            borderColorLabelText = "Main Color";
            break;
        default:
            borderColorLabelText = "Border Color";
            break;
    }

    let inputSourceForNest = null;
    let inputSourceForTextBox = null;
    let arrowFlowMarkers = null;
    let analogGaugeFields = null;
    let wireFields = null;

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.wire) {
        wireFields = <>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Wire Width" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    label: "Wire Width",
                    type: "textInput",
                    inputType: 'number',
                    text: nodeUserData[UserDataProperties.wireWidth],
                    onChange: onTextLabelChanged(UserDataProperties.wireWidth)
                }} />
            </Grid>
        </>
    }

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.analogGauge) {
        analogGaugeFields = (<>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Min Reading" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    type: "textInput",
                    inputType: 'number',
                    text: nodeUserData[UserDataProperties.minReading],
                    onChange: onTextLabelChanged(UserDataProperties.minReading)
                }} />
            </Grid>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Max Reading" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    type: "textInput",
                    inputType: 'number',
                    text: nodeUserData[UserDataProperties.maxReading],
                    onChange: onTextLabelChanged(UserDataProperties.maxReading)
                }} />
            </Grid>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Rotation Delta" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    type: "textInput",
                    inputType: 'number',
                    text: (console.log('prop rot delta', nodeUserData[UserDataProperties.rotationDelta]), nodeUserData[UserDataProperties.rotationDelta]),
                    maxDecimalPlaces: 8,
                    onChange: onTextLabelChanged(UserDataProperties.rotationDelta)
                }} />
            </Grid>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Initial offset" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    type: "textInput",
                    inputType: 'number',
                    text: nodeUserData[UserDataProperties.initialReadingOffset],
                    onChange: onTextLabelChanged(UserDataProperties.initialReadingOffset)
                }} />
            </Grid>
            <Grid item xs={5}>
                <PanelInputLabel props={{ label: "Hide Digital Reading" }} />
            </Grid>
            <Grid item xs={7}>
                <PanelInputField props={{
                    type: "checkbox",
                    value: nodeUserData[UserDataProperties.hideDigitalReading],
                    onChange: onTextLabelChanged(UserDataProperties.hideDigitalReading)
                }} />
            </Grid>
        </>)
    }
    let inputSourceForMultimeter = null;
    // if (nodeUserData[UserDataProperties.type] === UserDataTypes.multimeter) {
    //     let allowedSystemVariablesForMultimeter = VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, spaceVariables);
    //     inputSourceForMultimeter =
    //         // <Grid container alignItems="center">
    //         <>
    //             <Grid item xs={5}>
    //                 <Box display='inline-flex' alignItems={'center'}>
    //                     <PanelInputLabel props={{ label: "Variable" }} />
    //                     <RichHelpBadge
    //                         // type="popper"
    //                         title="Variable"
    //                         content={[{ text: 'Link the value shown to a Variable to change its values dynamically' },
    //                         {
    //                             text: 'Create a variable and set "Allowed values" as comma-separated values',
    //                             imgSrc: "/assets/images/help/IotVarsHelp.png"
    //                         },
    //                         {
    //                             text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose",
    //                             imgSrc: "/assets/images/help/InteractionsHelp.png"
    //                         }
    //                         ]}
    //                     >
    //                     </RichHelpBadge>
    //                 </Box>
    //             </Grid>
    //             {/* <Grid container alignItems="center"> */}
    //             <Grid item xs={7}>
    //                 {/*
    //             <Tooltip title='Variable containing possible values for this gauge'
    //             > */}
    //                 <div>
    //                     <PanelInputField props={{
    //                         // label: "Variable containing possible values for this gauge",
    //                         type: "select", values: allowedSystemVariablesForMultimeter,
    //                         value: nodeUserData[UserDataProperties.inputSource1],
    //                         onChange: (e: any) => {
    //                             //console.log(e)
    //                             handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
    //                         }
    //                     }} />
    //                 </div>
    //                 {/* </Tooltip> */}

    //                 {/* </Grid> */}
    //             </Grid>
    //         </>
    // }
    if ((nodeUserData[UserDataProperties.type] === UserDataTypes.thermostatNest) || (nodeUserData[UserDataProperties.type] === UserDataTypes.analogGauge)) {

        let clickAnObjectTextPromptElement = null;

        if (propertiesPanelMode === PropertiesPanelMode.Editing_IOT) {
            clickAnObjectTextPromptElement = <Typography variant="body1" className={classes.behaviorPrompt}> Click an IoT in the scene!</Typography>
        }

        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, spaceVariables);
        //VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, variableValues);

        let otherNameToShow1 = "<None>";
        let otherNameToShow2 = "<None>";
        if (spaceModels.size > 0) {
            let node = spaceModels.get(nodeUserData[IoTSpecialProperties.diff1]);
            let node2 = spaceModels.get(nodeUserData[IoTSpecialProperties.diff2]);
            if (node) {
                otherNameToShow1 = node.userData[UserDataProperties.nameToShow];
            }

            if (node2) {
                otherNameToShow2 = node2.userData[UserDataProperties.nameToShow];
            }
        }

        inputSourceForNest =
            // <Grid container alignItems="center">
            <>
                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'}>
                        <PanelInputLabel props={{ label: "Variable" }} />
                        <RichHelpBadge
                            // type="popper"
                            title="Variable"
                            content={[{ text: 'Link this gauge to a Variable to change its values dynamically' },
                            {
                                text: 'Create a variable and set "Allowed values" as comma-separated values',
                                imgSrc: "/assets/images/help/IotVarsHelp.png"
                            },
                            {
                                text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose",
                                imgSrc: "/assets/images/help/InteractionsHelp.png"
                            }
                            ]}
                        >
                        </RichHelpBadge>
                    </Box>
                </Grid>
                {/* <Grid container alignItems="center"> */}
                <Grid item xs={7}>
                    {/*
                    <Tooltip title='Variable containing possible values for this gauge'
                    > */}
                    <div>
                        <PanelInputField props={{
                            // label: "Variable containing possible values for this gauge",
                            type: "select", values: allowedSystemVariablesAsInputSourceForNest, value: nodeUserData[UserDataProperties.inputSource1],
                            onChange: (e: any) => {
                                //console.log(e)
                                handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                            }
                        }} />
                    </div>
                    {/* </Tooltip> */}

                    {/* </Grid> */}
                </Grid>
            </>
        {/*
            <Grid container alignItems="center">
            <Grid item xs={3}>
                <PanelInputLabel props={{ label: "Difference" }} />
            </Grid>
            </Grid>
            <Grid container alignItems="center">
            <Grid item xs={5}>
                <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow1, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeIOTDiffSource(1); } }} />
            </Grid>
            <Fab color='inherit' size='small' onClick={(e) => { Simulation.instance.propertiesPanel.deleteNodeIOTDiffSource(1); }}>
                <CancelIcon />
            </Fab>
            <Grid item xs={5}>
                <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow2, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeIOTDiffSource(2); } }} />
            </Grid>
            <Fab color='inherit' size='small' onClick={(e) => { Simulation.instance.propertiesPanel.deleteNodeIOTDiffSource(2); }}>
                <CancelIcon />
            </Fab>

            {clickAnObjectTextPromptElement}
            </Grid>*/}
        // </Grid>
    }

    let bindingSourceForRotateToggle = null;

    if (propertiesPanelUIData.hasRotateToggle) {

        let allowedVariablesForRotateToggle = VariableTypeAllowedValues.updateInputSourceForGenericToggle(nodeUserData, spaceVariables);

        bindingSourceForRotateToggle =
            <>
                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'} justifyContent="flex-start">
                        <PanelInputLabel props={{ label: "Rotation Angles" }} />
                        <RichHelpBadge title="Rotation Angles">
                            <Box padding="8px">
                                <ul>
                                    <li>
                                        <Box>{`Set the possible positions for this as angles separated by commas. E.g. 90,45,0 or -90,-30,0`}
                                        </Box>
                                    </li>
                                    <li>
                                        <Box>{`Use '-' for the opposite direction, e.g. 0,-30,-90`}
                                        </Box>
                                    </li>
                                    <li>
                                        <Box>{`You can also control these by linking a variable to this object below!`}
                                        </Box>
                                    </li>
                                </ul>
                            </Box>
                        </RichHelpBadge>
                    </Box>
                </Grid >
                <Grid item xs={7}>
                    <div><PanelInputField props={{
                        label: "Rotation Angles", type: "textInput",
                        text: nodeUserData[UserDataProperties.rotationRange],
                        onChange: onTextLabelChanged(UserDataProperties.rotationRange)
                    }} />
                    </div>
                </Grid>

                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'} justifyContent="flex-start">
                        <PanelInputLabel props={{ label: "Rotation Speed" }} />
                        <HelpBadge tooltip="Change rotation speed. Set 0.5 to halve, or 2 to double the default" />

                    </Box>
                </Grid >
                <Grid item xs={7}>
                    <div><PanelInputField props={{
                        label: "Rotation Speed",
                        type: 'textInput',
                        inputType: "number",
                        maxDecimalPlaces: 8,
                        text: nodeUserData[UserDataProperties.rotationSpeed],
                        onChange: onTextLabelChanged(UserDataProperties.rotationSpeed)
                    }} />
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'}>
                        <PanelInputLabel props={{ label: "Variable" }} />
                        <RichHelpBadge
                            // type="popper"
                            title="Variable"
                            content={[
                                { text: 'Link this to a Variable to change rotation dynamically' },
                                {
                                    text: `If your rotation angles are 0,15,30 create a Variable with values such as: Closed, Partially Open, Open`,
                                    imgSrc: "/assets/images/help/DialVarsHelp.png"
                                },
                                {
                                    text: "Then, in Experiences (a.k.a. Lessons), set the Variable with Interactions or ask the user to choose",
                                    imgSrc: "/assets/images/help/InteractionsHelp.png"
                                }
                            ]}
                        >
                        </RichHelpBadge>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <PanelInputField props={{
                        type: "select", values: allowedVariablesForRotateToggle, value: nodeUserData[UserDataProperties.inputSource1],
                        onChange: (e: any) => {
                            //console.log(e)
                            handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                        }
                    }} />
                </Grid>

                {<>
                    <Grid item xs={5}>
                        <Box display="flex" justifyContent="flex-start" alignItems={"center"}>
                            <PanelInputLabel props={{ label: "Model URL" }} />
                            <RichHelpBadge
                                title=''
                                content={[{
                                    text: "Try replacing this object with a different 3D model URL!"
                                },
                                {
                                    text: "It will just swap the model and keep the same behavior!"
                                },
                                {
                                    text: "You may have to refresh the page!"
                                }
                                ]}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={7}>
                        <PanelInputField props={{
                            // label: "Model URL2:",
                            type: "textInput",
                            text: nodeUserData[UserDataProperties.newModelURL],
                            onChange: onTextLabelChanged(UserDataProperties.newModelURL)
                        }} />
                    </Grid>
                </>}
                {getModelConfigFields()}
            </>
    }

    let bindingSourceForToggleComponent = null;

    if (propertiesPanelUIData.hasToggleComponent) {

        let allowedSystemVariablesAsBindingSourceForToggleComponent = VariableTypeAllowedValues.updateInputSourceForGenericToggle(nodeUserData, spaceVariables);

        bindingSourceForToggleComponent =
            // <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'space-evenly'} }>
            //              <PanelInputLabel props={{ label: "Variable" }} />
            //              <PanelInputField props={{
            //                 // label: "Bind to",
            //                 type: "select", values: allowedSystemVariablesAsBindingSourceForToggleComponent, value: nodeUserData[UserDataProperties.inputSource1],
            //                 onChange: (e: any) => {
            //                     //console.log(e)
            //                     handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
            //                 }
            //             }} />
            // </div>

            <Grid container alignItems="center" alignContent="center">
                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'}>
                        <PanelInputLabel props={{ label: "Variable" }} />
                        <RichHelpBadge
                            // type="popper"
                            title="Variable"
                            content={[{
                                text: `Link this to a Variable to change its values dynamically`
                            },
                            { text: `For e.g. create a variable called Power and its 'Allowed values' to 'On,Off'` },
                            {
                                text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose",
                                imgSrc: "/assets/images/help/InteractionsHelp.png"
                            }
                            ]}
                        >
                        </RichHelpBadge>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <PanelInputField props={{
                        // label: "Bind to",
                        type: "select", values: allowedSystemVariablesAsBindingSourceForToggleComponent, value: nodeUserData[UserDataProperties.inputSource1],
                        onChange: (e: any) => {
                            //console.log(e)
                            handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                        }
                    }} />
                </Grid>
            </Grid>
    }

    let imageUpload = null;
    let imageProperties = null;

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.imageRenderer
        || nodeUserData[UserDataProperties.type] === UserDataTypes.analogGauge
        || nodeUserData[UserDataProperties.type] === UserDataTypes.multimeter) {
        imageUpload =
            <>
                <Grid container alignItems="center">
                    <Grid container alignItems="center">

                        {/* // Image Upload  */}
                        <Grid item xs={5}>
                            <PanelInputLabel props={{ label: "Image" }} />
                        </Grid>
                        <Grid item xs={7}>
                            <PanelInputField props={{
                                label: "Image Upload", type: "fileInput",
                                value: nodeUserData[UserDataProperties.textureSource],
                                onChange: onTextLabelChanged(UserDataProperties.textureSource)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
    }
    if (nodeUserData[UserDataProperties.type] === UserDataTypes.imageRenderer) {
        imageProperties =
            <>
                <Grid container alignItems="center">
                    <Grid container alignItems="center">
                        <Grid item xs={5}>
                            <PanelInputLabel props={{ label: "360 Image?" }} />
                        </Grid>
                        <Grid item xs={4}>
                            <PanelInputField props={{
                                value: nodeUserData[UserDataProperties.is360], type: "checkbox",
                                onChange: onCheckboxPropertiesChange(UserDataProperties.is360)
                            }} />
                        </Grid>
                        <Grid item xs={3}>
                            <PanelInputField props={{
                                tooltip: "Show in AR?",
                                value: nodeUserData[UserDataProperties.showInAR], type: "checkbox",
                                onChange: onCheckboxPropertiesChange(UserDataProperties.showInAR)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
            </>
    }
    if ((nodeUserData[UserDataProperties.type] === UserDataTypes.textPanel) || (nodeUserData[UserDataProperties.type] === UserDataTypes.arrowFlow)) {
        let varsForTextBox = VariableTypeAllowedValues.updateInputSourceForNestNode(nodeUserData, spaceVariables);
        inputSourceForTextBox =
            // <Grid container alignItems="center">
            <>
                <Grid item xs={5}>
                    <Box display='inline-flex' alignItems={'center'}>
                        <PanelInputLabel props={{ label: "Variable" }} />
                        {nodeUserData[UserDataProperties.type] === UserDataTypes.textPanel && <RichHelpBadge
                            // type="popper"
                            title="Variable"
                            content={[{
                                text: `Link this to a Variable to change the text dynamically`
                            },
                            { text: 'A variable needs a name, and Allowed values - which are comma-separated texts' },
                            { text: `For e.g. create a variable called 'Feedback' and its 'Allowed values' to 'That is correct!, That is wrong, please try again!'` },
                            {
                                text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose",
                                imgSrc: "/assets/images/help/InteractionsHelp.png"
                            }
                            ]}
                        >
                        </RichHelpBadge>}
                        {nodeUserData[UserDataProperties.type] === UserDataTypes.arrowFlow && <RichHelpBadge
                            // type="popper"
                            title="Variable"
                            content={[{
                                text: `Link this to a Variable to show or hide this flow dynamically`
                            },
                            { text: 'A variable needs a name, and Allowed values - which are comma-separated e.g."On,Off"' },
                            {
                                text: "You can set variables dynamically in Experiences (a.k.a. Lessons) with Interactions, or ask the user to choose",
                                imgSrc: "/assets/images/help/InteractionsHelp.png"
                            }
                            ]}
                        >
                        </RichHelpBadge>}
                    </Box>
                </Grid>
                {/* <Grid container alignItems="center"> */}
                <Grid item xs={7}>

                    {/* <Tooltip title='Variable that sets the text'
                    > */}
                    <div>
                        <PanelInputField props={{
                            // allowedSystemVariablesAsInputSourceForNest has all vars
                            type: "select", values: varsForTextBox, value: nodeUserData[UserDataProperties.inputSource1],
                            onChange: (e: any) => {
                                //console.log(e)
                                handleDropDownChange(PropertiesPanelDropDownType.InputSource1_DropDown, e, 0);
                            }
                        }} />
                    </div>
                    {/* </Tooltip> */}
                    {/* </Grid> */}
                </Grid>
            </>
    }

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.arrowFlow) {

        arrowFlowMarkers = <>
            <Grid item xs={5}>
                {
                    showColorProperty &&
                    <PanelInputLabel props={{ label: "Markers" }} />
                }
            </Grid>
            <Grid item xs={7}>
                {flowMarkers(nodeUserData.id, nodeUserData[UserDataProperties.markers])}

                {/* <List> */}
                {/* <Box style={{ padding: '4px 4px' }}>
                    <ItemHover><p style={{
                        padding: '2px 4px', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'
                    }}
                        onClick={() => Simulation.instance.handleModelClick(nodeUserData.id)}>
                        {nodeUserData.nameToShow}
                    </p></ItemHover>
                    {nodeUserData.markers?.map((marker: any, i: number) => <ItemHover><p style={{
                        padding: '2px 4px', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'
                    }}
                        onClick={() => Simulation.instance.handleModelClick(marker.id)}>
                        {store.getState().home.spaceModels.get(marker.id)?.nameToShow}
                    </p></ItemHover>)}
                </Box> */}
            </Grid>

            <Grid item xs={12}>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Button size="small" variant="contained" color='secondary' onClick={() => {
                        Simulation.instance.srcObjectIdForInternalObjects = nodeUserData["id"] as string;
                        Simulation.instance.addObject(ArrowFlowMarker.payload.objects[0].name, SimulationMode.ADD_INTERNAL_OBJECT);
                    }}>
                        Add Next Marker
                    </Button>
                </Box>
            </Grid>
            <>
                <Grid item xs={5}>
                    <PanelInputLabel props={{ label: "Radius" }} />
                </Grid>
                <Grid item xs={7}>
                    <PanelInputField props={{
                        label: "Radius",
                        type: "textInput",
                        inputType: 'number',
                        text: nodeUserData[UserDataProperties.flowRadius],
                        onChange: onTextLabelChanged(UserDataProperties.flowRadius)
                    }} />
                </Grid>
            </>
        </>
    }

    function flowMarkers(srcNodeId: string, markers: string[]) {
        return <Box style={{ padding: '4px 4px' }}>

            <ItemHover><p style={{
                padding: '2px 4px', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'
            }}
                onClick={() => Simulation.instance.handleModelClick(srcNodeId)}>
                {store.getState().home.spaceModels.get(srcNodeId)?.nameToShow}
            </p></ItemHover>
            <Container onDrop={(e: any) => {
                console.log('dropped', e);
                let newMarkers = applyDrag(markers, e);
                console.log('newMarkerIds', newMarkers);
                let n = store.getState().home.spaceModels.get(srcNodeId)?.nodeRef;
                if (n) {
                    n.userData[UserDataProperties.markers] = newMarkers;
                    NodeStorage.storeNode(n);
                }
            }}
            >
                {markers?.map((marker: any, i: number) =>
                    <Draggable key={marker.id}>
                        <ItemHover>

                            <DragHandle />
                            <div style={{
                                padding: '2px 4px', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'pointer'
                            }}
                                onClick={() => Simulation.instance.handleModelClick(marker.id)}
                            >
                                {store.getState().home.spaceModels.get(marker.id)?.nameToShow}
                            </div>
                        </ItemHover>
                    </Draggable>)}
            </Container>
        </Box>
    }

    if (nodeUserData[UserDataProperties.type] === UserDataTypes.internalObject) {
        // console.log('nodeUserData', nodeUserData);
        if (nodeUserData.srcObjectId) {
            let srcNode = store.getState().home.spaceModels.get(nodeUserData.srcObjectId)?.nodeRef;
            // console.log('srcObject', srcObject);

            if (srcNode) {
                let srcNodeUserData = srcNode.userData;
                // console.log('srcObjectUserData', srcObjectUserData);
                if (srcNodeUserData && srcNodeUserData[UserDataProperties.type] === UserDataTypes.arrowFlow) {
                    let markers = srcNodeUserData.markers;
                    // console.log('markers', markers);
                    arrowFlowMarkers = <>
                        <Grid item xs={5}>
                            {
                                showColorProperty &&
                                <PanelInputLabel props={{ label: "Markers" }} />
                            }
                        </Grid>
                        <Grid item xs={7}>
                            {flowMarkers(srcNodeUserData.id, markers)}
                        </Grid>

                        <Grid item xs={12}>
                            <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Button size="small" variant="contained" color='secondary' onClick={() => {
                                    Simulation.instance.srcObjectIdForInternalObjects = nodeUserData.srcObjectId;
                                    Simulation.instance.addObject(ArrowFlowMarker.payload.objects[0].name, SimulationMode.ADD_INTERNAL_OBJECT);
                                }}>
                                    Add Next Marker
                                </Button>
                            </Box>
                        </Grid>
                        <>
                            <Grid item xs={5}>
                                <PanelInputLabel props={{ label: "Radius" }} />
                            </Grid>
                            <Grid item xs={7}>
                                <PanelInputField props={{
                                    label: "Radius",
                                    type: "textInput",
                                    inputType: 'number',
                                    text: srcNodeUserData[UserDataProperties.flowRadius],
                                    onChange: onTextLabelChanged(UserDataProperties.flowRadius, '', srcNode)
                                }} />
                            </Grid>
                        </>
                    </>
                }
            }
        }
    }

    let onClickActionButtonElement = null;
    let clickAnObjectTextPromptElement = null;
    let allActionTypes = Object.values(ActionType);

    if (propertiesPanelMode === PropertiesPanelMode.CanAdd) {
        onClickActionButtonElement =
            <Button variant='contained'
                color='secondary'
                size='small'
                style={{ height: '90%' }}
                type='button'
                onClick={() => Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.Adding)} >Add OnClick Action</Button>
    } else if (propertiesPanelMode === PropertiesPanelMode.Adding || propertiesPanelMode === PropertiesPanelMode.Editing) {
        onClickActionButtonElement =
            <Button variant='contained'
                color='primary'
                size='small'
                style={{ height: '90%' }}
                type='button'
                onClick={() => Simulation.instance.propertiesPanel.changePropertiesPanelMode(PropertiesPanelMode.CanAdd)} >Cancel</Button>

        clickAnObjectTextPromptElement =
            <Typography variant="body1" className={classes.behaviorPrompt}> Click an object in the scene!</Typography>

    }

    let eventActionElements: JSX.Element[] = [];

    if (UserDataProperties.ClickEventActionList in nodeUserData) {
        if (nodeUserData[UserDataProperties.ClickEventActionList]) {
            (nodeUserData[UserDataProperties.ClickEventActionList] as EventActionOutcome[]).map((actionOutcome: EventActionOutcome, index) => {

                let otherNode: any | null = null;
                let otherNameToShow = "<Node deleted from scene>";
                let hasParameter: boolean = false;
                let parameterString = "N"
                if (actionOutcome.objectID.length < 1) {
                    otherNameToShow = "[Select an object!]";
                } else {
                    if (spaceModels.size > 0) {
                        otherNode = spaceModels.get(actionOutcome.objectID);
                    }
                }

                if (otherNode) {
                    otherNameToShow = otherNode[UserDataProperties.userData][UserDataProperties.nameToShow];
                }

                /*
                if(Simulation.instance.propertiesPanel.isActionParameterized(actionOutcome.actionType)) {
                    hasParameter = true;
                    parameterString = actionOutcome.parameter;
                }*/

                let tempElement =
                    <Grid container alignItems="center">
                        <Grid item xs={5}>
                            <PanelInputField props={{
                                label: "Action", type: "select", values: allActionTypes, value: actionOutcome.actionType,
                                onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.ActionTypeDropDown, e, index); }
                            }} />
                        </Grid>

                        <Grid item xs={5}>
                            <PanelInputField props={{ label: "Action", type: "readOnlyText", text: otherNameToShow, onFocus: (e: any) => { Simulation.instance.propertiesPanel.editNodeClickActionEvent(index); } }} />
                        </Grid>

                        {
                            hasParameter &&
                            <Grid item xs={2}>
                                <PanelInputField props={{ label: "Parameter", type: "textInput", text: parameterString, onChange: onTextLabelChangedEventAction(actionOutcome, index) }} />
                            </Grid>
                        }

                        {
                            (!(propertiesPanelMode === PropertiesPanelMode.Adding || propertiesPanelMode === PropertiesPanelMode.Editing)) &&
                            <Grid item xs={2}>
                                <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                                <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.ActionTypeDropDown, e, index) }}>
                                    <CancelIcon />
                                </Button>
                            </Grid>
                        }
                    </Grid>

                eventActionElements.push(tempElement);

            })
        }
    }

    let onClickTriggerActionButtonElement = null;

    /*
    if(!(UserDataProperties.TriggerActionList in nodeUserData)) {
                nodeUserData[UserDataProperties.TriggerActionList] = [];
    }*/

    onClickTriggerActionButtonElement =
        <Button variant='contained'
            color='secondary'
            size='small'
            style={{ height: '90%' }}
            type='button'
            onClick={() => Simulation.instance.propertiesPanel.addTriggerActionOutcome()} >Add Trigger Action</Button>

    let triggerActionElements: JSX.Element[] = [];

    if (UserDataProperties.TriggerActionList in nodeUserData) {

        if (nodeUserData[UserDataProperties.TriggerActionList]) {
            (nodeUserData[UserDataProperties.TriggerActionList] as TriggerActionOutcome[]).map((triggerActionOutcome: TriggerActionOutcome, index) => {

                let hasParameter: boolean = false;
                let parameterString = "N";

                /*
                if(Simulation.instance.propertiesPanel.isActionParameterized(triggerActionOutcome.actionType)) {
                    hasParameter = true;
                    parameterString = triggerActionOutcome.parameter;
                }*/

                let tempElement =
                    <Grid container alignItems="center">
                        <Grid item xs={5}>
                            <PanelInputField props={{
                                label: "Action", type: "select", values: allActionTypes, value: triggerActionOutcome.actionType,
                                onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.TriggerActionTypeDropDown, e, index); }
                            }} />
                        </Grid>

                        {
                            hasParameter &&
                            <Grid item xs={5}>
                                <PanelInputField props={{ label: "Parameter", type: "textInput", text: parameterString, onChange: onTextLabelChangedTriggerAction(triggerActionOutcome, index) }} />
                            </Grid>
                        }

                        <Grid item xs={2}>
                            <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
                            <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.TriggerActionTypeDropDown, e, index) }}>
                                <CancelIcon />
                            </Button>
                        </Grid>
                    </Grid>

                triggerActionElements.push(tempElement);

            })
        }
    }

    // let varTriggerButton = <Button
    //     variant='contained'
    //     color='secondary'
    //     size='small'
    //     style={{ height: '90%' }}
    //     type='button'
    //     onClick={() => {
    //         let defaultVarName = '';
    //         let defaultValue = '';

    //         if (variableValues.length > 0) {
    //             defaultVarName = variableValues[0].name;
    //             let allowedValues = Simulation.instance.getVariableDefaultValues(defaultVarName);
    //             if (allowedValues.length > 0) {
    //                 defaultValue = allowedValues[0];
    //             }
    //         }

    //         if (defaultVarName.length > 0
    //             // && defaultValue.length
    //         ) {
    //             varTriggerList.push({ logic: VariableLogicType.and, name: defaultVarName, value: defaultValue });
    //             Simulation.instance.propertiesPanel.updateVarList(varTriggerList, UserDataProperties.varTriggers);
    //         }
    //     }}
    // >
    //     Variable Triggers
    // </Button>
    // let eventVarTriggerElementsVars: JSX.Element[] = [];
    // let vTriggers: any[] = [];

    // if (nodeUserData[UserDataProperties.varTriggers]) {
    //     (nodeUserData[UserDataProperties.varTriggers] as VariableValueTriggerPair[]).map((varTrigger, index) => {
    //         let variableValuesMapOutput = variableValues.map((v: any) => v.name);
    //         vTriggers.push({ id: varTrigger.name + varTrigger.value + index, variable: varTrigger.name, value: varTrigger.value });

    //         if (!variableValuesMapOutput) {
    //             variableValuesMapOutput = [];
    //         }

    //         let varValuesFind = variableValues.find(v => v.name === varTrigger.name);

    //         if (varValuesFind) {
    //             varValuesFind = varValuesFind.values?.split(",").map((v: any) => v.trim());
    //         } else {
    //             varValuesFind = ''
    //         }

    //         //let varLogicFind = variableValues.find(v => v.name === varTrigger.name);
    //         //console.log(varTrigger.logic);

    //         if (!varTrigger.logic) {
    //             varTrigger.logic = VariableLogicType.and;
    //         } else {
    //             if (varTrigger.logic as VariableLogicType === VariableLogicType.blank) {
    //                 varTrigger.logic = VariableLogicType.blank;
    //             }
    //         }

    //         var variableLogicTypesArray = Object.values(VariableLogicType);//Utils.enumToDescriptedArray(VariableLogicType).map((vlt:any) => vlt.id);
    //         variableLogicTypesArray.shift();
    //         //if(varTrigger.)

    //         let tempElement =
    //             <Grid container alignItems="center">
    //                 <Grid item xs={5}>
    //                     <PanelInputField props={{
    //                         label: "Variable", type: "select", values: variableValuesMapOutput, value: varTrigger.name,
    //                         onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index); }
    //                     }} />
    //                 </Grid>
    //                 <Grid item xs={5}>
    //                     <PanelInputField props={{
    //                         label: "Value", type: "select", values: varValuesFind, value: varTrigger.value,
    //                         onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index, 2); }
    //                     }} />
    //                 </Grid>

    //                 {/*}
    //                 <Grid item xs={2}>
    //                     <PanelInputField props={{
    //                         label: "Logic", type: "select", values: variableLogicTypesArray, value: varTrigger.logic,
    //                         onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index, 3); }
    //                     }} />
    //                 </Grid>*/}
    //                 <Grid item xs={1}>
    //                     <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
    //                     <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.SystemVariablesTriggerDropDown, e, index) }}>
    //                         <CancelIcon />
    //                     </Button>
    //                 </Grid>
    //             </Grid>

    //         eventVarTriggerElementsVars.push(tempElement);

    //     })
    // }
    //var actions
    // let varActionButton = <Button
    //     variant='contained'
    //     color='secondary'
    //     size='small'
    //     style={{ height: '90%' }}
    //     type='button'
    //     onClick={() => {
    //         let defaultVarName = '';
    //         let defaultValue = '';

    //         if (variableValues.length > 0) {
    //             defaultVarName = variableValues[0].name;
    //             let allowedValues = Simulation.instance.getVariableDefaultValues(defaultVarName);
    //             if (allowedValues.length > 0) {
    //                 defaultValue = allowedValues[0];
    //             }
    //         }

    //         if (defaultVarName.length > 0 && defaultValue.length) {
    //             varActionList.push({ logic: VariableLogicType.and, name: defaultVarName, value: defaultValue });
    //             Simulation.instance.propertiesPanel.updateVarList(varActionList, UserDataProperties.varActions);
    //         }
    //     }}
    // >
    //     Variable Actions
    // </Button>
    // let eventVarActionElementsVars: JSX.Element[] = [];

    // if (nodeUserData[UserDataProperties.varActions]) {
    //     (nodeUserData[UserDataProperties.varActions] as VariableValueActionPair[]).map((varAction, index) => {
    //         let variableValuesMapOutput = variableValues.map(v => v.name);

    //         if (!variableValuesMapOutput) {
    //             variableValuesMapOutput = [];
    //         }

    //         let varValuesFind = variableValues.find(v => v.name === varAction.name);

    //         if (varValuesFind) {
    //             varValuesFind = varValuesFind.values?.split(",").map((v: any) => v.trim());
    //         } else {
    //             varValuesFind = '';
    //         }

    //         let tempElement =
    //             <Grid container alignItems="center">
    //                 <Grid item xs={5}>
    //                     <PanelInputField props={{
    //                         label: "Variable", type: "select", values: variableValuesMapOutput, value: varAction.name,
    //                         onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index); }
    //                     }} />
    //                 </Grid>
    //                 <Grid item xs={5}>
    //                     <PanelInputField props={{
    //                         label: "Value", type: "select", values: varValuesFind, value: varAction.value,
    //                         onChange: (e: any) => { handleDropDownChange(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index, 2); }
    //                     }} />
    //                 </Grid>
    //                 <Grid item xs={2}>
    //                     <Typography variant="body1" className={classes.behaviorSubTitle}> </Typography>
    //                     <Button variant='text' color='inherit' size='small' onClick={(e) => { handleDeleteClick(PropertiesPanelDropDownType.SystemVariablesActionDropDown, e, index) }}>
    //                         <CancelIcon />
    //                     </Button>
    //                 </Grid>
    //             </Grid>

    //         eventVarActionElementsVars.push(tempElement);

    //     })
    // }

    // Helper function to get the path of an object in the object hierarchy
    function getObjectPath(object: THREE.Object3D) {
        let path = object.name;
        while (object.parent) {
            object = object.parent;
            path = object.name + '/' + path;
        }
        return path;
    }

    // const object = gltf.scene.getObjectByName(objectName);
    //         // Check if the object is the correct one by comparing its path in the object hierarchy
    //         if (object && getObjectPath(object) === objectPath) {
    //             // Attach the interaction to the object
    //             // ...
    //         }

    function generateTreeItems(object: any) {
        return (
            <TreeItem
                key={object.uuid}
                nodeId={object.uuid}
                label={object.name || 'Unnamed'}
                onClick={() => {
                    object?.isMesh && Simulation.instance.startOutlineGlow(object);
                }}
            >

                {/* Check if node is a Mesh */}
                {object?.isMesh && (
                    <>
                        {/* <TreeItem nodeId={object.uuid + '_geometry'} label={`Geometry: ${object.geometry.type}`}
                            onClick={() => {
                                Simulation.instance.startOutlineGlow(object);
                            }}
                        /> */}
                        {/* <TreeItem
                            nodeId={object.uuid + '_material'}
                            label={`Material: ${object.material.type}`}
                        >
                            {object.material.map && (
                                <TreeItem nodeId={object.uuid + '_textureMap'} label="Texture Map" />
                            )}
                        </TreeItem> */}
                    </>
                )}

                {/* Recursively display children */}
                {object.children?.map((child: any) => generateTreeItems(child))}
            </TreeItem>
        );
    }

    let showSizeOption: boolean = false;
    if (UserDataGizmoMinorMods.disableScale in nodeUserData) {
        if (nodeUserData[UserDataGizmoMinorMods.disableScale]) {
            showSizeOption = true;
        }
    }

    let node = store.getState().home.spaceModels.get(nodeUserData.id)?.nodeRef;
    let uploadedModelCollider = (node && catalogMap.get(node.name) && node?.components) ? node?.components[0]?.outputs?.collider : null;
    //setTimeout(_setPropertiesColorValueInternal, 1000);

    // const [openLogicTreesDrawer, setOpenLogicTreesDrawer] = useState<boolean>(false);

    return (console.log(`%c[st] rendering PropertiesPanel"`, 'color: orange;'),

        <Box className={classes.propPanelBox}>
            {
                (propertiesPanelMode !== PropertiesPanelMode.None) &&
                <Grid
                // spacing={1}
                >

                    <Typography className={'h7'} >Object Properties</Typography>
                    {/* {Simulation.instance.lastSelectedNode && <ExpandedModel node={Simulation.instance.lastSelectedNode} />} */}

                    <Divider orientation="horizontal" variant="middle" />
                    <Box paddingTop={2} />
                    <Grid container alignItems="center" spacing={0}>
                        <Grid item xs={5}>
                            <PanelInputLabel props={{ label: "Name" }} />
                        </Grid>

                        <Grid item xs={7}>
                            <PanelInputField props={{
                                label: "Name", text: nodeUserData[UserDataProperties.nameToShow],
                                required: true,
                                type: "textInput",
                                onChange: onTextLabelChanged(UserDataProperties.nameToShow)
                            }} />
                        </Grid>

                        {showColorProperty && <Grid item xs={5}>
                            {
                                showColorProperty &&
                                <PanelInputLabel props={{ label: "Color" }} />
                            }
                        </Grid>}

                        {showColorProperty && <Grid item xs={7}>
                            <PanelInputField props={{
                                label: "Color", type: "color",
                                color: nodeUserData[UserDataProperties.customColorProperty],
                                colorReference: colorReference, onColorChanged: onColorChanged,
                                showColorProperty: showColorProperty
                            }} />
                        </Grid>}

                        {showBorderColorProperty && <Grid item xs={5}>
                            {
                                <PanelInputLabel props={{ label: borderColorLabelText }} />
                            }
                        </Grid>}

                        {showBorderColorProperty && <Grid item xs={7}>
                            <PanelInputField props={{
                                label: "Border Color", type: "color",
                                color: nodeUserData[UserDataProperties.borderColorProperty],
                                colorReference: borderColorReference,
                                onColorChanged: onBorderColorChanged,
                                showColorProperty: showBorderColorProperty
                            }} />
                        </Grid>}

                        {imageUpload}
                        {imageProperties}

                        {store.getState().layer.currentLesson && <>
                            <Grid item xs={5}>

                                <Box display='inline-flex' alignItems={'center'}>
                                    <PanelInputLabel props={{ label: "Always Show" }} />
                                    <HelpBadge
                                        tooltip={`Applies to ${spacePrimaryUseCase?.layerTermPlural}. Checking this will make this object appear in ALL the steps`}
                                    />

                                </Box>
                            </Grid>

                            <Grid item xs={7}>

                                <div>
                                    <PanelInputField props={{
                                        // label: "Always Show",
                                        value: nodeUserData[UserDataProperties.alwaysShow], type: "checkbox",
                                        onChange: onCheckboxPropertiesChange(UserDataProperties.alwaysShow)
                                    }} />
                                </div>

                            </Grid>
                        </>}
                        {/* <Grid item xs={3}>

                            <PanelInputLabel props={{ label: "Always Show" }} />

                        </Grid> */}
                        {/* <Grid item xs={9}>
                            <Button variant='outlined' onClick={() => {
                                // console.log(`[st] ${stringify(propertiesPanelUIData.nodePointer?.obj3D?.children[0])}`);
                                // propertiesPanelUIData.nodePointer?.obj3D?.add(new THREE.AmbientLight(0xffffFF, 3.0));
                                propertiesPanelUIData.nodePointer?.obj3D?.add(new THREE.SpotLight(0xffffFF, 3.0));
                                // Simulation.instance.lastSelectedNode?
                                // (this.context as any).scene.add( new THREE.AmbientLight(0xffffFF, 3.0));
                            }}>Brighten</Button>
                        </Grid> */}

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={5}>
                                <PanelInputLabel props={{ label: "Font Size" }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={7}>
                                <PanelInputField props={{ label: "Font Size", type: "textInput", text: nodeUserData[UserDataProperties.fontSize], onChange: onTextLabelChanged(UserDataProperties.fontSize) }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={5}>
                                <PanelInputLabel props={{ label: "Text" }} />
                            </Grid>
                        }

                        {
                            (UserDataProperties.textProperty in nodeUserData) &&
                            <Grid item xs={7}>
                                <PanelInputField props={{ label: "Text", type: "textInputMulti", text: nodeUserData[UserDataProperties.textProperty], onChange: onTextLabelChanged(UserDataProperties.textProperty) }} />
                            </Grid>
                        }
                        {/* <Grid item xs={6}>
                            <Divider />
                        </Grid> */}

                        {/* <Grid item xs={12}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='small'
                                style={{ height: '90%' }}
                                type='button'
                                onClick={() => setOpenLogicTreesDrawer(true)}
                            >
                                If-then blocks
                            </Button>
                            <LogicTrees
                                nodeUserData={nodeUserData}
                                openLogicTreesDrawer={openLogicTreesDrawer}
                                onCloseDrawer={() => setOpenLogicTreesDrawer(false)}
                                />
                        </Grid> */}

                        {/*
                        <Grid container alignItems="center">
                            {onClickActionButtonElement}
                            {clickAnObjectTextPromptElement}
                        </Grid>
                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventActionElements}

                            </div>
                        </Grid>


                        <Grid container alignItems="center">
                            {onClickTriggerActionButtonElement}
                        </Grid>

                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {triggerActionElements}

                            </div>
                        </Grid>

                        <Grid container alignItems="center">
                            {varTriggerButton}
                        </Grid>
                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventVarTriggerElementsVars}

                            </div>
                        </Grid>

                        <Grid container alignItems="center">
                            {varActionButton}
                        </Grid>


                        <Grid item xs={12}>

                            <div style={{ backgroundColor: "#e8e6e6" }}>

                                {eventVarActionElementsVars}

                            </div>
                        </Grid>*/}

                        <PositionRotationScale showSizeOption={showSizeOption} />

                        {showSizeOption && <>
                            <Grid container alignItems="center">
                                <Grid item xs={5}>
                                    <PanelInputLabel props={{ label: "Size" }} />
                                </Grid>

                                <Grid item xs={7}>
                                    <PanelInputField props={{ label: "Size", text: propertiesPanelUIData.nodePointer?.scale.x, type: "textInput", onChange: onTextLabelNodeDataChanged(NodeDataProperties.scale) }} />
                                </Grid>
                            </Grid>
                        </>}

                        {<>
                            <Grid container alignItems="center">
                                <Grid item xs={5}>
                                    <PanelInputLabel props={{ label: "Custom Properties" }} />
                                </Grid>

                                <Grid item xs={7}>
                                    <PanelInputField props={{
                                        label: "Custom Properties",
                                        type: "textInputMulti",
                                        // text: JSON.stringify(nodeUserData[UserDataProperties.customProps]) || {}, //saved as object
                                        text: nodeUserData[UserDataProperties.customProps],
                                        onChange: onTextLabelChanged(UserDataProperties.customProps)
                                    }} />

                                </Grid>
                            </Grid>
                        </>}

                        {inputSourceForNest}
                        {inputSourceForTextBox}
                        {bindingSourceForRotateToggle}
                        {bindingSourceForToggleComponent}
                        {arrowFlowMarkers}
                        {analogGaugeFields}
                        {wireFields}

                        <Grid item xs={5}>

                            {/* property previously called lock */}
                            <Box display='inline-flex' alignItems={'center'}>
                                <PanelInputLabel props={{ label: "Merge" }} />
                                <HelpBadge tooltip={'Merge with the scene (i.e. clicking the object won`t select it). You will have to select it from the Inserted Objects list.'}
                                />

                            </Box>
                        </Grid>

                        <Grid item xs={7}>
                            <div>
                                <PanelInputField props={{
                                    // label: "Lock this object (i.e. clicking on it in the scene won't select it. Helpful if you have )",
                                    value: nodeUserData[UserDataProperties.lock], type: "checkbox",
                                    onChange: onCheckboxPropertiesChange(UserDataProperties.lock)
                                }} />
                            </div>

                        </Grid>


                    </Grid>

                    {uploadedModelCollider && <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                        {generateTreeItems(uploadedModelCollider)}
                    </TreeView>}
                    {/* <Divider /> */}


                </Grid>
            }
        </Box >
    );
};

export default PropertiesPanel;

export function PositionRotationScale({ showSizeOption }: { showSizeOption: boolean }) {
    // const selectedNode = useSelector<AppState, NodeProperties | undefined>(({ threeD }) => threeD.selectedNode);
    const selectedNodeId = useSelector<AppState, string>(({ threeD }) => threeD.selectedNodeId);
    const selectedNode = store.getState().home.spaceModels.get(selectedNodeId)?.nodeRef;


    return !selectedNode ? <></> : (console.log(`%c[st] rendering PosRotScale `, selectedNode?.position), <>
        <Grid item xs={3}>

                <PanelInputLabel props={{ label: "Position" }} />

        </Grid>
        <Grid item xs={9}>

            <Box display='flex'>
                {/* <Grid item xs={5}> */}

                <PanelInputField props={{
                    label: "Position X",
                    text: tryCatch(() => {
                        // console.log('position is' + selectedNode?.position?.x);
                        return Utils.RemoveTrailingZeros(Number((selectedNode?.position.x || 0).toFixed(8)));
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 3,
                    onChange: (v: any) => {
                        let n = tryCatch(() => {
                            // console.log(v);
                            let f = Number.parseFloat(v);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(n)) {
                            // propertiesPanelUIData.nodePointer && (propertiesPanelUIData.nodePointer.position.setX(n));
                            // propertiesPanelUIData.nodePointer && store.dispatch({ type: SELECT_NODE, payload: propertiesPanelUIData.nodePointer });
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.position.setX(n));
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                    }
                }} />

                {/* </Grid> */}
                {/* </Grid> */}

                {/* <Grid item xs={5}> */}
                <PanelInputField props={{
                    label: "Position Y",
                    text: tryCatch(() => {
                        // console.log(selectedNode?.position?.y);
                        return Utils.RemoveTrailingZeros(Number((selectedNode?.position.y || 0).toFixed(8)));
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 3,
                    onChange: (v: any) => {
                        let n = tryCatch(() => {
                            // console.log(v);
                            let f = Number.parseFloat(v);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(n)) {
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.position.setY(n));
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                    }
                }} />
                {/* </Grid> */}
                {/* <Grid item xs={5}> */}
                <PanelInputField props={{
                    label: "Position Z",
                    text: tryCatch(() => {
                        // console.log(selectedNode?.position?.z);
                        return Utils.RemoveTrailingZeros(Number((selectedNode?.position.z || 0).toFixed(8)));
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 3,
                    onChange: (v: any) => {
                        let n = tryCatch(() => {
                            // console.log(v);
                            let f = Number.parseFloat(v);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(n)) {
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.position.setZ(n));
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                    }
                }} />

            </Box >
        </Grid >
        <Grid item xs={3}>

                <PanelInputLabel props={{ label: "Rotation" }} />

        </Grid>

        <Grid item xs={9}>
            <Box display='flex'>
                <PanelInputField props={{
                    label: "Rotation X",
                    // text: getRotation(selectedNode, 'x'),
                    text: tryCatch(() => {

                        let savedEuler = selectedNode?.userData?.eulerRotation ? (new THREE.Euler()).fromArray(selectedNode?.userData?.eulerRotation) : undefined;
                        console.log('saved eulerRotation', selectedNode?.userData?.eulerRotation?.map((v, i) => i <=2 && v * THREE.MathUtils.RAD2DEG));
                        let r = savedEuler ? savedEuler.x * THREE.MathUtils.RAD2DEG :
                            getEulerAnglesFromQuaternion(selectedNode.quaternion).x * THREE.MathUtils.RAD2DEG;

                        return Utils.RemoveTrailingZeros(Number(r.toFixed(1)));
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 2,
                    onChange: (v: any) => {
                        let humanReadablebleNewXRotation = tryCatch(() => {
                            // console.log(v);
                            let f = (Number.parseFloat(v)).toFixed(1);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(humanReadablebleNewXRotation)) {
                            let eulerRotation: THREE.Euler = getEulerAnglesFromQuaternion(selectedNode.quaternion);
                            eulerRotation.x = humanReadablebleNewXRotation * THREE.MathUtils.DEG2RAD;
                            const quaternion = new THREE.Quaternion();

                            // Then use the method .setFromEuler() to create quaternion from euler
                            quaternion.setFromEuler(eulerRotation);
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.quaternion.copy(quaternion));
                            console.log('saving eulerRotation', eulerRotation.toArray());
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.userData.eulerRotation = eulerRotation.toArray());
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                        // TODO: Restore old values in the properties panel GUI
                    }
                }} />

                <PanelInputField props={{
                    label: "Rotation Y",
                    text: tryCatch(() => {
                        let savedEuler = selectedNode?.userData?.eulerRotation ? (new THREE.Euler()).fromArray(selectedNode?.userData?.eulerRotation) : undefined;

                        let r = savedEuler ? savedEuler.y * THREE.MathUtils.RAD2DEG :
                            getEulerAnglesFromQuaternion(selectedNode.quaternion).y * THREE.MathUtils.RAD2DEG;
                        return Utils.RemoveTrailingZeros(Number(r.toFixed(1)));
                        //return Simulation.instance.applyRotation.rotation.y;
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 2,

                    onChange: (v: any) => {
                        let humanReadablebleNewYRotation = tryCatch(() => {
                            // console.log(v);
                            let f = (Number.parseFloat(v)).toFixed(1);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(humanReadablebleNewYRotation)) {
                            let eulerRotation: THREE.Euler = getEulerAnglesFromQuaternion(selectedNode.quaternion);
                            eulerRotation.y = humanReadablebleNewYRotation * THREE.MathUtils.DEG2RAD;
                            const quaternion = new THREE.Quaternion();

                            // Then use the method .setFromEuler() to create quaternion from euler
                            quaternion.setFromEuler(eulerRotation);
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.quaternion.copy(quaternion));
                            console.log('saving eulerRotation', eulerRotation.toArray());
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.userData.eulerRotation = eulerRotation.toArray());
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                        // TODO: Restore old values in the properties panel GUI
                    }
                }} />

                <PanelInputField props={{
                    label: "Rotation Z",
                    // text: getRotation(selectedNode, 'z'),
                    text: tryCatch(() => {
                        // return Simulation.instance.applyRotation.rotation.z;
                        // let r = getEulerAnglesFromQuaternion(selectedNode.quaternion).z * THREE.MathUtils.RAD2DEG;

                        let savedEuler = selectedNode?.userData?.eulerRotation ? (new THREE.Euler()).fromArray(selectedNode?.userData?.eulerRotation) : undefined;

                        let r = savedEuler ? savedEuler.z * THREE.MathUtils.RAD2DEG :
                            getEulerAnglesFromQuaternion(selectedNode.quaternion).z * THREE.MathUtils.RAD2DEG;
                        return Utils.RemoveTrailingZeros(Number(r.toFixed(1)));
                    }, '3D') || 0,
                    type: "textInput",
                    inputType: 'number',
                    maxDecimals: 2,

                    onChange: (v: any) => {
                        let humanReadablebleNewZRotation = tryCatch(() => {
                            // console.log(v);
                            let f = (Number.parseFloat(v)).toFixed(1);
                            return Number(f);
                        }, '3D')
                        if (!Number.isNaN(humanReadablebleNewZRotation)) {
                            let eulerRotation: THREE.Euler = getEulerAnglesFromQuaternion(selectedNode.quaternion);
                            eulerRotation.z = humanReadablebleNewZRotation * THREE.MathUtils.DEG2RAD;
                            const quaternion = new THREE.Quaternion();

                            // Then use the method .setFromEuler() to create quaternion from euler
                            quaternion.setFromEuler(eulerRotation);
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.quaternion.copy(quaternion));
                            console.log('saving eulerRotation', eulerRotation.toArray());
                            Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.userData.eulerRotation = eulerRotation.toArray());
                            Simulation.instance.propertiesPanel.saveLastNode(true);
                        }
                        // TODO: Restore old values in the properties panel GUI
                    }
                }} />
            </Box>
            <Box>
                <Grid item xs={7}>
                    <PanelInputField props={{
                        label: "Save changes", type: "applyChanges",
                        onChange: (e: any) => {
                            Simulation.instance.applyRotation.PrintRotationValues();
                            Simulation.instance.applyRotation.ApplyToLastSceneNode();
                            Simulation.instance.propertiesPanel.saveLastNode(false);
                        }
                    }} />
                </Grid>
            </Box>
        </Grid>

        {!showSizeOption && <>
            <Grid item xs={3}>

                    <PanelInputLabel props={{ label: "Size" }} />

            </Grid>
            <Grid item xs={9}>
                <Box display='flex'>
                    <PanelInputField props={{
                        label: "Size X",
                        text: tryCatch(() => {
                            // console.log('scale is' + selectedNode?.scale?.x);

                            // let val = Number(selectedNode?.scale?.x || 0);
                            // console.log(val);
                            return Utils.RemoveTrailingZeros(Number((selectedNode?.scale.x || 0).toFixed(8)));
                        }, '3D') || 0,
                        type: "textInput",
                        inputType: 'number',
                        maxDecimals: 3,
                        onChange: (v: any) => {
                            let n = tryCatch(() => {
                                console.log(v);
                                let f = Number.parseFloat(v);
                                return Number(f);
                            }, '3D')
                            if (!Number.isNaN(n)) {
                                Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.scale.setX(n));
                                Simulation.instance.propertiesPanel.saveLastNode(true);
                            }
                        }
                    }} />

                    <PanelInputField props={{
                        label: "Size Y",
                        text: tryCatch(() => {
                            // console.log('scale is' + selectedNode?.scale?.y);
                            return Utils.RemoveTrailingZeros(Number((selectedNode?.scale.y || 0).toFixed(8)));
                        }, '3D') || 0,
                        type: "textInput",
                        inputType: 'number',
                        maxDecimals: 3,
                        onChange: (v: any) => {
                            let n = tryCatch(() => {
                                // console.log(v);
                                let f = Number.parseFloat(v);
                                return Number(f);
                            }, '3D')
                            if (!Number.isNaN(n)) {
                                Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.scale.setY(n));
                                Simulation.instance.propertiesPanel.saveLastNode(true);
                            }
                        }
                    }} />

                    <PanelInputField props={{
                        label: "Size Z",
                        text: tryCatch(() => {
                            // console.log('scale is' + selectedNode?.scale?.z);
                            return Utils.RemoveTrailingZeros(Number((selectedNode?.scale.z || 0).toFixed(8)));
                        }, '3D') || 0,
                        type: "textInput",
                        inputType: 'number',
                        maxDecimals: 3,
                        onChange: (v: any) => {
                            let n = tryCatch(() => {
                                // console.log(v);
                                let f = Number.parseFloat(v);
                                return Number(f);
                            }, '3D')
                            if (!Number.isNaN(n)) {
                                Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.scale.setZ(n));
                                Simulation.instance.propertiesPanel.saveLastNode(true);
                            }
                        }
                    }} />
                </Box></Grid></>}
    </>)
}

// object's rotation => determined by quaternion. Also save readableRotation in userData
// Created: at the time of placement, or Duplicate Node (save readableRotation in userData)
// Read by: PropPanel, Undo/ Redo
// Updated by: Gizmo, PropPanel changes the readableRotation, Undo/ Redo (save readableRotation in userData)
// Deleted by: Gizmo, Model list

function getRotation(node: any, onAxis: 'x' | 'y' | 'z') {
    // return (node.userData && JSON.parse(node.userData?.rotation)[onAxis]) || 0;
    // console.log('rotation is ' + ((node.obj3D as Object3D)?.quaternion));
    // console.log('rotation is ' + JSON.stringify(node.quaternion));

    if (node && node.obj3D && !!(node?.quaternion)) {
        let wq = (node)?.getWorldQuaternion((node)?.quaternion);
        // console.log('rotation is ' + JSON.stringify(wq));

        return (wq && wq['_' + onAxis]) || 0;
        // switch (onAxis) {
        //     case 'x':
        //         return wq?.x || 0;
        //     case 'y':
        //         return (node.obj3D as Object3D)?.rotation.y || 0;
        //     case 'z':
        //         return (node.obj3D as Object3D)?.rotation.z || 0;
        // }
    }
}

// function getReadableAnglesFromQuaternion(quaternion: THREE.Quaternion): THREE.Euler {
//     let euler = new THREE.Euler();

//     // The order 'XYZ' means the rotation is performed as follows:
//     // 1. Yaw around y-axis
//     // 2. Then pitch around x-axis
//     // 3. Finally roll around z-axis
//     euler.setFromQuaternion(quaternion, 'XYZ');

//     return euler;
// }

// function setRotation(node: ISceneNode, onAxis: 'x' | 'y' | 'z', angle: number) {

//     var quaternion = node.quaternion;
//     var eulerAngle: Euler = new Euler(0, 0, 0, 'XYZ');
//     eulerAngle = eulerAngle.setFromQuaternion(quaternion, 'XYZ');

//     // var newXaxis = new Vector3();
//     // var newYaxis = new Vector3();
//     // var newZaxis = new Vector3();
//     // (node.obj3D as THREE.Object3D).updateMatrix();
//     // (node.obj3D as THREE.Object3D).matrix.extractBasis(newXaxis, newYaxis, newZaxis);
//     // var degreesX = Number(MathUtils.radToDeg(eulerAngle.x).toFixed(0));
//     // var degreesY = Number(MathUtils.radToDeg(eulerAngle.y).toFixed(0));
//     // var degreesZ = Number(MathUtils.radToDeg(eulerAngle.z).toFixed(0));
//     console.log("Angle change: " + angle);

//     if (MathUtils.radToDeg(eulerAngle.y) == 89.9) {
//         eulerAngle.y = MathUtils.degToRad(89);
//     }

//     switch (onAxis) {
//         case 'x':
//             eulerAngle.x = MathUtils.degToRad(angle);
//             // var newQuat = new Quaternion().setFromAxisAngle(CardinalAxesAndPlanes.instance.xAxis, MathUtils.degToRad(angle));
//             // quaternion = quaternion.multiply(newQuat);
//             // eulerAngle.x = MathUtils.degToRad(angle);
//             // (node.obj3D as THREE.Object3D).rotateX()
//             // console.log("MathUtils.degToRadian: " + MathUtils.degToRad(angle));
//             // (node.obj3D as THREE.Object3D).rotation.x = MathUtils.degToRad(angle);
//             break;
//         case 'y':
//             eulerAngle.y = MathUtils.degToRad(angle);
//             // eulerAngle.y = MathUtils.degToRad(angle);
//             // (node.obj3D as THREE.Object3D).rotation.y = MathUtils.degToRad(angle);
//             break;
//         case 'z':
//             // console.log(MathUtils.radToDeg(eulerAngle.y));

//             eulerAngle.z = MathUtils.degToRad(angle);
//             // eulerAngle.z = MathUtils.degToRad(angle);
//             // (node.obj3D as THREE.Object3D).rotation.z = MathUtils.degToRad(angle);
//             break;
//     }

//     // eulerAngle.x = MathUtils.degToRad(degreesX);
//     // eulerAngle.y = MathUtils.degToRad(degreesY);
//     // eulerAngle.z = MathUtils.degToRad(degreesZ);

//     // var localEuler =  (node.obj3D as THREE.Object3D).rotation;
//     // localEuler.x = eulerAngle.x;
//     // localEuler.y = eulerAngle.y;
//     // localEuler.z = eulerAngle.z;

//     // (node.obj3D as THREE.Object3D).rotation.set(eulerAngle.x,  eulerAngle.y,  eulerAngle.z,  'XYZ');
//     ISceneNodeExtensions.setRotation(node, quaternion);
//     // console.log("New Euler: " + MathUtils.radToDeg(eulerAngle.x) + "," + MathUtils.radToDeg(eulerAngle.y) + "," + MathUtils.radToDeg(eulerAngle.z));

//     ISceneNodeExtensions.setRotation(node, new Quaternion().setFromEuler(eulerAngle));
//     // if (node) {
//     //     switch (onAxis) {
//     //         case 'x':
//     //             (node)?.rotateOnWorldAxis(new Vector3(1, 0, 0), angle);
//     //             break;
//     //         case 'y':
//     //             (node)?.rotateOnWorldAxis(new Vector3(0, 1, 0), angle);
//     //             break;
//     //         case 'z':
//     //             (node)?.rotateOnWorldAxis(new Vector3(0, 0, 1), angle);
//     //             break;
//     //     }
//     // }
//     // if (node && node.obj3D && !!(node.obj3D as Object3D)) {
//     //     switch (onAxis) {
//     //         case 'x':

//     //             (node.obj3D as Object3D)?.setRotationFromQuaternion(new Vector3(1, 0, 0), angle);
//     //             break;
//     //         case 'y':
//     //             (node.obj3D as Object3D)?.rotateOnWorldAxis(new Vector3(0, 1, 0), angle);
//     //             break;
//     //         case 'z':
//     //             (node.obj3D as Object3D)?.rotateOnWorldAxis(new Vector3(0, 0, 1), angle);
//     //             break;
//     //     }
//     // }
// }
export const DragHandle = () => {
    return <span style={{ float: 'left', padding: '0 10px', cursor: 'grab' }}>&#x2630;</span>
}

export const applyDrag = (arr: any[], dragResult: any) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;

    const result = [...arr];
    let itemToAdd = payload;

    if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
    }
    return result;
};
