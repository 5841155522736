import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ShowcaseTag, ANNOTATION_TYPE } from '../../../../../../types/models/home/HomeApp';
import { TagType, ViewMode } from '..';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setEditShowcaseTagId, setOpenTagForm } from 'redux/actions/Home';
import { addTagsToEmptyShowcase, getMatterportTagsFromDB, handleDeleteTag } from 'modules/home/SpaceDetail/utils';
import { ShowcaseTagMap } from 'redux/reducers/Home';
// import { Tooltip } from 'recharts';
import TagsList from './Tags/TagsList';

// function TabPanel(props: any) {
//   const { children, value, index, ...other } = props;
//   return (
//     <Typography
//       component='div'
//       role='tabpanel'
//       hidden={value !== index}
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}>
//       <Box p={3}>{children}</Box>
//     </Typography>
//   );
// }
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
//   // dir: PropTypes.any.isRequired,
// };
// function a11yProps(index: any) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%',
    // height: '600px',
    // height: '50%',
  },
  tabButton: {
    minWidth: '30px',
    textTransform: 'none'
  },
}));

interface IProps {
  // importMatterportTags(): void;
  // tagsAlreadyImported: boolean;
}
export default function SidebarTabPanel({
  // importMatterportTags,
  // tagsAlreadyImported,
}: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const spaceTags = useSelector<AppState, ShowcaseTagMap>(({ home }) => {
    return home.spaceTags;
  });
  const tagsViewMode = useSelector<AppState, string>(({ home }) => home.tagsViewMode);

  const TAG_TYPES = [TagType.ALL, TagType.INFO, TagType.QUIZ, TagType.TASK, TagType.MEDIA];
  const [value, setValue] = React.useState<TagType>(TagType.ALL);
  const dispatch = useDispatch();
  // let visibleSpaceTags: ShowcaseTag[] = [];
  const [st, setST] = useState<ShowcaseTag[][]>([]);

  React.useEffect(() => {getMatterportTagsFromDB()}, []);
  // let st: ShowcaseTag[][] = [];
  // [0, 1, 2, 3, 4].map(x => {
  //   st[x] = getTagsForTab(x);
  // });

  // useEffect(() => {
  //   let tempST: ShowcaseTag[][] = [];
  //   [0, 1, 2, 3, 4].map(x => {
  //     tempST[x] = getTagsForTab(x);
  //   });
  //   console.log(`setting ST`);
  //   setST(tempST);
  //   // dispatch(setCurrentShowcaseTags(stx));

  // }, [spaceTags,
  //   // value
  //   // tagsViewMode
  // ]);

  // useEffect(() => {
  //   let visibleSpaceTags: ShowcaseTag[] = getTagsForTab(value);
  //   //   st && st.length > value &&
  //   // dispatch(setCurrentShowcaseTags(stx));
  //   // let lt = Object.values(spaceTags).pop();
  //   // console.log('navigating to ' + lt?.data);
  //   // lt && handleTagClick(lt.id);
  //   addTagsToEmptyShowcase({tagIds: visibleSpaceTags.map(t => t.id)});
  //   console.log(`[st] setstate setting setCurrentSpaceTagsList 1`);
  //   // dispatch(setCurrentShowcaseTags(visibleSpaceTags))
  // }, [
  //   spaceTags,
  //   //   value
  //   //   // tagsViewMode
  // ]);

  function getTagsForTab(tagType: TagType, spaceTags: ShowcaseTagMap) {
    // let tagType = tagTypeToName(tabValue);
    let visibleSpaceTags: ShowcaseTag[] = [];
    // console.log(`TabPanel 2 for tabValue ${tabValue} + ${(new Date()).valueOf() / 1000}`);
    if (tagType === TagType.ALL) {
      return Object.values(spaceTags)
    }
    else if (tagType === TagType.INFO) {
      return Object.values(spaceTags).filter(
        (t: ShowcaseTag) => t.annotationType && t.annotationType === ANNOTATION_TYPE.INFO && !t.data.media?.src
      );
    }
    else if (tagType === TagType.QUIZ) {
      return Object.values(spaceTags).filter(
        (t) => t.annotationType && (t.annotationType === ANNOTATION_TYPE.QUIZ)
      );
    }
    else if (tagType === TagType.TASK) {
      return Object.values(spaceTags).filter(
        (t) => t.annotationType && (t.annotationType === ANNOTATION_TYPE.TASK)
      );
    }
    else if (tagType === TagType.MEDIA) {
      return Object.values(spaceTags).filter(
        // (t) => ((t.data?.media?.type || '').includes('file') || (t.data?.media?.type || '').includes('video') || (t.data?.media?.type || '').includes('photo'))
        (t) => (t.data?.media?.src && t.data?.media?.src.trim())
      );
    }
    return [];
    // console.log(`TabPanel 3 for tabValue ${tabValue} + ${(new Date()).valueOf() / 1000}`);
    // console.log(`tags for tabValue ${tagType} ${visibleSpaceTags.length}`);
    // return visibleSpaceTags;
  }


  function handleChange(event: any, newValue: TagType) {
    setValue(newValue);
    // setST(getTagsForTab(newValue));
    // dispatch(setCurrentShowcaseTags(getTagsForTab(newValue)));
    addTagsToEmptyShowcase({ tagIds: [], tagObjects: getTagsForTab(newValue, spaceTags) });
  }

  function handleChangeIndex(index: number) {
    setValue(TAG_TYPES[index]);
  }

  const handleEditTag = (tagId: string) => {
    dispatch(setOpenTagForm(true));
    dispatch(setEditShowcaseTagId(tagId));
  };

  return (console.log(`%c[st] rendering SidebarTabPanel`,'color: orange;'),
    <div className={classes.root}>
      {/* <AppBar position='static' color='default' classes={{ root: classes.appBarRoot }}> */}
      {/* <TabContext value={value}> */}
      <Tabs
        value={value}
        onChange={handleChange}
        style={tagsViewMode !== ViewMode.STATUS ? { minHeight: '48px' } : { minHeight: '0px' }}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label='full width tabs'>
        {<Tab value={TagType.ALL} classes={{ root: classes.tabButton }} label={'All'} />}
        {<Tab value={TagType.INFO} classes={{ root: classes.tabButton }} label={'Info'} />}
        {<Tab value={TagType.QUIZ} classes={{ root: classes.tabButton }} label={'Quiz'} />}
        {<Tab value={TagType.TASK} classes={{ root: classes.tabButton }} label={'Task'} />}
        {<Tab value={TagType.MEDIA} classes={{ root: classes.tabButton }} label={'Media'} />}
      </Tabs>
      {/* </AppBar> */}
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={TAG_TYPES.indexOf(value)}
        onChangeIndex={handleChangeIndex}
      >

      </SwipeableViews> */}

      <div>
        <TagsList
          onClickTagsPopup={(option: string, tagId: string) => {
            if (option === 'Delete') handleDeleteTag(tagId);
            else if (option === 'Edit') handleEditTag(tagId);
          }}
          // tabValue={value}
          currentShowcaseTags={getTagsForTab(value, spaceTags)}
        />
      </div>
      {/* })} */}
      {/* <TabPanel value={value} index={0}>
          {useMemo(() => {<TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
            tabValue={value}
          // currentShowcaseTags={visibleSpaceTags}
          />}, [spaceTags])}

        </TabPanel>
        <TabPanel value={value} index={1}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
            // tabValue={value}
          currentShowcaseTags={visibleSpaceTags}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
            // tabValue={value}
          currentShowcaseTags={visibleSpaceTags}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
            // tabValue={value}
          currentShowcaseTags={visibleSpaceTags}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <TagsList
            onClickTagsPopup={(option: string, tagId: string) => {
              if (option === 'Delete') handleDeleteTag(tagId);
              else if (option === 'Edit') handleEditTag(tagId);
            }}
            // tabValue={value}
          currentShowcaseTags={visibleSpaceTags}
          />
        </TabPanel> */}

      {/* </TabContext> */}
    </div>
  );
}

function tagTypeToName(index: number) {
  let name = '';
  if (index === 0) {
    name = TagType.ALL
  } else if (index === 1) {
    name = TagType.INFO
  }
  else if (index === 2) {
    name = TagType.QUIZ
  }
  else if (index === 3) {
    name = TagType.TASK
  }
  else if (index === 4) {
    name = TagType.MEDIA
  }
  return name
}