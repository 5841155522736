import { Quaternion } from "three";
import extension from "./extendFunc";

export class QuaternionExtensions {
    
    @extension(Quaternion)
    public static assignFromAny(q: Quaternion, other:any){
        q.x = other.x;
        q.y = other.y;
        q.z = other.z;
        q.w = other.w;
    }

    public static assignFromJSONString(q: Quaternion, other:any){
        let newQuat = JSON.parse(other);
        q.x = newQuat._x;
        q.y = newQuat._y;
        q.z = newQuat._z;
        q.w = newQuat._w;
    }

    public static assign(q: Quaternion, other:Quaternion){
        q.x = other.x;
        q.y = other.y;
        q.z = other.z;
        q.w = other.w;
    }
    
    public static toAxisAngle(q:Quaternion) {
        
        const angle = 2 * Math.acos(q.w);
        
        const s = Math.sqrt(1 - q.w * q.w);
        
        const x = q.x / s;
        const y = q.y / s;
        const z = q.z / s;
        
        // console.log(x, y, z, angle);
        
        
    }
}
