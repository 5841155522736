import { GizmoTools } from "../../../../modules/home/SpaceDetail/SpaceView/ShowcaseOverlay/3DTools/GizmoTools";
import { Behaviors, IInteractionDragBeginEvent, IInteractionDragEndEvent, IInteractionDraggingEvent, saveTempMeshesPositions } from "../SubSystems/core/Behaviors";
import Simulation from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import NodeStorage from "../SubSystems/storageAndSerialization/NodeStorage";
import { PropertiesPanelMode, UnserializedUserData, UserDataEventTypes, UserDataProperties, UserDataTypes } from "../SubSystems/ui-interop/PropertiesPanel";
import * as THREE from 'three';
import { WireTerminal } from "../components/Wire/WireTerminal";
import { store } from "App";
import Utils, { updateComponentPositionFromWorld, updateSubObjectPositionFromWorld } from "../Tools/Utils";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import OnDragProperties from "../SubSystems/ancillary/DragPropertiesOfComponents"
import { CONDITION_TYPE, Logic, LogicEngine, OBJECT_EVENT_TYPES, ObjectCondition, getAllConditions } from "types/models/dataAccess/Logic";
import { indexOf } from "lodash";
import { hideAlert, showAlert, showMessage } from "redux/actions";
import modelMap from "modules/home/models";
import { CompInfo, ICondition } from "modules/home/SpaceDetail/Interactions/LogicTrees";
import { SceneNodeBase } from "mp/core/craEngine/components/SceneNodeBase/SceneNodeBase";

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();
const planeNormal = new THREE.Vector3();

const allMeshes: Map<string, ISceneNode> = new Map();
const subObjsCollidingPointMap: Map<string, { x: number, y: number, z: number }> = new Map();
export class DragBeginObjectSpy implements IComponentEventSpy<IInteractionDragBeginEvent> {
    public eventType = ComponentInteractionType.DRAG_BEGIN;
    constructor(private component: SceneComponent, private node: ISceneNode) {
    }
    onEvent(payload: IInteractionDragBeginEvent) {
        console.log('glb db', payload);

        // let userConfirmation = true;

        // if(!store.getState().layer.presentationMode){
        //     return;
        // }
        if (!!store.getState().layer.presentationMode) {

            // if (!store.getState().layer.presentationMode) {
            // store.dispatch(showMessage("Dragging in Edit mode will permanently change the position of this object, i.e. it will not reset when the Lesson ends. If you intended to test object collisions, use Presentation mode instead"))
            //     store.dispatch(showAlert({
            //         open: true,
            //         onDeny: () => { store.dispatch(hideAlert());},
            //         onConfirm: () => { userConfirmation = true; store.dispatch(hideAlert()); },
            //         title: "Confirm position change?",
            //         dialogTitle: "Dragging in Edit mode will permanently change the position of this object, i.e. it will not reset when the Lesson ends. If you intended to test object collisions, use Presentation mode instead. Otherwise, continue to edit this object's position",
            //         // children: {},
            //         yesLabel: "Continue position change with dragging",
            //         noLabel: "Cancel Position change",
            //         //   isYesButtonCTA = true,
            //         dark: false
            //         //   cancelNotAllowed = false,
            //         //   hideYesButton = false
            //     }))
            // }

            console.log('drag begin', payload);
            // if (userConfirmation) {
            // Simulation.instance.draggedObject = this.node;
            Simulation.instance.isObjectDragging = true;
            let index = this.node.components.map(c => (c as any).id).indexOf((this.component as any).id); //FIXME id is not guranteed? or part of the class feclaration
            Simulation.instance.draggedObject = (new NodeCollider()).setNode(this.node);
            switch (this.component.componentType) {
                case 'mp.wireTerminal':
                    Simulation.instance.draggedObject = new NodeCompCollider().setNode(this.node).setComponentIndex(index);
                    (this.component as WireTerminal).inputs.connectedTo = undefined;
                    break;
                default:
                    break;
            }

            switch (this.node.name) {
                case 'gltf':
                    // Simulation.instance.draggedObject = new NodeCompCollider().setNode(this.node).setComponentIndex(index);
                    // (this.component as WireTerminal).inputs.connectedTo = undefined;
                    this.node.userData && (this.node.userData.customProps = SceneNodeBase.updateCustomProps(this.node.userData.customProps, 'Occupying', ''));
                    break;
                default:
                    break;
            }

            // if(Simulation.instance.draggedObject.node?.name === 'Multimeter'){
            //     Simulation.instance.draggedObject.node.unserializedUserData[UnserializedUserData.MultimeterState] =
            // }
            // if (Simulation.instance.draggedObject.node && Simulation.instance.draggedObject.node.name === 'Multimeter') {

            Simulation.instance.draggedObject.node && Behaviors.saveNodePosition(Simulation.instance.draggedObject.node);
            if (Simulation.instance.draggedObject.node) {
                let meshes: THREE.Object3D[] | null = null;
                if (UserDataProperties.skinnedNode in Simulation.instance.draggedObject.node.userData) {
                    meshes = [(Simulation.instance.draggedObject.node.userData[UserDataProperties.skinnedNode] as THREE.Group)];
                } else {
                    meshes = Utils.FindAllMeshesAndLineSegments(Simulation.instance.draggedObject.node);//TODO-ST
                }

                saveTempMeshesPositions(Simulation.instance.draggedObject.node, meshes);
            }
        }

        Array.from(store.getState().home.spaceModels.values()).forEach(spaceModel => {
            if (spaceModel.nodeRef) {
                let meshes = Utils.FindAllMeshesAndLineSegments(spaceModel.nodeRef);
                meshes?.forEach(m => {
                    m.uuid && allMeshes.set(m.uuid, spaceModel.nodeRef)
                })
            }
        })

        // Simulation.instance.mpRaycaster.onMouseDown(
        //     // {x: payload.input.position.x, y: payload.input.position.y}
        // )
        // Behaviors.instance.processAllNodeDragBeginEvents(this.node, payload);}
        // }
    }
}

export class DragEndObjectSpy implements IComponentEventSpy<IInteractionDragEndEvent> {

    private tempNode: ISceneNode;
    public eventType = ComponentInteractionType.DRAG_END;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
        this.tempNode = node;
    }
    async onEvent(payload: IInteractionDragEndEvent) {
        console.log('glb de', payload);

        // if(!store.getState().layer.presentationMode){
        //     return;
        // }
        console.log('drag end', this.mainComponent);
        if (Simulation.instance.draggedObject) {
            Simulation.instance.isObjectDragging = false;
            // Simulation.instance.dragEndedOn = Simulation.instance.draggedObject;
            Simulation.instance.draggedObject = null;
            const onDragProperties = new OnDragProperties(this.tempNode, this.mainComponent);

            if (!store.getState().layer.presentationMode) {
                onDragProperties.onDrag();
                // const terminalComponent = (this.mainComponent as WireTerminal);
                // if (terminalComponent.inputs.terminal === "start") {
                //     this.tempNode.userData[UserDataProperties.startTerminalPosition] = terminalComponent.getMeshPosition();
                //     // Simulation.instance.dragEndedOnComponent = terminalComponent;
                // }
                // else if (terminalComponent.inputs.terminal === "end") {
                //     this.tempNode.userData[UserDataProperties.endTerminalPosition] = terminalComponent.getMeshPosition();
                //     // Simulation.instance.dragEndedOnComponent = terminalComponent;
                // }
                NodeStorage.storeNode(this.tempNode);
            }
            // Behaviors.instance.processAllNodeDragEndEvents(this.node, payload);}
        }
    }
}

export class DragObjectSpy implements IComponentEventSpy<IInteractionDraggingEvent> {
    private frame: HTMLDivElement;

    public eventType = ComponentInteractionType.DRAG;
    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
        let frame = document.getElementById('sdk-iframe');
        if (frame) {
            this.frame = frame as HTMLDivElement;
        }
    }
    onEvent(eventData: IInteractionDraggingEvent & {
        component: SceneComponent, node: ISceneNode, eventType: string
    }) {
        // console.log('glb drag', eventData);

        // if(!store.getState().layer.presentationMode){
        //     return;
        // }
        if (!!Simulation.instance.sceneLoader.transformGizmoComponent?.inputs?.visible) {
            return;
        }
        // Simulation.instance.isObjectDragging = true;

        if (Simulation.instance.draggedObject) {

            let frameWidth, frameHeight;

            if (this.frame) {
                frameWidth = this.frame.clientWidth;
                frameHeight = this.frame.clientHeight;
            }
            else {
                frameWidth = window.innerWidth;
                frameHeight = window.innerHeight;
            }
            let input;
            if ((eventData as any).eventData) {
                input = (eventData as any).eventData.input;
            }
            else {
                input = (eventData as any).input;
            }
            mouse.x = (input.clientPosition.x / frameWidth) * 2 - 1;
            mouse.y = -(input.clientPosition.y / frameHeight) * 2 + 1;

            // Update the raycaster
            raycaster.setFromCamera(mouse, Simulation.instance.camera.Camera);

            // Update the plane's normal based on the camera's view direction
            planeNormal.copy(Simulation.instance.camera.Camera.getWorldDirection(planeNormal));

            if (eventData.component && (eventData.component as SceneComponent).componentType === 'mp.wireTerminal') {
                const _draggedComponent: SceneComponent = eventData.component;
                // Calculate the intersection point between the mouse ray and the plane
                const intersection = new THREE.Vector3();
                let oldCompWorldPosition = new THREE.Vector3();
                ((_draggedComponent as any).mesh as THREE.Mesh).getWorldPosition(oldCompWorldPosition);
                // const _drggedObject = Simulation.instance.draggedObject;

                raycaster.ray.intersectPlane(new THREE.Plane().setFromNormalAndCoplanarPoint(planeNormal, oldCompWorldPosition), intersection);

                updateComponentPositionFromWorld(_draggedComponent, intersection);

            } else {
                const _drggedObject = this.node;
                // Calculate the intersection point between the mouse ray and the plane
                const intersection = new THREE.Vector3();
                raycaster.ray.intersectPlane(new THREE.Plane().setFromNormalAndCoplanarPoint(planeNormal, _drggedObject.position), intersection);
                _drggedObject.position.copy(intersection)
            }

            if (Simulation.instance) {
                if (!Simulation.instance.xKeyPressed) {
                    checkForCollisions(eventData.component, eventData.node, eventData.collider);
                }
            }

        }
        Behaviors.instance.processAllNodeDragEvents(this.node, eventData);
    }
}

export class NodeCollider {
    protected _node?: ISceneNode;
    protected _nodeId: string;

    public get node(): ISceneNode | undefined {
        return this._node;
    }

    public get nodeId(): string {
        return this._nodeId;
    }

    constructor() { }

    // public static createNodeCollider(nodeId: string, componentIndex: number, subObjectName: string){
    //     if(!!subObjectName){

    //     }
    // }

    public setNode(node: ISceneNode) {
        this._node = node;
        this._nodeId = node.userData.id;
        // this._conditionObjectId = this.nodeId;
        // this._componentIndex = -1;
        return this;
    }

    public setNodeId(nodeId: string) {
        this._nodeId = nodeId;
        this._node = store.getState().home.spaceModels.get(nodeId)?.nodeRef;
        return this;
    }

    public setComponentColliderUUID(uuid: string) {
        return (this as unknown as NodeCompCollider).setComponentColliderUUID(uuid);
    }
    public setComponentIndex(index: number) {

        let ncc = new NodeCompCollider();
        this.node && ncc.setNode(this.node).setComponentIndex(index);
        return ncc;
        // return (this as unknown as NodeCompCollider).setComponentIndex(index);
        // this = (this as unknown as NodeCompCollider);
        // .setComponentIndex(index);
    }

    public setSubObjectName(subObjName: string) {

        let nso = new NodeSubObject();
        this.node && nso.setNode(this.node).setSubObjectName(subObjName);
        return nso;
        // return (this as unknown as NodeSubObject).setSubObjectName(subObjName);
    }

    getColliderUUID(): string {
        return this._nodeId;
    }

    getCompositeObjectId(): string { //nodeId
        return this._nodeId;
    }

    getReadableObjectId(): string {
        return this.node?.userData.nameToShow;
    }

    public static makeNodeCollider({ nodeId, componentIndex = -1, subObjectName = '' }: { nodeId: string, componentIndex?: number, subObjectName?: string })
        : NodeCompCollider | NodeSubObject | NodeCollider | undefined {

        if (!nodeId) {
            console.error("makeNodeCollider called with Empty nodeId");
            return undefined;
        }
        if (componentIndex > -1) { // is an Annotation
            return (new NodeCompCollider()).setNodeId(nodeId).setComponentIndex(componentIndex);
        } else if (!!subObjectName) {
            return (new NodeSubObject()).setNodeId(nodeId).setSubObjectName(subObjectName);
        } else {
            return (new NodeCollider()).setNodeId(nodeId);
        }
    }

    public static makeNodeColliderWithComponent({ nodeId, component = undefined, subObjectName = '' }: { nodeId: string, component: SceneComponent | undefined, subObjectName?: string })
        : NodeCompCollider | NodeSubObject | NodeCollider | undefined {
        let node = store.getState().home.spaceModels.get(nodeId)?.nodeRef;
        if (!node) {
            return undefined;
        }
        let componentIndex = !component ? -1 : (node as ISceneNode).components.findIndex(c => c?.inputs?.name === component?.inputs?.name);
        return NodeCollider.makeNodeCollider({ nodeId: nodeId, componentIndex: componentIndex, subObjectName: subObjectName });
    }

    public static makeNodeColliderFromObjectId(objectId: string)
        : NodeCompCollider | NodeSubObject | NodeCollider | undefined {
        let oc = objectId.trim();

        if (!!objectId) {

            let objArray = (oc || '').split('---').map(i => i.trim()).filter(i => !!i);
            let nodeId = objArray[0];

            let node = store.getState().home.spaceModels.get(nodeId)?.nodeRef;

            if (!!node) {
                if (objArray.length > 1) {
                    if (modelMap.get(node.name) && objArray[1] && Number.parseInt(objArray[1]) > -1) {
                        return NodeCollider.makeNodeCollider({ nodeId: nodeId, componentIndex: Number.parseInt(objArray[1]) });
                    }
                    return NodeCollider.makeNodeCollider({ nodeId: nodeId, subObjectName: objArray[1] });
                }
                return NodeCollider.makeNodeCollider({ nodeId: nodeId });
            }
        }
        return undefined;
    }

    public static getFromCollidesCondition(condition: ObjectCondition)
        : (NodeCompCollider | NodeSubObject | NodeCollider | null)[] {

        // return ([NodeCollider.makeNodeColliderFromObjectId(condition.objectId || '') || null,
        // NodeCollider.makeNodeColliderFromObjectId(condition.objectId2 || '') || null])


        let result: (NodeCollider | null)[] = [];
        let o1 = new NodeCollider();
        o1.setNodeId(condition.objectId || '');
        if (!!condition.subObject1Name) {
            o1 = (new NodeSubObject()).setNodeId(condition.objectId || '').setSubObjectName(condition.subObject1Name);
        } else if (condition.compInfo?.object1ComponentIndex && condition.compInfo?.object1ComponentIndex > -1) {
            o1 = (new NodeCompCollider()).setNodeId(condition.objectId || '').setComponentIndex(condition.compInfo?.object1ComponentIndex || -1);
        }

        result.push(o1.node ? o1 : null);

        let o2 = new NodeCollider();
        o2.setNodeId(condition.objectId2 || '');
        if (!!condition.subObject2Name) {
            o2 = (o2 as NodeSubObject).setSubObjectName(condition.subObject2Name);
        } else if (condition.compInfo?.object2ComponentIndex && condition.compInfo?.object2ComponentIndex > -1) {
            o2 = (o2 as NodeCompCollider).setComponentIndex(condition.compInfo?.object2ComponentIndex || -1);
        }
        result.push(o2.node ? o2 : null);

        return result;

    }

    public setConditionObject(isSecondObject: boolean, condition: ICondition) {
        if (!isSecondObject) {
            condition.objectId = this._nodeId;
            condition.compInfo && (condition.compInfo.object1ComponentIndex = -1);
            condition.localCollidingPoint1 = undefined;
            condition.subObject1Name = '';
        } else {
            condition.objectId2 = this._nodeId;
            condition.compInfo && (condition.compInfo.object2ComponentIndex = -1);
            condition.localCollidingPoint2 = undefined;
            condition.subObject2Name = '';
        }
        return condition;
    }

    setPosition(position: THREE.Vector3): void { //component MUST have outputs.objectRoot
        if (!this.node) {
            console.error("No node found");
            return;
        }
        // if (!this.componentIndex || this.componentIndex == -1) {
        this.node.position.copy(position)
        // } else {
        // let _draggedComponent = this.node?.components[this.componentIndex] as WireTerminal; //TODO //FIXME //HARDCODED
        // updateComponentPositionFromWorld(_draggedComponent, position);
        // }
    }

    getPosition(): THREE.Vector3 | undefined {
        if (!this.node) {
            console.error("No node found");
            return undefined;
        }
        if (this.node) {// && (!this.componentIndex || this.componentIndex == -1)) {
            return this.node.position;
        } else {
            // return this.node?.components[this.componentIndex].outputs.objectRoot?.position;
            return undefined;
        }

    }

    setPositionFrom(collidingPair: NodeCollider[], condition: ObjectCondition): void { //component MUST have outputs.objectRoot

        let collidedWith: NodeCollider | NodeCompCollider | NodeSubObject | undefined = undefined;
        if (this.getCompositeObjectId() === collidingPair[0].getCompositeObjectId()) {
            collidedWith = collidingPair[1];
            let pos = collidingPair[1].getPosition();
            // let pos = condition.localCollidingPoint2;
            pos && this.setPosition(new THREE.Vector3(pos.x, pos.y, pos.z));

        } else if (this.getCompositeObjectId() === collidingPair[1].getCompositeObjectId()) {
            collidedWith = collidingPair[0];
            let pos = collidingPair[0].getPosition();
            // let pos = condition.localCollidingPoint1;
            pos && this.setPosition(new THREE.Vector3(pos.x, pos.y, pos.z));
        }

        switch (this.node?.name) {
            case 'gltf':
                if (this.node?.userData) {
                    this.node.userData.customProps = SceneNodeBase.updateCustomProps(this.node.userData.customProps, 'Occupying', collidedWith?.getReadableObjectId() || '');
                }

            //     case 'Positive Terminal':
            //     case 'Negative Terminal':
            //         let nc = Simulation.instance.draggedObject as NodeCompCollider;
            //         (nc?.node?.components[nc.componentIndex] as WireTerminal).outputs.connectedTo = collidedWith;
        }
    }
}
export class NodeCompCollider extends NodeCollider {
    protected _componentColliderUUID: string;
    protected _componentIndex: number = -1;
    protected _conditionObjectId: string; //nodeId--componentIndex string
    private _componentName: string;
    protected _component: SceneComponent;

    // public get conditionObjectId(): string {
    //     return this._conditionObjectId;
    // }

    // public get componentColliderUUID(): string {
    //     return this._componentColliderUUID;
    // }

    public get componentIndex(): number {
        return this._componentIndex;
    }

    public get componentName(): string {
        return this._componentName;
    }

    public get component(): SceneComponent {
        return this._component;
    }

    public setNodeId(nodeId: string) { //set node and reset all other props
        super.setNodeId(nodeId);
        this._componentIndex = -1;
        this._componentColliderUUID = '';
        this._conditionObjectId = this.nodeId;
        this._componentName = '';
        return this;
    }

    public setNode(node: ISceneNode) { //reset all other props
        super.setNode(node);
        this._componentIndex = -1;
        this._componentColliderUUID = '';
        this._conditionObjectId = this.nodeId;
        return this;
    }

    setComponentColliderUUID(uuid: string) {
        this._componentColliderUUID = uuid;
        return this;
    }

    setComponentIndex(index: number) {
        if (!this.nodeId) {
            throw new Error("Node needs to be set before component index!");
        }
        if (!this.node) {
            console.error("Node not found");
        } else {
            this._componentIndex = index;
            if (index >= 0) {
                this._conditionObjectId = this.nodeId + '---' + index;
                this._componentColliderUUID = this.node.components[this.componentIndex]?.outputs.collider?.uuid || '';
                this._componentName = this.node.components[this.componentIndex].inputs?.name || '';
                this._component = this.node.components[this.componentIndex];
            }

        }
        return this;
    }

    getColliderUUID(): string {
        return this._componentColliderUUID;
    }

    getCompositeObjectId(): string { //nodeId---componentIndex
        return this._conditionObjectId;
    }

    getReadableObjectId(): string {
        return super.getReadableObjectId() + '---' + this._componentName;
    }

    public setConditionObject(isSecondObject: boolean, condition: ICondition) {//}, componentIndex = -1, subObjectName = '') {
        condition = super.setConditionObject(isSecondObject, condition);
        if (!isSecondObject) {

            if (!condition.compInfo) {
                condition.compInfo = { object1ComponentIndex: -1, object2ComponentIndex: -1 };
            }
            condition.compInfo.object1ComponentIndex = this._componentIndex;
        } else {
            if (!condition.compInfo) {
                condition.compInfo = { object1ComponentIndex: -1, object2ComponentIndex: -1 };
            }
            condition.compInfo.object2ComponentIndex = this._componentIndex;
        }
        return condition;
    }


    setPosition(position: THREE.Vector3, collidedWith?: NodeCollider | NodeCompCollider | NodeSubObject, dontUpdatePosition?: boolean): void { //component MUST have outputs.objectRoot
        if (!this.node) {
            console.error("No node found");
            return;
        }
        if (!this.componentIndex || this.componentIndex == -1) {
            this.node.position.copy(position);
        } else {
            // this.node?.components[this.componentIndex].outputs.objectRoot?.position.copy(position);
            // (this.node?.components[this.componentIndex] as WireTerminal).updateMeshPosition(position);

            if (this.node?.components[this.componentIndex].componentType === 'mp.wireTerminal') {
                let _draggedComponent = this.node?.components[this.componentIndex] as WireTerminal; //TODO //FIXME //HARDCODED
                !dontUpdatePosition && updateComponentPositionFromWorld(_draggedComponent, position);

                switch (this.componentName) {
                    case 'Positive Terminal':
                    case 'Negative Terminal':
                        //@ts-ignore //TODO //FIXME
                        collidedWith && ((this.node?.components[this._componentIndex] as WireTerminal).inputs.connectedTo = collidedWith);
                }
            } else {
                console.error("setPosition on drag behavior not implemented for this component");
            }

        }
    }

    getPosition(): THREE.Vector3 | undefined {
        if (!this.node) {
            console.error("No node found");
            return undefined;
        }
        if (this.node && (!this.componentIndex || this.componentIndex == -1)) {
            return this.node.position;
        } else {
            return this.node?.components[this.componentIndex].outputs.objectRoot?.position;
        }

    }

    private getObjectWorldPosition(object: THREE.Object3D): THREE.Vector3 {
        let worldPosition = new THREE.Vector3();
        object.getWorldPosition(worldPosition);
        return worldPosition;
    }


    protected updateSubObjectPositionFromWorld(node: ISceneNode, pos: THREE.Vector3): void {

        if (pos && node?.obj3D) {
            let posWorld = (node.obj3D as THREE.Object3D).localToWorld(pos);
            this.setPosition(posWorld);
        }
    }


    setPositionFrom(collidingPair: NodeCollider[], condition: {
        localCollidingPoint1?: { x: number, y: number, z: number },
        localCollidingPoint2?: { x: number, y: number, z: number }
    }): void { //component MUST have outputs.objectRoot

        let collidedWith: NodeCollider | NodeCompCollider | NodeSubObject | undefined = undefined;

        if (this.getColliderUUID() === collidingPair[0].getColliderUUID()) {
            collidedWith = collidingPair[1];

            let pos = condition.localCollidingPoint2;
            if (pos && collidingPair[1].node?.obj3D) {
                let posWorld = (collidingPair[1].node.obj3D as THREE.Object3D).localToWorld(new THREE.Vector3(pos.x, pos.y, pos.z));
                this.setPosition(posWorld, collidedWith);
            }

        } else if (this.getColliderUUID() === collidingPair[1].getColliderUUID()) {
            collidedWith = collidingPair[0];

            let pos = condition.localCollidingPoint1;
            if (pos && collidingPair[0].node?.obj3D) {
                let posWorld = (collidingPair[0].node.obj3D as THREE.Object3D).localToWorld(new THREE.Vector3(pos.x, pos.y, pos.z));
                this.setPosition(posWorld, collidedWith);
            }
        }

    }

}
export class NodeSubObject extends NodeCollider {

    protected _conditionWithSubObjectName: string; //nodeId--subObjectName string
    protected _subObjectName: string;
    protected _subObject?: THREE.Object3D;

    // public get conditionWithSubObjectName(): string {
    //     return this._conditionWithSubObjectName;
    // }

    public get subObjectName(): string {
        return this._subObjectName;
    }

    public get subObject(): THREE.Object3D | undefined {
        return this._subObject;
    }

    public setNodeId(nodeId: string) {
        super.setNodeId(nodeId);
        this._conditionWithSubObjectName = this.nodeId;
        this._subObjectName = '';
        this._subObject = undefined;
        return this;
    }

    public setNode(node: ISceneNode) {
        super.setNode(node);
        this._conditionWithSubObjectName = this.nodeId;
        this._subObjectName = '';
        this._subObject = undefined;
        return this;
    }

    setSubObjectName(subObjName: string) {
        if (!this.nodeId) {
            throw new Error("Node needs to be set before component index!");
        }
        if (!this.node) {
            console.error("Node not found");
        } else {
            this._subObjectName = subObjName;
            this._subObject = this.node?.obj3D?.getObjectByName(this._subObjectName);
            this._conditionWithSubObjectName = this.nodeId + '---' + this._subObjectName;
        }
        return this;
    }

    getColliderUUID(): string {
        return this._subObject?.uuid || '';
    }

    getCompositeObjectId(): string { //nodeId---subObjectName
        return this._conditionWithSubObjectName;
    }

    getReadableObjectId(): string {
        return super.getReadableObjectId() + '---' + this._subObjectName;
    }

    public setConditionObject(isSecondObject: boolean, condition: ICondition) {
        condition = super.setConditionObject(isSecondObject, condition);
        if (!isSecondObject) {
            condition.subObject1Name = this._subObjectName;
        } else {
            condition.subObject2Name = this._subObjectName;
        }
        return condition;
    }

    setPosition(position: THREE.Vector3): void { //component MUST have outputs.objectRoot
        if (!this.node) {
            console.error("No node found");
            return;
        }
        if (!this._subObjectName) {
            this.node.position.copy(position);
        } else {


            //TODO does this work?
            this._subObject && updateSubObjectPositionFromWorld(this._subObject, position);

            // let _draggedComponent = this.node?.components[this.componentIndex] as WireTerminal; //TODO //FIXME //HARDCODED
            // updateComponentPositionFromWorld(_draggedComponent, position);

        }
    }

    private getObjectWorldPosition(object: THREE.Object3D): THREE.Vector3 {
        let worldPosition = new THREE.Vector3();
        object.getWorldPosition(worldPosition);
        return worldPosition;
    }

    getPosition(): THREE.Vector3 | undefined {
        if (!this.node) {
            console.error("No node found");
            return undefined;
        }
        if (this.node && !this._subObjectName) {
            return this.node.position;
        } else {
            return this._subObject ? this.getObjectWorldPosition(this._subObject) : this.node.position;


            // return this._subObject?.position;
            // return this.node?.components[this.componentIndex].outputs.objectRoot?.position;
        }

    }

    setPositionFrom(collidingPair: NodeCollider[]): void { //component MUST have outputs.objectRoot
        if (this.getColliderUUID() === collidingPair[0].getColliderUUID()) {
            let pos = collidingPair[1].getPosition();
            pos && this.setPosition(new THREE.Vector3(pos.x, pos.y, pos.z));

        } else if (this.getColliderUUID() === collidingPair[1].getColliderUUID()) {
            let pos = collidingPair[0].getPosition();

            pos && this.setPosition(new THREE.Vector3(pos.x, pos.y, pos.z));
        }
    }
}

function checkForCollisions(component: SceneComponent, node: ISceneNode, collider: THREE.Object3D) {

    let currentLessonId = store.getState().layer.currentLesson?.id;
    let currentSpaceId = store.getState().home.currentSpace?.id;
    let tg = getCurrentTagGroup();
    let pairsOfCollidingObjectsToCheck: NodeCollider[][] = [];

    if (currentSpaceId && currentLessonId && tg?.logic) {// if condition exists, things can collide

        getAllConditions(tg.logic).filter(c => c?.type === CONDITION_TYPE.OBJECT_CONDITION)
            .filter(c => (c as ObjectCondition).objectEventType === OBJECT_EVENT_TYPES.COLLIDES)
            .map(c => {
                // list of nodes/ ncs pairs that can collide
                let pair: (NodeCollider | null)[] = NodeCollider.getFromCollidesCondition(c as ObjectCondition);

                if (pair[0] && pair[1]) {

                    if ((c as ObjectCondition).localCollidingPoint1 && (pair[0] as NodeSubObject).subObject) {
                        subObjsCollidingPointMap.set(pair[0].getCompositeObjectId(), (c as ObjectCondition).localCollidingPoint1!)
                    }
                    if ((c as ObjectCondition).localCollidingPoint2 && (pair[1] as NodeSubObject).subObject) {
                        subObjsCollidingPointMap.set(pair[1].getCompositeObjectId(), (c as ObjectCondition).localCollidingPoint2!)
                    }

                    if (Simulation.instance.draggedObject?.getCompositeObjectId() === pair[0].getCompositeObjectId()
                        || Simulation.instance.draggedObject?.getCompositeObjectId() === pair[1].getCompositeObjectId()
                    ) {
                        pairsOfCollidingObjectsToCheck.push(pair as NodeCollider[]);
                    }
                }
            });
    }

    let allObjColliders: any[] = [];
    let allWireTerminalColliders: THREE.Object3D[] = [];

    Array.from(store.getState().home.spaceModels.values()).map(spaceModel => {
        spaceModel.nodeRef && allObjColliders.push(...Utils.setCollidersOnAllNodesAndGet(spaceModel.nodeRef as ISceneNode));
    });


    try {
        const intersects = raycaster.intersectObjects(allObjColliders);
        let set = new Set(intersects.map(i => i.object.uuid))

        intersects.forEach(i => {
            // for eachpair pair raycast intersects includes, call logicProcessCondition
            // if true, snap positions
            if (i.object.type !== "LineSegments") {
                let n = allMeshes.get(i.object.uuid);
                n?.userData.id && set.add(n?.userData.id);
            }
            // n && intersectedNodes.set(n?.userData.id, n);
            // intersects.length >= 2 && console.log('intersects', intersectedNodes, allMeshes);
        })
        let intersectingUUIDs = Array.from(set);

        if (intersectingUUIDs.length >= 2) {

            if ((Simulation.instance.draggedObject as NodeCompCollider).node?.name === 'Wire' && (Simulation.instance.draggedObject as NodeCompCollider).component?.componentType === 'mp.wireTerminal') { //if a terminal collides with something, snap its position to it
                // Array.from(store.getState().home.spaceModels.values()).map(spaceModel => {
                //     spaceModel.nodeRef && allWireTerminalColliders.push(...Utils.getCollidersOnNode(spaceModel.nodeRef as ISceneNode, 'mp.wireTerminal'));
                // });
                intersectingUUIDs.forEach(uuid => {
                    if (allMeshes.get(uuid)?.name === 'gltf') {
                        let intersectingNode = allMeshes.get(uuid) as ISceneNode;

                        //TODO FIXME HARDCODED
                        let negCollider = (intersectingNode.obj3D as THREE.Object3D).getObjectByName('mesh_20-Mesh-0');
                        let posCollider = (intersectingNode.obj3D as THREE.Object3D).getObjectByName('mesh_0-Mesh-0');
                        let collidedWith: NodeSubObject | undefined = undefined;
                        if (uuid === negCollider?.uuid) {

                            collidedWith = (new NodeCollider()).setNodeId(intersectingNode.userData.id).setSubObjectName('mesh_20-Mesh-0')

                            collidedWith && (Simulation.instance.draggedObject as NodeCompCollider)?.setPositionFrom([collidedWith, Simulation.instance.draggedObject!],
                                { localCollidingPoint1: subObjsCollidingPointMap.get(collidedWith.getCompositeObjectId()) });
                            Simulation.instance.draggedObject = null;
                        } else if (uuid === posCollider?.uuid) {

                            collidedWith = (new NodeCollider()).setNodeId(intersectingNode.userData.id).setSubObjectName('mesh_0-Mesh-0');
                            // Simulation.instance.draggedObject?.setPosition(posCollider.position.clone(), collidedWith);

                            collidedWith && (Simulation.instance.draggedObject as NodeCompCollider)?.setPositionFrom([collidedWith, Simulation.instance.draggedObject!],
                                { localCollidingPoint1: subObjsCollidingPointMap.get(collidedWith.getCompositeObjectId()) });
                            Simulation.instance.draggedObject = null;
                        }


                    }
                })


            }

            // if condition exists, things can collide
            for (let pair of pairsOfCollidingObjectsToCheck) {
                if (intersectingUUIDs.includes(pair[0].getColliderUUID() || pair[0].nodeId)
                    && intersectingUUIDs.includes(pair[1].getColliderUUID() || pair[1].nodeId)) {

                    if (currentSpaceId && currentLessonId && tg?.logic) {
                        LogicEngine.processStepLogicConditions({
                            tgId: tg.id,
                            stepLogic: tg.logic as Logic,
                            targetNode: node,
                            targetNodeList: [
                                pair[0].getCompositeObjectId(),
                                pair[1].getCompositeObjectId()
                            ],
                            eventType: OBJECT_EVENT_TYPES.COLLIDES
                        });
                    }
                }
            }
        }

    } catch (e: any) {
        console.error(e);
    }

}

function raycastCollision(casterObject: THREE.Mesh, allObjColliders: THREE.Mesh[]) {
    // For more accuracy I cast from each geometry of the object ? maybe inefficent if object has large geometry
    // const originalPosition = casterObject.position.clone();
    // Set the origin to be the center of the box
    // raycaster2.set(casterObject.position, new THREE.Vector3(0, 0, -1));

    // // Get the direction vector based on the box's velocity (movement direction)
    // const velocity = casterObject.getWorldDirection(new THREE.Vector3());

    // // Set the direction for the raycaster2
    // raycaster2.ray.direction.copy(velocity);

    // const intersectObjects = raycaster2.intersectObjects(allObjColliders);
    // return intersectObjects;
    let originPoint = casterObject.position.clone();
    const intersectionList = [];

    // Get the positions (vertices) attribute from the geometry
    const positionsAttribute = casterObject.geometry.getAttribute('position');

    for (let vertexIndex = 0; vertexIndex < positionsAttribute.count; vertexIndex++) {
        // Get the local vertex position
        const localVertex = new THREE.Vector3();
        localVertex.fromBufferAttribute(positionsAttribute, vertexIndex);

        // Get the global vertex position by applying the matrix
        const globalVertex = localVertex.clone().applyMatrix4(casterObject.matrixWorld);

        // Calculate the direction vector from the originPoint to the globalVertex
        const directionVector = globalVertex.clone().sub(originPoint);

        // Create a raycaster with the originPoint and the normalized directionVector
        const raycaster = new THREE.Raycaster(originPoint, directionVector.clone().normalize());

        // Perform intersection check with allObjColliders
        const collisionResults = raycaster.intersectObjects(allObjColliders);

        // Check if there is a collision closer than the distance to the vertex
        intersectionList.push(...collisionResults);
        // if (collisionResults.length > 0 && collisionResults[0].distance < directionVector.length()) {
        // }
    }

    return intersectionList;

}