import React, {
    ReactElement,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import Draft, { DraftEditorCommand, DraftHandleValue, EditorState } from 'draft-js';
import Editor, { EditorCommand } from '@draft-js-plugins/editor';
import createMentionPlugin, {defaultSuggestionsFilter} from '@draft-js-plugins/mention';
import editorStyles from './MentionEditor.module.css';
import _ from 'lodash';
import '../../../../../node_modules/@draft-js-plugins/mention/lib/plugin.css';
import { Button } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';
import { CollaboratorObj } from 'types/models/apps/ProjectBoard';

const useStyles = makeStyles(() => ({
  commentButtonStyle: {
    position:"absolute",
    bottom:"10px",
    right:"10px",
    zIndex: 11,
    textTransform: 'capitalize',
  },
  commentIconStyle:{
    marginLeft:"5px",
    cursor:"pointer"
  }
}));

type mentionObj = {
  id?: null | string | number;
  name: string;
  image?: string;
  email?: string;
}
interface IProps{
    mentions:mentionObj[]
    placeholder:string
    setEditorState:any;
    editorState:Draft.EditorState
    onKeyDown:(event: any)=> DraftEditorCommand | string | null | undefined;
    onHandleKeyCommand:(command: EditorCommand)=>DraftHandleValue;
    onHandleSaveComment:() => void;
}
  
  export default function MentionEditor({mentions,placeholder,editorState,setEditorState,onKeyDown,onHandleKeyCommand,onHandleSaveComment}:IProps): ReactElement {
    const ref = useRef<Editor>(null);

    const [open, setOpen] = useState(false);

    const [suggestions, setSuggestions] = useState<mentionObj[]>(mentions);

    const { MentionSuggestions, plugins } = useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        mentionComponent(mentionProps) {
          return (
            <span className={mentionProps.className} title={mentionProps.mention.email || mentionProps.mention.name}>
              {mentionProps.children}
            </span>
          );
        },
      });
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin];
      return { plugins, MentionSuggestions };
    }, []);
  
    const onOpenChange = useCallback((_open: boolean) => {
      setOpen(_open);
    }, []);
    
    const onSearchChange = useCallback(({ value }: { value: string }) => {
        setSuggestions(defaultSuggestionsFilter(value, mentions,"@"));
    }, [mentions]);

    const classes = useStyles();
  
    return (
        <div
        className={editorStyles.editor}
        onClick={() => {
          ref.current!.focus();
        }}
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          plugins={plugins}
          placeholder={placeholder}
          ref={ref}
          keyBindingFn={onKeyDown}
          handleKeyCommand={onHandleKeyCommand}
        />
        <Button onClick={onHandleSaveComment} className={classes.commentButtonStyle} color='secondary' variant='contained'>
          Comment 
          {/* <SendIcon className={classes.commentIconStyle} style={{fontSize:"18px"}}/> */}
        </Button>
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={(mention) => {
            // get the mention object selected
          }}
        />
      </div>
    );
  }