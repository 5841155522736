import { ISceneNode, SceneComponent } from '../../mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import * as THREE from 'three';
import { MimickScene } from './MimickScene';
import { BaseComponent } from '../Components/BaseComponent';
import { GLTFComponent } from '../Components/GLTFComponent';
import {DAEComponent} from '../Components/DAEComponent';
import {OBJComponent} from '../Components/OBJComponent';
import {TextBoxComponent} from '../Components/TextBoxComponent';
import {ArrowFlowComponent} from '../Components/ArrowFlowComponent';
import {FlowMarkerComponent} from '../Components/FlowMarkerComponent';
import {ImageRendererComponent} from '../Components/ImageRendererComponent';
import {RotateToggleComponent} from '../Components/RotateToggleComponent';
import {FireParticleSystemComponent} from '../Components/FireParticleSystemComponent';
import {WaterParticleSystemComponent} from '../Components/WaterParticleSystemComponent';

export class SceneNode implements ISceneNode {
    customSceneNode = true;

    constructor(protected mimickScene: MimickScene/*, object:any*/) {
        this.customComponents = [];
    }


    animatedPosition: THREE.Vector3;
    components: SceneComponent[];
    name: string;
    readonly position: THREE.Vector3;
    readonly quaternion: THREE.Quaternion;
    readonly scale: THREE.Vector3;
    unserializedUserData: { [p: string]: any };
    userData: { [p: string]: any };

    customComponents: BaseComponent[];

    addComponent(factory: string, initialInputs?: SceneComponent['inputs']): SceneComponent {
        // switch (factory) {
        //     case
        // }
        let component = this.mimickScene.componentInitTypeNameMap[factory]();
        console.log(component);

        return component;
    }

    addCustomComponent(component: BaseComponent): BaseComponent {
        // switch (factory) {
        //     case
        // }

        // if (component instanceof GLTFComponent ||
        //     component instanceof DAEComponent ||
        //     component instanceof OBJComponent ||
        //     component instanceof TextBoxComponent ||
        //     component instanceof  ArrowFlowComponent ||
        //     component instanceof  FlowMarkerComponent ||
        //     component instanceof  ImageRendererComponent ||
        //     component instanceof  RotateToggleComponent ||
        //     component instanceof  FireParticleSystemComponent ||
        //     component instanceof  WaterParticleSystemComponent ) {
        //     // @ts-ignore
        //     this['position'] = component.root.position;
        //     // @ts-ignore
        //     this['quaternion'] = component.root.quaternion;
        //     // @ts-ignore
        //     this['scale'] = component.root.scale;
        //
        //     // if(component instanceof TextBoxComponent) {
        //     //     component.inputs.text = this.userData.textProperty;
        //     // }
        // }

        // @ts-ignore
        this['position'] = component.root.position;
        // @ts-ignore
        this['quaternion'] = component.root.quaternion;
        // @ts-ignore
        this['scale'] = component.root.scale;

        component.node = this;
        this.customComponents.push(component);
        return component;
    }

    bindEvent(eventType: string, src: SceneComponent, srcEventType: string) {

    }

    componentIterator(): IterableIterator<SceneComponent> {
        return this.components.values();
    }

    start(): void {

        for(const c of this.customComponents){
            // c.assignUserData(this.userData);
            c.onInit(this.userData);
        }

        // if (this.name === 'gltf') {
        //     console.log('loaded gltf');
        // } else if (this.name === 'dae') {
        //     console.log('loaded dae');
        // } else if (this.name === 'obj') {
        //     console.log('loaded obj');
        // }  else {
        //     console.log('SceneNode::start not implemented :(');
        // }
    }

    stop(): void {
        for(const c of this.customComponents){
            c.onStop();
        }

        console.log('SceneNode::stop not implemented :(');
    }
}
