import { ConfirmationDialogProps } from '@crema/core/ConfirmationDialog';
import { Dispatch } from 'redux';
import { AppActions } from 'types';
import * as CommonAction from '../../types/actions/Common.action';

export const fetchStart = (loadingInfo?: string): CommonAction.CommonActionTypes => ({ type: CommonAction.FETCH_START, payload: loadingInfo || '' });

export const fetchStartNonBlocking = (loadingInfo: string): CommonAction.CommonActionTypes => ({ type: CommonAction.FETCH_START_NON_BLOCKING, payload: loadingInfo });

export const fetchSuccess = (): CommonAction.CommonActionTypes => ({ type: CommonAction.FETCH_SUCCESS });

// export const historyPush = (route: string): any => {
//   return (dispatch: Dispatch<AppActions>) => {
//     history.push()
//   }
// };

export const showStatus = (status: string): CommonAction.CommonActionTypes => {
  // return (dispatch: Dispatch<AppActions>) => {
  return ({ type: CommonAction.SHOW_STATUS, payload: status });
  // }
}

export const showMessagePersistent = (message: string): CommonAction.CommonActionTypes => {
  setTimeout(() => {
    return (dispatch: Dispatch<AppActions>) => {
      console.log('%c[st] [message] ' + message, 'color: blue');
      dispatch(_showMessage(""));
    }
  }, 6000);
  return _showMessage(message);
}

export const showMessage = (message: string): any => {
  return (dispatch: Dispatch<AppActions>) => {
    setTimeout(() => {
      console.log('%c[st] [message] ' + message, 'color: blue');
      dispatch(_showMessage("")); //clear message after a second, so the next message can be displayed

    }, 6000);
    return dispatch(_showMessage(message));
  }
}

export const fetchError = (error: string): any => {
  return (dispatch: Dispatch<AppActions>) => {

    setTimeout(() => {
      dispatch(_fetchError("")); //clear error message after a bit, so next error can be displayed
    }
      , 6000);
    console.error('[st] [error]' + error);
    return dispatch(_fetchError(error));
  };
}

const _fetchError = (error: string): CommonAction.CommonActionTypes => {
  return ({ type: CommonAction.FETCH_ERROR, error });

};

const _showMessage = (message: string): CommonAction.CommonActionTypes => ({
  type: CommonAction.SHOW_MESSAGE,
  message,
});

export const onToggleAppDrawer = (): CommonAction.CommonActionTypes => ({
  type: CommonAction.TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonAction.CommonActionTypes => ({ type: CommonAction.HIDE_MESSAGE });

export const setShowDictaphone = (value: boolean): CommonAction.CommonActionTypes => ({ type: CommonAction.SHOW_DICTAPHONE, payload: value });

export const showAlert = (alertProps: ConfirmationDialogProps): CommonAction.CommonActionTypes => ({ type: CommonAction.SHOW_ALERT, payload: alertProps });
export const hideAlert = (): CommonAction.CommonActionTypes => ({ type: CommonAction.HIDE_ALERT });
export const setHasNewVersion = (hasNewVersion: boolean): CommonAction.CommonActionTypes => ({ type: CommonAction.SET_HAS_NEW_VERSION, payload: hasNewVersion });