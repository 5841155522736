import * as THREE from 'three';
import { WebXRController, } from 'three';
import { RenderingSystem } from './Systems/RenderingSystem';
import { RaycastSystem } from './Systems/RaycastSystem';
import {CSS2DRenderer} from 'three/examples/jsm/renderers/CSS2DRenderer';

export class TagsARInteraction {
    //XR members
    protected reticle: THREE.Mesh | null = null;

    protected hitTestSource: THREE.XRHitTestSource | null = null;
    protected hitTestSourceRequested = false;

    protected anchor1Mode = true;
    protected anchorsCollectedAndAligned = false;

    protected anchor1: THREE.Mesh;
    protected anchor2: THREE.Mesh;
    protected cylinderGeometry: THREE.BufferGeometry;

    constructor(protected xrReferenceSpaceObj3D: THREE.Object3D, protected renderingSystem: RenderingSystem,
        protected raycastSystem: RaycastSystem,
        // protected grabbableSystem: GrabbableSystem
    ) {

        this.renderingSystem.renderer.xr.enabled = true;
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.setupXRControllers(this.renderingSystem); //for VR
        // alert('To begin, look down & point the circle at your space\'s origin point');

        // controller1 = renderer.xr.getController( 0 );
        // controller1.addEventListener( 'selectstart', onSelectStart );
        // controller1.addEventListener( 'selectend', onSelectEnd );
        // scene.add( controller1 );

        this.raycastSystem.setupForAR();
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectCallback(this.xrOnSelect.bind(this));


        this.reticle = new THREE.Mesh(
            new THREE.RingGeometry(0.15, 0.2, 32).rotateX(-Math.PI / 2),
            new THREE.MeshBasicMaterial({ color: '0xff0000' }),
        );
        this.reticle.matrixAutoUpdate = false;
        this.reticle.visible = false;
        this.renderingSystem.scene.add(this.reticle);

        this.cylinderGeometry = new THREE.CylinderGeometry(0.1, 0.1, 0.2, 32).translate(0, 0.1, 0);

        //get element by id xrOverlay


    }

    // private setUpDivs():void {
        // var rootDiv = document.getElementById('xrOverlay');


        // rootDiv?.appendChild(this.tagRenderer.domElement);



        // var testTag = document.createElement('testTag');
        // testTag.style.position = 'absolute';
        // testTag.style.color = 'red';
        // testTag.style.marginLeft = '10px';
        // testTag.style.marginTop = '10px';
        // testTag.textContent = 'Look down';
        // rootDiv?.appendChild(testTag);
    // }

    protected xrOnSelect(controller: WebXRController) {
        if (this.anchorsCollectedAndAligned) {
            console.log(`[st] [ar] anchors aligned`);

            // const session = this.renderingSystem.renderer.xr.getSession();

            // if ((session as any).domOverlayState) {
            //     console.log(`[st] [ar] domOverlayState: ${(session as any).domOverlayState.type}`);
            // } else {
            //     console.log("[st] [ar] DOM overlay not supported or enabled!");
            // }

            // let intersections = this.getIntersections()
            // this.raycastSystem.processClickIntersectionResults(intersections);
            // console.log(`[st] [ar] ${JSON.stringify(intersections)}`);

            // let rootDiv = document.getElementById('xrOverlay');
            //
            // if (!!rootDiv && !!i && i[0] && i[0]?.object) {
            //
            //     // var tagDiv = document.createElement('div');
            //     // // tagDiv.style.width = '80%';
            //     // // tagDiv.style.height = '80%';
            //     // tagDiv.style.backgroundColor = 'red';
            //     // tagDiv.textContent = JSON.stringify(i[0].object.userData);
            //     // rootDiv.appendChild(tagDiv);
            //
            //
            //     store.dispatch({ type: SET_EDIT_SHOWCASE_TAG_ID, payload: i[0].object.userData.tagId });
            //     store.dispatch({ type: SET_OPEN_TAG_FORM, payload: true });
            //     let tagFormDiv = document.getElementById('add-tag-form-div');
            //
            //     console.log(`[st] [ar] tagFormDiv is ${tagFormDiv}`)
            //     // let tagFormDiv = document.createElement('div');
            //
            //     // tagFormDiv.style.
            //
            //     tagFormDiv && rootDiv.appendChild(tagFormDiv);
            //
            //     tagFormDiv && (tagFormDiv.style.display = 'block');
            // } else if (!rootDiv) {
            //     console.error("[st] [ar] xrOverlay div not found");
            // }
            // else {
            //     console.error("[st] [ar] intersection not found " + JSON.stringify(i));
            // }


        } else {
            console.log(`[st] [ar] anchors not aligned`)
            if (this.reticle!.visible) {
                if (this.anchor1Mode) {
                    if (!this.anchor1) {
                        const material = new THREE.MeshPhongMaterial({ color: 0xffffff });
                        const mesh = new THREE.Mesh(this.cylinderGeometry, material);
                        mesh.position.setFromMatrixPosition(this.reticle!.matrix);
                        // mesh.position.x +=
                        this.anchor1 = mesh;
                        this.renderingSystem.scene.add(this.anchor1);
                        // alert("Great! Now point the circle at Origin2");
                    } else {
                        this.anchor1.position.setFromMatrixPosition(this.reticle!.matrix);
                    }
                    this.anchor1Mode = !this.anchor1Mode;
                } else {
                    if (!this.anchor2) {
                        const material = new THREE.MeshPhongMaterial({ color: 0xea3939 });
                        const mesh = new THREE.Mesh(this.cylinderGeometry, material);
                        mesh.position.setFromMatrixPosition(this.reticle!.matrix);
                        this.anchor2 = mesh;
                        this.renderingSystem.scene.add(this.anchor2);
                    } else {
                        this.anchor2.position.setFromMatrixPosition(this.reticle!.matrix);
                    }

                    this.anchor1Mode = !this.anchor1Mode;
                }

                if (this.anchor1 && this.anchor2) {
                    let forwardAxis = this.anchor2.position.clone().sub(this.anchor1.position.clone());
                    forwardAxis = forwardAxis.normalize();
                    let upAxis = new THREE.Vector3(0, 1, 0);
                    let rightAxis = upAxis.clone().cross(forwardAxis);
                    rightAxis = rightAxis.normalize();

                    //xrReferenceMatrix.setPosition(anchor1.position.x, anchor1.position.y, anchor1.position.z);
                    let xrReferenceMatrix = new THREE.Matrix4();
                    xrReferenceMatrix.makeBasis(rightAxis, upAxis, forwardAxis);
                    // let offsetPosition = new THREE.Vector3(1, 1, 1);
                    this.xrReferenceSpaceObj3D.position.set(this.anchor1.position.x, this.anchor1.position.y, this.anchor1.position.z);
                    this.xrReferenceSpaceObj3D.setRotationFromMatrix(xrReferenceMatrix);

                    this.anchorsCollectedAndAligned = true;
                    console.log('anchors collected');
                }
            }
        }
    }

    public dispose() {
        if (this.reticle) {
            this.renderingSystem.scene.remove(this.reticle);
            this.reticle.geometry.dispose();
            (this.reticle.material as THREE.MeshBasicMaterial).dispose();
            this.reticle = null;
        }
        this.hitTestSourceRequested = false;
        this.clearAllHitTestSources();
    }

    protected clearAllHitTestSources() {
        this.hitTestSourceRequested = false;
        this.hitTestSource = null;
    }

    protected onSessionRequestReferenceSpace(session: XRSession, referenceSpace: XRReferenceSpace) {
        session.requestHitTestSource({ space: referenceSpace }).then((source) => {
            this.onRequestHitSource(source);
        });

    }

    protected onRequestHitSource(source: THREE.XRHitTestSource) {
        this.hitTestSource = source;
    }

    public xrRender(frame: any) {


        if (frame) {
            const referenceSpace = this.renderingSystem.renderer.xr.getReferenceSpace();
            const session = this.renderingSystem.renderer.xr.getSession();

            if (session) {

                if (!this.hitTestSourceRequested) {

                    session.requestReferenceSpace('viewer').then((referenceSpace) => {
                        this.onSessionRequestReferenceSpace(session, referenceSpace);
                    });

                    session.addEventListener('end', this.clearAllHitTestSources.bind(this));
                    this.hitTestSourceRequested = true;

                }

                if (this.hitTestSource) {

                    const hitTestResults = frame.getHitTestResults(this.hitTestSource);

                    if (hitTestResults.length) {
                        const hit = hitTestResults[0];
                        this.reticle!.visible = true;
                        this.reticle!.matrix.fromArray(hit.getPose(referenceSpace).transform.matrix);

                    } else {
                        this.reticle!.visible = false;
                    }

                }
            }

        }
    }
}
