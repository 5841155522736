import { TextField, Avatar, Badge, Box, Button, ClickAwayListener, Fab, IconButton, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip, Typography, MenuItem, Select, Divider, FormControl, InputLabel } from "@material-ui/core";
import { store } from "App";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store"
import { SHOW_POSES } from "types/actions/ThreeD.actions"
import CancelIcon from '@material-ui/icons/Cancel';
import Spacer from "@crema/core/Spacer";
import shortUUID from "short-uuid";
import { SpacePose } from "types/models/home/HomeApp";
import EditableTextField from "./LessonSidebar/EditableTextField";
import { getCurrentSweep, goToPose } from "./TagSidebar/Tags/ShowcaseUtils";
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { setSpacePoses } from "redux/actions/Home";
import AddIcon from '@material-ui/icons/Add';
import ReplayIcon from '@mui/icons-material/Replay';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import CameraRoundedIcon from '@mui/icons-material/CameraRounded';
import { showMessage } from "redux/actions";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { HelpBadge } from "@crema/core/HelpBage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            '& .MuiListItemAvatar-root': {
                'minWidth': '36px'
            },
            '& .MuiList-padding': {
                paddingBottom: '8px'
            }
        },
        icons: {
            padding: '4px',
        },
        badgeRoot: {
            // paddingLeft: '180px',
            // width: "100%"
            backgroundColor: 'yellow'
        }
    }));

export default function PoseList() {
    const showPoses = useSelector<AppState, boolean>(({ threeD }) => threeD.showPoses);
    const poses = useSelector<AppState, SpacePose[]>(({ home }) => home?.currentSpace?.poses || []);
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    return (console.log(`%c[st] rendering PoseList`, 'color: orange;'), showPoses ?
        <div style={{ position: 'absolute', zIndex: 99, height: "100%" }}>

            <div style={{
                backgroundColor: theme.palette.background.default,
                minWidth: '320px',
                maxWidth: '320px',
                maxHeight: '100%',
                height: "100%", padding: '16px', width: "100%",
                // backgroundColor: 'red'
            }}>
                {/* <ClickAwayListener
                    // style={{width: "100%"}}
                    onClickAway={() => { dispatch({ type: SHOW_POSES, payload: false }) }}> */}

                <div style={{

                    // backgroundColor: 'blue'
                    // width: "100%",
                }}>

                    {/* <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        className={classes.badgeRoot}
                        badgeContent={
                        // <IconButton color="primary" size="small">
                            <Tooltip title={'Close and go back'}>
                                <Link
                                    // to='/signin'
                                    component="button"
                                    variant="body2"
                                    underline='always'
                                    style={{ cursor: 'pointer' }}
                                    // className={clsx(
                                    //   classes.underlineNone,
                                    //   classes.textSecondary,
                                    // )}
                                    // >
                                    // variant='text'
                                    onClick={() => {
                                        store.dispatch({ type: SHOW_POSES, payload: false })
                                    }} >BACK
                                </Link>
                            </Tooltip>
                        // </IconButton>
                        }> */}
                    <Tooltip title={'Back'}>
                        <IconButton onClick={() => {
                            store.dispatch({ type: SHOW_POSES, payload: false })
                        }}>
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Tooltip>

                    {/* <Link
                            component="button"
                            variant="body2"
                            underline='always'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                store.dispatch({ type: SHOW_POSES, payload: false })
                            }} >BACK
                        </Link> */}
                    <Box style={{
                        // width: "100%",
                        // backgroundColor: 'green',
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    }}>
                        <Typography
                            style={{ textAlign: 'center', }}
                            variant='h6'
                        // className={classes.heading}
                        >
                            Viewpoints
                        </Typography>
                        <Spacer y={4} />
                        <Tooltip title='Add New Viewpoint'>
                            <Fab size="small" color="secondary"
                                onClick={async () => {
                                    let id = shortUUID.generate();
                                    let p: SpacePose = { id: id, data: await getCurrentSweep(), label: 'New Viewpoint' }
                                    dispatch(setSpacePoses(poses.concat(p)));
                                }}>
                                <AddIcon fontSize='small' />
                            </Fab>
                        </Tooltip>

                        <Spacer y={2} />

                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                            {/* <Box>Default Viewpoint</Box> */}

                            <FormControl variant="filled" style={{ margin: 1, width: '100%' }}>
                                <InputLabel id="pose-select">Default Viewpoint</InputLabel>
                                <Select
                                    label="Default Viewpoint"
                                    labelId="default-pose"
                                    id="default-pose"
                                    value={poses.find(p => !!p.isDefault)?.id}
                                    style={{ fontSize: '13px', width: '100%' }}
                                    // fullWidth
                                    onChange={(event: any) => {
                                        poses.forEach(p => p.isDefault = false);
                                        let dp = poses.find(p => p.id == event.target.value)
                                        dp && (dp.isDefault = true);
                                        dispatch(setSpacePoses(poses));
                                    }}
                                >
                                    {/* <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem> */}
                                    {poses?.map((p, i) => <MenuItem key={i} style={{ fontSize: '13px' }} value={p.id}>{p.label}</MenuItem>)}

                                </Select>
                            </FormControl>
                            <HelpBadge tooltip='Used as your starting viewpoint in AR. Clicking the Home button in the viewer also takes you here' />
                        </Box>

                        <Spacer y={2} />
                        <Divider />

                        <div style={{
                            // backgroundColor: theme.palette.background.paper,
                            // backgroundColor: 'pink',
                            borderRadius: '16px',
                            width: "100%"
                        }}>
                            <List className={classes.list}
                            >
                                {poses.map((p: SpacePose, i) => {
                                    return <ListItem key={i} disableGutters style={{ backgroundColor: 'white', borderRadius: '16px' }}>
                                        <Spacer x={2} />
                                        <ListItemAvatar>
                                            <Tooltip title='Navigate to Viewpoint'>
                                                <IconButton key={i} onClick={async () => await goToPose(p.data)} className={classes.icons}>
                                                    <DoubleArrowRoundedIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemAvatar>
                                        <EditableTextField
                                            save={(value) => {
                                                if (p.label !== value) {
                                                    p.label = value;
                                                    dispatch(setSpacePoses(poses));
                                                }
                                            }}
                                            saveOnEnter
                                            style={{ width: "100%" }}
                                            multiline={false}
                                            // value={p.label}
                                            initValue={p.label}
                                            placeholder={''}
                                        />
                                        <Spacer x={2} />

                                        <ListItemSecondaryAction>

                                            <Tooltip title='Update Current Viewpoint'>
                                                <IconButton key={i} className={classes.icons} color='secondary'
                                                    onClick={async () => {
                                                        getCurrentSweep().then((sweepData) => {
                                                            p.data = sweepData;
                                                            dispatch(setSpacePoses(poses));
                                                            dispatch(showMessage('Saved!'));
                                                        })
                                                    }}>
                                                    <CameraRoundedIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>


                                            <Tooltip title='Delete Viewpoint'>
                                                <IconButton key={i} className={classes.icons} onClick={() => {
                                                    poses.splice(i, 1);
                                                    dispatch(setSpacePoses(poses));
                                                }}>
                                                    <HighlightOffRoundedIcon />
                                                </IconButton>
                                            </Tooltip>

                                            {/* <Tooltip title='Make Default'>
                                                <span>
                                                    <RoundCheckbox style={{ padding: 0 }}
                                                        checked={!!poses[i].isDefault}
                                                        onChange={(e: any) => {
                                                            p.isDefault = e.target.checked;

                                                            let defaultPosesSize = poses.filter(p => !!p.isDefault).length;
                                                            if (defaultPosesSize > 1) {
                                                                alert("You can only have 1 Default viewpoint!");
                                                                p.isDefault = !e.target.checked;

                                                            } else if (defaultPosesSize == 0) {
                                                                alert("You need at least 1 Default viewpoint!");
                                                                p.isDefault = !e.target.checked;
                                                            } else {
                                                                dispatch(setSpacePoses(poses));
                                                            }
                                                        }
                                                        }
                                                    />
                                                </span>
                                            </Tooltip> */}
                                        </ListItemSecondaryAction>

                                    </ListItem>
                                })}
                            </List>
                        </div>
                        {/* {poses.map((p: SpacePose, i) => {
                                    return (
                                        <Box key={i}
                                            style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <EditableTextField save={(value) => {
                                                if (p.label !== value) {
                                                    p.label = value;
                                                    dispatch(setSpacePoses(poses));
                                                }
                                            }} style={{ width: "100%" }} multiline={false} initValue={p.label} placeholder={''} />

                                            <Box key={i}
                                                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Tooltip title='Navigate to Viewpoint'>
                                                    <IconButton key={i} onClick={async () => await goToPose(p.data)} className={classes.icons}>
                                                        <DoubleArrowRoundedIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title='Update Current Viewpoint'>
                                                    <IconButton key={i} className={classes.icons}
                                                        onClick={async () => {
                                                            getCurrentSweep().then((sweepData) => {
                                                                p.data = sweepData;
                                                                dispatch(setSpacePoses(poses));
                                                            })
                                                        }}>
                                                        <ReplayIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>


                                                <Tooltip title='Delete Viewpoint'>
                                                    <IconButton key={i} className={classes.icons} onClick={() => {
                                                        poses.splice(i, 1);
                                                        dispatch(setSpacePoses(poses));
                                                    }}>
                                                        <HighlightOffRoundedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    )
                                })

                                }*/}
                    </Box>
                    {/* </Badge> */}
                </div>
                {/* </ClickAwayListener> */}
            </div>

        </div> : <></>)
}