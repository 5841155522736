import { RenderingSystem } from '../Systems/RenderingSystem';
import { BaseComponent } from './BaseComponent';
import { SpatialThinkSDK } from '../SpatialThinkSDK';
import * as THREE from 'three';
import { SceneNode } from '../Mimick/SceneNode';
import Simulation from '../../mp/core/craEngine/SubSystems/core/Simulation';
import {
    CanvasTexture,
    CylinderGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    RepeatWrapping, SphereGeometry,
    Vector3,
} from 'three';
import { FrontSide } from 'three/src/constants';
import { MeshUserDataProperties } from '../../mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import { FlowMarkerComponent } from './FlowMarkerComponent';
import { Interactable } from './Others/Interactable';
import { AddObjectClickSpy } from 'mp/core/craEngine/spies/AddObjectClickSpy';
import { store } from 'App';
import {RaycastSystem} from '../Systems/RaycastSystem';
interface Inputs {
    activate: boolean,
    arrowColor: string,
    flowRadius: number,
}

export class ArrowFlowComponent extends BaseComponent {
    onDestroy(): void {
        console.error('onDestroy not implemented');
    }

    private tagList: any[] = [];
    private tagListPositions: THREE.Vector3[];
    private box: Object3D | null = null;
    private isPlaying: boolean = false;

    private colorR: number = 0;
    private colorG: number = 0;
    private colorB: number = 0;
    private arrowScale: number = 1;

    private timeVal: number = 0;
    private boxUpdateTimer: number = 0;

    private spheres: THREE.Mesh[] = [];
    // private interactableSphere: Interactable;

    inputs: Inputs = {
        activate: false,
        arrowColor: '0x0000ff',
        flowRadius: 1.0,
    };

    public getInputs() {
        return this.inputs;
    }


    onInputsUpdated(oldInputs: Inputs, force:boolean) {
        this.makeBox();
    }

    // setInputs(newInputs: Partial<Inputs>) {
    //     let previousInputs = Object.assign({}, this.inputs);
    //     Object.assign(this.inputs, newInputs);
    //     this.onInputsUpdated(previousInputs);
    // }

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = 'ArrowFlowComponent';
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onTick.bind(this));

        this.tagListPositions = [];
        this.tagList = [];
        // this.interactableSphere = new Interactable();
    }

    onInit(userdata: { [p: string]: any }): void {
        super.onInit(userdata);
        this.box = new THREE.Object3D();
        this.inputs.activate = true;
        this.isPlaying = false;
        // this.node && this.setLoaded(true);
        // this.makeBox();
        // this.interactable.addOnClick(this.onClick.bind(this));
        // this.interactableSphere.addAction(this.onClick.bind(this));
    }

    onClick() {

        console.log(`[st] [Simulation] Arrow flow clicked`, this.box?.position, this.root.position, this.node.position);
        AddObjectClickSpy.executeClick(this.node);


        // this.inputs.state++;

        // if (this.inputs.state >= this.inputs.rotationRange.length) {
        //     this.inputs.state = 0;
        // }

        // this.prepareClips();
        // this.currentAnimationTime = 0;
    }

    private makeBox() {
        if (this.box) {
            this.renderingSystem.base.remove(this.box);
            this.box.clear();

            //remove all spheres from root
            for (let i = 0; i < this.spheres.length; i++) {
                this.renderingSystem.base.remove(this.spheres[i]);
            }
            this.spheres = [];
        }

        if (this.inputs.activate) {
            let newMeshScale = this.inputs.flowRadius * 0.5;
            if (this.node.userData?.markers) {
                for (let i = 0; i < this.node.userData.markers.length; i++) {
                    let marker = Simulation.instance.sceneLoader.findNodeByID(this.node.userData.markers[i].id) as SceneNode;
                    if (marker) {
                        let markerObj3D = (marker.customComponents[0] as FlowMarkerComponent).root;
                        markerObj3D.scale.set(newMeshScale, newMeshScale, newMeshScale);
                    }
                }
            }

            //

            let localIndex = this.node.userData?.markers?.length;
            while (localIndex--) {
                let marker = Simulation.instance.sceneLoader.findNodeByID(this.node.userData.markers[localIndex].id) as SceneNode;
                if (marker) {
                    // let markerNode = marker.obj3D as SceneNode;
                } else {
                    this.node.userData?.markers.splice(localIndex, 1);
                }
            }

            this.getRGBFromString(this.inputs.arrowColor);
            this.tagListPositions = [];
            this.tagList = [];
            if (this.node.userData?.markers) {
                this.tagList = this.tagList.concat(this.node.userData?.markers);
                this.tagList.reverse();
                this.tagList.push({
                    position: this.node.position,
                    id: this.node.userData['id'],
                });
            }

            // setting boxcomponent
            if (this.tagList) {

                // make a texture with an arrow
                const ctx: CanvasRenderingContext2D = document.createElement('canvas').getContext('2d')!;
                ctx.canvas.width = 64;
                ctx.canvas.height = 64;

                ctx.fillStyle = 'rgba(0,0,0,0)';
                ctx.fillRect(0, 0, 64, 64);

                ctx.translate(32, 32);
                ctx.rotate(Math.PI * .5);
                ctx.fillStyle = `rgb(${this.colorR},${this.colorG},${this.colorB})`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'hanging';
                ctx.font = '36px sans-serif';
                let totalArrows: number = 5;
                for (var i = -1; i < totalArrows - 1; i++) {
                    ctx.fillText('➡︎', 0, -13 * i);
                }

                ctx.fillText('➡︎', 0, 0);

                ctx.fillStyle = 'rgba(1,0,0,0)';
                ctx.fillRect(0, 0, 64, 64);

                const texture = new CanvasTexture(ctx.canvas);
                texture.wrapS = RepeatWrapping;
                texture.wrapT = RepeatWrapping;
                texture.repeat.x = 1;
                texture.repeat.y = 5;


                let isFirst: boolean = true;
                let prePosition: Vector3 = new Vector3(0, 0, 0);

                const arrayTag: any[] = [];
                let flg = true;

                this.tagList.forEach(tag => {
                    this.tagListPositions.push(new THREE.Vector3(tag.position.x, tag.position.y, tag.position.z));
                    tag.position = Simulation.instance.sceneLoader.findNodeByID(tag.id)?.position;
                    if (isFirst == true) {
                        isFirst = false;
                        prePosition = tag.position;
                        this.spheres.push(this.makeSphere(tag.position, this.inputs.flowRadius * 0.05));
                        this.outputs.collider = this.spheres[0];
                        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.spheres[0]);
                        return;
                    }

                    const distance = Math.sqrt((tag.position.x - prePosition.x) * (tag.position.x - prePosition.x) + (tag.position.y - prePosition.y) * (tag.position.y - prePosition.y) + (tag.position.z - prePosition.z) * (tag.position.z - prePosition.z));

                    const texture = new CanvasTexture(ctx.canvas);
                    texture.wrapS = RepeatWrapping;
                    texture.wrapT = RepeatWrapping;
                    texture.repeat.x = 1;
                    texture.repeat.y = Math.floor(distance / (0.15 * this.arrowScale));

                    const boxGeometry: CylinderGeometry = new CylinderGeometry(this.inputs.flowRadius * 0.05, this.inputs.flowRadius * 0.05, distance, 20, 2, true);
                    const boxMaterial = new MeshPhongMaterial({
                        map: texture,
                        side: FrontSide,
                        transparent: true,
                    });
                    const boxMesh = new Mesh(boxGeometry, boxMaterial);

                    boxMesh.position.x = (tag.position.x + prePosition.x) / 2;
                    boxMesh.position.y = (tag.position.y + prePosition.y) / 2;
                    boxMesh.position.z = (tag.position.z + prePosition.z) / 2;

                    boxMesh.lookAt(new Vector3(tag.position.x, tag.position.y, tag.position.z));
                    boxMesh.rotateX(Math.PI / 2);



                    prePosition = tag.position;

                    boxMesh.userData['distance'] = Number.POSITIVE_INFINITY;
                    boxMesh.userData['goal'] = 0;

                    // console.log(`[st] [Simulation] ArrowFlow boxmesh`, boxMesh.position, boxMesh);
                    // @ts-ignore

                    // let group = new THREE.Group();
                    // group.add(boxMesh);
                    // group.add(circle);
                    // this.box && this.box.add(group);
                    this.box?.add(boxMesh);
                    boxMesh.userData[MeshUserDataProperties.arrowFlow] = true;

                });

                if (!store.getState().layer?.presentationMode) {
                    for (let i = 0; i < this.spheres.length; i++) {
                        this.renderingSystem.base.add(this.spheres[i]);
                    }
                } else {
                    this.spheres.map(s => s.visible = false); //TODO-ST
                }

                this.renderingSystem.base.add(this.box!);
                // this.root?.scale.set(newMeshScale * 0.05, newMeshScale * 0.05, newMeshScale * 0.05);
                this.isPlaying = true;
            }
        }
        // this.node && this.setLoaded(true);
    }

    //Write method for this.makeSphere, that creates a threejs Sphere
    makeSphere(position: Vector3, radius: number): Mesh {
        const geometry = new SphereGeometry(radius, 32, 32);
        const material = new MeshPhongMaterial({ color: 0xff00ff });
        const sphere = new Mesh(geometry, material);
        sphere.position.set(position.x, position.y, position.z);

        // sphere.userData[Interactable.type] = this.interactableSphere;
        return sphere;
    }

    onTick(delta: number): void {
        this.timeVal += delta;

        if (this.inputs.activate == true && this.isPlaying == false) {
            this.makeBox();
        }

        // console.log(this.timeVal);

        if (this.boxUpdateTimer < 1) {
            this.boxUpdateTimer += delta;
        } else {
            this.boxUpdateTimer = 0;
            // let newTagList = this.context.root.userData?.markers || [];

            let newTagList: any[] = [];
            if (this.node.userData?.markers) {
                newTagList = newTagList.concat(this.node.userData?.markers);
                newTagList.reverse();
                newTagList.push({
                    position: this.node.position,
                    id: this.node.userData['id'],
                });
            }
            if (newTagList && this.tagListPositions) {
                // newTagList.reverse();
                if (newTagList.length != this.tagListPositions.length) {
                    this.makeBox();
                } else {
                    for (let i = 0; i < newTagList.length; i++) {
                        let oldTagPos = this.tagListPositions[i] as THREE.Vector3;
                        let newTagPos = newTagList[i].position as THREE.Vector3;

                        let dist = oldTagPos.manhattanDistanceTo(newTagPos);
                        if (dist > 0.1) {
                            this.makeBox();
                            break;
                        }
                    }
                }
            }
        }

        if (this.isPlaying == true) {

            if (this.node.scale.distanceTo(new Vector3(0, 0, 0)) / 1.4 !== this.arrowScale) {
                this.arrowScale = this.node.scale.distanceTo(new Vector3(0, 0, 0)) / 1.4;
                this.makeBox();
            }
            if (this.box) {
                // const tmpPosition: Object3D = new Object3D();
                this.box.traverse((object) => {
                    if ((object as Mesh).isMesh) {
                        if ((this.timeVal * 3 % 1) > 1) {
                            this.timeVal = 0;
                        }
                        const c = object as Mesh;
                        (c.material as MeshBasicMaterial)!.map!.offset.y = (this.timeVal * 3 % 1);
                    }
                });
                // dispose
            }
        }
    }

    hide(): void { //TODO-ST do we need this?
        // (this.box) && (this.box.visible = false);
        // for (const s of this.spheres) {
        //     s.visible = false;
        // }
    }

    getRGBFromString(colorString: string) {
        const colorNumber: number = parseInt(colorString);

        this.colorB = colorNumber % 256;
        this.colorG = Math.floor(colorNumber / 256) % 256;
        this.colorR = Math.floor(colorNumber / 65536) % 256;
    }
}