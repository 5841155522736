import React, { } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Switch, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import HomeIcon from '@material-ui/icons/Home';
import { OverflowMenu } from '../../../components/OverflowMenu';
import Simulation, { ExternalSdkMode } from 'mp/core/craEngine/SubSystems/core/Simulation';
import { AppState } from 'redux/store';
import InputSubSystem from 'mp/core/craEngine/SubSystems/input/InputSubSystem';
import { canEditSpace } from '@crema/utility/Utils';
import { Lesson, SpaceRole } from 'types/models/home/HomeApp';
import { store } from 'App';
import { addTagsToEmptyShowcase, getCurrentTagGroup, isUserAdmin } from '../../utils';
import { goToPoseById } from '../Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { CremaTheme } from 'types/AppContextPropsType';
import IconHover from '@crema/core/AppAnimation/ItemHover/IconHover';
import { showMessage, updateCurrentLesson } from 'redux/actions';
import { setLockView } from 'redux/actions/Home';
import { GizmoOnIcon, LockedViewIcon, UnlockedViewIcon } from '@crema/core/Icons/CustomIcons';
import * as Icons from '@mui/icons-material';
import { SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import { setSDKMode } from 'redux/actions/ThreeD';

const useStyles = makeStyles((theme: CremaTheme) => ({
  bar: {
    left: '0px',
    bottom: '140px',
    display: 'flex',
    position: 'absolute',

    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    zIndex: 5,
    pointerEvents: 'none',

    // left: '30px',
    // left: 'calc(1vw + 28px)',
    // width: '5%',
    // bottom: '60px',

    // // marginLeft: "40px"
    // // display: 'flex',
    // // flexDirection: 'row',
    // // alignItems: 'center'

  },
  root: {
    // width: '600px',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

  },
  iconRowOld: {
    display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'center',
    pointerEvents: 'auto',
  },
  iconRow: {
  },
  threeDotsIcon: {
    paddingBottom: '2px'
    // margin: '4px',
    // paddingTop: '2px',
    // color: theme.palette.secondary.main,
    // cursor: 'pointer',
    // height: "30px",
    // width: "30px",
    // '&:hover, &:focus': {
    //   color: '#FF3158',
    // },
  },
  lockIcon: {
    '& .MuiCheckbox-root': {
      padding: '0px'
    }
  }
}));

interface Props {
  goToHomeSweep: () => void;
  lockViewDefault: boolean;
  hide3DToolsDefault: boolean;
  currentLesson: Lesson | null;
}

const ShortCutPanel: React.FC<Props> = ({ goToHomeSweep, lockViewDefault, hide3DToolsDefault, currentLesson
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const spaceMembershipRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home.currentSpace?.spaceMembership?.role);
  // const currentLesson = useSelector<AppState, Lesson | null>(({ layer }) => layer.currentLesson);
  const presentationMode = useSelector<AppState, boolean>(({ layer }) => layer.presentationMode);
  // const [lockView, setLockView] = React.useState(lockViewDefault);
  const lockView = useSelector<AppState, boolean>(({ home }) => home.lockView);

  const [propertyPanelShowOverride, setPropertyPanelShowOverride] = React.useState<boolean>(true);

  const theme = useTheme();
  Simulation.instance.lockViewState = [lockView, setLockView];
  Simulation.instance.propertyPanelShowOverride = [propertyPanelShowOverride, setPropertyPanelShowOverride];

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow placement='top' classes={classes} {...props} />;
  }
  // React.useEffect(() => {
  //   let setTo = !hide3DToolsDefault ? propertyPanelShowOverride : !propertyPanelShowOverride
  //   setPropertyPanelShowOverride(setTo)
  // }, [Simulation.instance])

  React.useEffect(() => {

    if (!(spaceMembershipRole && canEditSpace(spaceMembershipRole))) {
      setPropertyPanelShowOverride(false);
      console.log(`[st] 1 setPropertyPanelShowOverride `);

    } else {
      let setTo = !hide3DToolsDefault ? propertyPanelShowOverride : !propertyPanelShowOverride;
      console.log(`[st] 2 setPropertyPanelShowOverride ${setTo}`);
      setPropertyPanelShowOverride(setTo);
    }

  }, [Simulation.instance, spaceMembershipRole]);

  //TODO does this break things?
  // React.useEffect(() => {
  //   console.log(`[st] locking view ${isAddingTag}`);
  //   setLockView(isAddingTag);
  // }, [isAddingTag]);

  // const isUserAdmin = useUserAdmin();

  React.useEffect(() => {
    if (!store.getState().home.currentSpace?.settings?.useFreeCamera) {
      InputSubSystem.input && InputSubSystem.input.setMatterPortCameraMovement(!lockView);
    }
  }, [InputSubSystem.input, lockView]);

  return (console.log(`%c[st] rendering ShortcutPanel`, 'color: orange;'),
    <Box className={classes.bar} id='shortcut-panel'>
      <Shortcuts3D open={open} setOpen={setOpen} />
      {/* <FormControlLabel
        style={{
          pointerEvents: 'auto',
        }}
        control={ */}

      {/* }
        label="Lock View"
      />  */}
      <div className={classes.iconRowOld} >
        {/* <BootstrapTooltip title='Add with Voice' style={{visibility: 'hidden'}}>
          <KeyboardVoiceIcon
            onClick={() => {
              if (document.activeElement && document.activeElement.constructor.name == "HTMLInputElement"){
                if((document.activeElement as HTMLInputElement).type == 'text'){
                  (document.activeElement as HTMLInputElement).value = 'this is a test'
                }

              }
              setDictaphoneOpen(!isDictaphoneOpen);

            }}
            className={classes.showcaseIcon}
            fontSize='small'
          />
        </BootstrapTooltip> */}
        {!presentationMode && <IconHover tooltip={`${lockView ? 'Unlock movement (or press M)' : 'Lock movement (press N)'} `} >
          <Box
            className={classes.lockIcon}
            style={{
              pointerEvents: 'auto',
              // backgroundColor: 'white'
            }}>
            <Checkbox
              checked={lockView}
              icon={<UnlockedViewIcon style={{ height: '35px', width: '35px' }} />}
              checkedIcon={<LockedViewIcon style={{ height: '35px', width: '35px' }} />}
              size="small"
              onChange={(e: any) => {
                // spaceModels.size > 0 &&
                // InputSubSystem.input.setMatterPortCameraMovement(e.target.checked);
                setLockView(e.target.checked);
              }}
              name="lockViewSwitch"
              color="primary"
            />
          </Box>
        </IconHover>}
        {/* {!presentationMode && <IconHover tooltip='Keyboard Shortcuts' >
          <HelpOutlineIcon
            onClick={() => {
              setOpen(!open);
            }}
          // fontSize='small'
          />
        </IconHover>} */}

        {/* <HomePosition goToHomeSweep={goToHomeSweep} /> */}

        {!presentationMode && <IconHover tooltip='Go to Home position'>
          <HomeIcon
            onClick={() => goToHomeSweep ? goToHomeSweep() : {}}
          // fontSize='small'
          />
        </IconHover>}
        {presentationMode && <IconHover tooltip='Back to viewpoint'>
          <Icons.ReplayRounded
            onClick={() => {
              let poseId = getCurrentTagGroup()?.poseId;
              if (!poseId) {
                dispatch(showMessage("No viewpoint to go to at this time"))
              } else {
                goToPoseById(poseId);
              }
            }}
          />
        </IconHover>
        }

        {/* <ZoomIn /> */}
        {!presentationMode && <IconHover tooltip='More Options'>
          <div
            className={classes.threeDotsIcon}
          >
            <OverflowMenu buttonStyle={{ color: theme.palette.secondary.main }}
              propIcon={<Icons.MoreHoriz />}
              menuItems={[
                // {
                //   component: <Box display="inline-flex" alignItems="center">
                //     <Checkbox
                //       checked={propertyPanelShowOverride}
                //       icon={< GizmoOnIcon style={{ height: '25px', width: '25px' }} />}
                //       checkedIcon={<GizmoOffIcon style={{ height: '25px', width: '25px' }} />}
                //       size="small"
                //       onChange={(e: any) => {
                //         setPropertyPanelShowOverride && setPropertyPanelShowOverride(!propertyPanelShowOverride);
                //       }}
                //       name="propPanelShowSwitch"
                //       color="primary"
                //     />
                //     Show 3D tools
                //   </Box>

                // },
                // {
                //   component: <Box display="inline-flex" alignItems="center">
                //     <Checkbox
                //       checked={!currentLesson?.hideVariablesPanel}
                //       icon={< VariablesOnIcon style={{ height: '25px', width: '25px' }} />}
                //       checkedIcon={<VariablesOffIcon style={{ height: '25px', width: '25px' }} />}
                //       size="small"
                //       onChange={(e: any) => {
                //         currentLesson && dispatch(updateCurrentLesson({ ...currentLesson, hideVariablesPanel: !currentLesson?.hideVariablesPanel } as Lesson))
                //       }}
                //       name="showVariablesSwitch"
                //       color="primary"
                //     />
                //     Show Variables
                //   </Box>

                // },
                {
                  component: <Box display="inline-flex" alignItems="center">
                    <FormControlLabel
                      style={{
                        // textAlign: "center",
                        // marginBottom: '10px',
                        // display: 'block'
                      }}
                      control={
                        <Switch
                          size="small"
                          checked={propertyPanelShowOverride}
                          onChange={(e: any) => {
                            setPropertyPanelShowOverride && setPropertyPanelShowOverride(!propertyPanelShowOverride);
                          }}
                          name="propPanelShowSwitch"
                          color="primary"
                        />
                      }
                      label="Show 3D tools"
                    />
                    < GizmoOnIcon style={{ height: '20px', width: '20px' }} />
                  </Box>
                },
                {

                  component: <FormControlLabel
                    style={{
                    }}
                    control={
                      <Switch
                        size="small"
                        checked={!currentLesson?.hideVariablesPanel}
                        onChange={(e) => {
                          currentLesson && dispatch(updateCurrentLesson({ ...currentLesson, hideVariablesPanel: !currentLesson?.hideVariablesPanel } as Lesson))
                        }}
                        name="showVariablesSwitch"
                        color="primary"
                      />
                    }
                    label="Show Variables"
                  />
                },
                {
                  title: "Hide all tags", onClick: () => { addTagsToEmptyShowcase({ tagIds: [] }) }
                  // title: "Hide all tags", onClick: () => {dispatch(setCurrentShowcaseTags([]))}
                },
                { title: "Hide all 3D", onClick: () => { Simulation.instance.filterVisibleNodes([]) } },
                { title: "Show all tags", onClick: () => { addTagsToEmptyShowcase({ tagIds: Object.keys(store.getState().home.spaceTags) || [] }) } },
                // {title: "Show all tags", onClick: () => {dispatch(setCurrentShowcaseTags(Object.values(store.getState().home.spaceTags) || []))} },
                { title: "Show all 3D", onClick: () => { Simulation.instance.filterVisibleNodes(Array.from(store.getState().home.spaceModels?.keys())) } },

              ]} />

          </div>
        </IconHover>}
      </div>
    </Box>
  );
};

export default React.memo(ShortCutPanel);

// function ZoomIn() {

//   const [showSlider, setShowSlider] = React.useState(false);
//   // const [zoom, setZoom] = React.useState(0.1);
//   const classes = useStyles();

//   let zoom = 1;
//   MpSdk?.Instance?.mps?.Camera.zoom.subscribe(function (z: any) {
//     console.log('[vars] Current zoom is ', zoom);
//     // setZoom(zoom);
//     zoom = z;
//   });

//   return <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

//     {showSlider && <ClickAwayListener onClickAway={() => setShowSlider(false)}>
//       <Stack style={{ height: 100 }} spacing={1} direction="column">
//         <Slider
//           aria-label="Zoom"
//           defaultValue={zoom}
//           orientation="vertical"
//           // getAriaValueText={(v, i) => (v*33.33).toString()}
//           valueLabelDisplay="auto"
//           onChange={(e, v) => {
//             console.log(`[vars] zooming to ${v}`);
//             v && MpSdk?.Instance?.mps?.Camera.zoomTo(v).catch(console.error).then(() => {
//               console.log(`[vars] done zooming`);

//             })
//           }}
//           step={0.1}
//           min={0}
//           max={3}
//         />
//       </Stack>
//     </ClickAwayListener>}
//     <ZoomInIcon
//       onClick={() => setShowSlider(!showSlider)}
//       className={classes.showcaseIcon}
//     // fontSize='small'
//     />

//   </Box>
// }

export function Shortcuts3D({ open, setOpen }: { open: boolean, setOpen: (value: boolean) => void }) {
  const classes = useStyles();
  return (<div className={classes.root} style={{ pointerEvents: 'none' }}>
    <Collapse in={open}>
      <Alert
        severity='info'
        action={
          <IconButton
            style={{ pointerEvents: 'all' }}
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              setOpen(false);
            }}>
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }>
        Keyboard shortcuts for 3D objects
        <br /><br />

        <p><b>▪️ To Move (by 1 inch): </b> Press Keys
          <span style={{ color: 'green' }}> G / </span>
          <span style={{ color: 'blue' }}>B / </span>
          <span style={{ color: 'red' }}>R  </span>
          or
          <b> Shift</b> +   <span style={{ color: 'green' }}> G / </span>
          <span style={{ color: 'blue' }}>B / </span>
          <span style={{ color: 'red' }}>R  </span> for the opposite direction
          <br /> -- (<span style={{ color: 'green' }}>G for Green</span>/
          <span style={{ color: 'blue' }}>B for Blue </span>/
          <span style={{ color: 'red' }}>R for Red </span> for corresponding X/Y/Z axes )

        </p>
        <br />
        <p><b>▪️ To Rotate: </b> <b>Option</b> +
          <span style={{ color: 'green' }}> G / </span>
          <span style={{ color: 'blue' }}>B / </span>
          <span style={{ color: 'red' }}>R  </span>
          and
          <b> Shift + Option</b> +  <span style={{ color: 'green' }}> G / </span>
          <span style={{ color: 'blue' }}>B / </span>
          <span style={{ color: 'red' }}>R  </span> for the opposite direction
        </p>
        <br />
        <p><b>▪️ To Lock movement: </b> Press N </p>
        <p><b>▪️ To Resume movement: </b> Press M </p>
      </Alert>
    </Collapse>
  </div>)
}

// export function HomePosition({ goToHomeSweep }: { goToHomeSweep: () => void }) {

//   const dispatch = useDispatch();
//   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
//   const showHomePositionSaver = Boolean(anchorEl);

//   const setShowHomePositionSaver = (event: any) => {
//     setAnchorEl(anchorEl ? null : event.currentTarget);
//   };

//   return (<Box

//   >{showHomePositionSaver &&
//     <Popper open={showHomePositionSaver} anchorEl={anchorEl} placement='top-start'>
//       <Link
//         component="button"
//         variant="body2"
//         onClick={() => {
//           getCurrentSweep().then(s => {
//             let id = shortUUID.generate();
//             let p: SpacePose = { id: id, data: s, label: 'Home Viewpoint' }
//             dispatch(setSpaceHomePose(p));
//           }).catch(fetchError);
//         }}
//       >
//         Save current as new Home Position
//       </Link></Popper>}

//     <IconHover tooltip='Go to Home position'
//     >

//       <Box
//         onMouseOver={setShowHomePositionSaver}
//         onMouseLeave={setShowHomePositionSaver}
//         // style={{padding: '4px'}}
//       >
//         <HomeIcon
//           onClick={() => goToHomeSweep ? goToHomeSweep() : {}}
//         // fontSize='small'
//         />
//       </Box>
//     </IconHover>

//   </Box>)
// }