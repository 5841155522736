import React from 'react';
import { Icon, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { Badge, NavLink } from '../../../../@crema';
import clsx from 'clsx';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './HorizontalItem.style';
import Box from '@material-ui/core/Box';
import { NavItemProps } from '../../../../modules/routesConfig';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface HorizontalItemProps extends RouteComponentProps<any> {
  item: NavItemProps;
  dense?: string;
  location: any;
}

const HorizontalItem: React.FC<HorizontalItemProps> = ({
  item,
  location,
  history,
  dense,
}) => {
  const classes = useStyles();
  const { pathname } = location;
  const active = isUrlInChildren(item, pathname);

  function isUrlInChildren(parent: any, url: string) {
    if (!parent.children) {
      return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].children) {
        if (isUrlInChildren(parent.children[i], url)) {
          return true;
        }
      }

      if (
        parent.children[i].url === url ||
        url.includes(parent.children[i].url)
      ) {
        return true;
      }
    }

    return false;
  }

  //ugh the item.disabled code is clunky
  return (
    <Box style={{
      display: 'flex', flexDirection: 'column',
      justifyContent: 'space-between', alignItems: 'center'
    }}>
      <Tooltip title={item.tooltip || ''}>
        <Box>
          {!!item.disabled &&
            <ListItem
              disabled={!!item.disabled}
              className={clsx('navItemSubmenu', classes.root, dense && 'dense', {
                active: pathname === item.url,
              })}>
              <ListItemText
                className={clsx('navLinkTextSubmenu', classes.navListItemText)}
                primary={<IntlMessages id={item.messageId} />}
              />
            </ListItem>
          }
          {!item.disabled && <ListItem
            disabled={!!item.disabled}
            component={NavLink}
            to={item.url}
            onClick={() => !item.disabled && history.push(item.url ? item.url : '/')}
            className={clsx('navItemSubmenu', classes.root, dense && 'dense', {
              active: pathname === item.url,
            })}>

            {/* {item.icon && (
          <Box fontSize={{ xs: 16, xl: 18 }} mr={3} clone>
            <Icon style={{ color: active ? 'white' : 'action' }}>{item.icon}</Icon>
          </Box>
        )} */}
            <ListItemText
              className={clsx('navLinkTextSubmenu', classes.navListItemText)}
              primary={<IntlMessages id={item.messageId} />}
            />
            {/* {item.count && (
          <Box ml={4} clone>
            <Badge count={item.count} color={item.color} />
          </Box>
        )} */}

          </ListItem>}
        </Box>
      </Tooltip>
      {(pathname === item.url) && <div className={classes.activeMenuItem}></div>}
    </Box>
  );
};
export default withRouter(React.memo(HorizontalItem));
