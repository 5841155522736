import {OutlinePass} from 'three/examples/jsm/postprocessing/OutlinePass';
import { Texture, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { SceneComponent } from '../../SubSystems/sceneManagement/SceneComponent';
import Simulation from '../../SubSystems/core/Simulation';
import * as THREE from "three"

export class TemporalOutlineElement {
    //Lifetime is in milliseconds, NOT seconds
    constructor(public lifeTime:number, public meshes:Object3D[]) {

    }
}

export class OutlinePostProcess extends SceneComponent {
  public outlinePass: OutlinePass;
  private selectedObjects:Object3D[];
  private ephemeralObjects:Map<number, Object3D[]>;
  private internalEphemeralIndex:number = -1;

  //protected temporalOutlines: { [key: string]: TemporalOutlineElement | null };
  public temporalOutlines: Map<string, TemporalOutlineElement>;

  onInit() {
    const THREE = this.context.three;
    console.log("[OutlinePostProcess] Init")

    this.outlinePass = new (this.context.three as any).OutlinePass(Simulation.instance.renderingSubSystem.getScreenResolution(),
     this.context.scene,
      this.context.camera,
       this.selectedObjects);


       var params = {
        edgeStrength: 4,
        edgeGlow: 2,
        edgeThickness: 10.0,
        pulsePeriod: 1,
        usePatternTexture: false
    };

    this.outlinePass.edgeStrength = params.edgeStrength;
    this.outlinePass.edgeGlow = params.edgeGlow;
    this.outlinePass.visibleEdgeColor.set(0xffffff);
    this.outlinePass.hiddenEdgeColor.set(0xffffff);

		Simulation.instance.renderingSubSystem.effectComposer.addPass( this.outlinePass );

    //Simulation.instance.renderingSubSystem.effectComposer.addPass( new RenderPass(this.context.scene, otherCam) );
    console.log(this.outlinePass.changeVisibilityOfSelectedObjects);
    this.outlinePass.changeVisibilityOfSelectedObjects = this.changeVisibilityOfSelectedObjects.bind(this);
    this.outlinePass.changeVisibilityOfNonSelectedObjects = this.changeVisibilityOfNonSelectedObjects.bind(this);
    //this.outlinePass.changeVisibilityOfNonSelectedObjects = this.testing;

    this.selectedObjects = [];
    this.ephemeralObjects = new Map<number, Object3D[]>();

    this.temporalOutlines = new Map<string, TemporalOutlineElement>();
  }

  public addEphemeralMeshes(meshes:Object3D[], existingEphemeralIndex:number = -1):number {

    if(existingEphemeralIndex < 0) {
        ++this.internalEphemeralIndex;
    }

    if(this.ephemeralObjects.get(this.internalEphemeralIndex)) {

    } else {
        this.ephemeralObjects.set(this.internalEphemeralIndex, meshes);
    }

    return this.internalEphemeralIndex;
  }

  public removeEphemeralMeshes(existingEphemeralIndex:number) {
        this.ephemeralObjects.delete(existingEphemeralIndex);
  }

  changeVisibilityOfNonSelectedObjects( bVisible:boolean ) {
    const cache = (this.outlinePass as any)._visibilityCache;
    const selectedMeshes:any[] = [];

    function gatherSelectedMeshesCallBack( object:any ) {
      if ( object.isMesh ) selectedMeshes.push( object );
    }

    for ( let i = 0; i < this.selectedObjects.length; i ++ ) {
      const selectedObject = this.selectedObjects[ i ];
      selectedObject && selectedObject.traverse( gatherSelectedMeshesCallBack );
    }

    function VisibilityChangeCallBack( object:any ) {
      if ( object.isMesh || object.isSprite || object.isTransformControls ) {
        // only meshes and sprites are supported by OutlinePass
        let bFound = false;

        for ( let i = 0; i < selectedMeshes.length; i ++ ) {
          const selectedObjectId = selectedMeshes[ i ].id;
          if ( selectedObjectId === object.id ) {
            bFound = true;
            break;
          }
        }

        if ( bFound === false ) {
          const visibility = object.visible;
          if ( bVisible === false || cache.get( object ) === true ) {
            object.visible = bVisible;
          }

          cache.set( object, visibility );
        }

      } else if ( object.isPoints || object.isLine ) {
        // the visibilty of points and lines is always set to false in order to
        // not affect the outline computation
        if ( bVisible === true ) {
          object.visible = cache.get( object ); // restore
        } else {
          cache.set( object, object.visible );
          object.visible = bVisible;
        }
      }
    }

    this.outlinePass.renderScene.traverse( VisibilityChangeCallBack );
  }


  changeVisibilityOfSelectedObjects( bVisible:boolean ) {
    const cache = (this.outlinePass as any)._visibilityCache;

    function gatherSelectedMeshesCallBack( object:any ) {
      if ( object.isMesh ) {
        if ( bVisible === true ) {
          object.visible = cache.get( object );
        } else {
          cache.set( object, object.visible );
          object.visible = bVisible;
        }
      }
    }

    for ( let i = 0; i < this.selectedObjects.length; i ++ ) {
      const selectedObject = this.selectedObjects[ i ];
      selectedObject && selectedObject.traverse( gatherSelectedMeshesCallBack );
    }
  }

  processTemporalOutlineMap(toRemove:string[], delta:number, item:TemporalOutlineElement, key:string, mapObj:Map<string, TemporalOutlineElement>) {

    if(item.lifeTime > 0) {
      item.lifeTime -= delta;
      this.selectedObjects.concat(item.meshes)
    } else {
      toRemove.push(key);
    }

    this.selectedObjects = this.selectedObjects.concat(item.meshes)
  }

  onTick(delta:number) {
    super.onTick(delta);
    this.selectedObjects = []
    let toRemove:string[]|null = [];

    //console.log(this.context.scene.traverse);

    this.temporalOutlines.forEach(this.processTemporalOutlineMap.bind(this, toRemove, delta));

    for (const keyString of toRemove) {
      this.temporalOutlines.delete(keyString);
    }

    let ephemeralFinalArray:Object3D[] =[];

    this.ephemeralObjects.forEach((value:Object3D[], key:number) => {
        ephemeralFinalArray = ephemeralFinalArray.concat(value);
    });

    this.selectedObjects = this.selectedObjects.concat(ephemeralFinalArray);
    this.outlinePass.selectedObjects = this.selectedObjects;
    //this.outlinePass.selectedObjects = this.outlinePass.selectedObjects.concat(this.ephemeralObjects);
    //this.outlinePass.selectedObjects = this.ephemeralObjects;
    toRemove = null;
    /*
    var i = this.temporalOutlines.length;
    while (i--) {
        let temporalOutline = this.temporalOutlines[i];
        if(temporalOutline.lifeTime > 0) {
          temporalOutline.lifeTime -= delta;
          this.selectedObjects.push(temporalOutline.object3D);
        } else {
          this.temporalOutlines.splice(i, 1);
        }
    }*/
    //console.log(this.glitchPass);
  }

  onEvent(eventType: string, eventData: unknown) {
    this.notify(eventType, eventData);
  }

  onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;
  }

}

export const outlinePostProcessType = 'mp.outlinePostProcess';
export function makeOutlinePostProcess() {
  return new OutlinePostProcess();
}
