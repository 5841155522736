import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { Badge, Tooltip } from '@mui/material';

// const useStyles = makeStyles(theme => ({
//   root: {
//     padding: '0 7px',
//     fontSize: 11,
//     fontWeight: 600,
//     height: 20,
//     minWidth: 20,
//     borderRadius: 20,
//     display: 'flex',
//     alignItems: 'center',
//     color: theme.palette.secondary.contrastText,
//   },
// }));

interface BadgeProps {
  children: React.ReactElement<any, any>;
  [x: string]: any;
}

const BadgeBeta: React.FC<BadgeProps> = ({
  children,
  ...props
}) => {
  // const classes = useStyles();

  return <Badge
    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    overlap={props.overlap || "circular"}
    // className={classes.badgeRoot}
    badgeContent={
      <Tooltip title="Beta feature - may not be stable. But we would love your feedback!">
        {<p>β</p>}
      </Tooltip>}
    color="warning"
    {...props}

  >
    {children}
  </Badge >

};

export default BadgeBeta;

export const BadgeAlpha: React.FC<BadgeProps> = ({
  children
}) => {
  // const classes = useStyles();

  return <Badge anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    // className={classes.badgeRoot}
    badgeContent={
      <Tooltip title="Alpha feature - very experimental - may not work at all">
        {<p>𝛼</p>}
      </Tooltip>} color="error"
  >
    {children}
  </Badge >

};