import { IQuizDetails } from 'types/models/home/HomeApp';

export const tagForm = `
<style type="text/css">
.container {
  background: #EB3B88;
}
.form-style-1 {
margin:10px auto;
max-width: 400px;
padding: 20px 12px 10px 20px;
font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}
.form-style-1 li {
padding: 0;
display: block;
list-style: none;
margin: 10px 0 0 0;
}
.form-style-1 label{
margin:0 0 3px 0;
padding:0px;
display:block;
font-weight: bold;
}
.form-style-1 input[type=text],
.form-style-1 input[type=date],
.form-style-1 input[type=datetime],
.form-style-1 input[type=number],
.form-style-1 input[type=search],
.form-style-1 input[type=time],
.form-style-1 input[type=url],
.form-style-1 input[type=email],
textarea,
select{
box-sizing: border-box;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
border:1px solid #BEBEBE;
padding: 7px;
margin:0px;
-webkit-transition: all 0.30s ease-in-out;
-moz-transition: all 0.30s ease-in-out;
-ms-transition: all 0.30s ease-in-out;
-o-transition: all 0.30s ease-in-out;
outline: none;
}
.form-style-1 input[type=text]:focus,
.form-style-1 input[type=date]:focus,
.form-style-1 input[type=datetime]:focus,
.form-style-1 input[type=number]:focus,
.form-style-1 input[type=search]:focus,
.form-style-1 input[type=time]:focus,
.form-style-1 input[type=url]:focus,
.form-style-1 input[type=email]:focus,
.form-style-1 textarea:focus,
.form-style-1 select:focus{
-moz-box-shadow: 0 0 8px #88D5E9;
-webkit-box-shadow: 0 0 8px #88D5E9;
box-shadow: 0 0 8px #88D5E9;
border: 1px solid #88D5E9;
}
.form-style-1 .field-divided{
width: 49%;
}

.form-style-1 .field-long{
width: 100%;
}
.form-style-1 .field-select{
width: 100%;
}
.form-style-1 .field-textarea{
height: 100px;
}
.form-style-1 input[type=submit], .form-style-1 input[type=button]{
background: #4B99AD;
padding: 8px 15px 8px 15px;
border: none;
color: #fff;
}
.form-style-1 input[type=submit]:hover, .form-style-1 input[type=button]:hover{
background: #4691A4;
box-shadow:none;
-moz-box-shadow:none;
-webkit-box-shadow:none;
}
.form-style-1 .required{
color:red;
}
</style>
<div class="container">

  <ul class="form-style-1">
      <li>
          <label>Tag Label <span class="required">*</span></label>
          <input type="text" id="tagLabel" name="tagLabel" class="field-long" />
      </li>
      <li>
          <label>Tag Layer</label>
          <select id="layer" name="layer" class="field-select">
          <option value="Advertise">Advertise</option>
          <option value="Partnership">Partnership</option>
          <option value="General Question">General</option>
          </select>
      </li>
      <li>
          <label>Description <span class="required">*</span></label>
          <textarea id="desc" name="desc" id="desc" class="field-long field-textarea"></textarea>
      </li>
      <li>
      <button id="saveButton">Save</button>
      </li>
  </ul>

</div>
<script>
    var btn1 = document.getElementById("saveButton");
    saveButton.addEventListener("click", function () {
      window.send("saveButtonClick", document.getElementById("tagLabel").value, document.getElementById("desc").value, document.getElementById("layer").value);
    });
  </script>
`;

export const tagForm2 =
  '  <style> \
    button { \
      width: 100px; \
      height: 50px; \
    } \
  </style> \
  <button id="btn1">CLICK ME</button> \
  <script> \
    var btn1 = document.getElementById("btn1"); \
    btn1.addEventListener("click", function () { \
      window.send("buttonClick", 12345); \
    }); \
  </script>';

export const quizTagHtml = (
  quizDetails: IQuizDetails | undefined,
  tagHtml: string,
) => {
  return `<style>
      body{
        font-family: Open Sans,sans-serif;
      }
      #quizTagBilling {
        height: 100%,
      }
      form{
        display:flex;
      }
      #radioParent{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }
        #quizQuestion {
          color:#fff;
          margin: 0px;
          margin-bottom: 8px;
          font-size: 14px;
          font-family: Open Sans,sans-serif;
          font-weight: 400;
          line-height: 1.5;
        }
        #radio{
          color:#fff;
          flex: 1 1 49%;
          padding: 8px 0px;
        }
        label{
          color:#fff;
          font-size: 14px;
          font-family: Open Sans,sans-serif;
          font-weight: 400;
          line-height: 1.5;
        }
        #correct-ans{
          color:green;
          display:none;
        }
        #wrong-ans{
          color:red;
          display:none;
        }
        .choice{
          padding: 4px;
        }
        .choice:hover{
          background: grey;
          cursor: pointer;
        }
        }
      </style>
      ${tagHtml}
      <div id="quizTagBilling">
        <h4 id="quizQuestion">${quizDetails?.question}</h4>
        <form name="myForm" id="radioInputForm">
          <div id="radioParent">
          ${quizDetails && quizDetails.options.map((item, index) => (
            `<div id="choice-${index}" class="choice">
              <label>
                <input type="radio" id="radioInput" name="choice" value=${index}>
              ${item.text}
                </label>
            </div>`
  ))
    }
          </div>
        </form>
        <h4 id="correct-ans">That is correct!</h4>
        <h4 id="wrong-ans">That's not correct, please try again!</h4>
        <style>
        button { width: 80px; height: 30px; padding: 2px}
        body{margin: 5px 0px;}
      </style>

      </div>
      `;
};

export const mediaTagHtml = (mediaLink: string, tagHtml: string) => {
  return `<style>
      #iframely-embed {
        width: 100%;
      }
      </style>
      ${tagHtml}
      <div id="iframely-embed">
        <a data-iframely-url href=${mediaLink}></a>
      </div>
    <script async charset="utf-8" src="//cdn.iframe.ly/embed.js?api_key=4a2fdc14874b9e0b9c4735"></script>
      `;
};

export const taskTagHtml2 = (taskDetails: any) => {
  return `<style>
      #taskTagBilling {}
      form{
        display:flex;
      }
      #radioParent{
        flex:1;
      }
        #taskTitle {
          color:#fff;
          margin: 0px;
          margin-bottom: 8px;
          font-size: 22px;
          font-family: Open Sans,sans-serif;
          font-weight: 400;
          line-height: 1.5;
        }
        #radio{
          color:#fff;
          flex:1;
          padding: 8px 0px;
        }
        label{
          color:#fff;
          font-size: 14px;
          font-family: Open Sans,sans-serif;
          font-weight: 400;
          line-height: 1.5;
        }
        #correct-ans{
          color:green;
          display:none;
        }
        #wrong-ans{
          color:red;
          display:none;
        }
      </style>
      <div id="taskTagBilling">
        <h4 id="taskTitle">${taskDetails?.title}</h4>
        <h4 id="taskStatus">${taskDetails?.newCard?.label}</h4>

        <div id="btn1">PURCHASE</div>
        <style>
        button { width: 80px; height: 30px; padding: 2px}
        body{margin: 5px 0px;}
      </style>

      </div>
      `;
};
