import { Euler, Quaternion, Vector3 } from "three";
import { ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";

/*
ISceneNode.prototype.isBigNumber = function (this: number)  {
    return this > 100000;
};*/


export class ISceneNodeExtensions {
    /*
    @extension(ISceneNode)
    static test(user: ISceneNode){

    }*/

    public static setRotation(node: ISceneNode, q: Quaternion) {
        // if ((node as any).hasOwnProperty('')
        node.quaternion.x = q.x;
        node.quaternion.y = q.y;
        node.quaternion.z = q.z;
        node.quaternion.w = q.w;
    }

    // public static setRotationFromAny(node:ISceneNode,anyRot:any) {
    //     let q = new Quaternion(anyRot._x, anyRot._y, anyRot._z, anyRot._w);
    //     node.quaternion.x = q.x;
    //     node.quaternion.y = q.y;
    //     node.quaternion.z = q.z;
    //     node.quaternion.w = q.w;
    // }

    public static setPosition(node: ISceneNode, v: Vector3) {
        node.position.x = v.x;
        node.position.y = v.y;
        node.position.z = v.z;
    }

    public static setPositionFromAny(node: ISceneNode, v: any) {
        node.position.x = v.x;
        node.position.y = v.y;
        node.position.z = v.z;
    }

    public static setScaleFromAny(node: ISceneNode, v: any) {
        node.scale.x = v.x;
        node.scale.y = v.y;
        node.scale.z = v.z;
    }

    public static setEulerRotation(node: ISceneNode, v: any[] | undefined) {
        // let x = (!v || v.length === 0) ? undefined : (new Euler()).fromArray(v);

        node.userData.eulerRotation = v ? [...v] : undefined;

    }

    public static setVector(v: Vector3, node: ISceneNode) {
        v.x = node.position.x;
        v.y = node.position.y;
        v.z = node.position.z;
    }
}
