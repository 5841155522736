import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import SidebarTabPanel from './TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { ANNOTATION_TYPE, ShowcaseTag, SIDEBAR_INDEX } from 'types/models/home/HomeApp';
import ProjectBoard from 'modules/apps/ProjectBoard';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import { addTagsToEmptyShowcase } from 'modules/home/SpaceDetail/utils';
import { ProjectObj } from 'types/models/apps/ProjectBoard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
      textAlign: 'center',
      marginTop: '10px'
    },
    tagsOr3dTab: {
      margin: '10px 5px',
      padding: '5px 2px',
      width: '50%',
      textAlign: 'center'

    },
    tagsOr3dTabActive: {
      color: 'black',
      borderBottom: '3px solid #315F72',
      borderRadius: '2px',

    },
    tagsOr3dTabInActive: {
      // color: 'black',

      cursor: 'pointer',
      '&:hover': {
        borderBottom: '3px solid #315F7250',
        borderRadius: '2px'
      }
    }
  }),
);
interface Props {
  // importMatterportTags(): void;
  // tagsAlreadyImported: boolean;
  // addObject(objectName: string): void;
}

export default function TaskSidebar({
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const spaceTags = useSelector<AppState, ShowcaseTagMap>(({ home }) => home.spaceTags);
  const currentSidebarIndex = useSelector<AppState, SIDEBAR_INDEX>(({ home }) => home.currentSidebarIndex);
  const projectDetail = useSelector<AppState, ProjectObj | null>(({ projects }) => projects.projectDetail);

  useEffect(() => {
    let visibleSpaceTags: ShowcaseTag[] = [];
    if (currentSidebarIndex == SIDEBAR_INDEX.TASKS) {
      visibleSpaceTags = Object.values(spaceTags).filter(
        (t) => t.annotationType && t.annotationType.startsWith(ANNOTATION_TYPE.TASK),
      );
    } else {
      // visibleSpaceTags = getTagsForTab(value);
    }
    addTagsToEmptyShowcase({tagIds: visibleSpaceTags.map(tag => tag.id)});
    // console.log(`[st] setstate setting setCurrentSpaceTagsList 1`);
    // dispatch(setCurrentShowcaseTags(visibleSpaceTags));
  }, [spaceTags, currentSidebarIndex, projectDetail])

  return (console.log(`%c[st] rendering TaskSidebar`,'color: orange;'),
    <div style={{
      height: "100%", padding: '16px',
      // width: '100%'
      minWidth: '550px',
      maxWidth: '600px',
    }}>
      <ProjectBoard />
    </div>
  );
}
