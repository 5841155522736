import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ProjectDetail from './ProjectDetail';
import ProjectList from './ProjectList';
import {useParams} from 'react-router-dom';
import { Box } from '@material-ui/core';

export interface ProjectParamsProps {
  id: string;
  pDid: string;
  taskId: string; 
  sectionId: string; 
}

const ProjectBoard = () => {
  const dispatch = useDispatch();
  const params = useParams<ProjectParamsProps>();

  const onGetMainComponent = () => {
    if (params.id||params.pDid) {
      return <ProjectDetail />;
    } else {
      return <ProjectList />;
    }
  };

  return <>{onGetMainComponent()}</>;
};

export default ProjectBoard;
