import axios from 'axios';
import { getServerApiUrl } from '../../Config';
import { auth } from './auth/firebase/firebase';

let Api = axios.create({
  baseURL: getServerApiUrl(),
  headers: {
    // token: await auth.currentUser?.getIdToken()
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
  },
});

// apiConfig.defaults.headers.common = {'Authorization': `Bearer ${token}`}

Api.interceptors.request.use(async config => {
  config.headers.token = await auth.currentUser?.getIdToken()
  return config
}, (error) => {
  return Promise.reject(error)
});

export default Api;