import { IModel, Repo } from "./Repo";
import { firestore, userRepo } from "@crema/services/auth/firebase/firebase";
import { IShareSpace, SpaceData, SpaceMembership, SpacePermissions, SpacePose, SpaceRole } from "../home/HomeApp";
import { UserModel } from "./User";
import { DocumentData } from "@firebase/firestore-types";
import { UseCase } from "shared/constants/AppConst";
import { ProjectObj } from "../apps/ProjectBoard";
export class Space extends IModel implements SpaceData{
  poses: SpacePose[];
  sid: string;
  did: string;
  name: string;
  imageSrc?: string | undefined;
  homeSweepId?: any;
  settings?: any;
  spaceMembership: SpaceMembership;
  variables?: any[] | undefined;
  primaryUseCase?: UseCase | undefined;
  secondaryUseCase?: UseCase[] | undefined;
  primaryUseCaseOther?: string | undefined;
  secondaryUseCaseOther?: string | undefined;
  spaceProjects: ProjectObj[];
  currentSpaceProject: ProjectObj;
  isDeleted: boolean;
  tagsImported?: boolean | undefined;

  // // id: string;
  // sid: string;
  // name: string;
  // description?: string;
  // // creationTimestamp: Date;
  // // lastUpdatedTimestamp?: Date;
  // image?: {
  //   id: number;
  //   src: string;
  // }[];
  // rating?: number;
  // viewers?: string[];
  // editors?: string[];
  // owners: string[];
  // sharedSpace?: IShareSpace[];
}

export interface SpaceInvite extends IModel {
  // userId: string;
  spaceId: string;
  role: SpaceRole;
  userEmail: string;
  // inviteStatus: InviteStatus;
}

// const userConverter = {
//   toFirestore(post: UserModel): FirebaseFirestore.DocumentData {
//     return {};
//   },
//   fromFirestore(
//     snapshot: FirebaseFirestore.QueryDocumentSnapshot,

//   ): UserModel {
//     const data = snapshot.data()!;
//     return new UserModel(data.title, data.author);
//   }
// };

export class SpaceRepo extends Repo<Space>{
  getDbPath(id?: string | undefined): string {
    if (id) {
      return `/Spaces/${id}`;
    }
    return 'Spaces';
  }

  async create(object: Space): Promise<Space> {

    console.log(`[st] creating space for user ${object.createdBy}`);
    let batch = firestore.batch();
    // let spaceDocRef = await firestore.collection(this.getDbPath()).add(object);

    let spaceDocRef = firestore.collection(this.getDbPath()).doc();
    batch.set(spaceDocRef, object);

    let user: UserModel = await userRepo.get(object.createdBy);



    let zz = spaceDocRef.id;
    let me: any = {};
    let sp: SpacePermissions = { "userId": user.id, "spaceId": spaceDocRef.id, "spaceSid": object.sid, "name": object.name || "", "role": SpaceRole.OWNER, createdOn: new Date(), createdBy: user.id };
    me[zz] = Object.assign({}, sp);

    let meAr = [sp];
    user.spaceMap = user.spaceMap || [];
    user.spaceMap.push(sp);

    // await firestore.collection('Users').doc(object.createdBy).set({"spaceMap" : me}, {merge: true});
    batch.set(firestore.collection('Users').doc(object.createdBy), { "spaceMap": user.spaceMap }, { merge: true });

    await batch.commit();

    let s = await spaceDocRef.get();

    console.log(`[st] model created ${s.id}`);
    return this.mapDbToObject(s.data() as DocumentData, s.id);

  }

  async findSpaceBySid(sid: string, uid: string): Promise<Space[]> {

    console.log(`[st] sid ${sid}`);

    let qs = await firestore
      .collection('Spaces')
      // .where('creatorId', '==', uid)
      .where('sid', '==', sid)
      .get();

    console.log(`[st] is presebt ${!qs.empty}`);
    if (qs.empty) {
      return [];
    }

    //TODO what if result is empty?
    return qs.docs.map(doc => this.mapDbToObject(doc.data(), doc.id));
  }

  async getSpacesForUser(uid: string): Promise<SpacePermissions[]> {

    // console.log(`[st] ${useAuthUser()?.userProfile.spaceMap}`);

    let user = (await userRepo.get(uid))
    let userPermissions = user.spaceMap?.values() || [];
    return Array.from(userPermissions);

    // return user.spaceMap || [];

  }

  // async delete(id: string): Promise<boolean> {

  //   let batch = firestore.batch();
  //   batch.delete(firestore.doc(this.getDbPath(id)));
  //   userRepo.
  //   batch.update(firestore.doc
  //     )
  //   await
  //       return true;
  // }

}

export const SpaceErrors = new Map<string, any>([
  ["space/no-user-access", "You don't have access to this space. Access may have been removed"],
  ["space/not-found", "Space not found. It may have been deleted!"]
]);