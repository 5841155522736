import ManagedObject from '../../ds/ManagedObject';
import MathExtensions from '../../../Tools/MathExtensions';

export default class GradientInterpolator extends ManagedObject {
    public constructor(private gradientArray: { gradient:number, interpolationMapping:number }[]) {
        super(null);
        this.gradientArray.sort((a, b) => (a.gradient > b.gradient) ? 1 : -1);
    }
    
    public getInterpolation(x:number):number {
        let lastIndex = -1;
        for(let i = 0; i < this.gradientArray.length; i++) {
            lastIndex = i;
            if(x <= this.gradientArray[i].gradient) {
                break;
            }
        }
        
        if(lastIndex === -1) {
            return this.gradientArray[this.gradientArray.length - 1].interpolationMapping;
        } else if(lastIndex === 0) {
            return this.gradientArray[lastIndex].interpolationMapping;
        }
        
        let gradientElementA= this.gradientArray[lastIndex];
        let gradientElementB= this.gradientArray[lastIndex - 1];
        
        let normalized = MathExtensions.normalize(x, gradientElementA.gradient, gradientElementB.gradient);
        
        return MathExtensions.lerp(gradientElementB.interpolationMapping, gradientElementA.interpolationMapping, normalized);
    }
}
