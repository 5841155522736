import React, { useEffect } from 'react';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { Button, MenuItem, Select } from '@material-ui/core';
import { store } from 'App';

const useStyles = makeStyles((theme) => ({
  bgTransparent: {
    backgroundColor: 'transparent',
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: "#315f72",
      color: 'white'
    },
    '& .MuiToggleButton-root': {
      color: "white",
      // height: "1em",
      paddingTop: '0px',
      paddingBottom: '0px',
      textShadow: "0 0 5px rgb(0 0 0)"

    },
    margin: '5px',
  },
  isDefaultVarsUI: {
    backgroundColor: 'transparent',
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: "#315f72",
      color: 'white'
    },
    '& .MuiToggleButton-root': {
      color: "black",
      paddingTop: '0px',
      paddingBottom: '0px',

      // height: "1em",
      // textShadow: "0 0 5px rgb(0 0 0)"
    },
    margin: '5px',
    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center'
  },
  btn: {
    '& .MuiToggleButton-root': {
      // color: "white",
      // height: "0.85em",
      // textShadow: "0 0 5px rgb(0 0 0)"
    }
  },
}));
export default function VariablesButtonBar(props: any) {
  const [value, setValue] = React.useState('');
  const [openSelect, setOpenSelect] = React.useState(false);

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
    props.handleChange(newValue);
  };
  const classes = useStyles(props);

  useEffect(() => { setValue(props.initialValue) }, [props]);
  const [showAll, setShowAll] = React.useState(false);

  return (console.log(`%c[st] rendering VariablesButtonBar select is: ${openSelect} `,'color: orange;'),
    // <Grid container spacing={2} direction='column' alignItems='center' alignContent='flex-start' className={classes.gridContainer}>
    // <Grid item className={classes.gridContainerItem}>
    <div onMouseLeave={() => !props.isDefaultVarsUI && setShowAll(false)}
      onMouseOver={() => !props.isDefaultVarsUI && setShowAll(true)}
    // onMouseOut={() => setShowAll(false)}
    // onMouseLeave={() => setShowAll(false)}
    // onMouseOver={() => setShowAll(true)}
    // onMouseOver={() => setOpenSelect(true)}
    // onMouseLeave={() => setOpenSelect(false)}
    // onMouseOut={() => setOpenSelect(false)}

    >

      {/* <Select
        labelId="variable-for-input-label"
        id="variable-for-input-select"
        value={value}
        style={{ fontSize: '13px' }}
        label="Ask User for Input"
        // open={openSelect}
        // displayEmpty
        onChange={(e) => {
          handleChange(e, e.target.value as string);
        }} //TODO check
      >
        <MenuItem style={{ fontSize: '13px' }} value={''}><em>None</em></MenuItem>
        {props.children.map((v: any, index: any) =>
          <MenuItem style={{ fontSize: '13px' }} key={index} value={v}>
            <ToggleButton key={index} value={v} className={classes.btn}>
            {v}
          </ToggleButton>
          </MenuItem>)}
      </Select>  */}
      {/* //TODO complete this, remove scrollbar */}

      {/* <div
        className={props.isDefaultVarsUI ? classes.isDefaultVarsUI : classes.bgTransparent}
        // onMouseOver={() => setShowAll(true)}
        onMouseOut={() => setShowAll(false)}
      >
        {!showAll && <ToggleButton key={value} value={value} className={classes.btn}
          onMouseOver={() => setShowAll(true)}
        >
          {props.children.find((item: any) => item == value)}
        </ToggleButton>}
      </div> */}
      {<ToggleButtonGroup
        className={props.isDefaultVarsUI ? classes.isDefaultVarsUI : classes.bgTransparent}
        size={props.size}
        value={value}
        exclusive
        onChange={handleChange}

      >
        {props.children?.filter((i: any) => !(!props.isDefaultVarsUI && !showAll && i !== value)).map((item: any, index: any) => {
          {
            // if (!props.isDefaultVarsUI && !showAll && item !== value) {
            //     return <></>;
            //   // }
            //   //  else {
            //   //   return <ToggleButton key={index} value={item} className={classes.btn}>
            //   //     {item}
            //   //   </ToggleButton>
            //   // }

            // } else {
              return <ToggleButton key={index} value={item} className={classes.btn}>
                {item}
              </ToggleButton>
            // }
          }
        })}
      </ToggleButtonGroup>}
    </div>
    //   </Grid>

    // </Grid>
  );
}