import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import IntlMessages from '../../../../../../@crema/utility/IntlMessages';
import { Form, useField } from 'formik';
import GridContainer from '../../../../../../@crema/core/GridContainer';
import Grid from '@material-ui/core/Grid';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete/Autocomplete';
import { useFormikContext } from 'formik';
import { blueGrey } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import CardAttachments from './CardAttachments';
import CardCheckedList from './CardCheckedList';
import CardComments from './CardComments';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from '../../../../../../shared/constants/AppEnums';
import { AppState } from '../../../../../../redux/store';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {
  AttachmentObj,
  ProjectObj,
  TaskSectionObj,
  TaskObj,
  CheckedListObj,
  LabelObj,
  CollaboratorObj,
  CommentObj,
} from '../../../../../../types/models/apps/ProjectBoard';
import { CremaTheme } from '../../../../../../types/AppContextPropsType';
import { useLocation } from 'react-router';
import { avatarName, getUserAvatar } from '@crema/utility/Utils';
import { useAuthUser, useOnClickOutside } from '@crema/utility/AppHooks';
import { CirclePicker, TwitterPicker } from 'react-color';
import { Chip, Tooltip } from '@material-ui/core';
import { onEditProjectLabel, onAddProjectLabel, onAddProjectCollaborator } from 'redux/actions';
import { ANNOTATION_TYPE, HomeRoutes, InviteStatus, SpaceRole } from 'types/models/home/HomeApp';
import { firestore } from '@crema/services/auth/firebase/firebase';
import shortUUID from 'short-uuid';
import { defaultTaskCollaboratorColor, defaultTaskLabelColor, MpColors } from 'shared/constants/AppConst';
import { store } from 'App';
import Api from '@crema/services/ApiConfig';

const useStyles = makeStyles((theme: CremaTheme) => ({
  root: {
    width: '100%',
  },
  myTextFieldRoot: {
    width: '100%',
    marginBottom: 20,
    border: '0px'
  },
  titleField: {
    width: "100%"
    // marginRight: 10,
    // flexGrow: 2
  },
  fieldRoot: {
    width: '100%',
    marginBottom: 20,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  btnRoot: {
    paddingLeft: 32,
    paddingRight: 32,
  },
  dividerRoot: {
    marginBottom: 32,
    marginLeft: -32,
    marginRight: -32,
  },
  gridContainer: {
    marginBottom: 12,
  },
  avatarRoot: {
    fontSize: 18,
    width: 50,
    height: 50,
    backgroundColor: blueGrey[700],
  },
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 400,
      width: '100%',
    },
  },
  cpPopover: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 11,
    backgroundColor: 'white'
  },
  collaboratorOptionEmail: {
    color: '#6d6e6f',
    fontSize: '12px',
  }
}));

const MyTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
    />
  );
};

interface AddCardFormProps {
  project: ProjectObj;
  list: TaskSectionObj;
  checkedList: CheckedListObj[];
  setCheckedList: (checkList: CheckedListObj[]) => void;
  comments: CommentObj[];
  values: any;
  setFieldValue: (name: string, value: any) => void;
  setComments: (comments: CommentObj[]) => void;
  attachments: AttachmentObj[];
  setAttachments: (attachments: AttachmentObj[]) => void;
  selectedLabels: LabelObj[];
  setSelectedLabels: (lables: LabelObj[]) => void;
  selectedCollaborators: CollaboratorObj[];
  setCollaboratorsList: (menmbers: CollaboratorObj[]) => void;
  selectedTask: TaskObj | null;
  onCloseAddTask: () => void;
  isSubmitting: boolean;
  section: TaskSectionObj | null;
  setSection: (section: TaskSectionObj | null) => void;
  selectedProject: ProjectObj | null;
  setSelectedProject: (selectedProject: ProjectObj | null) => void;
  touched: any;
  errors: any;
  annotationType: string;
}

type newLabelObj = {
  inputValue?: string;
  name: string;
  id: number;
  color: string;
}

type newCollaboratorObj = {
  inputValue?: string;
  id: string;
  name: string;
  image: string;
  email: string;
  // inviteStatus?: InviteStatus;
}

const filter = createFilterOptions<newCollaboratorObj>();
const filterHigh = createFilterOptions<newLabelObj>();
const AddCardForm: React.FC<AddCardFormProps> = ({
  project,
  list,
  values,
  setFieldValue,
  checkedList,
  setCheckedList,
  comments,
  setComments,
  attachments,
  setAttachments,
  selectedLabels,
  setSelectedLabels,
  selectedCollaborators,
  setCollaboratorsList,
  selectedTask,
  onCloseAddTask,
  isSubmitting,
  section,
  setSection,
  selectedProject,
  setSelectedProject,
  touched,
  errors,
  annotationType
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { submitForm } = useFormikContext();
  const authUser = useAuthUser();
  const labelPopupRef = useRef<HTMLHeadingElement>(null)
  const taskDialogRef = useRef<HTMLFormElement>(null)

  const [visible, setVisibility] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState<number | undefined>(undefined);

  const [openMemberDialog, setOpenMemberDialog] = React.useState(false);
  const [memberDialogValue, setMemberDialogValue] = React.useState({
    name: '',
    email: '',
  });

  const currentSpaceProjects = useSelector<AppState, ProjectObj[]>(
    ({ home }) => home.currentSpace?.spaceProjects || []
  );

  useOnClickOutside(labelPopupRef, () => setVisibility(false));

  // useOnClickOutside(taskDialogRef, () => {onCloseAddTask()});

  const colorLabel = () => (
    visible ? (
      <Box className={classes.cpPopover}>
        <CirclePicker
          // color={primary}
          colors={MpColors.map(c => c.hex)}
          onChange={color => {
            setVisibility(!visible);
            let index = selectedLabels.findIndex((lab) => (lab.id === selectedLabelId));
            let label = { ...selectedLabels[index], color: color.hex };
            selectedLabels[index].color = color.hex;
            dispatch(onEditProjectLabel(project?.id, label));
          }}
        />
      </Box>
    ) : null
  );


  const onDeleteCheckedItem = (id: string) => {
    const updatedList = checkedList.filter(
      (item: CheckedListObj) => item.id !== id,
    );
    setCheckedList(updatedList);
  };

  const onAddNewCheckedItem = () => {
    const item = {
      id: Math.floor(Math.random() * 1000).toString(),
      title: '',
      checked: false,
    };
    const updatedList = checkedList.concat(item);
    setCheckedList(updatedList);
  };

  const onSetCheckedItemText = (title: string, id: string) => {
    const updatedList = checkedList.map((item: CheckedListObj) => {
      if (item.id === id) {
        item.title = title;
        return item;
      } else {
        return item;
      }
    });
    setCheckedList(updatedList);
  };

  const onSetCheckedItemCheckbox = (value: boolean, id: string) => {
    const updatedList = checkedList.map((item: CheckedListObj) => {
      if (item.id === id) {
        item.checked = value;
        return item;
      } else {
        return item;
      }
    });
    setCheckedList(updatedList);
  };

  const onAddNewComment = (comment: string) => {
    let collabName = authUser?.user ? (authUser?.user?.firstName + " " + authUser?.user?.lastName) : (authUser?.email || '');
    setComments(
      comments.concat({
        comment: comment,
        name: collabName,
        image: authUser!.photoURL || '',
        date: new Date(),
      }),
    );
  };

  const onDeleteAttachment = (id: string) => {
    const updatedAttachments = attachments.filter(
      (attachment: AttachmentObj) => attachment.id !== id,
    );
    setAttachments(updatedAttachments);
  };
  const handleClose = () => {
    setMemberDialogValue({
      name: '',
      email: '',
    });
    setOpenMemberDialog(false);
  };

  const submitCollaborator = async (event: any) => {
    event.preventDefault();
    if (memberDialogValue?.email) {
      const userQs = await firestore.collection('Users').where('email', '==', memberDialogValue?.email).get();
      const user = userQs.docs.map(u => (u.data()));

      const collaborator: CollaboratorObj = {
        // id: shortUUID.generate(),
        id: (user && user.length !== 0) ? user[0].id : shortUUID.generate(),
        name: memberDialogValue?.name,
        email: memberDialogValue?.email,
        image: '',
        ...(!userQs.size && { inviteStatus: InviteStatus.INVITED })
      }
      setCollaboratorsList([...selectedCollaborators, collaborator]);
      dispatch(onAddProjectCollaborator(project.id, collaborator, () => {
        setCollaboratorsList(selectedCollaborators.filter((c => c.id !== collaborator.id)));
      }));
      handleClose();
    }
  };

  const renderCollaboratorDialog = () => {
    return (
      <Dialog open={openMemberDialog} onClose={handleClose} className={classes.dialogBox}>
        <form>
          <DialogTitle>Add an email</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
            Did you miss any film in our list? Please, add it!
          </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              className={classes.myTextFieldRoot}
              id="email"
              value={memberDialogValue?.email}
              onChange={(event) =>
                setMemberDialogValue({
                  ...memberDialogValue,
                  email: event.target.value,
                })
              }
              label={<IntlMessages id='common.email' />}
              type="email"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={submitCollaborator}>Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }

  const classes = useStyles();

  return (
    <Form className={classes.root} noValidate autoComplete='off' ref={taskDialogRef}>
      {renderCollaboratorDialog()}
      {/* <Divider /> */}
      <Box
        pt={4}
        px={{ xs: 5, lg: 8, xl: 10 }}
        pb={5}
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ sm: 'center' }}>
        {project && list && <Box
          mr={{ sm: 2 }}
          mb={{ xs: 3, sm: 0 }}
          // fontWeight={Fonts.MEDIUM}
          fontSize={16}>
          <Tooltip title={`Project: ${project?.name}`}>
            <span style={{ fontWeight: Fonts.MEDIUM }}>{project?.name}</span>
          </Tooltip>   {'>'} <Tooltip title={`List: ${list?.name}`}>
            <span style={{ fontWeight: Fonts.REGULAR }}>{list?.name}</span>
          </Tooltip>
        </Box>}
      </Box>

      <Box
        pb={!(pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) ? 5 : 0}
        px={!(pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) ? { xs: 5, lg: 8, xl: 10 } : {}}
      >
        <GridContainer className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} sm={8}>
            <MyTextField
              className={classes.titleField}
              variant='outlined'
              label={<IntlMessages id='common.title' />}
              name='title'
            /></Grid>
          <Grid item xs={12} sm={4}>
            {/* <Box ml={{ sm: 'auto' }}> */}
              <KeyboardDatePicker
                // autoOk
                format='YYYY/MM/DD'
                inputVariant='outlined'
                label={<IntlMessages id='common.date' />}
                name='date'
                value={values.date}
                onChange={value => setFieldValue('date', moment(value).format())}
              />
            {/* </Box> */}
          </Grid>
        </GridContainer>
        {(pathname.includes(HomeRoutes.SPACE) && annotationType === ANNOTATION_TYPE.TASK) ? (
          <GridContainer className={classes.gridContainer} spacing={2}>

            <Grid item xs={12} md={6}>
              <Autocomplete
                id='tags-outlined'
                options={currentSpaceProjects || []}
                getOptionLabel={option => option?.name}
                getOptionSelected={(option, value) => option?.name === value?.name}
                value={selectedProject}
                onChange={(event, value) => setSelectedProject(value)}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={<IntlMessages id='projectboard.project' />}
                    fullWidth
                    helperText={
                      !selectedProject && touched?.selectedProject ? (
                        <p>{messages['validation.projectRequired'] as string}</p>
                      ) : null
                    }
                    error={!(selectedProject) && touched?.selectedProject}
                  />
                )}
              /></Grid>
            <Grid item xs={12} md={6}>

              <Autocomplete
                id='tags-outlined'
                options={(project.sectionList || [])}
                getOptionLabel={option => option?.name}
                getOptionSelected={(option, value) => option?.name === value?.name}
                value={section || (project.sectionList && project.sectionList[0])}
                onChange={(event, value) => setSection(value)}
                filterSelectedOptions
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label={<IntlMessages id='common.list' />}
                    fullWidth
                    helperText={
                      !section
                      && touched?.section ? (
                        <p>{messages['validation.sectionRequired'] as string}</p>
                      ) : null
                    }
                    error={!(section) && touched?.section}
                  />
                )}
              />
            </Grid></GridContainer>


        ) : (null)}

        <MyTextField
          name='desc'
          multiline
          className={classes.fieldRoot}
          rows='4'
          variant='outlined'
          placeholder={messages['common.description']}
        />

        <GridContainer className={classes.gridContainer} spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='tags-outlined'
              options={project?.labelList || []}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={selectedLabels}
              getOptionSelected={(option, value) => option?.name === value?.name}
              getOptionLabel={(option: newLabelObj) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue.trim();
                }
                return option?.name
              }}
              onChange={(event, value: newLabelObj[]) => {
                if (typeof value === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setSelectedLabels(value);
                  });
                } else if (value && value[value.length ? value.length - 1 : 0]?.inputValue) {
                  setSelectedLabels(value);
                  let label = value[value.length ? value.length - 1 : 0];
                  let labelObj = {
                    id: label.id,
                    name: label?.name,
                    color: label.color
                  }
                  project?.labelList.push(labelObj);

                  Api.post('/api/projects/add/label', { projectId: project.id, label: label }).catch(error => console.error(`[st] ERROR: couldn't create label`));
                  // console.log(labelObj,"labelObj")
                  // dispatch(onAddProjectLabel(project.id, labelObj, () => {
                    // setSelectedLabels(selectedLabels.filter((l=>l.id!=labelObj.id)));
                  // }));
                } else {
                  setSelectedLabels(value);
                }
              }}
              renderOption={(option) => (!option.inputValue ? <Chip label={option?.name} style={{ background: option.color }} /> : <Box>{option.inputValue}</Box>)}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  variant='outlined'
                  placeholder='Search or Add new'
                  label={<IntlMessages id='common.label' />}
                  fullWidth
                />
              )}
              renderTags={(value, getTagProps) =>
                <div style={{ position: 'relative' }} ref={labelPopupRef}>
                  {colorLabel()}
                  {value.map((option, index) => (
                    <Chip
                      label={option?.name}
                      style={{ background: option.color }}
                      {...getTagProps({ index })}
                      onClick={() => {
                        setVisibility(!visible);
                        setSelectedLabelId(option.id);
                      }}
                      onDelete={() => {
                        setSelectedLabels(selectedLabels.filter((lab) => lab.id != option.id));
                        // selectedTask&&dispatch(onDeleteProjectLabel(project.id,selectedTask.id,option.id));
                      }}
                    />
                  ))}
                </div>
              }

              filterOptions={(options, params) => {
                const toFilter = filterHigh(options, params);
                if (params.inputValue !== '') {
                  let newLabel: newLabelObj = {
                    inputValue: `Create new label "${params.inputValue}"`,
                    name: `${params.inputValue}`,
                    id: project.labelList.length,
                    color: defaultTaskLabelColor,
                  }
                  toFilter.push(newLabel);
                }
                return toFilter;
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              id='tags-outlined'
              options={(() => {

                let list = project?.memberList || [];

                store.getState().home.peopleList.map(p => p.inviteStatus!==InviteStatus.INVITED && !list.find(i => i.id == p.userId) && list.push({...p, id: p.userId, image: ''} as CollaboratorObj))

                return list;
              })()}
              getOptionLabel={(option: newCollaboratorObj) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue?.trim();
                }
                return option?.name?.trim() || (option?.email || '').trim();
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              value={selectedCollaborators}
              getOptionSelected={(option, value) => option?.email === value?.email}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setOpenMemberDialog(true);
                    setMemberDialogValue({
                      name: newValue,
                      email: newValue,
                    });
                  });
                } else if (newValue && newValue[newValue.length ? newValue.length - 1 : 0]?.inputValue) {
                  setOpenMemberDialog(true);
                  setMemberDialogValue({
                    name: newValue[newValue.length ? newValue.length - 1 : 0]?.inputValue || '',
                    email: newValue[newValue.length ? newValue.length - 1 : 0]?.inputValue || '',
                  });
                } else {
                  setCollaboratorsList(newValue);
                }
              }}
              renderOption={option => (
                <React.Fragment>
                  {!option.inputValue ? option?.image ? (
                    <Avatar src={option?.image} className={classes.avatarRoot} />
                  ) : (
                    <Avatar className={classes.avatarRoot}>
                      {avatarName(option?.name)}
                    </Avatar>
                  ) : null}
                  <Box ml={4}>{option?.name}</Box>
                  <Box ml={2} className={classes.collaboratorOptionEmail}>{option?.email}</Box>
                </React.Fragment>
              )}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder='Search or Add new'
                  variant='outlined'
                  //collaborator text field
                  label={<IntlMessages id='common.collaborators' />}
                  fullWidth
                />
              )}
              renderTags={(value, getTagProps) => (
                value.map((option, index) => (
                  <Tooltip arrow title={option?.email} key={option?.email}>
                    <Chip
                      avatar={
                        option?.image ? (
                          <Avatar src={option?.image} className={classes.avatarRoot} style={{ color: '#fff' }} />
                        ) : (
                          <Avatar className={classes.avatarRoot} style={{ color: '#fff' }}>
                            {avatarName(option?.name)}
                          </Avatar>
                        )}
                      style={{ background: defaultTaskCollaboratorColor }}
                      label={`${option?.name}`}
                      {...getTagProps({ index })}
                      onClick={() => { }}
                      onDelete={() => {
                        setCollaboratorsList(selectedCollaborators.filter(c => (c?.id !== option?.id)))
                      }}
                    />
                  </Tooltip>
                ))
              )}
              filterOptions={(options, params) => {
                // console.log("options and params",params,options)
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    email: `Invite "${params.inputValue}" via email`,
                    name: `Invite "${params.inputValue}" via email`,
                    image: '',
                    id: '',
                    // role: SpaceRole.VIEWER,
                  });
                }

                return filtered;
              }}
            />
          </Grid>
        </GridContainer>

        <CardAttachments
          attachments={attachments}
          onDeleteAttachment={onDeleteAttachment}
        />

        {/* {selectedTask ? ( */}
        <CardCheckedList
          onAddNewCheckedItem={onAddNewCheckedItem}
          checkedList={checkedList}
          onDeleteCheckedItem={onDeleteCheckedItem}
          onSetCheckedItemText={onSetCheckedItemText}
          onSetCheckedItemCheckbox={onSetCheckedItemCheckbox}
        />
        {/* ) : null} */}

        <Divider className={classes.dividerRoot} />

        <CardComments comments={comments} onAddNewComment={onAddNewComment} project={project} selectedTask={selectedTask} />
      </Box>
      <Box px={8} py={4} bgcolor='grey.300'>
        <Button
          className={classes.btnRoot}
          color='secondary'
          variant='contained'
          disabled={isSubmitting}
          // type='submit'
          onClick={() => submitForm()}>
          <IntlMessages id='common.save' />
        </Button>
        <Button
          className={classes.btnRoot}
          color='secondary'
          onClick={onCloseAddTask}>
          <IntlMessages id='common.cancel' />
        </Button>
      </Box>
    </Form>
  );
};

export default AddCardForm;
