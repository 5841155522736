import { VIEW_TYPE } from 'redux/reducers/Auth';
import { SketchfabToken, ThirdPartyToken } from 'types/models/dataAccess/User';
import { FilterDataSpaces, SpaceData } from 'types/models/home/HomeApp';
import {AuthUser} from '../models/AuthUser';

export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const FORGET_PASSWORD_EMAIL = 'FORGET_PASSWORD_EMAIL';
export const SIGNOUT_AUTH_SUCCESS = 'SIGNOUT_AUTH_SUCCESS';
export const AWAITING_VERIFICATION = 'AWAITING_VERIFICATION';

export const GET_SPACES_LIST = 'GET_SPACES_LIST';
export const SET_SPACE_LIST = 'SET_SPACE_LIST';
export const GET_SHARED_SPACES_LIST = 'GET_SHARED_SPACES_LIST';
export const SET_CATALOG_ITEMS = 'SET_CATALOG_ITEMS';
export const SET_THIRD_PARTY_TOKENS = 'SET_THIRD_PARTY_TOKENS';
export const SET_SPACE_VIEW_TYPE = 'SET_SPACE_VIEW_TYPE';
export const SET_FILTER_DATA_SPACES = 'SET_FILTER_DATA_SPACES';
export interface SetAuthTokenActions {
  type: typeof SET_AUTH_TOKEN;
  payload: string | null;
}

export interface SetForgetPasswordActions {
  type: typeof FORGET_PASSWORD_EMAIL;
  payload: string | null;
}

export interface UpdateAuthUserActions {
  type: typeof UPDATE_AUTH_USER;
  payload: AuthUser | null;
}
export interface AwaitingVerificationActions {
  type: typeof AWAITING_VERIFICATION;
  payload: boolean;
}
export interface SignoutAuthUserActions {
  type: typeof SIGNOUT_AUTH_SUCCESS;
}
export interface GetSpacesAction {
  type: typeof GET_SPACES_LIST;
  payload: SpaceData[];
}
export interface SetSpacesAction {
  type: typeof SET_SPACE_LIST;
  payload: SpaceData[];
}

export interface SetCatalogItemsAction {
  type: typeof SET_CATALOG_ITEMS;
  payload: any[];
}

export interface GetSharedSpacesAction {
  type: typeof GET_SHARED_SPACES_LIST;
  payload: SpaceData[];
}

export interface SetThirdPartyTokensAction {
  type: typeof SET_THIRD_PARTY_TOKENS;
  payload: ThirdPartyToken[];
}

export interface SetSpaceViewTypeAction {
  type: typeof SET_SPACE_VIEW_TYPE;
  payload: VIEW_TYPE;
}
export interface SetFilterDataSpacesAction {
  type: typeof SET_FILTER_DATA_SPACES;
  payload: FilterDataSpaces;
}

export type AuthActions =
  | UpdateAuthUserActions
  | SetForgetPasswordActions
  | SetAuthTokenActions
  | SignoutAuthUserActions
  | AwaitingVerificationActions
  | SetCatalogItemsAction
  | GetSharedSpacesAction
  | GetSpacesAction
  | SetSpacesAction
  | SetThirdPartyTokensAction
  | SetSpaceViewTypeAction
  | SetFilterDataSpacesAction
  ;
