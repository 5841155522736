import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {CollaboratorObj} from '../../../../../../types/models/apps/ProjectBoard';
import {getUserAvatar,avatarName} from "../../../../../../@crema/utility/Utils";
import { useAuthUser } from '@crema/utility/AppHooks';
import {blueGrey} from '@material-ui/core/colors';

interface CollaboratorsProps {
  collaborators: CollaboratorObj[];
}

const Collaborators: React.FC<CollaboratorsProps> = ({collaborators}) => {
  const useStyles = makeStyles(theme => ({
    avatarRroot: {
      width: 35,
      height: 35,
      marginRight: 8,
      backgroundColor: blueGrey[700]
    },
  }));

  const classes = useStyles();
  const authUser = useAuthUser()
  return (<Box display='flex' alignItems='center'>
      {collaborators.map(member => {
        return (
          <Tooltip arrow  title={member?.name} key={member?.id}>
            {member?.image ? (
              <Avatar
                className={classes.avatarRroot}
                src={member?.image}
                alt='created'
              />
            ) : (
              <Avatar className={classes.avatarRroot}>
               {avatarName(member?.name)}
              </Avatar>
            )}
          </Tooltip>
        );
      })}
    </Box>
  );
};

export default Collaborators;
