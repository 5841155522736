import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import AppsContainer from '../../../../@crema/core/AppsContainer';
import ProjectDetailView from './ProjectDetailView';
import {onGetMemberList, onGetProjectLabelList} from '../../../../redux/actions';
import {useHistory, useLocation} from 'react-router-dom';
import {
  onGetProjectDetail,
  onNullifyProjectDetail,
} from '../../../../redux/actions';
import {AppState} from '../../../../redux/store';
import {ProjectObj} from '../../../../types/models/apps/ProjectBoard';
import {useParams} from 'react-router-dom';
import { ProjectParamsProps } from '..';
import { HomeRoutes } from 'types/models/home/HomeApp';

const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer',
  },
}));

const ProjectDetail = () => {
  const history = useHistory();
  const projectDetail = useSelector<AppState, ProjectObj | null>(({projects}) => projects.projectDetail );

  const currentSpaceProjectDid = useSelector<AppState, string>(
    ({ home }) => home.currentSpace?.currentSpaceProject.did || ''
  );
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const params = useParams<ProjectParamsProps>();

  useEffect(() => {
    // const path = pathname.split('/');
    // const did = path[path.length - 1];
    const did = params.id||params.pDid;

   // if project details open from space
   if(pathname.includes(HomeRoutes.TASKS) && currentSpaceProjectDid){
      dispatch(onGetProjectDetail(currentSpaceProjectDid));
    } else{
    // if project details open from project board side
    dispatch(onGetProjectDetail(did));
    // dispatch(onGetProjectLabelList(did));
    // dispatch(onGetMemberList(did));
    }
    return () => {
      if(!pathname.includes(HomeRoutes.TASKS))
        dispatch(onNullifyProjectDetail());
    };
  }, [dispatch, pathname]);

  const onGoToProjectList = () => {
    history.goBack();
  };

  const classes = useStyles();
  if (!projectDetail) {
    return null;
  }

  return (
    <AppsContainer
      cardStyle={{backgroundColor: 'transparent'}}
      appContainerStyle={{height: '95%'}}
      fullView
      title={
        // !pathname.includes(HomeRoutes.TASKS) ?
          <>
            <Box
              mr={2}
              component='span'
              color='primary.main'
              className={classes.pointer}
              onClick={onGoToProjectList}>
              Project
            </Box>
            {projectDetail?.name}
          </>
          // : null
      }>
      {projectDetail && <ProjectDetailView
      projectDetail={projectDetail}
      />}
    </AppsContainer>
  );
};

export default ProjectDetail;
