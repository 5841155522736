import React from 'react';
import { authRole } from '../../shared/constants/AppConst';

export const adminConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/admin',
        component: React.lazy(() => import('./Admin')),
      }
    ],
  }
];
