import { IInteractionDragBeginEvent } from "../SubSystems/core/Behaviors";
import Simulation from "../SubSystems/core/Simulation";
import { IComponentEventSpy, ComponentInteractionType, SceneComponent, ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { Logic, LogicEngine, OBJECT_EVENT_TYPES } from "../../../../types/models/dataAccess/Logic";
import { store } from "App";
import { areNodesSpatialThinkSDK, getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { onSetVariableValue } from "redux/actions";
import { logUserActivity, onShowPropertiesPanel } from "redux/actions/Home";
import { ACTIONS, getNodePropsFromNode, OBJECT_TYPES } from "types/models/home/HomeApp";
import { SceneNode } from "CustomSdk/Mimick/SceneNode";
import modelMap from "modules/home/models";
import { SELECT_NODE, SET_SELECTED_NODE_ID } from "types/actions/ThreeD.actions";

export class AddObjectClickSpy implements IComponentEventSpy<IInteractionDragBeginEvent> {
    public eventType = ComponentInteractionType.CLICK;
    public static ptrToSetActionToNode: ((node: ISceneNode, i: number) => void) | undefined = undefined;
    public static ptrToSetConditionToNode: ((node: ISceneNode, i: number, isSecondObject: boolean,
        optionalData?: { subObjectName?: string, localCollidingPoint?: { x: number, y: number, z: number }, component?: SceneComponent }) => void) | undefined = undefined;
    // public static dispatch: any | undefined = undefined;
    public static objectSelectionState: number = -1;
    public static objectSelectionStateIsObject2: boolean = false;
    public static actionOrConditionStatePointer: React.Dispatch<React.SetStateAction<number>> | undefined = undefined;

    constructor(private mainComponent: SceneComponent, private node: ISceneNode) {
    }

    public static executeClick(node: ISceneNode | SceneNode, payload?: { eventData: IInteractionDragBeginEvent, args: any }) {

        console.log(`[vars] [st] clicked`, node, getNodePropsFromNode(node), payload);
        store.dispatch({type: SET_SELECTED_NODE_ID, payload: node.userData.id});
        let subObjectName = '';
        if (payload?.eventData?.collider) {
            subObjectName = payload.eventData.collider.name;
        }

        if ((payload as any)?.collider) {
            subObjectName = (payload as any).collider?.name;
        }

        let currentSpace = store.getState().home.currentSpace;

        !areNodesSpatialThinkSDK() && Simulation.instance.resetSimulationMode();

        if (!store.getState().layer.presentationMode
            && !areNodesSpatialThinkSDK()
            // && (store.getState().home.currentSidebarIndex !== SIDEBAR_INDEX.DATA)
            && (AddObjectClickSpy.objectSelectionState == -1)
        ) {
            Simulation.instance.selectNodeWithPropertiesUpdate(node).then(() => {
                store.dispatch(onShowPropertiesPanel(true));
            })
        }

        if (!!store.getState().layer.presentationMode && !Simulation.instance.grabbedObject) {
            console.log('[st] [nodes] selected node');
            Simulation.instance.lastSelectedNode = node;
        }

        if (AddObjectClickSpy.ptrToSetActionToNode) {
            AddObjectClickSpy.ptrToSetActionToNode(node, AddObjectClickSpy.objectSelectionState);
        }

        if (AddObjectClickSpy.ptrToSetConditionToNode) {
            // save it to the more specific component or subObject. User can remove the more specific object from the Condition UI
            if (modelMap.get(node.name)) {
                if (!!(payload as any).component?.inputs?.showInConditions) {
                    AddObjectClickSpy.ptrToSetConditionToNode(node, AddObjectClickSpy.objectSelectionState,
                        AddObjectClickSpy.objectSelectionStateIsObject2,
                        ({ component: (payload as any).component as SceneComponent }));
                } else {
                    AddObjectClickSpy.ptrToSetConditionToNode(node, AddObjectClickSpy.objectSelectionState,
                        AddObjectClickSpy.objectSelectionStateIsObject2);
                }
            } else if (payload?.eventData.point) {
                let nodeObj = ((node as any)?.obj3D as THREE.Object3D | undefined);
                if (nodeObj) {
                    let localCollidingPoint = nodeObj.worldToLocal(payload.eventData.point);
                    AddObjectClickSpy.ptrToSetConditionToNode(node, AddObjectClickSpy.objectSelectionState,
                        AddObjectClickSpy.objectSelectionStateIsObject2,
                        (subObjectName ? { subObjectName: subObjectName, localCollidingPoint: localCollidingPoint } : undefined));
                }



            }
        }

        if (node.userData?.inputSource1) {

            if (!node.userData?.noClickVarChange) {
                let v = store.getState().layer.variableValues.find(v => v.name == node.userData?.inputSource1);
                let range = (v?.values.split(',') || []).map((v: string) => v.trim());
                let i = range.findIndex((vv: any) => vv == v.value);
                if (i > -1) {
                    let j = (i + 1) % range.length;
                    console.log(`[vars] from ClickSpy: setting var value to ${range[j]}. Var updates from if-else should happen after this.`)
                    store.dispatch(onSetVariableValue(node.userData?.inputSource1, range[j]));
                }
            }
        }

        // this.node.addComponent('mp.light', {});

        // Behaviors.instance.processAllNodeClickEvents(this.node);

        let currentLessonId = store.getState().layer.currentLesson?.id;
        let currentSpaceId = currentSpace?.id;
        let tg = getCurrentTagGroup();
        if (store.getState().layer.presentationMode) {
            store.dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.NODE, node.userData.id, { currentStepId: tg?.id }));
        }

        if (currentSpaceId && currentLessonId && tg?.logic) {
            // if (Simulation.instance.grabbedObject) {
            //     Simulation.instance.dragging = false;
            //     Simulation.instance.grabbedObject.position.copy(node.position);

            //     LogicEngine.processStepLogicConditions({
            //         tgId: tg.id, stepLogic: tg.logic as Logic, targetNode: node,
            //         targetNodeList: [node.userData?.id || '', Simulation.instance.grabbedObject?.userData?.id || ''],
            //         eventType: OBJECT_EVENT_TYPES.COLLIDES
            //     }

            //     );


            // } else {
            LogicEngine.processStepLogicConditions({
                tgId: tg.id, stepLogic: tg.logic as Logic, targetNode: node,
                subObjectName: subObjectName,
                // true, false
                eventType: OBJECT_EVENT_TYPES.CLICK
            });
            // }
        }
        Simulation.instance.grabbedObject = null;

        if (!currentLessonId && !node.userData?.noClickVarChange) {
            Simulation.instance.processNodeClickOutsideLesson(node);
        }
    }

    async onEvent(payload: any) { //payload type should be {type: 'INTERACTION.CLICK', node: NodeFacade, component: CustomGLTFLoader, eventData: IInteractionDragBeginEvent}

        console.log(`[vars] [st] clicked`, JSON.stringify(this.node.name), this.node, getNodePropsFromNode(this.node), this.mainComponent);
        AddObjectClickSpy.executeClick(this.node, payload);
    }


}
