import { ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "../SubSystems/ui-interop/PropertiesPanel";
import * as TWEEN from '@tweenjs/tween.js'

export default abstract class BaseAnimation {
    protected animationList: { [key: string]: boolean | null };

    protected constructor() {
        this.animationList = {};
        console.log("base init");
    }

    public PlayAnimation(node:ISceneNode, meshes:THREE.Object3D[], extraParams: { [key: string]: any } | null = null):void {
        if(!meshes) {
            console.error("No meshes found in the component")
            return;
        }

        if(this.animationList[node.userData[UserDataProperties.id]]) {

        } else {
            this.animationList[node.userData[UserDataProperties.id]] = true;

            // let newPosition = node.fakePosition.clone();
            // if((extraParams as any).deltaPosition) {
            //     newPosition.add((extraParams as any).deltaPosition);
            // }

            // new TWEEN.Tween(node.position)
            // .to(
            //     {
            //         x: newPosition.x,
            //         y: newPosition.y,
            //         z: newPosition.z
            //     },
            //     (extraParams as any).duration ? (extraParams as any).duration : 1000
            // )
            // .easing(TWEEN.Easing.Cubic.InOut)
            // .start()
            // .onComplete(() =>
            // {
            //     //super._animation(node.userData[UserDataProperties.id], null, null);
            //     this.animationList[node.userData[UserDataProperties.id]] = null;
            // })
            // .onUpdate((object: THREE.Vector3, elapsed: number) => {
            //     console.log(object)
            // });

            // this._rootAnimation(node, extraParams);

            for(let mesh of meshes) {
                this._animation(node, mesh, extraParams);
            }
        }
    }

    protected _animationComplete(node:ISceneNode):void {
        this.animationList[node.userData[UserDataProperties.id]] = null;
    }

    protected _rootAnimation(node:ISceneNode, extraParams: { [key: string]: any }|null):void {

    }

    protected _animation(node:ISceneNode, mesh:THREE.Object3D|null, extraParams: { [key: string]: any }|null):void {
        this.animationList[node.userData[UserDataProperties.id]] = null;
    }
}
