import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { Badge, Tooltip, Typography, Box, Avatar } from '@material-ui/core';
import { OverflowMenu } from 'modules/home/components/OverflowMenu';
import TextFormatRoundedIcon from '@mui/icons-material/TextFormatRounded';
import ThreeDRotationRoundedIcon from '@mui/icons-material/ThreeDRotationRounded';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import HighlightAltRoundedIcon from '@mui/icons-material/HighlightAltRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
// import { CustomRotateIcon, ThreeDIcon } from './Icons/CustomIcons'
import { ReactComponent as ThreeDIcon2Svg } from 'assets/images/3dIcons/ThreeDIcon2.svg';
import clsx from 'clsx';
import BadgeBeta from './BadgeBeta';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: 'theme.palette.background.paper',
        height: "100%",
        paddingTop: '8px'
    },
    tile: {
        borderRadius: '8px',
        '& .MuiGridListTile-tile': {
            cursor: 'pointer',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '16px',
            '&:hover': {
                borderRadius: 'inherit',
                backgroundColor: fade(theme.palette.primary.main, 0.1),
                transform: 'translateY(-4px)',
                '& .fav-btn': {
                    opacity: 1,
                    visibility: 'visible',
                },
                // "& .overflowMenu": {
                //     transform: "translateX(-22px)",
                //     opacity: 1,
                //     visibility: "visible",
                //     color: 'red'
                // },
            },
        }
    },
    tileItem: {
        '&:hover': {

            "& .hideDefault": {
                // transform: "translateX(0px)",
                opacity: 1,
                visibility: "visible",
                color: 'red'
            },
        },

    },
    tileTitle: {
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '4px',
        maxWidth: "100%",
        overflow: "hidden",
        // '&:hover': {
        //     overflow: 'visible',
        //     maxWidth: "",
        //     marginTop: '-12px'
        // }

    },
    itemRoot: {
        // minWidth: '100px',
        // maxWidth: '100px'
        borderRadius: '8px',
    },
    hideDefault: {

    },
    overflowMenu: {
        maxHeight: '10px',
        maxWidth: '10px',
        position: 'absolute',
        // right: '22px',
        top: '2px',
        zIndex: 3,

        transition: "all 0.5s ease",
        transform: "translateX(80px)",
        // opacity: 0,
        // visibility: "hidden",


        // display: '',
        '& .MuiIconButton-root': {
            padding: '2 2'
        },
        // '&:hover': {
        // display: 'block',
        // backgroundColor: 'red'
        // },
        // '&:hover': {

        //     // "& .hideDefault": {
        //     // transform: "translateX(0px)",
        //     opacity: 1,
        //     visibility: "visible",
        //     color: 'red'
        // },
    },
    gridList: {
        // width: 1000,
        // height: 1000,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    deleteBadge: {
        '& .MuiBadge-colorPrimary': {
            backgroundColor: 'transparent'

        }
    }
}));

interface TileData {
    id: string;
    img: any;
    title: string;
    onClick: () => void;
    onDelete: () => void;
    hoverInfo?: string;
}
interface Props {
    tileData: TileData[];
    hasOverflowMenu?: boolean;
}

export default function TitlebarGridList({ tileData, hasOverflowMenu }: Props) {
    const classes = useStyles();

    //   console.log(`[st] ${JSON.stringify(tileData)}`);

    const [showHoverInfo, setShowHoverInfo] = React.useState(false);

    return (
        <div className={classes.root}>
            <GridList
                cellHeight={"auto"}
                spacing={3} cols={3} className={classes.gridList}>
                {/* <GridListTile key="Subheader"  style={{ height: 'auto' }}>
          <ListSubheader component="div"></ListSubheader>
        </GridListTile> */}
                {tileData.map((tile: any, index: number) => (

                    <BadgeBeta key={index} invisible={!tile.hasBadgeBeta}>
                        <div style={{ width: '100%' }}>
                            <Tooltip key={index} title={
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>{tile.title}</div>
                                    <div style={{
                                        // color: 'red',
                                        // backgroundColor: '#fff'
                                    }}>{tile.hoverInfo}</div>

                                </div>}>

                                <GridListTile key={tile.id} className={classes.tile}>
                                    <TileItem tile={tile} hasOverflowMenu={hasOverflowMenu} />
                                </GridListTile>
                            </Tooltip>
                        </div>
                    </BadgeBeta>
                ))}
            </GridList>
        </div>
    );
}

export function getImageIcon(imgSrc: string, altText: string) {
    return !imgSrc ? <Avatar style={{}}>{altText.substring(0, Math.min(2, altText.length))}</Avatar> :
        <img style={{ borderRadius: '8px', minHeight: "50px", maxHeight: "50px", overflow: "hidden", maxWidth: "100%" }}
            src={imgSrc || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
            alt={altText}
        />
}

export const TileItem: React.FC<any> = ({ tile, hasOverflowMenu }) => {

    // const [isHovering, setHovering] = React.useState(false);
    const classes = useStyles();
    return (<div className={classes.tileItem}>
        {hasOverflowMenu && <span className={clsx([classes.overflowMenu,
            // classes.hideDefault
        ])}>
            <OverflowMenu menuItems={[{ title: "Delete", onClick: () => { tile.onDelete() }, isDisabled: tile.isDisabled }]} />
        </span>}
        <Box onClick={tile.onClick}
            // onHover={() => setHovering(true)}
            style={{
                height: '70px', minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '12px'
            }}>
            {/* <img style={{ height: "50px" }} src={tile.img || "https://onlinepngtools.com/images/examples-onlinepngtools/400-by-400.png"}
         alt={""} /> */}
            {/* <ArrowDownwardRoundedIcon /> */}

            {tile.iconMui ? <tile.iconMui /> : (tile.img ? getImageIcon(tile.img, tile.title) :
                // <ThreeDIcon color="black"/>
                <ThreeDIcon2Svg style={{ fill: "black", height: 23 }} />
            )}
            <div className={classes.tileTitle} >{tile.title}</div>
        </Box>
    </div>)

}