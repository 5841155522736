import { ISceneNode } from '../sceneManagement/SceneComponent';
import QueueScheduler from '../../Tools/QueueScheduler';
import Simulation from '../core/Simulation';
import { ISceneNodeExtensions } from '../../extensions/ISceneNodeExtensions';
import { EulerIntegrator } from '../../components/systemComponents/EulerIntegrator';


interface StackElement {
    node: ISceneNode;
    position: THREE.Vector3;
    rotation: THREE.Quaternion;
    scale: THREE.Vector3;
    eulerRotationArray: any[] | undefined;
    id: number;
}

export default class UndoStack {
    private stack: Array<StackElement>;
    private currentIndex: number;
    private limit: number;
    private time: number = 0;
    private updateInterval: number = 500;

    constructor(limit = 1000) {
        this.stack = [];
        this.currentIndex = -1;
        this.limit = limit;
        EulerIntegrator.instance?.integrators.push(this.onTick.bind(this));
    }

    private onTick(deltaTime: number) {
        if (this.time > 0) {
            this.time -= deltaTime;
        }
    }

    /**
     * Add an operation to the stack
     * If the number of operations has hit the limit, remove the oldest one
     */
    push(stackElement: StackElement): void {
        // We need to remove the operations after the current index
        // because we need to create a new branch of history
        this.stack = this.stack.slice(0, this.currentIndex + 1);
        console.log('[UNDO]', 'added to stack', stackElement.id, this.stack.length, this.currentIndex);
        this.stack.push(stackElement);

        // Ensure that the stack size does not exceed the limit
        while (this.stack.length > this.limit) {
            this.stack.shift();
            this.currentIndex--;
        }

        // Set the current index to the top of the stack
        this.currentIndex = this.stack.length - 1;
    }

    /**
     * Execute the undo operation and move the index back
     */
    undo(): void {
        console.log('[UNDO]', this.currentIndex );
        if (this.currentIndex < 0) {
            console.log('[UNDO]', "Nothing to undo");
            return;
        }

        const element = this.stack[this.currentIndex];
        this.currentIndex--;

        if (element) {
            console.log('[UNDO]', 'undo from stack euler', element, this.stack.length, this.currentIndex);
            ISceneNodeExtensions.setPosition(element.node, element.position);
            ISceneNodeExtensions.setRotation(element.node, element.rotation);
            ISceneNodeExtensions.setScaleFromAny(element.node, element.scale);
            ISceneNodeExtensions.setEulerRotation(element.node, element.eulerRotationArray);
        }
    }

    /**
     * Executes the redo operation and move the index forward
     */
    redo(): void {
        console.log('[UNDO]', this.currentIndex);

        

        //Check if currentIndex is out of bounds
        if (this.currentIndex >= this.stack.length) {
            console.log('[UNDO]', "Nothing to redo");
            return;
        }
        
        this.currentIndex++;
        
        const element = this.stack[this.currentIndex];
        console.log('[UNDO]', 'redo from stack euler', element, this.stack.length, this.currentIndex);

        ISceneNodeExtensions.setPosition(element.node, element.position);
        ISceneNodeExtensions.setRotation(element.node, element.rotation);
        ISceneNodeExtensions.setScaleFromAny(element.node, element.scale);
        ISceneNodeExtensions.setEulerRotation(element.node, element.eulerRotationArray);
    }

    /**
     * Check if there are operations in the stack
     */
    isEmpty(): boolean {
        return this.stack.length === 0;
    }

    public addNodeToUndoStack(
        node: ISceneNode,
        position: THREE.Vector3,
        rotation: THREE.Quaternion,
        scale: THREE.Vector3,
        wasJustPlaced: boolean
    ): void {
        if (this.time > 0) {
            return;
        }

        if (wasJustPlaced) {
            return;
        }
        let eulerRotationArray = node?.userData?.eulerRotation ? [...node.userData.eulerRotation] : undefined;
        let id = this.currentIndex;
        const stackElement: StackElement = {
            node,
            position,
            rotation,
            scale,
            eulerRotationArray,
            id
        };
        this.push(stackElement);
        this.time = this.updateInterval;
    }
}