import { RenderingSystem } from '../Systems/RenderingSystem';
import { BaseComponent } from './BaseComponent';

import * as THREE from 'three';
import { DAEComponent } from './DAEComponent';
import { Interactable } from './Others/Interactable';
import { SpatialThinkSDK } from '../SpatialThinkSDK';
import {RaycastSystem} from '../Systems/RaycastSystem';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

interface Inputs {
    rotationAxis: { x: number, y: number, z: number };
    rotationRange: number[];
    //toggle: boolean;
    state: number;
    transitionTime: number;
    // localScale: { x: number; y: number; z: number; };
    localPosition: { x: number; y: number; z: number; };
    // localRotation: { x: number; y: number; z: number; };
}

export class RotateToggleComponent extends BaseComponent {
    inputs: Inputs = {
        rotationAxis: { x: 0, y: 0, z: 1 },
        rotationRange: [0, 90],
        //toggle: false,
        state: 0,
        transitionTime: 0.3,
        localPosition: { x: 0, y: 0, z: 0 },
    };
    daeComponent: DAEComponent;

    private goalRotation: THREE.Quaternion;
    private initialized: boolean = false;
    private currentAnimationTime: number;
    // private _interactable: Interactable;
    private clickable: THREE.Object3D;

    // hide(){
    //     this.daeComponent.hide();
    // }

    // onEvent(eventType: any) {
    //     switch eventType {
    //         case 'click':

    //     }
    // }

    setNextInputValue() {

        let newState = this.inputs.state + 1;
        if (newState >= this.inputs.rotationRange.length) {
            newState = 0;
        }
        this.inputs.state = newState;
    }

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = "RotateToggleComponent";

        this.daeComponent = new DAEComponent(renderingSystem, "/assets/3d/Lever/Lever.dae", () => this.setLoaded(true));

        this.currentAnimationTime = 0;
        // this._interactable = new Interactable();
    }

    onInputsUpdated(oldInputs: Inputs, force:boolean) {
        if (oldInputs.state !== this.inputs.state || force) {
            this.prepareClips();
            this.currentAnimationTime = 0;
        }
    }

    onInit(userdata: { [p: string]: any }) {
        super.onInit(userdata);
        this.daeComponent.onInit(userdata);
        this.daeComponent.root.scale.set(0.3, 0.3, 0.3);
        this.daeComponent.rebase(this.root);

        // this.root.userData = { nodeUserData: this.node.userData }

        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onTick.bind(this));

        // this._interactable.addAction(this.onClick.bind(this));
        this.currentAnimationTime = 0;

        this.outputs.collider = this.root;
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.root);
    }

    onTick(deltaInSeconds: number) {
        // var deltaInSeconds = delta * 0.001;

        if (this.daeComponent.root) {

            // if (this.daeComponent.loaded) {
                // if (this.daeComponent.root.userData[Interactable.type] == null) {
                // this._interactable.addObject([this.daeComponent.root]);
                // }
            // }

            if (!this.initialized) {
                this.initialized = true;
                this.prepareClips();
                this.daeComponent.root.rotation.setFromQuaternion(this.goalRotation);
            } else {
                if (this.currentAnimationTime < this.inputs.transitionTime) {
                    let currentRotation = new THREE.Quaternion().setFromEuler(this.daeComponent.root.rotation);
                    currentRotation = currentRotation.slerp(this.goalRotation, this.currentAnimationTime / this.inputs.transitionTime);
                    this.daeComponent.root.rotation.setFromQuaternion(currentRotation);
                    this.currentAnimationTime += deltaInSeconds;
                } else {
                    this.daeComponent.root.rotation.setFromQuaternion(this.goalRotation);
                }
            }
        }
    }

    prepareClips() {
        let rotationAxisFinal = new THREE.Vector3(this.inputs.rotationAxis.x, this.inputs.rotationAxis.y, this.inputs.rotationAxis.z);

        for (let i = 0; i < this.inputs.rotationRange.length; i++) {
            if (typeof this.inputs.rotationRange[i] == 'string') {
                this.inputs.rotationRange[i] = Number.parseInt(this.inputs.rotationRange[i].toString());

            }
        }
        this.goalRotation = new THREE.Quaternion().setFromAxisAngle(rotationAxisFinal, this.inputs.rotationRange[this.inputs.state] * THREE.MathUtils.DEG2RAD);
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }

}