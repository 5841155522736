import * as THREE from 'three';
import {Intersection} from 'three';
import {RenderingSystem} from './RenderingSystem';
import {SpatialThinkSDKMode} from '../SpatialThinkSDK';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { AddObjectClickSpy } from 'mp/core/craEngine/spies/AddObjectClickSpy';
import { store } from 'App';
import { ISceneNode } from 'mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import { SceneNode } from 'CustomSdk/Mimick/SceneNode';
import { Logic, LogicEngine, OBJECT_EVENT_TYPES } from 'types/models/dataAccess/Logic';
import { getCurrentTagGroup } from 'modules/home/SpaceDetail/utils';
import { UserDataEventTypes, UserDataProperties } from '../../mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel';
import { TriggerState } from './InputSystem';
import { BaseComponent } from '../Components/BaseComponent';
import { SET_EDIT_SHOWCASE_TAG_ID, SET_OPEN_TAG_FORM } from 'types/actions/Home.action';

export class RaycastSystem {
    protected raycaster: THREE.Raycaster;
    // protected oldScales: Map<THREE.Object3D, THREE.Vector3>;
    protected rayworld: Map<string, THREE.Object3D>;
    protected tempMatrix = new THREE.Matrix4();
    protected _selected: THREE.Object3D | null;
    protected _offset: THREE.Vector3;
    protected _intersection: THREE.Vector3;
    protected _worldPosition: THREE.Vector3;
    protected _inverseMatrix: THREE.Matrix4;
    protected _plane: THREE.Plane;
    protected _intersections: THREE.Intersection[];
    private dragged = false;

    //Instance
    // private static _instance: RaycastSystem;

    constructor(protected renderingSystem: RenderingSystem) {
        // RaycastSystem._instance = this;

        this._offset = new THREE.Vector3();
        this._intersection = new THREE.Vector3();
        this._worldPosition = new THREE.Vector3();
        this._inverseMatrix = new THREE.Matrix4();

        this._plane = new THREE.Plane();

        this.raycaster = new THREE.Raycaster();
        // this._spareRaycaster = new THREE.Raycaster();
        // this.renderSystem.cameraSystem.windowingSystem.registerFrameUpdateCallback(this.onEnterFrame.bind(this));

        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerMouseMoveCallback(this.onMouseMove.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerMouseDownCallback(this.onMouseDown.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerMouseUpCallback(this.onMouseCancel.bind(this));

        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerMouseClickCallback(this.onClick.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectCallback(this.onSelect.bind(this));
        this.rayworld = new Map<string, THREE.Object3D>();
        // this.oldScales = new Map<THREE.Object3D, THREE.Vector3>();
    }

    public addRayWorldObject(object: THREE.Object3D) {
        this.rayworld.set(object.uuid, object);
        // console.log('[drag] add to rayworld', object, this.rayworld.values());
    }

    public removeRayWorldObject(object: THREE.Object3D) {

        this.rayworld.delete(object.uuid);
        // console.log('[drag] remove from rayworld', object, this.rayworld.values());
    }

    public setupForAR() {
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.clearXRCallbacks();
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectCallback(this.onSelect.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectStartCallback(this.onDragStart.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectEndCallback(this.onDragEnd.bind(this));
        // this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSqueezeStartCallback(this.onSelect.bind(this));
    }

    public setupForVR() {
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.clearXRCallbacks();
        // this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectCallback(this.onSelect.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSqueezeStartCallback(this.onDragStart.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSqueezeEndCallback(this.onDragEnd.bind(this));

        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSelectEndCallback(this.vrSelectEnd.bind(this));
        // this.renderingSystem.cameraSystem.windowingSystem.inputSystem.registerXROnSqueezeStartCallback(this.onSelect.bind(this));
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.onDraggingListen.bind(this));

    }

    // vrSelectStart(event: any) {
    //     const controller = event.target;
    //     this.detachControllerObjects(controller);
    //
    //
    //     controller.userData.isClicking = true;
    //     // // let objects = Array.from(this.objects.values());
    //     // for (let i = 0; i < objects.length; i++) {
    //     // const intersections = this.getIntersection(controller);
    // }

    vrSelectEnd(event: any) {
        const controller = event.target;

        if (controller.userData.trigger1State == TriggerState.Pressing) {
            this.processClickIntersection(controller);
            // console.log('[vr] select end (click)', controller);
        }
    }

    onClick(e: MouseEvent) {

        if (!this.dragged && Simulation.instance.sdk?.Mode == SpatialThinkSDKMode.Plain) {
            // console.log('mouse click', this.dragged);
            this.processClickIntersection(null,
                // this.getIntersections(null, Array.from(this.rayworld.values()))
                // this.raycaster.intersectObject(this.renderingSystem.base, true)
            );
        }
        this.dragged = false;
    }

    public onDragStart(event: any) {

        const controller = event.target;
        this.detachControllerObjects(controller);
        // // let objects = Array.from(this.objects.values());
        // for (let i = 0; i < objects.length; i++) {
        // const intersections = this.getIntersection(controller);

        let intersectedNodes = this.getIntersectedNodes(controller)?.nodes;

        if (intersectedNodes && intersectedNodes.length > 0) {
            // console.log('[xr] intersected nodes', intersectedNodes.length, intersectedNodes)
            for (const intersectedNode of intersectedNodes) {
                if (UserDataProperties.Events in intersectedNode.userData) {
                    // console.log('[xr] onSelectStart. found events', )
                    if ((intersectedNode.userData[UserDataProperties.Events]).includes(UserDataEventTypes.Drag)) {
                        const object = (intersectedNode as SceneNode).customComponents[0]?.root;
                        controller.attach(object);
                        controller.userData.selected = object;
                        break;
                    }
                }
            }

            // let intersectedNode = intersectedNodes[0];
            // let object = (intersectedNode as SceneNode).customComponents[0]?.root;
            //
            // controller.attach(object);
            // controller.userData.selected = object;
            // controller.userData.previousParent = object.parent;

            // break;
        }
        // }
    }

    public onDraggingListen(event: any) {
        let controllers = this.renderingSystem.cameraSystem.windowingSystem.inputSystem.getControllers();
        if (controllers && controllers.length > 0) {
            for (let controller of controllers) {
                // let controller = controllers[0];
                if (controller.userData.trigger2State == TriggerState.Pressing) {
                    console.log('[vr] dragging', controller);
                    let collidedNodes = this.getIntersectedNodes(controller)?.nodes;

                    console.log('[vr] collided nodes', collidedNodes?.length, collidedNodes);
                    if (collidedNodes && collidedNodes.length > 1) {
                        // console.log('[st] collided nodes', collidedNodes);
                        store.getState().layer.currentLesson && store.getState().layer.currentTagGroupId &&
                        LogicEngine.processStepLogicConditions({
                            tgId: store.getState().layer.currentTagGroupId,
                            stepLogic: getCurrentTagGroup()?.logic as Logic,
                            eventType: OBJECT_EVENT_TYPES.COLLIDES,
                            targetNodeList: collidedNodes.map(n => n.userData?.id || ''),
                        });
                    }
                }
            }
        }

    }

    protected onSelect(event: any) {
        const controller = event.target;
        this.processClickIntersection(
            controller,
            // this.getIntersections(event.target, Array.from(this.rayworld.values()))
        );
    }

    protected onMouseCancel(): void {
        this._selected = null;
    }

    public onDragEnd(event: any) {
        const controller = event.target;
        this.detachControllerObjects(controller);

        // console.log('[xr] onSelectEnd. processing click', controller.userData.selected)
        // if (!this.dragged) {
        //     this.processClickIntersection(controller);
        // }
    }

    private detachControllerObjects(controller: any) {
        if (controller.userData.selected !== undefined) {
            const object = controller.userData.selected;
            this.renderingSystem.base.attach(object);
            controller.userData.selected = undefined;
        }
    }

    public onMouseDown(): void {
        // console.log('mouse down');

        // this._intersections.length = 0;
        // let objects = Array.from(this.objects.values());
        // this.raycaster.intersectObject(this.renderingSystem.base, true)
        // for (let i = 0; i < objects.length; i++) {

        this.raycaster.setFromCamera(this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Pointer,
            this.renderingSystem.cameraSystem.camera);

        // this._intersections = this.raycaster.intersectObject(this.renderingSystem.base, true);
        // this.raycaster.intersectObject(objects[i], true);

        // if (this._intersections.length > 0) {
        // this._selected = objects[i];

        let clickedNodes = this.getIntersectedNodes(null)?.nodes;
        if (clickedNodes && clickedNodes.length > 0) {
            for (const clickedNode of clickedNodes) {
                if (UserDataProperties.Events in clickedNode.userData) {
                    if ((clickedNode.userData[UserDataProperties.Events]).includes(UserDataEventTypes.Drag)) {

                        this._selected = (clickedNode as SceneNode).customComponents[0]?.root;

                        this._plane.setFromNormalAndCoplanarPoint(this.renderingSystem.cameraSystem.camera.getWorldDirection(this._plane.normal),
                            this._worldPosition.setFromMatrixPosition(this._selected.matrixWorld));

                        if (this.raycaster.ray.intersectPlane(this._plane, this._intersection)) {

                            this._inverseMatrix.copy(this._selected!?.parent!?.matrixWorld).invert();
                            this._offset.copy(this._intersection).sub(this._worldPosition.setFromMatrixPosition(this._selected.matrixWorld));

                        }

                        break;
                    }
                }
            }
        }
        // break;

        // _domElement.style.cursor = 'move';

        // scope.dispatchEvent( { type: 'dragstart', object: this._selected } );

        // }
        // }
    }

    public onMouseMove(): void {
        this.raycaster.setFromCamera(this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Pointer,
            this.renderingSystem.cameraSystem.camera);

        if (this._selected) {
            this.dragged = true;
            if (this.raycaster.ray.intersectPlane(this._plane, this._intersection)) {
                this._selected.position.copy(this._intersection.sub(this._offset).applyMatrix4(this._inverseMatrix));
            }
            // scope.dispatchEvent( { type: 'drag', object: _selected } );

            // let collisions = this.raycaster.intersectObject(this.renderingSystem.base, true);

            let collidedNodes = this.getIntersectedNodes(null)?.nodes;

            if (collidedNodes && collidedNodes.length > 1) {
                // console.log('[st] collided nodes', collidedNodes);
                store.getState().layer.currentLesson && store.getState().layer.currentTagGroupId &&
                LogicEngine.processStepLogicConditions({
                    tgId: store.getState().layer.currentTagGroupId,
                    stepLogic: getCurrentTagGroup()?.logic as Logic,
                    eventType: OBJECT_EVENT_TYPES.COLLIDES,
                    targetNodeList: collidedNodes.map(n => n.userData?.id || ''),
                });
            }
        }
    }

    // public getIntersections(controller:THREE.Group|null, objects: THREE.Object3D[]): Intersection[] {
    //     if(controller == null) {
    //         this.raycaster.setFromCamera(this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Pointer, this.renderingSystem.cameraSystem.camera);
    //         return this.raycaster.intersectObjects(objects, true);
    //     } else {
    //         this.tempMatrix.identity().extractRotation(controller!.matrixWorld);
    //         this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
    //         this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.tempMatrix);
    //         return  this.raycaster.intersectObjects(objects, true);
    //     }
    //     return [];
    // }

    public getIntersection(controller: THREE.Group | null): Intersection[] {
        if (controller == null) {
            this.raycaster.setFromCamera(this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Pointer, this.renderingSystem.cameraSystem.camera);
            // console.log('[drag] rayworld', this.rayworld.values());
            return this.raycaster.intersectObjects(Array.from(this.rayworld.values()), true);
        } else {
            controller.updateMatrix();
            controller.updateMatrixWorld(true);

            this.tempMatrix.identity().extractRotation(controller!.matrixWorld);
            this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
            this.raycaster.ray.direction.set(0, 0, -1).applyMatrix4(this.tempMatrix);
            return this.raycaster.intersectObjects(Array.from(this.rayworld.values()), true);
        }
    }

    public getIntersectedNodes(controller: THREE.Group | null): { nodes: ISceneNode[], objects: THREE.Object3D[], tagIds: string[] }
    // [ISceneNode[], THREE.Object3D[]] | null
    {
        let i = this.getIntersection(controller);

        let intersectedObjects: THREE.Object3D[] = [];

        // console.log(`[st] [ST] intersection length`, i.length);
        // let intersectedNodeId = '';

        let nodeIdSet = new Set<string>();
        let tagIdSet = new Set<string>();

        let nodes: ISceneNode[] = [];
        if (i.length > 0) {
            for (const intersect of i) {
                let nodeIds = RaycastSystem.getParentNodeId(intersect.object as THREE.Object3D);
                nodeIds.map(n => nodeIdSet.add(n));
                intersectedObjects.push(intersect.object);

                if ((intersect.object as THREE.Object3D).userData.tagId) {
                    tagIdSet.add((intersect.object as THREE.Object3D).userData.tagId);
                }
            }
        }

        // if (!intersectedNodeId) {
        //     return null;
        // }


        Array.from(nodeIdSet).map(nid => {
            let model = store.getState().home.spaceModels.get(nid);
            nodes.push(model?.nodeRef);
        });

        // console.log('[st] [sdk] );
        return { nodes: nodes, objects: intersectedObjects, tagIds: Array.from(tagIdSet) };

    }


    // public getRayWorldObjects() {
    //     return Array.from(this.rayworld.values())
    // }

    //return raycaster
    public get Raycaster(): THREE.Raycaster {
        return this.raycaster;
    }

    public static getParentNodeId(object: THREE.Object3D): string[] {
        let parentNodeIds = new Set<string>();

        if (object?.userData?.nodeUserData?.id) {
            // return object?.userData?.nodeUserData?.id;
            parentNodeIds.add(
                object?.userData?.nodeUserData?.id,
            );
        }
        let parent = object.parent;
        while (parent) {
            if (parent.userData?.nodeUserData?.id) {
                parentNodeIds.add(
                    parent.userData?.nodeUserData?.id,
                );

            }
            parent = parent.parent;
        }
        // console.error("[sd] never found parent node id", object);
        // return undefined;
        return Array.from(parentNodeIds);
    }

    public processClickIntersection(controller: THREE.Group | null) {
        // let nodeIdSet = new Set<any>();
        let intersectedNodesAndObjects = this.getIntersectedNodes(controller);
        console.log('intersectedNodesAndObjects ', store.getState().threeD.sdkMode, intersectedNodesAndObjects);
        this.executeClickOnNodes({ nodes: intersectedNodesAndObjects.nodes, objects: intersectedNodesAndObjects.objects });

        //Show tag divs if the clicked objects is a tag in AR, VR, plain scene
        this.executeClickOnTags(intersectedNodesAndObjects.tagIds);

    }

    private executeClickOnNodes(clickedNodesAndIntersectedObjects: { nodes: ISceneNode[], objects: THREE.Object3D[] }) {
        let clickedNodes = clickedNodesAndIntersectedObjects?.nodes;
        if (clickedNodes && clickedNodes.length > 0) {

            for (const clickedNode of clickedNodes) {

                if (UserDataProperties.Events in clickedNode.userData) {
                    if ((clickedNode.userData[UserDataProperties.Events]).includes(UserDataEventTypes.Click)) {
                        // console.log('[st] [sdk] calling click for node:', clickedNode);
                        clickedNode && AddObjectClickSpy.executeClick(clickedNode as ISceneNode);

                        break;
                    }
                }
            }
        }

        let intersectedObjects = clickedNodesAndIntersectedObjects?.objects;
        if (intersectedObjects && intersectedObjects.length > 0) {
            for (const intersectedObject of intersectedObjects) {
                if (intersectedObject.userData?.componentTarget) {
                    if (BaseComponent.componentMap[(intersectedObject.userData?.componentTarget)]) {
                        BaseComponent.componentMap[(intersectedObject.userData?.componentTarget)].onClick();
                    }
                }
            }
        }
    }

    public executeClickOnTags(tagIds: string[]) {
        console.log('[st] [sdk] executeClickOnTags', tagIds);
        if (tagIds && tagIds.length > 0) {
            let tagId = tagIds[0];

            switch (store.getState().threeD.sdkMode) {

                case SpatialThinkSDKMode.AR:
                    let arRootDiv = document.getElementById('xrOverlay');
                    arRootDiv && showTag(arRootDiv as HTMLDivElement, tagId)
                    break;

                case SpatialThinkSDKMode.VR:
                    let vrRootDiv = document.getElementById('vrOverlay');
                    vrRootDiv && showTag(vrRootDiv as HTMLDivElement, tagId)
                    break;

                case SpatialThinkSDKMode.Plain:
                    let plainRootDiv = document.getElementById('sdk-iframe');
                    plainRootDiv && showTag(plainRootDiv as HTMLDivElement, tagId);
                    break;
            }
        }

        function showTag(rootDiv: HTMLDivElement, tagId: string){
            console.log(`[st] [ar] showing tags rootDiv is ${rootDiv}`);
            if (rootDiv) {
                store.dispatch({ type: SET_EDIT_SHOWCASE_TAG_ID, payload: tagId });
                store.dispatch({ type: SET_OPEN_TAG_FORM, payload: true });
                let tagFormDiv = document.getElementById('add-tag-form-div');

                console.log(`[st] [ar] tagFormDiv is ${tagFormDiv}`);
                tagFormDiv && rootDiv.appendChild(tagFormDiv);

                tagFormDiv && (tagFormDiv.style.display = 'block');
            } else {
                console.log(`[st] [ar] xrOverlay not found`);
            }
        }
    }


}
