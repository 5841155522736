import React from 'react';
import {Loader, MessageView} from '../../../@crema';
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/store';
import ConfirmationDialog from '../ConfirmationDialog';
import AppRefreshSnackbar from '../AppRefreshSnackbar';

interface InfoViewProps {}

const InfoView: React.FC<InfoViewProps> = () => {
  const {error, loading, message, loadingInfo, alert} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );

  const showMessage = () => {
    return <MessageView variant='success' message={message.toString()} />;
  };

  const showError = () => {
    return <MessageView variant='error' message={error.toString()} />;
  };

  const showAlert = () => {
    return <ConfirmationDialog {...alert} />;
  };

  return (
    <>
      {loading && <Loader loadingInfo={loadingInfo || ''}/>}
      {message && showMessage()}
      {error && showError()}
      {alert.open && alert.title && showAlert()}
      <AppRefreshSnackbar />
    </>
  );
};

export default InfoView;
