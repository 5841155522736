import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Snackbar } from '@material-ui/core/';
import { CremaTheme } from 'types/AppContextPropsType';
import { common } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { appIntl, reloadWithoutCache } from '@crema/utility/Utils';

const useStyles = makeStyles((theme: CremaTheme) => ({

}));

interface AppRefreshSnackbarProps { }

const AppRefreshSnackbar: React.FC<AppRefreshSnackbarProps> = () => {

    const classes = useStyles();
    const { messages } = appIntl();
    const [open, setOpen] = React.useState(true);
    const hasNewVersion = useSelector<AppState, boolean>(({ common }) => common.hasNewVersion);
    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={() => { reloadWithoutCache() }}
                variant='contained'
            >{messages["common.refresh"]}

            </Button>
            {/* <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {}}
      >
        <CloseIcon fontSize="small" />
      </IconButton> */}
        </React.Fragment>
    );

    return (<Snackbar
        open={hasNewVersion}
        // autoHideDuration={6000}
        onClose={() => { }}
        message={messages["app.newVersion"]}
        action={action}
    />)

};
export default AppRefreshSnackbar;
