import { SpaceRole } from "types/models/home/HomeApp";

const userRoles = SpaceRole;
// {
//   creator: 'creator',
//   editor: 'editor',
//   viewer: 'viewer',
// };

export default userRoles;
