import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import NoSsr from '@material-ui/core/NoSsr';
import Alert from '@material-ui/lab/Alert';
import { LinearProgress, useTheme } from '@material-ui/core';
import LoadingSnackbar from '../InfoView/LoadingSnackbar';

interface LoaderProps {
  loadingInfo?: string;
}

const Loader: React.FC<LoaderProps> = ({ loadingInfo }: LoaderProps) => {
  return (
    <NoSsr>
      {!!loadingInfo ?
        <LoadingSnackbar message={loadingInfo} />
        //    <Alert severity="info"
        //    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}

        //  ><div>{loadingInfo}</div>
        // <LinearProgress color={"primary"} />

        // </Alert>
        :
        <Box
          height='100%'
          display='flex'
          zIndex={999999}
          flex={1}
          alignItems='center'
          justifyContent='center'
          position='absolute'
          top={0}
          left={0}
          right={0}
          bottom={0}>
          {//!!loadingInfo ?
            // <Alert severity="info"
            //   style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}

            // ><div>{loadingInfo}</div>
            // <LinearProgress color={"primary"} />

            // </Alert>:
            <CircularProgress color="secondary" />
          }


        </Box>}
    </NoSsr>
  );
};

export default Loader;
