import Api from '../../@crema/services/ApiConfig';
import {firestore, storage} from '../../@crema/services/auth/firebase/firebase';
import {appIntl} from '../../@crema/utility/Utils';
import {Dispatch} from 'redux';
import {AppActions} from '../../types';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {
  ADD_NOTIFICATION,
  READ_ALL_NOTIFICATION,
  READ_NOTIFICATION,
  GET_NOTIFICATIONS,
} from '../../types/actions/Notification.actions';
import {
  NotificationModel,
  MemberObjNotif,
} from '../../types/models/NotificationModel';
import {Notification} from '../../types/models/NotificationModel';
import {AuthUser} from 'types/models/AuthUser';

export const onGetNotificationList = (authUser: AuthUser | null): any => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    const notification = new Notification();

    notification
      .get(authUser)
      .then((data: any) => {
        // console.log('checking structue od data', data);
        dispatch(fetchSuccess());
        dispatch({type: GET_NOTIFICATIONS, payload: data});
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onAddNewNotification = (
  recipientIds: string[],
  notificationText: string,
  creatorId: string,
  appLink: string
): any => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    const notification = new Notification();

    recipientIds.forEach((recipientId: string) => {
      notification
        .create(recipientId, notificationText, appLink, creatorId)
        .then((data: any) => {
          // console.log('checking structue od data', data);
          dispatch(fetchSuccess());
          if (Object.keys(data).length)
            dispatch({
              type: ADD_NOTIFICATION,
              payload: data,
            });
          // dispatch(showMessage(messages['notification.sharedSent'] as string));
        })
        .catch((error) => {
          dispatch(fetchError(error.message));
        });
    });
  };
};

export const onMarkReadAllNotification = (
  authUser: AuthUser | null,
  notifications: NotificationModel[],
): any => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    const notification = new Notification();

    notification
      .markReadAll(authUser, notifications)
      .then((data: any) => {
        dispatch(fetchSuccess());
        // dispatch(showMessage(messages['notification.projectEdited'] as string));
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const onMarkReadNotification = (
  user: AuthUser | null,
  notificationItem: NotificationModel,
): any => {
  const {messages} = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    const notification = new Notification();

    notification
      .markRead(user, notificationItem)
      .then((data: any) => {
        dispatch(fetchSuccess());
        // dispatch(showMessage(messages['notification.projectEdited'] as string));
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
