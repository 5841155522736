import Api from '@crema/services/ApiConfig';
import { store } from 'App';
import htmlToDraft from 'html-to-draftjs';
import MpSdk from 'mp/MpSdk';
import { setEditShowcaseTagId, setOpenTagForm, setSpaceTagsList, setTagsViewMode } from 'redux/actions/Home';
import { getDefaultMatterportColorObj, hexToMatterportColorObj } from 'shared/constants/AppConst';
import { CollaboratorObj, LabelObj, TaskObj } from 'types/models/apps/ProjectBoard';
import { ANNOTATION_TYPE, HandleTagClickOptions, IQuizDetails, ShowcaseTag, TagGroup } from 'types/models/home/HomeApp';
import { ViewMode } from './SpaceView/Sidebar';
import { addToCurrentTagsSidMapGlobal, addToTagHTMLMap, externalTags, getLabelIdsForTag, getTagIdForTagSid, getTagSidForTagId, setCurrentTagsSidMapGlobal, setExternalTags } from './SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils';
import { mediaTagHtml, quizTagHtml } from './SpaceView/Sidebar/TagSidebar/Tags/tagHt';
import { taskTagHtml } from './SpaceView/Sidebar/TagSidebar/Tags/taskTagHtml';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import _ from 'lodash';
import { fetchError, fetchStart, fetchSuccess, onDeleteSelectedTask, onGetProjectDetail, setShowDictaphone, showMessage } from 'redux/actions';
import { firestore, storage } from '@crema/services/auth/firebase/firebase';
import { svgToPng } from '@crema/core/SvgToPng';
import { ScheduledSvg } from 'assets/images/home/icons/ScheduledSvg';
import { InProgressSvg } from 'assets/images/home/icons/InProgressSvg';
import { CompleteSvg } from 'assets/images/home/icons/CompleteSvg';
import { NeedsAttentionSvg } from 'assets/images/home/icons/NeedsAttentionSvg';
import { SET_SPACE_DATA } from 'types/actions/Home.action';
import { ShowcaseTagMap } from 'redux/reducers/Home';
import { BasicProfiler, canViewSpace } from '@crema/utility/Utils';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VideocamIcon from '@mui/icons-material/Videocam';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import * as THREE from 'three';
import { ExternalSdkMode } from 'mp/core/craEngine/SubSystems/core/Simulation';

export const getTagIcon = (annotationType: any): any => {
  return tagIconMap1.get(annotationType);
};
// const tagIconMap = {
//     'annoIconId': 'https://i.imgur.com/c4Cwbjd.png',
//     'quizIconId': 'https://i.imgur.com/gJlKMOv.png',
//     'taskScheduled':
//         '/assets/images/home/clock.png',
//     'taskComplete':
//         '/assets/images/home/checked.png',
//     'taskPaused':
//         '/assets/images/home/pause.png',
//     'taskInProgress':
//         '/assets/images/home/play-button.png'
// }

// const tagIconIdMap = (annoType) => {
//     switch (annoType) {
//         case 'quiz': return 'quizIconId'
//         case 'taskComplete': return 'quizIconId'
//     }
//     if (annoType === 'quiz')

// }

export const taskColors: Record<string, string> = {
  Complete: '#008037',
  'In Progress': '#D6AE0D',
  Scheduled: '#02377D',
  'Needs Attention': '#BE1931',
};
export const taskCompleteColor = '008037';
export const taskInProgressColor = 'D6AE0D';
export const taskScheduledColor = '02377D';
export const taskNeedsAttentionColor = 'BE1931';

const tagIconMap1: Map<string, string> = new Map<string, string>();
tagIconMap1.set('undefined', 'https://i.imgur.com/c4Cwbjd.png');
tagIconMap1.set('anno', 'https://i.imgur.com/c4Cwbjd.png');
tagIconMap1.set('quiz', 'https://i.imgur.com/gJlKMOv.png');
// tagIconMap1.set('taskScheduled', '/assets/images/home/clock.png');
// tagIconMap1.set('taskComplete', '/assets/images/home/orangeIcon.png');
tagIconMap1.set('task', '/assets/images/home/orangeIcon.png');
// 'https://i.imgur.com/dSEEzXP.png')
// '/assets/images/home/checked.png')
// tagIconMap1.set('taskPaused', '/assets/images/home/orangeIcon.png');
// '/assets/images/home/pause.png')
// tagIconMap1.set('taskInProgress', '/assets/images/home/play-button.png');

export const registerAllIcons = (mpSdk: any) => {
  // Array.from(tagIconMap1).map(([key,value]) => mpSdk.Mattertag.registerIcon(key, value).catch(console.error));
  // tagIconMap1.keys().next(key => mpSdk.Mattertag.registerIcon(key, tagIconMap1[key]));
  // Object.entries(tagIconMap).forEach((k, v) => mpSdk.Mattertag.registerIcon(k, v));

  mpSdk.Mattertag.registerIcon('info-Red', '/assets/images/home/InfoIconRed.png');
  mpSdk.Mattertag.registerIcon('info-Orange', '/assets/images/home/InfoIconOrange.png');
  mpSdk.Mattertag.registerIcon('info-Pink', '/assets/images/home/InfoIconPink.png');
  mpSdk.Mattertag.registerIcon('info-PinkWarm', '/assets/images/home/InfoIconPinkWarm.png');
  mpSdk.Mattertag.registerIcon('info-Lavender', '/assets/images/home/InfoIconLavender.png');
  mpSdk.Mattertag.registerIcon('info-Purple', '/assets/images/home/InfoIconPurple.png');

  mpSdk.Mattertag.registerIcon('info-Teal', '/assets/images/home/InfoIconTeal.png');
  mpSdk.Mattertag.registerIcon('info-Blue', '/assets/images/home/InfoIconBlue.png');
  mpSdk.Mattertag.registerIcon('info-SkyBlue', '/assets/images/home/InfoIconSkyBlue.png');
  mpSdk.Mattertag.registerIcon('info-Olive', '/assets/images/home/InfoIconOlive.png');
  mpSdk.Mattertag.registerIcon('info-Green', '/assets/images/home/InfoIconGreen.png');
  mpSdk.Mattertag.registerIcon('info-Mint', '/assets/images/home/InfoIconMint.png');

  mpSdk.Mattertag.registerIcon('info-Lime', '/assets/images/home/InfoIconLime.png');
  mpSdk.Mattertag.registerIcon('info-Yellow', '/assets/images/home/InfoIconYellow.png');
  mpSdk.Mattertag.registerIcon('info-Mustard', '/assets/images/home/InfoIconMustard.png');
  mpSdk.Mattertag.registerIcon('info-Brown', '/assets/images/home/InfoIconBrown.png');
  mpSdk.Mattertag.registerIcon('info-Grey', '/assets/images/home/InfoIconGrey.png');
  mpSdk.Mattertag.registerIcon('info-Black', '/assets/images/home/InfoIconBlack.png');


  // mpSdk.Mattertag.registerIcon('annoIconId', '/assets/images/home/InfoBlue3DRedWhite.png');
  // mpSdk.Mattertag.registerIcon('anno', '/assets/images/home/InfoIcon3DBlueWhite.png');

  mpSdk.Mattertag.registerIcon('quizIconId', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz-Green', '/assets/images/home/QuizIconGreen.png');
  mpSdk.Mattertag.registerIcon('quiz-Red', '/assets/images/home/QuizIconRed.png');
  mpSdk.Mattertag.registerIcon('quiz-PinkWarm', '/assets/images/home/QuizIconPinkWarm.png');
  mpSdk.Mattertag.registerIcon('quiz-Mustard', '/assets/images/home/QuizIconMustard.png');
  mpSdk.Mattertag.registerIcon('quiz-Blue', '/assets/images/home/QuizIconBlue.png');

  mpSdk.Mattertag.registerIcon('media', '/assets/images/home/MediaIconBlue.png');

  svgToPng(ScheduledSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskScheduled',
      url,
    );
  });

  svgToPng(InProgressSvg()).then((url) => {
    console.log(`[st] InProgressSvg ${url}`);

    mpSdk.Mattertag.registerIcon(
      'taskInProgress',
      url,
    );
  });

  svgToPng(CompleteSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskComplete',
      url,
    );
  });

  svgToPng(NeedsAttentionSvg()).then((url) => {
    mpSdk.Mattertag.registerIcon(
      'taskNeedsAttention',
      url,
    );
  });

  // mpSdk.Mattertag.registerIcon(
  //   'taskInProgress',
  //   '/assets/images/home/InProgressIcon2.png',
  // );
  // mpSdk.Mattertag.registerIcon(
  //   'taskNeedsAttention',
  //   '/assets/images/home/NeedsAttentionIcon2.png',
  //   // '/assets/images/home/pause.png'
  // );
  // mpSdk.Mattertag.registerIcon(
  //   'taskComplete',
  //   '/assets/images/home/CompleteIcon2.png',
  // );

  // let a = svgToPng(NeedsAttentionSvg);

  mpSdk.Mattertag.registerIcon('task', '/assets/images/home/checked.png');
};

export const getFilteredTags = (
  filters: any,
  tags: ShowcaseTagMap,
): ShowcaseTag[] => {
  if (filters?.tagsViewMode == ViewMode.STATUS) {
    return Object.values(tags).filter(
      (t) => t.annotationType && t.annotationType.startsWith('task'),
    );
  } else {
    return Object.values(tags);
  }
};

export const wrapTagHtml = (content: string) => {
  return `<div style="color:white; font-family:Poppins">` +
    content +
    `</div>`;
}

export const getFirestoreLink = (path: string) => {

  path.replaceAll("/", "~2F");
  return `https://console.firebase.google.com/u/0/project/virtual-tc/firestore/data/~2F${path}`;

}

export const makeModelsAccessible = () => {

  Api.post('/admin/model-check', { "modelPath": `3d/` })
    .then(data => {
      if (data.status === 200) {
        alert(`[st] model now accessible `);
        console.log(`[st] model now accessible`);
      } else {
        console.error(`[st] error ${JSON.stringify(data)} `);
      }
    })
    .catch(error => {
      console.error(`[st] error ${JSON.stringify(error)} `);
    });
}

export function makeQuizHtml(
  mattertagId: string,
  quizDetails: IQuizDetails | undefined,
  tagHtml: string,
) {
  return (
    quizTagHtml(quizDetails, tagHtml) +
    serializeQuizFunctionCall(mattertagQuizFunction, mattertagId, quizDetails)
  );
}

export function makeMediaHtml(
  mattertagId: string,
  mediaLink: string,
  tagHtml: string,
) {
  return (
    mediaTagHtml(mediaLink, tagHtml) +
    serializeMediaFunctionCall(mattertagMediaFunction, mattertagId, mediaLink)
  );
}

export function makeTaskTagHtml(taskItem: TaskObj, tagSid: string, tagHtml: string,
  // project: ProjectObj
) {

  let taskTagHtmlText: any = taskTagHtml(taskItem, tagSid,
    // project
  );

  return (
    taskTagHtmlText
    +
    serializeTaskFunctionCall(mattertagTaskFunction, taskItem, tagSid)
  );
}



// serialize the contents of the function and call it with a single parameter.
// Then, wrap it with a script tag. '<' is separated from '/' to prevent the browser from
// adding this script tag to the parent page on jsfiddle.
function serializeQuizFunctionCall(
  myFunction: any,
  mattertagId: string,
  quizDetails: IQuizDetails | undefined,
) {
  return (
    `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(quizDetails)});
        <` + `/script>`
  );
}

function serializeTaskFunctionCall(
  myFunction: any,
  taskItem: any,
  tagSid: any,
) {
  return (
    `<script>
${myFunction.toString()};
${myFunction.name}(${JSON.stringify(taskItem)},'${tagSid}');
<` + `/script>`
  );
}

function serializeMediaFunctionCall(
  myFunction: any,
  mattertagId: string,
  mediaLink: string,
) {
  return (
    `<script>
        ${myFunction.toString()};
        ${myFunction.name}('${mattertagId}',${JSON.stringify(mediaLink)});
      <` + `/script>`
  );
}


// The contents of this function will be executed in a sandboxed mattertag iframe.
// You can only refer to variables defined in the iframe.
function mattertagQuizFunction(
  mattertagId: string,
  quizDetails: IQuizDetails,
) {
  function getSelectedCheckboxValues(name: string) {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}"]:checked`,
    );
    let value: string = '';
    checkboxes.forEach((checkbox: any) => {
      value = checkbox.value;
    });
    return value;
  }
  const btn: any = document.querySelector('#radioInputForm');
  btn.addEventListener('change', (event: any) => {
    let selectedChoice = getSelectedCheckboxValues('choice');

    let correctAns: any = document.getElementById('correct-ans');
    let wrongAns: any = document.getElementById('wrong-ans');

    let choiceDiv: any = document.getElementById('choice-' + Number.parseInt(selectedChoice));

    if (quizDetails?.options[Number.parseInt(selectedChoice)]?.isCorrect) {
      correctAns.style.display = 'block';
      wrongAns.style.display = 'none';
      choiceDiv.style.border = '1px solid green';
      choiceDiv.style.borderRadius = '8px';
      // correctAns.style.animation= "fadeInOut 2s linear 1 forwards";
    } else {
      wrongAns.style.display = 'block';
      correctAns.style.display = 'none';
      choiceDiv.style.border = '1px solid red';
      choiceDiv.style.borderRadius = '8px';
      // wrongAns.style.animation= "fadeInOut 2s linear 1 forwards";
    }
  });
}

function mattertagMediaFunction(mattertagId: string, mediaLink: string) { }

function mattertagTaskFunction(taskItem: any, tagSid: any) {
  // when the button is clicked send a message back to the parent page through the window object.
  let editTaskBtn: any = document.getElementById('editTaskBtn');
  editTaskBtn.addEventListener('click', function (event: any) {
    // console.log(`[st] editTaskBtn button clicked`);
    // setOpenEditTagForm(true);
  });
}

export function mediaTypeFinder(matterportType: string) {
  let type = '';

  if (!matterportType) {
    type = `mattertag.media.none`;
  } else if (matterportType.includes('video')) {
    type = `mattertag.media.video`;
  } else if (matterportType.includes('photo')) {
    type = `mattertag.media.photo`;
  } else if (matterportType.includes('file')) {
    type = `mattertag.media.file`;
  } else if (matterportType.includes('rich')) {
    type = `mattertag.media.rich`;
  } else {
    type = `mattertag.media.none`;
  }
  // console.log(type,"mediaTypeFinder")
  return type;
}

export function TagIcons(mediaType: string) {
  switch (mediaType) {
    case 'mattertag.media.video':
      return VideocamIcon;
    case 'mattertag.media.photo':
      return InsertPhotoIcon;
    case 'mattertag.media.file':
      return AttachFileIcon;
    case 'mattertag.media.rich':
      return InsertLinkIcon;
    case 'mattertag.media.none':
    default:
      return InfoOutlinedIcon;
  }
}

/*
  tagIds: firebase doc IDs for things like Layers and TagGroups that store tagIds, and need to hide/ show tags
  tagObjects: typically to load from the database when the app is first laoded
*/
export const addTagsToEmptyShowcase = async ({ tagIds, tagObjects, callback }:
  {
    tagIds?: string[],
    tagObjects?: ShowcaseTag[],
    callback?: (id: string, sid: string) => void
  },
) => {
  // await initMpSdk();

  if (!MpSdk.Instance.mps) {
    return;
  }

  let showcaseTagsToAdd: any[];

  let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();

  try {
    await MpSdk.Instance.mps.Mattertag.remove(
      tagsFromMatterport.map((tag: any) => tag.sid),
    );
  } catch (err: any) {
    console.error(err);
    store.dispatch( //FIXME with return (dispatch: Dispatch<AppActions>) => {
      fetchError(
        'Something went wrong connecting to Matterport. Please refresh the page',
      ),
    );
    return;
  }

  if (tagObjects && tagObjects.length > 0) {
    // tag objects from DB e.g. during initial load
    showcaseTagsToAdd = tagObjects;
    // if(space.tagsImported){
    // console.log(`[st] setstate setting externaltags`);
    //store, in case user wants to import from Matterport
    // console.log(`[st] setting externaltags 1 ${tagsFromMatterport?.length}`);
    // (!externalTags || externalTags.length == 0) && setExternalTags(tagsFromMatterport);
    // }

  } else {
    let spaceTags = store.getState().home.spaceTags;
    showcaseTagsToAdd = tagIds
      ? // && tagIds.length > 0
      tagIds.reduce((res: ShowcaseTag[], tagId: string) => {
        // console.log(`[st] using spaceTags 4`);
        let spaceTag = spaceTags[tagId];
        spaceTag != undefined && res.push(spaceTag);
        return res;
      }, [])
      : getFilteredTags({}, spaceTags); //FIXME STATUS doesn't work - removed tagsViewMode here
  }

  // let xrTags = showcaseTagsToAdd.map(tag => ({label: JSON.parse(tag.data).label, position: JSON.parse(tag.data).anchorPosition}))

  // console.log(`[st] tags dump ${JSON.stringify(xrTags) }`);

  let tagDataToAdd = showcaseTagsToAdd.map((tag: any) => tag.data);

  // if(!currentTagGroupId ) {
  for (let i = 0; i < showcaseTagsToAdd.length; i++) {
    try {
      if (tagDataToAdd[i].media) {
        tagDataToAdd[i].media = {
          type: mediaTypeFinder(tagDataToAdd[i].media?.type),
          src: tagDataToAdd[i].media?.src,
        };
      }

      let labelIds = getLabelIdsForTag(showcaseTagsToAdd[i]);
      let colorHex = labelIds?.map(lid => getProjectLabel(lid))?.find(label => !!label?.color)?.color || '';

      if (!colorHex) {
        if (tagDataToAdd[i].color) {
          //TODO implement to support imported colored tags. create labels
        }

        if (!labelIds || labelIds?.length === 0) {
          tagDataToAdd[i].color = getDefaultMatterportColorObj();
        }

      } else {
        tagDataToAdd[i].color = hexToMatterportColorObj(colorHex);
      }

      try {

        // console.log('tagDataToAdd[i]', tagDataToAdd[i]);
        (MpSdk.Instance.mps.Mattertag.add(tagDataToAdd[i])).then((res: string[]) => {
          let newTagSid = res[0];
          // console.log(`[tags] tag inited  ${tagDataToAdd[i].id} -- newTagSid: ${newTagSid}`);

          callback && callback(showcaseTagsToAdd[i].id, newTagSid as string);
          let showcaseTag = showcaseTagsToAdd[i];
          let currentSid = newTagSid; //newTagSids[i];
          initiateTag(showcaseTag, currentSid).then(() => {

            // console.log(`[tags] tag inited  ${tagDataToAdd[i].id} -- newTagSid: ${newTagSid}`);
          })
        }).catch((e: any) => console.error("error adding tag i", e));

      } catch (e) {
        console.error("error adding tag ", showcaseTagsToAdd[i].id, e);
      }
    } catch (error: any) {
      console.error("error adding tag", error);
    }
    // }
  }
};

export const initiateTag = async (showcaseTag: ShowcaseTag, currentSid: string,
  // project:ProjectObj
) => {

  // console.log(showcaseTag, currentSid, 'initiateTag');
  //set global tag sid map
  addToCurrentTagsSidMapGlobal(showcaseTag.id, currentSid);

  //TODO double check before merging, should we prevent these actions?
  // mpSdk.Mattertag.preventAction(currentSid, {
  //   // opening: true,
  //   // navigating: true
  // });

  //TODO is this needed? test media tags from matterport
  // showcaseTag.data?.media?.type &&
  //   (showcaseTag.data.media.type =
  //     'mattertag.media.' + showcaseTag.data.media.type);

  // let iconId = '';
  //set icon
  // if (showcaseTag.data.source == 'ST') {
  if (!showcaseTag.annotationType) {
    showcaseTag.annotationType = 'info';
  }
  // if (!showcaseTag.annotationType || showcaseTag.annotationType == 'info') {

  //   if (showcaseTag.data.media && showcaseTag.data.media.src) {
  //     iconId = `media`;
  //   } else {
  //     iconId = `info-${TagColorToMatterportColorObj(showcaseTag.data.color)}`;
  //   }
  // }

  // if (showcaseTag.annotationType.startsWith('quiz')) {
  //   if (showcaseTag.data.color) {
  //     iconId = `quiz-${TagColorToMatterportColorObj(showcaseTag.data.color)}`;
  //   } else {
  //     iconId = 'quizIconId';
  //   }
  // }
  // // alert("2" + iconId);
  // if (showcaseTag.annotationType.startsWith('task')) {
  //   let sectionName = store?.getState().home.currentSpace?.currentSpaceProject?.sectionList?.find(s => s.id == showcaseTag.taskTag?.sectionId)?.name;
  //   //  alert(sectionName);
  //   iconId = 'task' + sectionName?.replaceAll(' ', '');
  // }

  // if(){
  // console.log(`[tags] color is ${JSON.stringify(showcaseTag.data.color)} `)

  // }
  // await MpSdk.Instance.mps.Mattertag.editIcon(currentSid, iconId);
  // update tag color based on tag labels, or set to red.

  //inject HTML
  try {
    const tagHtml = showcaseTag.tagHtml || '';
    const blocksFromHtml = htmlToDraft(tagHtml);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    let pureText = ''
    convertToRaw(editorState.getCurrentContent()).blocks.forEach(block => {
      pureText = pureText + ' ' + block.text
    });

    const textToSpeechButton = `<button style="position: absolute; right: 5px; top: 0px;" onclick="(()=>{
      var synth = window.speechSynthesis;
      var utterThis = new SpeechSynthesisUtterance('${pureText}');
      synth.speak(utterThis);
      return;
      })()"><img src="https://icon-library.com/images/google-voice-icon/google-voice-icon-20.jpg" height='12' width='7' /></button>`;
    let tagHtmlSpeech =
      tagHtml !== '<p></p>\n' //i.e. if draft is empty
        ? `<div style="color:white; font-family:Open Sans;"> ${tagHtml} ${showcaseTag.showTextToSpeech ? textToSpeechButton : ''} </div>`
        : '';
    if (showcaseTag?.annotationType === ANNOTATION_TYPE.INFO) {
      // if (showcaseTag?.mediaLink) {
      //   const htmlString = makeMediaHtml(
      //     currentSid,
      //     showcaseTag?.mediaLink,
      //     tagHtml,
      //   );
      //   await injectHtmlHandler(htmlString, currentSid, 600, 400);
      // } else {
      addToTagHTMLMap(showcaseTag.id, tagHtmlSpeech);
      return await injectHtmlHandler(tagHtmlSpeech, currentSid, undefined, undefined); //, 400, 200);
      // }
    } else if (showcaseTag?.annotationType === ANNOTATION_TYPE.QUIZ) {
      const htmlString = makeQuizHtml(
        currentSid,
        showcaseTag.quizDetails,
        tagHtmlSpeech,
      );
      addToTagHTMLMap(showcaseTag.id, htmlString);
      return await injectHtmlHandler(htmlString, currentSid, 400, 250);
    } else if (showcaseTag?.annotationType?.startsWith(ANNOTATION_TYPE.TASK)) {
      const htmlString = !!showcaseTag.taskTag ? makeTaskTagHtml(
        showcaseTag.taskTag,
        currentSid,
        tagHtmlSpeech,
        // project
      ) : '';
      addToTagHTMLMap(showcaseTag.id, htmlString);
      return await injectHtmlHandler(htmlString, currentSid, 600, 250);
    }
  } catch (error: any) {
    console.error(error);
  }
};


const injectHtmlHandler = async (
  htmlString: any,
  tagSid: string,
  width: undefined | number,
  height: undefined | number,
) => {
  // await initMpSdk();


  if (htmlString !== '' && MpSdk.Instance.mps) {

    try {
      const [tagSandbox, messenger] = await MpSdk.Instance.mps.Tag.registerSandbox(htmlString, {
        size: {
          w: width,
          h: height,
        }
      });

      // MpSdk.Instance.mps.Mattertag.injectHTML(tagSid, htmlString, {
      //   size: {
      //     w: width,
      //     h: height,
      //   },
      //   windowPath: '',
      // })
      //   .then(function (messenger: any) {
      messenger.on('editTask', function (tagSid: any) {
        if (canViewSpace()) {
          store.dispatch(setTagsViewMode(ViewMode.TAGS));
          let selectedShowcaseId = getTagIdForTagSid(tagSid);

          selectedShowcaseId && store.dispatch(setEditShowcaseTagId(selectedShowcaseId));
          store.dispatch(setOpenTagForm(true));
        } else {
          store.dispatch(fetchError("You don't have the permissions for this action"))
        }
      });

      messenger.on('addVoiceComment', function (tagSid: any) {
        if (canViewSpace()) {
          let selectedShowcaseId = getTagIdForTagSid(tagSid);

          selectedShowcaseId && store.dispatch(setEditShowcaseTagId(selectedShowcaseId));
          store.dispatch(setShowDictaphone(true));
        } else {
          store.dispatch(fetchError("You don't have the permissions for this action"))
        }
      });

      try {
        MpSdk.Instance.mps.Tag.attach(tagSid, tagSandbox);
      }
      catch (e: any) {
        console.error(e);
        console.error('Error in injectHTML');
      };
    } catch (error) {
      console.error("[st] error with embedly", error);
    }
  }

};

export const importMatterportTags = async () => {

  if (externalTags.length == 0) {
    store.dispatch(showMessage("You don't have any tags to import from Matterport! But you can create new tags from the Quick Add toolbar!"));
    return;
  }
  let batch = firestore.batch();
  let tagsFromMpToSave: ShowcaseTag[] = [];

  let labelObjs: LabelObj[] = [];

  let colorHexsSet = new Set<string>();
  // let tagColorHex: {[tagId: string]: string} = {};

  externalTags.map(t => {
    let cc = new THREE.Color(t.color.r, t.color.g, t.color.b);
    // tagColorHex[t.id] = '#' + cc.getHexString();
    t.labelColorTemp = '#' + cc.getHexString();
    colorHexsSet.add('#' + cc.getHexString());
  })
  let colorsArr = Array.from(colorHexsSet);
  let projectId = store.getState().projects.projectDetail?.id;
  let projectDid = store.getState().projects.projectDetail?.did;

  if (colorsArr && colorsArr.length > 1) {

    if (projectId) {
      try {
        let project = await firestore.doc(`Projects/${projectId}`).get();
        if (project.exists) {
          let labelList = (project.data()?.labelList || []) as LabelObj[];

          let maxLabelId = Math.max(1, ...labelList.map(label => label.id))
          labelObjs = colorsArr.map((c: string, i) => ({
            id: i + maxLabelId + 1,
            name: 'Label ' + (i + maxLabelId + 1),
            color: c,
          }));

          labelList.push(...labelObjs);

          try {
            await project.ref.update('labelList', labelList);
          } catch (e) {
            store.dispatch(fetchError("We tried to create new labels based on your multiple tag colors, but that didn't quite work. Please label the tags once everything is imported."))
          }
        }
      } catch (e) {
        store.dispatch(fetchError("We tried to create new labels based on your multiple tag colors, but that didn't quite work. Please label the tags once everything is imported."))
      }
    }
  }


  externalTags.forEach((tag: any) => {
    if (tag.enabled === true) {
      // tag.annotationType = ANNOTATION_TYPE.INFO;
      tag.source = 'MP';
      // tag.tagHtml = wrapTagHtml(tag.description);
      tag.labelIds = labelObjs.find(l => tag.labelColorTemp == l.color) || [];
      let tagString = JSON.stringify(tag);
      const tagsCollectionRef = firestore
        .collection('Spaces')
        .doc(store.getState().home.currentSpace?.id)
        .collection('tags');
      let tagDocRef = tagsCollectionRef.doc();
      batch.set(tagDocRef, {
        data: tagString,
        sid: tag.sid,
        labelIds: [labelObjs.find(l => tag.labelColorTemp == l.color)?.id] || [],
        annotationType: ANNOTATION_TYPE.INFO,
        createdOn: new Date(),
        lastUpdatedOn: new Date(),
        createdBy: store.getState().auth.authUser?.uid,
        lastUpdatedBy: store.getState().auth.authUser?.uid,
        tagsAlreadyImported: true
      });
      tagsFromMpToSave.push({ id: tagDocRef.id, data: tagString });
    }
  });
  batch.commit().then(() => {
    let bp = new BasicProfiler();
    let space = _.cloneDeep(store.getState().home.currentSpace);
    bp.lap('[clone] space in importMatterportTags');

    if (space) {
      space.tagsImported = true;
      firestore.doc(`Spaces/${space?.id}`).update({ tagsImported: true }).then(() => {
        store.dispatch({ type: SET_SPACE_DATA, payload: space });
        projectDid && store.dispatch(onGetProjectDetail(projectDid));
        getMatterportTagsFromDB();
      }).catch(console.error);
    }

  });
};

export const handleTagClickBySid = async (currentTagSid: string, options?: HandleTagClickOptions) => {
  if (currentTagSid && MpSdk.Instance.mps) {
    //  let cts = await MpSdk.Instance.mps.Mattertag.getData();

    if (options && options.preventNavigation) {
      MpSdk.Instance.mps.Tag.allowAction(currentTagSid, {
        // opening: true,
        navigating: true
      }).then(() => {
        console.log(`[st] nav to tag 1`);

        MpSdk.Instance.mps.Mattertag.navigateToTag(
          currentTagSid,
          MpSdk.Instance.mps.Mattertag.Transition.FLY,
        ).then(() => {
          // setTimeout(() => spaceTag != undefined && playTextToSpeech(spaceTag.data.label), 1000); //TODO commenting this for now
        }).catch(console.error);
      })
    } else {
      console.log(`[st] nav to tag 2 ${currentTagSid}`);
      MpSdk.Instance.mps.Mattertag.navigateToTag(
        currentTagSid,
        MpSdk.Instance.mps.Mattertag.Transition.FLY,
      ).then(() => {
        console.log(`[st] nav to tag 2 ${currentTagSid} DONE`);
        // spaceTag != undefined && playTextToSpeech(spaceTag.data.label);
      }).catch(console.error);
    }

  }
}


export const handleTagClick = async (showcaseTagId: string, options?: HandleTagClickOptions) => {
  // await initMpSdk();
  let currentTagSid = getTagSidForTagId(showcaseTagId);
  currentTagSid && handleTagClickBySid(currentTagSid, options);
  let spaceTag = store.getState().home.spaceTags[showcaseTagId];
  // let spaceTag = spaceTags.get(showcaseTagId);
  console.log(
    `[st] tag clicked ${showcaseTagId} - ${currentTagSid} - ${JSON.stringify(spaceTag)}`
  );


};

const playTextToSpeech = (text: string) => {

  const synth = window.speechSynthesis;
  const utterThis = new SpeechSynthesisUtterance(text);
  synth.speak(utterThis);

};

export const handleDeleteTag = async (tagId: string) => {
  console.log(`[st] using spaceTags`);
  // let visibleSpaceTags: ShowcaseTag[] = Object.values(spaceTags);
  // let tag: ShowcaseTag | undefined = visibleSpaceTags.find((el: ShowcaseTag) => tagId === el.id);

  // if (tag) {
  let res = window.confirm(`Are you sure you want to delete this tag?`);
  if (res) {
    deleteTag(tagId);
  }
  // }
};

const deleteTag = async (tagId: string) => {
  try {
    let tag = store.getState().home.spaceTags[tagId];
    if (tag && tag.annotationType === ANNOTATION_TYPE.TASK) {
      let task = tag.taskTag;
      task && store.dispatch(onDeleteSelectedTask(task.projectId, task.sectionId || 0, task.id || ''));//TODO sectionId, id part is shady
    }
  }
  catch (e) {

  }
  const deleteTagRef: any = await firestore
    .doc(`Spaces/${store.getState().home.currentSpace?.id}`)
    .collection(`tags`)
    .doc(tagId);

  // const { id: deleteTagRefId } = deleteTagRef;
  deleteTagRef
    .delete()
    .then(() => {
      getMatterportTagsFromDB();
    })
    .catch(console.error);
}

export const handleDeleteAllTags = (ids: string[]) => {
  let res = window.confirm(`Are you sure you want to delete ${ids.length} tag(s)?`);
  let t: boolean = false;
  if (res) {
    ids.forEach(async (tagId: string) => {
      deleteTag(tagId);
      // const deleteTagRef: any = await firestore
      //   .doc(`Spaces/${store.getState().home.currentSpace?.id}`)
      //   .collection(`tags`)
      //   .doc(tagId);

      // // const { id: deleteTagRefId } = deleteTagRef;
      // deleteTagRef
      //   .delete()
      //   .then(() => {
      //     getMatterportTagsFromDB();
      //   })
      //   .catch(console.error);
    });
    t = true;
  }
  return t;
}

/*
   Re-initialize all Matterport tags in DB
   Remove all from showcase
   Add tags and annotations from DB to showcase
    */
export const getMatterportTagsFromDB = async () => {

  let space = store.getState().home.currentSpace;
  // Get all space projects and find the default project
  let bp = new BasicProfiler();

  // let spaceProjects = (await firestore.collection(`Projects`).where('spaceId', '==', space?.id).get()).docs.map(doc => doc.data() as ProjectObj);
  bp.lap('get spaceprojects from the api');
  // const sProject: ProjectObj = spaceProjects.find((f: ProjectObj) => (f.name.includes("Default Project"))) || spaceProjects[0];

  const sProject = store.getState().projects.projectDetail;
  let tagsFromDb: ShowcaseTag[] = [];
  let mergedTaskTags = [];
  let badTagIds: string[] = [];

  let qsTags = await firestore
    .collection('Spaces')
    .doc(space?.id)
    .collection('tags')
    .get();
  let tags = qsTags.docs.map((doc: ShowcaseTag) => ({ ...doc.data(), id: doc.id }));

  let nonTaskTags = tags.filter((doc: ShowcaseTag) => doc.annotationType !== "task");
  let taskTags = tags.filter((doc: ShowcaseTag) => (doc.annotationType === "task" && (sProject && doc.projectId === sProject?.id)));

  let qsProjectTasks = await firestore
    .collection('Projects')
    .doc(sProject?.id)
    .collection('Tasks')
    .where('type', '==', 'TASK_TAG')
    .get();

  let projectTasks = await Promise.all(qsProjectTasks.docs.map(async (doc: any) => {
    let label = doc.data().labelIds.map((labId: number) => (sProject?.labelList[sProject?.labelList.findIndex((a: any) => (a.id == labId))]));
    let collaborators: CollaboratorObj[] = [];

    if ((doc.data()?.collaboratorIds || []).length) {
      let ms = await firestore.collection('Users').where('id', "in", doc.data().collaboratorIds).get();
      collaborators = ms.docs.map(u => ({ id: u.id, name: `${u.data().firstName} ${u.data().lastName}`, image: u.data().photoURL, email: u.data().email }));
    }

    // let collaborators = doc.data().collaboratorIds.map((cId: number) => (project.memberList[project.memberList.findIndex((a: any) => (a.id == cId))]));
    return ({ ...doc.data(), id: doc.id, label, collaborators });

  }));

  // merge Space Task data and Project Task data
  mergedTaskTags = taskTags.map(s => ({ ...s, taskTag: projectTasks.find(p => (p.tagId === s.id)) }));

  const allTags = [...nonTaskTags, ...mergedTaskTags];

  allTags.forEach((tag) => {
    try {
      // attachment-feature
      //           let tagHtml= tag?.tagHtml;
      //         if (tag.data.description) {
      //           tagHtml = `${tagHtml} ${wrapTagHtml(tag.data.description)}`;
      //         }
      const id = tag.id;
      const data = tag.data;
      const annotationType = tag?.annotationType;
      const quizDetails = tag?.quizDetails;
      // const mediaLink = tag?.mediaLink;
      const taskTag = tag?.taskTag;
      const tagHtml = tag?.tagHtml;
      const showTextToSpeech = tag?.showTextToSpeech;
      const createdOn = tag?.createdOn;
      const lastUpdatedOn = tag?.lastUpdatedOn;
      const createdBy = tag?.createdBy;
      const lastUpdatedBy = tag?.lastUpdatedBy;
      const labelIds = tag?.labelIds;
      const projectId = tag?.projectId;
      const uploadType = tag?.uploadType;

      tagsFromDb.push({
        id: id,
        data: JSON.parse(data),
        ...(annotationType && { annotationType: annotationType }),
        ...(quizDetails && { quizDetails: quizDetails }),
        // ...(mediaLink && { mediaLink: mediaLink }),
        ...(taskTag && { taskTag: taskTag }),
        ...(tagHtml && { tagHtml: tagHtml }),
        ...(showTextToSpeech && { showTextToSpeech: showTextToSpeech }),
        ...(createdOn && { createdOn: new Date(tag.createdOn.seconds * 1000) }),
        ...(lastUpdatedOn && { lastUpdatedOn: new Date(tag.lastUpdatedOn.seconds * 1000) }),
        ...(createdBy && { createdBy: createdBy }),
        ...(lastUpdatedBy && { lastUpdatedBy: lastUpdatedBy }),
        ...(labelIds && { labelIds: labelIds }),
        ...(projectId && { projectId: projectId }),
        ...(uploadType && { uploadType: uploadType }),
      });
    } catch (error: any) {
      console.error('found a bad tag');
      console.error(error);
      badTagIds.push(tag.id);
    }
  });

  // if (tagsFromDb.find((x) => x.data?.source == 'MP')) {
  //   setTagsAlreadyImported(true);
  // }

  try {
    if (!areNodesSpatialThinkSDK()) {
      let tagsFromMatterport = await MpSdk.Instance.mps.Mattertag.getData();
      // await MpSdk.Instance.mps.Mattertag.remove(tagsFromMatterport.map((tag: any) => tag.sid)).catch(console.error);

      if (externalTags.length == 0) {
        console.log(`[st] [tags] setstate setting externaltags 2 ${tagsFromMatterport?.length}`);
        setExternalTags(tagsFromMatterport);
      }
    }
    tagsFromDb = tagsFromDb.filter((tag) => !badTagIds.includes(tag.id));

    let tagMap: ShowcaseTagMap = {};
    tagsFromDb.map((t) => (tagMap[t.id] = t));
    console.log(`[st] setting spaceTags ${Object.keys(tagMap).length}`);
    store.dispatch(setSpaceTagsList(tagMap)); //controls state accross components

    // let tagSidsMap = new Map(tagsFromDb.map(t => [t.id, t.data.sid]));
    // setCurrentTagsSidMapGlobal(tagSidsMap); //global reference for current tag sids

    // add to showcase
    setCurrentTagsSidMapGlobal(new Map());
    await addTagsToEmptyShowcase({ tagIds: [], tagObjects: tagsFromDb }); //tagsFromDb
    // store.dispatch(setCurrentShowcaseTags(tagsFromDb));

    // })
  } catch (error: any) {
    console.error(error, 'error while getting initial tag data');
  }

}

export const getCurrentTagGroup = () => {
  return store.getState().layer.tagGroupsList.find(
    (tagGroup: TagGroup) => tagGroup.id === store.getState().layer.currentTagGroupId,
  );
}

export const addModelToCurrentTagGroup = (modelId: string) => {
  let tagGroup = getCurrentTagGroup();
  (tagGroup?.tagIds || []).push(modelId);
  // store.getState().layer.tagGroupsList.find(tg => tg.id == tagGroup?.id) //FIXME what if tg is undefined - test it

  //   tgs.push(tagGroup);
  //   dispatch(setTagGroupsList(tgs));
}

export const getTagGroupsLength = () => {
  let tgList = store.getState().layer?.tagGroupsList;
  return (tgList && tgList.length) ? tgList.length : 0;
}

export const isJumpToTagIdValid = (tagGroup: TagGroup): boolean => {


  return (
    !!tagGroup.jumpToTagId
    && tagGroup.tagIds?.includes(tagGroup.jumpToTagId)
    && (!!getTagSidForTagId(tagGroup.jumpToTagId)
      || store.getState().home.spaceModels.has(tagGroup.jumpToTagId))
  )

}

export const getInQueryResultWithChunks = async (collectionName: string, fieldName: string, inList: string[]) => {
  let inSet = Array.from(new Set(inList));
  let result: any[] = [];
  await Promise.all(
    _.chunk(inSet, 10).map(inListChunk => {

      return firestore.collection(collectionName).where(fieldName, 'in', inListChunk).get()
        .then((qs) => {
          let resultChunk: any[] = qs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          result.push(...resultChunk);
        })
      // .catch(console.error);
    }));
  return result;
}

export const uploadMediaFiles = async (attachments: File[]) => {
  store.dispatch(fetchStart());
  // let tagDocRef = firestore.collection(`Spaces/${store.getState().home.currentSpace?.id}/nodes/${store.getState().auth.authUser?.uid}`);

  //logic to create urls for attachments
  let prefix = `Spaces/${store.getState().home.currentSpace?.did}/nodes`;
  var storageRef = storage.ref(prefix);

  let attachmentsUrls: string[] = [];

  let promises: Promise<string>[] = [];
  promises = attachments.map((f, index) => {
    let file: any = f;

    let filename: any = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
    filename = `${store.getState().auth.authUser?.uid}-${Date.now()}-${filename}`;
    let imageRef = storageRef.child(filename);
    // let baseModelRef = storage.ref(`3d/${type}/${name}`).child(fileName);

    return imageRef
      .put(file)
      .then(() => {
        const imageURL = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${filename}`; //imageRef.getDownloadURL();
        return imageURL;
      })
      .then(async (imageURL: any) => {
        attachmentsUrls.push(imageURL);
        //make model bucket/folder public
        try {
          let makeFilePublicResult = await Api.post('/admin/model-check', { "modelPath": `${prefix}/${filename}` });

          if (makeFilePublicResult.status === 200) {
            console.debug(`[st] file made public: ${prefix}/${filename}`);
          } else {
            console.error(`[st] error making file public: ${prefix}/${filename} : ${JSON.stringify(makeFilePublicResult)}`)
            store.dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
          }
        } catch (error) {
          console.error(`[st] error making file public: ${prefix}/${filename} : ${error}`)
          store.dispatch(fetchError("The file is uploaded, but we couldn't generate the preview. See if you see the file preview, or try uploading again, or email us with your space name"));
        };

        return imageURL;
      })
      .catch((error: any) => {
        console.log(error);
      });
  });

  return await Promise.all(promises).then((response) => { store.dispatch(fetchSuccess()); return response; })
}

export function getProjectLabel(labelId: number) {
  return store.getState().projects.projectDetail?.labelList?.find(label => label.id == labelId)
}

export function isUserAdmin(): boolean {
  return !!store.getState().auth.authUser?.user?.permissions?.includes('ADMIN');
}

export function isUserInExperiment(experimentName: string): boolean {
  // const authUser = useSelector<AppState, AuthUser | null>(({ auth }) => auth.authUser);
  return !!store.getState().auth.authUser?.user?.permissions?.includes(experimentName) || isUserAdmin();
}

export function isAnnotationVisible(m: any) {
  return !m['access'] || isUserInExperiment(m['access'])
}

export async function doesTagSidExistInShowcase(sid: string | undefined) {
  if (!sid) {
    return false;
  }
  let tags = await MpSdk.Instance.mps.Mattertag.getData();
  let sidExists = tags.map((tag: any) => tag.sid).includes(sid);
  console.log(`[st[ [task] sidExists for ${sid}: ${sidExists}`)
  return sidExists;

}

export function areNodesSpatialThinkSDK() {
  // console.log('[st] SDK MODE', Simulation.instance.sdkMode);
  // if (Simulation.instance.sdkMode == ExternalSdkMode.MP){
  if (store.getState().threeD.sdkMode == ExternalSdkMode.MP) {
    return false;
  } else {
    return true;
  }
  // let sm = store.getState().home.spaceModels;
  // if (sm && sm.size > 0) {
  //   let n = sm.values().next().value;
  //   if (n.nodeRef?.constructor.name == 'SceneNode') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // return false;
}