import { WireTerminal } from "../../components/Wire/WireTerminal";
import { ISceneNode, SceneComponent } from "../sceneManagement/SceneComponent";
import { UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";

export default class OnDragProperties{
    private tempNode: ISceneNode;
    private tempComponent: SceneComponent;

    constructor(node: ISceneNode, component: SceneComponent){
        this.tempNode = node;
        this.tempComponent = component;
    }

    public onDrag(){
        if (this.tempNode && this.tempNode.userData.type === UserDataTypes.wire){
            const terminalComponent = (this.tempComponent as WireTerminal);
            if (terminalComponent.inputs.terminal === "start") {
                this.tempNode.userData[UserDataProperties.startTerminalPosition] = terminalComponent.getMeshPosition();
            }
            else if (terminalComponent.inputs.terminal === "end") {
                this.tempNode.userData[UserDataProperties.endTerminalPosition] = terminalComponent.getMeshPosition();
            }
            else if (terminalComponent.inputs.terminal === "mid"){
                this.tempNode.userData[UserDataProperties.midTerminalPosition] = terminalComponent.getMeshPosition();
            }
        }
        else if(this.tempNode && this.tempNode.userData.type === UserDataTypes.multimeter){
            const terminalComponent = (this.tempComponent as WireTerminal);
            if (terminalComponent.inputs.terminal === "start") {
                if (terminalComponent.inputs.wireIndex === 0){
                    this.tempNode.userData[UserDataProperties.startTerminal1Position] = terminalComponent.getMeshPosition();
                }
                else{
                    this.tempNode.userData[UserDataProperties.startTerminal2Position] = terminalComponent.getMeshPosition();
                }
            }
            else if (terminalComponent.inputs.terminal === "end") {
                if (terminalComponent.inputs.wireIndex === 0){
                    this.tempNode.userData[UserDataProperties.endTerminal1Position] = terminalComponent.getMeshPosition();
                }
                else{
                    this.tempNode.userData[UserDataProperties.endTerminal2Position] = terminalComponent.getMeshPosition();
                }
            }
        }
    }
}