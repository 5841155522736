import { GenericInputEvent } from "../../../components/systemComponents/GenericInput";
import { IComponentEventSpy } from "../../sceneManagement/SceneComponent";
import {store} from '../../../../../../App';
import {EulerIntegrator} from '../../../components/systemComponents/EulerIntegrator';

export class GenericInputDragEndSpy implements IComponentEventSpy<unknown> {
    public eventType = GenericInputEvent.DragEnd;
    constructor() {
    }
    onEvent(payload: any) {
        // console.log('drag object GenericInputDragBeginSpy', payload, payload.node.root.id,  payload.node.root.name,  payload.node.root.obj3D);
        // console.log("[raj] [drag] ending drag");
        // if (store.getState().home.currentSpace?.settings?.useFreeCamera) {
        //     if (EulerIntegrator.instance?.cameraSystem) {
        //         EulerIntegrator.instance.cameraSystem.matterportDragging = false;
        //     }
        // }
    }
}
