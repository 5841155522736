import { store } from "App";
import { getTagIdForTagSid, goToPose, goToPoseById, resetSpaceMeta } from "modules/home/SpaceDetail/SpaceView/Sidebar/TagSidebar/Tags/ShowcaseUtils";

class MpSdk {
    private static _instance: MpSdk;
    public mps: any;

    public static get Instance(): MpSdk {

        if (!this._instance) {
            this._instance = new MpSdk();
        }
        return this._instance;
    }

    init(mpSdk: any) {
        // if(mpSdk) {
        resetSpaceMeta();
        this.mps = mpSdk;

        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.HOVER, this.hoverHandler);
        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.CLICK, this.clickHandler);
        // this.mps.on(MpSdk.Instance.mps.Mattertag.Event.LINK_OPEN, () => console.log(`[st] link opened`));
        // }

    }

    public hoverHandler = (tagSid: any) => {
        // console.log(`[st] hovering ${tagSid}`);

        // prevent the billboard from showing
        // var noBillboardTag = mattertagData[1];
        // this.mps.Mattertag.preventAction(tagSid, {
        //     opening: false,
        // }).then(() => {
        // this.mps.Mattertag.getData().then((data: any) => {
        //     let tag = data.find((t: any) => t.sid == tagSid);
        //     if (tag) {
        //         let discAnchorPosition = this.mps.Mattertag.getDiscPosition(tag);
        //         // console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);

        //     }
        // }).catch(console.error);

        // }).catch(console.error);
    }


    public clickHandler = (tagSid: any) => {
        let tagId = getTagIdForTagSid(tagSid);

        // console.log(`[st] clicked on ${tagSid} - ${JSON.stringify(tagId && store.getState().home.spaceTags[tagId])}`);
        console.log(`[st] clicked on`, tagSid, store.getState().home.spaceTags[tagId || '']);

        // prevent the billboard from showing
        // var noBillboardTag = mattertagData[1];
        this.mps.Mattertag.navigateToTag(tagSid, MpSdk.Instance.mps.Mattertag.Transition.FLY)
            // .preventAction(tagSid, {
            //     opening: false,
            // }).then(() => {
            //     this.mps.Mattertag.getData().then((data: any) => {
            //         let tag = data.find((t: any) => t.sid == tagSid);
            //         if (tag) {
            //             let discAnchorPosition = this.mps.Mattertag.getDiscPosition(tag);
            //                // console.log(`[st] hovering 2 ${JSON.stringify(discAnchorPosition)}`);
            //         }
            //     }).catch(console.error);
            // })
            .catch(console.error);
    }


    public goToHomeSweep = async () => {
        let space = store.getState().home.currentSpace;

        console.log(`[st] [vp] going to home sweep`)
        let dp = space?.poses?.find(p => !!p.isDefault);
        if (dp) {
            console.log(`[st] [vp] default pose found`)
            goToPose(dp.data);
        } else {
            console.log(`[st] [vp] default pose not found`);
            // if (!store.getState().home.currentSpace?.settings?.useFreeCamera) {
                this.mps && space && space.homeSweepId && this.mps.Sweep.moveTo(space.homeSweepId)
                    .then(async () => {
                        this.mps.Camera.zoomTo(0.7).then(() => { }).catch(console.error);

                    }).catch(console.error);
            // }
        }
    }

}
export default MpSdk;


// export namespace Mpsdk {
//     mps: any;
//     export function mpSdk() { return mps}
// }
// // Usage
// import { SingletonInstance } from "path/to/Singleton";

// SingletonInstance.someMethod();
// var x = SingletonInstance; // If you need to alias it for some reason