import React from 'react';
import Box from '@material-ui/core/Box';
import ListHeader from './ListHeader';
import CardDetail from './CardDetail';
import Scrollbar from '../../../../../@crema/core/Scrollbar';
import AddCardButton from './AddCardButton';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  DropResult,
  NotDraggingStyle } from 'react-beautiful-dnd';
import {
  TaskSectionObj,
  TaskObj,
} from '../../../../../types/models/apps/ProjectBoard';

interface ListProps {
  list: TaskSectionObj;
  onEditTaskDetail: (task: TaskObj, list: TaskSectionObj) => void;
  onClickAddTask: (list: TaskSectionObj) => void;
  projectId: string;
}

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250
});


const List: React.FC<ListProps> = ({
  list,
  onEditTaskDetail,
  onClickAddTask,
  projectId,
}) => {
  return (
    <Droppable droppableId={list.id.toString()}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          // style={getListStyle(snapshot.isDraggingOver)}
        >
          <Box
            p={2}
            key={list.id}
            display='flex'
            flexDirection='column'
            className='project-col'
          >
            <ListHeader list={list} projectId={projectId} />
    
            <Scrollbar className='scroll-project-item'>
              {list.taskList && list.taskList.length > 0 ? (
                <>
                  {list.taskList.map((task, index) => {
                    return (
                      <Draggable
                        key={task.id}
                        draggableId={task.id||''}
                        index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                          >
                            <CardDetail
                              key={task.id}
                              task={task}
                              onEditTaskDetail={onEditTaskDetail}
                              list={list}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </>
              ) : null}
              {provided.placeholder}
            </Scrollbar>
    
            <AddCardButton onClickAddTask={onClickAddTask} list={list} />
          </Box>
        </div>
      )}
    </Droppable>
  );
};

export default List;
