import { SpatialThinkSDKMode } from "CustomSdk/SpatialThinkSDK";
import { ExternalSdkMode } from "mp/core/craEngine/SubSystems/core/Simulation";
import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
import { NodeProperties } from "types/models/home/HomeApp";
import { MeModelList, MeModelResponse } from "types/models/home/ThirdParty/Sketchfab";

export const OBJECT_PLACED = 'OBJECT_PLACED';
export const SHOW_PROP_PANEL = 'SHOW_PROP_PANEL';
export const SHOW_POSES = 'SHOW_POSES';
export const SET_SELECTED_NODE_ID = 'SET_SELECTED_NODE';
export const SHOW_GIZMO = 'SHOW_GIZMO';
export const SET_MY_SKETCHFAB_MODELS = 'SET_MY_SKETCHFAB_MODELS';
export const SELECT_NODE = 'SELECT_NODE';
export const SET_SDK = 'SET_SDK';
export const SET_HIGHLIGHTED_OBJECT = 'SET_HIGHLIGHTED_OBJECT';
export interface ObjectPlacedAction {
  type: typeof OBJECT_PLACED;
  payload: string;
}

export interface ShowPropPanelAction {
  type: typeof SHOW_PROP_PANEL;
  payload: boolean;
}

export interface ShowPosesAction {
  type: typeof SHOW_POSES;
  payload: boolean;
}

export interface SetSelectedNodeIdAction {
  type: typeof SET_SELECTED_NODE_ID;
  payload: string;
}
export interface SetShowGizmoAction {
  type: typeof SHOW_GIZMO;
  payload: boolean;
}
export interface SetMySketchfabModelsAction {
  type: typeof SET_MY_SKETCHFAB_MODELS;
  payload: MeModelResponse;
}

export interface SetSelectedNodeAction {
  type: typeof SELECT_NODE;
  payload: NodeProperties | undefined;
}

export interface SetSDKAction {
  type: typeof SET_SDK;
  payload: SpatialThinkSDKMode | ExternalSdkMode | undefined;
}

export interface SetHighlightedObjectAction {
  type: typeof SET_HIGHLIGHTED_OBJECT;
  payload: THREE.Object3D | undefined;
}


export type ThreeDActions =
  | ObjectPlacedAction
  | ShowPropPanelAction
  | ShowPosesAction
  | SetSelectedNodeIdAction
  | SetShowGizmoAction
  | SetMySketchfabModelsAction
  | SetSelectedNodeAction
  | SetSDKAction
  | SetHighlightedObjectAction
  ;