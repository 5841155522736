//import React, {useState, useEffect, useRef, useCallback} from 'react';
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
//import Lessons, { IOnClickPlayLessons, IShowcaseSceneTag } from '../../Lessons';
import { useDispatch } from 'react-redux';
//import ModelsList from './ModelsList';
import { Disable } from 'react-disable';

import modelMap from 'modules/home/models';
import TitlebarGridList from '@crema/core/TitlebarGridList';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
import { showMessage } from 'redux/actions';
import { canEditSpace } from '@crema/utility/Utils';
import { SimulationMode } from 'mp/core/craEngine/SubSystems/core/RenderingAndPlaceObjectStateSystem';
import { isAnnotationVisible, isUserAdmin, isUserInExperiment } from 'modules/home/SpaceDetail/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addModelRoot: {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    userControl: {
      width: '100%',

      '& .MuiOutlinedInput-input': {
        paddingTop: 8,
        paddingBottom: 6,
        minHeight: 42,
      },
    },
    form: {
      width: '100%',
    },
    gridRoot: {
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '16px',
    },
    grid: {
      padding: '10px',
      marginTop: '10px',
    },
    heading: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      lineHeight: '48px',
    },
    field: {
      width: '100%',
    },
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(2),
    },
    buttonGroup: {
      alignContent: 'center',
      justifyContent: 'center',
      width: '80%',
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      color: '#fff',
      margin: theme.spacing(1),
      minWidth: 120,
    },
    topBorder: {
      borderTop: `solid 1px ${theme.palette.grey[200]}`,
    },
    rightBorder: {
      borderRight: `solid 1px ${theme.palette.grey[200]}`,
    },
    rightBorderTransparent: {
      borderRight: `solid 1px transparent`,
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    outlineBox: {
      border: '1px solid #0000001c',
      padding: "15px",
      paddingTop: "25px",
      width: '280px',
      borderRadius: '10px'
    },
    outlineBoxTitle: {
      // marginBottom: "-5px",
      backgroundColor: "white",
      color: "#5f5f5f",
      marginTop: "-45px",
      padding: "10px",
      width: "fit-content",
      textTransform: "uppercase",
      fontSize: "13px",
    }
  }),
);
interface Props {

}

export default function ThreeDAnnotations({
  // addObject
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function getStacks() {

    // let tileData = Array.from(modelMap.values())
    //   // .filter(m => !m['access'] || (isUserAdmin && m['access']=="ADMIN"))
    //   .map(model => ({
    //     id: model.payload.objects[0].name,
    //     img: model.icon,
    //     iconMui: model.iconMui,
    //     title: model.payload.objects[0].name,
    //     onClick: () => {
    //       Simulation.instance.addObject(model.payload.objects[0].name);
    //       dispatch(showMessage("Move cursor into the Space area to add!"));
    //     },
    //     onDelete: () => { }
    //   }));

    {/* <Box style={{
            display: "block",
            // display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            width: "100%",
            maxWidth: "100%",
            overflow: "scroll",
            height: "100%"
          }}> */}

    {/* <Scrollbar >
sdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfgsdfghdfhdfghdfg
</Scrollbar> */}
    {/* <Stack direction='row' spacing={3} style={{
              //  display: "flex", maxWidth: "100%",
            // overflow: 'scroll',
            }}>
              {tileData.map(tile => <TileItem tile={tile} hasOverflowMenu={false} />)} */}
    {/* </Stack> */ }
  }

  return (console.log(`%c[st] rendering Insert 3D`, 'color: orange;'),
    <>
      <Disable disabled={!canEditSpace()}>
        <div id="annoContainer"
          className={classes.gridRoot}
        >
          {
            // propertiesPanelUIData.propertiesPanelMode !== PropertiesPanelMode.None &&
            // <div className={classes.outlineBox}>
            //   <p className={classes.outlineBoxTitle}>Properties</p>
            //   <PropertiesPanel propertiesPanelUIData={propertiesPanelUIData} />
            // </div>
          }

          {/* </Box> */}
          {modelMap.size > 0 && <TitlebarGridList tileData={
            Array.from(modelMap.values())
              .filter(m => isAnnotationVisible(m))
              .map(model => ({
                id: model.payload.objects[0].name,
                img: model.icon,
                iconMui: model.iconMui,
                title: model.payload.objects[0].name,
                hasBadgeBeta: !!model.access,
                onClick: () => {

                  // if(model.payload.objects[0].name == "Fire" || model.payload.objects[0].name == "Water Spray"){
                  //   showExperimentAlert("You found a beta feature! Play with it, add it your space. It doesn't work with Experiences/ Lessons just yet")
                  // }

                  Simulation.instance.addObject(model.payload.objects[0].name, SimulationMode.ADD_OBJECT);
                  dispatch(showMessage("Move cursor into the Space area to add!"));
                },
                onDelete: () => { }
              }))
          }
            hasOverflowMenu={false} />}
        </div>

      </Disable >
    </>
  );
}
