import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AppBar, Box, Button, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { useDispatch, useSelector, } from 'react-redux';
import { ViewMode, } from '.';
import LogicTrees from '../../Interactions/LogicTrees';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { setWorkflowsSidebarOpen, setTagsViewMode, setTagsViewModeSecondary } from 'redux/actions/Home';
import { firestore } from '@crema/services/auth/firebase/firebase';
import { Lesson, SpaceData, TagGroup } from 'types/models/home/HomeApp';
import { AppState } from 'redux/store';
import Layout from './layout';
import IndicatorPulse from '@crema/core/IndicatorPulse';
import { getCurrentTagGroup } from '../../utils';
import { Logic, ConditionTree, CONDITION_JOINER } from 'types/models/dataAccess/Logic';
import ShortUuid from 'short-uuid';
import { onUpdateTagGroup } from 'redux/actions/Step';
import Spacer from '@crema/core/Spacer';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            fontWeight: 500,
            color: 'black',
            lineHeight: '48px',
        },


        sidebarContainer: {
            minWidth: '35%',
            backgroundColor: theme.palette.background.default,
            // paddingTop: '10px',
            // maxWidth: '320px',
            // height: '100%',
            padding: '8px',
            overflow: 'auto',
            boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
            transition: 'all 0.5s ease',
            // paddingTop: '0px',
            [theme.breakpoints.down('xs')]: {
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
            },
        },
        backButton: {
            // height: '90%',
            // padding: '30px 10px',
            // margin: '10px 10px',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
                //   height: '0.75em',
            },
            // margin: 10,
            textAlign: 'center',
            cursor: 'pointer'
        },
    })
);

interface Props {
    // currentSpace: SpaceData;;
    // currentLesson: Lesson;
    tagGroupx: TagGroup;
}

export default function WorkflowsSidebar({ tagGroupx }: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
    const currentTagGroupId = useSelector<AppState, string>(({ layer }) => layer.currentTagGroupId);
    let tagGroup = getCurrentTagGroup();

    const [logic, setLogic] = useState<Logic>({ conditions: [] });

    // React.useEffect(() => {
    //     tagGroup = getCurrentTagGroup();
    // }, [currentTagGroupId])
    // const [tagGroupData, setTagGroupData] = useState<TagGroup>(null);

    // const initCurrentTagGroupData = async() => {
    //     if(currentSpace?.id) {
    //         if(currentLesson?.id) {
    //             if(currentTagGroupId) {
    //                 let data = tagGroupsList.find(tg => tg.id == currentTagGroupId)
    //                 //setTagGroupData(await (await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson}/tagGroups/${currentTagGroupId}`).get()).data);
    //                 // let docRef = await firestore.doc(`Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${currentTagGroupId}`);
    //                 // let doc = await docRef.get();
    //                 // let data = await doc.data();
    //                 if(data) {
    //                     setTagGroupData(data);
    //                 }
    //             }
    //         }
    //     }
    // }

    // useEffect(() => {
    //     initCurrentTagGroupData();
    // });

    // isWorkflowsSidebarOpen ?
    //  [ViewMode.TABLE, ViewMode.LAYERS_SIM].map(v => v.valueOf()).includes(tagsViewMode)  ||

    // const hasChanges = React.useRef<any>(null);

    function saveLogicTree() {
        // Simulation.instance.propertiesPanel.updateLogic(logic);

        if (tagGroup) {
            tagGroup.logic = logic;
            dispatch(onUpdateTagGroup(tagGroup));
            // setIsChanged(false);
            setHasPendingChanges(false);
        }
    }

    return (console.log(`%c[st] rendering WorkflowsSidebar - ${tagGroup?.bannerText}`, 'color: orange;'),
        <Layout width='500px'>

            <AppBar position="sticky" color='default'>

                <Toolbar variant="dense" style={{ border: '1px solid lightgrey' }}>
                    <Button
                        // variant='text'
                        className={classes.backButton}
                        // color='primary'
                        size='small'
                        // type='button'
                        onClick={() => {

                            // if (tagsViewMode == ViewMode.LAYERS_SIM) {
                            // dispatch(setTagsViewMode(ViewMode.LAYERS));
                            // dispatch(setTagsViewModeSecondary(''));
                            // }

                            if (hasPendingChanges) {
                                let res = window.confirm("You have unsaved changes. Are you sure you want to leave this page? Click OK to discard changes");
                                if (res) {
                                    dispatch(setWorkflowsSidebarOpen(false));
                                }
                            } else {
                                // if (hasChanges && hasChanges?.current && hasChanges.current()) {
                                dispatch(setWorkflowsSidebarOpen(false));
                            }
                        }}>
                        {/* <Tooltip title="Back"> <ArrowBackRoundedIcon /> </Tooltip> */}
                        <Tooltip title='Back'>
                            <ArrowBackRoundedIcon />
                        </Tooltip>
                    </Button>
                    <Typography
                        // variant='contained'
                        variant='h6'
                        component='span'
                    // align='center'
                    // color='secondary'

                    // className={classes.heading}
                    // gutterBottom

                    >
                        Interactions
                    </Typography>
                    {/* <Button
                        variant='contained'
                        color='secondary'
                        size='small'
                        style={{ float: 'right', marginLeft: 20 }}
                        // className={classes.addLogicTreeButton}
                        type='button'
                        onClick={() => addNewLogicTree(logic, setLogic)}
                    >
                        Add New
                    </Button>

                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={hasPendingChanges == false}
                        // className={classes.addLogicTreeButton}
                        type='button'
                        onClick={() => saveLogicTree()}
                    >
                        Save
                    </Button> */}

                </Toolbar>
            </AppBar>
            <Box component="main" className={'sidebar ' + classes.sidebarContainer}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '10px 2px',
                        width: '80%'

                    }}>
                        <Typography
                            variant='body2'
                            component='span'
                            style={{fontWeight: 'bold'}}
                        >
                            {tagGroup?.name + ": "}
                        </Typography>
                        <Spacer x={2}/>
                        <Typography
                            // variant='contained'
                            variant='caption'
                            component='span'
                            // style={{ width: '70%' }}
                        // align='center'
                        // color='secondary'

                        // className={classes.heading}
                        // gutterBottom

                        >
                            {tagGroup?.bannerText}
                        </Typography>
                    </div>

                    {/* <Typography
                variant='h6'
                align='center'
                className={classes.heading}
                gutterBottom>
                Broad: {tagsViewMode}  {primaryUseCase?.layerTermPlural || 'Experiences'}
            </Typography> */}
                    {/* <Link
            type='underlineAlways'
            //  underline='always'
            className={classes.backButton}
            // style={{ margin: 10, textAlign: 'center', cursor: 'pointer' }}
            onClick={() => {}}>
            BACK
          </Link> */}

                    {React.useMemo(() => <LogicTrees
                        tagGroup={tagGroup}
                        // logic={logic}
                        // setLogic={setLogic}
                        openLogicTreesDrawer={true}
                        onCloseDrawer={() => { }}
                        setHasPendingChanges={setHasPendingChanges}
                    // hasChanges={hasChanges}
                    />, [currentTagGroupId])}
                </div>
            </Box>
        </Layout>

    )

}


function addNewLogicTree(logic: Logic, setLogic: (logic: Logic) => void) {
    let conditionTreesTemp: ConditionTree[] = logic.conditions.map(e => e);
    let newConditionTree: ConditionTree = {
        id: ShortUuid.generate(),
        actions: [],
        conditionRoot: {
            conditionJoiner: CONDITION_JOINER.ALL,
            conditions: []
        }
    };
    conditionTreesTemp.push(newConditionTree);
    setLogic({ conditions: conditionTreesTemp });
}
