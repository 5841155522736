import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import React, { ReactNode } from "react";
import { CheckCircleOutlineRounded } from '@material-ui/icons';
import { Box, Button, makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';

interface Props {
    children: ReactNode;
    [x: string]: any;
}


const useStyles = makeStyles((theme: CremaTheme) => ({
    linkButton: {
        cursor: 'pointer',
        color: 'darkblue',
        textTransform: 'none',
        textDecoration: 'underline',
        fontSize: '12px',
        '&:hover': {
            color: '#FF3158'
        }
    }
}));


export function LinkButton({ children, ...props }: Props) {

    const classes = useStyles();
    let x = <Button
        variant="text"
        size={props.size || "small"}
        className={classes.linkButton}
        // style={{
        //     color: props.color || 'darkblue',
        // }}
        disableElevation={true}
        {...props}
    >
        {children}
    </Button>

    return <Box
        // variant="text"
        // size="small"
        className={classes.linkButton}
        style={{
            // color: props.color || 'darkblue',
        }}
        // disableElevation={true}
        {...props}
    >
        {children}
    </Box>

}