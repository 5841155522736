import { FilterDataSpaces, SpaceData } from 'types/models/home/HomeApp';
import { NotificationModel } from 'types/models/NotificationModel';
import { AppActions } from '../../types';
import * as AuthActions from '../../types/actions/Auth.actions';
import { AuthUser } from '../../types/models/AuthUser';
import {
  GET_NOTIFICATIONS,
  ADD_NOTIFICATION,
} from 'types/actions/Notification.actions';
import _ from 'lodash';
import { BasicProfiler } from '@crema/utility/Utils';

export enum VIEW_TYPE {
  LIST = 1,
  GRID = 2,
}

const INIT_STATE: {
  authUser: AuthUser | null;
  token: string | null;
  message_data: string | null;
  awaiting_verfication: boolean;

  spacesList: SpaceData[];
  sharedSpacesList: SpaceData[];
  notifications: NotificationModel[];
  notifCount: number;
  catalogItems: any[];
  viewType: VIEW_TYPE;
  filterDataSpaces: FilterDataSpaces;
}
  = {
  authUser: null,
  token: null,
  message_data: null,
  awaiting_verfication: false,

  spacesList: [],
  sharedSpacesList: [],
  notifications: [],
  notifCount: 0,
  catalogItems: [],
  viewType: VIEW_TYPE.GRID,
  filterDataSpaces: {
    name: '',
    rating: [],
  },
};

const Auth = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case AuthActions.UPDATE_AUTH_USER: {
      return action.payload == null ? //on signout - clear states
        INIT_STATE
        :
        {
          ...state,
          authUser: action.payload
        }
    }
    case AuthActions.AWAITING_VERIFICATION: {
      return {
        ...state,
        awaiting_verfication: action.payload,
      };
    }
    case AuthActions.SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        authUser: null,
      };
    }
    case AuthActions.SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case AuthActions.FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        message_data: action.payload,
      };
    }

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        notifCount: action.payload.notifCount,
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications,
          // action.payload
        ],
      };


    case AuthActions.SET_SPACE_LIST:
      return {
        ...state,
        spacesList: state.spacesList,
      };

    case AuthActions.GET_SPACES_LIST:

      return {
        ...state,
        //TODO put these in a separate User/ Auth store,
        // notifications: state.notifications,
        // notifCount: state.notifCount,

        //TODO put these in a separate global application store,
        catalogItems: state.catalogItems,

        spacesList: action.payload,
      };

    case AuthActions.SET_CATALOG_ITEMS:
      return {
        ...state,
        catalogItems: action.payload,
      };
    case AuthActions.GET_SHARED_SPACES_LIST:
      return {
        ...state,
        sharedSpacesList: action.payload,
      };

    case AuthActions.SET_THIRD_PARTY_TOKENS:
      // let u = {
      //   ...state.authUser, user: {
      //     ...state.authUser?.user,
      //     thirdPartyTokens: action.payload
      //   }
      // };

      let bp = new BasicProfiler();
      let au = _.cloneDeep(state.authUser);
      bp.lap('[clone] authUser in SET_THIRD_PARTY_TOKENS reducer');

      au && (au.user.thirdPartyTokens = action.payload);
      return {
        ...state,
        authUser: au
      };

    case AuthActions.SET_SPACE_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case AuthActions.SET_FILTER_DATA_SPACES:
      return {
        ...state,
        filterDataSpaces: action.payload,
      };

    default:
      return state;
  }
};
export default Auth;
