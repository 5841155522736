import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Select, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import ApiConfig from '@crema/services/ApiConfig';
import { appIntl } from '@crema/utility/Utils';
import { Stack } from '@mui/material';
import { Release, ReleaseItem, RELEASE_LEVEL } from 'types/models/Releases';
// import pkg from '../../../package.json';
import { AxiosError, AxiosResponse } from 'axios';

export const ReleaseNotesEditor = ({ showReleaseNotesEditor, setShowReleaseNotesEditor }: {
    showReleaseNotesEditor: boolean,
    setShowReleaseNotesEditor: (value: boolean) => void
}) => {

    const [release, setRelease] = React.useState<Release>(new Release());
    const [saveButtonText, setSaveButtonText] = React.useState<string>('Save');
    const dispatch = useDispatch();
    const messages = appIntl();
    return (<Dialog open={showReleaseNotesEditor} onClose={() => setShowReleaseNotesEditor(false)}>
        <DialogTitle>Create Release Notes</DialogTitle>
        <DialogContent>

            <Stack direction='column' style={{ padding: '16px', minWidth: '80%' }}>
                <FormControlLabel control={
                    <Checkbox checked={release.mustReload} onChange={(e) => setRelease({ ...release, mustReload: e.target.checked })} />
                } label="Force Reload with a countdown?" />

                {/* <FormControl>
                    <Checkbox checked={release.mustReload} onChange={(e) => setRelease({...release, mustReload: e.target.checked})}/>
                </FormControl> */}
                <Select
                    value={release?.level || RELEASE_LEVEL.patch}
                    onChange={(e) => setRelease({ ...release, level: (e.target.value as RELEASE_LEVEL) })}
                >
                    {Object.values(RELEASE_LEVEL).map(rl => <MenuItem value={rl} key={rl}>{rl}</MenuItem>)}
                </Select>
                <TextField style={{ minWidth: '500px' }} placeholder='Title' name='Title' value={release.title} onChange={(e: any) => setRelease({ ...release, title: (e.target.value) })} />

                <Box py={2} style={{ textAlign: 'center' }}>
                    <Button size='small' color='secondary' variant="outlined" onClick={() => setRelease({ ...release, items: [...release.items, new ReleaseItem()] })}> Add entry</Button>
                </Box>
                {release.items.map(item => <Stack direction='column' py={2}>
                    <TextField placeholder='Item Title' name='Title' value={item.title} onChange={(e: any) => { item.title = e.target.value; setRelease({ ...release }) }} />
                    <TextField multiline placeholder='Item Details' name='Details' value={item.details} onChange={(e: any) => { item.details = e.target.value; setRelease({ ...release }) }} />
                </Stack>)}

                <Button variant='contained' color='secondary' size='small'
                    onClick={() => {

                        dispatch(fetchStart());
                        ApiConfig.post('/admin/releases/', release).then((response: AxiosResponse) => {
                            if (response.status === 200) {
                                dispatch(fetchSuccess());

                                { console.log(`[st] release number is `, response.data); }
                                let releaseResponse = response.data;
                                // pkg.version = releaseResponse.id; // writing to pkg json doesn't
                                setSaveButtonText(releaseResponse.id);
                            } else {
                                console.error('data status ' + response.statusText);
                                dispatch(
                                    fetchError(messages['message.somethingWentWrong'] as string),
                                );
                            }
                        }).catch((error: AxiosError) => {
                            console.error('Error posting release: ' + error?.response?.data);
                            dispatch(fetchError(error?.response?.data));
                        });

                    }}>{saveButtonText}</Button>
            </Stack>
        </DialogContent>

    </Dialog>)
}
