import DoublyLinkedList from './DoublyLinkedList';
import INodeRefHolder from './INodeRefHolder';

export default class ManagedObject implements INodeRefHolder {
    get linkedListRef(): DoublyLinkedList<ManagedObject> | null {
        return this._linkedListRef;
    }
    public get nodeRef(): ManagedObject|null {
        return this._nodeRef;
    }
    
    public disconnectFromList():void {
        this._linkedListRef = null;
    }
    
    public connectToList(linkedListRef:DoublyLinkedList<ManagedObject>):void {
        this._linkedListRef = linkedListRef;
    }
    
    public constructor(protected _linkedListRef:DoublyLinkedList<ManagedObject>|null = null) {
        if(this._linkedListRef) {
            this._nodeRef = this._linkedListRef.push(this);
        }
    }
    
    public changeNodeRef(nodeRef:ManagedObject):void {
        this._nodeRef = nodeRef;
    }
    
    protected _nodeRef: ManagedObject|null;
}