import { ISceneNode } from "../../SubSystems/sceneManagement/SceneComponent";

export class ChangeNodeColorPassThrough {
    constructor(public colorHex:string, public alpha:number, public borderColor:boolean, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}
export class ChangeTextPanelTextPassThrough {
    constructor(public newText:string|null, public fontSize:number|null, public wireWidth:number|null, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}

export class ChangeTextPassThrough {
    constructor(public newText:string, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}

export class ChangeTextPassWithParamThrough {
    constructor(public newText:string, public param:string, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}

export class ChangeGenericActionOutcomeTextPassThrough {
    constructor(public newValue:string, public index:number, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}

export class ChangeBooleanPropertyPassThrough {
    constructor(public value:boolean, public propertyName:string, public passNode:ISceneNode|null = null, public updateUI:boolean = true){    }
}