import {BaseComponent} from './BaseComponent';
import {RenderingSystem} from '../Systems/RenderingSystem';
import {CanvasRenderer} from './CanvasTools/CanvasRenderer';
import {Mesh, Texture, Vector2} from 'three';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import * as THREE from 'three';
import {Size} from '../../mp/core/craEngine/components/DecoupledComponents/DecoupledComponent';
import {ButtonComponent} from './ButtonComponent';
import {BasicTextComponent} from './BasicTextComponent';
import {FastDictionary} from './Others/Interactable';
import {SpatialThinkSDK} from '../SpatialThinkSDK';
import {RaycastSystem} from '../Systems/RaycastSystem';
import {forEach} from 'lodash';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';
// import {Grabbable} from './Others/Grabbable';

export interface ChoiceCollectionElement {
    text: string;
    correct: boolean;
}


export class QuizComponent extends BaseComponent {
    private canvasRendererBG: CanvasRenderer;

    private borderColor: string = '#c4a404';
    private bgColor: string = '#5f69f6';

    private buttons: ButtonComponent[] = [];
    private titleText:BasicTextComponent;

    private choices: ChoiceCollectionElement[] = [];

    constructor(renderingSystem: RenderingSystem) {
        super(renderingSystem);
        this.type = 'QuizComponent';

    }

    onInit(userdata: {[p: string]: any}): void {
        super.onInit(userdata);
        this.buildTextures();
        this.buildMeshes();

        this.choices = [];// Object.assign({}, userdata["choices"]);

        for (const choice of userdata['choices']) {
            this.choices.push(choice);
        }

        for (let i = 0; i < this.choices.length; i++) {
            let tempButton = new ButtonComponent(this.renderingSystem, this);

            tempButton.inputs = Object.assign({}, {
                'transparent': true,
                'opacity': 1,
                'polygonOffset': true,
                'polygonOffsetFactor': -4,
                'borderRadius': 50,
                'color': '#f5efef',
                'hoverColor': '#ff0d60',
                'clickColor': '#3b74ff',
                'borderShadowColor': '#4bea27',
                'borderStyle': '#000000',
                'borderLineWidth': 10,
                'bgColor': 'rgba(111,200,239,0.93)',
                'bgHoverColor': 'rgba(140,0,255,0.93)',
                'bgClickColor': 'rgba(46,109,134,0.93)',
                'fontSize': 25,
                'textLabel': this.choices[i].text,
                'size': {'w': 0.7, 'h': 0.1},
                'localPosition': {'x': 0.0, 'y': 0.1, 'z': 0.01},
            }) as any;

            tempButton.inputs.localPosition.y -= 0.11 * i;

            // tempButton.inputs = Object.assign({}, tempInputs) as any;
            // tempButton.inputs.localPosition.x = 0;
            // tempButton.inputs.localPosition.y = 0.0;
            // tempButton.inputs.localPosition.z = 0.05;
            tempButton.userData["oldBGColor"] = tempButton.inputs.bgColor;
            tempButton.onClickCallback.push((((x:FastDictionary) => {
                console.log("Button Clicked ",  this.choices[i].text);
                if(tempButton.inputs.bgColor == tempButton.userData["oldBGColor"]) {
                    if(this.choices[i].isCorrect) {
                        tempButton.inputs.bgColor = `rgba(66, 255, 0,1)`;
                    } else {
                        tempButton.inputs.bgColor= `rgba(255, 0, 0, 1)`;
                    }
                }
            }).bind(this)));

            this.buttons.push(tempButton);


            // tempButton.inputs.localPosition.y -= 0.15 * i;
            this.buttons[i].onInit({});
            this.root.add(this.buttons[i].root);
        }

        this.titleText = new BasicTextComponent(this.renderingSystem);
        this.titleText.inputs.textLabel = userdata["promptText"];
        this.titleText.inputs.fontSize = 30;
        this.titleText.inputs.localPosition.z = 0.03;
        this.titleText.inputs.localPosition.y = 0.3;
        this.titleText.inputs.size = {w: 0.8, h: 0.2};
        this.titleText.onInit({});
        this.root.add(this.titleText.root);
        // SpatialThinkSDK.instance.GrabSystem.objectToGrab.push(this.root);

        // let grabbable = new Grabbable();
        // grabbable.addObject([this.root]);
        // // this.node && this.setLoaded(true);
    }

    onInputsUpdated(oldInputs: this['inputs'], force:boolean): void {
    }

    private buildTextures() {

        this.canvasRendererBG = new CanvasRenderer(1024, 1024);
        // this.canvasRendererBG.FillColor(new Vector2(0, 0), new Vector2(1024, 1024), this.bgColor);
        this.canvasRendererBG.drawBorder(new Vector2(0, 0), new Vector2(1024, 1024), 100, 20, this.borderColor);
    }

    private buildMeshes() {
        this.clearMeshes();

        this.createMesh(new Vector2(1, 1), true, null, 1, 100, true, 0, 0, new THREE.Color(this.bgColor));
        this.createMesh(new Vector2(1, 1), true, this.canvasRendererBG.Texture, 0.8, 100, true, -2);

        this.outputs.collider = this.meshes[0];
        (Simulation.instance.sdk as SpatialThinkSDK).RaycastSystem.addRayWorldObject(this.meshes[0]);
    }

    onDestroy() {
        console.error('onDestroy not implemented');
    }
}