import { ProjectObj } from 'types/models/apps/ProjectBoard';
import {
  Lesson,
  TagGroup,
} from 'types/models/home/HomeApp';
export const SET_LESSON_DATA = 'SET_LESSON_DATA';
export const SET_TAG_GROUP_LIST = 'SET_TAG_GROUP_LIST';
export const SET_TAG_GROUP_ID = 'SET_TAG_GROUP_ID';
export const SET_BANNER_TEXT = 'SET_BANNER_TEXT';
export const SET_LESSON_VARIABLES = 'SET_LESSON_VARIABLES';
export const SET_PRESENTATION_MODE = 'SET_PRESENTATION_MODE';
export const SET_BROAD_SIDEBAR_OPEN = 'SET_BROAD_SIDEBAR_OPEN';
export const STOP_LESSON = 'STOP_LESSON';
export const SET_STEP_STATUS = 'SET_STEP_STATUS';

export interface SetLessonDetailAction {
  type: typeof SET_LESSON_DATA;
  payload: Lesson | null;
}
export interface SetTagGroupListAction {
  type: typeof SET_TAG_GROUP_LIST;
  payload: TagGroup[];
}
export interface SetTagGroupAction {
  type: typeof SET_TAG_GROUP_ID;
  payload: any;
}
export interface SetBannerTextAction {
  type: typeof SET_BANNER_TEXT;
  payload: any;
}
export interface SetLessonVariablesAction {
  type: typeof SET_LESSON_VARIABLES;
  payload: any[];
}
export interface SetWorkflowsSidebarOpenAction {
  type: typeof SET_BROAD_SIDEBAR_OPEN;
  payload: boolean;
}

export interface SetPresentationModeAction {
  type: typeof SET_PRESENTATION_MODE;
  payload: boolean;
}

export interface StopLessonAction {
  type: typeof STOP_LESSON;
}

export interface SetStepStatusAction {
  type: typeof SET_STEP_STATUS;
  payload: string;
}

export type LayerActionTypes =
  | SetLessonDetailAction
  | SetTagGroupListAction
  | SetTagGroupAction
  | SetBannerTextAction
  | SetLessonVariablesAction
  | SetWorkflowsSidebarOpenAction
  | SetPresentationModeAction
  | StopLessonAction
  | SetStepStatusAction
  ;