import { store } from "App";
import { SpatialThinkSDKMode } from "CustomSdk/SpatialThinkSDK";
import { getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { handleGoToTagGroupByIndex, handleNextTagGroup } from "redux/actions/Step";
import { setSDKMode } from "redux/actions/ThreeD";

export function addXRControls(currentSession: any): HTMLDivElement {

    var controlsDiv = document.createElement('div');
    document.body.appendChild(controlsDiv);
    controlsDiv.style.float = 'right';
    controlsDiv.style.display = 'inline-flex';

    //next button
    var nextButtonDiv = document.createElement('div');
    controlsDiv.appendChild(nextButtonDiv);

    var nextButton = document.createElement('button');
    nextButton.textContent = 'Next';
    nextButton.addEventListener('click', function () {

        if (store.getState().layer.currentLesson && store.getState().layer.tagGroupsList.length > 1) {
            if (!store.getState().layer.currentTagGroupId) {
                store.dispatch(handleGoToTagGroupByIndex(1));
            } else if (getCurrentTagGroup()?.sortIndex !== store.getState().layer.tagGroupsList.length - 1) {
                store.dispatch(handleNextTagGroup());
            }
        }
    });
    nextButtonDiv.appendChild(nextButton);

    store.getState().layer.currentLesson && controlsDiv.appendChild(nextButtonDiv);

    //close button

    var closeButtonDiv = document.createElement('div');
    // overlay.style.display = 'none';
    controlsDiv.appendChild(closeButtonDiv);

    var closeSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    closeSvg.setAttribute('width', '38');
    closeSvg.setAttribute('height', '38');
    // svg.style.position = 'absolute';
    // svg.style.right = '20px';
    // svg.style.top = '20px';
    closeSvg.addEventListener('click', function () {
        currentSession?.end();
        store.dispatch(setSDKMode(SpatialThinkSDKMode.Plain));
        // history.push(`/home/space/${store.getState().home.currentSpace?.did}/project/${store.getState().projects.projectDetail?.did}`);
    });
    var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('d', 'M 12,12 L 28,28 M 28,12 12,28');
    path.setAttribute('stroke', '#000');
    path.setAttribute('stroke-width', '2');
    closeSvg.appendChild(path);

    closeButtonDiv.appendChild(closeSvg);

    return controlsDiv;

}