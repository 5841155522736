import * as THREE from 'three';
import {RenderingSystem} from './RenderingSystem';

export class LightSystem {
    constructor(protected renderingSystem:RenderingSystem) {
    
        const color = 0xFFFFFF;
        const intensity = 4;
        let light = new THREE.DirectionalLight(color, intensity);
    
        light.position.set( 0.5, 1, -1);
        light.position.multiplyScalar( 10 );
    
        light.shadow.mapSize.width = 2048;
        light.shadow.mapSize.height = 2048;
    
        light.shadow.camera.near = 0.5; // default
        light.shadow.camera.far = 450;
        light.shadow.bias = -0.0001;
    
        /*
        const dirLightHelper = new THREE.DirectionalLightHelper( light, 10 );
        (this.context as any).scene.add( dirLightHelper );
        */
        light.castShadow = true;
        this.renderingSystem.scene.add(light);
        this.renderingSystem.scene.add( new THREE.AmbientLight(0xffffFF, 3.0));
    
    
        for(let child of  this.renderingSystem.scene.children) {
            //console.log(child.type)
            //(child as THREE.Object3D).receiveShadow = true;
            this.traverseChildrenAndSetShadowReception(child);
        }
    }
    
    
    private traverseChildrenAndSetShadowReception(obj: THREE.Object3D) {
        if (obj.children.length < 1) {
            obj.receiveShadow = true;
        } else {
            for (let child of obj.children) {
                //console.log(child.type)
                this.traverseChildrenAndSetShadowReception(child);
            }
            
        }
    }
}
