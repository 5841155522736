// @ts-ignore
import { Color } from 'react-color';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import Utils from '../../Tools/Utils';
import QueueScheduler from '../../Tools/QueueScheduler';
import Simulation from '../../SubSystems/core/Simulation';
import {
    ComponentContext,
    ComponentInteractionType,
    ISceneNode,
    SceneComponent,
} from '../../SubSystems/sceneManagement/SceneComponent';
import { WindowingSystem } from '../../../../../CustomSdk/Systems/WindowingSystem';
import { CameraSystem } from '../../../../../CustomSdk/Systems/CameraSystem';
import { store } from 'App';

interface Inputs {
    running: boolean;
}

// (elsewhere at top-level)
export interface EulerIntegratorFunctionMachine {
    (dt: number): void;
}

export class EulerIntegrator extends SceneComponent {
    public pivot: THREE.Object3D | null = null;
    public scene: THREE.Scene;

    private static _instance: EulerIntegrator | null = null;

    public integrators: EulerIntegratorFunctionMachine[];

    public cameraRef: THREE.PerspectiveCamera | null = null;
    // private anotherScene: THREE.Scene | null = null;

    public cameraSystem: CameraSystem | null = null;
    public windowingSystem: WindowingSystem | null = null;

    public lastDeltaTime: number = 0;

    inputs: Inputs = {
        running: true,
    };

    events = {
        [ComponentInteractionType.CLICK]: false,
        [ComponentInteractionType.HOVER]: false,
        [ComponentInteractionType.DRAG]: false,
    };

    onInit() {

        const THREE = this.context.three;
        const root = this.context.root;

        this.scene = this.context.scene;

        this.pivot = new THREE.Object3D();
        // @ts-ignore
        this.outputs.objectRoot = this.pivot;

        this.scene = this.context.scene;

        (root as any).obj3D.add(this.pivot);


        // MpSdk?.Instance?.mps?.Pointer.setVisible(false);


        //create light in threejs
        // const light = new THREE.DirectionalLight( 0xffffff, 1 );
        // light.position.set( 0, 1, 1 );
        // this.scene.add( light );
        //
        // //create ambient light in threejs
        //   const ambientLight = new THREE.AmbientLight( 0xffffff, 0.5 );
        //   this.scene.add( ambientLight );

        // this.anotherScene = new THREE.Scene();
        //
        // //create camera in threejs





        // this.anotherTestCamera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 1000 );
        // this.anotherTestCamera.position.z = 5;
        // this.anotherTestCamera.position.y = 5;
        // this.anotherTestCamera.position.x = 5;
        // this.anotherTestCamera.lookAt(0,0,0);
        // //Set camera layer to 2
        // this.anotherTestCamera.layers.set(7);
        //
        // this.scene.add(this.anotherTestCamera);
        //
        //Create a box in threejs with the same layer visibility as the camera anotherTestCamera
        // const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        // const cube = new THREE.Mesh( geometry, material );
        // // cube.layers.set(7);
        // this.scene.add( cube );



        setTimeout(() => {

            if (store.getState().home.currentSpace?.settings?.useFreeCamera!) {


                // Execute this function after a few seconds
                let objToRemove: THREE.Object3D[] = [];
                // setTimeout(() => {
                for (let i = 0; i < this.scene.children.length; i++) {


                    const obj = this.scene.children[i];

                    //Remove obj from scene if it's a light by checking it's type being a substring of light
                    if (obj.type.toLowerCase().includes('light')) {
                        console.log('[RAJ] removing light', obj.name, obj.type);
                        //add object to remove to an array
                        // objToRemove.push(obj);
                    } else {
                        //If object name contains camera don't remove it
                        if (obj.name.toLowerCase().includes('camera')) {
                            this.cameraRef = obj.children[0] as THREE.PerspectiveCamera;
                        } else {
                            objToRemove.push(obj);
                        }
                    }



                    let lowerCaseName = obj.name.toLowerCase();

                    if (lowerCaseName.includes('skySphere') ||
                        lowerCaseName.includes('skybox') ||
                        lowerCaseName.includes('modelmeshdam') ||
                        lowerCaseName.includes('fallbackmesh') ||
                        lowerCaseName.includes('puck')) {
                        objToRemove.push(obj);
                    }

                    // if (obj.userData && obj.userData.component) {
                    //   if (obj.userData.component.onTick) {
                    //     obj.userData.component.onTick(delta);
                    //   }
                    // }
                }

                //remove all objects in the array
                for (let i = 0; i < objToRemove.length; i++) {
                    this.scene.remove(objToRemove[i]);
                }
                // }, 3000);
                let showcaseDocument = (document.getElementById('sdk-iframe') as HTMLFrameElement).contentDocument;
                console.log(`[raj] showcaseDocument`, showcaseDocument);

                this.windowingSystem = new WindowingSystem(showcaseDocument!);
                this.cameraSystem = new CameraSystem(this.windowingSystem);

                // this.cameraSystem = new CameraSystem(null);
                this.cameraSystem.camera.position.set(0, 1.7, 5);
                this.cameraSystem.camera.lookAt(new THREE.Vector3(1, 2, 0));
                this.cameraSystem.cameraSpeed = 2;
                // this.cameraSystem.rotationSpeed = 10;
                this.cameraSystem.fixedYplane = true;
                this.HookCamera();

                // this.createBackground('/assets/images/screenshot360.jpg');
                // this.createBackground('/assets/images/bgfons.com_sky_texture1991.jpg', new THREE.Vector3(0, 450, 0));
                // this.createBackground('/assets/images/101_hdrmaps_com_free.jpg', null);
                // this.createBackground('/assets/images/004_hdrmaps_com_free.jpg',null);


                // create GridHelper
                const size = 100;
                const divisions = 100;

                // const gridHelper = new THREE.GridHelper(size, divisions, 0xff0000, 0x0000ff);
                //
                // gridHelper.position.set(0, -1, 0);
                // // add it to the scene
                // this.scene.add(gridHelper);
                this.context.renderer.setClearColor(0xffffff, 1);


                // // Create ground plane
                // const geometry = new THREE.PlaneGeometry(50, 50, 1);
                // const material = new THREE.MeshBasicMaterial({color: 0xbcc6c5, side: THREE.DoubleSide }); // 0xFF0000 here represents Red color
                // const plane = new THREE.Mesh( geometry, material );
                // plane.rotateX(-Math.PI / 2);
                // // plane.position.set(0, -1, 0);
                // this.scene.add( plane );
                // this.createPlane(new THREE.Vector3(0, 0, 0));

                let sdk = Simulation.instance.sdk;
                const mode = sdk.Mode.Mode.DOLLHOUSE;
                const pos = {x: 0, y: 0, z: 0};
                const rotation = {x: -90, y: 0};
                const transition = sdk.Mode.TransitionType.FLY;
                const zoom = 1;

                sdk.Mode.moveTo(mode, {
                        pos,
                        rotation,
                        transition,
                        zoom,
                    })
                    .then(function(nextMode:any){
                        // Move successful.
                        console.log('Arrived at new view mode ' + nextMode);
                    })
                    .catch(function(error:any){
                        // Error with moveTo command
                        console.log('Error moving to new view mode: ' + error);
                    });


                // showcaseDocument!.addEventListener('mousemove', this.freeCamMouseMove.bind(this));
            }

        }, 100);

        EulerIntegrator._instance = this;
        this.integrators = [];
    }

    private createPlane(position:THREE.Vector3 | null = null) {
        const THREE = this.context.three;
        // Create the plane
        var geometry = new THREE.PlaneGeometry(50, 50, 5);

        // Number of checkerboard squares
        var squares = 128; // configure this to change the checkerboard pattern

        // Texture resolution
        var resolution = 4096; // configure this to change the texture resolution

        // Create checkerboard pattern
        var canvas = document.createElement('canvas');
        canvas.width = resolution;
        canvas.height = resolution;

        var context = canvas.getContext('2d')!;
        context.fillStyle = "#000000";
        context.fillRect(0, 0, resolution, resolution);

        var squareSize = resolution / squares; // size of each square in texture

        context.fillStyle = "#ffffff";

        for (var i = 0; i < resolution; i += squareSize) {
            for (var j = 0; j < resolution; j += squareSize) {
                if ((i / squareSize) % 2 == (j / squareSize) % 2) {
                    context.fillRect(i, j, squareSize, squareSize);
                }
            }
        }


        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;


        // Apply anisotropic filtering
        texture.anisotropy = this.context.renderer.capabilities.getMaxAnisotropy();

        // Use nearest filtering
        texture.magFilter = THREE.NearestFilter;
        texture.minFilter = THREE.NearestFilter;

        // Material
        var material = new THREE.MeshBasicMaterial({
            map: texture,
            side: THREE.DoubleSide
        });

        // Mesh
        var plane = new THREE.Mesh(geometry, material);
        plane.rotation.x = Math.PI / 2;

        if(position)   {
            plane.position.set(position.x, position.y, position.z);
        }

        this.scene.add(plane);
    }

    // private freeCamMouseMove(e:MouseEvent):void {
    //     // console.log(`[raj] showcaseDocument Mouse position: X = ${e.movementX}, Y = ${e.movementY}`);
    //
    //     if (this.cameraSystem) {
    //         if(this.cameraSystem?.matterportDragging) {
    //             this.cameraSystem.moveCameraWithMouse(e.movementX * 0.001, e.movementY * 0.001);
    //         }
    //     }
    // }

    private createBackground(textureSource: string, position:THREE.Vector3 | null = null) {
        const THREE = this.context.three;
        // creating geometry for the sphere
        const sphereGeometry = new THREE.SphereGeometry(1000, 60, 40);
        sphereGeometry.scale(-1, 1, 1); // scale geometry to invert for inside-out effect
        if(position)   {
            sphereGeometry.translate(position.x, position.y, position.z);
        }

        // creating material
        const sphereMaterial = new THREE.MeshBasicMaterial({ map: new THREE.TextureLoader().load(textureSource) });
        // create a red material
        // const sphereMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000 } );
        // creating mesh and add to the scene
        const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        this.scene.add(sphere);
    }
    public async HookCamera() {
        let sdk = Simulation.instance.sdk;
        // Create a scene object and node with a camera component.
        const cameraNode = await sdk.Scene.createNode();
        const cameraComponent = cameraNode.addComponent('mp.camera', {
            enabled: true,
            camera: this.cameraSystem!.camera,
        });


        // spy on the camera before it starts.
        // cameraComponent.spyOnEvent( new AccessGrantedSpy(cameraComponent, cameraNode));
        // cameraComponent.spyOnEvent( new AccessRevokedSpy(cameraComponent, cameraNode))\
        cameraNode.start();
    }

    onEvent(interactionType: ComponentInteractionType, eventData: unknown): void {
    }

    onInputsUpdated(oldInputs: Inputs) {
        // @ts-ignore
        const THREE = this.context.three;

        if (oldInputs.running !== this.inputs.running) {
            // @ts-ignore
        }
    }

    public static get instance(): EulerIntegrator | null {
        return EulerIntegrator._instance;
    }

    onTick(delta: number) {
        super.onTick(delta);
        // @ts-ignore
        //console.log("hieulerIntegratorNode")
        TWEEN.update();

        //Fear not, these are glorified tickers!
        for (let i of this.integrators) {
            i(delta);
        }

        this.lastDeltaTime = delta;
        if (this.cameraSystem && this.windowingSystem) {
            this.windowingSystem.processAllFrameUpdateCallbacks();
            this.windowingSystem.processAllUpdateCallbacks(delta * 0.001);
        }
        //Loop through all objects in the scene
        // console.log('this.scene.children.length', this.scene.children.length);


        if (Simulation) {
            QueueScheduler.ProcessAllQueueSchedulers(delta);
        }
    }

    onDestroy() {
        this.outputs.collider = null;
        this.outputs.objectRoot = null;
        EulerIntegrator._instance = null;
    }
}

export const eulerIntegratorType = 'mp.eulerIntegrator';
export const makeEulerIntegrator = function () {
    return new EulerIntegrator();
};
