// import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
// import { ISceneNode } from "mp/core/craEngine/SubSystems/sceneManagement/SceneComponent";
// import PropertiesPanel, { PropertiesPanelDropDownType, UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";

// export enum VariableType {
//     booleanVariableType = "Boolean",
//     csvVariableType = "List",
//     differenceVariableType = "List Difference",
//     stringVariableType = "Any"
// }

// export const getLabelForVariableType = (varType: VariableType) => {
//     switch (varType) {
//         case VariableType.booleanVariableType:
//             return "True/ False";
//         case VariableType.csvVariableType:
//             return "Values to show on Digital Gauge";
//         case VariableType.stringVariableType:
//             return "Any";
//     }
// }

// export enum VariableOptions {
//     allowControl = "allowControl",
//     type = "type"
// }

// export class VariableTypeAllowedValues {
//     public static getAllowedTypes(type: VariableType): string[] {
//         switch (type) {
//             case VariableType.booleanVariableType:
//                 return ["True", "False"]
//                 break;
//             case VariableType.csvVariableType:
//                 return [];
//                 break;
//             case VariableType.stringVariableType:
//                 return [];
//                 break;
//         }

//         return [];
//     }

//     public static getSystemVariablesOfType(variableType: VariableType, variableValues: any[], prepopulateWithNone: boolean = true, allowControlOnly: boolean = true): string[] {
//         let inputSource = [];

//         if (prepopulateWithNone) {
//             inputSource.push("None");
//         }
//         variableValues = variableValues || [];
//         for (const variable of variableValues) {
//             if (allowControlOnly) {
//                 if (VariableOptions.allowControl in variable) {
//                     if (variable[VariableOptions.allowControl]) {
//                         if (VariableOptions.type in variable) {
//                             if (variable[VariableOptions.type] === variableType) {
//                                 inputSource.push(variable.name);
//                             }
//                         }
//                     }
//                 }

//             } else {
//                 if (VariableOptions.type in variable) {
//                     if (variable[VariableOptions.type] === variableType) {
//                         inputSource.push(variable.name);
//                     }
//                 }
//             }
//         }

//         return inputSource;
//     }

//     public static updateInputSourceForNestNode(nodeUserData: any, variableValues: any[]): string[] {
//         let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, true, false)
//             .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.differenceVariableType, variableValues, false, false));

//         if (nodeUserData[UserDataProperties.inputSource1] === "None") {
//         } else {
//             if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
//                 nodeUserData[UserDataProperties.inputSource1] = "None";
//                 Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
//             }
//         }

//         return allowedSystemVariablesAsInputSourceForNest;
//     }

//     public static getAllIOTVariables(variableValues: any[]): string[] {
//         return VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, false, false)
//             .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.differenceVariableType, variableValues, false, false));
//     }

//     public static updateInputSourceForGenericToggle(nodeUserData: any, variableValues: any[]): string[] {
//         let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.booleanVariableType, variableValues, true, false)
//             .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableType.csvVariableType, variableValues, false, false));
//         if (nodeUserData[UserDataProperties.inputSource1] === "None") {
//         } else {
//             if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
//                 nodeUserData[UserDataProperties.inputSource1] = "None";
//                 Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
//             }
//         }

//         return allowedSystemVariablesAsInputSourceForNest;
//     }
// }

import { store } from "App";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import { PropertiesPanelDropDownType, UserDataProperties } from "mp/core/craEngine/SubSystems/ui-interop/PropertiesPanel";
import { Vector3 } from "three";

export enum VariableTypesAvailable {
    booleanVariableType = "Boolean",
    // csvVariableType = "List",
    // differenceVariableType = "List Difference",
    stringVariableType = "Any",
    // rotation = "Degrees of rotation"
}

export enum VariableTypeName {
    booleanVariableType = "Boolean",
    csvVariableType = "List",
    differenceVariableType = "List Difference",
    stringVariableType = "Any",
    rotation = "Degrees of rotation"
}

export const getLabelForVariableType = (varType: VariableTypeName) => {
    switch (varType) {
        case VariableTypeName.booleanVariableType:
            return "True/ False";
        case VariableTypeName.csvVariableType:
            return "Values to show on Digital Gauge";
        case VariableTypeName.stringVariableType:
            return "Any";
        case VariableTypeName.rotation:
            return VariableTypeName.rotation;
    }
}

export enum VariableOptions {
    allowControl = "allowControl",
    type = "type"
}

export class VariableTypeAllowedValues {
    public static getAllowedTypes(type: VariableTypeName): string[] {
        switch (type) {
            case VariableTypeName.booleanVariableType:
                return ["True", "False"]
                break;
            case VariableTypeName.csvVariableType:
                return [];
                break;
            case VariableTypeName.stringVariableType:
                return [];
                break;
        }

        return [];
    }

    public static getSystemVariablesOfType(variableType: VariableTypeName, variableValues: any[], prepopulateWithNone: boolean = true, allowControlOnly: boolean = true): string[] {
        let inputSource = [];

        if (prepopulateWithNone) {
            inputSource.push("None");
        }
        variableValues = variableValues || [];
        for (const variable of variableValues) {
            if (allowControlOnly) {
                if (VariableOptions.allowControl in variable) {
                    if (variable[VariableOptions.allowControl]) {
                        if (VariableOptions.type in variable) {
                            if (variable[VariableOptions.type] === variableType) {
                                inputSource.push(variable.name);
                            }
                        }
                    }
                }

            } else {
                if (VariableOptions.type in variable) {
                    if (variable[VariableOptions.type] === variableType) {
                        inputSource.push(variable.name);
                    }
                }
            }
        }

        return inputSource;
    }

    public static updateInputSourceForNestNode(nodeUserData: any, variableValues: any[]): string[] {
        // let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.csvVariableType, variableValues, true, false)
        //     .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.differenceVariableType, variableValues, false, false));
        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.booleanVariableType, variableValues, true, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.stringVariableType, variableValues, false, false));

        if (nodeUserData[UserDataProperties.inputSource1] === "None") {
        } else {
            if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
                nodeUserData[UserDataProperties.inputSource1] = "None";
                Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
            }
        }

        return allowedSystemVariablesAsInputSourceForNest;
    }

    public static getAllIOTVariables(variableValues: any[]): string[] {
        return VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.booleanVariableType, variableValues, false, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.stringVariableType, variableValues, false, false));
    }

    public static updateInputSourceForGenericToggle(nodeUserData: any, variableValues: any[]): string[] {
        let allowedSystemVariablesAsInputSourceForNest = VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.booleanVariableType, variableValues, true, false)
            .concat(VariableTypeAllowedValues.getSystemVariablesOfType(VariableTypeName.stringVariableType, variableValues, false, false));
        if (nodeUserData[UserDataProperties.inputSource1] === "None") {
        } else {
            if (allowedSystemVariablesAsInputSourceForNest.indexOf(nodeUserData[UserDataProperties.inputSource1]) === -1) {
                nodeUserData[UserDataProperties.inputSource1] = "None";
                Simulation.instance.propertiesPanel.dropDownChangeEvents<string>(PropertiesPanelDropDownType.InputSource1_DropDown, "None", 0);
            }
        }

        return allowedSystemVariablesAsInputSourceForNest;
    }
}

// export type ValueType = Number | string | Boolean | Vector3;
// // export type VariableValueType = Number | string | Boolean | Vector3;

// export abstract class Variable<T extends VariableValue<ValueType>> {
//     private readonly id: number;
//     public name: string;
//     public allowControl: boolean = false;
//     public range: T[] = [];
//     public value: T | undefined;
//     public type: VariableTypeName;

//     private maxValueId: number = 0;

//     constructor() {
//         this.id = this.getNewVariableId();
//     }

//     // protected constructor(props: {name?: string, allowControl?: boolean, range?: VariableValue[], type?: VariableTypeName}) {
//     // this.id = Variable.getUniqueId();
//     // }

//     private getNewValueId() {
//         return this.maxValueId++;
//     }

//     public data() {

//         let { allowControl, name, id, range, type } = this;
//         // ({ allowControl } = this); //{this.id, this.name, this.allowControl};
//     }

//     // public set range(range: VariableValue[]) {
//     //     this.range = range;
//     // }
//     public abstract addToRangeFromText(text: string): void;
//     public resetRangeFromText(text: string) {

//         // let varSet = new Set(text.split(',').map(v => v.trim()));
//         // new Set(this.range).add(text.split(',').map(v => v.trim()));
//         // varSet.
//         // let rangeSet = new Set(this.range);
//         text.split(',').map(v => v.trim()).forEach((val) => {
//             if (!this.range.map(v => v.value).includes(val)) {
//                 this.addToRangeFromText(text);
//             }
//         })
//         // this.range = [];
//         // this.addToRangeFromText(text);
//     }

//     public addToRange(label: string, value: ValueType) {
//         let x = VariableValue.makeValue(label, value, this.getNewValueId()) as T;
//         this.range.push(x as T);
//         // this.range.push(new VariableValue(label, value, this.getValueId()));
//     }

//     // public addToRange(r: T) {
//     //     this.range.push(r);
//     // }

//     // public get type() {
//     //     return this.type;
//     // }

//     // public set type(type: VariableTypeName) {
//     //     this.type = type;
//     // }

//     // public set value(varValueId: number | undefined) {
//     //     this.value = this.range.find(vv => vv.id == varValueId);
//     // }

//     // public get range() {
//     //     return this.range;
//     // }

//     public resetRange() {
//         this.range = [];
//     }

//     public getNewVariableId() {
//         let varIds = store.getState().home.spaceVariables?.map(v => v.id);

//         if (varIds && (varIds?.length > 0)) {
//             let maxId = Math.max(...varIds);
//             return (maxId + 1);
//         }
//         return 0;
//     }

//     public static translateLegacyVars(vars: any[]) {
//         let vv: any[] = [];
//         vars.map(v => {
//             switch (v.type?.toLowerCase()) {
//                 case 'boolean':
//                     let bv: BooleanVariable = Variable.makeVar(VariableTypeName.booleanVariableType) as BooleanVariable;
//                     bv.addToRangeFromText(v.values);
//                     vv.push(bv);
//                     break;

//                 case 'csv':
//                 case 'list':
//                     let listv = this.makeVar(VariableTypeName.csvVariableType) as ListVariable;
//                     listv.addToRangeFromText(v.values);
//                     break;

//                 case 'difference':
//                 case 'list difference':
//                     let diffv = this.makeVar(VariableTypeName.differenceVariableType) as ListDiffVariable;
//                     diffv.addToRangeFromText(v.values);
//                     break;

//                 case 'any':
//                 case 'string':
//                 case undefined:
//                 case '':
//                     let textv = this.makeVar(VariableTypeName.stringVariableType) as TextVariable;
//                     textv.addToRangeFromText(v.values);
//                     break;

//             }

//         });
//         return vv;

//     }

//     public static makeVar(type: VariableTypeName) {

//         switch (type) {
//             case VariableTypeName.booleanVariableType:
//                 return new BooleanVariable();

//             case VariableTypeName.stringVariableType:
//                 return new TextVariable();
//             // if (props) {
//             //     sv.range = (props.values?.split(',') || []).map((v: any) => ({ label: v.trim() }));
//             // }

//             // return sv;
//             case VariableTypeName.csvVariableType:
//                 return new ListVariable();
//             case VariableTypeName.differenceVariableType:
//                 return new ListDiffVariable();
//             case VariableTypeName.rotation:
//                 return new RotationVariable();
//             default:
//                 return new TextVariable();
//         }
//     }
// }

// export abstract class VariableValue<T extends ValueType> {
//     public readonly id: number;
//     public label: string;
//     public value: T;

//     public static makeValue(label: string, value: ValueType, id: number) {
//         switch (typeof value) {
//             case 'string':
//                 return new TextValue(label, value, id);
//             case 'number':
//                 return new NumberValue(label, value, id);
//             case 'boolean':
//                 return new BooleanValue(label, value, id);

//         }

//     }

//     // public get id() {
//     //     return this.id;
//     // }

//     // public get label() {
//     //     return this.label;
//     // }
//     // public set label(label: string) {
//     //     this.label = label;
//     // }
//     // public get value() {
//     //     return this.value;
//     // }
//     // public set value(value: T) {
//     //     this.value = value;
//     // }

//     public setValue(value: T) {
//         this.value = value;
//     }

//     constructor(label: string, value: T, id: number) {
//         this.id = id;
//         this.label = label;
//         this.value = value;
//     }
// }

// export class RotationValue extends VariableValue<Number> {
// }
// export class BooleanValue extends VariableValue<Boolean> {
// }
// export class PositionValue extends VariableValue<Vector3> {
// }
// export class TextValue extends VariableValue<string> {

//     public setValue(value: string) {
//         this.value = value;
//         this.label = value;
//     }
// }
// export class NumberValue extends VariableValue<Number> {
// }
// export class BooleanVariable extends Variable<BooleanValue> {

//     public addToRangeFromText(text: string) {

//         let range = text?.split(',').map((v: string) => v.trim()) || [];
//         if (range && range.length > 0) {
//             if (['open', 'on', 'true', 'yes'].includes(range[0]?.toLowerCase())) {
//                 this.setTrueLabel(range[0]);
//                 this.setFalseLabel(range[1]);
//             } else {
//                 this.setTrueLabel(range[1]);
//                 this.setFalseLabel(range[0]);
//             }
//         }
//     }

//     // public addToRange(r: BooleanValue) {
//     //     throw new Error("Cannot add to a boolean variable!")
//     // };

//     // public addToRange (r: VariableValue) {
//     //     this.range.push(r);
//     // }

//     constructor() {
//         super();
//         this.type = VariableTypeName.booleanVariableType;
//         this.addToRange('Off', false);
//         this.addToRange('On', true);
//         // this.range = [new BooleanValue('On', true, 0), new BooleanValue('Off', false, 1)];
//         // this.addToRange(new BooleanValueType('On', true, 0));
//         // this.addToRange(new BooleanValueType('Off', false, 1));
//     }
//     // value = this.range[0];

//     setTrueLabel(label: string) {
//         this.range[0].label = label;
//     }
//     setFalseLabel(label: string) {
//         this.range[1].label = label;
//     }
// }

// export class RotationVariable extends Variable<RotationValue> {
//     public addToRangeFromText(text: string): void {
//         (text?.split(',') || []).map((vv: string) =>
//             this.addToRange(vv, Number.parseFloat(vv)));
//     }
//     constructor() {
//         super();
//         this.type = VariableTypeName.rotation;
//         // this.addToRange(new RotationValue('Closed', 0, 0));
//         // this.addToRange(new RotationValue('Partially open', 45, 1));
//         // this.addToRange(new RotationValue('Open', 90, 2));
//         this.addToRange('Closed', 0);
//         this.addToRange('Partially open', 45);
//         this.addToRange('Open', 90);

//     }
// }

// export class TextVariable extends Variable<TextValue> {
//     public addToRangeFromText(text: string): void {
//         (text?.split(',') || []).map((vv: string) =>
//             this.addToRange(vv, vv));
//     }
//     constructor() {
//         super();
//         this.type = VariableTypeName.stringVariableType;
//     }

// }

// export class ListVariable extends Variable<NumberValue> implements ticks {

//     public addToRangeFromText(text: string): void {
//         (text?.split(',') || []).map((vv: string) =>
//             // listv.addToRange(new NumberValue(vv, Number.parseFloat(vv), i)));
//             this.addToRange(vv, Number.parseFloat(vv)));
//     }

//     constructor() {
//         super();
//         this.type = VariableTypeName.csvVariableType;
//     }

//     ticks: () => { //shows values at every refresh
//     }
// }
// export class ListDiffVariable extends Variable<NumberValue> implements ticks {
//     public addToRangeFromText(text: string): void {
//         (text?.split(',') || []).map((vv: string, i: number) =>
//             // diffv.addToRange(new NumberValue(vv, Number.parseFloat(vv), i)));
//             this.addToRange(vv, Number.parseFloat(vv)));

//     }
//     constructor() {
//         super();
//         this.type = VariableTypeName.differenceVariableType;
//     }

//     ticks: () => { //shows values at every refresh
//     }
// }

// export interface ticks {
//     ticks: () => void;
// }
