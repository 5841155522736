import { firestore, storage } from "@crema/services/auth/firebase/firebase";
import { Dispatch } from "react";
import { store } from "App";
import { AppActions } from "types";
import { SET_SKETCHFAB_SEARCH_RESPONSE } from "types/actions/Home.action";
import { fetchStart, fetchSuccess, fetchError, showMessage, showMessagePersistent } from "./Common";
import ExternalApi from "@crema/services/ExternalApiConfig";
import { MeModelList, MeModelResponse, ModelDownloadResponse, ModelSearchResponse } from "types/models/home/ThirdParty/Sketchfab";
import { AxiosRequestConfig, AxiosResponse } from "axios";
// import { getSketchfabToken } from "modules/home";
import { unzip } from 'unzipit';
import Api from "@crema/services/ApiConfig";
import { onGetCatalogItems } from 'redux/actions/FirebaseAuth';
import { FileObj, ThirdPartyName, ThirdPartyToken, ThreeDModel, ThreeDModelSource, ThreeDModelType } from "types/models/dataAccess/User";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";
import Utils from "mp/core/craEngine/Tools/Utils";
import { BasicProfiler } from '@crema/utility/Utils';
import { SET_MY_SKETCHFAB_MODELS } from "types/actions/ThreeD.actions";
import { SimulationMode } from "mp/core/craEngine/SubSystems/core/RenderingAndPlaceObjectStateSystem";
// import { Blob } from "@firebase/firestore-types";
// import { UPDATE_AUTH_USER } from "types/actions/Auth.actions";

export const getMyModels = (token: string, q?: string) => {
    return (dispatch: Dispatch<AppActions>) => {
        let query = q || `https://api.sketchfab.com/v3/me/models`;
        const authUser = store.getState().auth.authUser;
        if (authUser) {
            dispatch(fetchStart());
            let axiosConfig: AxiosRequestConfig = {};
            axiosConfig.headers = {
                'Authorization': `Bearer ${token}`
            }

            ExternalApi.get(query, axiosConfig)
                .then(data => {
                    if (data.status === 200) {
                        let meModelResponse = data.data as MeModelResponse;
                        dispatch(fetchSuccess());
                        dispatch({ type: SET_MY_SKETCHFAB_MODELS, payload: meModelResponse });
                    } else {
                        console.log('response data', data)
                        dispatch(fetchError('Models not found'));
                    }
                })
                .catch(error => {
                    console.error('error', error);
                    dispatch(fetchError('Error connecting to sketchfab'));
                });
        };

        // dispatch(executeSketchfabQuery(q));
        // dispatch(executeSketchfabQuery(`https://api.sketchfab.com/v3/search\?type\=models\&q\=${searchText}\&downloadable\=true\&archives_max_size\=7000000`));
    }
}

export const onSearchSketchfab = ({ searchText, noSizeLimit }: { searchText: string, noSizeLimit?: boolean }) => {
    return (dispatch: Dispatch<AppActions>) => {
        let q = `https://api.sketchfab.com/v3/search\?type\=models\&q\=${searchText}\&downloadable\=true`;
        if (!noSizeLimit) {
            q = q.concat(`\&archives_max_size\=7000000`);
        }
        dispatch(executeSketchfabQuery(q));
        // dispatch(executeSketchfabQuery(`https://api.sketchfab.com/v3/search\?type\=models\&q\=${searchText}\&downloadable\=true\&archives_max_size\=7000000`));
    }
}

export const executeSketchfabQuery = (
    queryString: string,
    // cursorQuery: string | null,
    // authUser: AuthUser | null
): any => {
    return (dispatch: Dispatch<AppActions>) => {
        const authUser = store.getState().auth.authUser;
        if (authUser) {
            dispatch(fetchStart());

            ExternalApi.get(queryString)
                .then(data => {
                    if (data.status === 200) {
                        let searchResponse = data.data as ModelSearchResponse;

                        // if (!!payload.code) {
                        //     dispatch(fetchError(SpaceErrors.get(payload.code)));
                        //     setTimeout(() => {
                        //         dispatch(fetchError("")); //clear error message after a few seconds, so next error can be displayed
                        //     }
                        //         , 3000);
                        // } else {
                        dispatch(fetchSuccess());
                        dispatch({ type: SET_SKETCHFAB_SEARCH_RESPONSE, payload: searchResponse });
                        // }

                    } else {
                        console.log('response data', data)
                        dispatch(fetchError('Models not found'));
                    }
                })
                .catch(error => {

                    console.error('error', error);
                    // onFailure(error);
                    dispatch(fetchError('Error connecting to sketchfab'));
                    // history && history.goBack();

                    // dispatch(fetchError(error.response.statusText));
                });
        };
    }
};

export const onDownloadModel = (
    uid: string,
    name: string,
    token: string
    // authUser: AuthUser | null
): any => {
    return (dispatch: Dispatch<AppActions>) => {
        const authUser = store.getState().auth.authUser;
        if (authUser) {

            firestore.collection(`ThreeDModels`).where('externalID', '==', uid)
                .withConverter(ThreeDModel.ThreeDModelConverter)
                .get().then((qs) => {
                    if (!qs.empty) {
                        let m = qs.docs[0].data() as ThreeDModel;
                        addObjectToSpace(m);
                        //TODO what if the model needs to be re-downloaded?

                    } else {
                        try {
                            dispatch(showMessagePersistent('Starting download'));
                            let axiosConfig: AxiosRequestConfig = {};
                            axiosConfig.headers = {
                                'Authorization': `Bearer ${token}`
                            }

                            const bp = new BasicProfiler();

                            ExternalApi.get(`https://api.sketchfab.com/v3/models/${uid}/download`, axiosConfig)
                                .then(data => {
                                    if (data.status === 200) {
                                        let downloadResponse = data.data as ModelDownloadResponse;
                                        if (downloadResponse?.gltf?.url) {

                                            bp.lap('sketchfab get download link');
                                            store.dispatch(showMessagePersistent('Downloading files'));
                                            ExternalApi.get(downloadResponse?.gltf?.url, { responseType: 'blob' })
                                                .then(async (fileResponse: AxiosResponse<Blob>) => {

                                                    if (fileResponse.status === 200) {

                                                        // dispatch(fetchSuccess());

                                                        let files: FileObj[] = [];

                                                        bp.lap('sketchfab download gltf');
                                                        // let modelName = `sketchfab-${name}`;
                                                        // let storagePrefixNoParent = `3d/gltf/${modelName}/`;
                                                        // async function readFiles(url) {
                                                        const { entries } = await unzip(fileResponse.data);

                                                        bp.lap('unzip');
                                                        // print all entries and their sizes
                                                        for (let i = 0; i < Object.entries(entries).length; i++) {
                                                            let item = Object.entries(entries)[i];

                                                            if (item[1]?.isDirectory) {
                                                                continue;
                                                            }

                                                            const blob = await item[1].blob();

                                                            bp.lap(`get blob ${item[0]} size: ${item[1].size / 1000} kB`);
                                                            files.push({ path: item[0], content: blob });

                                                            // console.log(`[st] blob has size ${blob.size}`);
                                                            // let baseModelRef = storage.ref(`${storagePrefixNoParent}`).child(item[0]);
                                                            // await baseModelRef.put(blob);
                                                            // console.log(`[st] uploaded ${uploadedFile.ref.fullPath} ${uploadedFile.ref.getDownloadURL()}`);
                                                            // fileUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/3d/${type}/${modelName}/${parent}${fileName}`);
                                                            // console.log(`[st] uploaded file is at https://storage.googleapis.com/virtual-tc.appspot.com/${storagePrefixNoParent}${item[0]}`);
                                                        }

                                                        let thumbnailFiles: FileObj[] = [];
                                                        let modelData = store.getState().home?.sketchfabSearchResponse?.results?.find(sr => sr.uid == uid);

                                                        modelData?.thumbnails?.images?.map(i => {
                                                            let pA = i.url?.split('/') || [];
                                                            thumbnailFiles.push({ path: pA[pA.length - 1], content: i.url })
                                                        });
                                                        bp.lap('get thumbnail link - ' + JSON.stringify(thumbnailFiles[0]));

                                                        dispatch(onModelUpload2(uid, files, ThreeDModelType.gltf, thumbnailFiles, name, '', (threeDModel: ThreeDModel) => {
                                                            addObjectToSpace(threeDModel);

                                                            // Simulation.instance.handleModelClick(threeDModel.id);
                                                        }, ThreeDModelSource.SKETCHFAB, '', bp));

                                                        bp.lap('dispatched');
                                                        // bp.log(`[st] done` );
                                                    }
                                                }).catch((e) => {
                                                    console.error(e);
                                                });
                                        }

                                        // if (!!payload.code) {
                                        //     dispatch(fetchError(SpaceErrors.get(payload.code)));
                                        //     setTimeout(() => {
                                        //         dispatch(fetchError("")); //clear error message after a few seconds, so next error can be displayed
                                        //     }
                                        //         , 3000);
                                        // } else {
                                        // dispatch(fetchSuccess());
                                        // dispatch({ type: SET_SKETCHFAB_RESULTS, payload: models });
                                        // }

                                    } else {
                                        console.log('response data', data)
                                        dispatch(fetchError('Models not found'));
                                    }
                                })
                                .catch(error => {

                                    console.error('error', error);
                                    if (error?.response?.status == 401) {
                                        dispatch(clearSketchfabToken());
                                    }

                                    // if 401, show Link Button again
                                    // onFailure(error);
                                    dispatch(fetchError('Error connecting to sketchfab'));
                                    // history && history.goBack();

                                    // dispatch(fetchError(error.response.statusText));
                                });
                        } catch (e: any) {
                            dispatch(fetchError('Error connecting to sketchfab. Please try again, or reach out to us to get this fixed'));
                            console.error(e);
                        }


                    }
                })



        };
    }

};


export const onModelUpload = (
    files: any[],
    type: string,
    thumbnailFile: any,
    // authUser: AuthUser | null,
    dedupeNameFrom: any[],
    name: string,
    bucketPrefix: string,
    currentSpaceId: string,
    onComplete: () => void
): any => {
    return async (dispatch: Dispatch<AppActions>) => {
        const authUser = store.getState().auth.authUser;
        if (authUser) {
            dispatch(fetchStart());

            let fileUrls: string[] = [];
            let mainFile = files.find(f => f.file.path.endsWith(type));

            if (!mainFile) {
                dispatch(fetchError(`No ${type} found. Please try again`));
            } else {
                let pathArray = mainFile.file.path.split("/").filter((i: string) => !!i.trim());

                let modelName = name || "Upload on " + (new Date()).toLocaleString();
                if (pathArray.length > 1) {
                    modelName = pathArray[pathArray.length - 2];
                }

                let mainFolderName = modelName;

                let existingModelsWithSameName = dedupeNameFrom.filter(i => i.name?.toLowerCase() === modelName?.toLowerCase());
                if (existingModelsWithSameName.length > 0) {
                    modelName += `${existingModelsWithSameName.length}`;
                }

                if (!!bucketPrefix.trim()) {
                    bucketPrefix = bucketPrefix.trim() + "/";
                } else {
                    bucketPrefix = '';
                }
                let storagePrefixNoParent = `3d/${type}/${bucketPrefix}${modelName}/`;

                await Promise.all(
                    files.map(async (fileObj: any) => {
                        let file = fileObj.file;
                        let fileName: any = file?.name;

                        let pathArray: any[] = file.path.split("/").filter((i: string) => !!i.trim());
                        pathArray.splice(pathArray.length - 1, 1); //remove filename
                        let mainFolderIndex = pathArray.lastIndexOf(mainFolderName);

                        // if (pathArray.length == 1){

                        // } else
                        if (mainFolderIndex > -1) {
                            pathArray.splice(0, mainFolderIndex + 1);
                        }

                        let parent = pathArray.join("/");

                        parent[0] == "/" && (parent = parent.substring(1));
                        if (!!parent.trim()) {
                            parent += "/";
                        }

                        let storagePrefix = `${storagePrefixNoParent}${parent}`;
                        // let storagePrefix = `3d/${type}/${bucketPrefix}${modelName}/${parent}`;

                        let baseModelRef = storage.ref(storagePrefix).child(fileName);
                        await baseModelRef.put(file);
                        // console.log(`[st] uploaded ${uploadedFile.ref.fullPath} ${uploadedFile.ref.getDownloadURL()}`);
                        // fileUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/3d/${type}/${modelName}/${parent}${fileName}`);
                        fileUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/${storagePrefix}${fileName}`);
                        // .then(() => {
                        //   const imageURL = baseModelRef.getDownloadURL();
                        //   return imageURL;
                        // }).catch(console.error);
                    }));

                //sub folder files
                // await uploadSubfolderFiles();


                //thumbnail
                // let prefix = `3d/${type}/${modelName}`;

                let thumbnailUrl = '';
                if (thumbnailFile) {
                    let file = thumbnailFile[0].file;
                    let fileName: any = file?.name;

                    let baseModelRef = storage.ref(storagePrefixNoParent).child(fileName);

                    let uploadedFile = await baseModelRef.put(file);
                    console.log(`[st] uploaded ${uploadedFile.ref.fullPath} ${uploadedFile.ref.getDownloadURL()}`);

                    // let thumbnailUrl = `https://storage.googleapis.com/virtual-tc.appspot.com/${prefix}/${fileName}`;

                    thumbnailUrl = `https://storage.googleapis.com/virtual-tc.appspot.com/${storagePrefixNoParent}${fileName}`;
                }


                let docRef = firestore.collection(`catalog`).doc();
                await docRef.set({
                    id: docRef.id,
                    type: type,
                    name: modelName,
                    space: currentSpaceId,
                    createdBy: authUser?.uid,
                    createdOn: new Date(),
                    source: ThreeDModelSource.UPLOAD,
                    isPrivate: true //!(authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN"))
                });
                await docRef.update({
                    fileUrls: fileUrls,
                    thumbnailUrl: thumbnailUrl
                });
                dispatch(showMessage("Successfully uploaded! Verifying download..."));

                //make model bucket/folder public
                try {
                    let modelCheckRes = await Api.post('/admin/model-check', { "modelPath": storagePrefixNoParent });

                    if (modelCheckRes.status === 200) {
                        console.log(`[st] model now accessible ${modelName}`);
                        dispatch(fetchSuccess());

                        dispatch(onGetCatalogItems());
                        dispatch(showMessage("Successfully uploaded!"));
                        // dispatch({ type: GET_SPACES_LIST, payload: data.data });
                        onComplete();
                    } else {
                        console.log(`[st] error: `, modelCheckRes)
                        dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
                    }
                } catch (error) {
                    console.log(error)
                    dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
                };
            }


        }
    };
};

export const onModelUpload2 = (
    sketchfabID: string,
    files: FileObj[],
    type: ThreeDModelType,
    thumbnailFiles: FileObj[],
    // authUser: AuthUser | null,
    // dedupeNameFrom: any[],
    name: string,
    bucketPrefix: string,
    // currentSpaceId: string,
    onComplete: (item: any) => void,
    source: ThreeDModelSource,
    description?: string,
    bp?: BasicProfiler
): any => {
    const authUser = store.getState().auth.authUser;
    function findMainFile(files: any[], type: ThreeDModelType, source: ThreeDModelSource) {
        return files.find(f => f.path.endsWith('.' + type));
    }

    return async (dispatch: Dispatch<AppActions>) => {
        try {
            if (!bp) {
                bp = new BasicProfiler();
            }

            bp.lap('starting upload');

            if (authUser) {
                store.dispatch(showMessagePersistent('Saving to your account...'));

                if (!findMainFile(files, type, source)) {
                    dispatch(fetchError(`No ${type} found. Please try again`));
                } else {

                    let modelName = name || "Upload on " + (new Date()).toLocaleString();

                    // let existingModelsWithSameName = dedupeNameFrom.filter(i => i.name?.toLowerCase() === modelName?.toLowerCase());
                    // if (existingModelsWithSameName.length > 0) {
                    //     modelName += `${existingModelsWithSameName.length}`;
                    // }

                    let doc = firestore.collection(`ThreeDModels`).doc();

                    let bucketPrefix = `https://storage.googleapis.com/virtual-tc.appspot.com/`;
                    let storagePrefix = `3d/${type}/${source}/${doc.id}/`;
                    let threeDModel = ThreeDModel.makeObject(source);
                    threeDModel.id = doc.id;
                    threeDModel.externalID = sketchfabID;
                    threeDModel.type = type;
                    threeDModel.name = modelName;
                    threeDModel.createdBy = authUser?.uid;
                    threeDModel.createdOn = new Date();
                    threeDModel.isPrivate = true;
                    threeDModel.source = source;
                    threeDModel.fileUrls = [];

                    // await Promise.all(
                    //     threeDModel.fileUrls = files.map(f => {

                    //         // let dir = `/${doc.id}/`;
                    //         // let localPath = dir + f.path;

                    //         // var fs = require('file-system');

                    //         // var domUrl = window.URL || window.webkitURL || window;

                    //         // var url = domUrl.createObjectURL(f.content);

                    //         // //@ts-ignore
                    //         // window.webkitResolveLocalFileSystemURL(url, (s: any) => {
                    //         //     console.log(`[st] resolved url is ${s}`);
                    //         // })


                    //         // fs.mkdir(dir, [''], function (err: any) {console.log(`[st] ${err}`) });
                    //         // // fs.mkdirSync('1/2/3/4/5', [mode]);
                    //         // fs.writeFile(localPath, f.content, function (err: any) {console.log(`[st] ${err}`) });

                    //         // return localPath;
                    //         // @ts-ignore
                    //         //                     return window.webkitRequestFileSystem(Window.TEMPORARY, 10000, (fs: any) => {
                    //         // // // FormData
                    //         //                         fs.root.setFile(localPath, f.content);
                    //         //                         let a = fs.root.getFile(localPath);
                    //                                 return localPath;

                    //         //                     });
                    //         // );


                    //         //@ts-ignore
                    //         // let fs = window.webkitRequestFileSystem(Window.TEMPORARY, );
                    //         // FileSystem.prototype.root.getDirectory();
                    //         // FileSystem.writeFile(localPath, f);
                    //         // (new window.FileSystem()).window.webkitRequestFileSystem()
                    //         // window?.webkitRequestFileSystem()


                    //         // let s = (new window.FileSystem()).root.fullPath;
                    //         // window.FileSystemDirectoryHandle.
                    //         // var domUrl = window.URL || window.webkitURL || window;

                    //         // var url = domUrl.createObjectURL(f.content);

                    //         // //@ts-ignore
                    //         // window.webkitResolveLocalFileSystemURL('blob:http://localhost:3000/17cb7d18-c32e-4049-841c-b77955f567ac', (s) => {return s})

                    //         // return url;

                    //     }));

                    // onComplete(threeDModel);
                    // files.push(thumbnailFile[0]);

                    bp.lap('doc created');
                    await Promise.all(
                        files.map(async (file: FileObj) => {
                            // let file = fileObj.file;
                            // let file = getFile();

                            threeDModel.fileUrls.push(`${bucketPrefix}${storagePrefix}${file.path}`);
                            return storage.ref(storagePrefix).child(file.path).put(file.content);
                            // return baseModelRef.put(file.content).then((data) =>
                            //     data.ref.getDownloadURL().then((downloadURL) =>
                            //         (bp.lap(`[st] uploaded file: ${downloadURL}`), threeDModel.fileUrls.push(downloadURL))))
                        }));

                    bp.lap('model uploaded');
                    store.dispatch(showMessagePersistent('Adding to your space'));
                    onComplete(threeDModel);
                    bp.lap('model inserted');

                    // let file = thumbnailFile[0].file;
                    // let fileName: any = file?.name;
                    // let thumbnailFile = thumbnailFileObj.file;

                    // fetch(thumbnailFiles[0].content, { mode: 'no-cors'})
                    //     .then(res => {
                    //         console.log('got blob 1')
                    //         return res.blob()
                    //     }) // Gets the response and returns it as a blob
                    //     .then(blob => {
                    //         console.log('got blob 2')
                    //         storage.ref(storagePrefix).child('thumbnails/' + thumbnailFiles[0].path)
                    //             .put(blob)
                    //     })

                    // // .then(() =>
                    // threeDModel.thumbnailUrls = [`${bucketPrefix}${storagePrefix}${thumbnailFiles[0].path}`];
                    // );

                    threeDModel.thumbnailUrls = [thumbnailFiles[0].content];

                    // await baseModelRef.put(thumbnailFile.content);

                    // let downloadURL = await (await baseModelRef.put(thumbnailFiles[0].content)).ref.getDownloadURL();
                    // console.debug(`[st] uploaded thumbnail file: ${downloadURL}`);
                    // threeDModel.thumbnailUrls = [downloadURL];

                    // fileUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/${storagePrefixNoParent}${file.path}`);
                    // thumbnailUrls.push(`https://storage.googleapis.com/virtual-tc.appspot.com/${storagePrefix}thumbnails/${thumbnailFile.path}`);

                    bp.lap('thumbnail uploaded');
                    // dispatch(showMessage("Successfully uploaded! Inserting... "));

                    // threeDModel.fileUrls = fileUrls;
                    // threeDModel.thumbnailUrls = thumbnailUrls;

                    doc.set(JSON.parse(JSON.stringify(threeDModel)));
                    bp.lap('file urls updated');

                    // dispatch(onGetCatalogItems());
                    // bp.lap('catalog refreshed');

                    // let docRef = firestore.collection(`catalog`).doc();
                    // await docRef.set({
                    //     id: docRef.id,
                    //     type: type,
                    //     name: modelName,
                    //     // space: currentSpaceId,
                    //     createdBy: authUser?.uid,
                    //     createdOn: new Date(),
                    //     isPrivate: true //!(authUser && authUser.user.permissions && authUser.user.permissions.includes("MODEL_ADMIN"))
                    // });
                    // await docRef.update({
                    //     fileUrls: fileUrls,
                    //     thumbnailUrl: thumbnailUrls
                    // });


                    //make model bucket/folder public
                    // try {
                    //     let modelCheckRes = await Api.post('/admin/model-check', { "modelPath": `${storagePrefix}` });
                    //     bp.lap('model check result');
                    //     if (modelCheckRes.status === 200) {
                    //         console.log(`[st] model now accessible ${modelName}`);
                    //         dispatch(fetchSuccess());


                    // bp.lap();
                    // dispatch(showMessage("Successfully uploaded!"));
                    //         // dispatch({ type: GET_SPACES_LIST, payload: data.data });

                    // onComplete(threeDModel);
                    // bp.lap('inserted');
                    //     } else {
                    //         console.log(`[st] error: ${JSON.stringify(modelCheckRes)}`)
                    //         dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
                    //     }
                    // } catch (error) {
                    //     console.log(error)
                    //     dispatch(fetchError("The model is uploaded, but we couldn't verify the download. Please check the uploaded model from the catalog, or try again"));
                    // };


                }


            }

        } catch (e: any) {
            dispatch(fetchError('Error connecting to sketchfab. Please try again, or reach out to us to get this fixed'));
            console.error(e);
        }
    };
};

export const clearSketchfabToken = (): any => {

    return async (dispatch: Dispatch<AppActions>) => {
        let authUser = store.getState().auth.authUser;

        let t = (authUser?.user.thirdPartyTokens?.findIndex((t: ThirdPartyToken) => t.type == ThirdPartyName.SKETCHFAB));

        if (
            t !== undefined && t > -1
            && authUser && authUser.user.thirdPartyTokens) {
            // tokens[t].data = null;
            let tokens = (authUser?.user.thirdPartyTokens || []).filter((to, i) => i !== t);
            await firestore.doc(`Users/${authUser?.uid}`).update('thirdPartyTokens', tokens);

            // dispatch({type: UPDATE_AUTH_USER, payload: null});
            // dispatch({ type: UPDATE_AUTH_USER, payload: {
            //     ...authUser, user: {
            //         ...authUser.user,
            //         thirdPartyTokens: tokens
            //     }
            // } });
        }
    }
}

function addObjectToSpace(threeDModel: ThreeDModel) {
    Simulation.instance.addObject(JSON.stringify(threeDModel), SimulationMode.ADD_OBJECT).then(() => {
        alert(`Sketchfab models are often too big- try adjusting the Size Property to 0.1 or 0.01 in Object Properties in case you don't see the model correctly`);
    })
}
