export default function extension(ctr: any ) {
    let originalFunction: Function;
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      originalFunction = descriptor.value;

      ctr.prototype[propertyKey] = function(...args:any){
        return originalFunction(this, ...args);
      }
    }
}
