import * as THREE from 'three';
import INodeRefHolder from '../ds/INodeRefHolder';
import DoublyLinkedList from '../ds/DoublyLinkedList';
import ManagedObject from '../ds/ManagedObject';

export default class RigidBody extends ManagedObject {
    
    
    public velocity: THREE.Vector3;
    public position: THREE.Vector3;
    public angularVelocity: number;
    public angle: number;
    
    protected acceleration: THREE.Vector3;
    protected angularAcceleration: number;
    private _inverseMass: number;
    private _inverseInertia:number;

    public constructor(_linkedListRef:DoublyLinkedList<RigidBody>|null = null) {
        super(_linkedListRef);
        this.velocity = new THREE.Vector3(0, 0, 0);
        this.position = new THREE.Vector3(0, 0, 0);
        this.acceleration = new THREE.Vector3(0, 0, 0);
        this.mass = 1;
        this.inertia = 1.0
        this.angle = 0;
        this.angularVelocity = 0;
        this.angularAcceleration = 0;
    }
    
    private _mass: number;
    private _inertia: number;
    
    get mass(): number {
        return this._mass;
    }
    
    get inertia(): number {
        return this._inertia;
    }
    
    set inertia(value: number) {
        if (value < 1) {
            console.error('[RigidBody] Inertia can\'t be lower than 1');
            return;
        } else {
            this._inertia = value;
            this._inverseInertia = 1.0 / this._inertia;
        }
    }
    
    set mass(value: number) {
        if (value < 1) {
            console.error('[RigidBody] Mass can\'t be lower than 1');
            return;
        } else {
            this._mass = value;
            this._inverseMass = 1.0 / this._mass;
        }
    }
    
    public applyForce(force: THREE.Vector3): void {
        this.acceleration.x += force.x * this._inverseMass;
        this.acceleration.y += force.y * this._inverseMass;
        this.acceleration.z += force.z * this._inverseMass;
    }
    
    public applyTorque(torque: number):void {
        this.angularAcceleration += torque * this._inverseInertia;
    }
    
    public applyDamping(factor: number): void {
        this.velocity.x *= factor;
        this.velocity.y *= factor;
        this.velocity.z *= factor;
    }
    
    public applyAngularDamping(factor:number):void {
        this.angularVelocity *= factor;
    }
    
    public simulate(dt: number) {
        this.velocity.x += this.acceleration.x * dt;
        this.velocity.y += this.acceleration.y * dt;
        this.velocity.z += this.acceleration.z * dt;
        
        this.position.x += this.velocity.x * dt;
        this.position.y += this.velocity.y * dt;
        this.position.z += this.velocity.z * dt;
        
        this.angularVelocity += this.angularAcceleration * dt;
        
        this.angle += this.angularVelocity * dt;
        
        this.acceleration.set(0, 0, 0);
        this.angularAcceleration = 0;
    }
    //public listRef: DoublyLinkedList<ManagedObject>;
    
}
