import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";

export const FileUploader = (props: any) => {
    const config = {};

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: props.acceptedFileTypes,
        multiple: !props.alowOnlyOne,
        onDrop: acceptedFiles => {
            const files = acceptedFiles.map(file => {
              return {
                id: Math.floor(Math.random() * 10000),
                file,
                preview: URL.createObjectURL(file),
              };
            });
            // onAddAttachments(files);
            // console.log(`[st] ${files}`);
            props.handleFiles(files);
          },
    });


    const baseStyle = {
        flex: 1,
        display: 'flex',
        // flexDirection: "column",
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };
    const activeStyle = {
        borderColor: '#2196f3',
    };
    const acceptStyle = {
        borderColor: '#00e676',
    };
    const rejectStyle = {
        borderColor: '#ff1744',
    };

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragAccept, isDragReject],
    );


    const acceptedFilesItems =
        acceptedFiles &&
        acceptedFiles.map((file: any) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));
    const rejectedFilesItems =
        fileRejections &&
        fileRejections.map((file: any) => (
            <li key={file.path}>
                {file.path} - {file.size} bytes
            </li>
        ));
    return (

        <section className='container'>

            <div className='container'>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <span style={{color:'gray', textAlign: 'center', cursor:'pointer'}}>
                        {props.title ||
                        `Drag 'n' drop some files here, or click to select files`}
                        <br/><br/>
                    {props.subtitle}</span>
                </div>
            </div>


            <aside>
                {acceptedFiles && acceptedFiles.length > 0  && <>
                <h5>Accepted files</h5>
                <ul>{acceptedFilesItems}</ul></>}
                {fileRejections && fileRejections.length>0 && <> <h5>Rejected files</h5>
                <ul>{rejectedFilesItems}</ul></>}
            </aside>
        </section>
    );
};