import React from 'react';
import { Redirect } from 'react-router-dom';

import { createRoutes } from '../@crema/utility/Utils';

import { errorPagesConfigs } from './errorPages';
import { authRouteConfig } from './auth';
import { initialUrl } from '../shared/constants/AppConst';
import { menuLevelConfig } from './home/components/menu';
import { homeConfig } from './home';
import { appsConfig } from './apps';
import { adminConfig } from './reports';

const routeConfigs = [
  ...authRouteConfig,
  ...homeConfig,
  ...menuLevelConfig,
  ...errorPagesConfigs,
  ...appsConfig,
  ...adminConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '*',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
