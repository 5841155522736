import { store } from 'App';
import { MathUtils, Matrix4, Quaternion } from 'three';
import { Vector3 } from "three";
import { OBJECT_PLACED } from 'types/actions/ThreeD.actions';
import { ISceneNodeExtensions } from "../../extensions/ISceneNodeExtensions";
import { QuaternionExtensions } from "../../extensions/QuaternionExtensions";
import CardinalAxesAndPlanes from "../../Tools/CardinalAxesAndPlanes";
import Utils from "../../Tools/Utils";
import { CraSubSystem } from "../CraSubSystem";
import InputSubSystem from "../input/InputSubSystem";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import NodeStorage from '../storageAndSerialization/NodeStorage';
import { InitialPlacementStyle, UserDataProperties, UserDataTypes } from "../ui-interop/PropertiesPanel";
import RenderingAndPlaceObjectStateSystem, { SimulationMode } from "./RenderingAndPlaceObjectStateSystem";
import Simulation from './Simulation';

export default class PlaceObjectBehaviors extends RenderingAndPlaceObjectStateSystem {

    protected setupPointerIntersectionSubscription() {
        this.renderingSubSystem.sdk().Pointer.intersection.subscribe((intersectionData: {
            object: string;
            position: any;
            normal: any;
        }) => {
            switch (this.simulationMode) {
                case SimulationMode.ADD_OBJECT:
                case SimulationMode.PLACE_MODE:
                    if (
                        intersectionData.object === 'intersectedobject.model' ||
                        intersectionData.object === 'intersectedobject.sweep'
                    ) {
                        this.updateLastNodePosition(intersectionData.position, intersectionData.normal);
                        // let node = this.sceneLoader.getLastNodeAdded();
                        // store.dispatch({type: OBJECT_PLACED, payload: node?.userData?.id });
                    }
                    break;
                case SimulationMode.ADD_INTERNAL_OBJECT:
                    if (
                        intersectionData.object === 'intersectedobject.model' ||
                        intersectionData.object === 'intersectedobject.sweep'
                    ) {
                        this.updateLastNodePosition(intersectionData.position, intersectionData.normal);
                        let lastNode = this.sceneLoader.getLastNodeAdded();
                        lastNode.userData.type = UserDataTypes.internalObject;
                        lastNode.userData.srcObjectId = Simulation.instance.srcObjectIdForInternalObjects;
                        let srcNode = Simulation.instance.sceneLoader.findNodeByID(Simulation.instance.srcObjectIdForInternalObjects);

                        if (srcNode) {
                            let existingMarker = srcNode.userData.markers?.find((marker: any) => marker.id === lastNode.userData.id)
                            if (existingMarker) {

                                srcNode.userData.markers?.forEach((marker: any) => {
                                    if (marker.id === lastNode.userData.id) {
                                        marker.position = intersectionData.position
                                    }
                                });
                            } else {
                                !srcNode.userData.markers && (srcNode.userData.markers = []);
                                srcNode.userData.markers.push({
                                    id: lastNode.userData.id,
                                    position: intersectionData.position
                                })

                            }
                            NodeStorage.storeNode(srcNode);
                        }
                        //  = Array.from(new Set(srcNode.userData.markers || []).add(lastNode.userData.id)));

                        console.log(`[st] [objects] lastselected node is ${lastNode.name}-- ${lastNode.userData.nameToShow}`)
                        // saveInternalObject();

                        // let node = this.sceneLoader.getLastNodeAdded();
                        // store.dispatch({type: OBJECT_PLACED, payload: node?.userData?.id });
                    }
                    break;
                case SimulationMode.GRAB_MODE:
                    if (
                        intersectionData.object === 'intersectedobject.model' ||
                        intersectionData.object === 'intersectedobject.sweep'
                    ) {
                        this.updateLastNodePosition(intersectionData.position, intersectionData.normal);
                        // let node = this.sceneLoader.getLastNodeAdded();
                        // store.dispatch({type: OBJECT_PLACED, payload: node?.userData?.id });
                    }

            }
        });
    }

    protected updateLastNodePosition(newPos: Vector3, newNormal: Vector3): void {
        let lastNode: ISceneNode | null = null;
        if (this.simulationMode == SimulationMode.ADD_OBJECT || this.getSimulationMode() == SimulationMode.ADD_INTERNAL_OBJECT) {
            lastNode = this.sceneLoader.getLastNodeAdded();
        } else if ([SimulationMode.PLACE_MODE, SimulationMode.GRAB_MODE].includes(this.simulationMode)) {
            lastNode = this.lastSelectedNode;
        }

        if (!lastNode) {
            this.cancelPlaceMode();
            return;
        }
        var offsetDistance: number = 0;
        if (UserDataTypes.initialPlacementStyle in lastNode.userData) {
            switch (lastNode.userData[UserDataTypes.initialPlacementStyle]) {
                case InitialPlacementStyle.arrow:
                    this.orientArrowToSurface(lastNode, newPos);
                    break;
                case InitialPlacementStyle.thermostat:
                    this.orientThermostatNestToSurface(lastNode, newPos, newNormal);
                    break;
                case InitialPlacementStyle.analogGauge:
                    this.orientThermostatNestToSurface(lastNode, newPos, newNormal);
                    break;
                case InitialPlacementStyle.dial:
                    this.orientThermostatNestToSurface(lastNode, newPos, newNormal, true);
                    break;
                case InitialPlacementStyle.threeFeetAbove:
                    this.placeObject3FeetAboveSurface(lastNode, newPos);
                    break;
                case InitialPlacementStyle.plane:
                    this.orientPlaneToSurface(lastNode, newPos, newNormal);
                    break;
                case InitialPlacementStyle.offsetFromNormal:
                    if (UserDataProperties.distanceForNormalOffset in lastNode.userData) {
                        offsetDistance = lastNode.userData[UserDataProperties.distanceForNormalOffset];
                    }

                    this.offsetNodeFromSurface(lastNode, newPos, newNormal, offsetDistance);
                    break;
                case InitialPlacementStyle.wire:
                    this.orientNodeForWire(lastNode, newPos, newNormal, offsetDistance);
                    break;
                default:
                    if (UserDataProperties.distanceForNormalOffset in lastNode.userData) {
                        offsetDistance = lastNode.userData[UserDataProperties.distanceForNormalOffset];
                    }

                    this.orientNodeToSurface(lastNode, newPos, newNormal, offsetDistance);
                    break;
            }
        } else {
            this.orientNodeToSurface(lastNode, newPos, newNormal);
        }
    }

    protected orientThermostatNestToSurface(node: ISceneNode, pos: Vector3, newNormal: Vector3, dialMode: boolean = false): void {
        let rotation = new Quaternion();
        QuaternionExtensions.assignFromJSONString(rotation, node.userData["quaternionStart"]);

        let matrixWithSeparatedBasis = Utils.MakeBasisWithNormalAndTwoCardinalVectors(newNormal, CardinalAxesAndPlanes.instance.yAxis, CardinalAxesAndPlanes.instance.xAxis);
        let angleToMakeNestUpright = Math.acos(CardinalAxesAndPlanes.instance.yAxis.dot(matrixWithSeparatedBasis.forward));
        let surfaceFacing = matrixWithSeparatedBasis.up.dot(CardinalAxesAndPlanes.instance.xAxis);

        if (surfaceFacing > 0) {
            angleToMakeNestUpright *= -1;
        }

        rotation.multiply(new Quaternion().setFromAxisAngle(matrixWithSeparatedBasis.up, angleToMakeNestUpright));
        if (dialMode) {
            rotation.multiply(new Quaternion().setFromAxisAngle(matrixWithSeparatedBasis.right, -90 * MathUtils.DEG2RAD));
        }
        rotation.multiply(new Quaternion().setFromRotationMatrix(matrixWithSeparatedBasis.matrix));
        //console.log(angleToMakeNestUpright * MathUtils.RAD2DEG);

        ISceneNodeExtensions.setRotation(node, rotation);
        ISceneNodeExtensions.setPosition(node, pos);
    }

    protected orientArrowToSurface(node: ISceneNode, pos: Vector3): void {
        let newNormal = new Vector3(0, 1, 0);
        let newPos = new Vector3(pos.x, pos.y, pos.z);

        let cameraPosition = this.camera.Position.clone();
        let nodePosition = node.position.clone();

        cameraPosition.y = nodePosition.y;
        let newRotation = new Quaternion();

        var lookAtMatrix = new Matrix4();
        lookAtMatrix.lookAt(nodePosition, cameraPosition, CardinalAxesAndPlanes.instance.yAxis);
        newRotation.setFromRotationMatrix(lookAtMatrix);

        ISceneNodeExtensions.setRotation(node, newRotation);
        ISceneNodeExtensions.setPosition(node, newPos.clone().add(newNormal.multiplyScalar(1.2)));
    }

    protected placeObject3FeetAboveSurface(node: ISceneNode, pos: Vector3): void {
        let newNormal = new Vector3(0, 1, 0);
        let newPos = new Vector3(pos.x, pos.y, pos.z);

        let cameraPosition = this.camera.Position.clone();
        let nodePosition = node.position.clone();
        let upVector = new Vector3(0, 0.5, 0);

        cameraPosition.y = nodePosition.y;
        let newRotation = new Quaternion();

        var lookAtMatrix = new Matrix4();
        lookAtMatrix.lookAt(cameraPosition, nodePosition, upVector);
        newRotation.setFromRotationMatrix(lookAtMatrix);

        ISceneNodeExtensions.setRotation(node, newRotation);
        ISceneNodeExtensions.setPosition(node, newPos.clone().add(newNormal.multiplyScalar(0.1145)));
    }

    protected orientNodeToSurface(node: ISceneNode, pos: Vector3, newNormal: Vector3, offsetDistance: number = 0): void {
        let oldRotation = new Quaternion();
        let position = new Vector3(pos.x, pos.y, pos.z);
        //
        QuaternionExtensions.assignFromJSONString(oldRotation, node.userData["quaternionStart"]);
        let localNormal = new Vector3(newNormal.x, newNormal.y, newNormal.z);

        let newRotation = new Quaternion();
        newRotation.setFromUnitVectors(CardinalAxesAndPlanes.instance.yAxis, localNormal);
        newRotation.multiply(oldRotation);

        position.addScaledVector(localNormal, offsetDistance);

        ISceneNodeExtensions.setRotation(node, newRotation);
        ISceneNodeExtensions.setPosition(node, position);
    }

    protected orientNodeForWire(node: ISceneNode, pos: Vector3, newNormal: Vector3, offsetDistance: number = 0): void {
        let oldRotation = new Quaternion();
        let position = new Vector3(pos.x, pos.y, pos.z);
        //
        QuaternionExtensions.assignFromJSONString(oldRotation, node.userData["quaternionStart"]);
        let localNormal = new Vector3(0, 1, 0);

        let newRotation = new Quaternion();
        newRotation.setFromUnitVectors(CardinalAxesAndPlanes.instance.yAxis, localNormal);
        newRotation.multiply(oldRotation);

        position.addScaledVector(localNormal, offsetDistance);

        ISceneNodeExtensions.setRotation(node, newRotation);
        ISceneNodeExtensions.setPosition(node, position);
    }

    protected offsetNodeFromSurface(node: ISceneNode, pos: Vector3, newNormal: Vector3, offset: number): void {
        let rotation = new Quaternion();
        let position = new Vector3(pos.x, pos.y, pos.z);
        //
        QuaternionExtensions.assignFromJSONString(rotation, node.userData["quaternionStart"]);
        let localNormal = new Vector3(newNormal.x, newNormal.y, newNormal.z);

        position.addScaledVector(localNormal, offset);

        ISceneNodeExtensions.setRotation(node, rotation);
        ISceneNodeExtensions.setPosition(node, position);
    }

    protected orientPlaneToSurface(node: ISceneNode, pos: Vector3, newNormal: Vector3): void {
        let rotation = new Quaternion();
        let position = new Vector3(pos.x, pos.y, pos.z);
        //
        QuaternionExtensions.assignFromJSONString(rotation, node.userData["quaternionStart"]);

        let surfaceUpVector = new Vector3(newNormal.x, newNormal.y, newNormal.z);
        Utils.reorientNormalToIdealSurface(surfaceUpVector);

        let rotationBetweenSurfaceNormalAndWorldUpVector = new Quaternion().setFromUnitVectors(CardinalAxesAndPlanes.instance.zAxis, surfaceUpVector);
        rotation.multiply(rotationBetweenSurfaceNormalAndWorldUpVector);
        position.addScaledVector(surfaceUpVector, 0.1);

        ISceneNodeExtensions.setRotation(node, rotation);
        ISceneNodeExtensions.setPosition(node, position);
    }

    protected constructor() {
        super();
    }
}