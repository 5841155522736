import { grid } from '@mui/system';
import { store } from 'App';
import { SpatialThinkSDKMode } from 'CustomSdk/SpatialThinkSDK';
import * as THREE from 'three';
import { Material, XRFrame, XRRigidTransform } from 'three';
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { CameraSystem } from './CameraSystem';

export interface RenderCallback {
    (timestamp: any, frame: any): void;
}

export class RenderingSystem {
    protected container: HTMLElement;
    protected _renderer: THREE.WebGLRenderer;
    protected _scene: THREE.Scene;
    protected clock: THREE.Clock;
    protected _stepsPerFrame: number;
    protected _stepsPerFrameInverse: number;

    protected renderCallbacks: RenderCallback[];
    private _baseObject3D: THREE.Object3D;

    public labelRenderer: CSS2DRenderer;

    constructor(protected _cameraSystem: CameraSystem) {
        this.container = document.createElement('div');
        this.clock = new THREE.Clock();

        this._renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this._renderer.setPixelRatio(window.devicePixelRatio);
        // this._renderer.setClearColor(new THREE.Color(0.5, 0.3, 0.5), 1);
        this._renderer.physicallyCorrectLights = true;
        this._renderer.outputEncoding = THREE.sRGBEncoding;
        this._renderer.shadowMap.enabled = true;
        // this._renderer.shadowMap.bias = 0.0001;
        this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this._renderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
        this.container.appendChild(this._renderer.domElement);
        this._cameraSystem.windowingSystem.initContainer(this.container, this._renderer.domElement);

        this.renderCallbacks = [];

        this.stepsPerFrame = 1;

        this._scene = new THREE.Scene();
        this._baseObject3D = new THREE.Object3D();
        this._scene.add(this._baseObject3D);

        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.Plain) {
            var planeGeometry = new THREE.PlaneGeometry(100, 100, 32);
            var planeMaterial = new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false, });
            var plane = new THREE.Mesh(planeGeometry, planeMaterial);
            plane.position.set(0, 0, 0);
            plane.rotation.set(Math.PI / 2, 0, 0);
            this._scene.add(plane);

            const grid = new THREE.GridHelper(100, 100, 0x000000, 0x000000);
            (grid.material as Material).opacity = 0.2;
            (grid.material as Material).transparent = true;
            // console.log('s35 adding grid plane')
            this._scene.add(grid);
            this._scene.add(new THREE.AxesHelper(100));
        }

        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
        this.labelRenderer.domElement.style.position = 'absolute';
        this.labelRenderer.domElement.style.top = '0px';
        this.labelRenderer.domElement.id = 'labelRenderer';
        // this.labelRenderer.domElement.style.pointerEvents = 'none';

        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.Plain) {
            document.getElementById('sdk-iframe')?.appendChild(this.labelRenderer.domElement);
        }
    }

    //Get bounding rect of domElement
    public getBoundingClientRect(): DOMRect {
        return this._renderer.domElement.getBoundingClientRect();
    }

    public set stepsPerFrame(x) {
        this._stepsPerFrame = x;
        this._stepsPerFrameInverse = 1.0 / this._stepsPerFrame;
    }

    public get stepsPerFrame(): number {
        return this._stepsPerFrame;
    }

    public registerRenderCallback(renderCallback: RenderCallback): number {
        return this.renderCallbacks.push(renderCallback) - 1;
    }

    public unregisterRenderCallback(index: number): void {
        this.renderCallbacks.splice(index, 1);
    }

    public startRenderer() {
        window.addEventListener('resize', this.onWindowResize.bind(this));
        this._renderer.setAnimationLoop(this.render.bind(this));
    }

    protected render(timestamp: any, frame: any) {
        this._cameraSystem.windowingSystem.processAllFrameUpdateCallbacks();

        const deltaTime = Math.min(0.05, this.clock.getDelta()) * this._stepsPerFrameInverse;

        for (let i = 0; i < this._stepsPerFrame; i++) {
            this._cameraSystem.windowingSystem.processAllUpdateCallbacks(deltaTime);
        }

        for (const renderCallback of this.renderCallbacks) {
            renderCallback(timestamp, frame);
        }
        this.renderer.render(this._scene, this._cameraSystem.camera);

        // if(this.renderer.xr && frame) {
        //     const referenceSpace = this.renderer.xr.getReferenceSpace();
        //     const xrSession = this.renderer.xr.getSession();

        //     // const xrViewerPose = await xrSession?.requestAnimationFrame(nu);
        //     let f = frame as XRFrame;
        //     let pose = f.getViewerPose(referenceSpace!);

        //     if(pose) {
        //         // this._cameraSystem.camera.fov = 65;
        //         // this._cameraSystem.camera.projectionMatrix.fromArray(pose.views[0].projectionMatrix)
        //         // this._cameraSystem.camera.updateProjectionMatrix();
        //     }
        // }

        this.labelRenderer.render(this._scene, this._cameraSystem.camera);
    }

    protected onWindowResize() {
        // if(this.renderer.xr) {
        //     if(this.renderer.xr.enabled) {

        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.AR && this._cameraSystem?.windowingSystem) {
            this._cameraSystem.windowingSystem.ar = true;
        }
        // }

        this._cameraSystem.camera.aspect = this._cameraSystem.windowingSystem.aspectRatio;
        this._cameraSystem.camera.updateProjectionMatrix();

        //Check if ar
        if (store.getState().threeD.sdkMode == SpatialThinkSDKMode.AR && this._cameraSystem?.windowingSystem) {
            this._cameraSystem.camera.fov = 65;
            this._cameraSystem.camera.updateProjectionMatrix();
        }

        this._renderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
        this.labelRenderer.setSize(this._cameraSystem.windowingSystem.width, this._cameraSystem.windowingSystem.height);
    }

    public disableXR() {
        this.renderer.xr.enabled = false;
    }

    public enableXR() {
        this.renderer.xr.enabled = true;
    }

    get renderer(): THREE.WebGLRenderer {
        return this._renderer;
    }

    get scene(): THREE.Scene {
        return this._scene;
    }

    get base(): THREE.Object3D {
        return this._baseObject3D;
    }

    get cameraSystem(): CameraSystem {
        return this._cameraSystem;
    }

}
