import {makeStyles} from '@material-ui/core/styles';
import {CremaTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  root: {
    minHeight: 48,
    width: 'max-content',
    '&.active': {
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {},
    '& .list-item-text': {
      padding: '0 0 0 16px',

    },

    color: theme.palette.text.primary,
    textDecoration: 'none!important',
    '&.dense': {
      padding: '8px 12px 8px 12px',
      minHeight: 40,
      '& .list-item-text': {
        padding: '0 0 0 8px',
      },
    },
  },
  navListItemText: {
    '& .MuiTypography-body1': {
      fontSize: '14px',
    }

  },
  activeMenuItem: {
     backgroundColor: theme.palette.primary.main, height: '5px', width: '70%', borderRadius:'5px', justifySelf: 'flex-end', marginBottom: '-12px'
  }
}));
export default useStyles;
