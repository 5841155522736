import { BasicProfiler } from '@crema/utility/Utils';
import _ from 'lodash';
import { AppActions } from 'types';
import {
  ADD_PROJECT_SECTION,
  ADD_SECTION_TASK,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  DELETE_SECTION,
  DELETE_LIST_TASK,
  EDIT_PROJECT_DETAIL,
  EDIT_SECTION_TASK,
  GET_PROJECT_DETAIL,
  GET_PROJECTS,
  GET_MEMBER_LIST,
  GET_PROJECT_LABEL_LIST,
  EDIT_PROJECT_LABEL,
  DELETE_PROJECT_LABEL,
  ADD_PROJECT_LABEL,
  ADD_MEMBER_LIST_INVITES,
  UPDATE_TASK
} from '../../types/actions/Projectboard.actions';
import {
  ProjectObj,
  LabelObj,
  CollaboratorObj,
} from '../../types/models/apps/ProjectBoard';

// const defaultProject: ProjectObj = {id: 0, name: 'Default', sectionList: [], labelList: [], spaceId: '', memberList: []
// // sectionList: [
// //   {id: 0, name: 'Scheduled', taskList: [], color: taskStatusColors[0]},
// //   {id: 1, name: 'In Progress', taskList: [], color: taskStatusColors[1]},
// //   {id: 2, name: 'Complete', taskList: [], color: taskStatusColors[2]},
// //   {id: 3, name: 'Needs Attention', taskList: [], color: taskStatusColors[3]},
// // ]
// };

const initialState: {
  projectList: ProjectObj[];
  labelList: LabelObj[];
  memberList: CollaboratorObj[];
  projectDetail: ProjectObj | null;
} = {
  projectDetail: null,
  projectList: [],
  labelList: [],
  memberList: [],
};

const Projects = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_MEMBER_LIST:
      return {
        ...state,
        memberList: action.payload,
      };

    case GET_PROJECT_LABEL_LIST:
      return {
        ...state,
        labelList: action.payload,
      };

    case GET_PROJECTS:
      return {
        ...state,
        projectList: action.payload,
      };

    case GET_PROJECT_DETAIL:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case ADD_NEW_PROJECT:
      return {
        ...state,
        projectList: state.projectList.concat(action.payload),
      };

    case EDIT_PROJECT_DETAIL: {
      return {
        ...state,
        projectList: state.projectList.map(project =>
          project.id === action.payload.id ? action.payload : project,
        ),
      };
    }

    case DELETE_PROJECT:
      return {
        ...state,
        projectList: state.projectList.filter(project => project.id !== action.payload),
      };

    case DELETE_SECTION:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case ADD_PROJECT_SECTION:
      return {
        ...state,
        projectDetail: action.payload,
      };

    // case EDIT_PROJECT_LIST:
    //   return {
    //     ...state,
    //     projectDetail: action.payload,
    //   };

    case ADD_SECTION_TASK:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case EDIT_SECTION_TASK:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case EDIT_PROJECT_LABEL:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case DELETE_PROJECT_LABEL:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case ADD_PROJECT_LABEL:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case ADD_MEMBER_LIST_INVITES:
      return {
        ...state,
        projectDetail: action.payload,
      };

    case DELETE_LIST_TASK:
      return {
        ...state,
        projectDetail: action.payload,
      };


    case UPDATE_TASK: //TODO: this is kinda ugly. clean it up - does updating the whole project obj cause slowness?
      // let taskObj: TaskObj | undefined = undefined;

      let bp = new BasicProfiler();
      let p = _.cloneDeep(state.projectDetail);
      bp.lap('[clone] projectDetail in updatetask');


      // state.projectDetail?.sectionList?.forEach(s =>
      for (let i = 0; i < (p?.sectionList?.length || 0); i++) {
        let s = p?.sectionList[i];
        if (!!s) {
          let taskObjIndex = s.taskList.findIndex(t => t.id == action.payload?.id);
          if (taskObjIndex > -1) {
            s.taskList[taskObjIndex] = action.payload;

            break;
          }
        }
      }

      // );
      return {
        ...state,
        projectDetail: p,
      };

    default:
      return state;
  }
};
export default Projects;
