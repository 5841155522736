import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  ANNOTATION_TYPE,
  PeopleObj, SpaceData, SpaceRole,
} from 'types/models/home/HomeApp';
import {
  Box,
  Fab,
  List,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { canEditSpace } from '@crema/utility/Utils';
import { onDeleteSpacePeople } from 'redux/actions/Space';
import { useAuthUser } from '@crema/utility/AppHooks';
import PeopleItem from './PeopleItem';
import Layout from '../layout';
import ConfirmationDialog from '@crema/core/ConfirmationDialog';
import IntlMessages from '@crema/utility/IntlMessages';
import { fetchError } from 'redux/actions';
import { addTag } from 'redux/actions/Tags';
import AddIcon from '@material-ui/icons/Add';
import { ProjectObj } from 'types/models/apps/ProjectBoard';
import { ShareSpace } from 'modules/home/Spaces/MySpaces/components/SpacesListing';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 500,
      color: 'black',
      lineHeight: '48px',
    },
    tagsRoot: {
      // overflow: 'scroll',
      // height: '800px',
      // paddingLeft: '10px',
      // paddingRight: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    // heading: {
    //   fontWeight: 500,
    //   color: theme.palette.text.primary,
    //   lineHeight: '48px',
    // },
    root: {
      width: '100%',
      // maxWidth: 360,
      // backgroundColor: theme.palette.background.paper,
    },

    listItem: {
      paddingLeft: '12px',
      paddingRight: '2px',
      fontSize: '12px',
      paddingTop: '2px',
      paddingBottom: '2px',
      background: "#f9f8f8",
      borderRadius: "5px",
      marginBottom: "7px",
      '&:hover': {
        border: "1px solid grey",
      },
    },
  }),
);

interface Props { }

export default function PeopleSidebar({ }: Props) {


  const classes = useStyles();
  const dispatch = useDispatch();
  let authUser = useAuthUser();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<PeopleObj | undefined>(undefined);
  const spaceRole = useSelector<AppState, SpaceRole | undefined>(({ home }) => home?.currentSpace?.spaceMembership?.role);
  const currentSpace = useSelector<AppState, SpaceData | undefined>(({ home }) => home?.currentSpace);
  const currentProject = useSelector<AppState, ProjectObj | null>(({ projects }) => projects.projectDetail);
  const peopleList = useSelector<AppState, PeopleObj[]>(({ home }) => home.peopleList);

  const [openShareModal, setOpenShareModal] = useState(false);
  const onDeletePeople = () => {

    if (peopleList.find(p => p.role == SpaceRole.OWNER && p.userId !== selectedUser?.userId)) {
      currentSpace && selectedUser && dispatch(onDeleteSpacePeople(currentSpace.id, currentSpace.currentSpaceProject.id, selectedUser));
      setDeleteDialogOpen(false);
    } else {
      dispatch(fetchError("Cannot delete the last space Owner. You may delete the space from your Spaces Dashboard."))
    }
  };

  let currentUserSpaceRole: PeopleObj | undefined = peopleList.find((p) => (p.userId === authUser?.uid));

  return (console.log(`%c[st] rendering PeopleSidebar`, 'color: orange;'),
    <Layout>
      <div className={
        // 'sidebar-container'
        ""
      }>
        {/* {currentSpace && currentProject &&
        <ShareSpace
          open={openShareModal}
          onClose={() => {
            setOpenShareModal(false);
          }}
          // submitForm={handleShareSpace}
          id={currentSpace?.id || ''}
          space={currentSpace}
          project={currentProject}
        />} */}

        {/* {canEditSpace(spaceRole) &&
          <Tooltip title='Add someone to this space' >
            <Fab size="small" color="secondary" onClick={() => {
              setOpenShareModal(true);
            }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        } */}
        {currentSpace && canEditSpace(currentSpace.spaceMembership.role) && (
          <Box>
            <Box style={{margin: '8px', color: 'white', padding: '4px', textAlign: 'center', borderRadius: '16px', backgroundColor: 'grey'}}>{`${peopleList.filter(p => p.role === SpaceRole.OWNER).length} Owners, ${peopleList.filter(p => p.role === SpaceRole.EDITOR).length} Editors, ${peopleList.filter(p => p.role === SpaceRole.VIEWER).length} Viewers`}</Box>
            <List
              style={{ width: "100%", padding: 8 }}
            // style={{ padding: 3 }}
            >

              {peopleList.map((person: PeopleObj, index) => (

                <Tooltip title={person.email}><div>
                  <PeopleItem
                    // listStyle='paddingX'
                    key={person.email}
                    item={person}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                    setSelectedUser={setSelectedUser}
                    currentPeopleSpaceRole={currentUserSpaceRole}
                  /></div>
                </Tooltip>
              ))}
            </List>
          </Box>
        )}
        {isDeleteDialogOpen ? (
          <ConfirmationDialog
            open={isDeleteDialogOpen}
            onDeny={setDeleteDialogOpen}
            onConfirm={onDeletePeople}
            title={<IntlMessages id='sidebar.people.deleteMessage' />}
            dialogTitle={<IntlMessages id='common.deleteItem' />}
          />
        ) : null}

      </div>
    </Layout>);

}
// export const PeopleList = (peopleList: PeopleObj[]) => {

//   <List
//     style={{ width: "100%", padding: 8 }}
//   // style={{ padding: 3 }}
//   >

//     {peopleList.map((person: PeopleObj, index) => (

//       <Tooltip title={person.email}><div>
//         <PeopleItem
//           // listStyle='paddingX'
//           key={person.email}
//           item={person}
//           setDeleteDialogOpen={setDeleteDialogOpen}
//           setSelectedUser={setSelectedUser}
//           currentPeopleSpaceRole={currentUserSpaceRole}
//         /></div>
//       </Tooltip>
//     ))}
//   </List>
// }