import {
  SpaceData,
  FilterDataSpaces,
  Lesson,
  ShowcaseTag,
  PeopleObj,
  SIDEBAR_INDEX,
} from '../../types/models/home/HomeApp';
import * as HomeAction from '../../types/actions/Home.action';
import { AppActions } from '../../types';
import { UseCase } from 'shared/constants/AppConst';
import _ from 'lodash';
import { ModelSearchResponse } from 'types/models/home/ThirdParty/Sketchfab';
import { ViewModeSecondary } from 'modules/home/SpaceDetail/SpaceView/Sidebar';
import { SET_TAGS_VIEW_MODE } from '../../types/actions/Home.action';
import Simulation from 'mp/core/craEngine/SubSystems/core/Simulation';

export type ShowcaseTagMap = {
  [id: string]: ShowcaseTag
}

const initialState: {
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////   Don't put here if a field is not specific to a space /////////////////////////////////////
  // mpSdk: any;
  // spacesList: SpaceData[];
  // sharedSpacesList: SpaceData[];
  currentSpace: SpaceData | undefined;
  lessonsList: Lesson[];
  currentCustomData: any,
  spaceTags: ShowcaseTagMap;
  spaceModels: Map<string, any>;
  currentShowcaseTags: ShowcaseTag[];
  tagsViewMode: string;
  tagsViewModeSecondary: string;
  spaceVariables: any[];
  isWorkflowsSidebarOpen: boolean;
  bannerText: string;
  peopleList: PeopleObj[];
  selectedTagId: string;
  variableValues: any[];
  primaryUseCase: UseCase | null;
  secondaryUseCase: UseCase[];
  primaryUseCaseOther: string;
  secondaryUseCaseOther: string;
  editShowcaseTagId: string; //Set when you click a tag: For editing, adding voice comment, scrolling into view
  tagSidBeingAdded: any;
  openTagForm: boolean;
  openRadialMenu: boolean;
  currentSidebarIndex: SIDEBAR_INDEX;
  isAddingTag: boolean;
  sketchfabSearchResponse: ModelSearchResponse;
  presentationMode: boolean;
  spaceMetadata: any;
  lockView: boolean;
  editTagInXR: boolean;
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
} = {
  // mpSdk: null,
  // spacesList: [],
  // sharedSpacesList: [],
  currentSpace: undefined,
  lessonsList: [],
  currentCustomData: {},
  spaceTags: {},
  spaceModels: new Map(),
  currentShowcaseTags: [],
  tagsViewMode: '',
  tagsViewModeSecondary: '',
  spaceVariables: [],
  isWorkflowsSidebarOpen: false,
  bannerText: '',
  peopleList: [],
  selectedTagId: '', //Set when you hover over a tag
  variableValues: [],
  primaryUseCase: null,
  secondaryUseCase: [],
  primaryUseCaseOther: '',
  secondaryUseCaseOther: '',
  editShowcaseTagId: '',
  tagSidBeingAdded: null,
  openTagForm: false,
  openRadialMenu: false,
  currentSidebarIndex: SIDEBAR_INDEX.NONE,
  isAddingTag: false,
  sketchfabSearchResponse: { results: [] },
  presentationMode: false,
  spaceMetadata: {},
  lockView: false,
  editTagInXR: false
};

const Home = (state = initialState, action: AppActions) => {
  switch (action.type) {
    // case SET_MP_SDK:
    //   return {
    //     ...state,
    //     mpSdk: action.payload,
    //   };
    // case GET_SPACES_LIST:

    //   return {
    //     ...state,
    //     //TODO put these in a separate User/ Auth store,
    //     // notifications: state.notifications,
    //     // notifCount: state.notifCount,

    //     //TODO put these in a separate global application store,
    //     catalogItems: state.catalogItems,

    //     spacesList: action.payload,
    //   };

    case HomeAction.SET_SPACE_DATA:

      let spaceProperties = action.payload ? { ...state } : { ...initialState }

      return {
        ...spaceProperties,
        currentSpace: {
          ...action.payload,
          // variables: Variable.translateLegacyVars(action.payload?.variables || []) as any[]
        },
        // variableValues: action.payload?.variables ? action.payload.variables : [],
        spaceVariables:
          // Variable.translateLegacyVars
          (action.payload?.variables || []).map(v => ({ ...v, allowControl: true })) as any[], //allowControl has no meaning at space level. It only means if a var should be shown for a lesson.
        primaryUseCase: action.payload?.primaryUseCase ? action.payload.primaryUseCase : null,
        secondaryUseCase: action.payload?.secondaryUseCase ? action.payload.secondaryUseCase : [],
        primaryUseCaseOther: action.payload?.primaryUseCaseOther ? action.payload.primaryUseCaseOther : '',
        secondaryUseCaseOther: action.payload?.secondaryUseCaseOther ? action.payload.secondaryUseCaseOther : '',
      };

    // case SET_SPACE_LIST:
    //   return {
    //     ...state,
    //     spacesList: state.spacesList,
    //   };

    case HomeAction.ADD_SPACES_HIDE_MODAL:
      return {
        ...state,
        hide_model: action.payload,
      };

    // case GET_SHARED_SPACES_LIST:
    //   return {
    //     ...state,
    //     sharedSpacesList: action.payload,
    //   };

    case HomeAction.SET_SPACE_TAGS_LIST:
      return {
        ...state,
        spaceTags: action.payload,
        // currentSpaceTags: action.payload
      };

    case HomeAction.SET_SPACE_MODELS_LIST:
      return {
        ...state,
        spaceModels: action.payload,
        // spaceTags: [],
      };

    case HomeAction.GET_LESSONS_LIST:

      // let cl = store().getState().layer.currentLesson;
      // if (store().getState().layer.currentLesson) {
      //   let l: any = action.payload.find(lesson => lesson.id == store().getState().layer.currentLesson?.id) || null;
      // return {
      //   ...state,
      //   lessonsList: action.payload,
      //   // currentLesson: l
      // };
      // }
      return {
        ...state,
        lessonsList: action.payload,
      };
    case SET_TAGS_VIEW_MODE:
      return {
        ...state,
        tagsViewMode: action.payload,
      };
    case HomeAction.SET_TAGS_VIEW_MODE_SECONDARY:
      // let cst = action.payload == ViewModeSecondary.LAYERS_TAGS ? {currentShowcaseTags: Object.values(state.spaceTags)} : {};
      return {
        ...state,
        tagsViewModeSecondary: action.payload,
        // ...cst
      };

    case HomeAction.GET_PEOPLE_LIST_DATA:
      return {
        ...state,
        peopleList: action.payload,
      };

    case HomeAction.SET_CURRENT_SHOWCASE_TAGS:
      return {
        ...state,
        currentShowcaseTags: action.payload,
      };

    // case HomeAction.SET_OPEN_TAG_FORM:
    //   return {
    //     ...state,
    //     openTagForm: action.payload,
    //   };

    case HomeAction.SET_EDIT_SHOWCASE_TAG_ID:
      let newState = {
        ...state,
        editShowcaseTagId: action.payload,
      };
      // !!action.payload && (newState.openTagForm = true)

      return newState;

    case HomeAction.SET_OPEN_TAG_FORM:

      if (!action.payload) {
        return {
          ...state,
          openTagForm: action.payload,
          isAddingTag: false,
          editShowcaseTagId: '',
          editTagInXR: false,
        };
      }
      else {
        return {
          ...state,
          openTagForm: action.payload,
        };
      }

    case HomeAction.GET_SPACE_PROJECTS:
      return {
        ...state,
        spaceProjectList: action.payload,
      };

    case HomeAction.SET_SELECTED_TAG:
      return {
        ...state,
        selectedTagId: action.payload,
      };
    case HomeAction.OPEN_RADIAL_MENU:
      return {
        ...state,
        // openRadialMenu: action.payload,
      };
    case HomeAction.SET_CURRENT_SIDEBAR_INDEX:
      return {
        ...state,
        currentSidebarIndex: action.payload,
      };

    case HomeAction.SET_IS_ADDING_TAG:
      return {
        ...state,
        isAddingTag: action.payload,
      };
    case HomeAction.SET_TAG_SID_BEING_ADDED:
      return {
        ...state,
        tagSidBeingAdded: action.payload,
      };
    case HomeAction.SET_CURRENT_CUSTOM_DATA:
      return {
        ...state,
        currentCustomData: action.payload
      }
    case HomeAction.SET_SKETCHFAB_SEARCH_RESPONSE:
      return {
        ...state,
        sketchfabSearchResponse: action.payload
      }
    case HomeAction.SET_PRESENTATION_MODE:
      Simulation.instance.updateFireWaterObjects(!action.payload);
      return {
        ...state,
        presentationMode: action.payload
      }
    case HomeAction.SET_SPACE_VARIABLES:
      return {
        ...state,
        spaceVariables: action.payload
      }
    case HomeAction.SET_SPACE_METADATA:
      return {
        ...state,
        spaceMetadata: action.payload
      }

    // case HomeAction.ADD_NEW_TAG:
    //   return {
    //     ...state,
    //     spaceTags: state.spaceTags.entries().
    //   }

    case HomeAction.UPDATE_TAG:

      return {
        ...state,
        spaceTags: { ...state.spaceTags, [action.payload.id]: action.payload },
        // currentShowcaseTags: [ ...state.currentShowcaseTags, action.payload]
      }

    case HomeAction.CLEAR_SPACE:

      return {
        ...initialState,
      }

    case HomeAction.LOCK_VIEW:

      return {
        ...state,
        lockView: action.payload
      }

    case HomeAction.EDIT_TAG_IN_XR:
      return {
        ...state,
        editTagInXR: action.payload
      }
    default:
      return state;
  }
};
export default Home;
