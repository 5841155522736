import { tryCatch } from "@crema/utility/Utils";
import { Tooltip, Box, Button, Checkbox, FormControl, FormControlLabel, InputBase, InputLabel, makeStyles, MenuItem, Select, TextField, Typography, InputAdornment, IconButton } from "@material-ui/core";
import { values } from "lodash";
import { uploadMediaFiles } from "modules/home/SpaceDetail/utils";
import React, { useEffect, useState } from "react";
import { Vector3 } from "three";
import ColorPicker from "../../../../../../@crema/core/ColorPicker";
import Utils from '../../../../../../mp/core/craEngine/Tools/Utils';
import * as Icons from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({

    bar: {
        position: 'absolute',
        right: "1%",
        top: "1%",
        width: '25%',
        // height: '30%',
        opacity: "0.9",
        backgroundColor: 'rgba(255, 255, 255)',
        borderRadius: "4px"
    },

    panelTitle: {
        textAlign: "center",
        paddingBottom: 5,
        opacity: "1",
        color: "black"
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 200,
        // borderColor: 'green',
        borderWidth: 2,
        // backgroundColor: "grey",
        '& .MuiInputBase-root': {
            color: "black",
            opacity: "1",
            // borderColor: 'green',
            borderWidth: 2,
        },
        '& .MuiInputBase-input': {
            color: "black",
            opacity: "1",
            lineHeight: 1.2,
            paddingTop: '12px',
            fontSize: '12px',
            backgroundColor: "#e0e0e0",
            borderRadius: "4px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontWeight: 500
        },
        '&  .MuiFormLabel-root': {
            color: "black"
        },
        '& .MuiOutlinedInput-input': {
            padding: '11px'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: "translate(23px, -2px) scale(0.75)"
        }

    },
    fieldLabel: {
        marginLeft: 9,
        fontWeight: 500,
        // color: "black",
        // width: '50px',
        display: "inline-flex",
    },
    selectFormControl: {
        display: 'flex',
        margin: theme.spacing(1),
        minWidth: "90%",
        color: "black",
        '& .MuiFormLabel-root': {
            color: "black",
        }
    },
    selectBox: {
        display: 'flex',
        fontSize: '13px',
        fontWeight: 500,
        padding: "1px",
        color: "black",

    },
    selectMenuItem: {
        display: 'flex',
        fontSize: "13px",
        color: "black",
        fontWeight: 500,
    },
    checkbox: {
        padding: '0px 8px'
    }
}));

interface PanelInputFieldProps {
    props: any;
}


const PanelInputComp: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();
    return (console.log(`%c[st] rendering PanelInputField"`, 'color: orange;'), <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">

        <Typography variant="caption" className={classes.fieldLabel}> {props.label} </Typography>

        {props.type == "textInput" && <InputBase
            id={props.label}
            // label='Label'
            style={{ padding: 8 }}
            placeholder={props.placeholder}

            className={classes.textField}
            fullWidth

        />}



        {props.type == "color" &&

            <Box style={{ padding: 8 }}>
                <ColorPicker
                    //ref={(props as any).colorReference}
                    color="#ff00ffff"
                    onColorChanged={() => { }}
                    visible={true}>
                </ColorPicker></Box>
        }
    </Box>)
}

export const PanelInputLabel: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();

    return <Typography variant="caption" className={classes.fieldLabel} > {props.label} </Typography>
}

export const PanelInputField: React.FC<PanelInputFieldProps> = ({ props }) => {
    const classes = useStyles();
    const [renderState, updateRenderState] = React.useState<boolean>(false);
    //const [checkState, setCheckState] = React.useState(false);

    function handleChange(e: any) {

        if (props.inputType == "number") {
            let v = e.target.value;

            if (props.maxDecimals != undefined) {
                if (v.indexOf(".") != -1) {
                    let dec = v.split(".")[1];
                    if (dec.length > props.maxDecimals) {
                        v = v.substring(0, v.length - 1);
                        e.target.value = v;
                    }
                }
            }
        }

        if (props.onChange) {
            props.onChange(e.target.value)
        }
        props.text = e.target.value;
        updateRenderState(!renderState);

    }

    function handleChangeWithValue(v: string) {
        if (props.onChange) {
            props.onChange(v);
        }
        props.text = v;
        updateRenderState(!renderState);

    }

    function handleCheckBoxChange(e: any) {
        if (props.onChange) {
            props.onChange(e.target.checked)
        }
        props.value = e.target.checked;
        updateRenderState(!renderState);

    }

    async function handleInputFileChange(e: any) {
        let filesUrls: string[] = await uploadMediaFiles([...e.target.files]);
        if (props.onChange) {
            props.onChange(filesUrls[0]);
        }
        props.value = filesUrls[0];
        updateRenderState(!renderState);
    }

    function handleApplyChange(e: any) {
        if (props.onChange) {
            props.onChange("apply")
        }
        updateRenderState(!renderState);

    }
    return (
        <Tooltip title={props?.label || ''}><div style={props.style ? props.style : null}>
            {props.type == "textInput" && <TextField
                type={props.inputType}
                id={props.label}
                // label='Label'
                style={{ padding: 4, ...props.style }}
                placeholder={props.placeholder}
                required={!!props.required}
                className={classes.textField}
                fullWidth
                value={props.text}
                onChange={handleChange}
            />
            }

            {props.type == "readOnlyText" && <InputBase
                id={props.label}
                // label='Label'
                readOnly={true}
                className={classes.textField}
                fullWidth
                value={props.text}
                onFocus={props.onFocus}
            />
            }


            {props.type == "textInputMulti" && <InputBase
                id={props.label}
                // label='Label'
                style={{ padding: 8 }}
                placeholder={props.placeholder}

                className={classes.textField}
                fullWidth
                multiline={true}
                rowsMin={4}
                rows={4}
                value={props.text}
                onChange={handleChange}
            //     inputProps={{ endAdornment: (<InputAdornment position="end">
            //     <Button onClick={() => { console.log('saved') }}>Save </Button>
            // </InputAdornment>) }}

                // endAdornment={<InputAdornment position="end">
                //     <Button onClick={() => { console.log('saved') }}>Save </Button>
                // </InputAdornment>}
            />
            }

            {props.type == "color" &&

                <Box style={{ padding: 8 }}>
                    <ColorPicker
                        // ref={colorReference}
                        ref={(props as any).colorReference}
                        color={props.color}

                        onColorChanged={(props as any).onColorChanged}
                        visible={(props as any).showColorProperty} >
                    </ColorPicker></Box>
            }

            {props.type == "select" && props.values && props.values.length > 0 &&
                <FormControl
                    className={classes.selectFormControl}
                >
                    {/* <InputLabel htmlFor={props.label}>{props.label}</InputLabel> */}
                    {/* <Select
                        className={classes.selectBox}
                        value={props.value}
                        onChange={handleChange}
                        inputProps={{
                            name: props.label,
                            id: props.label,
                        }}>
                        {props.values.map((val: any) => <MenuItem
                            key={val} className={classes.selectMenuItem} value={val}>{val}
                        </MenuItem>)} */}
                    <SelectWithCustom props={{ ...props, handleChange: handleChange }} />

                    {/* </Select> */}
                </FormControl>
            }

            {
                props.type == "checkbox" && ("value" in props) &&
                <div className={classes.checkbox} ><FormControlLabel
                    control={<Checkbox checked={props.value} onChange={handleCheckBoxChange} />}
                    label={props.label}
                /></div>
            }

            {props.type == "recorder" && props.recordedValue

            }

            {props.type == "fileInput" &&
                <Button
                    variant="contained"
                    component="label"
                    size="small"
                    color="primary"
                    style={{ height: "2em", marginLeft: 8 }}
                >
                    Upload
                    <input
                        type="file"
                        hidden
                        onChange={handleInputFileChange}
                    />
                </Button>
            }

            {/* {props.type == "applyChanges" &&
                <Button
                    variant="contained"
                    component="label"
                    size="small"
                    color="primary"
                    style={{ height: "2em", marginLeft: 8 }}
                >
                    Apply
                    <input
                        type="submit"
                        hidden
                        onClick={handleApplyChange}
                    />
                </Button>
            } */}

            {props.type == "XYZ" &&
                <PanelXYZField
                    label={props.label}
                    // value={new Vector3(props.text?.split(','))}
                    value={props.text || props.defaultValue}
                    onChange={(axis: string, axisIndex: number, n: number) => {
                        // let v = props.text?.split(',');
                        let v: any[] = (props.text || props.defaultValue)?.split(',').map((vv: any) => Number(vv.trim()));
                        v[axisIndex] = n;
                        handleChangeWithValue(v.toString());
                    }}

                />


            }

        </div></Tooltip>
    )

}

export default PanelInputField;

export const PanelXYZField = (
    { label, value, onChange }: { label: string, value: string, onChange: (axis: string, axisIndex: number, n: number) => void }
) => {
    return (<Box display='flex'>
        {/* <Grid item xs={5}> */}
        {['x', 'y', 'z'].map((axis, i) => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* <div style={{ color: getXYZColor(axis) }} >{axis}</div> */}
            <PanelInputField props={{
                label: `${label ? (label + ' ') : ''}${axis}`,
                // style: { backgroundColor: getXYZColor(axis) },
                style: { borderTop: '1px solid ' + getXYZColor(axis) },
                text: tryCatch(() => {
                    //TODO replace this
                    // console.log(selectedNode?.position?.x);
                    // return selectedNode?.position.x.toFixed(2)

                    return value.split(',').map(vv => Number(vv.trim()))[i]
                    // return JSON.parse(JSON.stringify(value))[axis]?.toFixed(2)
                }, '3D parameterized') || 0,
                type: "textInput",
                inputType: 'number',
                onChange: (v: any) => {
                    let n = tryCatch(() => {
                        console.log(v);
                        let f = Number.parseFloat(v).toFixed(2);
                        return Number(f);
                    }, '3D')
                    if (n) {
                        //TODO replace this
                        // Simulation.instance.lastSelectedNode && (Simulation.instance.lastSelectedNode.position.setX(n));
                        // Simulation.instance.propertiesPanel.saveLastNode(true);
                        onChange(axis, i, n);
                    }
                }
            }} />

        </div>)
        }



    </Box >)
}

function getXYZColor(axis: string) {
    switch (axis) {
        case 'x':
            return 'red';
        case 'y':
            return 'green';
        case 'z':
            return 'blue';
        default:
            return 'black';


    }
}

// const SelectWithCustom = ({handleChange, value, values}: {handleChange: any, value: any, values: any[]}) => {
const SelectWithCustom = ({ props }: { props: any }) => {

    const classes = useStyles();
    const [customValue, setCustomValue] = useState('');
    const [open, setOpen] = useState(false);

    const handleCustomChange = (event: any) => {
        setCustomValue(event.target.value);
    };

    return (
        <FormControl className={classes.selectFormControl}>
            <Select
                className={classes.selectBox}
                value={props.value}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(e) => {
                    e.target.value !== 'custom' ? props.handleChange(e) : setOpen(true)
                }}
                inputProps={{
                    name: props.label,
                    id: props.label,
                }}
            >
                {props.values.map((val: any) => (
                    <MenuItem key={val} className={classes.selectMenuItem} value={val}>
                        {val}
                    </MenuItem>
                ))}
                {props.allowCustomInput && <MenuItem value='custom'
                    onFocus={(e: any) => {
                        e.stopPropagation()
                    }}
                    onClick={(e: any) => {
                        e.stopPropagation()
                    }}
                >
                    <TextField
                        value={customValue}
                        onChange={handleCustomChange}
                        placeholder='Enter custom value'
                        InputProps={{
                            endAdornment: <InputAdornment position="end" >
                                <IconButton
                                    size="small"
                                    // aria-label="toggle password visibility"
                                    onClick={() => props.handleChange({ target: { value: customValue } })}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    <Icons.Check />
                                </IconButton>
                            </InputAdornment>

                        }}
                    />
                    {/* <Button onClick={(event: any) => {
                        if (event.target.value !== 'custom') {
                            setCustomValue('');
                        }
                        handleChange(customValue)}}>
                        Confirm
                    </Button> */}
                </MenuItem>}
            </Select>
        </FormControl >
    );
};
