import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import "./style.css";
import { LinkButton } from '../LinkButton';
const useStyles = makeStyles((theme: CremaTheme) => ({

}))

export default function IndicatorPulse({ onCancel }: { onCancel: () => void }) {
  // const classes = useStyles();
  return (<div style={{
    margin: "8px 8px", paddingLeft: "8px", paddingRight: "8px",
    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
  }}>
    <div
      className={"dot-pulse"}
    ></div>

    <LinkButton onClick={onCancel}>Cancel
    </LinkButton>


  </div>)
}