import { firestore } from "@crema/services/auth/firebase/firebase";
import { addTagsToEmptyShowcase, getCurrentTagGroup } from "modules/home/SpaceDetail/utils";
import { Dispatch } from "react";
import { store } from "App";
import { AppActions } from "types";
import { SET_TAG_GROUP_LIST, SET_TAG_GROUP_ID } from "types/actions/Home.action";
import { GET_SPACES_LIST } from 'types/actions/Auth.actions';
import { AuthUser } from "types/models/AuthUser";
import { Logic, LogicEngine } from "types/models/dataAccess/Logic";
import { TagGroup, ACTIONS, OBJECT_TYPES } from "types/models/home/HomeApp";
import { fetchStart, fetchSuccess, fetchError } from "./Common";
import { logUserActivity } from "./Home";
import Simulation from "mp/core/craEngine/SubSystems/core/Simulation";

export const onGetTagGroupsList = (
    authUser: AuthUser | null,
    currentLesson: any,
): any => {
    return (dispatch: Dispatch<AppActions>) => {
        if (authUser != null) {
            let currentSpace = store.getState().home.currentSpace;
            dispatch(fetchStart());
            firestore
                .collection(
                    `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups`,
                )
                .get()
                .then((tagGroupDocs: any) => {

                    let tgs: TagGroup[] = tagGroupDocs.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }));
                    // tagGroupDocs.docs.map((tagGroupDoc: any) =>
                    //   tgs.push({
                    //     id: tagGroupDoc.id,
                    //     name: tagGroupDoc.data().name,
                    //     tagIds: tagGroupDoc.data().tagIds,
                    //     sortIndex: tagGroupDoc.data().sortIndex,
                    //   }),
                    // );

                    tgs.sort((a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex);
                    dispatch(fetchSuccess());
                    dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
                    if (tgs && tgs.length > 0 && !store.getState().layer.currentTagGroupId) {
                        // if(){
                        dispatch(setCurrentTagGroupId(tgs[0].id));
                        // }
                    }
                })
                .catch((error) => {
                    console.log('Error getting tag groups: ', error);
                });
        } else {
            // dispatch(fetchError('User is not logged in! Please try again'));
            dispatch({ type: GET_SPACES_LIST, payload: [] });
        }

    };
};


export const updateStepLogic = (logic: Logic): any => {

    return (dispatch: Dispatch<AppActions>) => {

        let spaceId = store.getState().home?.currentSpace?.id;
        let lessonId = store.getState().layer?.currentLesson?.id;
        let currentTagGroupId = store.getState().layer?.currentTagGroupId;
        let tagGroupsList = store.getState().layer.tagGroupsList;

        let currentTagGroup = tagGroupsList.find(tg => tg.id == currentTagGroupId);

        if (currentTagGroup) {
            currentTagGroup.logic = logic;
            console.log(`[st] saving step logic `, logic);

            spaceId && lessonId && currentTagGroup && dispatch(onUpdateTagGroup(currentTagGroup
                // spaceId, lessonId, currentTagGroup, tagGroupsList
            ));
        }
    }

}


export const handleGoToTagGroupByIndex = (sortIndex: number) => {

    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let nextTagGroup: TagGroup | undefined;

        tagGroup = getCurrentTagGroup();

        if (sortIndex == store.getState().layer.tagGroupsList.length) {
            // do nothing
            ;
        } else {
            nextTagGroup = store.getState().layer.tagGroupsList.find(tg => tg.sortIndex == sortIndex);
            console.log(`[st] going to step: ${nextTagGroup?.name} - ${nextTagGroup?.bannerText}`);

            dispatch(setCurrentTagGroupId(nextTagGroup?.id || ''));
            dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        }

    }
};

export const handleNextTagGroup = () => {

    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let nextTagGroup: TagGroup | undefined;

        tagGroup = getCurrentTagGroup();
        if (store.getState().layer.presentationMode && tagGroup?.variableNameForInput) {
            let v = store.getState().layer.variableValues.find(v => v.name == tagGroup?.variableNameForInput);
            if (v && !v.value) {
                dispatch(fetchError(`You need to select a value for ${v.name}  to continue!`));
                return;
            }
        }
        let nextTagGroupIndex = tagGroup ? tagGroup.sortIndex + 1 : 0;
        if (nextTagGroupIndex == store.getState().layer.tagGroupsList.length) {
            // do nothing
            ;
        } else {
            nextTagGroup = store.getState().layer.tagGroupsList.find(tg => tg.sortIndex == nextTagGroupIndex);
            console.log(`[st] going to next step ${nextTagGroup?.name} - ${nextTagGroup?.bannerText}`);

            dispatch(setCurrentTagGroupId(nextTagGroup?.id || ''));
            dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        }

    }
};

export const handleBackTagGroup = () => {
    console.log(`[st] handling prev`);
    return (dispatch: Dispatch<AppActions>) => {
        let tagGroup: TagGroup | undefined;
        let prevTagGroup: TagGroup | undefined;
        // if (currentTagGroupId) {
        tagGroup = store.getState().layer.tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === store.getState().layer.currentTagGroupId);
        let tagGroupIndex = tagGroup ? tagGroup.sortIndex - 1 : 0
        prevTagGroup = store.getState().layer.tagGroupsList?.[tagGroupIndex] || {};
        console.log(`[st] going back to step ${prevTagGroup.name} - ${prevTagGroup.bannerText}`);
        dispatch(setCurrentTagGroupId(prevTagGroup?.id || ''));
        dispatch(logUserActivity(ACTIONS.CLICK, OBJECT_TYPES.TAGGROUP, tagGroup?.id));
        // }
    }
};


export const setTagGroupsList = (tagGroupsList: TagGroup[]): any => {
    return (dispatch: Dispatch<AppActions>) => {
        dispatch({ type: SET_TAG_GROUP_LIST, payload: tagGroupsList });
    };
};

export const onUpdateTagGroup = (tagGroup: TagGroup
    // spaceId: string, lessonId: string, tagGroup: TagGroup, tagGroupsList: TagGroup[]
): any => {
    return (dispatch: Dispatch<AppActions>) => {
        let { currentLesson, tagGroupsList } = store.getState().layer;
        let currentSpace = store.getState().home.currentSpace;

        if (!currentSpace || !currentLesson || !tagGroup) {
            return;
        }

        dispatch(fetchStart());

        firestore
            .doc(
                `Spaces/${currentSpace?.id}/lessons/${currentLesson?.id}/tagGroups/${tagGroup.id}`,
            )
            .set(tagGroup).then(() => {
                dispatch(fetchSuccess());

                let tgl = tagGroupsList.slice();
                let i = tagGroupsList?.findIndex(tg => tg.id == tagGroup.id);
                tgl[i] = tagGroup;
                dispatch({ type: SET_TAG_GROUP_LIST, payload: tgl });
            })
            .catch((error) => {
                dispatch(fetchError("Hmm.. That didn't work. Can you try again?"));
                console.error('couldnt update tagGroup: ', error);
            });
    };
};

export const setCurrentTagGroupId = (tagGroupId: string): any => {
    return (dispatch: Dispatch<AppActions>) => {

        if (tagGroupId !== store.getState().layer.currentTagGroupId) {
            dispatch({ type: SET_TAG_GROUP_ID, payload: tagGroupId });
            //C
            // let tg = getCurrentTagGroup();

            // if (tg) {
            //     addTagsToEmptyShowcase({ tagIds: tg.tagIds.filter(t => Object.keys(store.getState().home.spaceTags).includes(t)) });
            //     // let modelsList = tagIdsForTagGroup.map((tagId: any) => tagId.split('-')[1]);

            //     let spaceModels = store.getState().home.spaceModels;
            //     let showModelIds: string[] = spaceModels && spaceModels.size > 0 ?
            //         Array.from(spaceModels.values()).filter(s => s.userData.alwaysShow).map(s => s.id) : [];

            //     showModelIds.push(...tg.tagIds.filter(spaceModel => spaceModels.has(spaceModel)));
            //     // console.log(`[st] step: filtering nodes, showing ${showModelIds.length}`);
            //     Simulation.instance.filterVisibleNodes(showModelIds).then(() => {
            //         Simulation.instance.updateVariablesState(store.getState().layer.variableValues, getCurrentTagGroup() || null);
            //     })
            //     console.log(`[vars] filtered nodes for step`);
            // }


            // let tg = getCurrentTagGroup();
            // let logic = tg?.logic;
            // console.log(`[st] executing logic for step ${JSON.stringify(tg?.name)}`);

            // // goToPoseForStep(tg);
            // // showTagsAnd3DForStep(tagGroupId);
            // // handleScrollIntoView(tagGroupId);

            // tg?.id && logic && LogicEngine.processStepLogicConditions(tg.id, logic);
        }
    };
};


export const onAddTagGroup = async (tgObj: TagGroup | null) => {

    let docRef = firestore
        .collection(
            `Spaces/${store.getState().home.currentSpace?.id || ''}/lessons/${store.getState().layer.currentLesson?.id}/tagGroups`,
        )
        .doc();
    let tagGroupsList = store.getState().layer.tagGroupsList;
    let tg: TagGroup = {
        id: docRef.id,
        name: tgObj?.name || `Step ${tagGroupsList.length + 1}`,
        tagIds: (tgObj && tgObj.tagIds) ? [...tgObj.tagIds] : [],
        sortIndex: tagGroupsList.length,
        // sweepAndPose: _.cloneDeep(tagGroupsList.find(tg => tg.sortIndex == tagGroupsList.length - 1)?.sweepAndPose || await getCurrentSweep()),
        ...(tgObj && tgObj.bannerText && { bannerText: tgObj.bannerText }),
        ...(tgObj && tgObj.variableNameForInput && { variableNameForInput: tgObj.variableNameForInput }),
        ...(tgObj && tgObj.jumpToTagId && { jumpToTagId: tgObj.jumpToTagId }),
        ...(tgObj && { audioOnly: tgObj.audioOnly }),
        ...(tgObj && tgObj.logic && { logic: tgObj.logic }),
        ...(tgObj && tgObj.video && { video: tgObj.video }),
        ...(tgObj && tgObj.poseId && { poseId: tgObj.poseId }),
        ...(tgObj && tgObj.lockView && { lockView: tgObj.lockView }),
        ...(tgObj && { textOnly: tgObj.textOnly }),
    };
    await docRef.set(tg);
    let tgs = [...tagGroupsList, tg].sort(
        (a: TagGroup, b: TagGroup) => a.sortIndex - b.sortIndex,
    );
    store.dispatch({ type: SET_TAG_GROUP_LIST, payload: tgs });
    store.dispatch(setCurrentTagGroupId(tg.id));
    store.dispatch(logUserActivity(ACTIONS.ADD, OBJECT_TYPES.TAGGROUP, tg.id));
    addTagsToEmptyShowcase({ tagIds: [] });
    // dispatch(setCurrentShowcaseTags(Object.values(spaceTags)));
};


// const goToPoseForStep = (tg: TagGroup) => {
//     if (ç.jumpToTagId && isJumpToTagIdValid(tagGroup)) {
//         try {
//             handleTagClick(tagGroup.jumpToTagId);
//             Simulation.instance.handleModelClick(tagGroup.jumpToTagId);
//         } catch (e) { }
//     } else if (tagGroup.sweepAndPose) {
//         goToPose(tagGroup);
//     }
// }

// const showTagsAnd3DForStep = async (tagGroupId: any) => {

//     if (tagGroupId) {
//         let tagGroup = tagGroupsList.find((tagGroup: TagGroup) => tagGroup.id === tagGroupId);

//         if (!tagGroup) {
//             return;
//         }
//         let tagIdsForTagGroup = tagGroup.tagIds || [];

//         await addTagsToEmptyShowcase({ tagIds: tagIdsForTagGroup.filter(t => Object.keys(spaceTags).includes(t)) });

//         let showModelIds: string[] = spaceModels && spaceModels.size > 0 ?
//             Array.from(spaceModels.values()).filter(s => s.userData.alwaysShow).map(s => s.id) : [];

//         showModelIds.push(...tagIdsForTagGroup.filter(spaceModel => spaceModels.has(spaceModel)));
//         console.log(`[st] step: filtering nodes, showing ${showModelIds.length}`);
//         await Simulation.instance.filterVisibleNodes(showModelIds);
//     }
// }

// export function goToZoom(tagGroup: TagGroup) {
//     let mpSdk = MpSdk.Instance.mps;
//     mpSdk && tagGroup && tagGroup.sweepAndPose && tagGroup.sweepAndPose.zoom &&
//         (tagGroup.sweepAndPose.zoom?.level !== currentZoom?.level) &&
//         mpSdk.Camera.zoomTo(tagGroup.sweepAndPose.zoom.level)
//             .catch((e: any) => console.error(`Error step/cant-move-to-zoom: ${e}`))
// }

// export async function goToPose(tagGroup: TagGroup) {
//     let mpSdk = MpSdk.Instance.mps;

//     if (mpSdk && tagGroup && tagGroup?.sweepAndPose) {
//         let currentSweep = await getCurrentSweep();
//         if (currentSweep?.sid !== tagGroup.sweepAndPose.sid) { // different sweeps, move, rotate and zoom
//             mpSdk.Sweep.moveTo(tagGroup?.sweepAndPose.sid, { rotation: tagGroup?.sweepAndPose?.pose?.rotation, transition: SweepTransition.FLY }).then(() => {
//                 goToZoom(tagGroup);
//             })
//                 .catch((e: any) => console.error(`Error step/cant-move-to-pose: ${e}`));

//         } else if (currentSweep?.rotation !== tagGroup.sweepAndPose.rotation) { //same sweep, check rotation
//             mpSdk.Camera.setRotation(tagGroup.sweepAndPose.rotation).then(goToZoom(tagGroup))
//                 .catch((e: any) => console.error(`Error step/cant-move-to-rotation: ${e}`));
//         } else { //same sweep, same rotation, check zoom
//             goToZoom(tagGroup);
//         }
//     }

// }