import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, fade, Tooltip } from '@material-ui/core';
import { CremaTheme } from '../../../../types/AppContextPropsType';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import AddIcon from '@mui/icons-material/Add';
import * as icons from '@mui/icons-material/';
import clsx from 'clsx';


interface IconHoverProps {
  children: any;
  tooltip: string;
  dark?: boolean;
  iconProps?: any;
  styleProps?: any;
}

const IconHover: React.FC<IconHoverProps> = ({ children, tooltip, dark = false, iconProps = {}, styleProps }) => {

  const useStyles = makeStyles((theme: CremaTheme) => ({
    root: {
      // height: "30px",
      // width: "30px",
      '& .MuiSvgIcon-root': {
        margin: '4px',
        // fontSize: 30,
        cursor: 'pointer',
        height: "25px",
        width: "25px",
        '&:hover, &:focus': {
          color: '#FF3158',
          // transition: 'all 0.3s ease',
          // transform: 'translateY(-4px)',
        },
        filter: 'drop-shadow(1px 1px 1px rgb(0 0 0 / 0.5))',

        color: dark ? theme.palette.grey.A400 : 'white',
        ...iconProps,
      },
      // transition: 'all .1s',
      '& .fav-btn': {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  }));


  const classes = useStyles();
  return <Tooltip title={tooltip}>
    <Box className={clsx(classes.root)} style={styleProps}>{children}</Box></Tooltip>;
};
export default IconHover;
