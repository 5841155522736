import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import {useIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {orange,blueGrey} from '@material-ui/core/colors';
import {Fonts} from '../../../../../../shared/constants/AppEnums';
import {TextField} from '@material-ui/core';
import {avatarName} from "../../../../../../@crema/utility/Utils";
import TextAreaMention from 'modules/home/components/TextAreaMention';
import { CollaboratorObj, CommentObj, ProjectObj, TaskObj } from 'types/models/apps/ProjectBoard';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import MentionEditor from "../../../../../../modules/home/components/Mentions";
import Draft,{ convertToRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';
import _ from 'lodash';
import NotificationService from "../../../../../../@crema/services/NotificationService";
import { EditorCommand } from '@draft-js-plugins/editor';
import { baseUrl } from 'Config';
import moment from 'moment';
import { AuthUser } from 'types/models/AuthUser';
import { store } from 'App';
import { InviteStatus } from 'types/models/home/HomeApp';

const useStyles = makeStyles(() => ({
  avatarRoot: {
    fontSize: 18,
    width: 50,
    height: 50,
    backgroundColor: blueGrey[700],
  },
  textareaAutosizeRoot: {
    width: '100%',
  },
  avatar: {
    fontSize: 18,
    backgroundColor: blueGrey[700],
  }
}));

interface CardCommentsProps {
  comments: CommentObj[];
  onAddNewComment: (comment: string) => void;
  project: ProjectObj;
  selectedTask: TaskObj | null;
}

const CardComments: React.FC<CardCommentsProps> = ({
  comments,
  onAddNewComment,
  project,
  selectedTask,
}) => {
  const {hasCommandModifier} = KeyBindingUtil;

  const dispatch = useDispatch();

  const authUser = useSelector<AppState, AuthUser | null>(({auth}) => auth.authUser);

  const [editorState, setEditorState] = useState<Draft.EditorState>(EditorState.createEmpty());

  const options = {
    // eslint-disable-next-line consistent-return
    entityStyleFn: (entity:any) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "mention") {
        const data = entity.getData();
        return {
          element: "span",
          attributes: {
            "data-mention-id": data.mention.id,
            class: "mention_class",
            title:data.mention.email
          },
          style: {
            color:"blue"
          }
        };
      }
    }
  };

  const contentStateHtml = stateToHTML(editorState.getCurrentContent(),options);

  const myKeyBindingFn = (e: any): string | null =>{
    if( e.keyCode === 13 && hasCommandModifier(e)) {
      return "myeditor-save";
    }
    return getDefaultKeyBinding(e);
  }

  const renderContentAsRawJs = ()=> {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);

    let recipients:CollaboratorObj[] = Object.values(raw.entityMap).map((item:any)=> (item.data.mention));
    return recipients;
  }

  const handleKeyCommand = (command: EditorCommand) =>{
    if (command === 'myeditor-save') {
      onAddNewComment(contentStateHtml);
      setEditorState(EditorState.createEmpty())
      // console.log("editorState.getCurrentContent()",editorState.getCurrentContent().hasText())
      const recipients:CollaboratorObj[] = renderContentAsRawJs()
      Promise.all(recipients.map((r:CollaboratorObj)=>(
        dispatch(NotificationService.mentionInTaskComment(`apps/projects/${project.did}/section/${selectedTask?.sectionId}/task/${selectedTask?.id}`, authUser?.uid || '', r.id?.toString()||'',
      {
        fromFirstName: authUser?.user.firstName || "",
        toEmail: r?.email || "",
        taskTitle: project.name,
        taskLink: `${baseUrl}/apps/projects/${project.did}/section/${selectedTask?.sectionId}/task/${selectedTask?.id}`,
      })))))
      return 'handled';
    }
    return 'not-handled';
  }

  const handleOnButtonClick = ()=>{
    onAddNewComment(contentStateHtml);
    setTimeout(function(){ setEditorState(EditorState.createEmpty()) });
  }

  const {messages} = useIntl();

  const classes = useStyles();

  return (
    <Box mb={{xs: 5, xl: 10}}>
      <Box mb={5}>
        {comments.map((item, index) => {
          return (
            <Box mb={5} key={index}>
              <Box mb={4} display='flex' alignItems='center'>
                {item.image ? (
                  <Avatar src={item.image} className={classes.avatarRoot} />
                ) : (
                  <Avatar className={classes.avatar}>{avatarName(item.name)}</Avatar>
                )}
                <Box ml={4}>
                  <Box
                    component='span'
                    mb={1}
                    display='block'
                    color='primary.main'
                    fontWeight={Fonts.LIGHT}>
                    {item.name}
                  </Box>
                  <Box
                    component='span'
                    color='text.secondary'
                    fontSize={14}
                    display='block'>
                    {moment(item.date).format('MMM DD, hh:mm A')}
                  </Box>
                </Box>
              </Box>
              <Box ml={2} mb={0}>
                <div dangerouslySetInnerHTML={{__html: item.comment}} />
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* <TextField
        multiline
        onKeyDown={onAddComment}
        value={comment}
        onChange={e => setComment(e.target.value)}
        className={classes.textareaAutosizeRoot}
        rows='4'
        variant='outlined'
        placeholder={messages['common.pressEnter'] as string}
      /> */}
       {/* <TextAreaMention
        value={comment}
        data={(project?.memberList||[]).map((item,index)=>({id:item.id,display:item.name}))}
        onChange={(event:any, newValue:any, newPlainTextValue:any, mentions:any) => {
          setComment(newPlainTextValue);
          setCommentMention(mentions);
        }}
        onAdd={(id:any, display:any, startPos:any, endPos:any) => {}}
        placeholder={messages['common.pressEnter'] as string}
        className={classes.textareaAutosizeRoot}
        onKeyDown={onAddComment}
        mentions={commentMention}
      /> */}
      <MentionEditor
        mentions={(()=>{
          let list = project?.memberList || [];

          store.getState().home.peopleList.map(p => p.inviteStatus!==InviteStatus.INVITED && !list.find(i => i.id == p.userId) && list.push({...p, id: p.userId, image: ''} as CollaboratorObj))

          return list;
        })()}
        placeholder={messages['common.pressEnter'] as string}
        editorState={editorState}
        setEditorState={setEditorState}
        onKeyDown={myKeyBindingFn}
        onHandleKeyCommand={handleKeyCommand}
        onHandleSaveComment={handleOnButtonClick}
      />
    </Box>
  );
};

export default CardComments;
