import React, { useContext, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Scrollbar from '../../../../../@crema/core/Scrollbar';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import clsx from 'clsx';
import { SIDEBAR_INDEX } from 'types/models/home/HomeApp';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixedWidth:{
      minWidth: '320px',
      maxWidth: '320px',
    },
    layoutBox: {
      // overflowX: 'hidden',
      // height: '100%',
      // minHeight: 'calc(91vh)',
      // maxHeight: 'calc(91vh)',
      // overflow: 'scroll',
      // boxShadow: '0px 0px 2px 2px rgba(0,0,0,0.12)',
      // border: '1px solid black',
      marginTop: '8px',
      marginBottom: '36px',
      // backgroundColor: 'green',
      transition: 'all 0.5s ease',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        maxWidth: '100%',
        width: '100%',
      },
    },
  }),
);

interface Props {
  children?: React.ReactNode;
  width?: string;
  scrollBarClass?: string;
}

export default function Layout({ children, width, scrollBarClass }: Props) {
  const classes = useStyles();
  const isWorkflowsSidebarOpen = useSelector<AppState, boolean>(({ layer }) => layer.isWorkflowsSidebarOpen);
  // let w = width ?? (!!isWorkflowsSidebarOpen ? '500px' : '');
  const currentSidebarIndex = useSelector<AppState, SIDEBAR_INDEX>(({ home }) => home.currentSidebarIndex);
  let w = isWorkflowsSidebarOpen ? '500px' : (width ?? '');

  return (
    <Scrollbar className={scrollBarClass ?? ''}>
      <div
        className={clsx('sidebar', classes.layoutBox, (currentSidebarIndex == SIDEBAR_INDEX.NONE ? '' : classes.fixedWidth))}
        style={w ? { maxWidth: w, minWidth: w } : {}}>
        {children}
      </div>
    </Scrollbar>
  )
}
