import * as THREE from 'three';
import { RenderingSystem } from './Systems/RenderingSystem';
import { RaycastSystem } from './Systems/RaycastSystem';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';
import Simulation from '../mp/core/craEngine/SubSystems/core/Simulation';
import {TagSystem} from './Systems/TagSystem';

export class TagsVRInteraction {
    //XR members
    protected reticle: THREE.Mesh | null = null;

    protected hitTestSource: THREE.XRHitTestSource | null = null;
    protected hitTestSourceRequested = false;

    protected anchor1Mode = true;
    protected anchorsCollectedAndAligned = false;

    protected anchor1: THREE.Mesh;
    protected anchor2: THREE.Mesh;
    protected cylinderGeometry: THREE.CylinderGeometry;
    // protected controls, group;

    protected controllerGrip1: THREE.Group;
    protected controllerGrip2: THREE.Group;

    constructor(protected xrReferenceSpaceObj3D: THREE.Object3D, protected renderingSystem: RenderingSystem,
                protected raycastSystem: RaycastSystem,
        // protected grabbableSystem: GrabbableSystem
    ) {
        this.renderingSystem.renderer.xr.enabled = true;
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.setupXRControllers(this.renderingSystem); //for VR

        this.raycastSystem.setupForVR();
        const controllerModelFactory = new XRControllerModelFactory();

        this.controllerGrip1 = this.renderingSystem.renderer.xr.getControllerGrip(0);
        this.controllerGrip1.add(controllerModelFactory.createControllerModel(this.controllerGrip1));
        this.renderingSystem.scene.add(this.controllerGrip1);

        this.controllerGrip2 = this.renderingSystem.renderer.xr.getControllerGrip(1);
        this.controllerGrip2.add(controllerModelFactory.createControllerModel(this.controllerGrip2));
        this.renderingSystem.scene.add(this.controllerGrip2);

        const geometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, - 1)]);

        const line = new THREE.Line(geometry);
        line.name = 'line';
        line.scale.z = 5;

        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Controllers[0].add(line.clone());
        this.renderingSystem.cameraSystem.windowingSystem.inputSystem.Controllers[1].add(line.clone());
    
        

    }


    public dispose() {

    }

    public xrRender(frame: any) {
        if (frame) {
            const referenceSpace = this.renderingSystem.renderer.xr.getReferenceSpace();
            const session = this.renderingSystem.renderer.xr.getSession();



        }
    }
}
