import Utils from "../../Tools/Utils";
import { ISceneNode } from "../sceneManagement/SceneComponent";
import { UserDataProperties } from '../ui-interop/PropertiesPanel';

export default class Serialization {
    public static SerializeNode(node: ISceneNode, id: string): any {
        /*
        if(node.userData['id'] !== id) {
            node.userData['id'] = id;
            console.log("Patching id for duplicate node")
        }*/

        try {
            let finalUserData = JSON.parse(JSON.stringify(node.userData));

            if (UserDataProperties.skinnedNode in finalUserData) {
                //remove skinnedNode key in finalUserData
                delete finalUserData[UserDataProperties.skinnedNode];
            }

            let serializedNodeData = {
                annotationType: "3dObject",
                name: node.name,
                position: JSON.stringify(node.position),
                scale: JSON.stringify(node.scale),
                quaternion: JSON.stringify(node.quaternion),
                userData: finalUserData,
                id: id,
                // createdOn: new Date(),
                // createdBy: user.uid
            }
            return serializedNodeData;

        } catch (error) {
            console.error("Error serializing node", error);
            let serializedNodeData = {
                annotationType: "3dObject",
                name: node.name,
                position: JSON.stringify(node.position),
                scale: JSON.stringify(node.scale),
                quaternion: JSON.stringify(node.quaternion),
                userData: node.userData,
                id: id,
                // createdOn: new Date(),
                // createdBy: user.uid
            }
            return serializedNodeData;
        }

    }

    public static DeserializeNode(doc: any) {
        return {
            ...doc.data(),
            ...{
                id: doc.id,
                /*nameToShow: Utils.ExtractModelDisplayName(doc.data().name, doc.id)*/
            }
        }
    }
}