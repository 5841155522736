import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchStart,
  fetchSuccess,
  fetchError,
  onAddNewProject,
  onEditProjectDetail,
  onGetProjectList,
} from '../../../../redux/actions';
import {useHistory} from 'react-router-dom';
import AddNewProject from './AddNewProject';
import IntlMessages from '../../../../@crema/utility/IntlMessages';
import Box from '@material-ui/core/Box';
import GridContainer from '@crema/core/GridContainer';
import ProjectItem from './ProjectItem';
import AddProjectButton from './AddProjectButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {grey, red} from '@material-ui/core/colors';
import {Fonts} from '../../../../shared/constants/AppEnums';
import InfoView from '../../../../@crema/core/InfoView';
import {CremaTheme} from '../../../../types/AppContextPropsType';
import {AppState} from '../../../../redux/store';
import {ProjectObj} from '../../../../types/models/apps/ProjectBoard';
import { useAuthUser } from '@crema/utility/AppHooks';

const useStyles = makeStyles((theme: CremaTheme) => ({
  projectStyle: {
    borderRadius: theme.overrides.MuiCard.root.borderRadius,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    height: 224,
    border: '2px dashed',
    borderColor: grey[600],
  },
  gridContainer: {
    justifyContent: 'center',
  },
  projectItem: {
    borderRadius: theme.overrides.MuiCard.root.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    textAlign: 'center',
    height: 224,
  },
  listAltIcon: {
    marginBottom: 16,
    fontSize: 24,
    [theme.breakpoints.up('xl')]: {
      fontSize: 40,
    },
  },
  addProjectCard: {
    padding: '32px 40px',
  },
  addButton: {
    backgroundColor: red[500],
    color: theme.palette.primary.contrastText,
    paddingRight: 32,
    paddingLeft: 32,
    marginTop: 12,
    '&:hover, &:focus': {
      backgroundColor: red[700],
      color: theme.palette.primary.contrastText,
    },
  },
  avatar: {
    backgroundColor: grey[500],
    marginBottom: 20,
  },
  addIcon: {
    fontSize: 24,
    [theme.breakpoints.up('xl')]: {
      fontSize: 40,
    },
  },
  dialogBox: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 600,
      width: '100%',
    },
    '& .MuiTypography-h6': {
      fontWeight: Fonts.LIGHT,
    },
  },
}));

const ProjectList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let authUser = useAuthUser();

  const projectList = useSelector<AppState, ProjectObj[]>(( { projects } ) => projects.projectList)

  const [isAddProjectOpen, setAddProjectOpen] = useState<boolean>(false);

  const [selectedProject, setSelectedProject] = useState<ProjectObj | null>(null);

  useEffect(() => {
    // authUser && loadSpaces();
    authUser && dispatch(onGetProjectList());
  }, [dispatch]);

  const loadSpaces = async () => {
    dispatch(fetchStart());
    try {
      // dispatch({type: SET_SPACE_DATA, payload: undefined});
      // await dispatch(onGetSpacesList());
      dispatch(fetchSuccess());
    } catch (e:any) {
      console.error(e);
      dispatch(fetchError(e.message));
    }
  };

  const onCloseAddProjectModal = () => {
    setAddProjectOpen(false);
  };

  const onAddButtonClick = () => {
    setSelectedProject(null);
    setAddProjectOpen(true);
  };

  const onEditButtonClick = (project: ProjectObj) => {
    setSelectedProject(project);
    setAddProjectOpen(true);
  };

  const onAddProject = (name: string,spaceId:string) => {
    if (selectedProject) {
      const project = {...selectedProject, name};
      dispatch(onEditProjectDetail(project));
    } else {
      dispatch(onAddNewProject(name,spaceId));
    }
  };

  const onViewProjectDetail = (project: ProjectObj) => {
    history.push(`/apps/projects/${project.did}`);
  };

  const classes = useStyles();

  const projSortList = projectList.sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()).reverse();

  return (console.log(`%c[st] rendering ProjectList`,'color: orange;'),
    <>
      <Box pt={4} display='flex' flex={1} flexDirection='column'>
        <Box
          my={{xs: 5, sm: 5, xl: 8}}
          component='h2'
          color='text.primary'
          fontWeight={Fonts.BOLD}
          textAlign='center'
          fontSize={16}>
          <IntlMessages id='projectboard.projects' />
        </Box>
        <GridContainer className={classes.gridContainer}>
          {projSortList && projSortList.length > 0
            ? projSortList.map(project => {
                return (
                  <ProjectItem
                    key={project.id}
                    project={project}
                    onEditButtonClick={onEditButtonClick}
                    onViewProjectDetail={onViewProjectDetail}
                    classes={classes}
                  />
                );
              })
            : null}
          <AddProjectButton
            onAddButtonClick={onAddButtonClick}
            classes={classes}
          />
        </GridContainer>
      </Box>

      {isAddProjectOpen ? (
        <AddNewProject
          isAddProjectOpen={isAddProjectOpen}
          onCloseAddProjectModal={onCloseAddProjectModal}
          onAddProject={onAddProject}
          selectedProject={selectedProject!}
          classes={classes}
        />
      ) : null}
      <InfoView />
    </>
  );
};

export default ProjectList;
