import * as THREE from 'three';
import {RenderingSystem} from '../Systems/RenderingSystem';
import {SceneNode} from '../Mimick/SceneNode';
import {AddObjectClickSpy} from 'mp/core/craEngine/spies/AddObjectClickSpy';
import {Dict} from 'mp/core/types';
import _, {Dictionary} from 'lodash';
import {Mesh, Texture} from 'three';
import Utils from '../../mp/core/craEngine/Tools/Utils';
import {ISceneNode} from '../../mp/core/craEngine/SubSystems/sceneManagement/SceneComponent';
import {FastDictionary, UserDataCallback} from './Others/Interactable';
import {ComponentUtils} from './Others/ComponentUtils';
import {Generate, Helpers} from '@jsonforms/core';
import {generateUUID} from '../../shared/helpers/helper';

// class Root
// extends THREE.Object3D
// {

//     constructor()

//     add(...object: THREE.Object3D<THREE.Event>[]): this {
//         super.add(...object);

//         return this;
//     }

// }
export abstract class BaseComponent {
    public root: THREE.Object3D;
    public type: string;
    public loadingManager: THREE.LoadingManager;
    public node: SceneNode;
    inputs: Dict;
    outputs: Dict;
    previousInputs: Dict;
    private loaded: boolean;
    // private _interactable:Interactable;
    
    // public get interactable() {
    //     return this._interactable;
    // }
    // abstract nodeRef(): SceneNode;
    
    setLoaded(hasLoaded: boolean) {
        this.loaded = hasLoaded;
        this.initUserDataWithNode();
    }
    
    protected meshes: Mesh[] = [];
    
    public sceneNodeBackup: ISceneNode;
    
    public userData: FastDictionary;
    
    public onClickCallback: UserDataCallback[];
    
    public static componentMap: { [key: string]: any } = {};
    
    public uuid:string;
    
    onClick(): void {
        for (const callback of this.onClickCallback) {
            callback();
        }
    }
    
    abstract onInputsUpdated(oldInputs: this['inputs'], force: boolean): void;
    
    constructor(protected renderingSystem: RenderingSystem) {
        this.onClickCallback = [];
        // this.previousInputs = null;
        // this.inputs = null;
        this.root = new THREE.Object3D();
        this.outputs = {};
        this.uuid = generateUUID();
        BaseComponent.componentMap[this.uuid] = this;
        
        this.root.addEventListener('added', this.initUserDataWithNode.bind(this));
        
        // this.initUserDataWithNode();
        this.type = 'BaseComponent';
        this.userData = {};
        // this._interactable = new Interactable();
        // this.root.userData[Interactable.type] = this._interactable;
        this.renderingSystem.cameraSystem.windowingSystem.registerUpdateCallback(this.inputsObserver.bind(this));
        this.initUserDataWithParentComponent();
    }
    
    private inputsObserver() {
        if (!this.inputs || !this.previousInputs) {
            this.previousInputs = Object.assign({}, this.inputs);
            return;
        }
        // if (ComponentUtils.compareDictionaries(this.inputs, this.previousInputs)) {
        //     return;
        // }
        
        if(_.isEqual(this.inputs, this.previousInputs)) {
            return;
        }
        
        this.onInputsUpdated(this.previousInputs!, false);
        this.previousInputs = Object.assign({}, this.inputs);
    }
    
    hide() {//TODO-ST do we need to disable colliders too? And loop through and hide all meshes?
        this.root.visible = false;
    }
    
    // public assignUserData(data:{ [p: string]: any }) :void {
    //
    // }
    
    show() {
        this.root.visible = true;
    }
    
    onInit(userdata: {[p: string]: any}): void {
        this.renderingSystem.base.add(this.root);
        this.loadingManager = new THREE.LoadingManager();
        this.loadingManager.onLoad = function() {
            // Simulation.instance.initLoaded = true;
            console.log('[stsdk] init loading done: ');
        };
        
        this.loadingManager.onStart = function() {
            console.log('[stsdk] init loading started');
        };
        // this.interactable.addAction(this.onAction);
    }
    
    protected clearMeshes() {
        this.meshes.forEach((mesh) => {
            mesh.geometry.dispose();
            (mesh.material as THREE.MeshBasicMaterial).dispose();
            this.root.remove(mesh);
        });
        
        this.meshes = [];
    }
    
    protected createMesh(size: THREE.Vector2, transparent: boolean, texture: Texture | null, opacity: number, borderRadius: number,
                         polygonOffset: boolean = false, polygonOffsetFactor: number = 0, polygonOffsetUnits: number = 0, meshColor: THREE.Color = new THREE.Color(1, 1, 1)): THREE.Mesh {
        //ThreeJS code to draw a plane from scratch
        let geometry = new THREE.ShapeGeometry(Utils.RoundedRectShape(1, 1, borderRadius));
        let tempMesh = new THREE.Mesh(
            //new THREE.PlaneBufferGeometry(2, 1),
            geometry.translate(-0.5, -0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            //new THREE.ShapeGeometry(Utils.RoundedRectShape(this.context.root.scale.x, this.context.root.scale.y, this.inputs.borderRadius)).translate(-this.context.root.scale.x*0.5, -this.context.root.scale.y*0.5, 0),//.scale(1, this.oldRootScale.y, 1),
            new THREE.MeshBasicMaterial({
                transparent: transparent,
                map: texture,
                opacity: opacity,
                color: meshColor,
                polygonOffset: polygonOffset,
                polygonOffsetFactor: polygonOffsetFactor,
                polygonOffsetUnits: polygonOffsetUnits,
                side: THREE.DoubleSide,
            }),
            // new THREE.MeshBasicMaterial( {color: 0x00ff00} )
        );
        
        this.meshes.push(tempMesh);
        
        // tempMesh.scale.set(size.x, size.y, 1);
        
        tempMesh.scale.set(size.x, size.y, 1);
        tempMesh.updateMatrixWorld();
        
        this.root.add(tempMesh);
        return tempMesh;
    }
    
    initUserDataWithNode() {
        if (this.node) {
            // console.log('[sdk] adding to root', this);
            
            this.root.traverse((child) => {
                child.userData = {...child.userData, nodeUserData: this.node.userData};
                child.userData = {...child.userData, nodeReference: this.node};
            });
            
            this.root.userData = {...this.root.userData, nodeUserData: this.node.userData};
            this.root.userData = {...this.root.userData, nodeReference: this.node};
            // console.log('[sdk] added to root with userData', this);
        }
    }
    
    initUserDataWithParentComponent() {
        if (this.parentComponentRef) {
            this.root.traverse((child) => {
                child.userData = {...child.userData, componentReference: this.parentComponentRef};
            });
    
            this.root.userData = {...this.root.userData, componentReference: this.parentComponentRef};
        }
    }
    
    abstract onDestroy(): void;
    
    onStop() {
        this.onDestroy();
        this.renderingSystem.base.remove(this.root);
    }
    
    // public enableGrab() {
    //     let _domElement = this.renderingSystem.renderer.domElement;
    //     _domElement.addEventListener( 'pointermove', this.onPointerMove );
    //     _domElement.addEventListener( 'pointerdown', onPointerDown );
    //     _domElement.addEventListener( 'pointerup', onPointerCancel );
    //     _domElement.addEventListener( 'pointerleave', onPointerCancel );
    // }
    //
    // private onPointerMove( event:PointerEvent ) {
    //
    //     _raycaster.setFromCamera( this.renderingSystem.cameraSystem.windowingSystem.inputSystem., _camera );
    //
    //     if ( _selected ) {
    //
    //         if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {
    //
    //             _selected.position.copy( _intersection.sub( _offset ).applyMatrix4( _inverseMatrix ) );
    //
    //         }
    //
    //         scope.dispatchEvent( { type: 'drag', object: _selected } );
    //
    //         return;
    //
    //     }
    //
    //     // hover support
    //
    //     if ( event.pointerType === 'mouse' || event.pointerType === 'pen' ) {
    //
    //         _intersections.length = 0;
    //
    //         _raycaster.setFromCamera( _pointer, _camera );
    //         _raycaster.intersectObjects( _objects, true, _intersections );
    //
    //         if ( _intersections.length > 0 ) {
    //
    //             const object = _intersections[ 0 ].object;
    //
    //             _plane.setFromNormalAndCoplanarPoint( _camera.getWorldDirection( _plane.normal ), _worldPosition.setFromMatrixPosition( object.matrixWorld ) );
    //
    //             if ( _hovered !== object && _hovered !== null ) {
    //
    //                 scope.dispatchEvent( { type: 'hoveroff', object: _hovered } );
    //
    //                 _domElement.style.cursor = 'auto';
    //                 _hovered = null;
    //
    //             }
    //
    //             if ( _hovered !== object ) {
    //
    //                 scope.dispatchEvent( { type: 'hoveron', object: object } );
    //
    //                 _domElement.style.cursor = 'pointer';
    //                 _hovered = object;
    //
    //             }
    //
    //         } else {
    //
    //             if ( _hovered !== null ) {
    //
    //                 scope.dispatchEvent( { type: 'hoveroff', object: _hovered } );
    //
    //                 _domElement.style.cursor = 'auto';
    //                 _hovered = null;
    //
    //             }
    //
    //         }
    //
    //     }
    //
    // }
    //
    // function onPointerDown( event ) {
    //
    //     if ( scope.enabled === false ) return;
    //
    //     updatePointer( event );
    //
    //     _intersections.length = 0;
    //
    //     _raycaster.setFromCamera( _pointer, _camera );
    //     _raycaster.intersectObjects( _objects, true, _intersections );
    //
    //     if ( _intersections.length > 0 ) {
    //
    //         _selected = ( scope.transformGroup === true ) ? _objects[ 0 ] : _intersections[ 0 ].object;
    //
    //         _plane.setFromNormalAndCoplanarPoint( _camera.getWorldDirection( _plane.normal ), _worldPosition.setFromMatrixPosition( _selected.matrixWorld ) );
    //
    //         if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {
    //
    //             _inverseMatrix.copy( _selected.parent.matrixWorld ).invert();
    //             _offset.copy( _intersection ).sub( _worldPosition.setFromMatrixPosition( _selected.matrixWorld ) );
    //
    //         }
    //
    //         _domElement.style.cursor = 'move';
    //
    //         scope.dispatchEvent( { type: 'dragstart', object: _selected } );
    //
    //     }
    //
    //
    // }
    //
    // function onPointerCancel() {
    //
    //     if ( scope.enabled === false ) return;
    //
    //     if ( _selected ) {
    //
    //         scope.dispatchEvent( { type: 'dragend', object: _selected } );
    //
    //         _selected = null;
    //
    //     }
    //
    //     _domElement.style.cursor = _hovered ? 'pointer' : 'auto';
    //
    // }
    
    // onAction(){
    
    // }
}
