import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  appHeader: {
    [theme.breakpoints.up('sm')]: {
      height: 60,
    }, //added because My Spaces header on xs has two rows #stylechange
    [theme.breakpoints.up('xl')]: {
      height: 77,
    },
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${grey[300]}`,

  },
  checkboxRoot: {
    marginRight: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

interface AppsFooterProps {
  children: ReactNode;
}

const AppsHeader: React.FC<AppsFooterProps> = ({ children }) => {
  const classes = useStyles();
  return (
    <Box px={6} py={{ xs: 1, xl: 3 }} className={classes.appHeader}>
      {children}
    </Box>
  );
};

export default AppsHeader;
AppsHeader.defaultProps = {};
