import Simulation from '../../../mp/core/craEngine/SubSystems/core/Simulation';
import { TagSystem } from '../../Systems/TagSystem';
import { addXRControls } from "./XRControls";
class VRButton {

	static createButton(renderer, sessionInit = {}) {

		const button = document.createElement('button');
		var rootDiv = document.createElement('div');
		rootDiv.id = 'vrOverlay';

		function showEnterVR( /*device*/) {
			let currentSession = null;
			if (sessionInit.domOverlay === undefined) {

				let currentSession = null;
				let controlsDiv = addXRControls(currentSession);
				rootDiv.appendChild(controlsDiv);

				if (sessionInit.optionalFeatures === undefined) {
                    sessionInit.optionalFeatures = [];
                }
				sessionInit.optionalFeatures.push('dom-overlay');
				sessionInit.domOverlay = { root: rootDiv };
			}

			async function onSessionStarted(session) {

				session.addEventListener('end', onSessionEnded);

				await renderer.xr.setSession(session);
				Simulation.instance.sdk.tagSystem = new TagSystem(Simulation.instance.sdk.renderingSystem);
				button.textContent = 'EXIT VR';
				// Simulation.instance.sdk?.SetMode(SpatialThinkSDKMode.VR);

				currentSession = session;
				sessionInit.domOverlay.root.style.display = '';
			}

			function onSessionEnded( /*event*/) {

				currentSession.removeEventListener('end', onSessionEnded);
				button.textContent = 'ENTER VR';
				currentSession = null;
				// Simulation.instance.sdk?.SetMode(SpatialThinkSDKMode.Plain);
				sessionInit.domOverlay.root.style.display = 'none';
			}

			button.style.display = '';
			button.style.cursor = 'pointer';
			button.style.left = 'calc(50% - 50px)';
			button.style.width = '100px';

			button.textContent = 'ENTER VR';

			button.onmouseenter = function () {
				button.style.opacity = '1.0';
			};

			button.onmouseleave = function () {

				button.style.opacity = '0.5';

			};

			button.onclick = function () {

				if (currentSession === null) {

					// WebXR's requestReferenceSpace only works if the corresponding feature
					// was requested at session creation time. For simplicity, just ask for
					// the interesting ones as optional features, but be aware that the
					// requestReferenceSpace call will fail if it turns out to be unavailable.
					// ('local' is always available for immersive sessions and doesn't need to
					// be requested separately.)

					const sessionInit = { optionalFeatures: ['local-floor', 'bounded-floor', 'hand-tracking', 'layers'] };
					navigator.xr.requestSession('immersive-vr', sessionInit).then(onSessionStarted);

				} else {

					currentSession.end();

				}

			};

		}

		function disableButton() {

			button.style.display = '';

			button.style.cursor = 'auto';
			button.style.left = 'calc(50% - 75px)';
			button.style.width = '150px';

			button.onmouseenter = null;
			button.onmouseleave = null;

			button.onclick = null;

		}

		function showWebXRNotFound() {

			disableButton();

			button.textContent = 'VR NOT SUPPORTED';

		}

		function showVRNotAllowed(exception) {

			disableButton();

			console.warn('Exception when trying to call xr.isSessionSupported', exception);

			button.textContent = 'VR NOT ALLOWED';

		}

		function stylizeElement(element) {

			element.style.position = 'absolute';
			element.style.top = '100px';
			element.style.padding = '12px 6px';
			element.style.border = '1px solid #fff';
			element.style.borderRadius = '4px';
			element.style.background = 'rgba(0,0,0,0.9)';
			element.style.color = '#fff';
			element.style.font = 'normal 13px sans-serif';
			element.style.textAlign = 'center';
			element.style.opacity = '0.5';
			element.style.outline = 'none';
			element.style.zIndex = '999';

		}

		if ('xr' in navigator) {

			button.id = 'VRButton';
			button.style.display = 'none';

			stylizeElement(button);

			navigator.xr.isSessionSupported('immersive-vr').then(function (supported) {

				supported ? showEnterVR() : showWebXRNotFound();
				//
				// if ( supported && VRButton.xrSessionIsGranted ) {
				//
				// 	button.click();
				//
				// }

			}).catch(showVRNotAllowed);

			return button;

		} else {

			const message = document.createElement('a');

			if (window.isSecureContext === false) {

				message.href = document.location.href.replace(/^http:/, 'https:');
				message.innerHTML = 'WEBXR NEEDS HTTPS'; // TODO Improve message

			} else {

				message.href = 'https://immersiveweb.dev/';
				message.innerHTML = 'WEBXR NOT AVAILABLE';

			}

			message.style.left = 'calc(50% - 90px)';
			message.style.width = '180px';
			message.style.textDecoration = 'none';

			stylizeElement(message);

			return message;

		}

	}

	static registerSessionGrantedListener() {

		if ('xr' in navigator) {

			// WebXRViewer (based on Firefox) has a bug where addEventListener
			// throws a silent exception and aborts execution entirely.
			if (/WebXRViewer\//i.test(navigator.userAgent)) return;

			navigator.xr.addEventListener('sessiongranted', () => {

				VRButton.xrSessionIsGranted = true;

			});

		}

	}

}

VRButton.xrSessionIsGranted = false;
VRButton.registerSessionGrantedListener();

export { VRButton };