import BaseAnimation from "./BaseAnimation";
import * as THREE from "three"
import * as TWEEN from '@tweenjs/tween.js'
import {MathUtils} from 'three';
import { ISceneNode } from "../SubSystems/sceneManagement/SceneComponent";
import { UserDataProperties } from "../SubSystems/ui-interop/PropertiesPanel";

export default class RotateAnimation extends BaseAnimation {
    private static _instance: RotateAnimation;
    
    public static get instance():RotateAnimation {
        if(!this._instance) {
            this._instance = new RotateAnimation();
        }
        
        return this._instance;
    }
    
    
    protected _animation(node:ISceneNode, mesh:THREE.Object3D, extraParams: { [key: string]: any } | null):void {
        if(!mesh) {
            return;
        }
        let oldRotation = mesh.rotation.clone();
        let newRotation = mesh.rotation.clone();
    
        if((extraParams as any).deltaRotation) {
            let deltaRotation = (extraParams as any).deltaRotation;
            newRotation.set(
                oldRotation.x + deltaRotation.x * MathUtils.DEG2RAD,
                oldRotation.y + deltaRotation.y * MathUtils.DEG2RAD,
                oldRotation.z + deltaRotation.z * MathUtils.DEG2RAD, "XYZ");
        }
        
        new TWEEN.Tween(mesh.rotation)
            .to(
                {
                    x: newRotation.x,
                    y: newRotation.y,
                    z: newRotation.z
                },
                (extraParams as any).duration ? (extraParams as any).duration : 1000
            )
            .easing(TWEEN.Easing.Cubic.InOut)
            .start().onComplete(() =>
            {
                super._animationComplete(node);
            });
    }
    
}
