import * as React from 'react';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core';
import { CremaTheme } from 'types/AppContextPropsType';
import { grey } from '@material-ui/core/colors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NestedMenuItem from "material-ui-nested-menu-item";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import IconButton from '@material-ui/core/IconButton';

interface MultipleOverflowMenuProps {
    buttonStyle?: any;
    menuItems: Array<any>;
    propIcon: any;
}

export const MultipleOverflowMenu: React.FC<MultipleOverflowMenuProps> = ({
    menuItems,
    buttonStyle,
    propIcon = <MoreVertIcon />,
  }) => {

  const [menuPosition, setMenuPosition] = React.useState<any>(null);

  const handleRightClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX
    });
  };

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };

  const handleItemClickOnSubMenu = (event: React.MouseEvent, func: () => void) => {
    func();
    setMenuPosition(null);
  }

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        disableFocusRipple={true}
        onClick={handleRightClick}>
        <LocalOfferOutlinedIcon/>
      </IconButton>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        {menuItems.map((item, index) => {
          return(
            <NestedMenuItem
              label={item.title}
              parentMenuOpen={!!menuPosition}
              onClick={handleItemClick}
              key={`nested-menu-${index}`}
            >
              {item.subItems.map((subItem: any, index_: any) => {
                return <MenuItem key={`nested-menu-item-${index_}`} onClick={(e) => handleItemClickOnSubMenu(e, subItem.onClick)}>{subItem.title}</MenuItem>
              })}
            </NestedMenuItem>
        )})}
      </Menu>
    </div>
  );
}
