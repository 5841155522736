import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const columns1 = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: any) =>
      `${params.getValue(params.id, 'firstName') || ''} ${params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

export default function AppDataGrid(
  { rows,
    columns,
    ...props
  }: {
    rows?: any[],
    columns?: GridColDef[],
    [x: string]: any
  },


) {

  // rows.every(row => Object.entries(row).every(kv => row[kv[0]] = isObject(kv[1]) ? JSON.stringify(kv[1]) : kv[1]));
  return (
    <div style={{
      height: "600px",
      width: '100%',
      backgroundColor: 'white'
    }}>

      <DataGrid
        rowHeight={30}
        rows={rows || []}
        columns={columns || columns1}
        pageSize={20}
        rowsPerPageOptions={[20]}
        checkboxSelection={props?.checkboxSelection || true}
        disableSelectionOnClick
        {...props}
      />

    </div>
  );
}
