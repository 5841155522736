import ManagedObject from '../../ds/ManagedObject';
import MathExtensions from '../../../Tools/MathExtensions';
import * as THREE from 'three';

export default class VectorGradientInterpolator extends ManagedObject {
    private lerpOutput:THREE.Vector3;
    public constructor(private gradientArray: { gradient:number, interpolationMapping:THREE.Vector3 }[]) {
        super(null);
        this.lerpOutput = new THREE.Vector3();
        this.gradientArray.sort((a, b) => (a.gradient > b.gradient) ? 1 : -1);
    }
    
    public getInterpolation(x:number):THREE.Vector3 {
        let lastIndex = -1;
        for(let i = 0; i < this.gradientArray.length; i++) {
            if(x <= this.gradientArray[i].gradient) {
                lastIndex = i;
                break;
            }
        }
        
        if(lastIndex === -1) {
            return this.gradientArray[this.gradientArray.length - 1].interpolationMapping;
        } else if(lastIndex === 0) {
            return this.gradientArray[lastIndex].interpolationMapping;
        }
        
        let gradientElementA= this.gradientArray[lastIndex];
        let gradientElementB= this.gradientArray[lastIndex - 1];
        
        let normalized = MathExtensions.normalize(x, gradientElementA.gradient, gradientElementB.gradient);
    
        this.lerpOutput.lerpVectors(gradientElementB.interpolationMapping, gradientElementA.interpolationMapping, normalized);
        return this.lerpOutput;
    }
}
